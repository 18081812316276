import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  FlexBox,
  JoonUIColor,
  SPACING,
  TherapistIcon,
  Typography,
  UserRole,
} from "@joonapp/web-shared"

import DataTaskCard from "./dataTasks/DataTaskCard"
import useDataTasksModalStore from "./dataTasks/useDataTasksModalStore"
import Card from "../../components/card/Card"
import ArrowCircleRightIcon from "../../components/icons/ArrowCircleRightIcon"
import { completeQuestStatuses } from "../../constants"
import {
  useIncidentTemplatesQuery,
  useParentTaskQuery,
  useReorderQuestsQuery,
  useUserQuery,
} from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"
import { getParentAvatarImage } from "../../util/util"
import useCareTeam from "../care/useCareTeam"
import ReportIncidentButton from "../me/incidents/ReportIncidentButton"
import ParentTaskCard from "../me/parentTasks/ParentTaskCard"
import { useDisplayParentTaskSection } from "../me/parentTasks/useDisplayParentTasksSection"
import useParentTasksModalStore from "../me/parentTasks/useParentTasksModalStore"
import useQuestBoardStore from "../questBoard/useQuestBoardStore"

const AcademyActions = () => {
  const { data: incidentTemplates } = useIncidentTemplatesQuery()
  const { data: parentTasks } = useParentTaskQuery()
  const { displayParentTasksSection } = useDisplayParentTaskSection()
  const router = useRouter()
  const { careTeamUserId } = router.query
  const { hasTherapist } = useCareTeam()

  const dataTasks = incidentTemplates?.parent_training_templates.filter(
    // if on a care team user page, only show tasks assigned to that user
    (task) => !careTeamUserId || task.assigner.id === Number(careTeamUserId)
  )
  const quickTherapistTasks = parentTasks?.filter(
    (task) => task.series.assigner_profile?.role === UserRole.THERAPIST
  )

  // Quick todos
  const selectedTherapistProfile =
    quickTherapistTasks?.[0]?.series?.assigner_profile
  const sortedTasks =
    quickTherapistTasks?.sort((task) =>
      completeQuestStatuses.includes(task.status) ? 1 : -1
    ) || []

  const hasRemainingTherapistTasks = !sortedTasks?.every((task) =>
    completeQuestStatuses.includes(task.status)
  )

  const numIncompleteParentQuests =
    parentTasks?.filter(
      (task) =>
        task.series.assigner_profile?.role === UserRole.PARENT &&
        !completeQuestStatuses.includes(task.status)
    ).length || 0

  if (!displayParentTasksSection) return <></>

  return (
    <FlexBox direction="column" gap={SPACING.space2}>
      {careTeamUserId ? (
        <>
          {/* TODO: Should we only be showing this ReportIncidentButton if there are child action templates? */}
          {hasTherapist && (
            <div className="tour-step-3" style={{ width: "100%" }}>
              <ReportIncidentButton />
            </div>
          )}
          {dataTasks?.length === 1 ? (
            <DataTaskCard dataTask={dataTasks[0]} />
          ) : (
            dataTasks && dataTasks?.length > 1 && <DataTasksCard />
          )}
          {sortedTasks.length === 1 ? (
            <ParentTaskCard task={sortedTasks[0]} />
          ) : (
            hasRemainingTherapistTasks &&
            selectedTherapistProfile && (
              <ParentTasksCard
                therapistId={selectedTherapistProfile?.user_id}
              />
            )
          )}
        </>
      ) : (
        <>
          {numIncompleteParentQuests > 0 && (
            <ParentQuestsCard
              numIncompleteParentQuests={numIncompleteParentQuests}
            />
          )}
          {/* TODO: Should we only be showing this ReportIncidentButton if there are child action templates? */}
          {hasTherapist && <TherapistTasksCard />}
          {hasTherapist && (
            <div style={{ width: "100%" }}>
              <ReportIncidentButton />
            </div>
          )}
        </>
      )}
    </FlexBox>
  )
}

export default AcademyActions

const DataTasksCard = () => {
  const { onOpen: openDataTasksModal } = useDataTasksModalStore()

  return (
    <Card
      title="Practice tasks"
      buttonComponent={<ArrowCircleRightIcon />}
      onClick={() => openDataTasksModal()}
    />
  )
}

const ParentTasksCard = ({ therapistId }: { therapistId: number }) => {
  const { onOpen: openParentTasksModal } = useParentTasksModalStore()

  return (
    <Card
      title="Quick to-dos"
      buttonComponent={<ArrowCircleRightIcon />}
      onClick={() => {
        openParentTasksModal(therapistId)
        trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_PARENT_TASKS_SHEET)
      }}
    />
  )
}

const ParentQuestsCard = ({
  numIncompleteParentQuests,
}: {
  numIncompleteParentQuests: number
}) => {
  const router = useRouter()
  const { user } = useUserQuery()
  const { setSelectedUserId } = useQuestBoardStore()

  if (!user) return null

  return (
    <Card
      title={
        <FlexBox direction="row" align="center" gap={SPACING.space2}>
          <img
            src={getParentAvatarImage(user.avatar) || ""}
            style={{
              borderRadius: "50%",
              width: SPACING.space6,
              height: SPACING.space6,
            }}
            alt="avatar"
          />
          <Typography variant="bodySmall">
            {numIncompleteParentQuests} incomplete quests
          </Typography>
        </FlexBox>
      }
      buttonComponent={
        <FontAwesomeIcon
          icon={faChevronRight}
          color={JoonUIColor.icon.neutral}
        />
      }
      onClick={() => {
        setSelectedUserId(user.id)
        router.push("/quests")
        trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_PARENT_TASKS_SHEET)
      }}
      type="secondary"
    />
  )
}

const TherapistTasksCard = () => {
  const router = useRouter()
  const { user } = useUserQuery()
  const { firstTherapist } = useCareTeam()
  const { data: incidentTemplates } = useIncidentTemplatesQuery()
  const dataTasks = incidentTemplates?.parent_training_templates || []
  const { data: quests } = useReorderQuestsQuery()
  const numTasksFromTherapist =
    quests?.filter(
      (quest) =>
        quest?.assigner_profile?.role === UserRole.THERAPIST &&
        quest.assigner_profile?.user_id === user?.id
    ).length || 0
  const numTasksFromData = dataTasks.length + numTasksFromTherapist

  if (numTasksFromData === 0) return null

  if (!firstTherapist) return null

  return (
    <Card
      title={
        <FlexBox direction="row" align="center" gap={SPACING.space2}>
          <TherapistIcon color={JoonUIColor.icon.accent} size={24} />
          <Typography variant="bodySmall">
            {numTasksFromData} tasks from your therapist
          </Typography>
        </FlexBox>
      }
      buttonComponent={
        <FontAwesomeIcon
          icon={faChevronRight}
          color={JoonUIColor.icon.neutral}
        />
      }
      onClick={() => {
        router.push(`/care/${firstTherapist.user.id}/academy`)
      }}
      type="secondary"
    />
  )
}
