import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"

import useDataTaskCompletionModalStore from "./useDataTaskCompletionModalStore"
import useDataTasksModalStore from "./useDataTasksModalStore"
import ArrowCircleRightIcon from "../../../components/icons/ArrowCircleRightIcon"
import { DataTaskIncidentTemplate } from "../../../types"

const DataTaskListItem = ({
  dataTask,
  isLastItem,
}: {
  dataTask: DataTaskIncidentTemplate
  isLastItem: boolean
}) => {
  const { onOpen: openDataTaskCompletionModal } =
    useDataTaskCompletionModalStore()
  const { onClose: closeDataTasksModal } = useDataTasksModalStore()

  const onClick = () => {
    openDataTaskCompletionModal(dataTask.id)
    closeDataTasksModal()
  }
  return (
    <button
      style={{ padding: 0, width: "100%" }}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
    >
      <FlexBox
        direction="row"
        wrap={false}
        align="center"
        justify="space-between"
        style={{
          padding: `${SPACING.space3} ${SPACING.space4}`,
          borderBottom: isLastItem
            ? "none"
            : `1px solid ${JoonUIColor.border.default}`,
        }}
      >
        <Typography variant="bodyBold">{dataTask.title}</Typography>
        <ArrowCircleRightIcon />
      </FlexBox>
    </button>
  )
}

export default DataTaskListItem
