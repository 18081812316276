import { useMutation } from "@tanstack/react-query"
import toast from "react-hot-toast"

import {
  createCustomReward,
  deleteCustomReward,
  editCustomReward,
  purchaseCustomReward,
  purchaseShopItem,
  redeemCustomReward,
  undoCustomRewardLog,
} from "./api"
import { QUERY_KEYS } from "../../constants"
import { queryClient } from "../../util/queryClient"
import { editParentPasscode } from "../users/api"

export const useEditRewardMutation = () =>
  useMutation({
    mutationFn: editCustomReward,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARDS])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_LOGS])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_BALANCES])
      queryClient.invalidateQueries([QUERY_KEYS.SCREEN_TIME_BALANCES])
      toast.success("Reward updated successfully!")
    },
  })

export const useAddRewardMutation = () =>
  useMutation({
    mutationFn: createCustomReward,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_LOGS])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_BALANCES])
      queryClient.invalidateQueries([QUERY_KEYS.SCREEN_TIME_BALANCES])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARDS])
    },
  })

export const usePurchaseRewardMutation = () =>
  useMutation({
    mutationFn: purchaseCustomReward,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_LOGS])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_BALANCES])
      queryClient.invalidateQueries([QUERY_KEYS.SCREEN_TIME_BALANCES])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARDS])
      queryClient.invalidateQueries([QUERY_KEYS.FAMILY])
    },
  })

export const useRedeemRewardMutation = () =>
  useMutation({
    mutationFn: redeemCustomReward,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_LOGS])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_BALANCES])
      queryClient.invalidateQueries([QUERY_KEYS.SCREEN_TIME_BALANCES])
    },
  })

export const useDeleteRewardMutation = () =>
  useMutation({
    mutationFn: deleteCustomReward,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARDS])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_BALANCES])
      queryClient.invalidateQueries([QUERY_KEYS.SCREEN_TIME_BALANCES])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_LOGS])
      queryClient.invalidateQueries([QUERY_KEYS.FAMILY])
      toast.success("Reward deleted successfully!")
    },
  })

export const useUndoCustomRewardLogMutation = () =>
  useMutation({
    mutationFn: undoCustomRewardLog,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_LOGS])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_BALANCES])
      queryClient.invalidateQueries([QUERY_KEYS.SCREEN_TIME_BALANCES])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARDS])
      queryClient.invalidateQueries([QUERY_KEYS.FAMILY])
    },
  })

export const useEditParentPasscodeMutation = () =>
  useMutation({
    mutationFn: editParentPasscode,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.USER])
      queryClient.invalidateQueries([QUERY_KEYS.FAMILY])
    },
  })

export const usePurchaseShopItemMutation = () =>
  useMutation({
    mutationFn: purchaseShopItem,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.INVENTORY])
      queryClient.invalidateQueries([QUERY_KEYS.SHOP_ITEMS])
      queryClient.invalidateQueries([QUERY_KEYS.USER])
      queryClient.invalidateQueries([QUERY_KEYS.FAMILY])
    },
  })
