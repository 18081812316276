import {
  ChevronRightIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"
import { isAndroid, isChrome, isMobile } from "react-device-detect"

import PageLoader from "../../../components/loading/PageLoader"
import { useAddToHomescreenModal } from "../../../components/modals/AddToHomescreenModal"
import { useIOSModal } from "../../../components/modals/IOSModal"
import { useWelcomeModalStore } from "../../../components/modals/WelcomeModal"
import { CoachPaywallChoice } from "../../../constants"
import {
  useRecommendedCoachesQuery,
  useRecommendedCoachesSummaryQuery,
} from "../../../networking/coaching/queries"
import { CoachingStep } from "../../../types/coachingGroups"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { cohorts, experimentNames } from "../../../util/experiments"
import { useGetExperimentCohort } from "../../../util/experiments"
import { useRouter } from "../../../util/router"
import { localStorageItems } from "../../../util/storage"
import { useCoachingStore } from "../useCoachingStore"
import useIsFromMOF from "../useIsFromMOF"

function CoachSummary() {
  const router = useRouter()
  const { data: coachSummary, isLoading: isLoadingCoachSummary } =
    useRecommendedCoachesSummaryQuery()
  const { selectedCoach, setSelectedCoach, setSelectedCoachIndex, setStep } =
    useCoachingStore()
  const isOnMobileChrome = isChrome && isMobile && isAndroid
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()
  const { onOpen: openIOSModal } = useIOSModal()
  const { data: recommendedCoaches, isLoading: isLoadingCoaches } =
    useRecommendedCoachesQuery()
  const isFromMOF = useIsFromMOF()
  const doterAICohort = useGetExperimentCohort(experimentNames.doterChatFTUX)
  const { onOpen: openWelcomeModal } = useWelcomeModalStore()

  const onSkipClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_SKIP_CLICK, {
      step: CoachingStep.COACH_SUMMARY,
      is_tof: !isFromMOF,
    })
    if (
      localStorage.getItem(localStorageItems.planChoiceFromPaywall) ===
        CoachPaywallChoice.WITH_COACH ||
      localStorage.getItem(localStorageItems.planChoiceFromPaywall) ===
        CoachPaywallChoice.EXIT_WITH_COACH
    ) {
      router.push("/paywall/trial")
    } else {
      localStorage.removeItem(localStorageItems.planChoiceFromPaywall)
      router.push("/me")
      if (isFromMOF) return
      if (doterAICohort === cohorts.variantA) {
        openWelcomeModal()
      } else {
        if (isOnMobileChrome) openAddToHomescreenModal()
        else openIOSModal()
      }
    }
  }

  const onSeeCoachesClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_CONTINUE_CLICK, {
      step: CoachingStep.COACH_SUMMARY,
      is_tof: !isFromMOF,
    })
    setStep(CoachingStep.RECOMMENDED_COACHES)
  }

  useEffect(() => {
    if (recommendedCoaches?.[0] && !selectedCoach) {
      setSelectedCoach(recommendedCoaches[0])
      setSelectedCoachIndex(0)
    }
  }, [
    recommendedCoaches,
    setSelectedCoach,
    setSelectedCoachIndex,
    selectedCoach,
  ])

  useEffect(() => {
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_VIEW_QUESTIONNAIRE_SUMMARY, {
      is_tof: !isFromMOF,
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoadingCoachSummary || isLoadingCoaches) return <PageLoader />

  const isFromCoachOnboarding = localStorage.getItem(
    localStorageItems.isFromCoachOnboarding
  )

  return (
    <FlexBox
      direction="column"
      align="center"
      justify="center"
      fullWidth
      gap={SPACING.space4}
      wrap={false}
      style={{ marginBottom: 0 }}
    >
      <FlexBox
        direction="column"
        align="center"
        justify="center"
        fullWidth
        gap={SPACING.space6}
        wrap={false}
      >
        <Typography
          variant="h2"
          color={JoonUIColor.text.primary}
          style={{ width: "min(100%, 350px)" }}
        >
          Thanks for sharing your parenting experience
        </Typography>
        <Typography variant="bodyBold" color={JoonUIColor.text.primary}>
          Does this capture how you're feeling?
        </Typography>
        <FlexBox
          style={{
            border: `1px solid ${JoonUIColor.border.accent}`,
            borderRadius: SPACING.space2,
            width: "100%",
            maxHeight: "412px",
            backgroundColor: JoonUIColor.background.primaryNeutral,
            overflow: "auto",
            padding: SPACING.space4,
          }}
        >
          <Typography
            variant="body"
            color={JoonUIColor.text.primary}
            textAlign="left"
          >
            {coachSummary || "No summary found"}
          </Typography>
        </FlexBox>
        <Typography variant="bodyBold" color={JoonUIColor.text.primary}>
          If this resonates with you, we can help!
        </Typography>
        <Typography
          variant="bodySmall"
          color={JoonUIColor.text.primary}
          style={{ zIndex: 1 }}
        >
          Our coaches specialize in helping parents navigate exactly these kinds
          of challenges. We've selected a few that will match your situation
          best.
        </Typography>
      </FlexBox>
      <button
        onClick={onSeeCoachesClick}
        style={{
          width: "100%",
          position: "sticky",
          bottom: SPACING.space2,
          zIndex: 100,
        }}
      >
        <FlexBox
          fullWidth
          justify="space-between"
          style={{
            background: JoonUIColor.background.accent,
            boxShadow: `4px 4px 4px 0px rgba(0, 0, 0, 0.08)`,
            borderRadius: SPACING.space3,
            padding: SPACING.space4,
          }}
        >
          <Typography variant="bodyBold" color={JoonUIColor.text.inverted}>
            See your matched coaches
          </Typography>
          <ChevronRightIcon size={24} color={JoonUIColor.text.inverted} />
        </FlexBox>
      </button>
      {isFromCoachOnboarding !== "true" && (
        <TextButton onClick={onSkipClick} style={{ zIndex: 1 }}>
          <Typography
            variant="bodySmall"
            color={JoonUIColor.text.primaryAccent}
          >
            No thanks, this doesn't resonate with me
          </Typography>
        </TextButton>
      )}
    </FlexBox>
  )
}

export default CoachSummary
