import {
  ArrowRightIcon,
  capitalizeFirstLetter,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  RotateLeftIcon,
  SPACING,
  TextButton,
  TherapistIcon,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"
import Skeleton from "react-loading-skeleton"

import AcademyActions from "./AcademyActions"
import useTrainingStore from "./training/useTrainingStore"
import { QUERY_KEYS } from "../../constants"
import { queryClient } from "../../util/queryClient"
import { useRouter } from "../../util/router"
import CareTeamAvatar from "../care/CareTeamAvatar"
import useCareTeam from "../care/useCareTeam"

const AcademyPageHeader = () => {
  const [isHoveredArrowIcon, setIsHoveredArrowIcon] = useState(false)
  const [isHoveredRefreshIcon, setIsHoveredRefreshIcon] = useState(false)
  const { setIsRefreshing } = useTrainingStore()
  const router = useRouter()

  const { selectedTherapistProfile } = useCareTeam()

  const displayName = selectedTherapistProfile?.nickname
    ? selectedTherapistProfile.nickname
    : `${selectedTherapistProfile?.user.name} ${selectedTherapistProfile?.user.last_name || ""}`

  if (!selectedTherapistProfile)
    return (
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        wrap={false}
        style={{
          background: JoonColorExpanded.purple600,
          width: "min(600px, 100%)",
          padding: SPACING.space4,
          borderRadius: `0 0 ${SPACING.space4} ${SPACING.space4}`,
        }}
      >
        <TextButton
          onClick={() => router.push("/care")}
          style={{
            borderRadius: "50%",
            padding: SPACING.space1,
            transform: "rotate(180deg)",
          }}
          onMouseEnter={() => setIsHoveredArrowIcon(true)}
          onMouseLeave={() => setIsHoveredArrowIcon(false)}
        >
          <ArrowRightIcon
            size={24}
            color={
              isHoveredArrowIcon
                ? JoonUIColor.background.lightGray
                : JoonUIColor.icon.inverted
            }
          />
        </TextButton>
        <Skeleton />
      </FlexBox>
    )

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space2}
      wrap={false}
      style={{
        background: JoonColorExpanded.purple600,
        width: "min(600px, 100%)",
        padding: SPACING.space4,
        borderRadius: `0 0 ${SPACING.space4} ${SPACING.space4}`,
      }}
    >
      <FlexBox
        direction="row"
        align="center"
        justify="space-between"
        gap={SPACING.space2}
        wrap={false}
      >
        <TextButton
          onClick={() => router.push("/care")}
          style={{
            borderRadius: "50%",
            padding: SPACING.space1,
            transform: "rotate(180deg)",
          }}
          onMouseEnter={() => setIsHoveredArrowIcon(true)}
          onMouseLeave={() => setIsHoveredArrowIcon(false)}
        >
          <ArrowRightIcon
            size={24}
            color={
              isHoveredArrowIcon
                ? JoonUIColor.background.lightGray
                : JoonUIColor.icon.inverted
            }
          />
        </TextButton>

        <CareTeamAvatar profile={selectedTherapistProfile} />

        <FlexBox
          direction="column"
          gap={SPACING.space05}
          wrap={false}
          style={{ textAlign: "left" }}
        >
          <Typography variant="bodyBold" color={JoonUIColor.text.inverted}>
            {displayName}
          </Typography>
          <Typography
            variant="bodyXSmallBold"
            color={JoonUIColor.text.inverted}
          >
            {capitalizeFirstLetter(selectedTherapistProfile?.role)}
          </Typography>
        </FlexBox>

        <TextButton
          onClick={async () => {
            setIsRefreshing(true)
            await Promise.all([
              queryClient.invalidateQueries([QUERY_KEYS.CARE_TEAM]),
              queryClient.invalidateQueries([QUERY_KEYS.PARENT_TASKS]),
              queryClient.invalidateQueries([QUERY_KEYS.TRAININGS]),
            ])
            setIsRefreshing(false)
          }}
          style={{
            borderRadius: "50%",
            padding: SPACING.space0,
          }}
          onMouseEnter={() => setIsHoveredRefreshIcon(true)}
          onMouseLeave={() => setIsHoveredRefreshIcon(false)}
        >
          <FlexBox
            direction="row"
            align="center"
            gap={SPACING.space1}
            wrap={false}
          >
            <Typography
              variant="bodyXSmallBold"
              color={
                isHoveredRefreshIcon
                  ? JoonUIColor.background.lightGray
                  : JoonUIColor.icon.inverted
              }
            >
              Refresh
            </Typography>
            <RotateLeftIcon
              size={20}
              color={
                isHoveredRefreshIcon
                  ? JoonUIColor.background.lightGray
                  : JoonUIColor.icon.inverted
              }
            />
          </FlexBox>
        </TextButton>
      </FlexBox>

      <FlexBox direction="row" wrap={false} gap={SPACING.space4}>
        <FlexBox wrap={false} align="center" gap={SPACING.space1}>
          <TherapistIcon size={24} color={JoonColorExpanded.purple600} />
          <Typography variant="bodyBold" color={JoonUIColor.text.inverted}>
            Daily to-dos
          </Typography>
        </FlexBox>
      </FlexBox>
      <AcademyActions />
    </FlexBox>
  )
}

export default AcademyPageHeader
