import { Typography, JoonUIColor, SPACING, FlexBox } from "@joonapp/web-shared"

import OnboardingPageContentWrapper from "./onboardingPageContentWrapper/OnboardingPageContentWrapper"
import useMediaQuery from "../../hooks/useMediaQuery"
import useOnboardContext, {
  OnboardingStep,
} from "../../hooks/useOnboardContext"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"
import { localStorageItems } from "../../util/storage"
import ChevronBackButton from "../buttons/ChevronBackButton"
import Primary3DButton from "../buttons/Primary3DButton"
import SecondaryTransparentButton from "../buttons/SecondaryTransparentButton"

const OnboardAddChildOrJoinFamily = () => {
  const { setStep, previousStep, setIsCoparent } = useOnboardContext()
  const router = useRouter()
  const isSmallMobile = useMediaQuery("(max-width: 500px)")
  const onBack = () => {
    if (!localStorage.getItem(localStorageItems.therapistCode)) previousStep()
    else router.push("/invite")
  }

  return (
    <OnboardingPageContentWrapper
      backgroundImageOnDesktop="url(/images/background/cloud-bg.png)"
      paddingTop="10dvh"
      style={{ position: "relative" }}
    >
      <ChevronBackButton onClick={onBack} />
      <FlexBox
        direction="column"
        align="center"
        justify="space-between"
        gap={SPACING.space4}
        wrap={false}
        style={{
          width: "100%",
          height: "100%",
          textAlign: "center",
        }}
      >
        <FlexBox
          direction="column"
          align="center"
          gap={SPACING.space8}
          wrap={false}
        >
          <img
            src="/images/gifs/onboarding-tree-clouds-gif.gif"
            alt="tree"
            style={{ width: isSmallMobile ? "160px" : "200px" }}
          />
          <FlexBox direction="column" align="center" gap={SPACING.space2}>
            <Typography variant="h2Serif">Up next:</Typography>
            <Typography variant="h2Serif" style={{ width: "70%" }}>
              Let's add your family information!
            </Typography>
          </FlexBox>
        </FlexBox>
        <FlexBox
          direction="column"
          align="center"
          gap={SPACING.space3}
          wrap={false}
          fullWidth
        >
          <Typography variant="bodySmall">
            You can always add another child later!
          </Typography>
          <Primary3DButton
            text="Continue to add child info"
            fullWidth
            onClick={() => {
              setIsCoparent(false)
              localStorage.removeItem(localStorageItems.isCoparent)
              trackAnalyticEvent(ANALYTIC_EVENTS.CONTINUE_ADD_CHILDREN)
              setStep(OnboardingStep.AddChildren)
            }}
          />
          <SecondaryTransparentButton
            fullWidth
            onClick={() => {
              setIsCoparent(true)
              trackAnalyticEvent(ANALYTIC_EVENTS.HAS_FAMILY_CODE)
              setStep(OnboardingStep.CoparentCode)
            }}
            text="Join an existing family"
          />
          <button
            onClick={() => {
              router.push("/auth/signin")
              trackAnalyticEvent(ANALYTIC_EVENTS.HAS_ACCOUNT)
            }}
            style={{
              width: "100%",
              border: "none",
              background: "none",
              padding: SPACING.space2,
              margin: 0,
            }}
          >
            <Typography variant="body" color={JoonUIColor.semantic.primary}>
              Sign in to an existing account
            </Typography>
          </button>
          <Typography variant="bodyXSmall" textAlign="center">
            Your answers are never shared with anyone without your consent.
          </Typography>
        </FlexBox>
      </FlexBox>
    </OnboardingPageContentWrapper>
  )
}

export default OnboardAddChildOrJoinFamily
