import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  Checkbox,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SPACING,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import { useTOSSigningModalStore } from "./useTOSSigningModalStore"
import { PhoneNumberInput } from "../../components/phoneNumberInput/PhoneNumberInput"
import { QUERY_KEYS } from "../../constants"
import { signAgreement } from "../../networking/agreementLinks/api"
import { useAgreementLinksQuery } from "../../networking/agreementLinks/queries"
import { useUserQuery } from "../../networking/queries"
import { addEmergencyContact } from "../../networking/user"
import { addUpdateUserAddress } from "../../networking/users/api"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { queryClient } from "../../util/queryClient"
import { localStorageItems } from "../../util/storage"
import { isValidPhoneNumber } from "../../util/util"
import useEditParentMutation from "../settings/useEditParentMutation"

export const TOSSigningModal = () => {
  const { isOpen, onClose, showFormContent, setShowFormContent } =
    useTOSSigningModalStore()
  const { data: agreementLinks } = useAgreementLinksQuery()

  if (!agreementLinks) return null

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      mobileFull
      style={{ minHeight: "95vh", width: "min(500px, 100vw)" }}
    >
      {showFormContent ? (
        <TOSFormContent />
      ) : (
        <>
          <ModalHeader
            title="Sign coaching agreement"
            onClose={onClose}
            showBorderBottom
            style={{ width: "100%" }}
          />
          <ModalContent style={{ display: "flex", flex: 1 }}>
            <iframe
              src={agreementLinks[0]?.link}
              style={{ border: "0", flex: 1, width: "100%" }}
              title="Joon Coaching Agreement"
            />
          </ModalContent>
          <ModalFooter>
            <FlexBox
              direction="column"
              gap={SPACING.space2}
              style={{
                width: "100%",
                padding: SPACING.space4,
                borderTop: `1px solid ${JoonUIColor.border.default}`,
                backgroundColor: JoonUIColor.background.xlightGray,
              }}
            >
              <Button
                text="Sign agreement"
                onClick={() => setShowFormContent(true)}
                buttonType="primary"
                fullWidth
                style={{ borderRadius: SPACING.space2 }}
              />
            </FlexBox>
          </ModalFooter>
        </>
      )}
    </Modal>
  )
}

const TOSFormContent = () => {
  const [error, setError] = useState<string | null>(null)

  const {
    setShowFormContent,
    isAgreementAccepted,
    setIsAgreementAccepted,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    onClose,
    month,
    setMonth,
    day,
    setDay,
    year,
    setYear,
    streetAddress,
    setStreetAddress,
    addressLine2,
    setAddressLine2,
    city,
    setCity,
    stateProvinceRegion,
    setStateProvinceRegion,
    zipPostalCode,
    setZipPostalCode,
    country,
    setCountry,
    emergencyContactFirstName,
    setEmergencyContactFirstName,
    emergencyContactLastName,
    setEmergencyContactLastName,
    emergencyContactPhoneNumber,
    setEmergencyContactPhoneNumber,
  } = useTOSSigningModalStore()

  const { user } = useUserQuery()
  const { data: agreementLinks } = useAgreementLinksQuery()
  const [isHoveredCheckbox, setIsHoveredCheckbox] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const editParentMutation = useEditParentMutation()

  useEffect(() => {
    if (firstName.length === 0 || lastName.length === 0) {
      setFirstName(user?.name ?? "")
      setLastName(user?.last_name ?? "")
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!agreementLinks) return null

  const onChangeZipPostalCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value.match(/^[a-zA-Z0-9\s-]*$/)) return
    setZipPostalCode(e.target.value)
  }

  const onChangeDateOfBirth = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/[^0-9]/g, "")
    if (value.length > 2) {
      value = value.slice(0, 2) + "/" + value.slice(2)
    }
    if (value.length > 5) {
      value = value.slice(0, 5) + "/" + value.slice(5)
    }
    const [newMonth, newDay, newYear] = value.split("/")
    setMonth(newMonth || "")
    setDay(newDay || "")
    setYear(newYear || "")
  }

  const handleSubmit = async () => {
    if (!user) return

    // Validate each field with specific error messages
    if (!isAgreementAccepted)
      return setError("Please agree to the coaching agreement")
    if (!firstName) return setError("Please enter your first name")
    if (!lastName) return setError("Please enter your last name")
    if (!streetAddress) return setError("Please enter your street address")
    if (!city) return setError("Please enter your city")
    if (!stateProvinceRegion)
      return setError("Please enter your state/province/region")
    if (!zipPostalCode) return setError("Please enter your zip/postal code")
    if (!country) return setError("Please enter your country")
    if (!emergencyContactFirstName)
      return setError("Please enter an emergency contact name")
    if (!isValidPhoneNumber(emergencyContactPhoneNumber))
      return setError("Please enter a valid emergency contact phone number")

    setError(null)
    setIsLoading(true)
    try {
      const birthdate =
        year.length === 4 && month.length === 2 && day.length === 2
          ? `${year}-${month}-${day}`
          : undefined
      // Update user address
      await addUpdateUserAddress({
        addressInfo: {
          address_line_1: streetAddress,
          address_line_2: addressLine2 || null,
          city: city,
          state: stateProvinceRegion,
          postal: zipPostalCode,
          country: country,
        },
        userId: user.id,
      })
      // Add emergency contact
      await addEmergencyContact({
        name: emergencyContactFirstName,
        lastName: emergencyContactLastName || undefined,
        phoneNumber: emergencyContactPhoneNumber,
      })
      // Sign agreement
      await signAgreement(agreementLinks[0].id)
      // Update user with first name, last name, birthdate
      await editParentMutation.mutateAsync({
        parentInfo: {
          name: firstName,
          last_name: lastName,
          birthdate,
        },
        userId: user?.id,
      })
      await queryClient.invalidateQueries([QUERY_KEYS.AGREEMENT_LINKS])
      queryClient.invalidateQueries([QUERY_KEYS.USER])
      trackAnalyticEvent(ANALYTIC_EVENTS.COMPLETE_COACHING_TOS)
      onClose()
      localStorage.removeItem(localStorageItems.lastSeenSessionBookingOverlay)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <FlexBox
        direction="column"
        justify="flex-start"
        gap={SPACING.space4}
        wrap={false}
        style={{
          height: "100%",
          overflowY: "auto",
          backgroundColor: JoonUIColor.background.xlightGray,
        }}
      >
        <button
          onClick={() => setShowFormContent(false)}
          style={{
            width: "100%",
            padding: SPACING.space4,
            paddingBottom: 0,
            marginRight: "auto",
          }}
        >
          <FlexBox
            direction="row"
            justify="flex-start"
            align="center"
            wrap={false}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              size="lg"
              color={JoonUIColor.text.secondary}
              style={{ marginRight: SPACING.space2 }}
            />
            <Typography
              variant="bodySmallBold"
              color={JoonUIColor.text.secondary}
              style={{ whiteSpace: "nowrap" }}
            >
              Return to agreement
            </Typography>
          </FlexBox>
        </button>

        <FlexBox
          direction="column"
          gap={SPACING.space2}
          wrap={false}
          style={{ padding: SPACING.space4, paddingTop: 0 }}
        >
          <FlexBox
            direction="row"
            wrap={false}
            justify="space-between"
            style={{ width: "calc(100%)", marginBottom: SPACING.space2 }}
          >
            <FlexBox
              direction="column"
              gap={SPACING.space1}
              style={{ width: "49%" }}
            >
              <Typography variant="bodySmall">First name</Typography>
              <TextInput
                name="first-name"
                placeholder="First name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                fullWidth
                style={{ minWidth: 0 }}
              />
            </FlexBox>
            <FlexBox
              direction="column"
              gap={SPACING.space1}
              style={{ width: "49%" }}
            >
              <Typography variant="bodySmall">Last name</Typography>
              <TextInput
                name="last-name"
                placeholder="Last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                fullWidth
                style={{ minWidth: 0 }}
              />
            </FlexBox>
          </FlexBox>

          <FlexBox direction="column" gap={SPACING.space1} fullWidth>
            <Typography variant="bodySmall">
              Date of birth (optional)
            </Typography>

            <TextInput
              name="date-of-birth"
              placeholder="MM/DD/YYYY"
              value={`${month}${day ? "/" + day : ""}${year ? "/" + year : ""}`}
              onChange={onChangeDateOfBirth}
              maxLength={10}
              fullWidth
            />
          </FlexBox>

          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="bodySmall">Street address</Typography>
            <TextInput
              name="street-address"
              placeholder="Street address"
              value={streetAddress}
              onChange={(e) => setStreetAddress(e.target.value)}
              fullWidth
              style={{ minWidth: 0 }}
            />
          </FlexBox>

          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="bodySmall">Address line 2</Typography>
            <TextInput
              name="address-line-2"
              placeholder="Address line 2"
              value={addressLine2}
              onChange={(e) => setAddressLine2(e.target.value)}
              fullWidth
              style={{ minWidth: 0 }}
            />
          </FlexBox>

          <FlexBox direction="row" gap={SPACING.space2} wrap={false}>
            <FlexBox
              direction="column"
              gap={SPACING.space1}
              style={{ width: "49%" }}
            >
              <Typography variant="bodySmall">City</Typography>
              <TextInput
                name="city"
                placeholder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                fullWidth
                style={{ minWidth: 0 }}
              />
            </FlexBox>
            <FlexBox
              direction="column"
              gap={SPACING.space1}
              style={{ width: "49%" }}
            >
              <Typography variant="bodySmall">State/Province/Region</Typography>
              <TextInput
                name="state-province-region"
                placeholder="State/Province/Region"
                value={stateProvinceRegion}
                onChange={(e) => setStateProvinceRegion(e.target.value)}
                fullWidth
                style={{ minWidth: 0 }}
              />
            </FlexBox>
          </FlexBox>

          <FlexBox direction="row" gap={SPACING.space2} wrap={false}>
            <FlexBox
              direction="column"
              gap={SPACING.space1}
              style={{ width: "49%" }}
            >
              <Typography variant="bodySmall">Zip/Postal code</Typography>
              <TextInput
                name="zip-postal-code"
                placeholder="Zip/Postal code"
                value={zipPostalCode}
                onChange={onChangeZipPostalCode}
                fullWidth
                style={{ minWidth: 0 }}
              />
            </FlexBox>
            <FlexBox
              direction="column"
              gap={SPACING.space1}
              style={{ width: "49%" }}
            >
              <Typography variant="bodySmall">Country</Typography>
              <TextInput
                name="country"
                placeholder="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                fullWidth
                style={{ minWidth: 0 }}
              />
            </FlexBox>
          </FlexBox>

          <div
            style={{
              margin: `${SPACING.space2} 0`,
              width: "100%",
              borderBottom: `1px solid ${JoonUIColor.border.default}`,
            }}
          />

          <Typography variant="bodyBold">Emergency contact</Typography>

          <FlexBox
            direction="row"
            wrap={false}
            justify="space-between"
            style={{ width: "calc(100%)", marginBottom: SPACING.space2 }}
          >
            <FlexBox
              direction="column"
              gap={SPACING.space1}
              style={{ width: "49%" }}
            >
              <Typography variant="bodySmall">First name</Typography>
              <TextInput
                name="emergency-contact-first-name"
                placeholder="First name"
                value={emergencyContactFirstName}
                onChange={(e) => setEmergencyContactFirstName(e.target.value)}
                fullWidth
                style={{ minWidth: 0 }}
              />
            </FlexBox>
            <FlexBox
              direction="column"
              gap={SPACING.space1}
              style={{ width: "49%" }}
            >
              <Typography variant="bodySmall">Last name (optional)</Typography>
              <TextInput
                name="emergency-contact-last-name"
                placeholder="Last name"
                value={emergencyContactLastName}
                onChange={(e) => setEmergencyContactLastName(e.target.value)}
                fullWidth
                style={{ minWidth: 0 }}
              />
            </FlexBox>
          </FlexBox>

          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="bodySmall">
              Emergency contact phone number
            </Typography>
            <PhoneNumberInput
              initialPhoneNumber={emergencyContactPhoneNumber}
              setPhoneNumber={setEmergencyContactPhoneNumber}
              style={{
                backgroundColor: JoonUIColor.background.primaryNeutral,
                height: SPACING.space11,
              }}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        wrap={false}
        style={{
          width: "100%",
          padding: SPACING.space4,
          boxShadow: `0 -1px 4px 0 ${JoonUIColor.border.default}`,
          backgroundColor: JoonUIColor.background.primaryNeutral,
        }}
      >
        <FlexBox
          direction="row"
          gap={SPACING.space2}
          align="center"
          wrap={false}
          style={{
            borderRadius: SPACING.space2,
            backgroundColor: JoonUIColor.background.lightGray,
            padding: SPACING.space2,
            cursor: "pointer",
            border: isHoveredCheckbox
              ? `1px solid ${JoonUIColor.border.default}`
              : "1px solid transparent",
          }}
          onClick={() => setIsAgreementAccepted(!isAgreementAccepted)}
          onMouseEnter={() => setIsHoveredCheckbox(true)}
          onMouseLeave={() => setIsHoveredCheckbox(false)}
        >
          <Checkbox
            name="agree-to-coaching-agreement"
            selected={isAgreementAccepted}
            onChange={() => {}}
            hideBorder
          />
          <Typography
            variant="bodySmall"
            style={{ lineHeight: 1, userSelect: "none" }}
          >
            I have read the Coaching Consent Form and agree to the conditions of
            coaching.
          </Typography>
        </FlexBox>
        {error && (
          <Typography variant="bodySmall" color={JoonUIColor.semantic.alert}>
            {error}
          </Typography>
        )}
        <Button
          text="Submit"
          onClick={handleSubmit}
          buttonType="primary"
          fullWidth
          style={{ borderRadius: SPACING.space2 }}
          isLoading={isLoading}
        />
      </FlexBox>
    </>
  )
}
