import { CoachProfile } from "@joonapp/web-shared"
import { useQuery } from "@tanstack/react-query"

import {
  getCoachingGroups,
  loadCoachSubscriptionPackages,
  loadRecommendedCoaches,
  loadRecommendedCoachesSummary,
} from "./api"
import { QUERY_KEYS } from "../../constants"
import { SubscriptionPackagesResponse } from "../../types"
import { useUserQuery } from "../queries"
import { getCoachSubscription } from "../subscriptions"

export const useCoachingGroupsQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.COACHING_GROUPS],
    queryFn: getCoachingGroups,
  })
}

export const useRecommendedCoachesQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.COACH_PROFILES],
    queryFn: () => {
      const cachedData = localStorage.getItem("recommended_coaches")
      return cachedData
        ? (JSON.parse(cachedData) as CoachProfile[])
        : loadRecommendedCoaches()
    },
    onSuccess: (data) => {
      localStorage.setItem("recommended_coaches", JSON.stringify(data))
    },
  })
}

export const useRecommendedCoachesSummaryQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.COACH_SUMMARY],
    queryFn: () => loadRecommendedCoachesSummary(),
  })
}

export const useCoachSubscriptionPackagesQuery = () => {
  return useQuery<SubscriptionPackagesResponse>({
    queryKey: [QUERY_KEYS.SUBSCRIPTION_PACKAGES],
    queryFn: loadCoachSubscriptionPackages,
  })
}

export const useGetCoachSubscriptionQuery = () => {
  const { user } = useUserQuery()
  return useQuery({
    queryKey: [QUERY_KEYS.COACH_SUBSCRIPTION],
    queryFn: () => getCoachSubscription(),
    enabled: !!user,
  })
}
