import {
  CircleIcon,
  CoachProfile,
  CoachTagCategory,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  StarIcon,
  Typography,
} from "@joonapp/web-shared/"

import CareTeamAvatar from "../care/CareTeamAvatar"

interface SmallCoachCardProps {
  coachCardContent: CoachProfile
  backgroundColor?: string
}

const SmallCoachCard = ({
  coachCardContent,
  backgroundColor,
}: SmallCoachCardProps) => {
  const { rating, num_ratings, tags } = coachCardContent
  const { name, last_name } = coachCardContent.user
  const credentials = tags
    .filter((tag) => tag.category === CoachTagCategory.CREDENTIALS)
    .map((tag) => tag.name)

  return (
    <FlexBox
      direction="row"
      align="center"
      style={{
        padding: SPACING.space4,
        background: backgroundColor || JoonColorExpanded.yellow100,
        border: `1px solid ${JoonUIColor.border.default}`,
        borderRadius: SPACING.space3,
      }}
      gap={SPACING.space4}
      wrap={false}
    >
      <CareTeamAvatar profile={coachCardContent} size="large" />
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        align="center"
        wrap={false}
      >
        <FlexBox
          direction="row"
          justify="space-between"
          align="center"
          fullWidth
          wrap={false}
        >
          <Typography variant="h3" style={{ textAlign: "left" }}>
            {name} {last_name}
          </Typography>
          <FlexBox
            direction="row"
            align="center"
            justify="flex-end"
            gap={SPACING.space1}
            wrap={false}
            style={{
              width: "fit-content",
              textAlign: "left",
              whiteSpace: "nowrap",
            }}
          >
            <div style={{ width: 18, height: 21 }}>
              <StarIcon size={18} color={JoonColorExpanded.yellow400} />
            </div>
            <Typography variant="bodySmallBold">
              {rating.toFixed(1)} ({num_ratings})
            </Typography>
          </FlexBox>
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space1}>
          {credentials.map((credential) => (
            <FlexBox
              key={credential}
              direction="row"
              align="center"
              gap={SPACING.space1}
              wrap={false}
            >
              <CircleIcon size={4} color={JoonColorExpanded.yellow400} />
              <Typography
                variant="bodyXSmall"
                color={JoonColorExpanded.purple600}
                textAlign="left"
                style={{ whiteSpace: "nowrap" }}
              >
                {credential}
              </Typography>
            </FlexBox>
          ))}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export default SmallCoachCard
