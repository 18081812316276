import {
  Button,
  FlexBox,
  Modal,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { create } from "zustand"

import { CloseButton } from "../../../components/closeButton/CloseButton"

interface RedemptionSuccessModalStoreProps {
  isOpen: boolean
  onOpen: ({
    rewardEmoji,
    rewardTitle,
    screenTimeMins,
    childName,
  }: {
    rewardEmoji: string
    rewardTitle: string
    screenTimeMins?: number
    childName?: string
  }) => void
  onClose: () => void
  rewardEmoji: string
  rewardTitle: string
  screenTimeMins: number
  childName?: string
}

export const useRedemptionSuccessModalStore =
  create<RedemptionSuccessModalStoreProps>((set) => ({
    isOpen: false,
    onOpen: ({ rewardEmoji, rewardTitle, screenTimeMins, childName }) =>
      set({
        isOpen: true,
        rewardEmoji,
        rewardTitle,
        screenTimeMins,
        childName,
      }),
    onClose: () =>
      set({
        isOpen: false,
        rewardEmoji: "",
        rewardTitle: "",
        screenTimeMins: 0,
      }),
    rewardEmoji: "",
    rewardTitle: "",
    screenTimeMins: 0,
    childName: "",
  }))

export const RedemptionSuccessModal = () => {
  const {
    isOpen,
    onClose,
    rewardEmoji,
    rewardTitle,
    screenTimeMins,
    childName,
  } = useRedemptionSuccessModalStore()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{ zIndex: 1000, width: "min(100%, 450px)" }}
      animate
    >
      <FlexBox
        direction="column"
        align="left"
        justify="center"
        style={{ padding: `${SPACING.space4} ${SPACING.space6}` }}
        gap={SPACING.space4}
        fullWidth
      >
        <CloseButton onClick={onClose} />

        <FlexBox direction="column" gap={SPACING.space4}>
          <Typography variant="bodyBold">
            {rewardEmoji} {rewardTitle} redeemed
            {screenTimeMins > 0
              ? ` and is now ready to be activated by ${childName ?? "your child"}`
              : ""}
            !
          </Typography>

          {screenTimeMins > 0 && (
            <>
              <Typography variant="bodySmall">
                {childName ?? "Your child"} now has an extra {screenTimeMins}{" "}
                minute
                {screenTimeMins === 1 ? " " : "s "}
                to access their blocked apps.
              </Typography>
              <Typography variant="bodySmall">
                {childName ?? "Your child"} can go to the Custom Rewards Shop on
                the Joon Kid App to activate their screen time reward. The timer
                will automatically start as soon as they do this and will
                continue until they run out of their available minutes.
              </Typography>
            </>
          )}

          {screenTimeMins === 0 && (
            <Typography variant="bodySmall">
              Remember this means that you already gave{" "}
              {childName ?? "your child"} "{rewardTitle}
              "!
            </Typography>
          )}
        </FlexBox>

        <Button buttonType="primary" text="Okay" onClick={onClose} fullWidth />
      </FlexBox>
    </Modal>
  )
}
