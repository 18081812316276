import { JoonColorExpanded, SPACING, Typography } from "@joonapp/web-shared"
import { CircleCheckboxChecked } from "@joonapp/web-shared"
import { JoonUIColor } from "@joonapp/web-shared"
import { useState } from "react"

import { AnswerInterface } from "../../../types"

interface CoachQuestionnaireAnswerButtonProps {
  isMultiSelected: boolean
  didSelectAnswer: (answer: AnswerInterface) => void
  answer: AnswerInterface
  numberOfSelectedAnswers: number
  delay: boolean
}

function CoachQuestionnaireAnswerButton({
  isMultiSelected,
  didSelectAnswer,
  answer,
  numberOfSelectedAnswers,
  delay,
}: CoachQuestionnaireAnswerButtonProps) {
  const [isSelected, setIsSelected] = useState(false)

  const selectAnswer = () => {
    if (isSelected || (numberOfSelectedAnswers >= 3 && !isMultiSelected)) return

    if (delay) {
      setIsSelected(true)
      setTimeout(function () {
        setIsSelected(false)
        didSelectAnswer(answer)
      }, 350)
    } else didSelectAnswer(answer)
  }

  return (
    <button
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        borderRadius: SPACING.space2,
        padding: SPACING.space3,
        background:
          isSelected || isMultiSelected
            ? JoonColorExpanded.viridian100
            : JoonUIColor.background.primaryNeutral,
        border:
          isSelected || isMultiSelected
            ? `1px solid ${JoonColorExpanded.viridian100}`
            : `1px solid ${JoonUIColor.border.default}`,
        opacity: numberOfSelectedAnswers >= 3 && !isMultiSelected ? 0.5 : 1,
        cursor:
          numberOfSelectedAnswers >= 3 && !isMultiSelected
            ? "not-allowed"
            : "pointer",
        transition: "all 0.2s ease-out",
      }}
      onClick={selectAnswer}
    >
      <Typography variant="body" textAlign="left">
        {answer.answer}
      </Typography>
      {isSelected || isMultiSelected ? (
        <CircleCheckboxChecked
          color={JoonUIColor.background.success}
          size={16}
        />
      ) : (
        <></>
      )}
    </button>
  )
}

export default CoachQuestionnaireAnswerButton
