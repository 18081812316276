import { faGift } from "@fortawesome/duotone-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { JoonUIColor, IconProps } from "@joonapp/web-shared"

const RewardsIcon = ({
  color = JoonUIColor.icon.accent,
  size = 24,
}: IconProps) => {
  return <FontAwesomeIcon icon={faGift} fontSize={size} color={color} />
}

export default RewardsIcon
