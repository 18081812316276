import {
  AuthCode,
  Modal,
  Button,
  Typography,
  FlexBox,
  SPACING,
} from "@joonapp/web-shared"
import { create } from "zustand"

import { useParentAuthCodeQuery } from "../../networking/queries"

interface AddParentModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useAddParentModal = create<AddParentModalStore>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),

  quest: null,
}))

const AddParentModal = () => {
  const { isOpen, onClose } = useAddParentModal()
  const { data: authCode } = useParentAuthCodeQuery()

  const onClickShareCode = () => {
    if (authCode) shareCoparentCode(authCode)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} displayCloseIcon animate>
      <FlexBox
        direction="column"
        align="center"
        style={{
          width: "min(500px, 95vw)",
          padding: SPACING.space6,
          textAlign: "center",
        }}
        gap={SPACING.space4}
      >
        <Typography variant="h2">Add Parent</Typography>
        <Typography variant="bodyBold">
          Share this one-time code with the other parent and they can use it
          when creating an account!
        </Typography>
        <AuthCode code={authCode || ""} />
        <Button
          onClick={onClickShareCode}
          text="Share"
          style={{ width: "200px", margin: "18px auto 0 auto" }}
        />
      </FlexBox>
    </Modal>
  )
}

export default AddParentModal

const shareCoparentCode = async (code: string) => {
  const subjectNew = "Join our family on Joon!"
  const instructionsNew = `Use the code ${code} to join our family on Joon! 
  
https://my.joonapp.io/welcome`

  try {
    // If the web share API is supported by the browser
    if (navigator.share) {
      await navigator.share({
        title: subjectNew,
        text: instructionsNew,
        url: "https://joonapp.io/download-redirect",
      })
    } else {
      // Else open email to share
      const emailSubject = encodeURIComponent(subjectNew)
      const emailBody = encodeURIComponent(instructionsNew)

      const url = `mailto:?subject=${emailSubject}&body=${emailBody}`
      window.open(url)
    }
  } catch (error) {
    console.error("Error sharing:", error)
  }
}
