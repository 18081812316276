import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Checkbox,
  FlexBox,
  JoonUIColor,
  QuestRoutine,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"

import { useSetTimeModalStore } from "./SetTimeModal"
import { useDateTime } from "./useDateTime"
import { capitalizeFirstLetter } from "../../../../util/util"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

export const RoutineSelectButton = ({
  questRoutine,
  index,
}: {
  questRoutine: QuestRoutine
  index: number
}) => {
  const { routine, reminderTimeMap, setReminderTimeMap } =
    useAddEditQuestStore()
  const { onOpen: onOpenSetTimeModal } = useSetTimeModalStore()
  const { onRoutineClick } = useDateTime()

  const onRemoveTimeClick = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation()
    setReminderTimeMap({})
  }

  const isLastElement = index === Object.values(QuestRoutine).length - 1

  return (
    <button
      key={index}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: SPACING.space2,
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: `1px solid ${JoonUIColor.border.default}`,
        ...(isLastElement && { borderBottom: "none" }),
        padding: SPACING.space3,
      }}
      onClick={(e) => onRoutineClick(e, questRoutine)}
    >
      <FlexBox direction="row" align="center" gap={SPACING.space2} wrap={false}>
        <Checkbox
          selected={routine === questRoutine}
          inputType="radio"
          name={questRoutine}
          onChange={() => {}}
          hideBorder
        />
        <Typography variant="bodySmallBold" textAlign="left">
          {capitalizeFirstLetter(questRoutine)}
        </Typography>
      </FlexBox>
      <TextButton
        onClick={(e?: React.MouseEvent<HTMLButtonElement>) => {
          e?.stopPropagation()
          onOpenSetTimeModal(questRoutine)
        }}
        style={{
          width: "fit-content",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: SPACING.space1,
          padding: SPACING.space2,
        }}
      >
        <Typography
          variant="bodyXSmallBold"
          color={
            reminderTimeMap?.[questRoutine]?.length > 0
              ? JoonUIColor.text.primary
              : routine === questRoutine
                ? JoonUIColor.text.secondary
                : JoonUIColor.background.disabled
          }
          style={{ whiteSpace: "nowrap" }}
        >
          {reminderTimeMap?.[questRoutine]?.length > 0
            ? reminderTimeMap[questRoutine]
            : "Set due time"}
        </Typography>
        {routine === questRoutine &&
        reminderTimeMap?.[questRoutine]?.length > 0 ? (
          <TextButton onClick={onRemoveTimeClick} style={{ padding: 0 }}>
            <FontAwesomeIcon
              icon={faXmark}
              color={JoonUIColor.icon.neutral}
              size="lg"
            />
          </TextButton>
        ) : (
          <FontAwesomeIcon
            icon={faChevronRight}
            color={JoonUIColor.icon.neutral}
          />
        )}
      </TextButton>
    </button>
  )
}
