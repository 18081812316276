import { JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"

import CatchUpCardContent from "./CatchUpCardContent"
import CatchUpCompleteContent from "./CatchUpCompleteContent"
import CatchUpCompleteUserSelectHeader from "./CatchupCompleteUserSelectHeader"
import CatchUpUserSelectHeader from "./CatchUpUserSelectHeader"
import { useCatchUpStore } from "./useCatchUpStore"
import { CloseButton } from "../../components/closeButton/CloseButton"
import SlideUpModal from "../../components/slideUpModal/SlideUpModal"
import useMobile from "../../hooks/useMobile"
import { useQuestBoardQuery } from "../../networking/queries"

const CatchUp = () => {
  const { isOpen, onClose, showCard } = useCatchUpStore((state) => ({
    isOpen: state.isOpen,
    onClose: state.onClose,
    showCard: state.showCard,
  }))
  const isReviewing = useCatchUpStore((state) => state.isReviewing)
  const isMobile = useMobile()
  useQuestBoardQuery() // Added query here to get around rerendering quest data issue

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      showCard={showCard}
      size="default"
      backgroundType={isMobile ? "solid" : "overlay"}
    >
      <div className="flex flex-col">
        <CloseButton onClick={onClose} />
        <Typography
          variant="bodySmallBold"
          style={{
            padding: `${SPACING.space3} ${SPACING.space4}`,
            borderBottom: `1px solid ${JoonUIColor.border.default}`,
          }}
        >
          {isReviewing ? "Verify completed quests" : "Review remaining quests"}
        </Typography>
        <div className="px-4 py-3 border-b border-borderPrimary">
          {isReviewing ? (
            <CatchUpUserSelectHeader />
          ) : (
            <CatchUpCompleteUserSelectHeader />
          )}
        </div>
      </div>
      {isReviewing ? <CatchUpCardContent /> : <CatchUpCompleteContent />}
    </SlideUpModal>
  )
}

export default CatchUp
