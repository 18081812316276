import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import {
  Button,
  JoonColorExpanded,
  SessionBillingPeriod,
  SessionBookingLinkType,
  Typography,
} from "@joonapp/web-shared"

import Overlay from "./Overlay"
import { useCoachOverlayStore } from "./useCoachOverlay"
import {
  PhoneNumberModalType,
  useAddPhoneNumberModalStore,
} from "../../../components/modals/addPhoneNumberModal/useAddPhoneNumberModalStore"
import { useUserQuery } from "../../../networking/queries"
import { useSessionBillingPeriodsQuery } from "../../../networking/sessions/queries"
import { CoachOverlayType } from "../../../types"
import useCareTeam from "../../care/useCareTeam"
import { useSessionBookingModalStore } from "../sessions/hooks/useSessionBookingModalStore"

export const getFirstAvailableSession = (
  billingPeriods: SessionBillingPeriod[]
) => {
  if (!billingPeriods) return null

  const isFirstPeriodFull =
    billingPeriods?.[0] &&
    billingPeriods?.[0]?.bookings.length === billingPeriods?.[0]?.max_bookings

  if (isFirstPeriodFull) return billingPeriods?.[1]?.booking_link
  else return billingPeriods?.[0]?.booking_link
}

const FTUXOverlay = () => {
  const { selectedCoachProfile } = useCareTeam()
  const { data: billingPeriods } = useSessionBillingPeriodsQuery()

  const { onOpen: openSessionBookingModal } = useSessionBookingModalStore()
  const { onOpen: openAddPhoneNumberModal } = useAddPhoneNumberModalStore()
  const { user } = useUserQuery()

  const { currentOverlay } = useCoachOverlayStore()

  if (currentOverlay !== CoachOverlayType.FTUX) return <></>

  const handleBookSession = () => {
    const userPhoneNumber = user?.phone_number
    if (!userPhoneNumber) {
      openAddPhoneNumberModal(PhoneNumberModalType.COACH)
      return
    }

    if (!billingPeriods) return
    const firstSession = getFirstAvailableSession(billingPeriods)
    if (!firstSession) return

    openSessionBookingModal(firstSession, SessionBookingLinkType.SCHEDULE)
  }

  return (
    <Overlay color={JoonColorExpanded.blue100}>
      <div className="flex flex-col gap-4 w-full">
        <div className="flex flex-col gap-2">
          <div className="flex flex-col">
            <Typography variant="h2">🚀</Typography>
            <Typography variant="h4">Welcome to:</Typography>
            <Typography variant="h4">
              The Joon Parent Coaching Program!
            </Typography>
          </div>
          <Typography variant="body">
            Coach {selectedCoachProfile?.user.name} is excited to meet you!
          </Typography>
        </div>
        <Button
          text="Get started"
          buttonType="primary"
          onClick={handleBookSession}
          fullWidth
          rightIcon={faChevronRight}
        />
      </div>
    </Overlay>
  )
}

export default FTUXOverlay
