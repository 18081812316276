import { QuestSeries } from "@joonapp/web-shared"
import { create } from "zustand"

interface ReorderQuestsModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  userHasMadeChanges: boolean
  setUserHasMadeChanges: (userHasMadeChanges: boolean) => void
  sections: [string, QuestSeries[]][]
  setSections: (sections: [string, QuestSeries[]][]) => void
}

export const useReorderQuestsModalStore = create<ReorderQuestsModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
    userHasMadeChanges: false,
    setUserHasMadeChanges: (userHasMadeChanges) => set({ userHasMadeChanges }),
    sections: [],
    setSections: (sections) => set({ sections }),
  })
)
