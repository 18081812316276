import { useState } from "react"
import toast from "react-hot-toast"

import { CoachPaywallChoice } from "../../../../constants"
import { getCoachingStripeCheckoutUrl } from "../../../../networking/subscriptions"
import { CoachingLookupKey } from "../../../../types/coachingGroups"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import { localStorageItems } from "../../../../util/storage"
import { useCoachingStore } from "../../useCoachingStore"
import useIsFromMOF from "../../useIsFromMOF"

export const usePlanCard = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { selectedCoach } = useCoachingStore()
  const isFromMOF = useIsFromMOF()

  const redirectToCoachCheckout = async (
    coachingPlan: CoachingLookupKey,
    numSessions?: string
  ) => {
    if (!selectedCoach) return
    setIsLoading(true)

    const bundleJoonity =
      localStorage.getItem(localStorageItems.planChoiceFromPaywall) ===
        CoachPaywallChoice.EXIT_WITH_COACH ||
      localStorage.getItem(localStorageItems.planChoiceFromPaywall) ===
        CoachPaywallChoice.WITH_COACH

    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_COACH_INITIATE_CHECKOUT, {
      is_tof: !isFromMOF,
      bundle_joonity: bundleJoonity,
      plan: coachingPlan,
      coach_id: selectedCoach.user.id,
    })

    try {
      localStorage.setItem(
        localStorageItems.coachChoiceStripeCheckout,
        JSON.stringify(selectedCoach)
      )
      if (numSessions)
        localStorage.setItem(
          localStorageItems.numPreferredSessions,
          numSessions
        )
      const checkoutUrl = await getCoachingStripeCheckoutUrl(
        selectedCoach.id, // must be profile id
        coachingPlan,
        bundleJoonity
      )
      if (!checkoutUrl) {
        toast.error("Subscription already exists")
      } else {
        window.location.assign(checkoutUrl)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return { redirectToCoachCheckout, isLoading }
}
