import {
  Button,
  CloseIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"

import { useSendMessageModalStore } from "./useSendMessageModalStore"
import { useViewMessageModalStore } from "./useViewMessageModalStore"
import SlideUpModal from "../../../components/slideUpModal/SlideUpModal"
import { GRADIENTS } from "../../../constants"
import { FamilyMailboxTab } from "../../../types"
import { getChildImage, getShopItemText } from "../../../util/util"

const ViewMessageModal = () => {
  const { isOpen, onClose, child, mail, tab } = useViewMessageModalStore()
  const { onOpen: onOpenSendMessageModal, setSelectedChildId } =
    useSendMessageModalStore()

  const image = getChildImage(child || null)

  const dateFormat = dayjs(mail?.sent_datetime || "")
  const date = `${dateFormat.format("MMM D, YYYY")} at ${dateFormat.format("h:mm A")}`

  const onReplyClick = () => {
    if (!mail?.sender.id) return
    onClose()
    setSelectedChildId(mail.sender.id)
    onOpenSendMessageModal()
  }

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      showCard={true}
      size="fit-content"
      backgroundType="overlay"
    >
      <button
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          padding: SPACING.space2,
        }}
        onClick={onClose}
      >
        <CloseIcon color={JoonUIColor.icon.neutral} />
      </button>
      <FlexBox
        direction="column"
        align="center"
        justify="space-between"
        wrap={false}
        style={{
          padding: SPACING.space6,
          background: GRADIENTS.gradient100,
          height: "60vh",
        }}
        fullWidth
      >
        <FlexBox
          direction="column"
          align="center"
          gap={SPACING.space2}
          wrap={false}
          fullWidth
          style={{ height: "100%" }}
        >
          <FlexBox
            direction="row"
            gap={SPACING.space1}
            align="center"
            justify="space-between"
            wrap={false}
            style={{ textWrap: "nowrap" }}
          >
            <FlexBox
              direction="row"
              justify="flex-start"
              align="center"
              gap={SPACING.space2}
            >
              <img
                src={image}
                style={{
                  width: "15vw",
                  maxWidth: "30px",
                  height: "auto",
                  borderRadius: "50%",
                }}
                alt="Child Avatar"
              />
              <Typography variant="bodyBold">
                {tab === FamilyMailboxTab.RECEIVED
                  ? "From: " + mail?.sender.name
                  : "To: " + mail?.receiver.name}
              </Typography>
            </FlexBox>
            <Typography variant="bodySmall">{date}</Typography>
          </FlexBox>
          <FlexBox
            direction="row"
            wrap={true}
            style={{
              backgroundColor: JoonUIColor.background.primaryNeutral,
              padding: SPACING.space3,
              borderRadius: "0px 16px 16px 16px",
              maxHeight: "30%",
              overflow: "scroll",
              marginTop: SPACING.space2,
            }}
          >
            <Typography variant="body">{mail?.body}</Typography>
          </FlexBox>
          {tab === FamilyMailboxTab.SENT && mail?.shop_item_gift && (
            <>
              <Typography
                variant="bodyBold"
                style={{ textAlign: "center", marginTop: SPACING.space2 }}
              >
                Enclosed gift
              </Typography>
              <FlexBox
                direction="row"
                align="center"
                justify="flex-start"
                wrap={false}
                gap={SPACING.space2}
                style={{
                  backgroundColor: JoonUIColor.background.primaryNeutral,
                  padding: SPACING.space2,
                  borderRadius: SPACING.space2,
                  maxHeight: "40%",
                  overflowY: "scroll",
                }}
              >
                <img
                  src={mail?.shop_item_gift.image_url}
                  style={{
                    width: "15vw",
                    maxWidth: "50px",
                    height: "auto",
                    borderRadius: "50%",
                  }}
                  alt="Gift"
                />
                <FlexBox
                  direction="column"
                  gap={SPACING.space0}
                  align="flex-start"
                  justify="flex-start"
                >
                  <Typography
                    variant="bodySmallBold"
                    style={{ lineHeight: "1.25rem" }}
                  >
                    {mail?.shop_item_gift.name}
                  </Typography>
                  <Typography
                    variant="bodySmall"
                    style={{ lineHeight: "1.25rem" }}
                  >
                    {mail?.shop_item_gift.description + " "}
                    {getShopItemText(mail?.shop_item_gift)}
                  </Typography>
                </FlexBox>
              </FlexBox>
            </>
          )}
        </FlexBox>
        {tab === FamilyMailboxTab.RECEIVED && (
          <Button
            buttonType="primary"
            onClick={onReplyClick}
            text={`Reply to ${mail?.sender.name}`}
            fullWidth
          />
        )}
      </FlexBox>
    </SlideUpModal>
  )
}

export default ViewMessageModal
