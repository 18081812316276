import {
  ChildUser,
  FlexBox,
  JoonColorExpanded,
  SPACING,
  Typography,
  UserRole,
} from "@joonapp/web-shared"

import { useFamilyQuery } from "../../../networking/queries"
import { getChildImage } from "../../../util/util"
import { getParentAvatarImage } from "../../../util/util"

type Props = {
  userId: number
  status?: string
}

const UserBadge = ({ userId }: Props) => {
  const { data: family } = useFamilyQuery()
  const userProfile = family?.profiles.find(
    (profile) => profile.user.id === userId
  )

  const userImage =
    userProfile?.role === UserRole.PARENT
      ? getParentAvatarImage(userProfile?.user.avatar)
      : getChildImage(userProfile?.user as ChildUser | null)

  return (
    <FlexBox
      align="center"
      gap={SPACING.space1}
      style={{
        background: JoonColorExpanded.yellow200,
        width: "fit-content",
        padding: `${SPACING.space0} ${SPACING.space2} ${SPACING.space0} ${SPACING.space05}`,
        borderRadius: SPACING.space4,
      }}
    >
      <img
        style={{
          height: SPACING.space4,
          width: SPACING.space4,
          borderRadius: "50%",
        }}
        src={userImage || undefined}
        alt="User Avatar"
      />
      <Typography variant="bodySmall">{userProfile?.user.name}</Typography>
    </FlexBox>
  )
}

export default UserBadge
