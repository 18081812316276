import dayjs from "dayjs"
import { useMemo } from "react"

import usePlanUpdateModalStore from "./usePlanUpdateModalStore"
import { useCoachSubscriptionPackagesQuery } from "../../../networking/coaching/queries"
import { SubscriptionPackage } from "../../../types"

const usePlanUpdate = () => {
  const { numSessions } = usePlanUpdateModalStore()
  const { data: subscriptionPackages, isLoading } =
    useCoachSubscriptionPackagesQuery()

  const packages: SubscriptionPackage[] = Object.values(
    subscriptionPackages || {}
  )

  const selectedPackage = packages.find(
    (plan) => plan.num_sessions === numSessions
  )
  const selectedPlanUnitAmount = (selectedPackage?.unit_amount || 0) / 100

  const activePlan = selectedPackage?.active_plan
  const proratedRate = Math.floor(
    (activePlan?.prorated_amount_change || 0) / 100
  )
  const currentBillingCycleEndDate = dayjs(
    activePlan?.current_billing_cycle_end_date
  ).format("MMM D")

  const isCurrentPlanSelected = activePlan?.num_sessions === numSessions
  const isDowngrading = (activePlan?.num_sessions || 0) > numSessions
  const isUpgrading = (activePlan?.num_sessions || 0) < numSessions
  const hasRemainingSessions =
    (activePlan?.remaining_sessions_in_cycle || 0) > 0

  const sessionOptions = [0, 1, 2, 3, 4]

  const planFinePrint = useMemo(() => {
    if (isCurrentPlanSelected) return null
    if (isDowngrading) {
      if (hasRemainingSessions) {
        return `You have ${activePlan?.remaining_sessions_in_cycle} sessions available to schedule in your current plan. If you want to use those, we recommend scheduling them before downgrading. After downgrading,  you'll lose unscheduled sessions from this cycle. Your new pricing will take effect at the start of your next billing period on ${currentBillingCycleEndDate}.`
      } else {
        return `When downgrading, you will keep your currently scheduled sessions in your cycle. Your new pricing will take effect at the start of your next billing period on ${currentBillingCycleEndDate}.`
      }
    } else {
      return `When upgrading, you’ll get charged a prorated $${proratedRate} today, with access to ${numSessions} sessions immediately. You will be charged the full new price at the start of your next billing period on ${currentBillingCycleEndDate}.`
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentPlanSelected, isDowngrading, hasRemainingSessions, activePlan])

  return {
    planFinePrint,
    sessionOptions,
    selectedPackage,
    selectedPlanUnitAmount,
    currentBillingCycleEndDate,
    isCurrentPlanSelected,
    isDowngrading,
    isUpgrading,
    hasRemainingSessions,
    activePlan,
    isLoadingSubPackages: isLoading,
  }
}

export default usePlanUpdate
