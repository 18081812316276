import { create } from "zustand"

interface AddPhoneNumberModalStore {
  isOpen: boolean
  onOpen: (type: PhoneNumberModalType) => void
  onClose: () => void
  phoneNumber: string
  setPhoneNumber: (phoneNumber: string) => void
  type: PhoneNumberModalType | null
  setType: (type: PhoneNumberModalType | null) => void
}

export enum PhoneNumberModalType {
  COACH,
  THERAPIST,
}

export const useAddPhoneNumberModalStore = create<AddPhoneNumberModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (type) => set({ isOpen: true, type }),
    onClose: () => set({ isOpen: false, type: null }),
    phoneNumber: "",
    setPhoneNumber: (phoneNumber) => set({ phoneNumber }),
    type: null,
    setType: (type) => set({ type }),
  })
)

// clear the
