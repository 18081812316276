import { FlexBox, JoonUIColor, SPACING } from "@joonapp/web-shared"

import { useCatchUp, useCatchUpStore } from "./useCatchUpStore"
import { QuestBoardSelectOption } from "../../types/quests"
import { getChildImage } from "../../util/util"
import { QuestBoardUserSelectButton } from "../questBoard/QuestBoardUserSelectButton"

const CatchUpUserSelectHeader = () => {
  const { childrenArray, selectedChild } = useCatchUpStore()
  const { onClickChild } = useCatchUp()

  return (
    <FlexBox
      direction="row"
      align="center"
      justify="flex-start"
      wrap={false}
      gap={SPACING.space1}
      fullWidth
      style={{
        width: "100%",
        maxWidth: "450px",
        background: JoonUIColor.background.primaryNeutral,
        padding: 0,
        overflowX: "auto",
        scrollbarWidth: "none",
      }}
    >
      {childrenArray.map((child) => (
        <QuestBoardUserSelectButton
          key={child.user.id}
          label={child.user.name}
          imageUrl={getChildImage(child.user)}
          onClick={() => onClickChild(child)}
          isSelected={selectedChild?.user.id === child.user.id}
          selectOption={QuestBoardSelectOption.USER}
        />
      ))}
    </FlexBox>
  )
}

export default CatchUpUserSelectHeader
