import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"

import { useInviteCodeStore } from "./useInviteCodeStore"
import Primary3DButton from "../../components/buttons/Primary3DButton"
import SecondaryTransparentButton from "../../components/buttons/SecondaryTransparentButton"
import OnboardingPageContentWrapper from "../../components/onboarding/onboardingPageContentWrapper/OnboardingPageContentWrapper"
import OnboardingPageWrapper from "../../components/onboarding/onboardingPageWrapper/OnboardingPageWrapper"
import useMobile from "../../hooks/useMobile"
import useOnboardContext, {
  OnboardingStep,
} from "../../hooks/useOnboardContext"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"
import { localStorageItems } from "../../util/storage"

function InviteContent() {
  const router = useRouter()
  const { setIsInvited, setTherapistCode } = useInviteCodeStore()
  const { setStep } = useOnboardContext()
  const isMobile = useMobile()

  return (
    <OnboardingPageWrapper
      style={
        isMobile
          ? {
              backgroundImage: "url(/images/background/cloud-bg.png)",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }
          : { background: "transparent" }
      }
    >
      <OnboardingPageContentWrapper
        backgroundImageOnDesktop="url(/images/background/cloud-bg.png)"
        paddingTop="20dvh"
      >
        <FlexBox
          direction="column"
          align="center"
          justify="center"
          gap={SPACING.space8}
          style={{ width: "min(500px, 100%)" }}
        >
          <img
            src="/images/logos/logo_white.svg"
            alt="Joon"
            style={{
              width: "130px",
              userSelect: "none",
              pointerEvents: "none",
            }}
          />
          <Typography
            variant="h2"
            textAlign="center"
            style={{ fontFamily: "Lora" }}
          >
            Were you given an invite code by a therapist?
          </Typography>
        </FlexBox>
        <FlexBox direction="column" align="center" gap={SPACING.space3}>
          <Primary3DButton
            fullWidth
            onClick={() => {
              localStorage.removeItem(localStorageItems.therapistCode)
              setTherapistCode("")
              setStep(OnboardingStep.ParentingQuestionnaire)
              trackAnalyticEvent(ANALYTIC_EVENTS.NOT_INVITED_BY_THERAPIST)
              router.push("/onboard")
            }}
            text="No"
          />
          <SecondaryTransparentButton
            fullWidth
            onClick={() => {
              trackAnalyticEvent(ANALYTIC_EVENTS.INVITED_BY_THERAPIST)
              setIsInvited(true)
            }}
            text="Yes"
          />
          <button
            style={{
              width: "100%",
              border: "none",
              background: "none",
              padding: SPACING.space2,
              margin: 0,
            }}
            onClick={() => {
              trackAnalyticEvent(ANALYTIC_EVENTS.AUTH_HAS_ACCOUNT)
              router.push("/auth/signin")
            }}
          >
            <Typography variant="body" color={JoonUIColor.semantic.primary}>
              Sign in to an existing account
            </Typography>
          </button>
        </FlexBox>
      </OnboardingPageContentWrapper>
    </OnboardingPageWrapper>
  )
}

export default InviteContent
