import { DropdownOption } from "@joonapp/web-shared"
import { create } from "zustand"

interface FilterRewardLogsModalStore {
  isOpen: boolean
  onOpen: (rewardId: number) => void
  onClose: () => void
  rewardId: number | undefined
  startDate: Date | null
  setStartDate: (startDate: Date | null) => void
  endDate: Date | null
  setEndDate: (endDate: Date | null) => void
  selectedUser: DropdownOption | null
  setSelectedUser: (user: DropdownOption | null) => void
}

export const useFilterRewardLogsModalStore = create<FilterRewardLogsModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (rewardId) => set({ isOpen: true, rewardId }),
    onClose: () => set({ isOpen: false }),
    rewardId: undefined,
    startDate: null,
    setStartDate: (startDate) => set({ startDate }),
    endDate: null,
    setEndDate: (endDate) => set({ endDate }),
    selectedUser: null,
    setSelectedUser: (user) => set({ selectedUser: user }),
  })
)
