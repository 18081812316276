import { JoonUIColor, SPACING } from "@joonapp/web-shared"
import { useEffect, useRef } from "react"

const CustomTextArea = ({
  value,
  onChange,
  onBlur,
  onKeyDown,
  disabled,
  placeholder,
  maxLength,
  name,
  style,
  hideBorder,
  defaultRows = 2,
}: {
  value: string
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
  disabled?: boolean
  placeholder?: string
  maxLength?: number
  name?: string
  style?: React.CSSProperties
  hideBorder?: boolean
  defaultRows?: number
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  // Adjust textarea height when content changes
  useEffect(() => {
    const textArea = textareaRef.current
    if (textArea instanceof HTMLTextAreaElement) {
      textArea.style.height = "auto"
      textArea.style.height = `${textArea.scrollHeight}px`
    }
  }, [value])

  return (
    <div
      style={{
        padding: SPACING.space2,
        width: "100%",
        background: JoonUIColor.background.primaryNeutral,
        border: hideBorder ? "none" : `1px solid ${JoonUIColor.border.default}`,
        borderRadius: SPACING.space2,
        ...style,
      }}
    >
      <textarea
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        maxLength={maxLength}
        disabled={disabled}
        rows={defaultRows}
        autoComplete="off"
        ref={textareaRef}
        style={{
          display: "block",
          overflow: "hidden",
          resize: "none",
          width: "100%",
          color: JoonUIColor.text.primary,
          fontSize: "14px",
          border: "none",
          outline: "none",
        }}
      />
    </div>
  )
}

export default CustomTextArea
