import {
  ChevronRightIcon,
  CustomReward,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import RewardChildRedeemCard from "./RewardChildRedeemCard"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { useRouter } from "../../../util/router"
import { getScreenTimeConfigurations } from "../../../util/util"

const PurchasedRewardGroup = ({ reward }: { reward: CustomReward }) => {
  const [isHovered, setIsHovered] = useState(false)
  const router = useRouter()

  const { numLinkedDevices } = getScreenTimeConfigurations(reward)

  return (
    <FlexBox
      direction="column"
      style={{
        border: `1px solid ${JoonUIColor.border.default}`,
        borderRadius: SPACING.space2,
      }}
    >
      <FlexBox
        justify="space-between"
        direction="row"
        align="center"
        wrap={false}
        onClick={() => {
          trackAnalyticEvent(ANALYTIC_EVENTS.VIEW_CUSTOM_REWARD_DETAILS)
          router.push(`/rewards/details/${reward.id}`)
        }}
        gap={SPACING.space2}
        style={{
          width: "100%",
          padding: SPACING.space4,
          paddingRight: SPACING.space3,
          borderBottom: `1px solid ${JoonUIColor.border.default}`,
          borderTopLeftRadius: SPACING.space2,
          borderTopRightRadius: SPACING.space2,
          cursor: "pointer",
          background: isHovered
            ? JoonUIColor.background.xlightGray
            : JoonUIColor.background.primaryNeutral,
          transition: "background 0.1s ease-in-out",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <FlexBox
          direction="row"
          align="center"
          justify="space-between"
          wrap={false}
        >
          <FlexBox direction="column">
            <Typography
              variant="bodySmallBold"
              textAlign="left"
              style={{
                textOverflow: "ellipsis",
                width: "100%",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {`${reward.emoji} ${reward.title}`}
            </Typography>
            {(!reward.purchasable_from_joonity || reward.date_deleted) && (
              <Typography
                variant="bodyXSmallBold"
                color={JoonUIColor.text.secondary}
                style={{
                  background: JoonColorExpanded.neutral200,
                  padding: `${SPACING.space0} ${SPACING.space2}`,
                  borderRadius: SPACING.space2,
                }}
              >
                {reward.date_deleted
                  ? "Deleted reward"
                  : "Not available for purchase"}
              </Typography>
            )}
          </FlexBox>
          <ChevronRightIcon color={JoonUIColor.icon.neutral} size={24} />
        </FlexBox>
      </FlexBox>

      {numLinkedDevices > 0 && (
        <Typography
          variant="bodyXSmall"
          color={JoonUIColor.text.secondary}
          style={{
            width: "100%",
            background: JoonUIColor.background.primaryNeutral,
            padding: `${SPACING.space1} ${SPACING.space4}`,
            borderBottom: `1px solid ${JoonUIColor.border.default}`,
          }}
        >
          Linked on {numLinkedDevices} devices
        </Typography>
      )}

      <FlexBox direction="column" gap={SPACING.space0}>
        {reward.assignments.map((assignment, index) => (
          <RewardChildRedeemCard
            childId={assignment.user_id}
            key={assignment.user_id}
            reward={reward}
            isGrouped={true}
            style={{
              background: JoonUIColor.background.primaryNeutral,
              borderRadius: 0,
              border: "none",
              borderBottomLeftRadius:
                index === reward.assignments.length - 1 ? SPACING.space2 : 0,
              borderBottomRightRadius:
                index === reward.assignments.length - 1 ? SPACING.space2 : 0,
              borderBottom:
                index === reward.assignments.length - 1
                  ? "none"
                  : `1px solid ${JoonUIColor.border.default}`,
            }}
          />
        ))}
      </FlexBox>
    </FlexBox>
  )
}

export default PurchasedRewardGroup
