import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  ChevronRightIcon,
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import Skeleton from "react-loading-skeleton"

import { useRecommendedCoachVideoModalStore } from "./useRecommendedCoachVideoModalStore"
import { CoachingStep } from "../../../../types/coachingGroups"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import { useCoachingStore } from "../../useCoachingStore"
import useIsFromMOF from "../../useIsFromMOF"

const RecommendedCoachVideoModal = () => {
  const { selectedCoach, setStep } = useCoachingStore()
  const { isOpen, onClose } = useRecommendedCoachVideoModalStore()
  const isFromMOF = useIsFromMOF()

  const onSelectCoachClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_COACH_SELECT, {
      coach_id: selectedCoach?.user.id,
      is_tof: !isFromMOF,
    })
    setStep(CoachingStep.PLAN_SELECT)
    onClose()
  }

  if (!selectedCoach) return <Skeleton />

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      displayCloseIcon={false}
      animate
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "transparent",
        width: "min(450px, 90%)",
        padding: SPACING.space0,
        zIndex: 1,
      }}
    >
      <button
        onClick={onClose}
        style={{
          position: "absolute",
          top: SPACING.space2,
          right: SPACING.space2,
          zIndex: 1000,
        }}
      >
        <FontAwesomeIcon
          icon={faXmarkCircle}
          color={JoonUIColor.icon.inverted}
          size="2xl"
        />
      </button>
      <FlexBox
        fullWidth
        style={{ height: "564px", overflow: "hidden", pointerEvents: "auto" }}
      >
        <iframe
          width="100%"
          height="110%"
          src={selectedCoach.profile_intro_video_url || ""}
          title="YouTube Video Player"
          allow="autoplay; encrypted-media"
          loading="lazy"
          style={{
            zIndex: -1, // to allow close button to work
            borderRadius: SPACING.space4,
            border: `2px solid transparent`,
          }}
        ></iframe>
      </FlexBox>

      <button onClick={onSelectCoachClick} style={{ width: "100%" }}>
        <FlexBox
          justify="space-between"
          fullWidth
          style={{
            background: JoonUIColor.background.accent,
            boxShadow: `${SPACING.space1} ${SPACING.space2} ${SPACING.space2} rgba(0, 0, 0, 0.08)`,
            padding: SPACING.space4,
          }}
        >
          <Typography variant="bodyBold" color={JoonUIColor.text.inverted}>
            Continue with {selectedCoach.user.name || "this coach"}
          </Typography>
          <ChevronRightIcon size={24} color={JoonUIColor.text.inverted} />
        </FlexBox>
      </button>
    </Modal>
  )
}

export default RecommendedCoachVideoModal
