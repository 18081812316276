import { QuestSeries } from "@joonapp/web-shared"
import { create } from "zustand"

import { QuestAIResponse } from "../../../../types/quests"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"

interface AIQuestStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void

  prompt: string
  setPrompt: (prompt: string) => void

  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void

  isAssigning: boolean
  setIsAssigning: (isAssigning: boolean) => void

  isRemoving: boolean
  setIsRemoving: (isRemoving: boolean) => void

  chatSessionId: string | null
  setChatSessionId: (chatSessionId: string | null) => void

  response: QuestAIResponse | null
  setResponse: (response: QuestAIResponse | null) => void

  selectedQuestUUIDs: string[]
  toggleQuestUUID: (questUUID: string) => void
  setSelectedQuestUUIDs: (questUUIDs: string[]) => void

  error: string | null
  setError: (error: string | null) => void

  assignedQuests: QuestSeries[]
  setAssignedQuests: (assignedQuests: QuestSeries[]) => void

  didStartOver: boolean
  setDidStartOver: (didStartOver: boolean) => void
}

const initialState = {
  isOpen: false,
  prompt: "",
  isLoading: false,
  isAssigning: false,
  isRemoving: false,
  chatSessionId: null,
  response: null,
  selectedQuestUUIDs: [],
  error: null,
  assignedQuests: [],
  didStartOver: false,
}

const useAIQuestStore = create<AIQuestStore>((set) => ({
  ...initialState,
  onOpen: () => {
    set({ isOpen: true })
    trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_AI_QUEST_BUILDER)
  },
  onClose: () => {
    set(initialState)
    trackAnalyticEvent(ANALYTIC_EVENTS.CLOSE_AI_QUEST_BUILDER)
  },
  setPrompt: (prompt) => set({ prompt }),
  setIsLoading: (isLoading) => set({ isLoading }),
  setIsAssigning: (isAssigning) => set({ isAssigning }),
  setIsRemoving: (isRemoving) => set({ isRemoving }),
  setChatSessionId: (chatSessionId) => set({ chatSessionId }),
  setResponse: (response) => set({ response }),
  toggleQuestUUID: (uuid) =>
    set((state) => ({
      selectedQuestUUIDs: state.selectedQuestUUIDs.includes(uuid)
        ? state.selectedQuestUUIDs.filter((title) => title !== uuid)
        : [...state.selectedQuestUUIDs, uuid],
    })),
  setSelectedQuestUUIDs: (questUUIDs) =>
    set({ selectedQuestUUIDs: questUUIDs }),
  setError: (error) => set({ error }),
  setAssignedQuests: (assignedQuests) => set({ assignedQuests }),
  setDidStartOver: (didStartOver) => set({ didStartOver }),
}))

export default useAIQuestStore
