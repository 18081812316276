import { FlexBox, SPACING } from "@joonapp/web-shared"
import { Typography } from "@joonapp/web-shared"
import { useState } from "react"

import useMediaQuery from "../../../hooks/useMediaQuery"
import useOnboardContext, { ChildStep } from "../../../hooks/useOnboardContext"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import ChevronBackButton from "../../buttons/ChevronBackButton"
import Primary3DButton from "../../buttons/Primary3DButton"
import OnboardingPageContentWrapper from "../onboardingPageContentWrapper/OnboardingPageContentWrapper"

interface Props {
  childName: string
  onConfirm: () => void
}

function QuestionnaireDisclaimer({ childName, onConfirm }: Props) {
  const { setChildStep } = useOnboardContext()
  const [isLoading, setIsLoading] = useState(true)

  const isSmallMobile = useMediaQuery("(max-width: 500px)")

  return (
    <OnboardingPageContentWrapper
      backgroundImageOnDesktop="url(/images/background/cloud-bg.png)"
      paddingTop={SPACING.space14}
      style={{ position: "relative" }}
    >
      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space3}
        style={{ marginBottom: SPACING.space6 }}
      >
        <ChevronBackButton onClick={() => setChildStep(ChildStep.ChildInfo)} />
        <img
          src="/images/gifs/onboarding-clipboard-gif.gif"
          alt="Questionnaire"
          style={{
            width: isSmallMobile ? "160px" : "200px",
            height: isSmallMobile ? "auto" : "200px",
            opacity: isLoading ? 0 : 1,
            transition: "opacity 0.1s ease-in-out",
          }}
          onLoad={() => setIsLoading(false)}
        />
        <Typography variant="h2Serif" textAlign="center">
          Intake Questionnaire for {childName}
        </Typography>
        <Typography variant="body" textAlign="center" style={{ width: "90%" }}>
          The following questions are used to help provide personalized
          recommendations and guidance for your family. You'll be able to add
          more children after this.
        </Typography>
      </FlexBox>
      <FlexBox direction="column" align="center" gap={SPACING.space3}>
        <Typography
          variant="bodySmall"
          textAlign="center"
          style={{ width: "70%" }}
        >
          Your answers are never shared with anyone without your consent.
        </Typography>
        <Primary3DButton
          fullWidth
          text="Continue"
          onClick={() => {
            onConfirm()
            trackAnalyticEvent(ANALYTIC_EVENTS.START_CHILD_ONBOARDING_SURVEY)
          }}
        />
      </FlexBox>
    </OnboardingPageContentWrapper>
  )
}

export default QuestionnaireDisclaimer
