import {
  ChildUser,
  Modal,
  Button,
  FlexBox,
  SPACING,
  Typography,
  Slider,
  TextInput,
  JoonUIColor,
  Family,
} from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"

import { useRejectQuestModalStore } from "./useRejectQuestModalStore"
import { useReviewQuestModalStore } from "./useReviewQuestModalStore"
import DifficultyBadge from "../../components/badges/difficultyBadge/DifficultyBadge"
import Counter from "../../components/counter/Counter"
import { QUERY_KEYS } from "../../constants"
import { useFamilyQuery } from "../../networking/queries"
import { verifyQuest } from "../../networking/quests"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { getChildImage, getUserFromFamily } from "../../util/util"

const ReviewQuestModal = () => {
  const {
    isOpen,
    onClose,
    quest,
    reward,
    setReward,
    notes,
    setNotes,
    difficulty,
    setDifficulty,
    error,
    setError,
    isLoading,
    setIsLoading,
  } = useReviewQuestModalStore()
  const { onOpen: openRejRetModal } = useRejectQuestModalStore()

  const queryClient = useQueryClient()

  const { data: family } = useFamilyQuery()
  if (!quest) return <></>

  const child = getUserFromFamily(family as Family, quest?.user_id)
  const childImage = getChildImage(child as ChildUser)

  const clickApprove = async () => {
    if (!quest) return
    setIsLoading(true)
    setError("")
    const body = {
      verification_notes: notes,
      redeemable_reward: reward,
      completion_difficulty: difficulty,
      new_difficulty: undefined,
    }
    await verifyQuest({ instanceId: quest.id, data: body })
    onClose()
    setIsLoading(false)
    trackAnalyticEvent(ANALYTIC_EVENTS.VERIFY_QUEST)
    queryClient.invalidateQueries([QUERY_KEYS.QUEST_REVIEW])
    queryClient.invalidateQueries([QUERY_KEYS.USER])
    queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
    queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER])
  }

  const clickReject = () => {
    setError("")
    openRejRetModal(quest)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      mobileFull
      displayCloseIcon
      animate
    >
      <div className="quest-modal">
        <div className="quest-modal-content">
          <Typography
            variant="h3"
            textAlign="center"
            style={{ marginBottom: SPACING.space4 }}
          >
            {quest?.series.title}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: "12px",
              width: "100%",
            }}
          >
            <DifficultyBadge
              difficulty={quest.series.difficulty}
              reward={quest.series.redeemable_reward}
            />
            <div style={{ display: "flex", gap: "8px", width: "100%" }}>
              <div
                className="quest-section"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="bodySmallBold"
                  style={{ marginBottom: SPACING.space1 }}
                >
                  Completed by
                </Typography>
                <div className={`child-badge mt-6`}>
                  <div className="child-icon">
                    <img src={childImage} alt="" />
                  </div>
                  <div>{child?.name}</div>
                </div>
              </div>
              <div
                className="quest-section"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="bodySmallBold"
                  style={{ marginBottom: SPACING.space1 }}
                >
                  Coin Reward
                </Typography>
                <div>
                  <Counter value={reward || 0} setValue={setReward} />
                </div>
              </div>
            </div>
            <div
              className="quest-section"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="bodySmallBold"
                style={{ marginBottom: SPACING.space1 }}
              >
                How difficult was it for your child to complete this task?
                (optional)
              </Typography>

              <Slider
                value={difficulty ?? null}
                options={[0, 1, 2, 3, 4, 5, 6]}
                setValue={setDifficulty}
                minLabel="Not Difficult"
                maxLabel="Very Difficult"
              />
            </div>
          </div>
          {quest.completion_notes && (
            <>
              <Typography
                variant="bodySmallBold"
                style={{ marginBottom: SPACING.space1 }}
              >
                {child?.name + "'s Notes"}
              </Typography>
              <Typography variant="body" style={{ marginTop: SPACING.space1 }}>
                {quest.completion_notes}
              </Typography>
            </>
          )}
          {quest.completion_photo_url && (
            <>
              <Typography
                variant="bodySmallBold"
                style={{ marginBottom: SPACING.space1 }}
              >
                Photo Verification
              </Typography>
              <div className="mt-6">
                <img
                  src={quest.completion_photo_url}
                  alt="Verification"
                  style={{
                    width: "auto",
                    maxHeight: "250px",
                    borderRadius: "16px",
                  }}
                />
              </div>
            </>
          )}
          <Typography
            variant="bodySmallBold"
            textAlign="left"
            style={{ marginTop: SPACING.space4 }}
          >
            Add Note
          </Typography>
          <TextInput
            name="Notes"
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Let your kid know how they did on the Quest!"
            value={notes || ""}
            maxLength={140}
            fullWidth
          />
          <Typography
            variant="bodyXSmall"
            textAlign="right"
            color={JoonUIColor.text.secondary}
          >
            Max 140 characters
          </Typography>
          {error && (
            <Typography
              variant="bodySmallBold"
              color={JoonUIColor.semantic.alert}
              style={{ marginTop: SPACING.space1 }}
            >
              {error}
            </Typography>
          )}
          <FlexBox
            direction="row"
            wrap={false}
            gap={SPACING.space6}
            style={{ marginTop: SPACING.space6 }}
          >
            <Button
              text="Reject"
              buttonType="redPrimary"
              fullWidth
              onClick={clickReject}
            />
            <Button
              text="Approve"
              fullWidth
              isLoading={isLoading}
              onClick={clickApprove}
            />
          </FlexBox>
        </div>
      </div>
    </Modal>
  )
}

export default ReviewQuestModal
