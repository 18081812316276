import { faChevronRight, faFlag } from "@fortawesome/duotone-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  ChildUser,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import { useFlaggedDoterChatLogsQuery } from "../../networking/chatSessions/queries"
import { useFamilyQuery } from "../../networking/queries"
import { AIChatFlaggedPromptNotification } from "../../types"
import { getUserFromFamily } from "../../util/util"
import DoterChatFlag from "../family/manageGameplay/doterChat/DoterChatFlag"
import {
  DoterChatTab,
  useDoterChatModalStore,
} from "../family/manageGameplay/doterChat/useDoterChatModalStore"

const DoterAIAlertsSection = () => {
  const { data: convos, isLoading } = useFlaggedDoterChatLogsQuery()

  const unreadConvos = convos?.filter((convo) => !convo.date_read)

  if (isLoading || !unreadConvos || unreadConvos?.length === 0) return null

  const childUserIds = [...new Set(unreadConvos.map((convo) => convo.user_id))]

  return (
    <FlexBox
      style={{
        padding: SPACING.space4,
        border: `1px solid ${JoonUIColor.semantic.alert}`,
        borderRadius: SPACING.space2,
        background: JoonUIColor.background.primaryNeutral,
      }}
      direction="column"
      gap={SPACING.space2}
    >
      <FlexBox direction="row" align="center" gap={SPACING.space2}>
        <FontAwesomeIcon
          icon={faFlag}
          fontSize={16}
          color={JoonUIColor.semantic.alert}
        />
        <Typography variant="bodyBold">Doter AI Conversation Alerts</Typography>
      </FlexBox>
      <FlexBox direction="column" gap={SPACING.space2}>
        {childUserIds.map((childUserId) => {
          const convos = unreadConvos.filter(
            (convo) => convo.user_id === childUserId
          )
          return (
            <DoterAIAlertItem
              key={childUserId}
              convos={convos}
              childUserId={childUserId}
            />
          )
        })}
      </FlexBox>
    </FlexBox>
  )
}

const DoterAIAlertItem = ({
  convos,
  childUserId,
}: {
  convos: AIChatFlaggedPromptNotification[]
  childUserId: number
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const { data: family } = useFamilyQuery()
  const { onOpen: openDoterChatModal, setTab } = useDoterChatModalStore()
  const child = getUserFromFamily(family!, childUserId || 0) as
    | ChildUser
    | undefined

  const flagTypes = [
    ...new Set(convos.flatMap((convo) => convo.message.prompt_flags)),
  ]

  const handleClick = () => {
    openDoterChatModal(childUserId)
    setTab(DoterChatTab.FLAGGED)
  }

  return (
    <button onClick={handleClick} style={{ width: "100%" }}>
      <FlexBox
        style={{
          border: `1px solid ${JoonUIColor.border.default}`,
          borderRadius: SPACING.space2,
          overflow: "hidden",
          transition: "border-color 0.2s ease-in-out",
          borderColor: isHovered
            ? JoonColorExpanded.neutral300
            : JoonUIColor.border.default,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <FlexBox
          direction="row"
          align="center"
          wrap={false}
          gap={SPACING.space2}
          style={{
            padding: `${SPACING.space2} ${SPACING.space3}`,
            background: JoonUIColor.background.lightGray,
          }}
          justify="space-between"
        >
          <Typography variant="bodySmall">
            Review{" "}
            <Typography variant="bodySmallBold">{child?.name}'s </Typography>
            conversations
          </Typography>
          <FontAwesomeIcon
            icon={faChevronRight}
            fontSize={16}
            color={JoonUIColor.icon.neutral}
          />
        </FlexBox>
        <FlexBox
          direction="row"
          gap={SPACING.space1}
          style={{
            padding: SPACING.space3,
            background: JoonUIColor.background.primaryNeutral,
          }}
        >
          {flagTypes.map(
            (flagType) =>
              flagType && <DoterChatFlag key={flagType} type={flagType} />
          )}
        </FlexBox>
      </FlexBox>
    </button>
  )
}

export default DoterAIAlertsSection
