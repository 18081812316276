import {
  Button,
  CustomRewardBalance,
  JoonUIColor,
  SPACING,
} from "@joonapp/web-shared"

import { useRewardQuantityModal } from "./RewardQuantityModal"

type Props = {
  rewardBalance: CustomRewardBalance
  childId?: number
}

const RewardRedeemButton = ({ rewardBalance, childId }: Props) => {
  const { onOpen: onOpenQuantityModal } = useRewardQuantityModal()

  const onRedeemClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onOpenQuantityModal(rewardBalance, childId ?? null)
  }

  return (
    <Button
      buttonType="secondary"
      size="small"
      onClick={onRedeemClick}
      text="Redeem"
      style={{
        color: JoonUIColor.text.primary,
        borderRadius: SPACING.space2,
        border: `1px solid ${JoonUIColor.border.default}`,
        padding: `${SPACING.space1} ${SPACING.space2}`,
      }}
    />
  )
}

export default RewardRedeemButton
