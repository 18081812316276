import dayjs from "dayjs"
import { useEffect } from "react"

import { JooniperPrepaywallModal } from "./JooniperPrepaywallModal/JooniperPrepaywallModal"
import { useJooniperPrepaywallModalStore } from "./JooniperPrepaywallModal/useJooniperPrepaywallModalStore"
import PaywallBundling from "./PaywallBundling"
import PaywallNoBundling from "./PaywallNoBundling"
import PaywallReferralModal from "./PaywallReferralModal"
import useDocumentTitle from "../../hooks/useDocumentTitle"
import {
  useCoachIntakeFormQuestionnaireQuery,
  useCoachPostSelectionQuestionnaireQuery,
  useUserQuery,
} from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { requireAuth } from "../../util/auth"
import {
  cohorts,
  useGetExperimentCohort,
  experimentNames,
} from "../../util/experiments"
import { localStorageItems } from "../../util/storage"

const Paywall = () => {
  const { onOpen: onOpenJooniperPrepaywall } = useJooniperPrepaywallModalStore()
  useDocumentTitle("Joon - Start Trial")
  const { user } = useUserQuery()
  const jooniperPrepaywallVariant = useGetExperimentCohort(
    experimentNames.jooniperPrepaywall
  )
  const customRewardsPrepaywallVariant = useGetExperimentCohort(
    experimentNames.customRewardsPrepaywall
  )

  // This is called here to preload/cache questionnaires for survey step
  useCoachIntakeFormQuestionnaireQuery()
  useCoachPostSelectionQuestionnaireQuery()

  const isLoadingExperiments =
    jooniperPrepaywallVariant === null ||
    customRewardsPrepaywallVariant === null

  const shouldOpenJooniperPrepaywall =
    !localStorage.getItem(localStorageItems.hasSeenJooniperPrepaywall) &&
    (jooniperPrepaywallVariant === cohorts.variantA ||
      customRewardsPrepaywallVariant === cohorts.variantA) &&
    !localStorage.getItem(localStorageItems.isCoparent) &&
    !localStorage.getItem(localStorageItems.therapistCode)

  useEffect(() => {
    trackAnalyticEvent(ANALYTIC_EVENTS.SHOW_PAYWALL)
    localStorage.setItem(
      localStorageItems.lastSeenPaywall,
      dayjs().format("YYYY-MM-DD")
    )
  }, [])

  useEffect(() => {
    if (shouldOpenJooniperPrepaywall) {
      trackAnalyticEvent(ANALYTIC_EVENTS.PREPAYWALL_MODAL_OPEN)
      onOpenJooniperPrepaywall()
    }
  }, [onOpenJooniperPrepaywall, shouldOpenJooniperPrepaywall])

  if (!user || isLoadingExperiments) return <></>

  const displayBundling = !!localStorage.getItem(
    localStorageItems.wasRoutedFromExternalCoachMarketing
  )

  return (
    <>
      {displayBundling ? <PaywallBundling /> : <PaywallNoBundling />}
      <PaywallReferralModal />
      <JooniperPrepaywallModal />
    </>
  )
}

const PaywallWithAuth = requireAuth(Paywall)
export default PaywallWithAuth
