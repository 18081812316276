import { useEffect } from "react"

import { useFamilyQuery } from "../networking/queries"
import { useSubscriberInfo } from "../networking/queries"
import { useUserNetworkInfo } from "../networking/queries"
import { useUserLocationInfo } from "../networking/queries"
import { updateSubscriberInfo } from "../networking/subscriptions"

export const useUpdateSubscriberAttributes = () => {
  const { data: family } = useFamilyQuery()
  const { data: subscriber } = useSubscriberInfo()
  const { data: ipAddress } = useUserNetworkInfo()
  const { data: locationInfo } = useUserLocationInfo()

  const cookies = document.cookie.split("; ")
  const fbClickCookie = cookies.find((cookie) => cookie.startsWith("_fbc="))
  const fbClickId = fbClickCookie ? fbClickCookie.split("=")[1] : undefined
  const fbBrowserCookie = cookies.find((cookie) => cookie.startsWith("_fbp="))
  const fbBrowser = fbBrowserCookie ? fbBrowserCookie.split("=")[1] : undefined

  useEffect(() => {
    if (!family || !ipAddress || !locationInfo) return
    updateSubscriberInfo(family, {
      ipAddress: ipAddress,
      countryCode: locationInfo.country_code,
      state: locationInfo.region_code,
      city: locationInfo.city,
      zipCode: locationInfo.postal,
      fbClickId: fbClickId,
      fbBrowser: fbBrowser,
    })
  }, [family, subscriber, ipAddress, locationInfo]) // eslint-disable-line react-hooks/exhaustive-deps
}
