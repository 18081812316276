import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import {
  Button,
  FlexBox,
  QuestSeries,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useMemo } from "react"

import { JooniperAssignBar } from "./JooniperAssignBar"
import { JooniperAssignedQuestCard } from "./JooniperAssignedQuestCard"
import JooniperDraftQuestCard from "./JooniperDraftQuestCard"
import useJooniperPrepaywallModal from "./useJooniperPrepaywallModal"
import { useJooniperPrepaywallModalStore } from "./useJooniperPrepaywallModalStore"
import AnimatedStars from "../../../components/animatedStars/AnimatedStars"
import Dotdotdot from "../../../components/dotdotdot/Dotdotdot"
import { useFamilyQuery } from "../../../networking/queries"
import { FamilyQuerySelect, JooniperPrepaywallStep } from "../../../types"
import {
  cohorts,
  experimentNames,
  useGetExperimentCohort,
} from "../../../util/experiments"

const JooniperQuestRecommendation = () => {
  const {
    setStep,
    response,
    assignedQuests,
    isAssigningQuests,
    selectedQuestUUIDs,
    onClose,
  } = useJooniperPrepaywallModalStore()
  const { handleAssignQuests } = useJooniperPrepaywallModal()
  const customRewardsPrepaywallVariant = useGetExperimentCohort(
    experimentNames.customRewardsPrepaywall
  )
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)

  // Get quests from cache rather than from AI response, then group them by quest_group_id
  const groupedAssignedQuests = useMemo(() => {
    const groups = {} as {
      [key: string]: { quest: QuestSeries; childIds: number[] }
    }

    assignedQuests.forEach((quest) => {
      const groupId = quest.quest_group_id ?? quest.id
      if (!groups[groupId])
        groups[groupId] = { quest, childIds: [quest.user_id] }
      else groups[groupId].childIds.push(quest.user_id)
    })

    return groups
  }, [assignedQuests])

  return (
    <>
      <FlexBox
        direction="column"
        align="center"
        justify="flex-start"
        gap={SPACING.space3}
        wrap={false}
        style={{
          padding: `0 ${SPACING.space4}`,
          height: "100%",
        }}
      >
        <FlexBox
          direction="row"
          align="center"
          justify="flex-start"
          style={{
            padding: isAssigningQuests
              ? `${SPACING.space4} ${SPACING.space3} ${SPACING.space3}`
              : `${SPACING.space4} ${SPACING.space0} ${SPACING.space0}`,
            transition: "padding 0.4s ease-in-out",
          }}
        >
          <AnimatedStars
            animate={isAssigningQuests}
            scale={isAssigningQuests ? 2 : 1}
          />
        </FlexBox>
        {isAssigningQuests ? (
          <Typography
            variant="aiBody"
            textAlign="left"
            style={{ whiteSpace: "pre-line" }}
          >
            Assigning quests
            <Dotdotdot />
          </Typography>
        ) : (
          <>
            <Typography
              variant="aiBody"
              textAlign="left"
              style={{ whiteSpace: "pre-line" }}
            >
              {assignedQuests.length === 0
                ? `Based on the family context you've shared, here is a starter routine I'd recommend for your family! Select the ones you'd like to actually assign and I'll handle the rest!\nYou can always modify everything later.`
                : `All quests have been assigned!\nAs your ${children?.length === 1 ? "child completes" : "children complete"} their quests, they'll earn coins that they can use to purchase Custom Rewards from your Family Shop.`}
            </Typography>
            <FlexBox
              direction="column"
              gap={SPACING.space2}
              wrap={false}
              style={{ paddingBottom: "100px" }}
            >
              {assignedQuests.length > 0
                ? Object.values(groupedAssignedQuests).map(
                    ({ quest, childIds }) => (
                      <JooniperAssignedQuestCard
                        key={quest.id}
                        questSeries={quest}
                        childIds={childIds}
                      />
                    )
                  )
                : response?.map((quest) => (
                    <JooniperDraftQuestCard
                      key={quest.pending_data_uuid}
                      quest={quest}
                    />
                  ))}
            </FlexBox>
          </>
        )}
      </FlexBox>
      {!isAssigningQuests &&
        (assignedQuests.length > 0 ? (
          <FlexBox
            style={{
              zIndex: 1000,
              position: "fixed",
              bottom: 0,
              padding: SPACING.space4,
            }}
          >
            <Button
              onClick={() => {
                if (customRewardsPrepaywallVariant === cohorts.variantA)
                  setStep(JooniperPrepaywallStep.REWARD_SETUP)
                else onClose()
              }}
              text={
                customRewardsPrepaywallVariant === cohorts.variantA
                  ? "Next up: Set up Rewards"
                  : "Take me to the app!"
              }
              rightIcon={faChevronRight}
              fullWidth
            />
          </FlexBox>
        ) : (
          <JooniperAssignBar
            assignLabel="Assign selected"
            onAssignClick={handleAssignQuests}
            assignDisabled={selectedQuestUUIDs.length === 0}
            dontAssignLabel="Don't assign any"
            onDontAssignClick={() =>
              setStep(JooniperPrepaywallStep.REWARD_SETUP)
            }
          />
        ))}
    </>
  )
}
export default JooniperQuestRecommendation
