import { faComment, faFlag } from "@fortawesome/duotone-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  ChildUser,
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { ModalHeader } from "@joonapp/web-shared"

import DoterChatHistory from "./DoterChatHistory"
import FlaggedConvos from "./FlaggedConvos"
import { DoterChatTab, useDoterChatModalStore } from "./useDoterChatModalStore"
import NotificationDot2 from "../../../../components/notificationDot/NotificationDot2"
import { useFlaggedDoterChatLogsQuery } from "../../../../networking/chatSessions/queries"
import { useFamilyQuery } from "../../../../networking/queries"
import { getUserFromFamily } from "../../../../util/util"

const DoterChatModal = () => {
  const { isOpen, onClose, tab, childUserId } = useDoterChatModalStore()

  const { data: family } = useFamilyQuery()
  const child = getUserFromFamily(family!, childUserId || 0) as
    | ChildUser
    | undefined

  if (!child) return null

  const doter = child.active_doter

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      mobileFull
      style={{ width: "min(500px, 100vw)" }}
      animate
    >
      <ModalHeader
        title={`${child.name} & ${doter?.name} chat history`}
        onClose={onClose}
      />

      <DoterChatTabNav />
      {isOpen && tab === DoterChatTab.CHAT && <DoterChatHistory />}
      {isOpen && tab === DoterChatTab.FLAGGED && <FlaggedConvos />}
    </Modal>
  )
}

const DoterChatTabNav = () => {
  const { tab, setTab, childUserId } = useDoterChatModalStore()
  const { data: flaggedConvos } = useFlaggedDoterChatLogsQuery()
  const isChatTab = tab === DoterChatTab.CHAT
  const isFlaggedTab = tab === DoterChatTab.FLAGGED

  const filteredConvos = flaggedConvos?.filter(
    (convo) => convo.user_id === childUserId
  )

  const unreadConvos = filteredConvos?.filter((convo) => !convo.date_read)

  return (
    <FlexBox
      direction="row"
      gap={SPACING.space6}
      wrap={false}
      justify="space-evenly"
      style={{
        padding: `0 ${SPACING.space4}`,
        borderBottom: `1px solid ${JoonUIColor.border.default}`,
      }}
    >
      <FlexBox
        direction="row"
        gap={SPACING.space2}
        onClick={() => setTab(DoterChatTab.CHAT)}
        style={{
          cursor: "pointer",
          width: "fit-content",
          borderBottom: "2px solid",
          padding: SPACING.space2,
          borderColor: isChatTab ? JoonUIColor.icon.accent : "transparent",
        }}
        align="center"
        justify="center"
      >
        <FontAwesomeIcon
          icon={faComment}
          color={isChatTab ? JoonUIColor.icon.accent : JoonUIColor.icon.neutral}
        />
        <Typography
          variant="bodySmall"
          style={{ fontWeight: isChatTab ? 500 : 400 }}
          color={
            isChatTab ? JoonUIColor.text.primary : JoonUIColor.text.secondary
          }
        >
          All messages
        </Typography>
      </FlexBox>
      <FlexBox
        direction="row"
        gap={SPACING.space2}
        onClick={() => setTab(DoterChatTab.FLAGGED)}
        style={{
          cursor: "pointer",
          width: "fit-content",
          borderBottom: "2px solid",
          padding: SPACING.space2,
          borderColor: isFlaggedTab ? JoonUIColor.icon.accent : "transparent",
        }}
        align="center"
        justify="center"
      >
        <FontAwesomeIcon
          icon={faFlag}
          color={
            isFlaggedTab ? JoonUIColor.icon.accent : JoonUIColor.icon.neutral
          }
        />
        <Typography
          variant="bodySmall"
          style={{ fontWeight: isFlaggedTab ? 500 : 400 }}
          color={
            isFlaggedTab ? JoonUIColor.text.primary : JoonUIColor.text.secondary
          }
        >
          Flagged convos{" "}
          {unreadConvos && unreadConvos?.length > 0 && (
            <span>({unreadConvos?.length})</span>
          )}
        </Typography>
        {unreadConvos && unreadConvos?.length > 0 && <NotificationDot2 />}
      </FlexBox>
    </FlexBox>
  )
}

export default DoterChatModal
