import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { JoonUIColor, SPACING, TherapistIcon } from "@joonapp/web-shared"
import { FlexBox } from "@joonapp/web-shared"
import { Typography } from "@joonapp/web-shared"

import useIncidentReportStore from "./useIncidentReportStore"
import Card from "../../../components/card/Card"
import ArrowCircleRightIcon from "../../../components/icons/ArrowCircleRightIcon"
import { useRouter } from "../../../util/router"

const ReportIncidentButton = () => {
  const { onOpen } = useIncidentReportStore()
  const router = useRouter()

  const isOnMePage = router.pathname.includes("/me")

  return (
    <Card
      title={
        <FlexBox direction="row" align="center" gap={SPACING.space2}>
          {isOnMePage && (
            <TherapistIcon color={JoonUIColor.icon.accent} size={24} />
          )}
          <Typography variant={isOnMePage ? "bodySmall" : "bodySmallBold"}>
            Report child behavior incident
          </Typography>
        </FlexBox>
      }
      onClick={(e) => {
        e.stopPropagation()
        onOpen()
      }}
      buttonComponent={
        isOnMePage ? (
          <FontAwesomeIcon
            icon={faChevronRight}
            color={JoonUIColor.icon.neutral}
          />
        ) : (
          <ArrowCircleRightIcon />
        )
      }
      type={isOnMePage ? "secondary" : "primary"}
    />
  )
}

export default ReportIncidentButton
