import {
  ChevronRightIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  ParentTraining,
  SPACING,
  Typography,
  PersonWalkingIcon,
  ParentTrainingStatus,
  FlagIcon,
  TextButton,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import useTourStore from "./tourAcademyTab/useTourStore"
import useTraining from "./training/useTraining"
import useTrainingStore from "./training/useTrainingStore"
import LinearProgress from "../../components/linearProgress/LinearProgress"
import { TrainingSkeleton } from "../../components/skeleton/Skeleton"
import { useTrainingsQuery } from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"
import useCareTeam from "../care/useCareTeam"

const TrainingsList = () => {
  const router = useRouter()
  const { isRefreshing } = useTrainingStore()
  const { firstTherapist } = useCareTeam()
  const { data: trainingsIP, isLoading: isLoadingIP } = useTrainingsQuery(
    ParentTrainingStatus.IN_PROGRESS
  )
  const { data: trainingsMastered, isLoading: isLoadingMastered } =
    useTrainingsQuery(ParentTrainingStatus.MASTERED)

  const isOnMeTab = router.pathname.includes("/me")

  const hasMasteredTrainings = trainingsMastered && trainingsMastered.length > 0

  return (
    <FlexBox
      direction="column"
      wrap={false}
      style={{
        padding: isOnMeTab ? 0 : SPACING.space4,
        width: "min(600px, 100%)",
      }}
      gap={SPACING.space2}
    >
      <FlexBox direction="row" gap={SPACING.space2} align="center">
        <PersonWalkingIcon size={16} color={JoonUIColor.text.primary} />
        <Typography variant="bodyBold">
          {hasMasteredTrainings
            ? "Continue lessons"
            : "Start your first lesson"}
        </Typography>
      </FlexBox>
      <FlexBox direction="column" gap={SPACING.space2}>
        {isLoadingIP || isLoadingMastered || isRefreshing ? (
          <TrainingSkeleton />
        ) : isOnMeTab && trainingsIP?.length ? (
          <>
            {trainingsIP.slice(0, 2).map((training) => (
              <TrainingListItem key={training.id} training={training} />
            ))}
            {trainingsIP.length > 2 && (
              <TextButton
                onClick={() =>
                  router.push(`/care/${firstTherapist?.user.id}/academy`)
                }
              >
                <Typography
                  variant="bodyBold"
                  color={JoonUIColor.text.primaryAccent}
                >
                  See all
                </Typography>
              </TextButton>
            )}
          </>
        ) : trainingsIP && trainingsIP.length > 0 ? (
          trainingsIP?.map((training) => (
            <TrainingListItem key={training.id} training={training} />
          ))
        ) : (
          <Typography variant="body">No trainings yet!</Typography>
        )}
      </FlexBox>
      {hasMasteredTrainings && !isOnMeTab && (
        <>
          <div style={{ marginTop: SPACING.space2 }}>
            <FlexBox direction="row" gap={SPACING.space2} align="center">
              <FlagIcon size={16} color={JoonUIColor.text.primary} />
              <Typography variant="bodyBold">Completed lessons</Typography>
            </FlexBox>
            <Typography variant="body">
              The following lessons have been marked as
              <strong> Mastered </strong>
              by your clinician.
            </Typography>
          </div>
          <FlexBox direction="column" gap={SPACING.space2}>
            {trainingsMastered?.map((training) => (
              <TrainingListItem key={training.id} training={training} />
            ))}
          </FlexBox>
        </>
      )}
    </FlexBox>
  )
}

export default TrainingsList

const TrainingListItem = ({ training }: { training: ParentTraining }) => {
  const { completionPercentage } = useTraining(training.id)
  const { firstTherapist } = useCareTeam()

  const router = useRouter()
  const careTeamUserId = router.query.careTeamUserId || firstTherapist?.user.id

  const { trainingId, setTrainingId } = useTourStore()
  const isInitialTraining = training.title === "Getting Started with Joon"

  const onClickTrainingItem = () => {
    router.push(`/care/${careTeamUserId}/academy/${training.id}`)
    trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_PARENT_TRAINING_DETAILS)
  }

  useEffect(() => {
    if (isInitialTraining && trainingId === null) {
      setTrainingId(training.id)
    }
  }, [trainingId, setTrainingId, training.id, isInitialTraining])

  return (
    <button
      className={isInitialTraining ? "tour-step-2" : ""}
      style={{
        padding: SPACING.space4,
        background: JoonUIColor.background.primaryNeutral,
        width: "100%",
        borderRadius: SPACING.space2,
      }}
      onClick={onClickTrainingItem}
    >
      <FlexBox direction="row" wrap={false} align="center">
        <FlexBox direction="column" gap={SPACING.space05}>
          <Typography variant="bodySmallBold" textAlign="left">
            {training.title}
          </Typography>
          {training.status === ParentTrainingStatus.IN_PROGRESS && (
            <LinearProgress
              value={completionPercentage}
              style={{ width: "40%" }}
              colorPrimary={JoonColorExpanded.viridian300}
              displayValue
            />
          )}
        </FlexBox>
        <FlexBox
          align="center"
          justify="center"
          style={{
            width: "fit-content",
            background: JoonUIColor.background.lightGray,
            borderRadius: "50%",
            padding: SPACING.space1,
          }}
        >
          <ChevronRightIcon size={20} color={JoonUIColor.text.primaryAccent} />
        </FlexBox>
      </FlexBox>
    </button>
  )
}
