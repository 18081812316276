import React, { useState, useEffect } from "react"

interface DotdotdotProps {
  speed?: number
  maxDots?: number
}

const Dotdotdot: React.FC<DotdotdotProps> = ({ speed = 500, maxDots = 3 }) => {
  const [dots, setDots] = useState<string>("")

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots.length >= maxDots) return ""
        return prevDots + "."
      })
    }, speed)

    // Clean up the interval on component unmount
    return () => clearInterval(interval)
  }, [speed, maxDots])

  return <span>{dots}</span>
}

export default Dotdotdot
