import {
  ChildUser,
  JoonColorExpanded,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { FlexBox } from "@joonapp/web-shared"
import { JoonUIColor } from "@joonapp/web-shared"
import dayjs from "dayjs"

import DoterChatFlag from "./DoterChatFlag"
import { useDoterChatModalStore } from "./useDoterChatModalStore"
import { useFamilyQuery } from "../../../../networking/queries"
import { DoterChatLog } from "../../../../types"
import { getChildImage, getUserFromFamily } from "../../../../util/util"

const DoterChatLogMessage = ({ log }: { log: DoterChatLog }) => {
  const { childUserId } = useDoterChatModalStore()
  const { data: family } = useFamilyQuery()
  const child = getUserFromFamily(family!, childUserId || 0) as
    | ChildUser
    | undefined

  if (!child) return null

  const doter = child.active_doter
  const doterImage = getChildImage(child)

  return (
    <FlexBox
      direction="column"
      wrap={false}
      gap={SPACING.space4}
      id={log.id.toString()}
    >
      {log.prompt && (
        <FlexBox direction="column" gap={SPACING.space2}>
          <FlexBox
            direction="row"
            gap={SPACING.space2}
            justify="space-between"
            wrap={false}
          >
            <FlexBox
              direction="row"
              gap={SPACING.space2}
              align="center"
              style={{ width: "fit-content" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: JoonColorExpanded.purple600,
                  width: SPACING.space6,
                  height: SPACING.space6,
                  borderRadius: "50%",
                }}
              >
                <Typography
                  variant="bodyXSmall"
                  color={JoonUIColor.text.inverted}
                >
                  {child.name[0].toUpperCase()}
                </Typography>
              </div>
              <Typography variant="bodySmall">{child.name}</Typography>
            </FlexBox>

            <Typography
              variant="bodySmall"
              color={JoonUIColor.text.secondary}
              style={{ fontWeight: 500 }}
            >
              {dayjs(log.date_created).format("MMM D")}
            </Typography>
          </FlexBox>
          <Typography
            color={JoonUIColor.text.inverted}
            variant="bodySmall"
            textAlign="left"
            style={{
              background: JoonColorExpanded.purple600,
              padding: "6px 12px",
              borderRadius: SPACING.space2,
              width: "100%",
            }}
          >
            {log.prompt}
            {log.prompt_flags && log.prompt_flags.length > 0 && (
              <FlexBox
                direction="row"
                gap={SPACING.space2}
                style={{ marginTop: SPACING.space1 }}
              >
                {log.prompt_flags.map((flag) => (
                  <DoterChatFlag key={flag} type={flag} />
                ))}
              </FlexBox>
            )}
          </Typography>
        </FlexBox>
      )}
      <FlexBox direction="column" gap={SPACING.space2}>
        <FlexBox direction="row" gap={SPACING.space2} justify="space-between">
          <FlexBox
            direction="row"
            gap={SPACING.space2}
            style={{ width: "fit-content" }}
            align="center"
          >
            <img
              src={doterImage}
              alt={doter?.name}
              style={{
                width: SPACING.space6,
                height: SPACING.space6,
                borderRadius: "50%",
              }}
            />
            <Typography variant="bodySmall">Doter: {doter?.name}</Typography>
          </FlexBox>
          <Typography
            variant="bodySmall"
            color={JoonUIColor.text.secondary}
            style={{ fontWeight: 500 }}
          >
            {dayjs(log.date_created).format("MMM D")}
          </Typography>
        </FlexBox>
        {log.responses.map((response) => (
          <Typography
            key={response}
            variant="bodySmall"
            style={{
              background: JoonUIColor.background.lightAccent,
              padding: "6px 12px",
              borderRadius: SPACING.space2,
              width: "100%",
            }}
          >
            {response}
          </Typography>
        ))}
      </FlexBox>
    </FlexBox>
  )
}

export default DoterChatLogMessage
