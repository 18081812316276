import { create } from "zustand"

import { ANALYTIC_EVENTS } from "../../../../util/analytics"
import { trackAnalyticEvent } from "../../../../util/analytics"
import { localStorageItems } from "../../../../util/storage"

export enum DoterAIInfoStep {
  STEP_1,
  STEP_2,
  STEP_3,
}

interface DoterAIInfoModalStore {
  isOpen: boolean
  onClose: () => void
  onOpen: (isFTUX?: boolean) => void
  currentStep: DoterAIInfoStep
  setCurrentStep: (currentStep: DoterAIInfoStep) => void
  isOnboarding: boolean
}

export const useDoterAIInfoModalStore = create<DoterAIInfoModalStore>(
  (set) => ({
    isOpen: false,
    onClose: () => {
      set({ isOpen: false })
      localStorage.setItem(localStorageItems.hasSeenDoterAIFTUX, "true")
    },
    onOpen: (isOnboarding = false) => {
      const isFTUX = !localStorage.getItem(localStorageItems.hasSeenDoterAIFTUX)
      trackAnalyticEvent(ANALYTIC_EVENTS.SHOW_DOTER_CHAT_INFORMATIONAL, {
        is_ftux: isFTUX,
      })
      set({
        isOpen: true,
        currentStep: DoterAIInfoStep.STEP_1,
        isOnboarding,
      })
    },
    currentStep: DoterAIInfoStep.STEP_1,
    setCurrentStep: (currentStep: DoterAIInfoStep) => set({ currentStep }),
    isOnboarding: false,
  })
)
