import {
  faFamily,
  faFaceSmileHearts,
  faGift,
} from "@fortawesome/duotone-light-svg-icons"
import {
  DropdownOption,
  Gender,
  QuestRoutine,
  QuestStatus,
} from "@joonapp/web-shared"
import dayjs from "dayjs"

import focus from "./images/icons/focus-icon@2x.png"
import kindness from "./images/icons/kindness-icon@2x.png"
import knowledge from "./images/icons/knowledge-icon@2x.png"
import morning from "./images/icons/morning-routine-icon@2x.png"
import stamina from "./images/icons/stamina-icon@2x.png"
import strength from "./images/icons/strength-icon@2x.png"
import { CancellationSurveyType } from "./types"
import { CancellationSurveyQuestion } from "./types"
import { CoachingLookupKey } from "./types/coachingGroups"
import { QuestTemplateCategory } from "./types/quests"
import { displayStringForTemplateCategory } from "./util/choices/quests"

export const SKILL_MAPPING = {
  knowledge: "Learning & Education",
  stamina: "Mental Health",
  strength: "Health & Fitness",
  kindness: "Relationship Building",
  focus: "Focus & Self Control",
  teamwork: "Chores & Housework",
} as { [key: string]: string }

export const SKILL_DROPDOWN = Object.entries(SKILL_MAPPING).map(
  ([key, value]) => ({
    label: value,
    value: key,
  })
)

export const REPETITIONS = {
  daily: [true, true, true, true, true, true, true],
  noDays: [false, false, false, false, false, false, false],
  weekdays: [false, true, true, true, true, true, false],
  weekends: [true, false, false, false, false, false, true],
}

export enum RecurrenceOption {
  EVERY_DAY,
  WEEKDAYS,
  WEEKENDS,
  WEEKLY_TODAY,
  CUSTOM,
}

export enum StartDateOption {
  TODAY,
  TOMORROW,
  CUSTOM,
}

export const anytimeToggleOptions: DropdownOption[] = [
  { label: "Anytime", value: "anytime" },
  { label: "Time of Day", value: "time-of-day" },
]

export const RECURRENCE_OPTIONS: DropdownOption[] = [
  {
    label: "Every day",
    value: RecurrenceOption.EVERY_DAY,
  },
  {
    label: "Weekdays",
    value: RecurrenceOption.WEEKDAYS,
  },
  {
    label: "Weekends",
    value: RecurrenceOption.WEEKENDS,
  },
  {
    label: `Every week on ${dayjs().format("ddd")}`,
    value: RecurrenceOption.WEEKLY_TODAY,
  },
  {
    label: "Custom...",
    value: RecurrenceOption.CUSTOM,
  },
]

export const timerMinuteOptions = Array.from({ length: 60 }, (_, i) => ({
  value: `${i}`,
  label: `${i} min`,
}))

export const timerSecondOptions = Array.from({ length: 60 }, (_, i) => ({
  value: `${i}`,
  label: `${i} sec`,
}))

export const difficultyRewards = {
  easy: 4,
  medium: 10,
  hard: 20,
}

export const ageGroups = {
  six_under: {
    value: "six_under",
    label: "6 & Under",
  },
  seven_nine: {
    value: "seven_nine",
    label: "7-9",
  },
  ten_twelve: {
    value: "ten_twelve",
    label: "10-12",
  },
  thirteen_up: {
    value: "thirteen_up",
    label: "13 & Over",
  },
}

export const PREFERENCE_KEY = {
  hidesFutureRoutines: "hides_future_routines",
  morningLimit: "morning_limit",
  afternoonLimit: "afternoon_limit",
  nightLimit: "night_limit",
  afternoonStartTime: "afternoon_start_time",
  nightStartTime: "night_start_time",
  enableDoterWishes: "enable_doter_wishes",
  enableDoterChat: "enable_doter_chat",
} as const

export const periodDropdownOptions = [
  {
    label: "All Time",
    value: "all_time",
    endDate: dayjs().format("YYYY-MM-DD"),
    startDate: null,
  },
  {
    label: "This Week",
    value: "this_week",
    endDate: dayjs().format("YYYY-MM-DD"),
    startDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
  },
  {
    label: "This Month",
    value: "this_month",
    endDate: dayjs().format("YYYY-MM-DD"),
    startDate: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
  },
  {
    label: "Custom",
    value: "custom",
    endDate: dayjs().format("YYYY-MM-DD"),
    startDate: null,
  },
]
export type PeriodDropdownOption = (typeof periodDropdownOptions)[number]

export const templateCategoryDropdownOptions = Object.values(
  QuestTemplateCategory
).map((category) => ({
  value: category,
  label: displayStringForTemplateCategory(category),
}))

export const parentQuestItems = [
  {
    icon: faFaceSmileHearts,
    title: "Lead by example and get in on the fun",
    description:
      "Create quests for yourself, lead your family by example, and earn coins when you do your quests!",
  },
  {
    icon: faGift,
    title:
      "Use earned coins to send surprise gifts straight to your kid's game!",
    description:
      "Send your kid accessories they can use in their Joon App Game and get extra parent brownie points!",
  },
  {
    icon: faFamily,
    title: "Families that quest together, stay together",
    description: "Collaborate together and keep each other accountable!",
  },
]

export const completeQuestStatuses = [
  QuestStatus.COMPLETED,
  QuestStatus.REDEEMED,
  QuestStatus.VERIFIED,
]

export enum QUERY_KEYS {
  QUEST_BOARD = "quest-board",
  QUEST_BOARD_REORDER = "quest-board-reorder",
  QUEST_INSTANCE_STATS = "quest-instance-stats",
  QUEST_REVIEW = "quest-review",
  QUEST_LOG = "quest-log",
  QUESTIONNAIRES = "questionnaires",
  CARE_TEAM = "care-team",
  COACH_INTAKE_FORM = "coach-intake-form",
  COACH_ONBOARD_INTAKE_FORM = "coach-onboard-intake-form",
  COACH_POST_SELECTION = "coach-post-selection",
  COACH_PROFILES = "coach-profiles",
  COACH_SUMMARY = "coach-summary",
  RECOMMENDATION_QUESTIONNAIRES = "recommendation-questionnaires",
  USER = "user",
  FAMILY = "family",
  FAMILY_MAIL = "family-mails",
  UNREAD_MAIL = "family-unread-mails",
  INVENTORY = "inventory-items",
  SHOP_ITEMS = "shop-items",
  SUBSCRIBER_INFO = "subscriber-info",
  CHILD_AUTH_CODE = "child-auth-code",
  PARENT_AUTH_CODE = "parent-auth-code",
  CUSTOM_REWARDS = "custom-rewards",
  CUSTOM_REWARD_LOGS = "custom-reward-logs",
  CUSTOM_REWARD_LOGS_STATS = "custom-reward-logs-stats",
  REWARD_TEMPLATES = "reward-templates",
  ONBOARDING_REWARD_TEMPLATES = "onboarding-reward-templates",
  QUEST_TEMPLATES = "quest-templates",
  NOTIFICATION_PREFERENCES = "notification-preferences",
  SB_CREDENTIALS = "sb-credentials",
  THERAPISTS = "therapists",
  THERAPIST_INVITES = "therapist-invites",
  EXPERIMENTS = "experiments",
  BONUS_REWARDS = "bonus-rewards",
  AVERAGE_COINS_EARNED_THIS_WEEK = "average-coins-earned-this-week",
  COIN_TRANSACTIONS = "coin-transactions",
  COIN_CUMULATIVE_STATS = "coin-cumulative-stats",
  NETWORK_INFO = "network-info",
  COUNTRY_CODE = "country-code",
  PARENT_TASKS = "parent-tasks",
  PARENT_TASK_INSTANCES = "parent-task-instances",
  INCIDENT_TEMPLATES = "incident-templates",
  TRAININGS = "trainings",
  FAMILY_CHECKOUT_LINK = "family-checkout-link",
  SUBSCRIPTION_PACKAGES = "subscription-packages",
  COACH_SUBSCRIPTION = "coach-subscription",
  SESSION_BILLING_PERIODS = "session-billing-periods",
  SESSION_BOOKINGS = "session-bookings",
  COACHING_GROUPS = "coaching-groups",
  SESSION_BOOKING = "session-booking",
  CUSTOM_REWARD_BALANCES = "custom-reward-balances",
  SCREEN_TIME_BALANCES = "screen-time-balances",
  AGREEMENT_LINKS = "agreement-links",
  DOTER_CHAT_LOGS = "doter-chat-logs",
  FLAGGED_DOTER_CHAT_LOGS = "flagged-doter-chat-logs",
}

export const questRoutineOptions: DropdownOption[] = [
  {
    label: "All",
    value: null,
  },
  {
    label: "Morning",
    value: QuestRoutine.MORNING,
  },
  {
    label: "Afternoon",
    value: QuestRoutine.AFTERNOON,
  },
  {
    label: "Night",
    value: QuestRoutine.NIGHT,
  },
  {
    label: "Anytime",
    value: QuestRoutine.ANYTIME,
  },
]

export const GRADIENTS = {
  gradient100: `linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 2.83%,
    rgba(255, 255, 255, 0) 51.53%,
    rgba(255, 255, 255, 0.8) 99.81%
  ),
  #e9eafa`,
}

export const BACKGROUND_COLORS = {
  xlightBlueBg: "#f7fbfe",
  lightBlueBg: "#f3f9fd",
}

export const GENDER_OPTIONS = [
  { label: "Girl", value: Gender.FEMALE },
  { label: "Boy", value: Gender.MALE },
  { label: "Other", value: Gender.OTHER },
  { label: "Prefer not to answer", value: null },
]

export const ACTIVE_COACHING_LOOKUP_KEY = CoachingLookupKey.STANDARD

export const COACH_PLANS = {
  [CoachingLookupKey.TEXT_ONLY]: {
    plan: "Starter",
    frequency: null,
  },
  [CoachingLookupKey.ONE_CALL]: {
    plan: "Basic",
    frequency: "1x flexible session with your coach/month",
  },
  [CoachingLookupKey.STANDARD]: {
    plan: "Plus",
    frequency: "2x flexible sessions with your coach/month",
  },
  [CoachingLookupKey.FOUR_CALLS]: {
    plan: "Pro",
    frequency: "4x flexible sessions with your coach/month",
  },
}

export const CoachingQuestionText = {
  [CancellationSurveyType.COACH]: {
    [CancellationSurveyQuestion.WHY_LEAVING]:
      "Why didn't coaching work for you?",
    [CancellationSurveyQuestion.ALTERNATIVE_SOLUTIONS]:
      "What will you use going forward for parenting resources?",
  },
  [CancellationSurveyType.FAMILY]: {
    [CancellationSurveyQuestion.WHY_LEAVING]: "Why didn't Joon work for you?",
    [CancellationSurveyQuestion.ALTERNATIVE_SOLUTIONS]:
      "What will you use going forward to motivate your child?",
  },
} as const

export enum CoachPaywallChoice {
  WITH_COACH = "withCoach",
  EXIT_WITH_COACH = "exitWithCoach",
  APP_ONLY = "appOnly",
}

export enum SmartRedemptionModalStep {
  ANNOUNCEMENT,
  INTRO,
  DEVICE_SELECTION,
  SETUP_PASSCODE,
  SETUP_JOONITY,
  FINISH,
}

export enum SmartRedemptionAnalyticsStep {
  DEVICE_SELECTION = "device_selection",
  SETUP_PASSCODE = "setup_passcode",
  SETUP_JOONITY = "setup_joonity",
  FINISH = "finish",
}

export const userIcons = {
  1: stamina,
  2: morning,
  3: focus,
  4: strength,
  5: kindness,
  6: knowledge,
  8: stamina,
  9: morning,
  10: focus,
  11: strength,
  12: kindness,
  13: knowledge,
} as any

export const priorityCountries = [
  { code: "1", flag: "🇺🇸", country: "United States" },
  { code: "1", flag: "🇨🇦", country: "Canada" },
  { code: "7", flag: "🇷🇺", country: "Russia" },
  { code: "7", flag: "🇰🇿", country: "Kazakhstan" },
  { code: "44", flag: "🇬🇧", country: "United Kingdom" },
  { code: "61", flag: "🇦🇺", country: "Australia" },
  { code: "64", flag: "🇳🇿", country: "New Zealand" },
  { code: "91", flag: "🇮🇳", country: "India" },
]

export const countryCodeList = [
  { code: "1", flag: "🇺🇸", country: "United States" },
  { code: "1", flag: "🇨🇦", country: "Canada" },
  { code: "7", flag: "🇷🇺", country: "Russia" },
  { code: "7", flag: "🇰🇿", country: "Kazakhstan" },
  { code: "20", flag: "🇪🇬", country: "Egypt" },
  { code: "27", flag: "🇿🇦", country: "South Africa" },
  { code: "30", flag: "🇬🇷", country: "Greece" },
  { code: "31", flag: "🇳🇱", country: "Netherlands" },
  { code: "32", flag: "🇧🇪", country: "Belgium" },
  { code: "33", flag: "🇫🇷", country: "France" },
  { code: "34", flag: "🇪🇸", country: "Spain" },
  { code: "36", flag: "🇭🇺", country: "Hungary" },
  { code: "39", flag: "🇮🇹", country: "Italy" },
  { code: "40", flag: "🇷🇴", country: "Romania" },
  { code: "41", flag: "🇨🇭", country: "Switzerland" },
  { code: "43", flag: "🇦🇹", country: "Austria" },
  { code: "44", flag: "🇬🇧", country: "United Kingdom" },
  { code: "45", flag: "🇩🇰", country: "Denmark" },
  { code: "46", flag: "🇸🇪", country: "Sweden" },
  { code: "47", flag: "🇳🇴", country: "Norway" },
  { code: "48", flag: "🇵🇱", country: "Poland" },
  { code: "49", flag: "🇩🇪", country: "Germany" },
  { code: "52", flag: "🇲🇽", country: "Mexico" },
  { code: "53", flag: "🇨🇺", country: "Cuba" },
  { code: "54", flag: "🇦🇷", country: "Argentina" },
  { code: "55", flag: "🇧🇷", country: "Brazil" },
  { code: "56", flag: "🇨🇱", country: "Chile" },
  { code: "57", flag: "🇨🇴", country: "Colombia" },
  { code: "58", flag: "🇻🇪", country: "Venezuela" },
  { code: "61", flag: "🇦🇺", country: "Australia" },
  { code: "62", flag: "🇮🇩", country: "Indonesia" },
  { code: "63", flag: "🇵🇭", country: "Philippines" },
  { code: "64", flag: "🇳🇿", country: "New Zealand" },
  { code: "65", flag: "🇸🇬", country: "Singapore" },
  { code: "66", flag: "🇹🇭", country: "Thailand" },
  { code: "81", flag: "🇯🇵", country: "Japan" },
  { code: "82", flag: "🇰🇷", country: "South Korea" },
  { code: "84", flag: "🇻🇳", country: "Vietnam" },
  { code: "86", flag: "🇨🇳", country: "China" },
  { code: "91", flag: "🇮🇳", country: "India" },
  { code: "92", flag: "🇵🇰", country: "Pakistan" },
  { code: "93", flag: "🇦🇫", country: "Afghanistan" },
  { code: "94", flag: "🇱🇰", country: "Sri Lanka" },
  { code: "95", flag: "🇲🇲", country: "Myanmar" },
  { code: "98", flag: "🇮🇷", country: "Iran" },
  { code: "211", flag: "🇸🇸", country: "South Sudan" },
  { code: "212", flag: "🇲🇦", country: "Morocco" },
  { code: "212", flag: "🇪🇭", country: "Western Sahara" },
  { code: "213", flag: "🇩🇿", country: "Algeria" },
  { code: "216", flag: "🇹🇳", country: "Tunisia" },
  { code: "218", flag: "🇱🇾", country: "Libya" },
  { code: "220", flag: "🇬🇲", country: "Gambia" },
  { code: "221", flag: "🇸🇳", country: "Senegal" },
  { code: "222", flag: "🇲🇷", country: "Mauritania" },
  { code: "223", flag: "🇲🇱", country: "Mali" },
  { code: "224", flag: "🇬🇳", country: "Guinea" },
  { code: "225", flag: "🇨🇮", country: "Ivory Coast" },
  { code: "226", flag: "🇧🇫", country: "Burkina Faso" },
  { code: "227", flag: "🇳🇪", country: "Niger" },
  { code: "228", flag: "🇹🇬", country: "Togo" },
  { code: "229", flag: "🇧🇯", country: "Benin" },
  { code: "230", flag: "🇲🇺", country: "Mauritius" },
  { code: "231", flag: "🇱🇷", country: "Liberia" },
  { code: "232", flag: "🇸🇱", country: "Sierra Leone" },
  { code: "233", flag: "🇬🇭", country: "Ghana" },
  { code: "234", flag: "🇳🇬", country: "Nigeria" },
  { code: "235", flag: "🇹🇩", country: "Chad" },
  { code: "236", flag: "🇨🇫", country: "Central African Republic" },
  { code: "237", flag: "🇨🇲", country: "Cameroon" },
  { code: "238", flag: "🇨🇻", country: "Cape Verde" },
  { code: "239", flag: "🇸🇹", country: "São Tomé and Príncipe" },
  { code: "240", flag: "🇬🇶", country: "Equatorial Guinea" },
  { code: "241", flag: "🇬🇦", country: "Gabon" },
  { code: "242", flag: "🇨🇬", country: "Republic of the Congo" },
  { code: "243", flag: "🇨🇩", country: "Democratic Republic of the Congo" },
  { code: "244", flag: "🇦🇴", country: "Angola" },
  { code: "245", flag: "🇬🇼", country: "Guinea-Bissau" },
  { code: "246", flag: "🇮🇴", country: "British Indian Ocean Territory" },
  { code: "247", flag: "🇸🇭", country: "Ascension Island" },
  { code: "248", flag: "🇸🇨", country: "Seychelles" },
  { code: "249", flag: "🇸🇩", country: "Sudan" },
  { code: "250", flag: "🇷🇼", country: "Rwanda" },
  { code: "251", flag: "🇪🇹", country: "Ethiopia" },
  { code: "252", flag: "🇸🇴", country: "Somalia" },
  { code: "253", flag: "🇩🇯", country: "Djibouti" },
  { code: "254", flag: "🇰🇪", country: "Kenya" },
  { code: "255", flag: "🇹🇿", country: "Tanzania" },
  { code: "256", flag: "🇺🇬", country: "Uganda" },
  { code: "257", flag: "🇧🇮", country: "Burundi" },
  { code: "258", flag: "🇲🇿", country: "Mozambique" },
  { code: "260", flag: "🇿🇲", country: "Zambia" },
  { code: "261", flag: "🇲🇬", country: "Madagascar" },
  { code: "262", flag: "🇷🇪", country: "Réunion" },
  { code: "262", flag: "🇾🇹", country: "Mayotte" },
  { code: "263", flag: "🇿🇼", country: "Zimbabwe" },
  { code: "264", flag: "🇳🇦", country: "Namibia" },
  { code: "265", flag: "🇲🇼", country: "Malawi" },
  { code: "266", flag: "🇱🇸", country: "Lesotho" },
  { code: "267", flag: "🇧🇼", country: "Botswana" },
  { code: "268", flag: "🇸🇿", country: "Eswatini" },
  { code: "269", flag: "🇰🇲", country: "Comoros" },
  { code: "290", flag: "🇸🇭", country: "Saint Helena" },
  { code: "291", flag: "🇪🇷", country: "Eritrea" },
  { code: "297", flag: "🇦🇼", country: "Aruba" },
  { code: "298", flag: "🇫🇴", country: "Faroe Islands" },
  { code: "299", flag: "🇬🇱", country: "Greenland" },
  { code: "350", flag: "🇬🇮", country: "Gibraltar" },
  { code: "351", flag: "🇵🇹", country: "Portugal" },
  { code: "352", flag: "🇱🇺", country: "Luxembourg" },
  { code: "353", flag: "🇮🇪", country: "Ireland" },
  { code: "354", flag: "🇮🇸", country: "Iceland" },
  { code: "355", flag: "🇦🇱", country: "Albania" },
  { code: "356", flag: "🇲🇹", country: "Malta" },
  { code: "357", flag: "🇨🇾", country: "Cyprus" },
  { code: "358", flag: "🇫🇮", country: "Finland" },
  { code: "359", flag: "🇦🇽", country: "Åland Islands" },
  { code: "359", flag: "🇧🇬", country: "Bulgaria" },
  { code: "370", flag: "🇱🇹", country: "Lithuania" },
  { code: "371", flag: "🇱🇻", country: "Latvia" },
  { code: "372", flag: "🇪🇪", country: "Estonia" },
  { code: "373", flag: "🇲🇩", country: "Moldova" },
  { code: "374", flag: "🇦🇲", country: "Armenia" },
  { code: "375", flag: "🇧🇾", country: "Belarus" },
  { code: "376", flag: "🇦🇩", country: "Andorra" },
  { code: "377", flag: "🇲🇨", country: "Monaco" },
  { code: "378", flag: "🇸🇲", country: "San Marino" },
  { code: "379", flag: "🇻🇦", country: "Vatican City" },
  { code: "380", flag: "🇺🇦", country: "Ukraine" },
  { code: "381", flag: "🇷🇸", country: "Serbia" },
  { code: "382", flag: "🇲🇪", country: "Montenegro" },
  { code: "383", flag: "🇽🇰", country: "Kosovo" },
  { code: "385", flag: "🇭🇷", country: "Croatia" },
  { code: "386", flag: "🇸🇮", country: "Slovenia" },
  { code: "387", flag: "🇧🇦", country: "Bosnia and Herzegovina" },
  { code: "389", flag: "🇲🇰", country: "North Macedonia" },
  { code: "420", flag: "🇨🇿", country: "Czech Republic" },
  { code: "421", flag: "🇸🇰", country: "Slovakia" },
  { code: "423", flag: "🇱🇮", country: "Liechtenstein" },
  { code: "500", flag: "🇫🇰", country: "Falkland Islands" },
  { code: "501", flag: "🇧🇿", country: "Belize" },
  { code: "502", flag: "🇬🇹", country: "Guatemala" },
  { code: "503", flag: "🇸🇻", country: "El Salvador" },
  { code: "504", flag: "🇭🇳", country: "Honduras" },
  { code: "505", flag: "🇳🇮", country: "Nicaragua" },
  { code: "506", flag: "🇨🇷", country: "Costa Rica" },
  { code: "507", flag: "🇵🇦", country: "Panama" },
  { code: "508", flag: "🇵🇲", country: "Saint Pierre and Miquelon" },
  { code: "509", flag: "🇭🇹", country: "Haiti" },
  { code: "590", flag: "🇬🇵", country: "Guadeloupe" },
  { code: "591", flag: "🇧🇴", country: "Bolivia" },
  { code: "592", flag: "🇬🇾", country: "Guyana" },
  { code: "593", flag: "🇪🇨", country: "Ecuador" },
  { code: "594", flag: "🇬🇫", country: "French Guiana" },
  { code: "595", flag: "🇵🇾", country: "Paraguay" },
  { code: "596", flag: "🇲🇶", country: "Martinique" },
  { code: "597", flag: "🇸🇷", country: "Suriname" },
  { code: "598", flag: "🇺🇾", country: "Uruguay" },
  { code: "599", flag: "🇨🇼", country: "Curaçao" },
  { code: "599", flag: "🇧🇶", country: "Bonaire, Sint Eustatius and Saba" },
  { code: "670", flag: "🇹🇱", country: "Timor-Leste" },
  { code: "672", flag: "🇦🇶", country: "Antarctica" },
  { code: "672", flag: "🇳🇫", country: "Norfolk Island" },
  { code: "673", flag: "🇧🇳", country: "Brunei" },
  { code: "674", flag: "🇳🇷", country: "Nauru" },
  { code: "675", flag: "🇵🇬", country: "Papua New Guinea" },
  { code: "676", flag: "🇹🇴", country: "Tonga" },
  { code: "677", flag: "🇸🇧", country: "Solomon Islands" },
  { code: "678", flag: "🇻🇺", country: "Vanuatu" },
  { code: "679", flag: "🇫🇯", country: "Fiji" },
  { code: "680", flag: "🇵🇼", country: "Palau" },
  { code: "681", flag: "🇼🇫", country: "Wallis and Futuna" },
  { code: "682", flag: "🇨🇰", country: "Cook Islands" },
  { code: "683", flag: "🇳🇺", country: "Niue" },
  { code: "685", flag: "🇼🇸", country: "Samoa" },
  { code: "686", flag: "🇰🇮", country: "Kiribati" },
  { code: "687", flag: "🇳🇨", country: "New Caledonia" },
  { code: "688", flag: "🇹🇻", country: "Tuvalu" },
  { code: "689", flag: "🇵🇫", country: "French Polynesia" },
  { code: "690", flag: "🇹🇰", country: "Tokelau" },
  { code: "691", flag: "🇫🇲", country: "Micronesia" },
  { code: "692", flag: "🇲🇭", country: "Marshall Islands" },
  { code: "850", flag: "🇰🇵", country: "North Korea" },
  { code: "852", flag: "🇭🇰", country: "Hong Kong" },
  { code: "853", flag: "🇲🇴", country: "Macau" },
  { code: "855", flag: "🇰🇭", country: "Cambodia" },
  { code: "856", flag: "🇱🇦", country: "Laos" },
  { code: "880", flag: "🇧🇩", country: "Bangladesh" },
  { code: "886", flag: "🇹🇼", country: "Taiwan" },
  { code: "960", flag: "🇲🇻", country: "Maldives" },
  { code: "961", flag: "🇱🇧", country: "Lebanon" },
  { code: "962", flag: "🇯🇴", country: "Jordan" },
  { code: "963", flag: "🇸🇾", country: "Syria" },
  { code: "964", flag: "🇮🇶", country: "Iraq" },
  { code: "965", flag: "🇰🇼", country: "Kuwait" },
  { code: "966", flag: "🇸🇦", country: "Saudi Arabia" },
  { code: "967", flag: "🇾🇪", country: "Yemen" },
  { code: "968", flag: "🇴🇲", country: "Oman" },
  { code: "970", flag: "🇵🇸", country: "Palestine" },
  { code: "971", flag: "🇦🇪", country: "United Arab Emirates" },
  { code: "972", flag: "🇮🇱", country: "Israel" },
  { code: "973", flag: "🇧🇭", country: "Bahrain" },
  { code: "974", flag: "🇶🇦", country: "Qatar" },
  { code: "975", flag: "🇧🇹", country: "Bhutan" },
  { code: "976", flag: "🇲🇳", country: "Mongolia" },
  { code: "977", flag: "🇳🇵", country: "Nepal" },
  { code: "992", flag: "🇹🇯", country: "Tajikistan" },
  { code: "993", flag: "🇹🇲", country: "Turkmenistan" },
  { code: "994", flag: "🇦🇿", country: "Azerbaijan" },
  { code: "995", flag: "🇬🇪", country: "Georgia" },
  { code: "996", flag: "🇰🇬", country: "Kyrgyzstan" },
  { code: "998", flag: "🇺🇿", country: "Uzbekistan" },
]

export const isVercelDeployment =
  window.location.hostname.endsWith(".vercel.app")
