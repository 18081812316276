import {
  FlexBox,
  SPACING,
  JoonUIColor,
  Typography,
  Switch,
  DropdownInput,
} from "@joonapp/web-shared"
import { create } from "zustand"

import { CloseButton } from "../../../../components/closeButton/CloseButton"
import SlideUpModal from "../../../../components/slideUpModal/SlideUpModal"
import { timerMinuteOptions, timerSecondOptions } from "../../../../constants"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

export interface TimerModalStoreProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  showTimer: boolean
  setShowTimer: (showTimer: boolean) => void
}

export const useTimerModalStore = create<TimerModalStoreProps>()((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
  showTimer: false,
  setShowTimer: (showTimer) => set({ showTimer }),
}))

export const TimerModal = () => {
  const { minutes, seconds, setMinutes, setSeconds } = useAddEditQuestStore()
  const { isOpen, onClose, showTimer, setShowTimer } = useTimerModalStore()

  const onCloseModal = () => {
    onClose()
    if (minutes === null && seconds === null) setShowTimer(false)
  }

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onCloseModal}
      size="fit-content"
      backgroundType="overlay"
      zIndex={1000}
    >
      <FlexBox
        direction="column"
        align="center"
        wrap={false}
        fullWidth
        gap={SPACING.space4}
        style={{
          position: "relative",
          backgroundColor: JoonUIColor.background.xlightGray,
          padding: SPACING.space4,
        }}
      >
        <CloseButton onClick={onCloseModal} />
        <Typography variant="bodyBold" textAlign="left">
          Set a timer
        </Typography>
        <FlexBox
          direction="column"
          gap={SPACING.space2}
          style={{
            padding: SPACING.space3,
            borderRadius: SPACING.space2,
            background: JoonUIColor.background.primaryNeutral,
          }}
        >
          <FlexBox
            direction="row"
            gap={SPACING.space2}
            align="center"
            justify="space-between"
          >
            <Typography variant="bodySmallBold">Add a timer</Typography>
            <Switch
              checked={showTimer}
              onChange={() => {
                if (showTimer) {
                  setShowTimer(false)
                  setMinutes(null)
                  setSeconds(null)
                } else setShowTimer(true)
              }}
              name="add-timer"
            />
          </FlexBox>
          <Typography variant="bodyXSmall" color={JoonUIColor.text.secondary}>
            Your child will be able to see and turn on a timer set to specified
            amount of time below. Best used for tasks that may benefit from a
            short burst of focus time!
          </Typography>
        </FlexBox>
        {showTimer && (
          <FlexBox
            direction="row"
            justify="center"
            gap={SPACING.space2}
            align="center"
            wrap={false}
          >
            <DropdownInput
              name="minute"
              options={timerMinuteOptions}
              label={minutes?.label}
              onSelect={setMinutes}
              placeholder="Minutes"
              style={{ background: JoonUIColor.background.primaryNeutral }}
              fullWidth
            />
            <DropdownInput
              name="second"
              options={timerSecondOptions}
              label={seconds?.label}
              onSelect={setSeconds}
              placeholder="Seconds"
              style={{ background: JoonUIColor.background.primaryNeutral }}
              fullWidth
            />
          </FlexBox>
        )}
      </FlexBox>
    </SlideUpModal>
  )
}
