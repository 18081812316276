import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"

export const BackArrowButton = ({
  onClick,
  text = "Back",
}: {
  onClick: () => void
  text?: string
}) => {
  return (
    <button onClick={onClick} style={{ padding: "0" }}>
      <FlexBox align="center" gap={SPACING.space2}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.292969 7.29365C-0.0976562 7.68428 -0.0976562 8.31865 0.292969 8.70928L4.29297 12.7093C4.68359 13.0999 5.31797 13.0999 5.70859 12.7093C6.09922 12.3187 6.09922 11.6843 5.70859 11.2937L3.41484 8.9999H14.9992C15.5523 8.9999 15.9992 8.55303 15.9992 7.9999C15.9992 7.44678 15.5523 6.9999 14.9992 6.9999H3.41484L5.70859 4.70615C6.09922 4.31553 6.09922 3.68115 5.70859 3.29053C5.31797 2.8999 4.68359 2.8999 4.29297 3.29053L0.292969 7.29053V7.29365Z"
            fill="#7B838E"
          />
        </svg>

        <Typography variant="bodySmallBold" color={JoonUIColor.text.secondary}>
          {text}
        </Typography>
      </FlexBox>
    </button>
  )
}
