import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { useState } from "react"

interface BaseActionButtonProps {
  icon: any
  primaryColor: string
  secondaryColor: string
  label: string
  onClick: () => void
  disabled?: boolean
}

export const BaseActionButton = ({
  icon,
  primaryColor,
  secondaryColor,
  label,
  onClick,
  disabled = false,
}: BaseActionButtonProps) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <button
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: SPACING.space1,
        flex: 1,
        cursor: disabled ? "default" : "pointer",
        background:
          isHovered && !disabled ? JoonUIColor.background.xlightGray : "unset",
        borderRadius: SPACING.space2,
        padding: `${SPACING.space2} ${SPACING.space0}`,
        opacity: disabled ? 0.5 : 1,
      }}
      onClick={disabled ? undefined : onClick}
      onMouseEnter={() => !disabled && setIsHovered(true)}
      onMouseLeave={() => !disabled && setIsHovered(false)}
    >
      <FontAwesomeIcon
        icon={icon}
        fontSize={24}
        style={{
          // @ts-ignore
          "--fa-primary-color": primaryColor,
          "--fa-secondary-color": secondaryColor,
        }}
      />
      <Typography variant="body" color={JoonUIColor.text.disabled}>
        {label}
      </Typography>
    </button>
  )
}
