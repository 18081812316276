import {
  faArrowsRotate,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  FlexBox,
  JoonUIColor,
  QuestStatus,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useMemo } from "react"
import { useShallow } from "zustand/react/shallow"

import { useCatchUpStore } from "./useCatchUpStore"

const CatchUpCardStatusOverlay = () => {
  const questStatus = useCatchUpStore(useShallow((state) => state.questStatus))

  const overlayStyles = useMemo(() => {
    switch (questStatus) {
      case QuestStatus.REJECTED:
        return {
          background: "rgba(255, 233, 233, 0.80)",
          primaryColor: JoonUIColor.semantic.alert,
          icon: (
            <FontAwesomeIcon
              icon={faThumbsDown}
              color={JoonUIColor.semantic.alert}
              fontSize="54px"
            />
          ),
          text: "Rejected!",
        }
      case QuestStatus.VERIFIED:
        return {
          background: "rgba(220, 252, 244, 0.80)",
          primaryColor: JoonUIColor.semantic.success,
          icon: (
            <FontAwesomeIcon
              icon={faThumbsUp}
              color={JoonUIColor.semantic.success}
              fontSize="54px"
            />
          ),
          text: "Approved!",
        }
      case QuestStatus.RETRY:
        return {
          background: "rgba(233, 233, 255, 0.80)",
          primaryColor: JoonUIColor.semantic.primary,
          icon: (
            <FontAwesomeIcon
              icon={faArrowsRotate}
              color={JoonUIColor.semantic.primary}
              fontSize="54px"
            />
          ),
          text: "Retry requested!",
        }
      default:
        return {}
    }
  }, [questStatus])

  if (!questStatus) return <></>

  return (
    <FlexBox
      direction="row"
      align="center"
      justify="center"
      wrap={false}
      gap={SPACING.space3}
      style={{
        height: "100%",
        width: "100%",
        border: `3px solid ${overlayStyles.primaryColor}`,
        background: overlayStyles.background,
        position: "absolute",
        top: 0,
        borderRadius: SPACING.space4,
        zIndex: 4000,
      }}
    >
      {overlayStyles.icon}
      <Typography variant="h2" color={overlayStyles.primaryColor}>
        {overlayStyles.text}
      </Typography>
    </FlexBox>
  )
}
export default CatchUpCardStatusOverlay
