import { AuthCodeType, FamilyChildProfile } from "@joonapp/web-shared"
import axios, { AxiosResponse } from "axios"

import { PREFERENCE_KEY } from "../constants"
import {
  AuthCode,
  FamilyMail,
  FamilyMailboxTab,
  InventoryItem,
  Invitation,
  NotificationPreferences,
  QuestionnairesInterface,
  TherapistProfile,
} from "../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../util/analytics"
import { createJoonAPIClient } from "../util/joon-api"

export async function addEmergencyContact({
  name,
  lastName,
  phoneNumber,
}: {
  name: string
  lastName?: string
  phoneNumber: string
}) {
  const API = createJoonAPIClient()
  return API.post(`api/emergency-contacts/`, {
    name,
    last_name: lastName,
    phone_number: phoneNumber,
  }).then((res) => res.data)
}

export const loadQuestionnaires = async () => {
  const API = createJoonAPIClient()
  return API.get<{ results: QuestionnairesInterface[] }>(
    "api/onboarding-questionnaires/"
  ).then((response) => response.data.results)
}

export const loadRecommendedQuestionnaires = async (childId: number) => {
  const API = createJoonAPIClient()
  return API.get<{ results: any[] }>("api/questionnaires/", {
    params: { user_id: childId, used_for_recommendations: true },
  }).then((response) => response.data.results)
}

export const loadCoachIntakeFormQuestionnaire = async () => {
  const API = createJoonAPIClient()
  return API.get<{ results: QuestionnairesInterface[] }>(
    "api/questionnaires/",
    {
      params: { title: "coach_intake_form" },
    }
  ).then((response) => {
    if (response?.data?.results?.length === 0) return null
    return response.data.results[0]
  })
}

export const loadCoachOnboardIntakeFormQuestionnaire = async () => {
  const API = createJoonAPIClient()
  return API.get<{ results: QuestionnairesInterface[] }>(
    "api/questionnaires/all/",
    { params: { title: "coach_intake_form" } }
  ).then((response) => {
    if (response?.data?.results?.length === 0) return null
    return response.data.results[0]
  })
}

export const loadCoachPostSelectionQuestionnaire = async () => {
  const API = createJoonAPIClient()
  return API.get<{ results: QuestionnairesInterface[] }>(
    "api/questionnaires/",
    {
      params: { title: "coach_post_selection" },
    }
  ).then((response) => {
    if (response?.data?.results?.length === 0) return null
    return response.data.results[0]
  })
}

export async function loadFamilyMail(
  userId: number,
  tab: FamilyMailboxTab,
  isUnread?: boolean
) {
  const joonApiClient = createJoonAPIClient()
  try {
    const response = await joonApiClient.get<{ results: FamilyMail[] }>(
      "api/family-mails/",
      {
        params: {
          user_id: userId,
          sender_id: tab === FamilyMailboxTab.SENT ? userId : undefined,
          receiver_id: tab === FamilyMailboxTab.RECEIVED ? userId : undefined,
          is_unread: isUnread,
        },
      }
    )
    return response.data.results
  } catch (error) {
    throw error
  }
}

export async function loadInventoryItems(userId: number) {
  const joonApiClient = createJoonAPIClient()
  try {
    const response = await joonApiClient.get<{ results: InventoryItem[] }>(
      "api/inventory-items/",
      {
        params: {
          user_id: userId,
          is_gift_item: false,
        },
      }
    )
    return response.data.results
  } catch (error) {
    throw error
  }
}

export async function sendMail(
  senderId: number,
  receiverId: number,
  body: string,
  itemGiftId?: number
) {
  const joonApiClient = createJoonAPIClient()
  return joonApiClient.post("api/family-mails/", {
    sender_id: senderId,
    receiver_id: receiverId,
    body: body,
    item_gift_id: itemGiftId || undefined,
  })
}

export async function markMailAsRead(familyMailId: number, userId: number) {
  const joonApiClient = createJoonAPIClient()
  try {
    return joonApiClient.post(`api/family-mails/${familyMailId}/read/`, {
      user_id: userId,
    })
  } catch (error) {
    throw error
  }
}

export function getNetworkInfo() {
  // If ipv6 exists, use that.
  return axios
    .get("https://api6.ipify.org?format=json")
    .then((response) => response.data.ip)
    .catch((_) => {
      // If ipv6 doesn't exist, the previous network request will fail. default to ipv4
      return axios
        .get("https://api64.ipify.org?format=json")
        .then((response) => response.data.ip)
    })
}

export function getLocationInfo() {
  return axios.get<{
    country_code: string
    region_code: string
    city: string
    postal: string
  }>("https://ipapi.co/json/")
}

export const getChildAuthCode = async () => {
  const API = createJoonAPIClient()
  const data = await API.get<{ results: AuthCode[] }>(`api/auth-codes`, {
    params: { type: AuthCodeType.CHILD_LOGIN },
  })
  return data
}

export const getParentAuthCode = async () => {
  const API = createJoonAPIClient()
  return API.get<{ results: AuthCode[] }>(`api/auth-codes`, {
    params: {
      type: AuthCodeType.COPARENT,
    },
  })
}

export const validateAuthCode = async (
  code: string,
  type: string = AuthCodeType.COPARENT
) => {
  const API = createJoonAPIClient()
  return API.get(`api/auth-codes/validate/`, { params: { code, type } })
}

export const joinFamily = async (code: string, nickname: string) => {
  const API = createJoonAPIClient()
  try {
    return API.post("api/families/join/", { code, nickname })
  } catch (error) {
    throw error
  }
}

export const removeCoParent = async (familyId: number, coparentId: number) => {
  const API = createJoonAPIClient()
  return API.post(`api/families/${familyId}/remove-member/`, {
    user_id: coparentId,
  })
}

export const getTherapists = async (children: FamilyChildProfile[]) => {
  const API = createJoonAPIClient()
  const therapistQueryPromises = children.map((child) =>
    API.get<{ results: TherapistProfile[] }>(
      `api/users/${child.user.id}/linked-therapists/`
    ).then((res) => ({
      [child.user.id]: res.data.results,
    }))
  )
  return Promise.all(therapistQueryPromises)
}

export const getTherapistInvites: {
  (): Promise<AxiosResponse<{ results: Invitation[] }>>
} = async () => {
  const API = createJoonAPIClient()
  return API.get<{ results: Invitation[] }>("api/invitations/", {
    params: { is_sender: true },
  })
}

export const sendTherapistInvitation = async (
  therapistEmail: string,
  childrenIds: number[]
) => {
  const API = createJoonAPIClient()

  trackAnalyticEvent(ANALYTIC_EVENTS.INVITE_THERAPIST_EMAIL)

  return API.post("api/invitations/", {
    invitee_email: therapistEmail,
    subject_user_ids: childrenIds,
    type: "link_therapist_patient",
  })
}

export const updateGamePreference = async ({
  childId,
  key,
  value,
}: {
  childId: number
  key: (typeof PREFERENCE_KEY)[keyof typeof PREFERENCE_KEY]
  value: any
}) => {
  const API = createJoonAPIClient()
  return API.patch(`api/users/${childId}/game-preferences/`, { [key]: value })
}

export const toggleRoutineTimeLimits = async ({
  childId,
  value,
}: {
  childId: number
  value: boolean
}) => {
  const promises = [
    PREFERENCE_KEY.morningLimit,
    PREFERENCE_KEY.afternoonLimit,
    PREFERENCE_KEY.nightLimit,
  ].map((key) => updateGamePreference({ childId, key, value }))
  return Promise.all(promises)
}

export const togglePause = async ({
  doterId,
  isPaused,
}: {
  doterId: number
  isPaused: boolean
}) => {
  const API = createJoonAPIClient()
  return API.post(`api/doters/${doterId}/${isPaused ? "unpause" : "pause"}/`)
}

export const getNotificationPreferences = async (userId: number) => {
  const API = createJoonAPIClient()
  return API.get<NotificationPreferences>(
    `api/users/${userId}/notification-preferences/`
  ).then((response) => response.data)
}

export const updateNotificationPreferences = async ({
  userId,
  receiveSms,
  coachCheckinTime,
}: {
  userId: number
  receiveSms?: boolean
  coachCheckinTime?: string // format is "20:30:00" to represent 8:30pm
}) => {
  const API = createJoonAPIClient()
  return API.patch(`api/users/${userId}/notification-preferences/`, {
    receive_sms: receiveSms,
    coach_checkin_time: coachCheckinTime,
  })
}

export const updatePhoneNumber = async ({
  userId,
  phoneNumber,
}: {
  userId: number
  phoneNumber: string
}) => {
  const API = createJoonAPIClient()
  return API.patch(`api/users/${userId}/`, { phone_number: phoneNumber })
}
