import { FlexBox, JoonColorExpanded, JoonUIColor } from "@joonapp/web-shared"
import { useEffect, useRef } from "react"

import CoachingFlow from "./coachingFlow/CoachingFlow"
import { useCoachingStore } from "./useCoachingStore"
import { CoachingStep } from "../../types/coachingGroups"
import { requireAuth } from "../../util/auth"

const CoachingPage = () => {
  const { step, questionnaireStep } = useCoachingStore()
  const pageContainerRef = useRef<HTMLDivElement>(null)

  const getBackgroundColor = () => {
    switch (step) {
      case CoachingStep.LANDING:
      case CoachingStep.INTAKE_FORM:
      case CoachingStep.MATCHING:
      case CoachingStep.POST_SELECTION:
      case CoachingStep.SCHEDULED:
        return JoonUIColor.background.primaryNeutral
      case CoachingStep.RECOMMENDED_COACHES:
      case CoachingStep.COACH_SUMMARY:
        return JoonColorExpanded.indigo100
      default:
        return JoonColorExpanded.yellow100
    }
  }

  useEffect(() => {
    pageContainerRef.current?.scrollTo(0, 0)
  }, [step, questionnaireStep])

  return (
    <FlexBox
      ref={pageContainerRef}
      direction="row"
      justify="center"
      wrap={false}
      style={{
        flexGrow: 1,
        overflowX: "hidden",
        overflowY: "auto",
        minHeight: "100dvh",
        maxHeight: "100dvh",
        textAlign: "center",
        backgroundColor: getBackgroundColor(),
      }}
    >
      <CoachingFlow />
    </FlexBox>
  )
}

const CoachingPageWithAuth = requireAuth(CoachingPage)
export default CoachingPageWithAuth
