import {
  Button,
  FamilyChildProfile,
  FamilyParentProfile,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
  UserRole,
  toPercentage,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useMemo } from "react"
import { useShallow } from "zustand/react/shallow"

import Card from "../../components/card/Card"
import { ProgressCardSkeleton } from "../../components/skeleton/Skeleton"
import {
  useFamilyQuery,
  useQuestInstanceStatsQuery,
  useUserQuery,
} from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"
import { getChildImage, getParentAvatarImage } from "../../util/util"
import { useGetChildStartedModal } from "../family/GetChildStartedModal"
import { useAddQuestTemplatesModalStore } from "../questBoard/addQuestTemplates/useAddQuestTemplatesModalStore"
import { useQuestTemplatesStore } from "../questBoard/addQuestTemplates/useQuestTemplatesStore"
import { useQBDatePickerStore } from "../questBoard/QuestBoardDatePicker"
import useQuestBoardStore from "../questBoard/useQuestBoardStore"

const TodaysProgressSection = () => {
  const { user } = useUserQuery()
  const { data: family, isLoading } = useFamilyQuery()

  const familyWithUserFirst = (family?.profiles || []).sort((a, b) =>
    a.user.id === user?.id ? -1 : b.user.id === user?.id ? 1 : 0
  )

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space2}
      style={{
        border: `1px solid ${JoonUIColor.border.default}`,
        background: JoonUIColor.background.primaryNeutral,
        borderRadius: SPACING.space4,
        padding: SPACING.space4,
      }}
    >
      <Typography variant="bodyBold">Today's Progress</Typography>

      {isLoading ? (
        <ProgressCardSkeleton />
      ) : (
        familyWithUserFirst.map((profile) => (
          <UserProgressCard key={profile.user.id} profile={profile} />
        ))
      )}
    </FlexBox>
  )
}

const UserProgressCard = ({
  profile,
}: {
  profile: FamilyChildProfile | FamilyParentProfile
}) => {
  const { user } = useUserQuery()
  const { data } = useQuestInstanceStatsQuery(profile.user.id)
  const router = useRouter()
  const { onOpen: openGetChildStartedModal } = useGetChildStartedModal()
  const setSelectedChild = useQuestTemplatesStore(
    useShallow((state) => state.setSelectedChild)
  )
  const openQuestTemplates = useAddQuestTemplatesModalStore(
    useShallow((state) => state.onOpen)
  )
  const { setSelectedUserId } = useQuestBoardStore(
    useShallow((state) => ({
      setSelectedUserId: state.setSelectedUserId,
    }))
  )
  const { setQuestBoardDate } = useQBDatePickerStore(
    useShallow((state) => ({
      setQuestBoardDate: state.setQuestBoardDate,
    }))
  )

  const noQuestsToday = data?.num_total_today === 0
  const routineNotSetUp = data?.num_user_created === 0
  const notYetOnboarded =
    profile.role === UserRole.CHILD &&
    profile?.user.active_doter.date_hatched === null

  const progress = useMemo(() => {
    if (!data || !data.num_total_today || !data?.num_user_created)
      return undefined
    return +toPercentage(
      (data.num_total_today - data.num_to_complete) / data.num_total_today
    )
  }, [data])

  const subtitle = useMemo(() => {
    if (notYetOnboarded) return "Not yet onboarded"
    if (routineNotSetUp) return "Routine not set up"
    if (noQuestsToday) return "No Quests for today"
    return undefined
  }, [notYetOnboarded, routineNotSetUp, noQuestsToday])

  const buttonComponent = useMemo(() => {
    if (notYetOnboarded)
      return (
        <Button
          size="small"
          buttonType="secondary"
          text="Onboard"
          onClick={() => {
            trackAnalyticEvent(ANALYTIC_EVENTS.CLICK_TODAYS_PROGRESS, {
              type: "onboard",
            })
            openGetChildStartedModal(profile.user.id)
          }}
          style={{ width: "70px", whiteSpace: "nowrap" }}
        />
      )
    if (routineNotSetUp)
      return (
        <Button
          size="small"
          buttonType="secondary"
          text="Set Up"
          onClick={() => {
            openQuestTemplates()
            setSelectedChild({
              label: profile.user.name,
              value: profile.user.id,
            })
            trackAnalyticEvent(ANALYTIC_EVENTS.CLICK_TODAYS_PROGRESS, {
              type: "setup_routine",
            })
            router.push("/quests")
            setSelectedUserId(profile.user.id)
          }}
          style={{ width: "70px", whiteSpace: "nowrap" }}
        />
      )
    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notYetOnboarded, routineNotSetUp])

  return (
    <Card
      title={
        <FlexBox direction="row" gap={SPACING.space2} align="center">
          <img
            src={
              profile.role === UserRole.CHILD
                ? getChildImage(profile.user) || ""
                : getParentAvatarImage(profile.user.avatar) || ""
            }
            alt="Child avatar"
            style={{
              width: SPACING.space6,
              height: SPACING.space6,
              borderRadius: SPACING.space6,
            }}
          />
          <Typography variant="bodySmallBold">
            {profile.user.id === user?.id ? "Me" : profile.user.name}
          </Typography>
        </FlexBox>
      }
      subtitle={subtitle}
      onClick={() => {
        if (buttonComponent) return
        trackAnalyticEvent(ANALYTIC_EVENTS.CLICK_TODAYS_PROGRESS, {
          type: "show_progress",
        })
        router.push("/quests")
        setSelectedUserId(profile.user.id)
        setQuestBoardDate(dayjs())
      }}
      buttonComponent={buttonComponent}
      type="secondary"
      percentage={progress}
      style={{
        ...(buttonComponent
          ? {
              background: "rgba(255, 255, 255, 0.6)",
              transition: "none",
              cursor: "default",
              transform: "none",
            }
          : {}),
      }}
    />
  )
}

export default TodaysProgressSection
