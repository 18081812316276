import { FamilyChildProfile, UserRole } from "@joonapp/web-shared"
import { AxiosResponse } from "axios"

import { trackAnalyticEvent } from "./analytics"
import { queryClient } from "./queryClient"
import { localStorageItems } from "./storage"
import { QUERY_KEYS } from "../constants"
import { submitQuestionnaire } from "../networking/authentication"
import {
  ParentDataInterface,
  QuestionAnswerMappingInterface,
  QuestionnairesInterface,
} from "../types"

export const submitParentQuestionnaires = async (
  onboardingQuestionnaires: QuestionnairesInterface[],
  parentData: any,
  parentId: number
) => {
  const parentQuestionnairePromises: Promise<AxiosResponse<any, any>>[] = []
  onboardingQuestionnaires
    .filter((questionnaire: any) => questionnaire.type === "parent")
    .forEach((questionnaire: any) => {
      const answers = Object.keys(parentData.parentQuestionnaireMapping)
        .filter((questionId) =>
          questionnaire.questions.some(
            (question: any) => `${question.id}` === questionId
          )
        )
        .map((questionId) => {
          return {
            question_id: Number(questionId),
            answer_ids: parentData.parentQuestionnaireMapping[questionId],
          }
        })
      parentQuestionnairePromises.push(
        submitQuestionnaire(questionnaire.id, parentId, answers)
      )
    })
  await Promise.all(parentQuestionnairePromises)
}

export const submitChildQuestionnaires = async (
  onboardingQuestionnaires: QuestionnairesInterface[],
  parentData: ParentDataInterface,
  children: FamilyChildProfile[]
) => {
  const childQuestionnairePromises: Promise<AxiosResponse<any, any>>[] = []
  const childrenQuestionnaires = onboardingQuestionnaires.filter(
    (questionnaire) => questionnaire.type === UserRole.CHILD
  )
  children.forEach((child) => {
    const childQuestionnaireMapping = parentData.childrenData.find(
      (childData) => childData.first_name === child.user.name
    )?.questionAnswerMapping
    if (!childQuestionnaireMapping)
      return trackAnalyticEvent("childQuestionnaireMapping not found")

    childrenQuestionnaires.forEach((questionnaire) => {
      const answers = Object.keys(childQuestionnaireMapping)
        .filter((questionId) =>
          questionnaire.questions.some(
            (question) => `${question.id}` === questionId
          )
        )
        .map((questionId) => {
          const question_id = Number(questionId)
          return {
            question_id: question_id,
            answer_ids: childQuestionnaireMapping[question_id] as number[],
          }
        })
      childQuestionnairePromises.push(
        submitQuestionnaire(questionnaire.id, child.user.id, answers)
      )
    })
  })
  await Promise.all(childQuestionnairePromises)
}

export const submitCoachQuestionnaire = async (
  parentId: number,
  questionnaire: QuestionnairesInterface,
  questionAnswerMapping: QuestionAnswerMappingInterface
) => {
  const answers = Object.keys(questionAnswerMapping).map((questionId) => {
    if (typeof questionAnswerMapping[Number(questionId)] === "string") {
      return {
        question_id: Number(questionId),
        freeform_answer: questionAnswerMapping[Number(questionId)] as string,
      }
    } else {
      return {
        question_id: Number(questionId),
        answer_ids: questionAnswerMapping[Number(questionId)] as number[],
      }
    }
  })
  await submitQuestionnaire(questionnaire.id, parentId, answers)
  queryClient.invalidateQueries([QUERY_KEYS.COACH_INTAKE_FORM])
}

export const getQIDMapping = (questionnaire: any) => {
  return {
    id: questionnaire.id,
    questions: questionnaire.questions.map((q: any) => q.id),
  }
}

export const getChildQuestions = (questionnaires: any) => {
  const childQuestionnaires = questionnaires.filter(
    (q: any) => q.type === "child"
  )
  const allQuestions = childQuestionnaires.map((q: any) => q.questions).flat()
  const questionIdMap = childQuestionnaires.map(getQIDMapping)
  return { allQuestions, questionIdMap }
}
export const getParentQuestions = (questionnaires: any) => {
  const childQuestionnaires = questionnaires.filter(
    (q: any) => q.type === "parent"
  )
  const allQuestions = childQuestionnaires.map((q: any) => q.questions).flat()
  const questionIdMap = childQuestionnaires.map(getQIDMapping)
  return { allQuestions, questionIdMap }
}

export const getAllQuestions = (
  onboardingQuestionnaires: QuestionnairesInterface[]
) => {
  const parentQuestions = onboardingQuestionnaires
    .filter((questionnaire) => questionnaire.type === "parent")
    .map((questionnaire) => questionnaire.questions)
    .flat()
  const childQuestions = onboardingQuestionnaires
    .filter((questionnaire) => questionnaire.type === "child")
    .sort((q1, q2) => q1.id - q2.id)
    .map((questionnaire) => questionnaire.questions)
    .flat()
  return { parentQuestions, childQuestions, onboardingQuestionnaires }
}

export const setNumPreferredSessions = (
  questionAnswerMapping: QuestionAnswerMappingInterface
) => {
  const frequencyQuestionAnswer = questionAnswerMapping[539]?.[0]
  if (frequencyQuestionAnswer) {
    let frequency
    switch (frequencyQuestionAnswer) {
      case 824: // "2-3 times per week"
      case 825: // "Once a week"
        frequency = 4
        break
      case 826: // "Every other week"
        frequency = 2
        break
      case 829: // I'd prefer async messaging only
        frequency = 0
        break
      default:
        frequency = 1
    }

    localStorage.setItem(
      localStorageItems.numPreferredSessions,
      frequency.toString()
    )
  }
}
