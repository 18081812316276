import {
  Button,
  CloseIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import GiftItem from "./GiftItem"
import { useGiftShopModalStore } from "./useGiftShopModalStore"
import { useSelectGiftModalStore } from "./useSelectGiftModalStore"
import { useSendMessageModalStore } from "./useSendMessageModalStore"
import SlideUpModal from "../../../components/slideUpModal/SlideUpModal"
import { GRADIENTS } from "../../../constants"
import { useInventoryGiftsQuery } from "../../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

const SelectGiftModal = () => {
  const { isOpen, onClose } = useSelectGiftModalStore()
  const { selectedGift, setSelectedGift } = useSendMessageModalStore()
  const { data: gifts } = useInventoryGiftsQuery()
  const { onOpen: onOpenGiftShopModal } = useGiftShopModalStore()

  const onCancel = () => {
    setSelectedGift(null)
    onClose()
  }

  const onPurchaseMoreItems = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.PURCHASE_MORE_ITEMS)
    onClose()
    onOpenGiftShopModal()
  }

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onCancel}
      showCard={true}
      size="fit-content"
      backgroundType="overlay"
    >
      <button
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          padding: SPACING.space2,
        }}
        onClick={onCancel}
      >
        <CloseIcon color={JoonUIColor.icon.neutral} />
      </button>
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        wrap={false}
        style={{
          padding: `${SPACING.space6} ${SPACING.space6} 0`,
          background: GRADIENTS.gradient100,
        }}
      >
        <Typography variant="h3">My inventory</Typography>

        <Typography variant="bodySmall">
          Select which gift you'd like to attach to this mail
        </Typography>

        <FlexBox
          direction="column"
          gap={SPACING.space2}
          style={{
            paddingBottom: SPACING.space4,
            overflowY: "scroll",
          }}
          wrap={false}
        >
          {gifts?.map((gift) => <GiftItem key={gift.id} gift={gift} />)}
          <button
            onClick={onPurchaseMoreItems}
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: SPACING.space2,
            }}
          >
            <Typography
              variant="bodySmall"
              color={JoonUIColor.text.primaryAccent}
            >
              + Purchase more items
            </Typography>
          </button>
        </FlexBox>
      </FlexBox>
      <FlexBox
        fullWidth
        style={{
          position: "sticky",
          bottom: 0,
          height: "fit-content",
          padding: SPACING.space6,
          borderTop: `1px solid ${JoonUIColor.border.default}`,
          background: JoonUIColor.background.primaryNeutral,
        }}
      >
        <Button
          text="Add to mail"
          onClick={onClose}
          fullWidth
          disabled={!selectedGift}
        />
      </FlexBox>
    </SlideUpModal>
  )
}

export default SelectGiftModal
