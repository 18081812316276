import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  Switch,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import useTextMessageSettingsStore from "./useTextMessageSettingsModalStore"
import { useNotificationPreferencesMutation } from "../../components/modals/addPhoneNumberModal/useNotificationPreferencesMutation"
import { usePhoneNumberMutation } from "../../components/modals/addPhoneNumberModal/usePhoneNumberMutation"
import { PhoneNumberInput } from "../../components/phoneNumberInput/PhoneNumberInput"
import TimeInputNew from "../../components/timeInput/TimeInputNew"
import { useUserQuery } from "../../networking/queries"
import { useNotificationPreferencesQuery } from "../../networking/queries"
import { SetPhoneNumberSource } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

const TextMessageSettings = () => {
  const { data: notificationPreferences } = useNotificationPreferencesQuery()
  const { user } = useUserQuery()
  const {
    isOpen,
    onClose,
    notificationTime,
    setNotificationTime,
    phoneNumber,
    setPhoneNumber,
    isTextMessageEnabled,
    setIsTextMessageEnabled,
    isSaving,
    setIsSaving,
  } = useTextMessageSettingsStore()
  const notificationPreferencesMutation = useNotificationPreferencesMutation()
  const phoneNumberMutation = usePhoneNumberMutation()

  useEffect(() => {
    setNotificationTime(notificationPreferences?.coach_checkin_time || "")
    setPhoneNumber(user?.phone_number || "")
    setIsTextMessageEnabled(notificationPreferences?.receive_sms || false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationPreferences, user])

  const onSave = async () => {
    setIsSaving(true)
    const promises = []
    if (phoneNumber !== user?.phone_number && phoneNumber.length > 5) {
      promises.push(phoneNumberMutation.mutateAsync(phoneNumber))
    }
    promises.push(
      notificationPreferencesMutation.mutateAsync({
        receiveSms: isTextMessageEnabled,
        coachCheckinTime: notificationTime || undefined,
      })
    )

    Promise.all(promises).then(() => {
      trackAnalyticEvent(ANALYTIC_EVENTS.SET_PHONE_NUMBER, {
        source: SetPhoneNumberSource.SETTINGS,
      })
      onClose()
      setIsSaving(false)
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      displayCloseIcon={false}
      animate
      style={{ width: "min(500px, 95vw)" }}
    >
      <ModalHeader
        title="Text message notification settings"
        onClose={onClose}
        showBorderBottom
      />
      <FlexBox
        direction="column"
        gap={SPACING.space6}
        style={{
          padding: SPACING.space6,
          paddingTop: SPACING.space4,
        }}
      >
        <FlexBox
          direction="column"
          gap={SPACING.space2}
          style={{
            background: JoonUIColor.background.xlightGray,
            borderRadius: SPACING.space2,
            padding: SPACING.space2,
          }}
        >
          <FlexBox
            direction="row"
            gap={SPACING.space2}
            justify="space-between"
            align="center"
          >
            <Typography variant="bodyBold">
              Enable text message notifications
            </Typography>
            <Switch
              checked={isTextMessageEnabled || false}
              onChange={() => setIsTextMessageEnabled(!isTextMessageEnabled)}
              name="Enable text message notifications"
              style={{ transform: "scale(0.8)" }}
            />
          </FlexBox>
          <Typography variant="bodyXSmall">
            We send an SMS message when your care team member has messaged you
            to help make sure you know a message was received. Your number will
            not be shared with any third parties. Reply STOP any time to cancel,
            or HELP for more info.
          </Typography>
        </FlexBox>
        {isTextMessageEnabled && (
          <>
            <FlexBox direction="column" gap={SPACING.space2}>
              <Typography variant="bodyBold">Phone number</Typography>
              <PhoneNumberInput
                initialPhoneNumber={user?.phone_number || ""}
                setPhoneNumber={setPhoneNumber}
              />
            </FlexBox>
            {notificationPreferences?.coach_checkin_time && (
              <FlexBox direction="column" justify="space-between">
                <Typography variant="bodyBold">
                  Best time to reach me
                </Typography>
                <Typography
                  variant="bodySmall"
                  style={{ marginBottom: SPACING.space2 }}
                >
                  We’ll use this information to ensure that you’re getting SMS
                  notifications during your most available time.
                </Typography>
                <TimeInputNew
                  value={notificationTime || ""}
                  setValue={setNotificationTime}
                />
              </FlexBox>
            )}
          </>
        )}
        <FlexBox
          direction="row"
          gap={SPACING.space4}
          justify="flex-end"
          align="center"
        >
          <TextButton onClick={onClose}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </TextButton>
          <Button text="Save" onClick={onSave} isLoading={isSaving} />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default TextMessageSettings
