import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { useState } from "react"

import useAIQuest from "./hooks/useAIQuest"
import useAIQuestStore from "./hooks/useAIQuestStore"
import AnimatedStars from "../../../components/animatedStars/AnimatedStars"
import CustomTextArea from "../../../components/textArea/CustomTextArea"

enum SuggestionType {
  CREATE = "create",
  EDIT = "edit",
}

const PromptTextArea = ({
  placeholder = "Ask Jooniper to create quests to manage your child's routine, teach them a new skill, or even ideate for fun things your child can do!",
  showSuggestions = true,
}) => {
  const { prompt, setPrompt } = useAIQuestStore()
  const [suggestionType, setSuggestionType] = useState<SuggestionType | null>(
    null
  )
  const { generateResponse, initializeChatSession } = useAIQuest()

  const handleShareFeedback = () => {
    window.open(
      "https://forms.gle/ZcCXH7z6Li6HKtTE9",
      "_blank",
      "noopener,noreferrer"
    )
  }

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space2}
      style={{ zIndex: 1, position: "relative" }}
    >
      {showSuggestions && (
        <div style={{ width: "100%" }}>
          {suggestionType ? (
            <QuestPromptSuggestions
              type={suggestionType}
              selectSuggestion={(suggestion) => {
                initializeChatSession()
                setPrompt(suggestion)
                setSuggestionType(null)
              }}
            />
          ) : (
            prompt.length === 0 && (
              <FlexBox direction="column" gap={SPACING.space2}>
                <SuggestionTypeButton
                  text="Create new Quests"
                  onClick={() => setSuggestionType(SuggestionType.CREATE)}
                />
                <SuggestionTypeButton
                  text="Modify existing Quests"
                  onClick={() => setSuggestionType(SuggestionType.EDIT)}
                />
              </FlexBox>
            )
          )}
        </div>
      )}

      <form
        onSubmit={generateResponse}
        style={{ width: "100%", background: "white" }}
      >
        <FlexBox
          direction="row"
          align="center"
          wrap={false}
          className="customized-message-input"
          gap={SPACING.space1}
          style={{
            border: `1px solid ${JoonUIColor.border.default}`,
            borderRadius: SPACING.space2,
          }}
        >
          <CustomTextArea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            maxLength={255}
            placeholder={placeholder}
            style={{
              maxHeight: "200px",
              fontSize: "16px",
              overflow: "auto",
              border: "none",
            }}
            hideBorder
            defaultRows={2}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault()
                generateResponse(e)
              }
            }}
          />
          {prompt.length > 0 && <SendMessageButton />}
        </FlexBox>
      </form>
      <FlexBox direction="column">
        <Typography
          variant="bodyXSmall"
          textAlign="left"
          color={JoonUIColor.text.secondary}
        >
          Jooniper is still in beta and may make mistakes.
        </Typography>
        <button onClick={handleShareFeedback}>
          <Typography
            variant="bodyXSmallBold"
            textAlign="left"
            color={JoonUIColor.text.primaryAccent}
          >
            Share feedback here
          </Typography>
        </button>
      </FlexBox>
    </FlexBox>
  )
}

export default PromptTextArea

const SendMessageButton = () => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <button
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: SPACING.space5,
        marginTop: "auto",
        marginBottom: "auto",
        marginLeft: "auto",
        marginRight: SPACING.space1,
        height: "100%",
        width: SPACING.space8,
        borderRadius: SPACING.space2,
        background: isHovered ? JoonUIColor.background.xlightGray : "white",
      }}
      type="submit"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <FontAwesomeIcon
        icon={faArrowUp}
        color={JoonUIColor.icon.neutral}
        fontSize={16}
      />
    </button>
  )
}

const QuestPromptSuggestions = ({
  type,
  selectSuggestion,
}: {
  type: SuggestionType
  selectSuggestion: (suggestion: string) => void
}) => {
  const suggestions = getRandomPrompts(
    type === "create" ? createSuggestions : editSuggestions,
    4
  )

  return (
    <FlexBox
      direction="column"
      style={{
        position: "absolute",
        bottom: "100%",
        background: "white",
        borderRadius: SPACING.space2,
      }}
    >
      <Typography
        variant="bodySmall"
        textAlign="left"
        color={JoonUIColor.text.secondary}
        style={{ marginBottom: SPACING.space1 }}
      >
        Starter Prompts:
      </Typography>
      {suggestions.map((suggestion) => (
        <button
          style={{
            padding: SPACING.space2,
            width: "100%",
            background: JoonUIColor.background.lightGray,
            borderRadius: SPACING.space2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: SPACING.space2,
            gap: SPACING.space2,
          }}
          onClick={() => selectSuggestion(suggestion)}
        >
          <Typography variant="bodySmall" textAlign="left">
            {suggestion}
          </Typography>
          <FontAwesomeIcon
            icon={faArrowDown}
            color={JoonUIColor.icon.neutral}
          />
        </button>
      ))}
    </FlexBox>
  )
}

const SuggestionTypeButton = ({
  text,
  onClick,
}: {
  text: string
  onClick: () => void
}) => {
  return (
    <button
      style={{
        padding: SPACING.space1,
        border: `1px solid ${JoonUIColor.border.default}`,
        borderRadius: SPACING.space2,
        display: "flex",
        alignItems: "flex-end",
        gap: SPACING.space2,
        background: "white",
      }}
      onClick={onClick}
    >
      <AnimatedStars
        animate={false}
        color={JoonUIColor.semantic.primary}
        scale={0.8}
      />
      <Typography variant="bodySmall" textAlign="left">
        {text}
      </Typography>
    </button>
  )
}

const getRandomPrompts = (bank: string[], numPrompts: number) => {
  const shuffledBank = bank.sort(() => Math.random() - 0.5)
  return shuffledBank.slice(0, numPrompts)
}

const createSuggestions = [
  "Create a morning routine for my child who struggles with getting ready for school",
  "Make a step-by-step morning checklist for my child who needs visual reminders",
  "Design a morning routine that helps my child be more independent",
  "Create age-appropriate chores for my children to do around the house",
  "Create a calming bedtime routine for my child who resists sleep",
  "Create homework quests for my child who has trouble focusing",
  "Make reading quests for my child who's learning to read",
  "Create a bedroom cleaning checklist for my messy child",
  "Make a night routine for my child who takes too long to get ready for bed",
  "Design a bedtime checklist that helps my anxious child wind down",
  "Make conversation starter quests for my child with social anxiety",
  "Design quests to help my child manage emotions when frustrated",
  "Create hygiene quests for my child who forgets to brush teeth",
  "Design healthy eating quests for my picky eater",
  "Create quests to help siblings reduce conflict",
]

const editSuggestions = [
  "Simplify the bedtime routine for my overwhelmed child",
  "Adjust homework quests to be more manageable for my struggling child",
  "Make our morning routine more challenging as my child grows",
  "Update our morning routine for summer vacation schedule",
  "Modify our after-school quests for the new school year",
  "Adapt our weekend chores for winter when we're indoors more",
  "Add more fun elements to my child's chore quests",
  "Increase rewards for homework quests to boost motivation",
  "Make bedtime quests more engaging for my resistant child",
  "Modify quests to help my child with impulse control during homework",
  "Update routines to reduce screen time battles with my child",
  "Adjust quests to help my child remember to clean up after activities",
  "Modify social quests to help my child with turn-taking",
  "Update routines to accommodate my child's new sports practice schedule",
]
