import { faWarning } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import Overlay from "./Overlay"
import { useCoachOverlayStore } from "./useCoachOverlay"
import { getCoachingStripeBillingUrl } from "../../../networking/subscriptions"
import { CoachOverlayType } from "../../../types"

const FailedPaymentOverlay = () => {
  const [isLoadingBilling, setIsLoadingBilling] = useState(false)
  const { currentOverlay } = useCoachOverlayStore()

  if (currentOverlay !== CoachOverlayType.EXPIRED_SUBSCRIPTION) return <></>

  const redirectToBilling = async () => {
    if (isLoadingBilling) return
    setIsLoadingBilling(true)
    const billingUrl = await getCoachingStripeBillingUrl()
    window.open(billingUrl, "_self")
    setIsLoadingBilling(false)
  }

  return (
    <Overlay color={JoonColorExpanded.red100}>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row gap-2 items-center">
          <FontAwesomeIcon
            icon={faWarning}
            fontSize="14px"
            color={JoonUIColor.semantic.destructive}
          />
          <Typography
            variant="bodyBold"
            color={JoonUIColor.semantic.destructive}
          >
            We ran into an issue processing your payment!
          </Typography>
        </div>
        <Typography
          variant="bodySmall"
          color={JoonUIColor.semantic.destructive}
        >
          To ensure you can retain access to your coach before your subscription
          expires, please update your payment details.
        </Typography>
        <Button
          text="Update payment"
          buttonType="primary"
          onClick={redirectToBilling}
          style={{
            borderRadius: SPACING.space2,
            padding: `${SPACING.space2} ${SPACING.space3}`,
            marginTop: SPACING.space2,
          }}
          fullWidth
          isLoading={isLoadingBilling}
        />
      </div>
    </Overlay>
  )
}

export default FailedPaymentOverlay
