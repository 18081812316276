import { faFamily } from "@fortawesome/duotone-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { JoonUIColor, IconProps } from "@joonapp/web-shared"

const FamilyIcon = ({
  color = JoonUIColor.icon.accent,
  size = 30,
}: IconProps) => {
  return <FontAwesomeIcon icon={faFamily} fontSize={size} color={color} />
}

export default FamilyIcon
