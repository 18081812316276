import { JoonUIColor } from "@joonapp/web-shared"
import { JoonColorExpanded } from "@joonapp/web-shared"

const NotificationDot2 = ({ size = "7px" }: { size?: string }) => {
  return (
    <div
      style={{
        width: size,
        minWidth: size,
        minHeight: size,
        height: size,
        boxSizing: "content-box",
        border: `2px solid ${JoonColorExpanded.red100}`,
        borderRadius: "50%",
        background: JoonUIColor.background.alert,
      }}
    />
  )
}

export default NotificationDot2
