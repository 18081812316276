import { FlexBox, SPACING, Typography } from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import OnboardingPageContentWrapper from "./onboardingPageContentWrapper/OnboardingPageContentWrapper"
import useMediaQuery from "../../hooks/useMediaQuery"
import useOnboardContext from "../../hooks/useOnboardContext"
import LinearProgress from "../linearProgress/LinearProgress"
function CreatingPlan() {
  const [progress, setProgress] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const { setCreatingPlan } = useOnboardContext()
  const isSmallMobile = useMediaQuery("(max-width: 500px)")
  const timerLength = 7000
  const maxPercent = 100
  const numIntervals = 60

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === maxPercent) return maxPercent
        const step = maxPercent / numIntervals
        return Math.min(oldProgress + step, maxPercent)
      })
    }, timerLength / numIntervals)
    const timer = setTimeout(() => setCreatingPlan(false), timerLength)

    return () => {
      clearInterval(interval)
      clearTimeout(timer)
    }
  }, [setCreatingPlan])

  const fadeOut =
    progress < 2 ||
    (progress > 32 && progress < 37) ||
    (progress > 67 && progress < 72)

  return (
    <OnboardingPageContentWrapper backgroundImageOnDesktop="url('/images/background/cloud-bg.png')">
      <FlexBox
        direction="column"
        align="center"
        justify="center"
        gap={SPACING.space4}
      >
        <img
          src={
            progress < 35
              ? "/images/gifs/onboarding-clipboard-gif.gif"
              : progress < 70
                ? "/images/gifs/onboarding-zoom-search-gif.gif"
                : "/images/gifs/onboarding-routine-cycle-gif.gif"
          }
          alt="Loading"
          style={{
            ...(isSmallMobile
              ? { width: "160px", height: "160px" }
              : { width: "200px", height: "200px" }),
            opacity: isLoading || fadeOut ? 0 : 1,
            transition: "opacity 0.1s ease-in-out",
          }}
          onLoad={() => setIsLoading(false)}
        />
        <Typography
          variant="h2Serif"
          textAlign="center"
          style={{
            marginBottom: SPACING.space4,
            height: "64px",
            width: "100%",
            opacity: fadeOut ? 0 : 1,
            transition: "opacity 0.1s ease-in-out",
          }}
        >
          {progress < 35
            ? "Analyzing your survey responses"
            : progress < 70
              ? "Zooming in on key focus areas for each child!"
              : "Finalizing a personalized plan for your family"}
        </Typography>
        <LinearProgress value={progress} withIcon />
      </FlexBox>
    </OnboardingPageContentWrapper>
  )
}

export default CreatingPlan
