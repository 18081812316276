import { create } from "zustand"

import { InventoryItem } from "../../../types"

interface SendMessageModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  selectedChildId: number
  setSelectedChildId: (selectedChild: number) => void
  message: string
  setMessage: (message: string) => void
  selectedGift: InventoryItem | null
  setSelectedGift: (selectedGift: InventoryItem | null) => void
}

export const useSendMessageModalStore = create<SendMessageModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () =>
      set({
        isOpen: false,
        selectedChildId: 0,
        message: "",
        selectedGift: null,
      }),
    selectedChildId: 0,
    setSelectedChildId: (selectedChildId) => set({ selectedChildId }),
    message: "",
    setMessage: (message) => set({ message }),
    selectedGift: null,
    setSelectedGift: (selectedGift) => set({ selectedGift }),
  })
)
