import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import toast from "react-hot-toast"

import { useGiftShopModalStore } from "./useGiftShopModalStore"
import { useUserQuery } from "../../../networking/queries"
import { ShopItem as ShopItemType } from "../../../types"
import { getShopItemText } from "../../../util/util"

interface ShopItemProps {
  shopItem: ShopItemType
}

const ShopItem = ({ shopItem }: ShopItemProps) => {
  const { user } = useUserQuery()
  const { selectedShopItem, setSelectedShopItem } = useGiftShopModalStore()

  const onClick = () => {
    if (shopItem.price <= (user?.coins ?? 0)) setSelectedShopItem(shopItem)
    else {
      toast.dismiss()
      toast.error("Not enough coins")
    }
  }

  return (
    <FlexBox
      key={shopItem.id}
      direction="row"
      align="center"
      justify="flex-start"
      wrap={false}
      gap={SPACING.space2}
      onClick={onClick}
      style={{
        border:
          selectedShopItem?.id === shopItem.id
            ? `1px solid ${JoonUIColor.border.accent}`
            : `1px solid ${JoonUIColor.border.default}`,
        backgroundColor: JoonUIColor.background.primaryNeutral,
        padding: SPACING.space2,
        borderRadius: SPACING.space2,
        cursor: "pointer",
      }}
    >
      <img
        src={shopItem.image_url}
        style={{
          width: "15vw",
          maxWidth: "50px",
          height: "auto",
        }}
        alt="Gift"
      />
      <FlexBox
        direction="column"
        align="flex-start"
        justify="flex-start"
        wrap={false}
        gap={SPACING.space05}
        style={{ scrollbarWidth: "thin" }}
      >
        <FlexBox
          direction="row"
          align="center"
          justify="space-between"
          wrap={false}
        >
          <Typography variant="bodySmallBold" style={{ lineHeight: "1.25rem" }}>
            {shopItem.name}
          </Typography>
          <FlexBox
            direction="row"
            align="center"
            justify="flex-end"
            gap={SPACING.space1}
            wrap={false}
            style={{ width: "fit-content" }}
          >
            <img
              src="/images/icons/coin-icon.png"
              alt="coin"
              style={{ width: "16px" }}
            />
            <Typography variant="bodySmall">{shopItem.price}</Typography>
          </FlexBox>
        </FlexBox>
        <Typography variant="bodySmall" style={{ lineHeight: "1.25rem" }}>
          {shopItem.description + " "}
          {getShopItemText(shopItem)}
        </Typography>
      </FlexBox>
    </FlexBox>
  )
}

export default ShopItem
