import {
  Button,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"
import toast from "react-hot-toast"
import { create } from "zustand"

import TransitionWrapper from "../../../components/coaching/TransitionWrapper"
import SlideUpModal from "../../../components/slideUpModal/SlideUpModal"
import { useUserQuery } from "../../../networking/queries"
import { useEditParentPasscodeMutation } from "../../../networking/rewards/mutations"

enum ParentPasscodeStep {
  ENTER_PASSCODE = "ENTER_PASSCODE",
  SET_PASSCODE = "SET_PASSCODE",
  CONFIRM_SET_PASSCODE = "CONFIRM_SET_PASSCODE",
  CHANGE_PASSCODE = "CHANGE_PASSCODE",
  CONFIRM_CHANGE_PASSCODE = "CONFIRM_CHANGE_PASSCODE",
  EDIT_PASSCODE = "EDIT_PASSCODE",
  DELETE_PASSCODE = "DELETE_PASSCODE",
}

interface ParentPasscodeModalStoreProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  currentPasscode: string
  setCurrentPasscode: (currentPasscode: string) => void
  passcode: string
  setPasscode: (passcode: string) => void
  confirmPasscode: string
  setConfirmPasscode: (confirmPasscode: string) => void
  step: ParentPasscodeStep
  setStep: (step: ParentPasscodeStep) => void
  showError: boolean
  setShowError: (showError: boolean) => void
}

export const useParentPasscodeModalStore =
  create<ParentPasscodeModalStoreProps>((set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () =>
      set({
        isOpen: false,
        step: ParentPasscodeStep.SET_PASSCODE,
        passcode: "",
        confirmPasscode: "",
        currentPasscode: "",
        showError: false,
      }),
    currentPasscode: "",
    setCurrentPasscode: (currentPasscode: string) => set({ currentPasscode }),
    passcode: "",
    setPasscode: (passcode: string) => set({ passcode }),
    confirmPasscode: "",
    setConfirmPasscode: (confirmPasscode: string) => set({ confirmPasscode }),
    step: ParentPasscodeStep.SET_PASSCODE,
    setStep: (step: ParentPasscodeStep) => set({ step }),
    showError: false,
    setShowError: (showError: boolean) => set({ showError }),
  }))

const ParentPasscodeModal = () => {
  const { isOpen, onClose, passcode, step, setStep } =
    useParentPasscodeModalStore()
  const { user } = useUserQuery()

  const editParentPasscodeMutation = useEditParentPasscodeMutation()

  const onSubmit = async () => {
    if (!user?.id) return
    const isChangingPasscode = user.passcode !== null
    await editParentPasscodeMutation.mutateAsync({
      userId: user.id,
      passcode,
    })
    onClose()
    if (isChangingPasscode) toast.success("Passcode changed successfully")
    else toast.success("Passcode set successfully")
  }

  const onDelete = async () => {
    if (!user?.id) return
    await editParentPasscodeMutation.mutateAsync({
      userId: user.id,
      passcode: null,
    })
    onClose()
    toast.success("Passcode deleted successfully")
  }

  useEffect(() => {
    if (!isOpen) return
    if (user?.passcode === null) {
      setStep(ParentPasscodeStep.SET_PASSCODE)
    } else {
      setStep(ParentPasscodeStep.ENTER_PASSCODE)
    }
  }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      size="fit-content"
      backgroundType="overlay"
      zIndex={1000}
    >
      <div className="quest-modal">
        <div
          className="quest-modal-content"
          style={{ width: "min(400px, 95vw)" }}
        >
          <TransitionWrapper
            showChildren={step === ParentPasscodeStep.ENTER_PASSCODE}
          >
            <EnterPasscode />
          </TransitionWrapper>
          <TransitionWrapper
            showChildren={step === ParentPasscodeStep.SET_PASSCODE}
          >
            <SetPasscode />
          </TransitionWrapper>
          <TransitionWrapper
            showChildren={step === ParentPasscodeStep.CONFIRM_SET_PASSCODE}
          >
            <ConfirmSetPasscode
              onSubmit={onSubmit}
              isLoading={editParentPasscodeMutation.isLoading}
            />
          </TransitionWrapper>
          <TransitionWrapper
            showChildren={step === ParentPasscodeStep.CHANGE_PASSCODE}
          >
            <ChangePasscode />
          </TransitionWrapper>
          <TransitionWrapper
            showChildren={step === ParentPasscodeStep.CONFIRM_CHANGE_PASSCODE}
          >
            <ConfirmChangePasscode
              onSubmit={onSubmit}
              isLoading={editParentPasscodeMutation.isLoading}
            />
          </TransitionWrapper>
          <TransitionWrapper
            showChildren={step === ParentPasscodeStep.EDIT_PASSCODE}
          >
            <EditPasscode />
          </TransitionWrapper>
          <TransitionWrapper
            showChildren={step === ParentPasscodeStep.DELETE_PASSCODE}
          >
            <DeletePasscode
              onDelete={onDelete}
              isLoading={editParentPasscodeMutation.isLoading}
            />
          </TransitionWrapper>
        </div>
      </div>
    </SlideUpModal>
  )
}

export default ParentPasscodeModal

const EnterPasscode = () => {
  const { user } = useUserQuery()
  const {
    setStep,
    currentPasscode,
    setCurrentPasscode,
    showError,
    setShowError,
  } = useParentPasscodeModalStore()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (user?.passcode === currentPasscode) {
      setShowError(false)
      setStep(ParentPasscodeStep.EDIT_PASSCODE)
    } else {
      setShowError(true)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <FlexBox
        direction="column"
        align="center"
        wrap={false}
        gap={SPACING.space4}
      >
        <Typography variant="h2">Enter passcode</Typography>
        <Typography variant="body" color={JoonUIColor.text.secondary}>
          To edit your passcode, enter your current one!
        </Typography>

        <PasscodeInput value={currentPasscode} setValue={setCurrentPasscode} />

        <Button
          text="Done"
          type="submit"
          fullWidth
          disabled={currentPasscode.length !== 4}
        />
        {showError && (
          <Typography variant="body" color={JoonUIColor.semantic.alert}>
            Incorrect. Please try again.
          </Typography>
        )}
      </FlexBox>
    </form>
  )
}
const SetPasscode = () => {
  const { passcode, setPasscode, setStep } = useParentPasscodeModalStore()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (passcode.length === 4) {
      setStep(ParentPasscodeStep.CONFIRM_SET_PASSCODE)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <FlexBox
        direction="column"
        align="center"
        wrap={false}
        gap={SPACING.space4}
      >
        <Typography variant="h2">Set passcode</Typography>
        <Typography variant="body" color={JoonUIColor.text.secondary}>
          When opening the Joon app, you'll need to enter this passcode.
        </Typography>

        <PasscodeInput value={passcode} setValue={setPasscode} />

        <Button
          type="submit"
          text="Done"
          fullWidth
          disabled={passcode.length !== 4}
        />
      </FlexBox>
    </form>
  )
}

const ConfirmSetPasscode = ({
  onSubmit,
  isLoading,
}: {
  onSubmit: () => void
  isLoading: boolean
}) => {
  const {
    passcode,
    confirmPasscode,
    setConfirmPasscode,
    showError,
    setShowError,
  } = useParentPasscodeModalStore()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (passcode === confirmPasscode) {
      setShowError(false)
      onSubmit()
    } else setShowError(true)
  }

  return (
    <form onSubmit={handleSubmit}>
      <FlexBox
        direction="column"
        align="center"
        wrap={false}
        gap={SPACING.space4}
      >
        <Typography variant="h2">Confirm</Typography>
        <Typography variant="body" color={JoonUIColor.text.secondary}>
          Enter your passcode again to confirm.
        </Typography>

        <PasscodeInput value={confirmPasscode} setValue={setConfirmPasscode} />

        <Button
          text="Done"
          type="submit"
          fullWidth
          disabled={passcode.length !== 4}
          isLoading={isLoading}
        />
        {showError && (
          <Typography variant="body" color={JoonUIColor.semantic.alert}>
            Passwords do not match. Please try again.
          </Typography>
        )}
      </FlexBox>
    </form>
  )
}

const ChangePasscode = () => {
  const { user } = useUserQuery()
  const { passcode, setPasscode, setStep, showError, setShowError } =
    useParentPasscodeModalStore()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (user?.passcode === passcode) setShowError(true)
    else {
      setShowError(false)
      setStep(ParentPasscodeStep.CONFIRM_CHANGE_PASSCODE)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <FlexBox
        direction="column"
        align="center"
        wrap={false}
        gap={SPACING.space4}
      >
        <Typography variant="h2">Edit Passcode</Typography>
        <Typography variant="body" color={JoonUIColor.text.secondary}>
          When opening the Joon app, you'll need to enter this passcode.
        </Typography>

        <PasscodeInput value={passcode} setValue={setPasscode} />

        <Button
          text="Done"
          type="submit"
          fullWidth
          disabled={passcode.length !== 4}
        />
        {showError && (
          <Typography variant="body" color={JoonUIColor.semantic.alert}>
            Please enter a different passcode.
          </Typography>
        )}
      </FlexBox>
    </form>
  )
}

const ConfirmChangePasscode = ({
  onSubmit,
  isLoading,
}: {
  onSubmit: () => void
  isLoading: boolean
}) => {
  const {
    passcode,
    confirmPasscode,
    setConfirmPasscode,
    showError,
    setShowError,
  } = useParentPasscodeModalStore()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (passcode === confirmPasscode) {
      setShowError(false)
      onSubmit()
    } else setShowError(true)
  }

  return (
    <form onSubmit={handleSubmit}>
      <FlexBox
        direction="column"
        align="center"
        wrap={false}
        gap={SPACING.space4}
      >
        <Typography variant="h2">Confirm</Typography>
        <Typography variant="body" color={JoonUIColor.text.secondary}>
          Enter your passcode again to confirm.
        </Typography>

        <PasscodeInput value={confirmPasscode} setValue={setConfirmPasscode} />

        <Button
          text="Done"
          type="submit"
          fullWidth
          disabled={passcode.length !== 4}
          isLoading={isLoading}
        />
        {showError && (
          <Typography variant="body" color={JoonUIColor.semantic.alert}>
            Passwords do not match. Please try again.
          </Typography>
        )}
      </FlexBox>
    </form>
  )
}

const EditPasscode = () => {
  const { setStep } = useParentPasscodeModalStore()

  return (
    <FlexBox
      direction="column"
      align="center"
      wrap={false}
      gap={SPACING.space4}
    >
      <Typography variant="h2">Edit Passcode</Typography>
      <Button
        text="Change Passcode"
        onClick={() => setStep(ParentPasscodeStep.CHANGE_PASSCODE)}
        fullWidth
      />
      <TextButton onClick={() => setStep(ParentPasscodeStep.DELETE_PASSCODE)}>
        <Typography variant="bodyBold" color={JoonUIColor.semantic.alert}>
          Delete Passcode
        </Typography>
      </TextButton>
    </FlexBox>
  )
}

const DeletePasscode = ({
  onDelete,
  isLoading,
}: {
  onDelete: () => void
  isLoading: boolean
}) => {
  const { onClose } = useParentPasscodeModalStore()

  return (
    <FlexBox
      direction="column"
      align="center"
      wrap={false}
      gap={SPACING.space4}
    >
      <Typography variant="h2">Delete Passcode</Typography>
      <Typography variant="body" color={JoonUIColor.text.secondary}>
        Note that this will allow your child to access the parent panel in the
        Joon Pet Game and remove any screen time restrictions if you've
        previously set any.
      </Typography>
      <Button
        buttonType="redPrimary"
        text="Confirm"
        onClick={onDelete}
        fullWidth
        isLoading={isLoading}
      />
      <TextButton onClick={onClose}>
        <Typography variant="bodyBold" color={JoonUIColor.text.primary}>
          Cancel
        </Typography>
      </TextButton>
    </FlexBox>
  )
}

const PasscodeInput = ({
  value,
  setValue,
}: {
  value: string
  setValue: (value: string) => void
}) => {
  return (
    <TextInput
      name="passcode"
      placeholder="0000"
      value={value}
      onChange={(e) => {
        const value = e.target.value
        if (Number.isNaN(Number(value))) return
        setValue(value)
      }}
      maxLength={4}
      fullWidth
    />
  )
}
