import { Checkbox, FlexBox, SPACING, Typography } from "@joonapp/web-shared"

import { useDraftQuestDetailsModalStore } from "./DraftQuestDetailsModal"
import useAIQuestStore from "./hooks/useAIQuestStore"
import Card from "../../../components/card/Card"
import { QuestAIEditInfo } from "../../../types/quests"

const SelectedExistingQuestCard = ({
  questInfo,
  childIds,
}: {
  questInfo: QuestAIEditInfo
  childIds: number[]
}) => {
  const { onOpen } = useDraftQuestDetailsModalStore()
  const { toggleQuestUUID, selectedQuestUUIDs } = useAIQuestStore()
  const isSelected = selectedQuestUUIDs.includes(questInfo.pending_data_uuid)

  return (
    <FlexBox direction="row" wrap={false}>
      <Card
        type="secondary"
        title={
          <Typography
            variant="bodySmallBold"
            style={{
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {questInfo?.pending_data?.title}
          </Typography>
        }
        subtitle={questInfo?.pending_data?.reminder_time || ""}
        userIds={childIds}
        coinAmount={questInfo?.pending_data?.redeemable_reward}
        onClick={() => onOpen(questInfo)}
      />

      <button
        style={{ height: "100%", padding: SPACING.space2 }}
        onClick={() => toggleQuestUUID(questInfo.pending_data_uuid)}
      >
        <Checkbox
          name={questInfo.pending_data.title}
          hideBorder
          selected={isSelected}
          onChange={() => {}}
        />
      </button>
    </FlexBox>
  )
}
export default SelectedExistingQuestCard
