import {
  Checkbox,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import Card from "../card/Card"

type ImageCheckboxRowProps = {
  title: string
  description: string
  image: string
  onClick: () => void
  isChecked: boolean
}

export const ImageCheckboxRow = ({
  title,
  description,
  image,
  onClick,
  isChecked,
}: ImageCheckboxRowProps) => {
  return (
    <Card
      title={
        <FlexBox
          direction="row"
          align="center"
          justify="space-between"
          gap={SPACING.space2}
          wrap={false}
        >
          <FlexBox
            direction="row"
            align="center"
            gap={SPACING.space2}
            wrap={false}
          >
            <Checkbox
              selected={isChecked}
              inputType="radio"
              name={title}
              onChange={() => {}}
              hideBorder
            />
            <FlexBox direction="column" align="flex-start" justify="center">
              <Typography variant="bodySmallBold">{title}</Typography>
              <Typography
                variant="bodyXSmall"
                color={JoonUIColor.text.secondary}
              >
                {description}
              </Typography>
            </FlexBox>
          </FlexBox>
          <img
            src={image}
            alt="Currency exchange"
            style={{ width: SPACING.space14 }}
          />
        </FlexBox>
      }
      onClick={onClick}
      style={{
        border: "none",
        padding: SPACING.space3,
        opacity: isChecked ? 1 : 0.7,
      }}
    />
  )
}
