import { IconProps, JoonUIColor, SPACING } from "@joonapp/web-shared"
import { Typography } from "@joonapp/web-shared"
import { FlexBox } from "@joonapp/web-shared"
import { ButtonHTMLAttributes } from "react"

// NOTE: This is temporary until we add new
// variants for button in the shared library

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  text: string | React.ReactNode
  icon?: React.FC<IconProps>
  buttonType: "primary" | "secondary"
  size?: "small" | "medium"
  fullWidth?: boolean
}

const Button = ({
  text,
  icon,
  buttonType = "primary",
  size,
  fullWidth,
  disabled,
  ...props
}: Props) => {
  const Icon = icon

  const isPrimary = buttonType === "primary"

  const fontSize = size === "small" ? "bodySmallBold" : "bodyBold"
  const padding =
    size === "small"
      ? `${SPACING.space1} ${SPACING.space3}`
      : `${SPACING.space2} ${SPACING.space3}`

  return (
    <button
      {...props}
      style={{
        padding,
        borderRadius: SPACING.space2,
        border: `1px solid ${JoonUIColor.border.accent}`,
        backgroundColor: isPrimary ? JoonUIColor.background.accent : "white",
        color: isPrimary ? "white" : "black",
        width: fullWidth ? "100%" : "auto",
        ...props.style,
      }}
    >
      <FlexBox gap={SPACING.space1} align="center" wrap={false}>
        {Icon && (
          <Icon
            size={14}
            color={
              isPrimary ? JoonUIColor.icon.inverted : JoonUIColor.icon.accent
            }
          />
        )}
        <Typography
          variant={fontSize}
          color={
            isPrimary
              ? JoonUIColor.text.inverted
              : JoonUIColor.text.primaryAccent
          }
          style={{
            lineHeight: "22px",
            whiteSpace: "nowrap",
            flex: 1,
          }}
        >
          {text}
        </Typography>
      </FlexBox>
    </button>
  )
}

export default Button
