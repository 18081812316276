import {
  CoachProfile,
  LoggedInParent,
  ParentAvatar,
  UserRole,
} from "@joonapp/web-shared"

import { TherapistProfile } from "../../types"
import { AddChildRequest } from "../../types"
import { createJoonAPIClient } from "../../util/joon-api"

export async function loadUser() {
  const joonApiClient = createJoonAPIClient()
  try {
    const response = await joonApiClient.get<LoggedInParent>("api/users/me", {
      params: { update_last_login: true },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getSBCredentials = async (userId: number) => {
  const API = createJoonAPIClient()
  return API.get<{ sendbird_user_id: string; sendbird_access_token: string }>(
    `api/users/${userId}/sendbird-access-token/`,
    {
      params: { role: UserRole.CLIENT },
    }
  ).then((response) => response.data)
}

export async function loadCareTeam(userId: number) {
  const API = createJoonAPIClient()
  try {
    const response = await API.get<{
      profiles: (CoachProfile | TherapistProfile)[]
    }>(`api/users/${userId}/care-team/`)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function editChild({
  childInfo,
  childId,
}: {
  childInfo: AddChildRequest
  childId: number
}) {
  const API = createJoonAPIClient()
  return API.patch(`api/users/${childId}/`, childInfo).then((res) => res.data)
}

export async function editParent({
  parentInfo,
  userId,
}: {
  parentInfo: {
    name?: string
    nickname?: string
    avatar?: ParentAvatar | null
    last_name?: string
    phone_number?: string // (format: "+12533914941")
    birthdate?: string // (format: "YYYY-MM-DD")
  }
  userId: number
}) {
  if (!userId) throw new Error("No user id")
  const API = createJoonAPIClient()
  return API.patch(`api/users/${userId}/`, parentInfo).then((res) => res.data)
}

export async function editParentPasscode({
  userId,
  passcode,
}: {
  userId: number
  passcode: string | null // null deletes the passcode
}) {
  if (!userId) throw new Error("No user id")
  const API = createJoonAPIClient()
  return API.patch(`api/users/${userId}/passcodes`, { passcode }).then(
    (res) => res.data
  )
}

export async function addUpdateUserAddress({
  addressInfo,
  userId,
}: {
  addressInfo: {
    address_line_1?: string
    address_line_2?: string | null
    city?: string
    state?: string
    postal?: string
    country?: string
  }
  userId: number
}) {
  if (!userId) throw new Error("No user id")
  const API = createJoonAPIClient()
  return API.post(`api/users/${userId}/address/`, addressInfo).then(
    (res) => res.data
  )
}
