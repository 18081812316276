import {
  Checkbox,
  FlexBox,
  InfoSolidIcon,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { create } from "zustand"

import { CloseButton } from "../../../../components/closeButton/CloseButton"
import SlideUpModal from "../../../../components/slideUpModal/SlideUpModal"
import { useFamilyQuery, useUserQuery } from "../../../../networking/queries"
import { getProfileDisplayName } from "../../../../util/util"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

interface ReassignQuestModalStore {
  childId: number
  isOpen: boolean
  onOpen: (childId: number) => void
  onClose: () => void
}

export const useReassignQuestModalStore = create<ReassignQuestModalStore>(
  (set) => ({
    childId: 0,
    isOpen: false,
    onOpen: (childId: number) => set({ childId, isOpen: true }),
    onClose: () => set({ isOpen: false }),
  })
)

const ReassignQuest = () => {
  const { isOpen, onClose, childId } = useReassignQuestModalStore()
  const { replaceUser, assignedUsers } = useAddEditQuestStore()
  const { user } = useUserQuery()
  const { data: family } = useFamilyQuery()
  const profilesToReassign = family?.profiles
    ? family.profiles.filter((user) => !assignedUsers.includes(user.user.id))
    : []

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      size="fit-content"
      backgroundType="overlay"
      zIndex={1001}
    >
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{
          position: "relative",
          backgroundColor: JoonUIColor.background.xlightGray,
          borderRadius: SPACING.space2,
          padding: SPACING.space4,
        }}
      >
        <CloseButton onClick={onClose} />
        <Typography variant="bodyBold">Assign to</Typography>
        <FlexBox
          direction="row"
          wrap={false}
          gap={SPACING.space2}
          style={{
            border: `1px solid ${JoonUIColor.semantic.destructive}`,
            borderRadius: SPACING.space2,
            background: JoonColorExpanded.red100,
            padding: SPACING.space2,
          }}
        >
          <div style={{ minWidth: "20px", paddingTop: SPACING.space1 }}>
            <InfoSolidIcon color={JoonUIColor.semantic.destructive} size={18} />
          </div>
          <Typography
            variant="bodySmallBold"
            color={JoonUIColor.semantic.destructive}
          >
            Changing the Quest assignment will remove historical data and
            completion history for the previous owner of this task. This is
            irreversible.
          </Typography>
        </FlexBox>
        <FlexBox
          direction="column"
          wrap={false}
          fullWidth
          style={{
            background: JoonUIColor.background.primaryNeutral,
            borderRadius: SPACING.space2,
            border: `1px solid ${JoonUIColor.border.default}`,
          }}
        >
          {profilesToReassign.map((profile, index) => {
            const userName = getProfileDisplayName(user, profile)
            return (
              <button
                key={profile.user.id}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: SPACING.space2,
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: `1px solid ${JoonUIColor.border.default}`,
                  ...(index === profilesToReassign.length - 1 && {
                    borderBottom: "none",
                    borderBottomLeftRadius: SPACING.space2,
                    borderBottomRightRadius: SPACING.space2,
                  }),
                  padding: SPACING.space3,
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  replaceUser(childId, profile.user.id)
                  onClose()
                }}
              >
                <Typography variant="bodySmallBold" textAlign="left">
                  {userName}
                </Typography>

                <Checkbox
                  selected={childId === profile.user.id}
                  inputType="radio"
                  name={profile.user.name}
                  onChange={() => {}}
                  hideBorder
                />
              </button>
            )
          })}
        </FlexBox>
      </FlexBox>
    </SlideUpModal>
  )
}

export default ReassignQuest
