import {
  Button,
  Checkbox,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { create } from "zustand"

import { CloseButton } from "../../../../components/closeButton/CloseButton"
import SlideUpModal from "../../../../components/slideUpModal/SlideUpModal"
import { RecurrenceOption, REPETITIONS } from "../../../../constants"
import { getDayAbbreviationsDDD } from "../../../../util/util"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

interface RepeatWeekModalStoreProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useRepeatWeekModalStore = create<RepeatWeekModalStoreProps>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  })
)

export const RepeatWeekModal = () => {
  const { isOpen, onClose } = useRepeatWeekModalStore()
  const { repetition, setRepetition, repeatsFrequency, setRepeatsFrequency } =
    useAddEditQuestStore()

  const weekOptions = Array.from({ length: 4 }, (_, i) => {
    const label =
      i === 0
        ? "Every week"
        : i === 1
          ? "Every other week"
          : `Every ${i + 1} weeks`
    return {
      value: i + 1,
      label,
    }
  })

  const toggleDay = (index: number) => {
    const newDays = !repetition ? [...REPETITIONS.noDays] : [...repetition]
    newDays[index] = !newDays[index]
    if (newDays?.every((day) => !day)) return
    setRepetition(newDays)
  }

  const dayAbbreviationLabels = getDayAbbreviationsDDD(REPETITIONS.daily)

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      size="fit-content"
      backgroundType="overlay"
      zIndex={1000}
    >
      <FlexBox
        direction="column"
        justify="space-between"
        gap={SPACING.space4}
        style={{
          position: "relative",
          backgroundColor: JoonUIColor.background.xlightGray,
          borderRadius: SPACING.space2,
          padding: SPACING.space4,
        }}
      >
        <FlexBox direction="column" gap={SPACING.space4}>
          <CloseButton onClick={onClose} />

          <Typography variant="bodyBold">Specify repeating days</Typography>

          <FlexBox
            direction="row"
            justify="space-between"
            gap={SPACING.space1}
            align="center"
            wrap={false}
            style={{ overflowX: "auto" }}
            fullWidth
          >
            {repetition &&
              [1, 2, 3, 4, 5, 6, 0].map((dayNumber, i) => {
                const isSelected = repetition[dayNumber]
                return (
                  <button
                    key={i}
                    onClick={() => toggleDay(dayNumber)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "52px",
                      height: "36px",
                      borderRadius: SPACING.space2,
                      border: isSelected
                        ? `2px solid ${JoonUIColor.border.accent}`
                        : `2px solid ${JoonUIColor.border.default}`,
                      background: isSelected
                        ? JoonUIColor.background.accent
                        : JoonUIColor.background.primaryNeutral,
                      transition: "all 0.1s ease-in-out",
                    }}
                  >
                    <Typography
                      variant="bodyBold"
                      color={
                        isSelected
                          ? JoonUIColor.text.inverted
                          : JoonUIColor.text.secondary
                      }
                      style={{ transition: "all 0.1s ease-in-out" }}
                    >
                      {dayAbbreviationLabels[dayNumber]}
                    </Typography>
                  </button>
                )
              })}
          </FlexBox>
          <FlexBox
            direction="column"
            style={{
              borderRadius: SPACING.space2,
              background: JoonUIColor.background.primaryNeutral,
              border: `1px solid ${JoonUIColor.border.default}`,
            }}
          >
            {weekOptions.map((option, index) => (
              <button
                key={index}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: SPACING.space2,
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom:
                    option.value === RecurrenceOption.CUSTOM
                      ? "none"
                      : `1px solid ${JoonUIColor.border.default}`,
                  padding: SPACING.space3,
                }}
                onClick={() => setRepeatsFrequency(option.value)}
              >
                <Typography variant="bodySmallBold" textAlign="left">
                  {option.label}
                </Typography>

                <Checkbox
                  selected={option.value === repeatsFrequency}
                  inputType="radio"
                  name={option.value?.toString() || "none"}
                  onChange={() => {}}
                  hideBorder
                />
              </button>
            ))}
          </FlexBox>
        </FlexBox>
        <Button
          buttonType="primary"
          onClick={onClose}
          text="Done"
          fullWidth
          style={{ borderRadius: SPACING.space2 }}
        />
      </FlexBox>
    </SlideUpModal>
  )
}
