import {
  FlexBox,
  SPACING,
  ParentAvatar,
  Typography,
  JoonUIColor,
} from "@joonapp/web-shared"
import { useState } from "react"

import OnboardingPageContentWrapper from "./onboardingPageContentWrapper/OnboardingPageContentWrapper"
import { BACKGROUND_COLORS } from "../../constants"
import useOnboardContext from "../../hooks/useOnboardContext"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { getParentAvatarImage } from "../../util/util"
import ChevronBackButton from "../buttons/ChevronBackButton"
import Primary3DButton from "../buttons/Primary3DButton"
import TextInputRect from "../textInputRect/TextInputRect"

function ParentAvatarSection({ avatar }: { avatar: ParentAvatar }) {
  const { parentData, setParentData } = useOnboardContext()
  const isSelected = parentData.avatar === avatar
  const setSelectedParentAvatar = () => {
    setParentData("avatar", avatar)
  }

  return (
    <button
      className={`parent-avatar-section ${isSelected ? "selected" : ""}`}
      onClick={setSelectedParentAvatar}
    >
      <img src={getParentAvatarImage(avatar) || undefined} alt={avatar} />
    </button>
  )
}

function OnboardParentAvatarSection() {
  const [warningText, setWarningText] = useState("")
  const { parentData, nextStep, previousStep, setParentData } =
    useOnboardContext()

  return (
    <OnboardingPageContentWrapper
      paddingTop="5dvh"
      style={{ position: "relative" }}
      backgroundOnDesktop={BACKGROUND_COLORS.lightBlueBg}
    >
      <ChevronBackButton onClick={previousStep} />
      <FlexBox direction="column" align="center" gap={SPACING.space6}>
        <img
          src={
            getParentAvatarImage(parentData.avatar as ParentAvatar) ||
            "/images/avatars/frumpulous-placeholder.png"
          }
          alt="Avatar"
          style={{
            width: "min(40%, 100px)",
            borderRadius: "50%",
          }}
        />
        <Typography variant="h2Serif" textAlign="center">
          How would you like to show up in your child's Joon app?
        </Typography>
        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="body">Parent nickname</Typography>
          <Typography variant="bodyXSmall">
            This is what your children will see your name as in Joon
          </Typography>
          <TextInputRect
            fullWidth
            name="nickName"
            placeholder="Mom/Dad"
            onChange={(e: any) => setParentData("nickname", e.target.value)}
            value={parentData.nickname}
          />
        </FlexBox>

        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="body">Parent avatar</Typography>
          <Typography variant="bodyXSmall">
            This is what your children will see you as in Joon!
          </Typography>

          <FlexBox
            direction="row"
            gap={SPACING.space0}
            wrap={false}
            justify="space-between"
          >
            <ParentAvatarSection avatar={ParentAvatar.FRUMPULOUS} />
            <ParentAvatarSection avatar={ParentAvatar.JUNK_MUTT} />
            <ParentAvatarSection avatar={ParentAvatar.SCRUFFIT} />
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <FlexBox
        direction="column"
        gap={SPACING.space2}
        align="center"
        style={{ marginTop: SPACING.space4 }}
      >
        {warningText.length > 0 && (
          <Typography
            variant="bodySmallBold"
            color={JoonUIColor.semantic.alert}
          >
            {warningText}
          </Typography>
        )}
        <Primary3DButton
          onClick={() => {
            if (parentData.nickname.length === 0) {
              setWarningText("Please enter a nickname")
            } else if (parentData.avatar == null) {
              setWarningText("Please select an avatar")
            } else {
              trackAnalyticEvent(ANALYTIC_EVENTS.ADD_PARENT_NICKNAME)
              nextStep()
            }
          }}
          text="Continue"
          fullWidth
        />
      </FlexBox>
    </OnboardingPageContentWrapper>
  )
}

export default OnboardParentAvatarSection
