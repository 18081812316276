import {
  FlexBox,
  JoonUIColor,
  SPACING,
  Slider,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useShallow } from "zustand/react/shallow"

import CatchUpCardEmptyState from "./CatchUpCardEmptyState"
import CatchUpCardStatusOverlay from "./CatchUpCardStatusOverlay"
import CatchUpTabBar from "./CatchUpTabBar"
import CatchUpTabBarReviewButtons from "./CatchUpTabBarReviewButtons"
import TimeOfDayCard from "./TimeOfDayCard"
import { useCatchUpStore } from "./useCatchUpStore"
import Counter from "../../components/counter/Counter"
import { useImageModalStore } from "../../components/modals/ImageModal"

const CatchUpCardContent = () => {
  const {
    selectedQuest,
    questReviewDifficulty,
    setQuestReviewDifficulty,
    questReviewReward,
    setQuestReviewReward,
    questReviewNotes,
    setQuestReviewNotes,
    selectedChild,
  } = useCatchUpStore(
    useShallow((state) => ({
      selectedQuest: state.selectedQuest,
      questReviewDifficulty: state.questReviewDifficulty,
      setQuestReviewDifficulty: state.setQuestReviewDifficulty,
      questReviewReward: state.questReviewReward,
      setQuestReviewReward: state.setQuestReviewReward,
      questReviewNotes: state.questReviewNotes,
      setQuestReviewNotes: state.setQuestReviewNotes,
      selectedChild: state.selectedChild,
    }))
  )

  if (!selectedQuest) return <CatchUpCardEmptyState />

  const completionDate = dayjs.unix(Number(selectedQuest.completion_date))
  const completionText = `Completed ${completionDate?.format("MMMM D, YYYY")} at ${completionDate?.format("h:mm A")}`

  return (
    <>
      <div>
        <div className="py-2 px-3 border-b border-borderPrimary">
          <TimeOfDayCard
            date={selectedQuest.date}
            time={selectedQuest.series.reminder_time}
            title={selectedQuest.series.title}
            routine={selectedQuest.series.routine}
            isSelfInitiated={selectedQuest.series.assigner_profile === null}
            completionText={completionText}
          />
        </div>
        <CompletionNotes />
        <div
          style={{
            zIndex: 1001,
            borderTopLeftRadius: SPACING.space4,
            borderTopRightRadius: SPACING.space4,
            background: "white",
            padding: `${SPACING.space4} ${SPACING.space6}`,
          }}
        >
          <Typography
            variant="bodySmallBold"
            textAlign="center"
            style={{ marginBottom: SPACING.space1 }}
          >
            How difficult was it to motivate {selectedChild?.user.name}?
          </Typography>
          <Slider
            value={questReviewDifficulty}
            options={[0, 1, 2, 3, 4, 5, 6]}
            setValue={setQuestReviewDifficulty}
            minLabel="Very easy"
            maxLabel="Very difficult"
          />
        </div>
        <div style={{ padding: `${SPACING.space4} ${SPACING.space6}` }}>
          <Typography variant="bodySmallBold" textAlign="center">
            Reward Adjustment
          </Typography>
          <Typography
            variant="bodySmall"
            textAlign="center"
            color={JoonUIColor.text.secondary}
            style={{ marginBottom: SPACING.space1 }}
          >
            Modify rewards based on completion quality
          </Typography>
          <Counter value={questReviewReward} setValue={setQuestReviewReward} />
        </div>
        <FlexBox
          direction="column"
          gap={SPACING.space1}
          style={{ padding: `${SPACING.space4} ${SPACING.space6}` }}
        >
          <Typography variant="bodySmallBold" textAlign="center">
            Add a note for {selectedChild?.user.name}
          </Typography>
          <TextInput
            name="Note"
            placeholder="Type a message..."
            value={questReviewNotes}
            onChange={(e) => setQuestReviewNotes(e.target.value)}
            fullWidth
          />
        </FlexBox>
      </div>
      <CatchUpCardStatusOverlay />
      <CatchUpTabBar>
        <CatchUpTabBarReviewButtons />
      </CatchUpTabBar>
    </>
  )
}

const CompletionNotes = () => {
  const { onOpen } = useImageModalStore()
  const { selectedQuest } = useCatchUpStore(
    useShallow((state) => ({
      selectedQuest: state.selectedQuest,
      selectedChild: state.selectedChild,
    }))
  )
  if (!selectedQuest) return null

  const hasDataToShow =
    selectedQuest.completion_photo_url ||
    selectedQuest.completion_notes ||
    selectedQuest.series.description

  if (!hasDataToShow) return null

  return (
    <div className="bg-bgXLightGray">
      {selectedQuest.completion_photo_url && (
        <button
          onClick={() =>
            selectedQuest.completion_photo_url &&
            onOpen(selectedQuest.completion_photo_url)
          }
        >
          <img
            src={selectedQuest.completion_photo_url || ""}
            alt="Quest completion verification"
            className="w-full h-full object-cover"
          />
        </button>
      )}
      {selectedQuest.completion_notes && (
        <div className="flex flex-col px-4 py-3">
          <Typography variant="bodySmallBold" textAlign="left">
            Completion note
          </Typography>
          <Typography variant="bodySmall" textAlign="left">
            "{selectedQuest.completion_notes}"
          </Typography>
        </div>
      )}
      {selectedQuest.series.description && (
        <div className="flex flex-col px-4 py-3">
          <Typography variant="bodySmallBold" textAlign="left">
            Quest description
          </Typography>
          <Typography variant="bodySmall" textAlign="left">
            {selectedQuest.series.description}
          </Typography>
        </div>
      )}
    </div>
  )
}

export default CatchUpCardContent
