import {
  CustomRewardCurrency,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import UserBadge from "../../../components/badges/userBadge/UserBadge"
import { OnboardingCustomRewardTemplateWithUserIds } from "../../../types/rewards"

export const JooniperSuccessRewardCard = ({
  reward,
}: {
  reward: OnboardingCustomRewardTemplateWithUserIds
}) => {
  if (!reward.user_ids?.length) return null

  return (
    <FlexBox direction="row" wrap={false}>
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        wrap={false}
        style={{
          padding: SPACING.space2,
          border: `1px solid ${JoonUIColor.border.default}`,
          borderRadius: SPACING.space2,
          background: "rgba(249, 250, 252, 0.40)",
        }}
      >
        <FlexBox
          direction="row"
          justify="space-between"
          align="center"
          wrap={false}
        >
          <Typography variant="bodyBold" textAlign="left">
            {reward.emoji} {reward.title}
          </Typography>
          <FlexBox
            direction="row"
            align="center"
            justify="flex-end"
            gap={SPACING.space1}
            wrap={false}
            style={{ width: "fit-content" }}
          >
            <FlexBox
              direction="row"
              align="center"
              justify="flex-end"
              gap={SPACING.space05}
              wrap={false}
            >
              <Typography variant="bodySmall">{reward.cost}</Typography>
              <img
                style={{ height: SPACING.space3 }}
                src="/images/icons/coin-icon.png"
                alt="coin"
              />
            </FlexBox>
            {reward.currency !== CustomRewardCurrency.ITEM && (
              <>
                <Typography
                  variant="bodySmall"
                  style={{ whiteSpace: "nowrap" }}
                >
                  →
                </Typography>
                <Typography
                  variant="bodySmall"
                  style={{ whiteSpace: "nowrap" }}
                >
                  1 {reward.currency}
                </Typography>
              </>
            )}
          </FlexBox>
        </FlexBox>
        <FlexBox direction="row" gap={SPACING.space1}>
          {reward.user_ids.map((userId) => (
            <UserBadge key={userId} userId={userId} />
          ))}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}
