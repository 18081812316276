import { Typography } from "@joonapp/web-shared"

export default (
  <div>
    <Typography variant="bodySmall">
      To ensure that your therapist will be able to perform their function, the
      following data will be shared with them:
    </Typography>
    <Typography variant="bodySmall">
      <ul>
        <li>Child data (names & birthdates)</li>
        <li>Quest & questionnaire response data</li>
        <li>Child progress data</li>
      </ul>
    </Typography>
  </div>
)
