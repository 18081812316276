import { FlexBox, SPACING } from "@joonapp/web-shared"
import "./animatedStars.scss"

const AnimatedStars = ({
  animate,
  scale = 1,
  color,
}: {
  animate: boolean
  scale?: number
  color?: string
}) => {
  return (
    <FlexBox
      direction="row"
      gap={SPACING.space2}
      style={{
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        width: "16px",
        height: "16px",
        transform: `scale(${scale}) translateY(-2px)`,
        transition: "transform 0.4s ease-in-out",
      }}
    >
      <StarIcon position="top" animate={animate} color={color} />
      <StarIcon position="bottom" animate={animate} color={color} />
    </FlexBox>
  )
}

export default AnimatedStars

const StarIcon = ({
  position,
  animate,
  color,
}: {
  position: string
  animate: boolean
  color: string | undefined
}) => {
  const animateClass = animate ? (position === "top" ? "grow" : "shrink") : ""

  return (
    <div className={`star-icon ${position} ${animateClass}`}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="starGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop
              offset="0%"
              style={{ stopColor: "#F47474", stopOpacity: 1 }}
            />
            <stop
              offset="50%"
              style={{ stopColor: "#CB6ED9", stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: "#6425ED", stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
        <path
          d="M7.59331 1.573C8.07655 0.267071 9.92363 0.267071 10.4069 1.573L11.7935 5.32042C11.9455 5.731 12.2692 6.05472 12.6798 6.20664L16.4272 7.59331C17.7331 8.07655 17.7331 9.92363 16.4272 10.4069L12.6798 11.7935C12.2692 11.9455 11.9455 12.2692 11.7935 12.6798L10.4069 16.4272C9.92363 17.7331 8.07655 17.7331 7.59331 16.4272L6.20664 12.6798C6.05471 12.2692 5.731 11.9455 5.32042 11.7935L1.573 10.4069C0.267071 9.92363 0.267071 8.07655 1.573 7.59331L5.32042 6.20664C5.731 6.05471 6.05472 5.731 6.20664 5.32042L7.59331 1.573Z"
          fill={color || "url(#starGradient)"}
        />
      </svg>
    </div>
  )
}
