import { useMemo } from "react"

import {
  useFamilyQuery,
  useReorderQuestsQuery,
} from "../../../networking/queries"
import { FamilyQuerySelect } from "../../../types"
import { QuestBoardSelectOption } from "../../../types/quests"
import useQuestBoardStore from "../useQuestBoardStore"

const useQuestBoardDndFilters = () => {
  const { data, isLoading } = useReorderQuestsQuery()
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const childIds = children?.map((child) => child.user.id)
  const { selectOption, selectedUserId } = useQuestBoardStore()

  const filteredQuests = useMemo(() => {
    return (
      data?.filter((quest) => {
        // Remove self-initiated quests
        if (quest.assigner_profile === null) {
          return false
        }
        // Filter quests by select option
        if (selectOption === QuestBoardSelectOption.ALL_USERS) {
          return true
        }
        if (selectOption === QuestBoardSelectOption.ALL_KIDS) {
          return childIds?.includes(quest.user_id)
        }
        // Filter quests by user
        if (selectedUserId && quest.user_id !== selectedUserId) {
          return false
        }
        return true
      }) || []
    )
  }, [data, selectOption, selectedUserId, childIds])

  return { filteredQuests, isLoading }
}

export default useQuestBoardDndFilters
