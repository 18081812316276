import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import dayjs from "dayjs"

export const PaywallBundlingFreeTrial = () => {
  const dateIn7days = dayjs().add(7, "day").format("MMMM D")

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space2}
      style={{
        borderTop: `1px solid ${JoonUIColor.border.default}`,
        paddingTop: SPACING.space3,
      }}
    >
      <Typography variant="bodyBold" textAlign="left">
        How your free trial works:
      </Typography>
      <FlexBox
        direction="row"
        justify="center"
        gap={SPACING.space4}
        wrap={false}
      >
        <div style={{ position: "relative" }}>
          <FlexBox
            direction="column"
            align="center"
            wrap={false}
            gap={SPACING.space6}
            style={{
              width: "30px",
              borderRadius: "20px",
              background: JoonUIColor.background.accent,
              position: "relative",
              color: "white",
              fontSize: "24px",
              zIndex: 5,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px 0px",
                height: "40px",
              }}
            >
              <img
                src="/images/icons/unlock.svg"
                className="paywall-icon"
                alt="bell"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px 0px",
                height: "40px",
              }}
            >
              <img
                src="/images/icons/bell.svg"
                className="paywall-icon"
                alt="bell"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px 0px",
                height: "40px",
              }}
            >
              <img
                src="/images/icons/star.svg"
                className="paywall-icon"
                alt="bell"
              />
            </div>
          </FlexBox>
          <div
            style={{
              width: "30px",
              height: "120px",
              zIndex: 2,
              position: "relative",
              borderRadius: "20px",
              marginTop: "-30px",
              background:
                "linear-gradient(180deg, rgba(103, 113, 247, 0.7) 20%, rgba(103, 94, 239, 0.19) 95%, rgba(86, 97, 225, 0) 100%)",
            }}
          />
        </div>
        <FlexBox
          direction="column"
          align="center"
          wrap={false}
          gap={SPACING.space6}
          style={{ paddingTop: SPACING.space2 }}
        >
          <FlexBox direction="column" style={{ height: "40px" }} wrap={false}>
            <Typography
              variant="bodySmallBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Today
            </Typography>
            <Typography variant="bodyXSmallBold">
              Start your full access to your tailored program for your family.
            </Typography>
          </FlexBox>
          <FlexBox direction="column" style={{ height: "40px" }} wrap={false}>
            <Typography
              variant="bodySmallBold"
              color={JoonUIColor.text.primaryAccent}
            >
              In 5 days
            </Typography>
            <Typography variant="bodyXSmallBold">
              Get a reminder about when your trial will end.
            </Typography>
          </FlexBox>
          <FlexBox direction="column" style={{ height: "40px" }} wrap={false}>
            <Typography
              variant="bodySmallBold"
              color={JoonUIColor.text.primaryAccent}
            >
              In 7 days
            </Typography>
            <Typography variant="bodyXSmallBold">
              You'll be charged on {dateIn7days}, cancel anytime before.
            </Typography>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}
