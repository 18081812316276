import {
  Checkbox,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import { useJooniperPrepaywallModalStore } from "./useJooniperPrepaywallModalStore"
import { useFamilyQuery } from "../../../networking/queries"
import { FamilyQuerySelect } from "../../../types"
import { QuestAICreateInfo } from "../../../types/quests"
import { getDayRepetitionString } from "../../../util/util"

const JooniperDraftQuestCard = ({ quest }: { quest: QuestAICreateInfo }) => {
  const { selectedQuestUUIDs, toggleQuestUUID } =
    useJooniperPrepaywallModalStore()

  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const assignedUsers = children
    ?.filter((child) => quest.pending_data.user_ids.includes(child.user.id))
    .map((user) => user.user.name)

  const isSelected = selectedQuestUUIDs.includes(quest.pending_data_uuid)

  return (
    <button
      style={{ width: "100%" }}
      onClick={() => toggleQuestUUID(quest.pending_data_uuid)}
    >
      <FlexBox direction="row" align="center" gap={SPACING.space2} wrap={false}>
        <FlexBox
          direction="column"
          style={{
            padding: SPACING.space2,
            border: `1px dashed ${isSelected ? JoonUIColor.border.accent : JoonUIColor.border.default}`,
            borderRadius: SPACING.space2,
            background: "rgba(249, 250, 252, 0.40)",
          }}
        >
          <Typography
            variant="body"
            textAlign="left"
            style={{ fontWeight: 600 }}
          >
            {quest.pending_data.title}
          </Typography>
          <Typography variant="bodySmall" color={JoonUIColor.text.secondary}>
            {assignedUsers && assignedUsers.length === 2
              ? assignedUsers.join(" and ")
              : assignedUsers?.join(", ")}
          </Typography>
          <Typography variant="bodySmall" color={JoonUIColor.text.secondary}>
            {getDayRepetitionString(
              quest.pending_data.recurrence_byday,
              quest.pending_data.reminder_time
            )}
          </Typography>
        </FlexBox>
        <Checkbox
          name={quest.pending_data.title}
          hideBorder
          selected={isSelected}
          onChange={() => {}}
        />
      </FlexBox>
    </button>
  )
}

export default JooniperDraftQuestCard
