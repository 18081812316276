import {
  FlexBox,
  JoonUIColor,
  QuestSeries,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useMemo } from "react"

import DraftQuestCard from "./DraftQuestCard"
import DraftQuestDetailsModal from "./DraftQuestDetailsModal"
import useAIQuestStore from "./hooks/useAIQuestStore"
import ModificationBar from "./ModificationBar"
import PromptTextArea from "./PromptTextArea"
import QuestAIError from "./QuestAIError"
import SelectedExistingQuestCard from "./SelectedExistingQuestCard"
import AnimatedStars from "../../../components/animatedStars/AnimatedStars"
import Card from "../../../components/card/Card"
import Dotdotdot from "../../../components/dotdotdot/Dotdotdot"
import { useReorderQuestsQuery } from "../../../networking/queries"
import { useAddEditQuestStore } from "../addEditQuestModal/useAddEditQuestStore"

const QuestAIResponse = () => {
  const {
    response,
    isLoading,
    isAssigning,
    assignedQuests,
    didStartOver,
    isRemoving,
    error,
  } = useAIQuestStore()

  const hasQuestsToCreate =
    response && response.pending_create_quest_data.length > 0
  const hasExistingQuestsNoEdits =
    response && response.selected_existing_quests_no_edits.length > 0
  const hasExistingQuestsWithEdits =
    response && response.pending_edit_existing_quest_data.length > 0

  const { data: allQuestSeries } = useReorderQuestsQuery()

  // Get quests from cache rather than from AI response, then group them by quest_group_id
  const groupedAssignedQuests = useMemo(() => {
    const filteredQuests =
      allQuestSeries?.filter((quest) =>
        assignedQuests.some((assignedQuest) => assignedQuest.id === quest.id)
      ) || []

    const groups = {} as {
      [key: string]: { quest: QuestSeries; childIds: number[] }
    }

    filteredQuests.forEach((quest) => {
      const groupId = quest.quest_group_id ?? quest.id
      if (!groups[groupId])
        groups[groupId] = { quest, childIds: [quest.user_id] }
      else groups[groupId].childIds.push(quest.user_id)
    })

    return groups
  }, [allQuestSeries, assignedQuests])

  return (
    <>
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        wrap={false}
        style={{
          padding: SPACING.space4,
          flex: `1 1 auto`,
          height: "50vh", // the height really doesn't matter here, just has to be set
          overflowY: "auto",
        }}
      >
        <AnimatedStars
          animate={isLoading || isAssigning || isRemoving}
          scale={1}
        />

        {isAssigning && (
          <AIText>
            Assigning Quests
            <Dotdotdot />
          </AIText>
        )}

        {isRemoving && (
          <AIText>
            Removing Quests
            <Dotdotdot />
          </AIText>
        )}

        {isLoading && (
          <AIText>
            Thinking
            <Dotdotdot />
          </AIText>
        )}

        <QuestAIError />

        {didStartOver && (
          <div>
            <AIText>Alright, wiping the slate clean!</AIText>
            <AIText>
              To start, tell me what you hope to accomplish with Joon.
            </AIText>
          </div>
        )}
        {assignedQuests.length > 0 && (
          <FlexBox direction="column" gap={SPACING.space2}>
            <AIText>
              All quests have been assigned! You can tap below to make any
              additional changes. Is there anything else you'd like to do?
            </AIText>
            {Object.values(groupedAssignedQuests).map(({ quest, childIds }) => (
              <AssignedQuestCard
                key={quest.id}
                questSeries={quest}
                childIds={childIds}
              />
            ))}
          </FlexBox>
        )}

        {!didStartOver && assignedQuests.length === 0 && !error && (
          <FlexBox direction="column" gap={SPACING.space2}>
            {!isAssigning && !isRemoving && !isLoading && (
              <AIText>{response?.response}</AIText>
            )}

            {hasQuestsToCreate && (
              <Typography
                variant="bodySmallBold"
                color={JoonUIColor.text.primaryAccent}
              >
                {response?.pending_create_quest_data.length} New Quests
              </Typography>
            )}
            {response?.pending_create_quest_data?.map((quest) => (
              <DraftQuestCard key={quest.pending_data_uuid} quest={quest} />
            ))}

            {hasExistingQuestsNoEdits && (
              <Typography
                variant="bodySmallBold"
                color={JoonUIColor.text.primaryAccent}
              >
                {response?.selected_existing_quests_no_edits.length} existing
                Quests
              </Typography>
            )}
            {response?.selected_existing_quests_no_edits?.map((quest) => (
              <SelectedExistingQuestCard
                key={quest.pending_data_uuid}
                questInfo={quest}
                childIds={[quest.pending_data.user_id]}
              />
            ))}

            {hasExistingQuestsWithEdits && (
              <Typography
                variant="bodySmallBold"
                color={JoonUIColor.text.primaryAccent}
              >
                {response?.pending_edit_existing_quest_data.length} existing
                Quests with modifications
              </Typography>
            )}
            {response?.pending_edit_existing_quest_data?.map((quest) => (
              <SelectedExistingQuestCard
                key={quest.pending_data_uuid}
                questInfo={quest}
                childIds={[quest.pending_data.user_id]}
              />
            ))}
          </FlexBox>
        )}
      </FlexBox>
      {assignedQuests.length > 0 || didStartOver || isRemoving || error ? (
        <div style={{ padding: SPACING.space4, width: "100%" }}>
          <PromptTextArea />
        </div>
      ) : (
        <ModificationBar />
      )}
      <DraftQuestDetailsModal />
    </>
  )
}

export default QuestAIResponse

const AIText = ({ children }: { children: string | React.ReactNode }) => {
  return (
    <Typography variant="aiBody" textAlign="left">
      {children}
    </Typography>
  )
}

const AssignedQuestCard = ({
  questSeries,
  childIds,
}: {
  questSeries: QuestSeries
  childIds: number[]
}) => {
  const { onOpen: openAddEditQuestModal, setAssignedUsers } =
    useAddEditQuestStore()

  const onClickCard = () => {
    openAddEditQuestModal(questSeries)
    setAssignedUsers([questSeries.user_id])
  }

  return (
    <Card
      type="secondary"
      title={
        <Typography
          variant="bodySmallBold"
          style={{
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {questSeries?.title}
        </Typography>
      }
      subtitle={questSeries?.reminder_time || ""}
      userIds={childIds}
      coinAmount={questSeries?.redeemable_reward}
      onClick={onClickCard}
    />
  )
}
