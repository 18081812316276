import {
  ChevronRightIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"
import { localStorageItems } from "../../util/storage"
import { useAddQuestTemplatesModalStore } from "../questBoard/addQuestTemplates/useAddQuestTemplatesModalStore"

const CoachUpsellBanner = () => {
  const { setShowCoachUpsellBanner, onClose: onCloseAddTemplateModal } =
    useAddQuestTemplatesModalStore()
  const router = useRouter()

  const onLearnMoreClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.COACH_UPSELL_BANNER_LEARN_MORE)
    onCloseAddTemplateModal()
    router.push("/coaching?from=preview&popup=true")
  }

  const onDismissClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    trackAnalyticEvent(ANALYTIC_EVENTS.COACH_UPSELL_BANNER_DISMISS)
    localStorage.setItem(localStorageItems.coachUpsellBannerSeen, "true")
    setShowCoachUpsellBanner(false)
  }

  useEffect(() => {
    trackAnalyticEvent(ANALYTIC_EVENTS.COACH_UPSELL_BANNER_VIEWED)
  }, [])

  return (
    <button
      onClick={onLearnMoreClick}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: SPACING.space2,
        background: JoonUIColor.background.primaryNeutral,
        position: "sticky",
        padding: SPACING.space4,
        bottom: SPACING.space2,
        boxShadow: `${SPACING.space1} ${SPACING.space2} ${SPACING.space2} rgba(0, 0, 0, 0.08)`,
        overflow: "hidden",
        border: `1px #E3E8EF solid`,
        borderRadius: SPACING.space2,
      }}
    >
      <FlexBox justify="space-between" align="center" fullWidth>
        <Typography variant="bodyXSmallBold" color={JoonUIColor.text.primary}>
          Need help getting started?
        </Typography>
        <button onClick={onDismissClick}>
          <Typography variant="bodyXSmall" color={JoonUIColor.text.disabled}>
            Dismiss
          </Typography>
        </button>
      </FlexBox>
      <Typography
        variant="bodyXSmall"
        color={JoonUIColor.text.primary}
        textAlign="left"
      >
        Get personalized advice and strategies on how to best improve your
        child's progress with a Joon Coach.
      </Typography>
      <FlexBox
        direction="row"
        align="center"
        style={{ borderRadius: SPACING.space2 }}
      >
        <Typography
          variant="bodyXSmall"
          color={JoonUIColor.text.primaryAccent}
          textAlign="left"
          style={{ width: "fit-content", whiteSpace: "nowrap" }}
        >
          Learn more about Joon coaching
        </Typography>

        <ChevronRightIcon color={JoonUIColor.text.primaryAccent} size={16} />
      </FlexBox>
    </button>
  )
}

export default CoachUpsellBanner
