import {
  SPACING,
  FlexBox,
  Typography,
  JoonUIColor,
  Loader,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useEffect, useMemo } from "react"

import { useSessionBookingModalStore } from "./hooks/useSessionBookingModalStore"
import SessionBillingPeriodGroup from "./SessionBillingPeriodGroup"
import SessionSlot from "./SessionSlot"
import PageLoader from "../../../components/loading/PageLoader"
import { useCoachSubscriptionPackagesQuery } from "../../../networking/coaching/queries"
import {
  useSessionBillingPeriodsQuery,
  useSessionBookingsQuery,
} from "../../../networking/sessions/queries"
import { localStorageItems } from "../../../util/storage"
import NoSessionsInPlanScreen from "../NoSessionsInPlanScreen"
import PlanUpdateModal from "../planUpdate/PlanUpdateModal"
import usePlanUpdateModalStore from "../planUpdate/usePlanUpdateModalStore"

const SessionsScreen = () => {
  const {
    data: billingPeriods,
    isLoading: isLoadingBillingPeriods,
    isFetching: isFetchingBillingPeriods,
    refetch: refetchBillingPeriods,
  } = useSessionBillingPeriodsQuery()
  const {
    data: bookings,
    isLoading: isLoadingBookings,
    refetch: refetchBookings,
  } = useSessionBookingsQuery()
  const { isOpen } = useSessionBookingModalStore()
  const { onOpen: onOpenPlanUpdateModal } = usePlanUpdateModalStore()
  useCoachSubscriptionPackagesQuery()

  // When the user comes back to the app, check if they need to refresh the bookings
  useEffect(() => {
    if (isOpen) return
    if (!!localStorage.getItem(localStorageItems.needsToRefreshBookings)) {
      localStorage.removeItem(localStorageItems.needsToRefreshBookings)
      refetchBillingPeriods()
      refetchBookings()
    }
  }, [isOpen, refetchBillingPeriods, refetchBookings])

  const { pastSessions, makeUpSessions } = useMemo(() => {
    const currentPeriodsSessionIds = billingPeriods?.flatMap((period) =>
      period.bookings?.map((booking) => booking.id)
    )

    const isSessionOutsidePeriods = (booking: any) =>
      !currentPeriodsSessionIds?.includes(booking.id)

    const pastSessions = bookings?.filter(
      (booking) =>
        (isSessionOutsidePeriods(booking) || !!booking.missed_session_id) &&
        dayjs(booking.start_datetime).isBefore(dayjs())
    )
    const makeUpSessions = bookings?.filter(
      (booking) =>
        !!booking.missed_session_id &&
        dayjs(booking.start_datetime).isAfter(dayjs())
    )

    return { pastSessions, makeUpSessions }
  }, [billingPeriods, bookings])

  if (isLoadingBillingPeriods || isLoadingBookings) return <PageLoader />
  if (billingPeriods?.length === 0) return <NoSessionsInPlanScreen />

  return (
    <FlexBox direction="column" gap={SPACING.space4}>
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{ padding: SPACING.space6 }}
      >
        {isFetchingBillingPeriods && (
          <FlexBox justify="center" gap={SPACING.space2}>
            <Loader size={24} color={JoonUIColor.icon.neutral} />
          </FlexBox>
        )}
        {makeUpSessions && makeUpSessions?.length > 0 && (
          <FlexBox direction="column" gap={SPACING.space2}>
            <Typography
              variant="bodySmallBold"
              color={JoonUIColor.text.secondary}
            >
              MAKE-UP SESSION
            </Typography>
            {makeUpSessions?.map((session) => (
              <SessionSlot session={session} key={session.id} />
            ))}
          </FlexBox>
        )}
        {billingPeriods?.[0] && (
          <SessionBillingPeriodGroup
            period={billingPeriods?.[0]}
            isCurrentCycle={true}
          />
        )}

        <div style={{ margin: `${SPACING.space4} 0` }}>
          {billingPeriods?.[0]?.max_bookings !== 4 && (
            <Typography
              variant="bodySmallBold"
              textAlign="left"
              color={JoonUIColor.text.secondary}
            >
              Looking for more sessions per cycle?
            </Typography>
          )}
          <button onClick={onOpenPlanUpdateModal}>
            <Typography
              variant="bodySmallBold"
              color={JoonUIColor.text.primaryAccent}
            >
              {billingPeriods?.[0]?.max_bookings === 4
                ? "Update coaching plan"
                : "Upgrade coaching plan"}
            </Typography>
          </button>
        </div>
        {billingPeriods?.[1] && (
          <SessionBillingPeriodGroup
            period={billingPeriods?.[1]}
            isCurrentCycle={false}
          />
        )}

        {pastSessions?.length && pastSessions?.length > 0 ? (
          <FlexBox direction="column" gap={SPACING.space2}>
            <Typography
              variant="bodySmallBold"
              color={JoonUIColor.text.secondary}
            >
              PAST SESSIONS
            </Typography>
            {pastSessions?.map((session) => (
              <SessionSlot session={session} key={session.id} />
            ))}
          </FlexBox>
        ) : null}
      </FlexBox>
      <PlanUpdateModal />
    </FlexBox>
  )
}

export default SessionsScreen
