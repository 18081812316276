import { ClientProfile } from "@joonapp/web-shared/dist/types"
import { CoachProfile } from "@joonapp/web-shared/dist/types/groups"

export interface CoachingGroup {
  profiles: (CoachProfile | ClientProfile)[]
  date_created: string
  id: number
  type: "coaching" // TODO: Update this in types - GroupType.COACHING
}

export enum CoachingLookupKey {
  TEXT_ONLY = "coaching_text_only_monthly",
  ONE_CALL = "coaching_one_call_monthly",
  STANDARD = "coaching_standard_monthly",
  FOUR_CALLS = "coaching_four_call_monthly",
}

export enum CoachingStep {
  POST_ANSWER_SCREEN = -1,
  LANDING,
  INTAKE_FORM,
  MATCHING,
  COACH_SUMMARY,
  RECOMMENDED_COACHES,
  POST_SELECTION,
  PLAN_SELECT,
  SCHEDULED,
}
