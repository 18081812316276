import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { useState } from "react"

import OnboardingPageContentWrapper from "./onboardingPageContentWrapper/OnboardingPageContentWrapper"
import { BACKGROUND_COLORS } from "../../constants"
import useOnboardContext, {
  OnboardingStep,
} from "../../hooks/useOnboardContext"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { localStorageItems } from "../../util/storage"
import ChevronBackButton from "../buttons/ChevronBackButton"
import Primary3DButton from "../buttons/Primary3DButton"
import TextInputRect from "../textInputRect/TextInputRect"

const OnboardParentNameSection = () => {
  const { parentData, setParentData, previousStep } = useOnboardContext()
  const [warningText, setWarningText] = useState("")
  const { setStep } = useOnboardContext()

  const isCoparent = localStorage.getItem(localStorageItems.isCoparent)

  return (
    <OnboardingPageContentWrapper
      paddingTop="5dvh"
      style={{ position: "relative" }}
      backgroundOnDesktop={BACKGROUND_COLORS.lightBlueBg}
    >
      <ChevronBackButton onClick={previousStep} />
      <FlexBox direction="column" align="center" gap={SPACING.space6}>
        <img
          src="images/avatars/frumpulous-placeholder.png"
          alt="Avatar"
          style={{ width: "min(40%, 100px)" }}
        />
        <Typography variant="h2Serif" textAlign="center">
          Parent information
        </Typography>
        {!isCoparent && (
          <Typography
            variant="body"
            textAlign="center"
            style={{ width: "75%" }}
          >
            Let's start with yours, you can always add a co-parent later
          </Typography>
        )}
        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="body">First name</Typography>
          <TextInputRect
            fullWidth
            name="firstName"
            placeholder="Jane"
            onChange={(e: any) => setParentData("name", e.target.value)}
            value={parentData.name}
          />
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="body">
            Last name{" "}
            <Typography variant="bodyXSmall" color={JoonUIColor.text.secondary}>
              (optional)
            </Typography>
          </Typography>
          <TextInputRect
            fullWidth
            name="lastName"
            placeholder="Doe"
            onChange={(e: any) => setParentData("lastName", e.target.value)}
            value={parentData.lastName || ""}
          />
        </FlexBox>
      </FlexBox>
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        align="center"
        style={{ marginTop: SPACING.space4 }}
      >
        {warningText.length > 0 && (
          <Typography
            variant="bodySmallBold"
            color={JoonUIColor.semantic.alert}
          >
            {warningText}
          </Typography>
        )}
        <Primary3DButton
          text="Continue"
          fullWidth
          onClick={() => {
            if (parentData.name.length === 0) {
              setWarningText("Please enter your first name")
            } else {
              trackAnalyticEvent(ANALYTIC_EVENTS.ADD_PARENT_NAME)
              setStep(OnboardingStep.ParentNicknameAvatar)
            }
          }}
        />
      </FlexBox>
    </OnboardingPageContentWrapper>
  )
}

export default OnboardParentNameSection
