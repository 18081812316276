import { faArrowUpArrowDown } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  ChevronRightIcon,
  FlexBox,
  JoonUIColor,
  QuestStatus,
  SPACING,
  Typography,
  QuestInstance,
  QuestRoutine,
  SegmentedControl2,
  DropdownOption,
  UserRole,
  Button,
  TextButton,
} from "@joonapp/web-shared"
import { useShallow } from "zustand/react/shallow"

import { useAddEditQuestStore } from "./addEditQuestModal/useAddEditQuestStore"
import { useAddQuestTemplatesModalStore } from "./addQuestTemplates/useAddQuestTemplatesModalStore"
import QuestBoardDatePicker, {
  useQBDatePickerStore,
} from "./QuestBoardDatePicker"
import QuestCard from "./QuestCard"
import { QuestGroupCard } from "./QuestGroupCard"
import QuestRoutineSection from "./QuestRoutineSection"
import { useReorderQuestsModalStore } from "./reorderQuests/useReorderQuestsModalStore"
import useQuestBoardFilters from "./useQuestBoardFilters"
import useQuestBoardMultiSelectStore from "./useQuestBoardMultiSelectStore"
import useQuestBoardStore from "./useQuestBoardStore"
import { HoverCard } from "../../components/hoverCard/HoverCard"
import { QuestBoardSkeleton } from "../../components/skeleton/Skeleton"
import { TemplateFigure } from "../../components/templateFigure/TemplateFigure"
import { parentQuestItems, questRoutineOptions } from "../../constants"
import { useFamilyQuery, useQuestBoardQuery } from "../../networking/queries"
import { QuestSectionType } from "../../types/quests"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { capitalizeFirstLetter, sortQuestSection } from "../../util/util"

const AssignedQuests = () => {
  const { questBoardDate } = useQBDatePickerStore()
  const { filteredQuests } = useQuestBoardFilters()
  const { isLoading: isLoadingQuests } = useQuestBoardQuery()
  const { selectedRoutine, setSelectedRoutine } = useQuestBoardStore()
  const { onOpen: onOpenReorderQuestsModal } = useReorderQuestsModalStore()
  const { isMultiSelectMode } = useQuestBoardMultiSelectStore(
    useShallow((state) => ({
      isMultiSelectMode: state.isMultiSelectMode,
      selectedQuestIds: state.selectedQuestIds,
    }))
  )

  const {
    completedQuestSection,
    routineSections,
    inReviewQuestSection,
    rejectedQuestSection,
    skippedQuestSection,
    selfInitiatedQuestSection,
  } = createQuestSections(filteredQuests)

  const onReorderClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.REORDER_QUESTS)
    setSelectedRoutine(null)
    onOpenReorderQuestsModal()
  }

  return (
    <div>
      {!isMultiSelectMode && (
        <>
          <FlexBox
            align="flex-end"
            justify="space-between"
            wrap={false}
            style={{ marginBottom: SPACING.space1, marginTop: SPACING.space2 }}
          >
            <Typography variant="bodyBold">
              {questBoardDate.format("MMMM YYYY")}
            </Typography>
            <TextButton onClick={onReorderClick}>
              <FlexBox
                direction="row"
                align="center"
                gap={SPACING.space1}
                wrap={false}
              >
                <FontAwesomeIcon icon={faArrowUpArrowDown} size="sm" />
                <Typography variant="bodyXSmallBold">Reorder</Typography>
              </FlexBox>
            </TextButton>
          </FlexBox>
          <QuestBoardDatePicker />
          <SegmentedControl2
            options={questRoutineOptions}
            value={
              questRoutineOptions.find(
                (tab) => tab.value === selectedRoutine
              ) as DropdownOption
            }
            setValue={(option) =>
              setSelectedRoutine(option.value as QuestRoutine)
            }
            fullWidth
            style={{
              color: JoonUIColor.text.primaryAccent,
              margin: `${SPACING.space3} 0`,
              border: `1px solid ${JoonUIColor.border.default}`,
            }}
          />
        </>
      )}
      {isLoadingQuests ? (
        <QuestBoardSkeleton />
      ) : filteredQuests?.length === 0 ? (
        <QuestBoardEmptyDay />
      ) : (
        <FlexBox
          direction="column"
          gap={SPACING.space4}
          style={{ marginTop: SPACING.space4, paddingBottom: "160px" }}
        >
          {routineSections.map(([sectionTitle, quests], i) => (
            <QuestRoutineSection
              key={i}
              title={sectionTitle}
              questInstances={quests}
              icon={`/images/icons/${sectionTitle}-routine-icon.png`}
            />
          ))}

          {selfInitiatedQuestSection.length > 0 && (
            <FlexBox direction="column" gap={SPACING.space1}>
              <FlexBox direction="column" align="center">
                <Typography
                  variant="bodySmallBold"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: SPACING.space1,
                  }}
                >
                  <img
                    src="/images/icons/shooting-star.svg"
                    alt="Self-initiated"
                    style={{ height: "18px" }}
                  />
                  <div>Optional self-initiated quests</div>
                </Typography>
                <Typography variant="bodySmall">
                  (These quests have been picked up proactively by your child!)
                </Typography>
              </FlexBox>

              {selfInitiatedQuestSection.map((questInstances, i) => (
                <QuestCard
                  questInstance={questInstances[0]}
                  type={QuestSectionType.SELF_INITIATED}
                  index={i}
                  key={i}
                />
              ))}
            </FlexBox>
          )}

          {inReviewQuestSection.length > 0 && (
            <FlexBox direction="column" gap={SPACING.space1}>
              {inReviewQuestSection.map((questInstances, i) =>
                questInstances.length === 1 ? (
                  <QuestCard
                    questInstance={questInstances[0]}
                    type={QuestSectionType.REVIEW}
                    index={i}
                    key={i}
                  />
                ) : (
                  <QuestGroupCard
                    questGroup={questInstances}
                    index={i}
                    type={QuestSectionType.REVIEW}
                  />
                )
              )}
            </FlexBox>
          )}
          {completedQuestSection.length > 0 && (
            <FlexBox direction="column" gap={SPACING.space1}>
              {completedQuestSection.map((questInstances, i) =>
                questInstances.length === 1 ? (
                  <QuestCard
                    questInstance={questInstances[0]}
                    type={QuestSectionType.COMPLETED}
                    key={i}
                    index={i}
                  />
                ) : (
                  <QuestGroupCard
                    questGroup={questInstances}
                    index={i}
                    type={QuestSectionType.COMPLETED}
                  />
                )
              )}
            </FlexBox>
          )}
          {skippedQuestSection.length > 0 && (
            <FlexBox direction="column" gap={SPACING.space1}>
              {skippedQuestSection.map((questInstances, i) =>
                questInstances.length === 1 ? (
                  <QuestCard
                    questInstance={questInstances[0]}
                    type={QuestSectionType.SKIPPED}
                    key={i}
                    index={i}
                  />
                ) : (
                  <QuestGroupCard
                    questGroup={questInstances}
                    index={i}
                    type={QuestSectionType.SKIPPED}
                  />
                )
              )}
            </FlexBox>
          )}
          {rejectedQuestSection.length > 0 && (
            <FlexBox direction="column" gap={SPACING.space1}>
              {rejectedQuestSection.map((questInstances, i) =>
                questInstances.length === 1 ? (
                  <QuestCard
                    questInstance={questInstances[0]}
                    type={QuestSectionType.REJECTED}
                    key={i}
                    index={i}
                  />
                ) : (
                  <QuestGroupCard
                    questGroup={questInstances}
                    index={i}
                    type={QuestSectionType.REJECTED}
                  />
                )
              )}
            </FlexBox>
          )}
        </FlexBox>
      )}
    </div>
  )
}

export default AssignedQuests

const QuestBoardEmptyDay = () => {
  const { data } = useQuestBoardQuery()
  const { onOpen: openAddEditQuestModal } = useAddEditQuestStore()
  const { onOpen: openAddQuestTemplatesModal } =
    useAddQuestTemplatesModalStore()
  const { data: family } = useFamilyQuery()
  const { selectedUserId } = useQuestBoardStore()
  const { toggleUser } = useAddEditQuestStore()
  const selectedUser = family?.profiles?.find(
    (profile) => profile.user.id === selectedUserId
  )

  const onCreateQuestClick = () => {
    if (selectedUserId) toggleUser(selectedUserId)
    openAddEditQuestModal()
    trackAnalyticEvent(ANALYTIC_EVENTS.EMPTY_STATE_CREATE_CUSTOM, {
      type: "quest",
    })
  }

  const onOpenTemplatesClick = () => {
    openAddQuestTemplatesModal()
    trackAnalyticEvent(ANALYTIC_EVENTS.EMPTY_STATE_OPEN_TEMPLATES, {
      type: "quest",
    })
  }

  const hasNotCreatedFirstQuest = data?.length === 0
  const parentIds =
    family?.profiles
      .filter((profile) => profile.role === UserRole.PARENT)
      .map((profile) => profile.user.id) || []
  const hasNotCreatedParentQuests =
    data?.filter((quest) => parentIds.includes(quest.user_id)).length === 0

  return (
    <FlexBox
      align="center"
      justify="center"
      gap={SPACING.space3}
      direction="column"
      style={{ marginTop: SPACING.space3 }}
    >
      {hasNotCreatedFirstQuest ? (
        <Typography variant="h3">Welcome to Joon!</Typography>
      ) : selectedUser?.role === UserRole.PARENT ? (
        <Typography variant="bodySmallBold" color={JoonUIColor.text.secondary}>
          No parent quests assigned{" "}
          {selectedUser?.user.name &&
            `to ${capitalizeFirstLetter(selectedUser?.user.name)} `}
          {hasNotCreatedParentQuests ? "yet!" : "on this day."}
        </Typography>
      ) : (
        <Typography variant="bodySmallBold" color={JoonUIColor.text.secondary}>
          {capitalizeFirstLetter(selectedUser?.user.name ?? "This child")} has
          no quests assigned on this day.
        </Typography>
      )}

      {selectedUser?.role === UserRole.PARENT && hasNotCreatedParentQuests ? (
        <FlexBox
          direction="column"
          align="center"
          gap={SPACING.space4}
          wrap={false}
          style={{
            width: "min(450px, 100%)",
            padding: SPACING.space6,
            background: JoonUIColor.background.primaryNeutral,
            border: `1px solid ${JoonUIColor.border.default}`,
            borderRadius: SPACING.space2,
          }}
          fullWidth
        >
          <Typography variant="h3">
            Join in on the fun - parents can now also participate in Quests!
          </Typography>

          <FlexBox
            direction="column"
            align="flex-start"
            gap={SPACING.space3}
            wrap={false}
          >
            {parentQuestItems.map((item, i) => (
              <FlexBox
                key={i}
                direction="row"
                gap={SPACING.space2}
                wrap={false}
              >
                <FontAwesomeIcon
                  icon={item.icon}
                  color={JoonUIColor.icon.accent}
                />
                <FlexBox direction="column" gap={SPACING.space1} wrap={false}>
                  <Typography variant="bodySmallBold">{item.title}</Typography>
                  <Typography variant="bodySmall">
                    {item.description}
                  </Typography>
                </FlexBox>
              </FlexBox>
            ))}
          </FlexBox>

          <Button
            buttonType="secondary"
            onClick={onCreateQuestClick}
            text="Check it out"
            fullWidth
          />
        </FlexBox>
      ) : (
        <>
          <HoverCard fullWidth onClick={onCreateQuestClick}>
            <FlexBox justify="space-between" direction="row" align="center">
              <Typography
                variant="bodyBold"
                color={JoonUIColor.semantic.primary}
              >
                {hasNotCreatedFirstQuest
                  ? "+ Create your first Quest"
                  : `+ Create a quest for ${capitalizeFirstLetter(
                      selectedUser?.user.name ?? "this child"
                    )}`}
              </Typography>
              <ChevronRightIcon
                color={JoonUIColor.semantic.primary}
                size={24}
              />
            </FlexBox>
          </HoverCard>
          <Typography variant="bodySmallBold">Or..</Typography>
          <HoverCard fullWidth onClick={onOpenTemplatesClick}>
            <FlexBox
              justify="space-between"
              direction="row"
              align="center"
              wrap={false}
            >
              <FlexBox direction="column" gap={SPACING.space2} wrap={false}>
                <Typography
                  variant="bodyBold"
                  color={JoonUIColor.semantic.primary}
                >
                  See what other parents are creating:
                </Typography>

                <FlexBox justify="space-around" gap={SPACING.space6}>
                  <TemplateFigure
                    text="Calm mornings"
                    image="/images/icons/morning-routine-icon.png"
                  />
                  <TemplateFigure
                    text="Managing Emotions"
                    image="/images/icons/mental-health-icon.png"
                  />
                  <TemplateFigure
                    text="After School Focus"
                    image="/images/icons/focus-self-control-icon.png"
                  />
                </FlexBox>
              </FlexBox>
              <ChevronRightIcon color={JoonUIColor.icon.accent} size={24} />
            </FlexBox>
          </HoverCard>
        </>
      )}
    </FlexBox>
  )
}

const createQuestSections = (questList: QuestInstance[]) => {
  const completedQuestSection = [] as QuestInstance[][]
  const skippedQuestSection = [] as QuestInstance[][]
  const inReviewQuestSection = [] as QuestInstance[][]
  const rejectedQuestSection = [] as QuestInstance[][]
  const selfInitiatedQuestSection = [] as QuestInstance[][]
  const routineSections = {} as { [key: string]: QuestInstance[][] }

  questList?.forEach((quest) => {
    let targetSection: QuestInstance[][] | null = null

    switch (quest.status) {
      case QuestStatus.SKIPPED:
        targetSection = skippedQuestSection
        break
      case QuestStatus.COMPLETED:
        targetSection = inReviewQuestSection
        break
      case QuestStatus.VERIFIED:
      case QuestStatus.REDEEMED:
        targetSection = completedQuestSection
        break
      case QuestStatus.REJECTED:
      case QuestStatus.REJECTED_VIEWED:
        targetSection = rejectedQuestSection
        break
      default:
        if (quest.series.assigner_profile === null)
          targetSection = selfInitiatedQuestSection
        break
    }

    if (targetSection !== null) {
      const questGroup =
        quest.series.quest_group_id !== null
          ? targetSection.find(
              (questInst) =>
                questInst[0].series.quest_group_id ===
                quest.series.quest_group_id
            )
          : undefined
      if (!!questGroup) {
        questGroup.push(quest)
      } else {
        targetSection.push([quest])
      }
    } else if (quest.series.assigner_profile !== null) {
      if (!routineSections[quest.series.routine])
        routineSections[quest.series.routine] = []
      const questGroup =
        quest.series.quest_group_id !== null
          ? routineSections[quest.series.routine].find(
              (questInst) =>
                questInst[0].series.quest_group_id ===
                quest.series.quest_group_id
            )
          : undefined
      if (!!questGroup) {
        questGroup.push(quest)
      } else {
        routineSections[quest.series.routine].push([quest])
      }
    }
  })

  return {
    completedQuestSection: sortQuestSection(completedQuestSection),
    skippedQuestSection: sortQuestSection(skippedQuestSection),
    inReviewQuestSection: sortQuestSection(inReviewQuestSection),
    rejectedQuestSection: sortQuestSection(rejectedQuestSection),
    routineSections: sortQuestSections(routineSections),
    selfInitiatedQuestSection: sortQuestSection(selfInitiatedQuestSection),
  }
}

const sortQuestSections = (questSections: {
  [key: string]: QuestInstance[][]
}) => {
  const order: { [key: string]: number } = {
    morning: 0,
    afternoon: 1,
    night: 2,
    anytime: 3,
  }
  const result = Object.entries(questSections).sort(
    ([keyA], [keyB]) => order[keyA] - order[keyB]
  )
  return result
}
