import { faGripVertical } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  FlexBox,
  getDayAbbreviations,
  getRepetitionArrayFromRrule,
  getReptitionIntervalFromRrule,
  JoonUIColor,
  QuestSeries,
  SPACING,
  TherapistIcon,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useState } from "react"
import { rrulestr } from "rrule"

import UserBadge from "../../../components/badges/userBadge/UserBadge"
import { DragHandle } from "../../../components/dnd/DragHandle"
import useQuestBoardStore from "../useQuestBoardStore"
import { useQuestGroupSeries } from "../useQuestGroups"

type Props = {
  questSeries: QuestSeries
}

const QuestDndCard = ({ questSeries }: Props) => {
  const { selectedUserId } = useQuestBoardStore()
  const { recurrence, reminder_time, occurrence_limit, start_date } =
    questSeries || {}
  const { byweekday } = rrulestr(recurrence).options
  const { questGroupSeries } = useQuestGroupSeries(questSeries)

  const [isHovered, setIsHovered] = useState(false)
  const daysString =
    byweekday?.length === 7
      ? "Daily"
      : getDayAbbreviations(getRepetitionArrayFromRrule(recurrence))
  const frequency = getReptitionIntervalFromRrule(recurrence)

  const recurrenceText =
    occurrence_limit && start_date
      ? dayjs(start_date).format("M/D/YY")
      : `${daysString} every ${frequency > 1 ? frequency : ""} week${frequency > 1 ? "s" : ""}`
  const timeText = reminder_time ? ` at ${reminder_time}` : ""

  return (
    <FlexBox
      justify="space-between"
      direction="row"
      align="center"
      wrap={false}
      gap={SPACING.space2}
      style={{
        width: "100%",
        height: "fit-content",
        padding: `${SPACING.space3} ${SPACING.space10} ${SPACING.space3} ${SPACING.space4}`,
        border: `1px solid ${JoonUIColor.border.default}`,
        borderRadius: SPACING.space2,
        boxShadow: "4px 6px 6px 0px rgba(0, 0, 0, 0.08)",
        background: isHovered
          ? JoonUIColor.background.xlightGray
          : JoonUIColor.background.primaryNeutral,
        transition: "background 0.2s",
        touchAction: "pan-y",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <FlexBox
        direction="column"
        align="center"
        style={{ overflowX: "hidden", position: "relative" }}
      >
        {recurrence || reminder_time ? (
          <Typography variant="bodyXSmall" textAlign="left">
            {recurrenceText + timeText}
          </Typography>
        ) : null}
        <Typography
          variant="bodySmallBold"
          textAlign="left"
          style={{
            textOverflow: "ellipsis",
            width: "100%",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          <QuestCardTitle questSeries={questSeries} />
        </Typography>
        {selectedUserId === null && (
          <FlexBox direction="row" gap={SPACING.space1}>
            {questGroupSeries.map((series) => (
              <UserBadge key={series.id} userId={series.user_id} />
            ))}
          </FlexBox>
        )}
      </FlexBox>
      <DragHandle
        style={{
          position: "absolute",
          right: SPACING.space0,
          padding: SPACING.space6,
          top: "50%",
          transform: "translateY(-50%)",
          cursor: "grab",
          zIndex: 1000,
        }}
      >
        <FontAwesomeIcon
          icon={faGripVertical}
          fontSize={SPACING.space4}
          color={JoonUIColor.icon.neutral}
        />
      </DragHandle>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
    </FlexBox>
  )
}

export default QuestDndCard

export const QuestCardTitle = ({
  questSeries,
}: {
  questSeries: QuestSeries
}) => {
  return (
    <FlexBox align="center" gap={SPACING.space1} wrap={false}>
      {questSeries.current_frequency_target && (
        <TherapistIcon size={16} color={JoonUIColor.background.accent} />
      )}
      <Typography
        variant="bodySmallBold"
        style={{
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {questSeries.title}
      </Typography>
    </FlexBox>
  )
}
