import { FlexBox, SPACING } from "@joonapp/web-shared"

import "./onboardingPageContentWrapper.scss"
import useMobile from "../../../hooks/useMobile"

interface OnboardingPageContentWrapperProps
  extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode
  paddingTop?: string
  backgroundOnDesktop?: string
  backgroundImageOnDesktop?: string
  style?: React.CSSProperties
}

const OnboardingPageContentWrapper = ({
  children,
  paddingTop = "15dvh",
  backgroundOnDesktop,
  backgroundImageOnDesktop,
  style,
}: OnboardingPageContentWrapperProps) => {
  const isMobile = useMobile()
  return (
    <FlexBox
      className="jooniverse__onboarding-page-content-wrapper"
      direction="column"
      justify="space-between"
      align="center"
      wrap={false}
      style={{
        paddingTop: paddingTop,
        padding: SPACING.space6,
        gap: SPACING.space4,
        ...(backgroundOnDesktop && {
          background: isMobile ? "transparent" : backgroundOnDesktop,
          boxShadow: isMobile ? "none" : "0px 0px 20px 0px rgba(0, 0, 0, 0.2)",
        }),
        ...(backgroundImageOnDesktop && {
          backgroundImage: isMobile ? "none" : backgroundImageOnDesktop,
          boxShadow: isMobile ? "none" : "0px 0px 20px 0px rgba(0, 0, 0, 0.2)",
        }),
        ...style,
      }}
    >
      {children}
    </FlexBox>
  )
}

export default OnboardingPageContentWrapper
