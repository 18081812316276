import {
  CustomReward,
  CustomRewardCurrency,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import RewardRedeemButton from "./RewardRedeemButton"
import { useFamilyQuery } from "../../../networking/queries"
import {
  useCustomRewardBalancesQuery,
  useScreenTimeBalancesQuery,
} from "../../../networking/rewards/queries"
import { FamilyQuerySelect } from "../../../types"
import { getScreenTimeConfigurations } from "../../../util/util"

type Props = {
  childId: number
  reward: CustomReward
  isGrouped?: boolean
  style?: React.CSSProperties
}

const RewardChildRedeemCard = ({
  childId,
  reward,
  isGrouped,
  style,
}: Props) => {
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const { data: rewardsBalances } = useCustomRewardBalancesQuery({
    rewardId: reward.id,
  })
  const { data: screenTimeBalances } = useScreenTimeBalancesQuery({
    rewardId: reward.id,
  })
  const { numLinkedDevices } = getScreenTimeConfigurations(reward, childId)
  const rewardBalance = rewardsBalances?.find(
    (balance) => balance.user_id === childId
  )
  const rewardBalanceReadyToUse = screenTimeBalances?.find(
    (balance) => balance.user_id === childId
  )
  const balance = rewardBalance?.balance || 0

  const balanceText =
    reward.currency === CustomRewardCurrency.DOLLAR
      ? `$${balance}`
      : reward.currency === CustomRewardCurrency.MINUTE
        ? `${balance} min${balance === 1 ? "" : "s"}`
        : `${balance}`

  const child = children?.find((child) => child.user.id === childId)

  return (
    <FlexBox
      justify="space-between"
      direction="row"
      align="center"
      wrap={false}
      gap={SPACING.space2}
      style={{
        width: "100%",
        padding: `${SPACING.space3} ${SPACING.space4}`,
        border: "1px solid JoonUIColor.border.default",
        borderRadius: SPACING.space2,
        background: JoonUIColor.background.primaryNeutral,
        ...style,
      }}
    >
      {isGrouped ? (
        <FlexBox
          direction="row"
          align="center"
          justify="flex-start"
          wrap={false}
          gap={SPACING.space1}
          style={{ width: "60%" }}
        >
          <Typography
            variant="bodySmallBold"
            style={{
              backgroundColor:
                balance > 0
                  ? JoonColorExpanded.green200
                  : JoonUIColor.icon.light,
              padding: `${SPACING.space05} ${SPACING.space1}`,
              borderRadius: SPACING.space1,
              whiteSpace: "nowrap",
            }}
          >
            {balanceText}
          </Typography>
          <Typography
            variant="bodySmallBold"
            textAlign="left"
            style={{
              textOverflow: "ellipsis",
              width: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {child?.user.name}
          </Typography>
        </FlexBox>
      ) : (
        <FlexBox
          direction="column"
          align="flex-start"
          justify="center"
          wrap={false}
          gap={SPACING.space1}
        >
          <Typography
            variant="bodySmallBold"
            textAlign="left"
            style={{
              textOverflow: "ellipsis",
              width: "100%",
              overflow: "hidden",
            }}
          >
            {child?.user.name}
          </Typography>
          {numLinkedDevices > 0 && (
            <Typography variant="bodySmall" color={JoonUIColor.text.secondary}>
              Smart redemption linked on {numLinkedDevices} device
              {numLinkedDevices === 1 ? "" : "s"}
            </Typography>
          )}
          <Typography
            variant="bodySmallBold"
            style={{
              backgroundColor:
                balance > 0
                  ? JoonColorExpanded.green200
                  : JoonUIColor.icon.light,
              padding: `${SPACING.space05} ${SPACING.space1}`,
              borderRadius: SPACING.space1,
            }}
          >
            {balanceText}
            <Typography variant="bodySmall"> purchased</Typography>
            {rewardBalanceReadyToUse?.balance && (
              <>
                <Typography variant="bodySmall">{` | `}</Typography>
                {rewardBalanceReadyToUse.balance} min
                {rewardBalanceReadyToUse.balance === 1 ? " " : "s "}
                <Typography variant="bodySmall">ready to use</Typography>
              </>
            )}
          </Typography>
        </FlexBox>
      )}
      <FlexBox
        direction="row"
        align="center"
        justify="flex-end"
        gap={SPACING.space1}
        wrap={false}
        style={{ width: "fit-content" }}
      >
        {rewardBalance?.balance ? (
          <RewardRedeemButton rewardBalance={rewardBalance} childId={childId} />
        ) : (
          <Typography
            variant="bodySmall"
            color={JoonUIColor.text.secondary}
            style={{
              textAlign: "right",
              fontStyle: "italic",
            }}
          >
            Nothing to redeem!
          </Typography>
        )}
      </FlexBox>
    </FlexBox>
  )
}

export default RewardChildRedeemCard
