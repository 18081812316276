import useAIQuestStore from "./hooks/useAIQuestStore"
import QuestAIContent from "./QuestAIContent"
import SlideUpModal from "../../../components/slideUpModal/SlideUpModal"
import useMobile from "../../../hooks/useMobile"

const QuestAIModal = () => {
  const { isOpen, onClose } = useAIQuestStore()
  const isMobile = useMobile()

  if (!isMobile) return null

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      showCard={true}
      size="full-height"
      backgroundType="overlay"
    >
      <QuestAIContent />
    </SlideUpModal>
  )
}

export default QuestAIModal
