import { useEffect } from "react"

import PageLoader from "../components/loading/PageLoader"
import CreatingPlan from "../components/onboarding/CreatingPlan"
import OnboardAddChildOrJoinFamily from "../components/onboarding/OnboardAddChildOrJoinFamily"
import OnboardAddChildrenSection from "../components/onboarding/OnboardAddChildrenSection"
import OnboardCoparentCodeSection from "../components/onboarding/OnboardCoparentCodeSection"
import OnboardingPageWrapper from "../components/onboarding/onboardingPageWrapper/OnboardingPageWrapper"
import OnboardParentAvatarSection from "../components/onboarding/OnboardParentAvatarSection"
import OnboardParentNameSection from "../components/onboarding/OnboardParentNameSection"
import QuestionnaireSection from "../components/onboarding/questionnaires/QuestionnaireSection"
import SignupSection from "../components/onboarding/SignupSection"
import { BACKGROUND_COLORS } from "../constants"
import { useAccountCreation } from "../hooks/useAccountCreation"
import useDocumentTitle from "../hooks/useDocumentTitle"
import useMobile from "../hooks/useMobile"
import useOnboardContext, {
  ChildStep,
  OnboardingStep,
} from "../hooks/useOnboardContext"
import {
  useFamilyQuery,
  useQuestionnairesQuery,
  useUserQuery,
} from "../networking/queries"
import { QuestionAnswerMappingInterface } from "../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../util/analytics"
import { useRouter } from "../util/router"
import { localStorageItems, sessionManager } from "../util/storage"

export const ImagePreloader = () => {
  return (
    <div style={{ display: "none" }}>
      <img src="/images/background/cloud-bg-mobile.png" alt="cloud-bg-mobile" />
      <img src="/images/background/cloud-bg.png" alt="cloud-bg" />
      <img
        src="/images/background/welcome-bg-mobile.png"
        alt="welcome-bg-mobile"
      />
      <img src="/images/background/welcome-bg.png" alt="welcome-bg" />
      <img src="/images/gifs/onboarding-clipboard-gif.gif" alt="clipboard" />
      <img src="/images/gifs/onboarding-zoom-search-gif.gif" alt="looking" />
      <img src="/images/gifs/onboarding-routine-cycle-gif.gif" alt="routine" />
      <img src="/images/gifs/onboarding-tree-clouds-gif.gif" alt="therapist" />
      <img src="/images/gifs/onboarding-grow-plant-gif.gif" alt="treehouse" />
    </div>
  )
}

function OnboardAccountPage() {
  useDocumentTitle("Joon - Create Account")
  const router = useRouter()
  const { user } = useUserQuery()
  const isMobile = useMobile()

  const {
    setParentData,
    currentStep,
    creatingPlan,
    nextStep,
    previousStep,
    parentQuestionnaireStep,
    setParentQuestionnaireStep,
    childStep,
    postAnswerScreen,
  } = useOnboardContext()
  const { uploadOnboardingSecondaryData } = useAccountCreation()

  const { data: questionsResponse } = useQuestionnairesQuery()
  const { data: family } = useFamilyQuery()

  useEffect(() => {
    if (!sessionManager.getRefreshToken()) return
    if (user || family) return router.push("/quests")

    trackAnalyticEvent(ANALYTIC_EVENTS.START_ONBOARD, {
      utm_source: router.query.utm_source,
      utm_campaign: router.query.utm_campaign,
      ad_set_name: router.query.ad_set_name,
      ad_name: router.query.ad_name,
    })
    // eslint-disable-next-line
  }, [user])

  const onConfirmParentQuestionnaire = (parentQuestionnaireMapping: {
    questionAnswerMapping: QuestionAnswerMappingInterface
  }) => {
    setParentData(
      "parentQuestionnaireMapping",
      parentQuestionnaireMapping.questionAnswerMapping
    )
    const lastQuestionIndex = questionsResponse
      ? questionsResponse.parentQuestions.length - 1
      : 0
    setParentQuestionnaireStep(lastQuestionIndex)
    const existingAnswers = JSON.parse(
      localStorage.getItem(localStorageItems.parentQuestionnaireAnswers) || "{}"
    )
    const updatedAnswers = {
      ...existingAnswers,
      ...parentQuestionnaireMapping.questionAnswerMapping,
    }
    localStorage.setItem(
      localStorageItems.parentQuestionnaireAnswers,
      JSON.stringify(updatedAnswers)
    )
    nextStep()
  }

  if (currentStep === OnboardingStep.CreateAccountWithCredentials) {
    uploadOnboardingSecondaryData()
  }

  const showCloudBackground =
    postAnswerScreen ||
    currentStep === OnboardingStep.AddChildOrJoinFamily ||
    (currentStep === OnboardingStep.AddChildren &&
      childStep === ChildStep.QuestionnaireDisclaimer) ||
    creatingPlan

  return (
    <OnboardingPageWrapper
      style={{
        background: !isMobile
          ? BACKGROUND_COLORS.xlightBlueBg
          : showCloudBackground
            ? undefined
            : currentStep === OnboardingStep.EmailPassword
              ? "linear-gradient(to bottom, rgba(245, 250, 255, 1), rgba(210, 230, 250, 1))"
              : BACKGROUND_COLORS.lightBlueBg,
        backgroundImage:
          isMobile && showCloudBackground
            ? "url(/images/background/cloud-bg-mobile.png)"
            : undefined,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {creatingPlan ? (
        <CreatingPlan />
      ) : currentStep === OnboardingStep.ParentName ? (
        <OnboardParentNameSection />
      ) : currentStep === OnboardingStep.ParentNicknameAvatar ? (
        <OnboardParentAvatarSection />
      ) : currentStep === OnboardingStep.ParentingQuestionnaire ? (
        <QuestionnaireSection
          questions={questionsResponse?.parentQuestions || []}
          onConfirm={onConfirmParentQuestionnaire}
          previousStep={previousStep}
          lastSeenStep={parentQuestionnaireStep}
        />
      ) : currentStep === OnboardingStep.AddChildOrJoinFamily ? (
        <OnboardAddChildOrJoinFamily />
      ) : currentStep === OnboardingStep.CoparentCode ? (
        <OnboardCoparentCodeSection />
      ) : currentStep === OnboardingStep.AddChildren ? (
        <OnboardAddChildrenSection />
      ) : currentStep === OnboardingStep.EmailPassword ? (
        <SignupSection />
      ) : (
        <PageLoader />
      )}
      <ImagePreloader />
    </OnboardingPageWrapper>
  )
}

export default OnboardAccountPage
