import {
  Button,
  FlexBox,
  Modal,
  QuestRoutine,
  UserRole,
} from "@joonapp/web-shared"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"

import QuestDndList from "./QuestDndList.tsx"
import { useReorderQuestsModalStore } from "./useReorderQuestsModalStore.ts"
import { useReorderQuestsMutation } from "./useReorderQuestsMutation.ts"
import { CloseButton } from "../../../components/closeButton/CloseButton.tsx"
import { Toasts } from "../../../components/toaster/Toaster.tsx"
import { QUERY_KEYS } from "../../../constants.tsx"
import { useReorderQuestsQuery } from "../../../networking/queries.ts"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics.ts"
import { queryClient } from "../../../util/queryClient.ts"
import QuestBoardUserSelectHeader from "../QuestBoardUserSelectHeader.tsx"
import useQuestBoardStore from "../useQuestBoardStore.ts"

const ReorderQuestsModal = () => {
  const { data: quests } = useReorderQuestsQuery()
  const { isOpen, onClose, userHasMadeChanges, sections } =
    useReorderQuestsModalStore()
  const { selectedUserId } = useQuestBoardStore()
  const [isLoadingQuestBoard, setIsLoadingQuestBoard] = useState(false)

  const reorderQuestsMutation = useReorderQuestsMutation()

  useEffect(() => {
    document.getElementById("quests-dnd-list")?.scrollTo(0, 0)
  }, [selectedUserId])

  const onSave = async () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.REORDER_QUESTS_SAVE)
    const newOrders = [
      ...sections[0][1].map((quest) => ({
        id: quest.id,
        order: quest.order,
        routine: QuestRoutine.MORNING,
        role: UserRole.PARENT,
      })),
      ...sections[1][1].map((quest) => ({
        id: quest.id,
        order: quest.order,
        routine: QuestRoutine.AFTERNOON,
        role: UserRole.PARENT,
      })),
      ...sections[2][1].map((quest) => ({
        id: quest.id,
        order: quest.order,
        routine: QuestRoutine.NIGHT,
        role: UserRole.PARENT,
      })),
      ...sections[3][1].map((quest) => ({
        id: quest.id,
        order: quest.order,
        routine: QuestRoutine.ANYTIME,
        role: UserRole.PARENT,
      })),
    ]

    const newOrdersWithGroupedQuests: typeof newOrders = []

    // we need to include the remaining grouped quests (all will have same order)
    newOrders.forEach((orderedQuest) => {
      const questSeries = quests?.find((quest) => quest.id === orderedQuest.id)
      const questGroupId = questSeries?.quest_group_id || null

      const questGroupSeries =
        questGroupId === null || !quests
          ? []
          : quests.filter((quest) => quest.quest_group_id === questGroupId)

      if (questGroupSeries.length > 1) {
        newOrdersWithGroupedQuests.push(
          ...questGroupSeries.map((series) => ({
            id: series.id,
            order: orderedQuest.order,
            routine: orderedQuest.routine,
            role: UserRole.PARENT,
          }))
        )
      } else newOrdersWithGroupedQuests.push(orderedQuest)
    })

    await reorderQuestsMutation.mutateAsync(newOrdersWithGroupedQuests)
    setIsLoadingQuestBoard(true)
    await queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
    setIsLoadingQuestBoard(false)
    onClose()
    toast.success(Toasts.successReorderingQuests, { duration: 1500 })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      mobileFull
      style={{ height: "100dvh", width: "min(600px, 100%)" }}
      animate
    >
      <FlexBox direction="column" justify="space-between">
        <CloseButton onClick={onClose} />
        <QuestBoardUserSelectHeader />
        <div
          id="quests-dnd-list"
          className="px-4 py-3 pr-3"
          style={{
            maxHeight: `calc(100dvh - 88px${userHasMadeChanges ? " - 71px" : ""})`,
            transition: "height 0.2s ease",
            overflowY: "auto",
            overflowX: "hidden",
            width: "100%",
          }}
        >
          <QuestDndList />
        </div>

        {userHasMadeChanges && (
          <div className="px-4 py-3 w-full">
            <Button
              buttonType="primary"
              text="Save Order"
              onClick={onSave}
              fullWidth
              isLoading={reorderQuestsMutation.isLoading || isLoadingQuestBoard}
            />
          </div>
        )}
      </FlexBox>
    </Modal>
  )
}

export default ReorderQuestsModal
