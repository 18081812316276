import InviteCode from "./InviteCode"
import InviteContent from "./InviteContent"
import { useInviteCodeStore } from "./useInviteCodeStore"

function InvitePage() {
  const { isInvited } = useInviteCodeStore()

  return isInvited ? <InviteCode /> : <InviteContent />
}

export default InvitePage
