import {
  faBullseyeArrow,
  faMobileAndroid,
  faPartyHorn,
  faUnlock,
} from "@fortawesome/duotone-light-svg-icons"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  Checkbox,
  CustomRewardCurrency,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"
import { create } from "zustand"

import { CloseButton } from "../../../components/closeButton/CloseButton"
import TransitionWrapper from "../../../components/coaching/TransitionWrapper"
import SlideUpModal from "../../../components/slideUpModal/SlideUpModal"
import { SmartRedemptionButton } from "../../../components/smartRedemptionButton/SmartRedemptionButton"
import {
  QUERY_KEYS,
  SmartRedemptionAnalyticsStep,
  SmartRedemptionModalStep,
} from "../../../constants"
import useMobile from "../../../hooks/useMobile"
import { useFamilyQuery, useUserQuery } from "../../../networking/queries"
import { FamilyQuerySelect } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { queryClient } from "../../../util/queryClient"
import { useGetChildStartedModal } from "../../family/GetChildStartedModal"
import { useParentPasscodeModalStore } from "../../family/manageProfile/ParentPasscodeModal"
import useAddEditRewardModalStore from "../hooks/useAddEditRewardModalStore"

interface SmartRedemptionModalStoreProps {
  isOpen: boolean
  onOpen: (step?: SmartRedemptionModalStep) => void
  onClose: () => void
  step: SmartRedemptionModalStep
  setStep: (step: SmartRedemptionModalStep) => void
  isPostCreate: boolean
  setIsPostCreate: (isPostCreate: boolean) => void
}

export const useSmartRedemptionModalStore =
  create<SmartRedemptionModalStoreProps>()((set) => ({
    isOpen: false,
    onOpen: (step?: SmartRedemptionModalStep) => set({ isOpen: true, step }),
    onClose: () => set({ isOpen: false, step: SmartRedemptionModalStep.INTRO }),
    step: SmartRedemptionModalStep.ANNOUNCEMENT,
    setStep: (step: SmartRedemptionModalStep) => set({ step }),
    isPostCreate: false,
    setIsPostCreate: (isPostCreate: boolean) => set({ isPostCreate }),
  }))

export const SmartRedemptionModal = () => {
  const { isOpen, onClose, step, setStep } = useSmartRedemptionModalStore()

  const isMobile = useMobile()

  const isFirstStep =
    step === SmartRedemptionModalStep.ANNOUNCEMENT ||
    step === SmartRedemptionModalStep.INTRO

  const showHeader = !isFirstStep && step !== SmartRedemptionModalStep.FINISH

  const isFullHeight = isMobile && !isFirstStep

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      size={"fit-content"}
      backgroundType="overlay"
    >
      <FlexBox
        direction="column"
        justify="flex-start"
        fullWidth
        style={{
          background: JoonUIColor.background.xlightGray,
          ...(isFullHeight
            ? {
                height: "95vh",
                marginTop: "auto",
              }
            : {
                height: "100%",
              }),
        }}
      >
        {showHeader && (
          <FlexBox
            direction="row"
            justify="space-between"
            align="center"
            fullWidth
            wrap={false}
            style={{
              padding: `${SPACING.space2} ${SPACING.space6}`,
              borderBottom: `1px solid ${JoonUIColor.border.default}`,
              background: JoonUIColor.background.primaryNeutral,
              height: "50px",
            }}
          >
            <FlexBox
              direction="row"
              justify="flex-start"
              align="center"
              gap={SPACING.space1}
              fullWidth
            >
              {step !== SmartRedemptionModalStep.DEVICE_SELECTION && (
                <TextButton onClick={() => setStep(step - 1)}>
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    color={JoonUIColor.icon.neutral}
                    fontSize={SPACING.space4}
                  />
                </TextButton>
              )}
              <Typography variant="h3">Screen Time Control set up</Typography>
            </FlexBox>
            <CloseButton
              onClick={onClose}
              style={{ position: "relative", top: "unset", right: "unset" }}
            />
          </FlexBox>
        )}
        <TransitionWrapper
          showChildren={step === SmartRedemptionModalStep.ANNOUNCEMENT}
          style={{
            width: "100%",
          }}
        >
          <AnnouncementStep />
        </TransitionWrapper>
        <TransitionWrapper
          showChildren={step === SmartRedemptionModalStep.INTRO}
          style={{
            width: "100%",
          }}
        >
          <IntroStep />
        </TransitionWrapper>
        <TransitionWrapper
          showChildren={step === SmartRedemptionModalStep.DEVICE_SELECTION}
          style={{
            width: "100%",
          }}
        >
          <DeviceSelectionStep />
        </TransitionWrapper>
        <TransitionWrapper
          showChildren={step === SmartRedemptionModalStep.SETUP_PASSCODE}
          style={{
            width: "100%",
          }}
        >
          <SetupPasscodeStep />
        </TransitionWrapper>
        <TransitionWrapper
          showChildren={step === SmartRedemptionModalStep.SETUP_JOONITY}
          style={{
            width: "100%",
          }}
        >
          <SetupJoonityStep />
        </TransitionWrapper>
        <TransitionWrapper
          showChildren={step === SmartRedemptionModalStep.FINISH}
          style={{
            width: "100%",
            height: step === SmartRedemptionModalStep.FINISH ? "100%" : "0%",
          }}
        >
          <FinishStep />
        </TransitionWrapper>
      </FlexBox>
    </SlideUpModal>
  )
}

const AnnouncementStep = () => {
  const { onClose } = useSmartRedemptionModalStore()
  const {
    selectedChildren,
    onOpen: onOpenAddEditRewardModal,
    setCurrency,
    setEmoji,
    setCost,
    setTitle,
    setSelectedChildren,
  } = useAddEditRewardModalStore()
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)

  const onCreateReward = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.SCREENTIME_FTUX_CREATE_REWARD)
    if (children?.[0]?.user?.id && selectedChildren.length === 0)
      setSelectedChildren([children?.[0]?.user?.id])
    setCurrency(CustomRewardCurrency.MINUTE)
    setCost(4)
    setTitle("Screen Time")
    setEmoji("📱")
    // note: there is also a useEffect in AddEditRewardModal that sets the same stuff
    onOpenAddEditRewardModal()
    onClose()
  }

  const infoItems = [
    {
      icon: faBullseyeArrow,
      title: "You choose which apps to lock",
      description:
        "Decide which apps your child can earn time for and for how long.",
    },
    {
      icon: faUnlock,
      title: "Approve requests & Track Usage",
      description:
        "Get notified and approve unlock requests, and see how many minutes spent per app.",
    },
    {
      icon: faPartyHorn,
      title: "Earned, Not Demanded:",
      description:
        "Kids feel empowered to earn screen time, while you stay in the loop—no surprises!",
    },
  ]

  return (
    <FlexBox direction="column" fullWidth>
      <SmartRedemptionImage />

      <FlexBox
        direction="column"
        gap={SPACING.space4}
        align="center"
        style={{ padding: SPACING.space6 }}
      >
        <FlexBox direction="column" gap={SPACING.space2}>
          <FlexBox
            gap={SPACING.space2}
            style={{
              width: "fit-content",
              background: JoonColorExpanded.yellow300,
              borderRadius: SPACING.space2,
              padding: `${SPACING.space1} ${SPACING.space2}`,
            }}
          >
            <Typography variant="bodyXSmall" style={{ fontWeight: 600 }}>
              New from Joon!
            </Typography>
          </FlexBox>

          <Typography variant="h3">
            Keep Your Child on Task - Lock Apps Until Time is Earned
          </Typography>
        </FlexBox>

        {infoItems.map((item, index) => (
          <FlexBox direction="row" gap={SPACING.space1} wrap={false}>
            <FontAwesomeIcon
              icon={item.icon}
              color={JoonUIColor.icon.accent}
              fontSize={SPACING.space4}
            />
            <FlexBox key={index} direction="column" wrap={false}>
              <Typography
                variant="bodySmallBold"
                color={JoonUIColor.text.primary}
              >
                {item.title}
              </Typography>
              <Typography variant="bodySmall" color={JoonUIColor.text.primary}>
                {item.description}
              </Typography>
            </FlexBox>
          </FlexBox>
        ))}
        <FlexBox
          direction="column"
          gap={SPACING.space2}
          justify="center"
          align="center"
          fullWidth
        >
          <Button
            text={"Set Up Screen-Time Rewards"}
            onClick={onCreateReward}
            fullWidth
            style={{ position: "sticky", bottom: SPACING.space2 }}
          />
          <TextButton
            onClick={() => {
              trackAnalyticEvent(ANALYTIC_EVENTS.SCREENTIME_FTUX_DISMISS)
              onClose()
            }}
            style={{ width: "fit-content" }}
          >
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Maybe later
            </Typography>
          </TextButton>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

const IntroStep = () => {
  const { setStep, isPostCreate } = useSmartRedemptionModalStore()

  const infoItems = [
    {
      icon: faBullseyeArrow,
      boldText: "Choose which apps to limit",
      text: "on your child's device",
    },
    {
      icon: faUnlock,
      boldText: "Approve screen time in blocked apps",
      text: "after your child earns coins",
    },
    {
      icon: faMobileAndroid,
      boldText: "All you need is your child's device",
      text: "to complete setup",
    },
  ]

  return (
    <FlexBox direction="column" gap={SPACING.space2} fullWidth>
      <SmartRedemptionImage />

      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{ padding: SPACING.space6, paddingBottom: 0 }}
      >
        <Typography variant="h3">
          Finish setting up your screen time reward
        </Typography>
        {infoItems.map((item, index) => (
          <FlexBox
            key={index}
            direction="row"
            justify="flex-start"
            align="flex-start"
            gap={SPACING.space1}
            wrap={false}
          >
            <FontAwesomeIcon
              icon={item.icon}
              color={JoonUIColor.icon.accent}
              fontSize={SPACING.space4}
            />
            <Typography variant="bodySmallBold">
              {item.boldText}{" "}
              <Typography variant="bodySmall">{item.text}</Typography>
            </Typography>
          </FlexBox>
        ))}
      </FlexBox>

      <div
        style={{
          width: "100%",
          padding: SPACING.space6,
          paddingTop: SPACING.space4,
        }}
      >
        <Button
          text="Continue to Set Up"
          onClick={() => {
            trackAnalyticEvent(ANALYTIC_EVENTS.VIEW_SCREENTIME_INSTRUCTIONS, {
              post_create: isPostCreate,
            })
            trackAnalyticEvent(ANALYTIC_EVENTS.SCREENTIME_INSTRUCTIONS, {
              step: SmartRedemptionAnalyticsStep.DEVICE_SELECTION,
            })
            setStep(SmartRedemptionModalStep.DEVICE_SELECTION)
          }}
          fullWidth
        />
      </div>
    </FlexBox>
  )
}

const DeviceSelectionStep = () => {
  const [hasAppleDevices, setHasAppleDevices] = useState<boolean | null>(null)
  const { setStep, onClose } = useSmartRedemptionModalStore()

  const onContinue = () => {
    if (!hasAppleDevices) {
      onClose()
    } else {
      trackAnalyticEvent(ANALYTIC_EVENTS.SCREENTIME_INSTRUCTIONS, {
        step: SmartRedemptionAnalyticsStep.SETUP_PASSCODE,
      })
      setStep(SmartRedemptionModalStep.SETUP_PASSCODE)
    }
  }

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space4}
      fullWidth
      style={{
        height: "100%",
        background: JoonUIColor.background.xlightGray,
        padding: `${SPACING.space4} ${SPACING.space6} ${SPACING.space6}`,
      }}
    >
      <Typography variant="bodyBold">
        Do any of your children have Apple devices?
      </Typography>

      <FlexBox
        direction="column"
        fullWidth
        style={{
          borderRadius: SPACING.space2,
          overflow: "hidden",
          border: `1px solid ${JoonUIColor.border.default}`,
        }}
      >
        <SmartRedemptionButton
          onClick={() => setHasAppleDevices(true)}
          style={{
            border: "none",
            borderRadius: 0,
            borderBottom: `1px solid ${JoonUIColor.border.default}`,
          }}
        >
          <Typography variant="bodySmallBold" textAlign="left">
            Yes
          </Typography>
          <Checkbox
            selected={hasAppleDevices === true}
            inputType="radio"
            name="hasAppleDevices"
            onChange={() => {}}
            hideBorder
          />
        </SmartRedemptionButton>
        <SmartRedemptionButton
          onClick={() => setHasAppleDevices(false)}
          style={{
            border: "none",
          }}
        >
          <Typography variant="bodySmallBold" textAlign="left">
            No
          </Typography>
          <Checkbox
            selected={hasAppleDevices === false}
            inputType="radio"
            name="hasAppleDevices"
            onChange={() => {}}
            hideBorder
          />
        </SmartRedemptionButton>
      </FlexBox>

      {hasAppleDevices === true ? (
        <FlexBox direction="column" gap={SPACING.space1} fullWidth>
          <Typography variant="bodySmallBold">
            🙌 Awesome! You're ready for the next step!
          </Typography>
          <Typography variant="bodySmall">
            Note: Make sure that your child's device is an iPad or iPhone on
            version iOS 18+.
          </Typography>
        </FlexBox>
      ) : (
        hasAppleDevices === false && (
          <FlexBox direction="column" gap={SPACING.space1} fullWidth>
            <Typography variant="bodySmallBold">Sorry 😔</Typography>
            <Typography variant="bodySmall">
              Screen time controls currently only work on Apple devices. Email{" "}
              <a
                href="mailto:contact@joonapp.io"
                style={{ color: JoonUIColor.text.primaryAccent }}
              >
                contact@joonapp.io
              </a>{" "}
              to learn about our Android release plan.
            </Typography>
          </FlexBox>
        )
      )}

      <Button
        text={hasAppleDevices === false ? "Go home" : "Continue"}
        onClick={onContinue}
        disabled={hasAppleDevices === null}
        fullWidth
      />
      <SmartRedemptionTroubleshooting />
    </FlexBox>
  )
}

const SetupPasscodeStep = () => {
  const { setStep } = useSmartRedemptionModalStore()
  const { onOpen: onOpenParentPasscodeModal } = useParentPasscodeModalStore()
  const { user } = useUserQuery()

  const onSkip = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.SCREENTIME_INSTRUCTIONS, {
      step: SmartRedemptionAnalyticsStep.SETUP_JOONITY,
    })
    setStep(SmartRedemptionModalStep.SETUP_JOONITY)
  }

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space4}
      fullWidth
      style={{ padding: SPACING.space6 }}
    >
      <Typography variant="bodyBold">Set up Parent Pin Code</Typography>
      <Typography variant="bodySmall">
        This prevents your child from changing screen time settings.
      </Typography>
      <Typography variant="bodySmall">
        This code will be required to access the mobile parent app, the Parent
        Panel on your child's device, and when switching child accounts in the
        game.
      </Typography>
      <FlexBox
        direction="column"
        gap={user?.passcode === null ? SPACING.space2 : SPACING.space4}
        fullWidth
      >
        {user?.passcode === null ? (
          <Button
            text="Set Parent Pin"
            onClick={onOpenParentPasscodeModal}
            fullWidth
          />
        ) : (
          <SmartRedemptionButton onClick={onOpenParentPasscodeModal}>
            <Typography variant="bodyXSmallBold" textAlign="left">
              Parent security code
            </Typography>
            <Typography
              variant="bodyXSmallBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Update
            </Typography>
          </SmartRedemptionButton>
        )}
        <Button
          text={user?.passcode === null ? "Skip" : "Next"}
          buttonType={user?.passcode === null ? "secondary" : "primary"}
          onClick={onSkip}
          fullWidth
        />
      </FlexBox>
      <Typography variant="bodySmall">
        You can always change this by going to the Family tab → Your name →
        Manage Profile.
      </Typography>
      <SmartRedemptionTroubleshooting />
    </FlexBox>
  )
}

const SetupJoonityStep = () => {
  enum KidAppSetupStep {
    OPEN_JOON_KID_APP,
    OPEN_SETTINGS,
    TAP_PARENT_PANEL,
    AUTHORIZE_SCREEN_TIME_CONTROLS,
    FOLLOW_INSTRUCTIONS,
  }

  const [isLoading, setIsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState<KidAppSetupStep>(
    KidAppSetupStep.OPEN_JOON_KID_APP
  )
  const { setStep: setSmartRedemptionModalStep } =
    useSmartRedemptionModalStore()
  const { onOpen: onOpenGetChildStartedModal } = useGetChildStartedModal()

  const setupSteps = [
    "Open the Joon Pet Game (kid app) on your child's device",
    "Open the Settings (Gear icon on the top right)",
    "Tap Parent Panel and enter your Parent Pin",
    "Authorize Joon Pet Game to access Screen Time Controls",
    `Follow on-screen instructions to select the child, the reward, and the apps you want to block for it.\n\nOnce done, return here and tap finish below!`,
  ]

  const onNext = async () => {
    if (currentStep !== KidAppSetupStep.FOLLOW_INSTRUCTIONS) {
      setCurrentStep((currentStep + 1) as KidAppSetupStep)
      return
    }
    setIsLoading(true)
    await queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARDS])
    await queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_BALANCES])
    await queryClient.invalidateQueries([QUERY_KEYS.SCREEN_TIME_BALANCES])
    setIsLoading(false)
    trackAnalyticEvent(ANALYTIC_EVENTS.SCREENTIME_INSTRUCTIONS, {
      step: SmartRedemptionAnalyticsStep.FINISH,
    })
    setSmartRedemptionModalStep(SmartRedemptionModalStep.FINISH)
  }

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space4}
      fullWidth
      style={{ padding: SPACING.space6 }}
    >
      <Typography variant="bodyBold">Set up on your child's device</Typography>

      <FlexBox
        direction="column"
        align="center"
        justify="flex-start"
        gap={SPACING.space2}
        fullWidth
        wrap={false}
        style={{
          padding: SPACING.space3,
          background: JoonUIColor.background.primaryNeutral,
          borderRadius: SPACING.space2,
          overflow: "hidden",
          border: `1px solid ${JoonUIColor.border.default}`,
        }}
      >
        <Typography
          variant="bodySmall"
          color={JoonUIColor.text.secondary}
          textAlign="center"
        >
          Step {currentStep + 1} of {setupSteps.length}
        </Typography>
        <Typography
          variant="bodySmall"
          style={{ whiteSpace: "pre-line" }}
          textAlign="center"
        >
          {setupSteps[currentStep]}
        </Typography>
        <img
          src={`/images/smartRedemptionSteps/step_${currentStep + 1}.png`}
          alt={`smart redemption step ${currentStep + 1}`}
          style={{
            width: "80%",
            height: "auto",
            borderRadius: SPACING.space2,
          }}
        />
        <FlexBox
          direction="row"
          align="center"
          gap={SPACING.space2}
          fullWidth
          wrap={false}
        >
          {currentStep !== KidAppSetupStep.OPEN_JOON_KID_APP && (
            <Button
              text="Previous step"
              buttonType="secondary"
              onClick={() =>
                setCurrentStep((currentStep - 1) as KidAppSetupStep)
              }
              fullWidth
            />
          )}

          {currentStep !== KidAppSetupStep.FOLLOW_INSTRUCTIONS && (
            <Button
              text="Next step"
              onClick={onNext}
              isLoading={isLoading}
              fullWidth
            />
          )}
        </FlexBox>
      </FlexBox>

      {currentStep === KidAppSetupStep.FOLLOW_INSTRUCTIONS && (
        <Button
          text="Finish set up"
          onClick={onNext}
          isLoading={isLoading}
          fullWidth
        />
      )}

      {currentStep === KidAppSetupStep.OPEN_JOON_KID_APP ? (
        <button onClick={() => onOpenGetChildStartedModal()}>
          <Typography
            variant="bodySmall"
            color={JoonUIColor.text.primaryAccent}
          >
            I don't have the Joon Pet Game set up yet
          </Typography>
        </button>
      ) : (
        <SmartRedemptionTroubleshooting />
      )}
    </FlexBox>
  )
}

const FinishStep = () => {
  const { onClose } = useSmartRedemptionModalStore()

  const listItems = [
    "Your child will need to purchase screen time minutes from the shop inside their app",
    `Once purchased, you can go ahead and redeem them in the "Rewards" tab of the parent app`,
    "After it's redeemed, your child will need to open their Joon Pet Game to see a pop up asking them if they'd like to start their screen time session",
    `Tapping "yes" will unlock their apps!`,
  ]

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space2}
      justify="space-between"
      fullWidth
      style={{
        background: JoonUIColor.background.primaryNeutral,
        height: "100%",
      }}
    >
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{
          padding: SPACING.space6,
          paddingBottom: 0,
        }}
      >
        <Typography variant="bodyBold">
          Screen time controls have been set up!
        </Typography>
        <Typography variant="bodySmall">
          Now your child can exchange their earned Joon Coins for Screen time
          rewards!
        </Typography>
        <Typography variant="bodySmall" textAlign="left">
          How this works:
          <ul
            style={{
              listStyle: "none",
              margin: 0,
              padding: 0,
            }}
          >
            {listItems.map((item, index) => (
              <li
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <span style={{ width: SPACING.space3 }}>•</span>
                <Typography variant="bodySmall" textAlign="left">
                  {item}
                </Typography>
              </li>
            ))}
          </ul>
        </Typography>
        <Typography variant="bodySmall">
          Note: If you have any issues with this set up, check out our{" "}
          <a
            href="https://www.joonapp.io/user-manual/screen-time-controls"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: JoonUIColor.text.primaryAccent }}
          >
            troubleshooting guide{" "}
          </a>
          or email us at{" "}
          <a
            href="mailto:contact@joonapp.io"
            style={{ color: JoonUIColor.text.primaryAccent }}
          >
            contact@joonapp.io
          </a>
        </Typography>
      </FlexBox>
      <div
        style={{
          borderTop: `1px solid ${JoonUIColor.border.default}`,
          background: JoonUIColor.background.xlightGray,
          width: "100%",
          height: "fit-content",
          padding: SPACING.space6,
          paddingTop: SPACING.space4,
        }}
      >
        <Button text="Go home" onClick={onClose} fullWidth />
      </div>
    </FlexBox>
  )
}

const SmartRedemptionImage = () => {
  return (
    <FlexBox
      align="center"
      justify="center"
      fullWidth
      style={{
        userSelect: "none",
        pointerEvents: "none",
        borderBottom: `1px solid ${JoonUIColor.border.default}`,
      }}
    >
      <img
        src="/images/lock_apps.png"
        alt="smart redemption"
        style={{ width: "100%", height: "auto" }}
      />
    </FlexBox>
  )
}

const SmartRedemptionTroubleshooting = () => {
  return (
    <Typography variant="bodySmall">
      Having trouble? View our{" "}
      <a
        href="https://www.joonapp.io/user-manual/screen-time-controls"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: JoonUIColor.text.primaryAccent }}
      >
        troubleshooting guide!
      </a>
    </Typography>
  )
}
