import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  FlexBox,
  JoonUIColor,
  Loader,
  SPACING,
  Typography,
  UserRole,
} from "@joonapp/web-shared"
import { useState } from "react"

interface Props {
  editChild: () => void
  name: string
  loading?: boolean
  type: string
  index: number
}

function UserComponent({ name, editChild, loading, type }: Props) {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <FlexBox
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={editChild}
      direction="row"
      align="center"
      justify="space-between"
      wrap={false}
      fullWidth
      style={{
        background: isHovered
          ? JoonUIColor.background.xlightGray
          : JoonUIColor.background.primaryNeutral,
        border: `1px solid ${JoonUIColor.border.accent}`,
        padding: SPACING.space4,
        borderRadius: SPACING.space2,
        transition: "background 0.1s ease-in-out",
        cursor: "pointer",
      }}
    >
      <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
        {name}
      </Typography>
      {type === UserRole.CHILD && (
        <button onClick={(e) => e.preventDefault()}>
          {loading ? (
            <Loader
              color={JoonUIColor.background.primaryNeutral}
              size={24}
              thickness={2}
            />
          ) : (
            <FontAwesomeIcon
              icon={faPenToSquare}
              color={JoonUIColor.icon.accent}
              size="xl"
              className="fa-light"
            />
          )}
        </button>
      )}
    </FlexBox>
  )
}

export default UserComponent
