import type {
  DraggableSyntheticListeners,
  UniqueIdentifier,
} from "@dnd-kit/core"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import type { CSSProperties, PropsWithChildren } from "react"
import { createContext, useMemo } from "react"

interface Props {
  id: UniqueIdentifier
  handle?: boolean
}

interface Context {
  attributes: Record<string, any>
  listeners: DraggableSyntheticListeners
  ref(node: HTMLElement | null): void
}

const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  ref() {},
})

export function SortableItem({
  children,
  id,
  handle = false,
}: PropsWithChildren<Props>) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    attributes: handle
      ? {
          role: "button",
          tabIndex: 0,
        }
      : undefined,
  })

  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef]
  )

  const style: CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
    width: "100%",
    cursor: handle ? "default" : "grab",
    touchAction: handle ? "pan-y" : "none",
  }

  return (
    <SortableItemContext.Provider value={context}>
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...(handle ? {} : listeners)}
        onContextMenu={(e) => e.preventDefault()}
      >
        {children}
      </div>
    </SortableItemContext.Provider>
  )
}

export { SortableItemContext }
