import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { JoonUIColor, Typography } from "@joonapp/web-shared"
import { SPACING } from "@joonapp/web-shared"
import { Switch } from "@joonapp/web-shared"
import { FlexBox } from "@joonapp/web-shared"
import { useState } from "react"

import { useDoterAIInfoModalStore } from "./useDoterAIInfoModalStore"
import { useDoterChatModalStore } from "./useDoterChatModalStore"
import NotificationDot2 from "../../../../components/notificationDot/NotificationDot2"
import { PREFERENCE_KEY } from "../../../../constants"
import { useFlaggedDoterChatLogsQuery } from "../../../../networking/chatSessions/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import { useManageGameplay } from "../useManageGameplay"
import { useManageGameplayModalStore } from "../useManageGameplayModalStore"

const DoterConversationsSection = () => {
  const { child, updatePreference } = useManageGameplay()
  const { onOpen: onOpenDoterAIInfoModal } = useDoterAIInfoModalStore()

  const handleToggle = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.TOGGLE_DOTER_CHAT, {
      source: "settings",
      enabled: !child.preferences.enable_doter_chat,
    })
    updatePreference(
      PREFERENCE_KEY.enableDoterChat,
      !child.preferences.enable_doter_chat
    )
  }

  const handleLearnMore = () => {
    onOpenDoterAIInfoModal()
  }

  return (
    <FlexBox direction="column" gap={SPACING.space3}>
      <FlexBox
        justify="space-between"
        align="center"
        wrap={false}
        gap={SPACING.space6}
      >
        <FlexBox direction="column">
          <Typography variant="bodyBold">Enable Doter Conversations</Typography>
          {/* @ts-ignore */}
          {child.preferences.enable_doter_chat ? (
            <Typography variant="bodySmall">
              {child.name} will be able to converse with doters with the help of
              AI. We have designed our Doter AI to behave as a kind and gentle
              companion for your child. You can always monitor the chat and turn
              this off at any time.
            </Typography>
          ) : (
            <Typography variant="bodySmall">
              Turning this on will allow {child.name} to converse with doters
              with the help of AI. We have designed our Doter AI to behave as a
              kind and gentle companion for your child. You can always monitor
              the chat and turn this off at any time.
            </Typography>
          )}
        </FlexBox>
        <Switch
          name="enableDoterChat"
          checked={child.preferences.enable_doter_chat}
          onChange={handleToggle}
        />
      </FlexBox>
      <button onClick={handleLearnMore}>
        <Typography
          variant="bodySmall"
          color={JoonUIColor.semantic.primary}
          style={{ fontWeight: 600 }}
        >
          Learn more
        </Typography>
      </button>
      <ViewChatMessagesButton />
    </FlexBox>
  )
}

export default DoterConversationsSection

const ViewChatMessagesButton = () => {
  const { childId } = useManageGameplayModalStore()
  const [isHovered, setIsHovered] = useState(false)
  const { onOpen: onOpenDoterChatModal } = useDoterChatModalStore()
  const { data: convos } = useFlaggedDoterChatLogsQuery()
  const filteredConvos = convos?.filter((convo) => convo.user_id === childId)

  const handleViewChatMessages = () => {
    onOpenDoterChatModal(childId!)
  }

  const unreadConvos = filteredConvos?.filter((convo) => !convo.date_read)

  return (
    <button
      style={{
        width: "100%",
        border: `1px solid ${JoonUIColor.border.default}`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: SPACING.space2,
        borderRadius: SPACING.space2,
        background: isHovered
          ? JoonUIColor.background.xlightGray
          : JoonUIColor.background.primaryNeutral,
      }}
      onClick={handleViewChatMessages}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <FlexBox direction="row" align="center" gap={SPACING.space1}>
        {unreadConvos && unreadConvos?.length > 0 && <NotificationDot2 />}
        <Typography variant="bodySmall">View chat messages</Typography>
      </FlexBox>
      <FontAwesomeIcon icon={faChevronRight} color={JoonUIColor.icon.neutral} />
    </button>
  )
}
