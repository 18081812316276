import { QuestInstance, QuestSeries } from "@joonapp/web-shared"
import { useMemo } from "react"

import {
  useQuestBoardQuery,
  useReorderQuestsQuery,
} from "../../networking/queries"

// Gets all grouped quest instances for a quest instance
export const useQuestGroupInstances = (questInstance: QuestInstance | null) => {
  const { data: quests } = useQuestBoardQuery()

  const questGroupInstances = useMemo(() => {
    if (!questInstance) return { questGroupInstances: [] }

    const questSeriesData = questInstance.series
    const questGroupInstances = []
    // we are using forEach to handle null case for quest_group_id
    if (questSeriesData.quest_group_id) {
      quests?.forEach((quest: QuestInstance) => {
        if (quest.series.quest_group_id === questSeriesData.quest_group_id) {
          questGroupInstances.push(quest)
        }
      })
    } else {
      questGroupInstances.push(questInstance)
    }

    return { questGroupInstances }
  }, [questInstance, quests])

  return questGroupInstances
}

// Gets all grouped quest series for a quest series
export const useQuestGroupSeries = (questSeries: QuestSeries | null) => {
  const { data: quests } = useReorderQuestsQuery()

  const questGroupSeries = useMemo(() => {
    if (!questSeries || !quests) return { questGroupSeries: [] }
    const questGroupId = questSeries.quest_group_id
    const questGroupSeries =
      questGroupId === null
        ? [questSeries]
        : quests.filter((quest) => quest.quest_group_id === questGroupId)

    return { questGroupSeries }
  }, [questSeries, quests])

  return questGroupSeries
}
