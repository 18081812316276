import { CustomReward, CustomRewardCurrency } from "@joonapp/web-shared"
import { create } from "zustand"

import { CustomRewardTemplate } from "../../../types/rewards"

interface AddEditRewardModalStore {
  isOpen: boolean
  onOpen: (reward?: CustomReward | CustomRewardTemplate) => void
  onClose: () => void

  rewardId: number | null
  templateId: number | null

  title: string
  setTitle: (title: string) => void
  cost: number | null
  setCost: (cost: number | null) => void
  emoji: string | null
  setEmoji: (emoji: string) => void
  selectedChildren: number[]
  toggleSelectedChild: (childId: number) => void
  setSelectedChildren: (children: number[]) => void
  currency: CustomRewardCurrency
  setCurrency: (currency: CustomRewardCurrency) => void
}

const useAddEditRewardModalStore = create<AddEditRewardModalStore>(
  (set, get) => ({
    isOpen: false,
    onOpen: (reward) => {
      if (reward) {
        const isTemplate = !("assignments" in reward)
        set({
          rewardId: isTemplate ? null : reward.id,
          templateId: isTemplate ? reward.id : null,
          title: reward.title,
          cost: reward.cost,
          emoji: reward.emoji,
          selectedChildren: isTemplate
            ? []
            : reward.assignments.map((assignment) => assignment.user_id),
          currency: reward.currency,
        })
      }
      set({ isOpen: true })
    },
    onClose: () =>
      set({
        isOpen: false,
        title: "",
        cost: null,
        emoji: null,
        selectedChildren: [],
        rewardId: null,
        templateId: null,
        currency: CustomRewardCurrency.ITEM,
      }),

    rewardId: null,
    templateId: null,

    title: "",
    cost: null,
    emoji: null,
    selectedChildren: [],
    currency: CustomRewardCurrency.ITEM,

    setTitle: (title: string) => set({ title: title }),
    setCost: (cost: number | null) => set({ cost: cost }),
    setEmoji: (emoji: string) => set({ emoji: emoji }),
    setCurrency: (currency: CustomRewardCurrency) => set({ currency }),
    toggleSelectedChild: (childId: number) => {
      const { selectedChildren } = get()
      if (selectedChildren.includes(childId))
        set({
          selectedChildren: selectedChildren.filter((id) => id !== childId),
        })
      else set({ selectedChildren: [...selectedChildren, childId] })
    },
    setSelectedChildren: (children: number[]) =>
      set({ selectedChildren: children }),
  })
)

export default useAddEditRewardModalStore
