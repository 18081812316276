import { CoachProfile, UserRole } from "@joonapp/web-shared"
import { useMemo } from "react"

import { useCareTeamQuery } from "../../networking/users/queries"
import { TherapistProfile } from "../../types"
import { useRouter } from "../../util/router"

const useCareTeam = () => {
  const { data: careTeam, isLoading } = useCareTeamQuery()
  const router = useRouter()
  const careTeamUserId = Number(router.query.careTeamUserId)

  const {
    sortedCareTeam,
    firstCoach,
    firstTherapist,
    hasCoach,
    hasTherapist,
    selectedCoachProfile,
    selectedTherapistProfile,
  } = useMemo(() => {
    const sortedCareTeam = careTeam
      ? [...careTeam.profiles].sort((profile1, profile2) => {
          if (
            profile1.role === UserRole.COACH &&
            profile2.role === UserRole.COACH
          ) {
            return profile1.user.id < profile2.user.id ? -1 : 1
          } else if (profile1.role === UserRole.COACH) {
            return -1
          } else if (profile2.role === UserRole.COACH) {
            return 1
          } else {
            return profile1.user.id < profile2.user.id ? -1 : 1
          }
        })
      : []

    const firstTherapist = sortedCareTeam?.find(
      (profile) => profile?.role === UserRole.THERAPIST
    )

    const firstCoach = sortedCareTeam?.find(
      (profile) => profile?.role === UserRole.COACH
    )

    const selectedCoachProfile = sortedCareTeam?.find(
      (profile) =>
        profile.role === UserRole.COACH && profile.user.id === careTeamUserId
    ) as CoachProfile | undefined

    const selectedTherapistProfile = sortedCareTeam?.find(
      (profile) =>
        profile.role === UserRole.THERAPIST &&
        profile.user.id === careTeamUserId
    ) as TherapistProfile | undefined

    return {
      sortedCareTeam,
      firstCoach,
      firstTherapist,
      hasCoach: !!firstCoach,
      hasTherapist: !!firstTherapist,
      selectedCoachProfile,
      selectedTherapistProfile,
    }
  }, [careTeam, careTeamUserId])

  return {
    careTeam: sortedCareTeam,
    isLoading,
    firstCoach,
    firstTherapist,
    hasCoach,
    hasTherapist,
    selectedCoachProfile,
    selectedTherapistProfile,
  }
}

export default useCareTeam
