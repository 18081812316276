import {
  ChildUser,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  Switch,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useEffect } from "react"

import DoterChatLogMessage from "./DoterChatLogMessage"
import { useDoterChatModalStore } from "./useDoterChatModalStore"
import useFlaggedConvoModalStore from "./useFlaggedConvoModalStore"
import { PREFERENCE_KEY } from "../../../../constants"
import { useDoterChatLogsQuery } from "../../../../networking/chatSessions/queries"
import { useFamilyQuery } from "../../../../networking/queries"
import { trackAnalyticEvent } from "../../../../util/analytics"
import { ANALYTIC_EVENTS } from "../../../../util/analytics"
import { getUserFromFamily } from "../../../../util/util"
import { useManageGameplay } from "../useManageGameplay"

const FlaggedConvoModal = () => {
  const { isOpen, onClose, date, logIdToScrollTo, setLogIdToScrollTo } =
    useFlaggedConvoModalStore()
  const { childUserId } = useDoterChatModalStore()
  const { data: convo } = useDoterChatLogsQuery(date || undefined)
  const { data: family } = useFamilyQuery()
  const child = getUserFromFamily(family!, childUserId || 0) as
    | ChildUser
    | undefined

  const { updatePreferenceMutation } = useManageGameplay()

  // Scroll to flagged message when it is opened / element has been rendered
  useEffect(() => {
    if (logIdToScrollTo && convo) {
      setTimeout(() => {
        const element = document.getElementById(logIdToScrollTo.toString())
        if (element) element.scrollIntoView()
        setLogIdToScrollTo(null)
      }, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logIdToScrollTo, convo])

  if (!convo || !child) return null

  const handleToggle = () => {
    if (!childUserId) return

    trackAnalyticEvent(ANALYTIC_EVENTS.TOGGLE_DOTER_CHAT, {
      source: "flagged_details",
      enabled: !child?.preferences.enable_doter_chat,
    })
    updatePreferenceMutation.mutate({
      childId: childUserId,
      key: PREFERENCE_KEY.enableDoterChat,
      value: !child?.preferences.enable_doter_chat,
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      mobileFull
      style={{ width: "min(500px, 100vw)" }}
    >
      <ModalHeader
        title={`Flagged conversation: ${dayjs(date).format("MMM D")}`}
        onClose={onClose}
        showBorderBottom
      />
      <FlexBox
        direction="column"
        wrap={false}
        gap={SPACING.space4}
        style={{
          padding: SPACING.space6,
          paddingTop: SPACING.space4,
          overflowY: "auto",
          opacity: logIdToScrollTo ? 0 : 1,
        }}
      >
        {[...convo]
          ?.reverse()
          .map((log, index) => <DoterChatLogMessage key={index} log={log} />)}
      </FlexBox>
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        style={{
          padding: SPACING.space4,
          paddingTop: SPACING.space3,
          borderTop: `1px solid ${JoonUIColor.border.default}`,
        }}
      >
        <Typography variant="bodyBold">A note from Joon:</Typography>
        <Typography variant="bodySmall">
          If necessary, discuss these issues with your child and/or seek help
          from a qualified provider.
        </Typography>
        <Typography variant="bodySmall">
          For urgent help in the US,
          <Typography variant="bodySmallBold">
            call or text 988 for the Suicide & Crisis Lifeline, visit your
            nearest ER, or call 911.{" "}
          </Typography>
          For international resources,{" "}
          <Typography variant="bodySmallBold">
            <a
              href="https://blog.opencounseling.com/suicide-hotlines/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: JoonUIColor.text.primaryAccent,
              }}
            >
              check here
            </a>
          </Typography>
        </Typography>
        <FlexBox
          direction="row"
          gap={SPACING.space2}
          justify="space-between"
          align="center"
          wrap={false}
          style={{
            padding: SPACING.space3,
            background: JoonUIColor.background.lightGray,
            borderRadius: SPACING.space2,
          }}
        >
          <Typography variant="bodySmallBold">
            {child.name}'s doter AI feature
          </Typography>
          <FlexBox
            direction="row"
            gap={SPACING.space2}
            wrap={false}
            align="center"
            style={{ width: "fit-content" }}
          >
            <Typography variant="body">
              {child.preferences.enable_doter_chat ? "ON" : "OFF"}
            </Typography>
            <Switch
              checked={child?.preferences.enable_doter_chat}
              onChange={handleToggle}
              name="enableDoterChat"
            />
          </FlexBox>
        </FlexBox>
        <button
          onClick={() =>
            window.open(
              "https://forms.gle/F8ieX5HPK5hk3jCt7",
              "_blank",
              "noopener,noreferrer"
            )
          }
          style={{ marginTop: SPACING.space2 }}
        >
          <Typography
            variant="bodySmallBold"
            color={JoonUIColor.text.primaryAccent}
          >
            Share feedback about this feature
          </Typography>
        </button>
      </FlexBox>
    </Modal>
  )
}

export default FlaggedConvoModal
