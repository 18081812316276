import { QuestSeries } from "@joonapp/web-shared"
import { create } from "zustand"

import { JooniperPrepaywallStep } from "../../../types"
import { QuestAICreateInfo } from "../../../types/quests"
import { OnboardingCustomRewardTemplateWithUserIds } from "../../../types/rewards"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { localStorageItems } from "../../../util/storage"

interface JooniperPrepaywallModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  step: JooniperPrepaywallStep
  setStep: (step: JooniperPrepaywallStep) => void
  chatSessionId: string | null
  setChatSessionId: (chatSessionId: string | null) => void
  response: QuestAICreateInfo[] | null
  setResponse: (response: QuestAICreateInfo[] | null) => void
  selectedQuestUUIDs: string[]
  toggleQuestUUID: (questUUID: string) => void
  setSelectedQuestUUIDs: (questUUIDs: string[]) => void
  selectedRewardTemplateIds: number[]
  toggleRewardTemplateId: (rewardTemplateId: number) => void
  setSelectedRewardTemplateIds: (rewardTemplateIds: number[]) => void
  assignedRewards: OnboardingCustomRewardTemplateWithUserIds[]
  addAssignedReward: (reward: OnboardingCustomRewardTemplateWithUserIds) => void
  isAssigningQuests: boolean
  setIsAssigningQuests: (isAssigningQuests: boolean) => void
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  assignedQuests: QuestSeries[]
  setAssignedQuests: (assignedQuests: QuestSeries[]) => void
  error: string | null
  setError: (error: string | null) => void
}

export const useJooniperPrepaywallModalStore =
  create<JooniperPrepaywallModalStore>((set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => {
      trackAnalyticEvent(ANALYTIC_EVENTS.PREPAYWALL_MODAL_CLOSE)
      localStorage.removeItem(localStorageItems.chatSessionId)
      localStorage.removeItem(localStorageItems.prepaywallQuests)
      localStorage.setItem(localStorageItems.hasSeenJooniperPrepaywall, "true")
      set({ isOpen: false })
    },
    step: JooniperPrepaywallStep.WELCOME,
    setStep: (step: JooniperPrepaywallStep) => set({ step }),
    chatSessionId: null,
    setChatSessionId: (chatSessionId: string | null) => set({ chatSessionId }),
    response: null,
    setResponse: (response: QuestAICreateInfo[] | null) => set({ response }),
    selectedQuestUUIDs: [],
    toggleQuestUUID: (questUUID: string) =>
      set((state) => ({
        selectedQuestUUIDs: state.selectedQuestUUIDs.includes(questUUID)
          ? state.selectedQuestUUIDs.filter((id) => id !== questUUID)
          : [...state.selectedQuestUUIDs, questUUID],
      })),
    setSelectedQuestUUIDs: (questUUIDs: string[]) =>
      set({ selectedQuestUUIDs: questUUIDs }),
    selectedRewardTemplateIds: [],
    toggleRewardTemplateId: (rewardTemplateId: number) =>
      set((state) => ({
        selectedRewardTemplateIds: state.selectedRewardTemplateIds.includes(
          rewardTemplateId
        )
          ? state.selectedRewardTemplateIds.filter(
              (id) => id !== rewardTemplateId
            )
          : [...state.selectedRewardTemplateIds, rewardTemplateId],
      })),
    setSelectedRewardTemplateIds: (rewardTemplateIds: number[]) =>
      set({ selectedRewardTemplateIds: rewardTemplateIds }),
    assignedRewards: [],
    addAssignedReward: (reward: OnboardingCustomRewardTemplateWithUserIds) =>
      set((state) => ({
        assignedRewards: [...state.assignedRewards, reward],
      })),
    isAssigningQuests: false,
    setIsAssigningQuests: (isAssigningQuests: boolean) =>
      set({ isAssigningQuests }),
    isLoading: false,
    setIsLoading: (isLoading: boolean) => set({ isLoading }),
    assignedQuests: [],
    setAssignedQuests: (assignedQuests: QuestSeries[]) =>
      set({ assignedQuests }),
    error: null,
    setError: (error: string | null) => set({ error }),
  }))
