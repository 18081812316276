import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { create } from "zustand"

import { useDeleteRewardMutation } from "../../networking/rewards/mutations"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"

interface DeleteRewardModalStore {
  isOpen: boolean
  onOpen: (rewardId: number) => void
  onClose: () => void

  rewardId: number | null
}

export const useDeleteRewardModalStore = create<DeleteRewardModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (rewardId) => set({ isOpen: true, rewardId }),
    onClose: () => set({ isOpen: false, rewardId: null }),

    rewardId: null,
  })
)

const DeleteRewardModal = () => {
  const { isOpen, onClose, rewardId } = useDeleteRewardModalStore()
  const router = useRouter()

  const deleteRewardMutation = useDeleteRewardMutation()

  const onDeleteRewardClick = () => {
    if (!rewardId) return
    deleteRewardMutation.mutate(rewardId)
    trackAnalyticEvent(ANALYTIC_EVENTS.DELETE_CUSTOM_REWARD)
    router.push("/rewards")
    onClose()
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} animate>
      <ModalHeader
        title="Confirm deletion"
        onClose={onClose}
        showBorderBottom
      />
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{
          padding: `${SPACING.space4} ${SPACING.space6}`,
          width: "min(450px, 95vw)",
        }}
      >
        <Typography variant="body">
          Once deleted, your child will not be able to purchase this reward
          anymore.
        </Typography>
        <Typography variant="body">
          You can still redeem previously purchased rewards. Once all rewards
          have been redeemed, this reward will no longer be visible to you.
        </Typography>

        <FlexBox
          justify="flex-end"
          wrap={false}
          align="center"
          gap={SPACING.space2}
        >
          <TextButton onClick={onClose}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </TextButton>

          <Button
            text="Confirm deletion"
            buttonType="redPrimary"
            isLoading={deleteRewardMutation.isLoading}
            onClick={onDeleteRewardClick}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default DeleteRewardModal
