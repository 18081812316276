import {
  JoonUIColor,
  QuestDifficulty,
  SPACING,
  FlexBox,
  Typography,
} from "@joonapp/web-shared"

import { capitalizeFirstLetter } from "../../../util/util"

type Props = {
  difficulty: QuestDifficulty
  reward: number | null
}

const DifficultyBadge = ({ difficulty, reward }: Props) => {
  const getBadgeColor = (difficulty: QuestDifficulty) => {
    switch (difficulty) {
      case "easy":
        return JoonUIColor.semantic.success
      case "medium":
        return JoonUIColor.semantic.warning
      case "hard":
        return JoonUIColor.semantic.destructive
    }
  }

  return (
    <FlexBox
      direction="row"
      align="center"
      justify="center"
      wrap={false}
      gap={SPACING.space1}
      style={{
        borderRadius: SPACING.space2,
        gap: SPACING.space1,
        color: JoonUIColor.text.inverted,
        whiteSpace: "nowrap",
        height: "fit-content",
        width: "fit-content",
        padding: `${SPACING.space1} ${SPACING.space3}`,
        background: getBadgeColor(difficulty),
      }}
    >
      <Typography variant="bodySmall" color={JoonUIColor.text.inverted}>
        {capitalizeFirstLetter(difficulty)} {reward}
      </Typography>
      <img
        style={{ height: SPACING.space4, width: "auto" }}
        src="/images/icons/coin-icon.png"
        alt=""
      />
    </FlexBox>
  )
}

export default DifficultyBadge
