import { JoonUIColor, SPACING, TextInput } from "@joonapp/web-shared"
import { TextInputProps } from "@joonapp/web-shared/dist/components/textInput/TextInput"

interface TextInputRectProps extends TextInputProps {}

const TextInputRect = ({ style, ...props }: TextInputRectProps) => {
  return (
    <TextInput
      {...props}
      style={{
        backgroundColor: "white",
        borderRadius: SPACING.space2,
        border: `1px solid ${JoonUIColor.border.default}`,
        height: "100%",
        maxHeight: "200px",
        ...style,
      }}
    />
  )
}

export default TextInputRect
