import { FlexBox, Typography, Modal, SPACING } from "@joonapp/web-shared"
import { create } from "zustand"

import { QuestAICreateInfo, QuestAIEditInfo } from "../../../types/quests"

interface DraftQuestDetailsModalStore {
  questInfo: QuestAICreateInfo | QuestAIEditInfo | null
  isOpen: boolean
  onClose: () => void
  onOpen: (questInfo: QuestAICreateInfo | QuestAIEditInfo) => void
}

export const useDraftQuestDetailsModalStore =
  create<DraftQuestDetailsModalStore>((set) => ({
    questInfo: null,
    isOpen: true,
    onClose: () => set({ isOpen: false }),
    onOpen: (questInfo) => set({ questInfo, isOpen: true }),
  }))

const DraftQuestDetailsModal = () => {
  const { questInfo, isOpen, onClose } = useDraftQuestDetailsModalStore()

  if (!questInfo) return null

  // Split the quest details by newline character
  const questDetailsLines = questInfo.quest_details.split("\n")

  return (
    <Modal isOpen={isOpen} onClose={onClose} animate displayCloseIcon>
      <FlexBox
        style={{ padding: SPACING.space6, maxWidth: "min(95vw, 400px)" }}
        gap={SPACING.space4}
      >
        <Typography variant="bodyBold" style={{ paddingRight: SPACING.space4 }}>
          {questInfo.pending_data.title}
        </Typography>
        <FlexBox direction="column" gap={SPACING.space2}>
          {/* Map over each line and render it in a separate Typography component */}
          {questDetailsLines.map((line, index) => (
            <Typography
              key={index}
              variant="body"
              textAlign="left"
              style={{ textIndent: "-7px", paddingLeft: "7px" }}
            >
              {line}
            </Typography>
          ))}
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default DraftQuestDetailsModal
