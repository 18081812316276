import {
  FamilyChildProfile,
  FamilyParentProfile,
  FlexBox,
  JoonUIColor,
  SPACING,
  UserRole,
} from "@joonapp/web-shared"

import { useCatchUpCompleteStore } from "./useCatchUpCompleteStore"
import { useFamilyQuery, useUserQuery } from "../../networking/queries"
import { QuestBoardSelectOption } from "../../types/quests"
import { getChildImage, getParentAvatarImage } from "../../util/util"
import { QuestBoardUserSelectButton } from "../questBoard/QuestBoardUserSelectButton"

const CatchUpCompleteUserSelectHeader = () => {
  const { data: family } = useFamilyQuery()
  const { setSelectedUserProfile, selectedUserProfile } =
    useCatchUpCompleteStore()
  const { user } = useUserQuery()

  const getLabel = (profile: FamilyChildProfile | FamilyParentProfile) => {
    const isSelf = profile.user.id === user?.id
    if (isSelf) return "Me"
    if (profile.role === UserRole.CHILD) return profile.user.name
    if (profile.role === UserRole.PARENT) return profile.user.name
    return ""
  }

  return (
    <FlexBox
      direction="row"
      align="center"
      justify="flex-start"
      wrap={false}
      gap={SPACING.space1}
      fullWidth
      style={{
        width: "100%",
        maxWidth: "450px",
        background: JoonUIColor.background.primaryNeutral,
        padding: 0,
        overflowX: "auto",
        scrollbarWidth: "none",
      }}
    >
      {family?.profiles.map((profile) => (
        <QuestBoardUserSelectButton
          key={profile.user.id}
          label={getLabel(profile)}
          imageUrl={getUserImage(profile)}
          onClick={() => setSelectedUserProfile(profile)}
          isSelected={selectedUserProfile?.user.id === profile.user.id}
          selectOption={QuestBoardSelectOption.USER}
        />
      ))}
    </FlexBox>
  )
}

export default CatchUpCompleteUserSelectHeader

const getUserImage = (profile: FamilyChildProfile | FamilyParentProfile) => {
  return profile.role === UserRole.CHILD
    ? getChildImage(profile.user) || ""
    : getParentAvatarImage(profile.user.avatar) || ""
}
