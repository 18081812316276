import { faCheck, faPlay } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  CircleIcon,
  CoachProfile,
  CoachTagCategory,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  StarIcon,
  Typography,
} from "@joonapp/web-shared"
import { useEffect, useRef, useState } from "react"
import Skeleton from "react-loading-skeleton"

import { useCoachSummarizerModalStore } from "./useCoachSummarizerModalStore"
import { useRecommendedCoachVideoModalStore } from "./useRecommendedCoachVideoModalStore"
import useMobile from "../../../../hooks/useMobile"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import { useCoachingStore } from "../../useCoachingStore"
import useIsFromMOF from "../../useIsFromMOF"

interface RecommendedCoachCardProps {
  coachCardContent: CoachProfile
  isSelected: boolean
  index: number
}

const RecommendedCoachCard = ({
  coachCardContent,
  isSelected,
  index,
}: RecommendedCoachCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isVideoHovered, setIsVideoHovered] = useState(false)
  const [isInfoHovered, setIsInfoHovered] = useState(false)
  const [overflowActive, setOverflowActive] = useState(false)
  const bioRef = useRef<HTMLDivElement>(null)
  const {
    user: { id, name, last_name },
    bio,
    tags,
    rating,
    num_ratings,
    num_open_slots,
    percent_match,
    match_reason_short,
    match_reason_long,
  } = coachCardContent
  const { setSelectedCoach } = useCoachingStore()
  const { onOpen: onOpenVideo } = useRecommendedCoachVideoModalStore()
  const { onOpen: onOpenSummarizerModal } = useCoachSummarizerModalStore()
  const hasProfileSummarizers = match_reason_short && match_reason_long
  const isFromMOF = useIsFromMOF()

  const isMobile = useMobile()

  const credentials = tags
    .filter((tag) => tag.category === CoachTagCategory.CREDENTIALS)
    .map((tag) => tag.name)
    .slice(0, 2)
  const specialties = tags
    .filter((tag) => tag.category === CoachTagCategory.SPECIALTIES)
    .map((tag) => tag.name)
  const approaches = tags
    .filter((tag) => tag.category === CoachTagCategory.APPROACHES)
    .map((tag) => tag.name)

  const formattedPercentMatch = ((percent_match || 0) * 100).toFixed(0)

  const onVideoClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_VIEW_COACH_VIDEO, {
      coach_id: id,
      is_tof: !isFromMOF,
    })
    onOpenVideo()
  }

  const onSummarizerClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_VIEW_COACH_SUMMARIZER, {
      coach_id: id,
      is_tof: !isFromMOF,
    })
    onOpenSummarizerModal()
  }

  useEffect(() => {
    if (!bioRef.current) return

    const resizeObserver = new ResizeObserver(() => {
      if (bioRef.current) {
        const lineHeight = parseFloat(
          getComputedStyle(bioRef.current).lineHeight
        )
        const maxHeight = lineHeight * 4
        const hasOverflow = bioRef.current.scrollHeight > maxHeight
        setOverflowActive(hasOverflow)
      }
    })
    resizeObserver.observe(bioRef.current)
    return () => resizeObserver.disconnect()
  }, [])

  if (!coachCardContent) return <Skeleton />

  return (
    <button
      onClick={(e) => {
        e.preventDefault()
        setSelectedCoach(coachCardContent)
      }}
      style={{
        width: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: SPACING.space3,
        userSelect: "none",
        outline: isSelected
          ? `2px solid ${JoonUIColor.border.accent}`
          : `2px solid ${JoonUIColor.border.default}`,
        transition: "outline 0.2s ease",
      }}
    >
      <FlexBox
        align="center"
        justify="center"
        style={{
          position: "absolute",
          top: "-10px",
          right: "-10px",
          width: "20px",
          height: "20px",
          borderRadius: "50%",
          opacity: isSelected ? 1 : 0,
          background: JoonUIColor.background.accent,
          transition: "opacity 0.2s ease",
        }}
      >
        <FontAwesomeIcon icon={faCheck} color={JoonUIColor.text.inverted} />
      </FlexBox>

      <FlexBox
        align="center"
        style={{
          borderTopLeftRadius: SPACING.space3,
          borderTopRightRadius: SPACING.space3,
          background: JoonColorExpanded.yellow100,
        }}
        gap={SPACING.space1}
        wrap={false}
        fullWidth
      >
        <div
          style={{
            borderTopLeftRadius: SPACING.space3,
            overflow: "hidden",
            minWidth: "100px",
            maxWidth: "100px",
            minHeight: "116px",
            maxHeight: "116px",
            background: JoonColorExpanded.yellow400,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {coachCardContent.profile_image_url ? (
            <img
              src={coachCardContent.profile_image_url}
              style={{
                width: "100px",
                height: "116px",
                objectFit: "cover",
                objectPosition: "center",
              }}
              alt="Coach Profile"
            />
          ) : (
            <Typography
              variant="h1"
              color={JoonUIColor.text.inverted}
              style={{ fontSize: "2rem" }}
            >
              {coachCardContent.user.name[0].toUpperCase()}
            </Typography>
          )}
        </div>
        <FlexBox
          direction="column"
          gap={SPACING.space2}
          align="flex-start"
          justify="flex-start"
          wrap={false}
          style={{
            height: "116px",
            padding: SPACING.space2,
          }}
        >
          <FlexBox
            direction="row"
            justify="space-between"
            align="center"
            fullWidth
            wrap={false}
          >
            <Typography variant="h3" style={{ textAlign: "left" }}>
              {name} {last_name ? `${last_name[0]}.` : ""}
            </Typography>
            <FlexBox
              direction="row"
              align="center"
              justify="flex-end"
              gap={SPACING.space1}
              wrap={false}
              style={{
                width: "fit-content",
                textAlign: "left",
                whiteSpace: "nowrap",
              }}
            >
              <div style={{ width: 18, height: 21 }}>
                <StarIcon size={18} color={JoonColorExpanded.yellow400} />
              </div>
              <Typography
                variant={isMobile ? "bodyXSmallBold" : "bodySmallBold"}
              >
                {rating.toFixed(1)} ({num_ratings})
              </Typography>
            </FlexBox>
          </FlexBox>

          <FlexBox direction="column" gap={SPACING.space05}>
            {credentials.map((credential) => (
              <FlexBox
                key={credential}
                direction="row"
                align="center"
                gap={SPACING.space1}
                wrap={false}
              >
                <CircleIcon size={4} color={JoonColorExpanded.yellow400} />
                <Typography
                  variant="bodyXSmall"
                  color={JoonColorExpanded.purple600}
                  textAlign="left"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {credential}
                </Typography>
              </FlexBox>
            ))}
          </FlexBox>
          <FlexBox
            direction="row"
            justify="center"
            align="center"
            wrap={false}
            gap={SPACING.space1}
            style={{
              width: "fit-content",
              background: JoonUIColor.background.accent,
              borderRadius: SPACING.space2,
              padding: `${SPACING.space1} ${SPACING.space2}`,
              zIndex: 1,
            }}
          >
            <div style={{ width: 14, height: 16 }}>
              <StarIcon size={14} color={JoonUIColor.icon.inverted} />
            </div>
            <Typography variant="bodyXSmall" color={JoonUIColor.text.inverted}>
              {formattedPercentMatch}% {index === 0 ? "Top Match!" : "Match"}
            </Typography>
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <div
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            onVideoClick()
          }
        }}
        onClick={onVideoClick}
        onMouseEnter={() => setIsVideoHovered(true)}
        onMouseLeave={() => setIsVideoHovered(false)}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          padding: `${SPACING.space2} ${SPACING.space4}`,
          background: isVideoHovered
            ? JoonUIColor.background.xlightGray
            : JoonUIColor.background.primaryNeutral,
          borderTop: `1px solid ${JoonUIColor.border.default}`,
          borderBottom: `1px solid ${JoonUIColor.border.default}`,
        }}
      >
        <Typography variant={isMobile ? "bodyXSmall" : "bodySmall"}>
          Watch {name}'s 30 second intro video
        </Typography>
        <FlexBox
          align="center"
          justify="center"
          style={{
            width: SPACING.space6,
            height: SPACING.space6,
            paddingLeft: "2px",
            background: JoonUIColor.icon.accent,
            borderRadius: "50%",
          }}
        >
          <FontAwesomeIcon
            icon={faPlay}
            color={JoonUIColor.text.inverted}
            size="sm"
          />
        </FlexBox>
      </div>

      <FlexBox
        direction="column"
        gap={SPACING.space2}
        fullWidth
        style={{
          padding: `${SPACING.space2} ${SPACING.space4}`,
          background: JoonUIColor.background.primaryNeutral,
          ...(!hasProfileSummarizers && {
            borderBottomLeftRadius: SPACING.space3,
            borderBottomRightRadius: SPACING.space3,
          }),
        }}
      >
        {hasProfileSummarizers ? (
          <Typography
            variant={isMobile ? "bodyXSmall" : "bodySmall"}
            textAlign="left"
          >
            {coachCardContent.match_reason_short}
          </Typography>
        ) : (
          <FlexBox direction="column" align="flex-end">
            <div
              ref={bioRef}
              style={{
                color: JoonUIColor.text.primary,
                fontSize: isMobile ? "0.75rem" : "0.875rem",
                lineHeight: isMobile ? "1rem" : "1.25rem",
                textAlign: "left",
                maxHeight: isExpanded ? "none" : "calc(1.25rem * 4)",
                display: "-webkit-box",
                WebkitLineClamp: isExpanded ? "unset" : 4,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {bio}
            </div>
            {overflowActive && (
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setIsExpanded(!isExpanded)
                }}
              >
                <Typography
                  variant={isMobile ? "bodyXSmall" : "bodySmall"}
                  color={JoonUIColor.text.secondary}
                >
                  {isExpanded ? "Read less" : "Read more"}
                </Typography>
              </button>
            )}
          </FlexBox>
        )}

        <Typography
          variant={isMobile ? "bodyXSmallBold" : "bodySmallBold"}
          color={JoonUIColor.text.secondary}
        >
          Specialties
        </Typography>
        <FlexBox gap={SPACING.space1} wrap={true} fullWidth>
          {specialties.map((specialty, index) => (
            <FlexBox
              key={index}
              style={{
                width: "fit-content",
                height: "fit-content",
                padding: isMobile
                  ? `${SPACING.space05} ${SPACING.space1}`
                  : `${SPACING.space1} ${SPACING.space2}`,
                background: JoonColorExpanded.yellow100,
                borderRadius: SPACING.space1,
              }}
            >
              <Typography
                variant={isMobile ? "bodyXSmallBold" : "bodySmallBold"}
                color={JoonUIColor.text.primary}
              >
                {specialty}
              </Typography>
            </FlexBox>
          ))}
        </FlexBox>

        <Typography
          variant={isMobile ? "bodyXSmallBold" : "bodySmallBold"}
          color={JoonUIColor.text.secondary}
        >
          Approaches
        </Typography>
        <FlexBox gap={SPACING.space1} wrap={true} fullWidth>
          {approaches.map((approach, index) => (
            <FlexBox
              key={index}
              style={{
                width: "fit-content",
                height: "fit-content",
                padding: isMobile
                  ? `${SPACING.space05} ${SPACING.space1}`
                  : `${SPACING.space1} ${SPACING.space2}`,
                background: JoonColorExpanded.yellow100,
                borderRadius: SPACING.space1,
              }}
            >
              <Typography
                variant={isMobile ? "bodyXSmallBold" : "bodySmallBold"}
                color={JoonUIColor.text.primary}
              >
                {approach}
              </Typography>
            </FlexBox>
          ))}
        </FlexBox>

        <Typography
          variant={isMobile ? "bodyXSmallBold" : "bodySmallBold"}
          color={JoonUIColor.text.secondary}
          style={{ marginTop: SPACING.space1 }}
        >
          {`${num_open_slots} client slot${num_open_slots === 1 ? "" : "s"} remaining`}
        </Typography>
      </FlexBox>

      {hasProfileSummarizers && (
        <div
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              onSummarizerClick()
            }
          }}
          onClick={onSummarizerClick}
          onMouseEnter={() => setIsInfoHovered(true)}
          onMouseLeave={() => setIsInfoHovered(false)}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            padding: `${SPACING.space2} ${SPACING.space4}`,
            background: isInfoHovered
              ? JoonUIColor.background.xlightGray
              : JoonUIColor.background.primaryNeutral,
            borderTop: `1px solid ${JoonUIColor.border.default}`,
            borderBottom: `1px solid ${JoonUIColor.border.default}`,
            borderBottomLeftRadius: SPACING.space3,
            borderBottomRightRadius: SPACING.space3,
          }}
        >
          <Typography
            variant={isMobile ? "bodySmallBold" : "bodyBold"}
            color={JoonUIColor.text.primaryAccent}
          >
            Read more on your match with Coach {name}
          </Typography>
        </div>
      )}
    </button>
  )
}

export default RecommendedCoachCard
