import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

const SecondaryTransparentButton = ({
  text,
  onClick,
  fullWidth = true,
  opaque = false,
  style,
}: {
  text: string | React.ReactNode
  onClick: () => void
  fullWidth?: boolean
  opaque?: boolean
  style?: React.CSSProperties
}) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <button
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onFocus={() => setIsHovered(true)}
      style={{
        width: fullWidth ? "100%" : "fit-content",
        background: isHovered
          ? `rgba(240, 240, 250, ${opaque ? 1 : 0.5})`
          : `rgba(255, 255, 255, ${opaque ? 1 : 0.5})`,
        borderRadius: 8,
        border: `1px solid ${JoonColorExpanded.indigo500}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: SPACING.space3,
        transition: "background 0.2s ease-in-out",
        ...style,
      }}
    >
      <FlexBox justify="center" align="center" fullWidth>
        {typeof text === "string" ? (
          <Typography variant="body" color={JoonUIColor.text.primaryAccent}>
            {text}
          </Typography>
        ) : (
          text
        )}
      </FlexBox>
    </button>
  )
}

export default SecondaryTransparentButton
