import {
  FlexBox,
  JoonUIColor,
  Loader,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"
import { useRef } from "react"

import DoterChatLogMessage from "./DoterChatLogMessage"
import { useDoterChatLogsQuery } from "../../../../networking/chatSessions/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import { useManageGameplay } from "../useManageGameplay"
const DoterChatHistory = () => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const { data, isLoading } = useDoterChatLogsQuery()
  const { child } = useManageGameplay()

  // Scroll to bottom of chat history when load / new messages are added
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }, [data])

  useEffect(() => {
    trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_DOTER_CHAT_HISTORY)
  }, [])

  if (isLoading)
    return (
      <FlexBox
        justify="center"
        align="center"
        style={{ padding: SPACING.space6 }}
      >
        <Loader color={JoonUIColor.icon.neutral} thickness={2} />
      </FlexBox>
    )

  if (!data) return null

  if (data.length === 0) {
    return (
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        justify="center"
        align="center"
        style={{ padding: SPACING.space6 }}
      >
        <Typography variant="bodySmall">No messages yet!</Typography>
        <Typography variant="bodySmall">
          {child?.name} can talk to their doter by tapping on it.
        </Typography>
      </FlexBox>
    )
  }

  return (
    <FlexBox
      direction="column"
      wrap={false}
      gap={SPACING.space6}
      style={{ padding: SPACING.space4, overflow: "auto" }}
      ref={scrollRef}
    >
      {[...data].reverse().map((log, index) => (
        <DoterChatLogMessage key={index} log={log} />
      ))}
    </FlexBox>
  )
}

export default DoterChatHistory
