import { JoonUIColor, Typography } from "@joonapp/web-shared"

import { CoachingLookupKey } from "../../../../types/coachingGroups"

export const PlanTitle = ({
  plan,
  coachingPlan,
}: {
  plan: string
  coachingPlan: CoachingLookupKey
}) => {
  // "Joon Plus Coach"
  if (coachingPlan === CoachingLookupKey.STANDARD) {
    return (
      <>
        <Typography variant="h3" color={JoonUIColor.text.primary}>
          Joon
        </Typography>
        <Typography
          variant="h3"
          color={JoonUIColor.text.primaryAccent}
          style={{ fontFamily: "Caveat" }}
        >
          {plan}
        </Typography>
        <Typography variant="h3" color={JoonUIColor.text.primary}>
          Coach
        </Typography>
      </>
    )
  }

  // "Joon Coach Starter", "Joon Coach Basic", "Joon Coach Pro"
  return (
    <>
      <Typography variant="h3" color={JoonUIColor.text.primary}>
        Joon
      </Typography>
      <Typography variant="h3" color={JoonUIColor.text.primary}>
        Coach
      </Typography>
      <Typography
        variant="h3"
        color={JoonUIColor.text.primaryAccent}
        style={{ fontFamily: "Caveat" }}
      >
        {plan}
      </Typography>
    </>
  )
}
