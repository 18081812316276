import { QuestRoutine } from "@joonapp/web-shared"
import { MutableRefObject } from "react"
import { create } from "zustand"

import { QuestBoardSelectOption } from "../../types/quests"

interface QuestBoardStore {
  questBoardRef: MutableRefObject<any> | null
  setQuestBoardRef: (questBoardRef: MutableRefObject<any> | null) => void

  selectedUserId: number | null
  setSelectedUserId: (selectedUserId: number | null) => void

  selectOption: QuestBoardSelectOption
  setSelectOption: (selectOption: QuestBoardSelectOption) => void

  selectedRoutine: QuestRoutine | null
  setSelectedRoutine: (selectedRoutine: QuestRoutine | null) => void

  showWelcome: boolean
  setShowWelcome: (showWelcome: boolean) => void
}

const useQuestBoardStore = create<QuestBoardStore>((set) => ({
  setQuestBoardRef: (questBoardRef: MutableRefObject<any> | null) =>
    set({ questBoardRef }),
  questBoardRef: null,

  selectedUserId: null,
  setSelectedUserId: (selectedUserId: number | null) => {
    if (selectedUserId) set({ selectOption: QuestBoardSelectOption.USER })
    set({ selectedUserId })
  },

  selectOption: QuestBoardSelectOption.ALL_USERS,
  setSelectOption: (selectOption: QuestBoardSelectOption) => {
    if (selectOption !== QuestBoardSelectOption.USER)
      set({ selectedUserId: null })
    set({ selectOption })
  },

  selectedRoutine: null,
  setSelectedRoutine: (selectedRoutine: QuestRoutine | null) =>
    set({ selectedRoutine }),

  showWelcome: false,
  setShowWelcome: (showWelcome: boolean) => set({ showWelcome }),
}))

export default useQuestBoardStore
