import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import useMobile from "../../hooks/useMobile"

export const ExpandCard = ({
  icon,
  text,
  reset,
  onReset,
  additionalContent,
  onClick,
  disabled,
  selected = false,
}: {
  icon?: IconProp
  text?: string | React.ReactNode
  reset?: boolean
  onReset?: () => void
  additionalContent?: React.ReactNode
  onClick?: () => void
  disabled?: boolean
  selected?: boolean
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [isResetHovered, setIsResetHovered] = useState(false)

  const isMobile = useMobile()

  const handleClick = () => {
    if (!isOpen) onClick?.()
    if (isMobile) return
    setIsOpen(!isOpen)
  }

  return (
    <button
      onMouseEnter={() => {
        if (isMobile) return
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        if (isMobile) return
        setIsHovered(false)
      }}
      onClick={handleClick}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: SPACING.space2,
        padding: `${SPACING.space3} ${SPACING.space4}`,
        borderBottom: `1px solid ${JoonUIColor.border.default}`,
        background:
          disabled || isMobile || (!isHovered && !isOpen)
            ? JoonUIColor.background.primaryNeutral
            : JoonUIColor.background.xlightGray,
        pointerEvents: disabled ? "none" : "auto",
      }}
    >
      <FlexBox
        direction="row"
        align="center"
        justify="space-between"
        wrap={false}
      >
        <FlexBox
          direction="row"
          align="center"
          gap={SPACING.space2}
          wrap={false}
        >
          {!isMobile && !disabled && (
            <FontAwesomeIcon
              icon={faCaretRight}
              size="lg"
              style={{
                color: JoonUIColor.icon.neutral,
                transform: isOpen
                  ? "rotate(90deg) translateX(-2px)"
                  : "rotate(0deg) translateX(0)",
                transition: "transform 0.1s ease",
              }}
            />
          )}
          <FlexBox
            direction="row"
            align="center"
            gap={SPACING.space2}
            wrap={false}
          >
            {icon && (
              <FontAwesomeIcon
                icon={icon}
                size="sm"
                color={
                  reset || selected
                    ? JoonUIColor.icon.accent
                    : JoonUIColor.icon.neutral
                }
              />
            )}
            {text}
          </FlexBox>
        </FlexBox>
        <button
          onMouseEnter={() => {
            setIsResetHovered(true)
            setIsHovered(false)
          }}
          onMouseLeave={() => {
            setIsResetHovered(false)
            setIsHovered(true)
          }}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            setIsOpen(false)
            onReset?.()
          }}
          style={{
            opacity: reset ? 1 : 0,
            pointerEvents: reset ? "auto" : "none",
            padding: `${SPACING.space05} ${SPACING.space2}`,
            borderRadius: SPACING.space2,
            background: isResetHovered
              ? JoonColorExpanded.neutral100
              : "transparent",
          }}
        >
          <Typography
            variant="bodySmallBold"
            color={JoonUIColor.text.primaryAccent}
          >
            reset
          </Typography>
        </button>
      </FlexBox>
      {!isMobile && isOpen && additionalContent}
    </button>
  )
}
