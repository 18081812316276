import { FlexBox, SPACING, Typography } from "@joonapp/web-shared"
import { useEffect, useRef, useState } from "react"

import useJooniperPrepaywallModal from "./useJooniperPrepaywallModal"
import { useJooniperPrepaywallModalStore } from "./useJooniperPrepaywallModalStore"
import AnimatedStars from "../../../components/animatedStars/AnimatedStars"
import TransitionWrapper from "../../../components/coaching/TransitionWrapper"
import { JooniperPrepaywallStep } from "../../../types"
import {
  cohorts,
  experimentNames,
  useGetExperimentCohort,
} from "../../../util/experiments"

const JooniperWelcome = () => {
  const [visibleSections, setVisibleSections] = useState<number[]>([])
  const [isStarAnimating, setIsStarAnimating] = useState(false)
  const [isTimeoutComplete, setIsTimeoutComplete] = useState(false)
  const hasInitialized = useRef(false)
  const { setStep, isLoading, response } = useJooniperPrepaywallModalStore()
  const { initializeAndGenerate } = useJooniperPrepaywallModal()
  const jooniperPrepaywallVariant = useGetExperimentCohort(
    experimentNames.jooniperPrepaywall
  )

  useEffect(() => {
    const sectionDelays = [0, 1500, 3000, 5000]
    const timeouts: NodeJS.Timeout[] = []
    timeouts.push(setTimeout(() => setIsStarAnimating(true), 3000))
    sectionDelays.forEach((delay, index) => {
      timeouts.push(
        setTimeout(() => setVisibleSections((prev) => [...prev, index]), delay)
      )
    })
    const timeout = setTimeout(() => {
      setIsTimeoutComplete(true)
    }, 5000)
    timeouts.push(timeout)
    // Cleanup all timeouts on component unmount
    return () => timeouts.forEach(clearTimeout)
  }, [])

  useEffect(() => {
    // After the timeout, react to changes in response
    if (isTimeoutComplete && !isLoading && !!response) {
      setStep(JooniperPrepaywallStep.QUEST_RECOMMENDATION)
      setIsStarAnimating(false)
    }
  }, [isTimeoutComplete, isLoading, response, setStep, setIsStarAnimating])

  useEffect(() => {
    if (
      isLoading ||
      response ||
      hasInitialized.current ||
      jooniperPrepaywallVariant !== cohorts.variantA
    )
      return

    initializeAndGenerate()
    hasInitialized.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FlexBox
      direction="column"
      align="center"
      justify="center"
      gap={SPACING.space6}
      style={{ paddingTop: SPACING.space14 }}
    >
      <TransitionWrapper showChildren={visibleSections.includes(0)}>
        <AnimatedStars animate={isStarAnimating} scale={2} />
      </TransitionWrapper>
      <TransitionWrapper showChildren={visibleSections.includes(1)}>
        <Typography variant="aiBody" style={{ marginTop: SPACING.space4 }}>
          Welcome to Joon!
        </Typography>
      </TransitionWrapper>
      <TransitionWrapper
        showChildren={visibleSections.includes(2)}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="aiBody"
          textAlign="center"
          style={{
            width: "80%",
            padding: `0 ${SPACING.space2}`,
          }}
        >
          I'm preparing an initial routine recommendation for your family based
          on what you've already shared!
        </Typography>
      </TransitionWrapper>
    </FlexBox>
  )
}

export default JooniperWelcome
