import { CoachProfile } from "@joonapp/web-shared"
import { create } from "zustand"

import { QuestionAnswerMappingInterface } from "../../types"
import { CoachingLookupKey, CoachingStep } from "../../types/coachingGroups"

interface CoachingStore {
  step: CoachingStep
  setStep: (value: CoachingStep) => void
  questionnaireStep: number
  setQuestionnaireStep: (value: number) => void
  questionAnswerMapping: QuestionAnswerMappingInterface
  setQuestionAnswerMapping: (value: QuestionAnswerMappingInterface) => void
  postSelectionQuestionAnswerMapping: QuestionAnswerMappingInterface
  setPostSelectionQuestionAnswerMapping: (
    value: QuestionAnswerMappingInterface
  ) => void
  phoneNumber: string
  setPhoneNumber: (value: string) => void
  phoneNumberInput: string
  setPhoneNumberInput: (value: string) => void
  postAnswerScreenInfo: {
    background_color_hex: string
    background_image_url: string
    button_title: string
  }
  setPostAnswerScreenInfo: (value: {
    background_color_hex: string
    background_image_url: string
    button_title: string
  }) => void
  selectedCoach: CoachProfile | null
  setSelectedCoach: (value: CoachProfile) => void
  selectedCoachIndex: number
  setSelectedCoachIndex: (value: number) => void
  timeLeft: number
  setTimeLeft: (value: number) => void
  coachingPlan: CoachingLookupKey
  setCoachingPlan: (value: CoachingLookupKey) => void
  isBundleJoonity: boolean
  setIsBundleJoonity: (value: boolean) => void

  sourcePage: "coach-onboard" | null
  setSourcePage: (value: "coach-onboard" | null) => void
}

export const useCoachingStore = create<CoachingStore>((set) => ({
  step: CoachingStep.LANDING,
  setStep: (step: CoachingStep) => set({ step }),
  questionnaireStep: 0,
  setQuestionnaireStep: (questionnaireStep: number) =>
    set({ questionnaireStep }),
  questionAnswerMapping: {},
  setQuestionAnswerMapping: (
    questionAnswerMapping: QuestionAnswerMappingInterface
  ) => set({ questionAnswerMapping }),
  postSelectionQuestionAnswerMapping: {},
  setPostSelectionQuestionAnswerMapping: (
    postSelectionQuestionAnswerMapping: QuestionAnswerMappingInterface
  ) => set({ postSelectionQuestionAnswerMapping }),
  phoneNumber: "",
  setPhoneNumber: (phoneNumber: string) => set({ phoneNumber }),
  phoneNumberInput: "",
  setPhoneNumberInput: (phoneNumberInput: string) => set({ phoneNumberInput }),
  postAnswerScreenInfo: {
    background_color_hex: "",
    background_image_url: "",
    button_title: "",
  },
  setPostAnswerScreenInfo: (postAnswerScreenInfo: {
    background_color_hex: string
    background_image_url: string
    button_title: string
  }) => set({ postAnswerScreenInfo }),
  selectedCoach: null,
  setSelectedCoach: (selectedCoach: CoachProfile) => set({ selectedCoach }),
  selectedCoachIndex: 0,
  setSelectedCoachIndex: (selectedCoachIndex: number) =>
    set({ selectedCoachIndex }),
  timeLeft: 10 * 60,
  setTimeLeft: (timeLeft: number) => set({ timeLeft }),
  coachingPlan: CoachingLookupKey.STANDARD,
  setCoachingPlan: (coachingPlan: CoachingLookupKey) => set({ coachingPlan }),
  isBundleJoonity: true,
  setIsBundleJoonity: (isBundleJoonity: boolean) => set({ isBundleJoonity }),
  sourcePage: null,
  setSourcePage: (sourcePage: "coach-onboard" | null) => set({ sourcePage }),
}))
