import {
  CheckIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useEffect, useMemo, useState } from "react"
import { LoaderIcon } from "react-hot-toast"

import {
  useRecommendedCoachesQuery,
  useRecommendedCoachesSummaryQuery,
} from "../../../networking/coaching/queries"
import { CoachingStep } from "../../../types/coachingGroups"
import { useCoachingStore } from "../useCoachingStore"

function Matching() {
  const [progress, setProgress] = useState(0)
  const { setStep } = useCoachingStore()

  const { isLoading: isCoachesLoading } = useRecommendedCoachesQuery()
  const { data: coachSummary, isLoading: isCoachSummaryLoading } =
    useRecommendedCoachesSummaryQuery()

  const timerLength = 18000 // seems close to actual data loading time
  const maxPercent = 100
  const numIntervals = 60
  const isDataLoaded = !isCoachesLoading && !isCoachSummaryLoading

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((currentProgress) => {
        if (currentProgress === maxPercent) return currentProgress
        const step = maxPercent / numIntervals
        return Math.min(currentProgress + step, maxPercent)
      })
    }, timerLength / numIntervals)

    const finishInterval = setInterval(() => {
      // will wait at least timerLength before proceeding
      if (progress === maxPercent && isDataLoaded) {
        setStep(
          coachSummary
            ? CoachingStep.COACH_SUMMARY
            : CoachingStep.RECOMMENDED_COACHES
        )
      }
    }, 100)

    return () => {
      clearInterval(interval)
      clearInterval(finishInterval)
    }
  }, [setStep, coachSummary, isDataLoaded, progress])

  const LoadingCard = useMemo(() => {
    return ({ text, isLoading }: { text: string; isLoading: boolean }) => (
      <FlexBox
        direction="row"
        align="center"
        justify="space-between"
        gap={SPACING.space4}
        style={{
          width: "fit-content",
          backgroundColor: JoonColorExpanded.purple600,
          padding: `${SPACING.space2} ${SPACING.space3}`,
          borderRadius: 100,
        }}
      >
        <Typography variant="body" color={JoonUIColor.text.inverted}>
          {text}
        </Typography>
        {isLoading ? (
          <LoaderIcon />
        ) : (
          <CheckIcon color={JoonUIColor.icon.inverted} />
        )}
      </FlexBox>
    )
  }, [])

  return (
    <FlexBox
      direction="column"
      align="center"
      justify="center"
      fullWidth
      gap={SPACING.space8}
    >
      <Typography
        variant="h2"
        color={JoonUIColor.text.primary}
        style={{ width: "80%" }}
      >
        Matching you with the best coach for you...
      </Typography>
      <FlexBox
        direction="column"
        align="center"
        justify="center"
        gap={SPACING.space4}
      >
        {[
          { text: "Analyzing Intake Questions", threshold: 25 },
          { text: "Finding available coaches", threshold: 60 },
          { text: "Preparing your options", threshold: 95 },
        ].map(({ text, threshold }, index, arr) => {
          const isLast = index === arr.length - 1
          const isLoading = isLast
            ? progress !== maxPercent || !isDataLoaded
            : progress < threshold

          return (
            <div
              key={text}
              style={{
                opacity: progress < (index === 0 ? 1 : threshold - 35) ? 0 : 1,
                transition: "opacity 1s",
              }}
            >
              <LoadingCard text={text} isLoading={isLoading} />
            </div>
          )
        })}
      </FlexBox>
    </FlexBox>
  )
}

export default Matching
