import {
  Button,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
  Loader,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { useRef } from "react"

import useChurnkeyStyles from "./useChurnkeyStyles"
import BackButton from "../../components/buttons/BackButton"
import PageContentWrapper from "../../components/pageContentWrapper/PageContentWrapper"
import PageHeader from "../../components/pageHeader/PageHeader"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { GRADIENTS } from "../../constants"
import useMobile from "../../hooks/useMobile"
import { getCoachingChurnkeyCancelFlow } from "../../networking/coaching/api"
import { useGetCoachSubscriptionQuery } from "../../networking/coaching/queries"
import { getJoonityChurnkeyCancelFlow } from "../../networking/families/api"
import {
  useFamilyQuery,
  useSubscriberInfo,
  useUserQuery,
} from "../../networking/queries"
import { getCoachingStripeBillingUrl } from "../../networking/subscriptions"
import { SubscriptionStatus } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { requireAuth } from "../../util/auth"
import { useRouter } from "../../util/router"
import { getCurrentSubscription, getSubscriptionType } from "../../util/util"
import PlanUpdateModal from "../coach/planUpdate/PlanUpdateModal"
import usePlanUpdateModalStore from "../coach/planUpdate/usePlanUpdateModalStore"
import { useCheckoutRedirect } from "../paywall/useCheckoutRedirect"

dayjs.extend(utc)

const SettingsSubscription = () => {
  const router = useRouter()
  const isMobile = useMobile()
  const { user } = useUserQuery()

  useChurnkeyStyles()

  if (!user) return <></>

  return (
    <PageWrapper style={{ background: GRADIENTS.gradient100 }}>
      <PageHeader
        title="Subscription"
        leftComponent={
          isMobile && <BackButton onBack={() => router.push("/settings")} />
        }
      />
      <PageContentWrapper
        mobileGutter={true}
        style={{ gap: SPACING.space6, marginTop: SPACING.space6 }}
      >
        <FamilySubscriptionSection />
        <CoachSubscriptionSection />
      </PageContentWrapper>
    </PageWrapper>
  )
}

const SettingsSubscriptionWithAuth = requireAuth(SettingsSubscription)
export default SettingsSubscriptionWithAuth

const FamilySubscriptionSection = () => {
  const hasMadeChangesRef = useRef(false)
  const {
    isLoadingCheckoutFn,
    redirectToFamilyBilling,
    redirectToFamilyCheckout,
  } = useCheckoutRedirect()

  const { data: subscriberData, isLoading } = useSubscriberInfo()
  const { data: family } = useFamilyQuery()

  const currentSubscription = getCurrentSubscription(subscriberData)
  const hasUnsubscribed = currentSubscription?.unsubscribe_detected_at !== null
  const expiresDate =
    subscriberData?.subscriber?.entitlements?.premium?.expires_date
  const hasExpiredPlan = dayjs(expiresDate).unix() < dayjs().unix()
  const planType = getSubscriptionType(currentSubscription)
  const renewal = currentSubscription?.expires_date

  const handleCancelSubscription = async () => {
    if (!family) return
    const { churnkey_auth_hash, customer_id, subscription_id } =
      await getJoonityChurnkeyCancelFlow(family?.id)
    trackAnalyticEvent(ANALYTIC_EVENTS.CHURNKEY_OPEN, {
      planType: "joonity",
    })

    // @ts-ignore
    window.churnkey.init("show", {
      customerId: customer_id,
      subscriptionId: subscription_id,
      authHash: churnkey_auth_hash,
      appId: "7h9b3hvtt",
      mode: "live",
      provider: "stripe",
      record: true,
      onClose: () => {
        trackAnalyticEvent(ANALYTIC_EVENTS.CHURNKEY_CLOSE, {
          planType: "joonity",
        })
        if (hasMadeChangesRef.current) {
          window.location.reload()
        }
      },
      onCancel: () => {
        trackAnalyticEvent(ANALYTIC_EVENTS.CHURNKEY_CANCEL, {
          planType: "joonity",
        })
        hasMadeChangesRef.current = true
      },
      onPause: () => {
        trackAnalyticEvent(ANALYTIC_EVENTS.CHURNKEY_PAUSE, {
          planType: "joonity",
        })
        hasMadeChangesRef.current = true
      },
      onDiscount: () => {
        trackAnalyticEvent(ANALYTIC_EVENTS.CHURNKEY_DISCOUNT_CHANGE, {
          planType: "joonity",
        })
        hasMadeChangesRef.current = true
      },
      onPlanChange: () => {
        trackAnalyticEvent(ANALYTIC_EVENTS.CHURNKEY_PLAN_CHANGE, {
          planType: "joonity",
        })
        hasMadeChangesRef.current = true
      },
      onResume: () => {
        trackAnalyticEvent(ANALYTIC_EVENTS.CHURNKEY_RESUME, {
          planType: "joonity",
        })
        hasMadeChangesRef.current = true
      },
      onStepChange: (newStep: string, oldStep: string) => {
        trackAnalyticEvent(ANALYTIC_EVENTS.CHURNKEY_STEP_CHANGE, {
          planType: "joonity",
          newStep,
          oldStep,
        })
      },
    })
  }

  return (
    <div
      style={{
        border: `1px solid ${JoonUIColor.border.default}`,
        borderRadius: SPACING.space2,
        overflow: "hidden",
        background: JoonUIColor.background.primaryNeutral,
      }}
    >
      <FlexBox
        direction="row"
        justify="space-between"
        style={{
          background: JoonUIColor.background.xlightGray,
          padding: `${SPACING.space3} ${SPACING.space6}`,
          borderBottom: `1px solid ${JoonUIColor.border.default}`,
        }}
      >
        <Typography variant="h3">Joon App</Typography>
      </FlexBox>
      {isLoading ? (
        <FlexBox
          direction="column"
          align="center"
          style={{ padding: SPACING.space4 }}
        >
          <Loader size={20} thickness={3} color={JoonUIColor.icon.accent} />
        </FlexBox>
      ) : !subscriberData?.active_plan?.is_active ? (
        <FlexBox
          direction="column"
          gap={SPACING.space4}
          align="center"
          style={{ padding: `${SPACING.space3} ${SPACING.space6}` }}
        >
          <Typography variant="bodyBold" textAlign="center">
            Subscribe to Joon
          </Typography>
          <Typography
            variant="bodySmall"
            textAlign="center"
            style={{ maxWidth: "440px" }}
          >
            Unlock the full Joon App experience that lets your family complete
            more than 7 quests per day, unlocking more in the world of Joon!
          </Typography>
          <img src="/images/previews/child_app_preview.svg" alt="" />
          <Button
            text="Purchase Subscription"
            isLoading={isLoadingCheckoutFn}
            onClick={() => redirectToFamilyCheckout()}
            fullWidth
          />
        </FlexBox>
      ) : (
        <>
          <FlexBox
            direction="row"
            justify="space-between"
            wrap={false}
            style={{
              padding: `${SPACING.space3} ${SPACING.space6}`,
              background: JoonUIColor.background.primaryNeutral,
              borderBottom: `1px solid ${JoonUIColor.border.default}`,
            }}
          >
            <FlexBox direction="column">
              <Typography
                variant="bodySmallBold"
                color={JoonUIColor.text.secondary}
              >
                Type
              </Typography>
              <Typography variant="bodySmallBold">
                {subscriberData?.active_plan?.is_active
                  ? planType[0]?.toUpperCase() + planType.slice(1)
                  : "None"}
              </Typography>
            </FlexBox>
            <FlexBox direction="column">
              <Typography
                variant="bodySmallBold"
                color={JoonUIColor.text.secondary}
              >
                {hasExpiredPlan
                  ? "Expired On"
                  : hasUnsubscribed
                    ? "Expiring On"
                    : "Renewing on"}
              </Typography>
              <Typography variant="bodySmallBold">
                {renewal ? dayjs.utc(renewal)?.format("MMMM D, YYYY") : "-"}
              </Typography>
            </FlexBox>
          </FlexBox>
          <FlexBox
            direction="row"
            justify="space-around"
            style={{ padding: `${SPACING.space3} ${SPACING.space6}` }}
          >
            {currentSubscription?.store === "stripe" ? (
              <FlexBox
                direction="row"
                gap={SPACING.space2}
                justify="space-around"
              >
                {isLoadingCheckoutFn ? (
                  <Loader
                    size={20}
                    thickness={2}
                    color={JoonUIColor.icon.accent}
                  />
                ) : (
                  <>
                    {!hasUnsubscribed && (
                      <TextButton onClick={() => handleCancelSubscription()}>
                        <Typography
                          variant="bodySmallBold"
                          color={JoonUIColor.text.primaryAccent}
                        >
                          Cancel
                        </Typography>
                      </TextButton>
                    )}
                    <TextButton onClick={() => redirectToFamilyBilling()}>
                      <Typography
                        variant="bodySmallBold"
                        color={JoonUIColor.text.primaryAccent}
                      >
                        Manage
                      </Typography>
                    </TextButton>
                  </>
                )}
              </FlexBox>
            ) : (
              <Typography
                variant="bodySmallBold"
                color={JoonUIColor.text.secondary}
              >
                Open in your Joon Parent App to manage subscription
              </Typography>
            )}
          </FlexBox>
        </>
      )}
    </div>
  )
}

const CoachSubscriptionSection = () => {
  const hasMadeChangesRef = useRef(false)
  const { data: coachSubscriptionData, isLoading } =
    useGetCoachSubscriptionQuery()
  const hasUnsubscribed = !!coachSubscriptionData?.set_to_cancel
  const renewal = coachSubscriptionData?.active_period_end_date
  const hasActivePlan = [
    coachSubscriptionData?.status === SubscriptionStatus.PAYING,
    coachSubscriptionData?.status === SubscriptionStatus.TRIAL,
  ].includes(true)
  const { onOpen: openUpgradePlanModal } = usePlanUpdateModalStore()

  const router = useRouter()

  const handleCancelSubscription = async () => {
    const { churnkey_auth_hash, customer_id, subscription_id } =
      await getCoachingChurnkeyCancelFlow()

    trackAnalyticEvent(ANALYTIC_EVENTS.CHURNKEY_OPEN, {
      planType: "coaching",
    })

    // @ts-ignore
    window.churnkey.init("show", {
      customerId: customer_id,
      subscriptionId: subscription_id,
      authHash: churnkey_auth_hash,
      appId: "7h9b3hvtt",
      mode: "live",
      provider: "stripe",
      record: true,
      onClose: () => {
        trackAnalyticEvent(ANALYTIC_EVENTS.CHURNKEY_CLOSE, {
          planType: "coaching",
        })
        if (hasMadeChangesRef.current) {
          window.location.reload()
        }
      },
      onCancel: () => {
        trackAnalyticEvent(ANALYTIC_EVENTS.CHURNKEY_CANCEL, {
          planType: "coaching",
        })
        hasMadeChangesRef.current = true
      },
      onPause: () => {
        trackAnalyticEvent(ANALYTIC_EVENTS.CHURNKEY_PAUSE, {
          planType: "coaching",
        })
        hasMadeChangesRef.current = true
      },
      onDiscount: () => {
        trackAnalyticEvent(ANALYTIC_EVENTS.CHURNKEY_DISCOUNT_CHANGE, {
          planType: "coaching",
        })
        hasMadeChangesRef.current = true
      },
      onPlanChange: () => {
        trackAnalyticEvent(ANALYTIC_EVENTS.CHURNKEY_PLAN_CHANGE, {
          planType: "coaching",
        })
        hasMadeChangesRef.current = true
      },
      onResume: () => {
        trackAnalyticEvent(ANALYTIC_EVENTS.CHURNKEY_RESUME, {
          planType: "coaching",
        })
        hasMadeChangesRef.current = true
      },
      onStepChange: (newStep: string, oldStep: string) => {
        trackAnalyticEvent(ANALYTIC_EVENTS.CHURNKEY_STEP_CHANGE, {
          planType: "coaching",
          newStep,
          oldStep,
        })
      },
    })
  }

  const handleGoToBilling = async () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.SHOW_BILLING)
    const billingUrl = await getCoachingStripeBillingUrl()
    window.open(billingUrl, "_self")
  }

  return (
    <div
      style={{
        border: `1px solid ${JoonUIColor.border.default}`,
        borderRadius: SPACING.space2,
        overflow: "hidden",
        background: JoonUIColor.background.primaryNeutral,
      }}
    >
      <FlexBox
        direction="row"
        justify="space-between"
        align="center"
        style={{
          background: JoonUIColor.background.xlightGray,
          padding: `${SPACING.space3} ${SPACING.space6}`,
          borderBottom: `1px solid ${JoonUIColor.border.default}`,
        }}
      >
        <Typography variant="h3">
          Joon{" "}
          <Typography
            variant="h3"
            color={JoonUIColor.text.primaryAccent}
            style={{ fontFamily: "Caveat", fontSize: "24px" }}
          >
            Plus{" "}
          </Typography>
          Coach
        </Typography>

        {hasActivePlan && coachSubscriptionData?.price_per_seat && (
          <Typography variant="bodySmallBold">
            ${(coachSubscriptionData.price_per_seat / 100).toFixed(2)} monthly
          </Typography>
        )}
      </FlexBox>
      {isLoading ? (
        <FlexBox
          direction="column"
          align="center"
          style={{ padding: SPACING.space4 }}
        >
          <Loader size={20} thickness={3} color={JoonUIColor.icon.accent} />
        </FlexBox>
      ) : hasActivePlan ? (
        <>
          <FlexBox
            direction="row"
            wrap={false}
            style={{
              padding: `${SPACING.space3} ${SPACING.space6}`,
              background: JoonUIColor.background.primaryNeutral,
              borderBottom: `1px solid ${JoonUIColor.border.default}`,
            }}
          >
            <FlexBox direction="column">
              <Typography
                variant="bodySmallBold"
                color={JoonUIColor.text.secondary}
              >
                {hasUnsubscribed ? "Expiring On" : "Renewing on"}
              </Typography>
              <Typography variant="bodySmallBold">
                {renewal ? dayjs.utc(renewal)?.format("MMMM D, YYYY") : "-"}
              </Typography>
            </FlexBox>
          </FlexBox>
          <FlexBox
            direction="row"
            justify="space-around"
            wrap={false}
            style={{ padding: `${SPACING.space3} ${SPACING.space6}` }}
          >
            {!hasUnsubscribed && (
              <TextButton onClick={() => handleCancelSubscription()}>
                <Typography
                  variant="bodySmallBold"
                  color={JoonUIColor.text.primaryAccent}
                >
                  Cancel
                </Typography>
              </TextButton>
            )}
            <TextButton onClick={handleGoToBilling}>
              <Typography
                variant="bodySmallBold"
                color={JoonUIColor.text.primaryAccent}
              >
                Manage
              </Typography>
            </TextButton>
            <TextButton onClick={openUpgradePlanModal}>
              <Typography
                variant="bodySmallBold"
                color={JoonUIColor.text.primaryAccent}
              >
                Change plan
              </Typography>
            </TextButton>
          </FlexBox>
        </>
      ) : (
        <>
          <FlexBox
            direction="column"
            align="center"
            gap={SPACING.space4}
            style={{ padding: `${SPACING.space3} ${SPACING.space6}` }}
          >
            <Typography variant="bodyBold" textAlign="center">
              Get a coach who gets you
            </Typography>
            <Typography variant="bodySmall" textAlign="center">
              Connect 1:1 with a coach, right here in the app and{" "}
              <Typography variant="bodySmallBold">
                get support like never before.
              </Typography>
            </Typography>
            <img
              src="/images/guidePhotos/coach_main_headline.svg"
              style={{ width: "70%" }}
              alt="~~~"
            />
            <FlexBox
              direction="row"
              justify="space-around"
              style={{ padding: `${SPACING.space3} ${SPACING.space6}` }}
            >
              <Button
                text="Learn more about Joon Coaching"
                fullWidth
                onClick={() => router.push("/coach")}
              />
            </FlexBox>
          </FlexBox>
        </>
      )}
      <PlanUpdateModal />
    </div>
  )
}
