import {
  Button,
  Checkbox,
  CustomRewardCurrency,
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  TextButton,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"

import { useDeleteRewardModalStore } from "./DeleteRewardModal"
import useAddEditRewardModalStore from "./hooks/useAddEditRewardModalStore"
import { useSmartRedemptionModalStore } from "./redeem/SmartRedemptionModal"
import { RewardEmojiPicker, RewardEmojiPickerButton } from "./RewardEmojiPicker"
import ImageCheckbox from "../../components/imageCheckbox/ImageCheckbox"
import { ImageCheckboxRow } from "../../components/imageCheckbox/ImageCheckboxRow"
import { SmartRedemptionModalStep } from "../../constants"
import useMobile from "../../hooks/useMobile"
import {
  useAverageCoinsEarnedThisWeekQuery,
  useFamilyQuery,
} from "../../networking/queries"
import {
  useAddRewardMutation,
  useEditRewardMutation,
} from "../../networking/rewards/mutations"
import { useCustomRewardsQuery } from "../../networking/rewards/queries"
import { FamilyQuerySelect } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

const AddEditRewardModal = () => {
  const {
    isOpen,
    onClose,
    title,
    cost,
    emoji,
    rewardId,
    selectedChildren,
    currency,
    templateId,
    setTitle,
    setCost,
    setEmoji,
    toggleSelectedChild,
    setSelectedChildren,
    setCurrency,
  } = useAddEditRewardModalStore()
  const [errorText, setErrorText] = useState("")
  const { onOpen: openDeleteRewardModal } = useDeleteRewardModalStore()
  const { onOpen: onOpenSmartRedemptionModal, setIsPostCreate } =
    useSmartRedemptionModalStore()
  const { data: rewardData } = useCustomRewardsQuery()
  const { data: averageCoinsEarned } = useAverageCoinsEarnedThisWeekQuery()
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const editRewardMutation = useEditRewardMutation()
  const addRewardMutation = useAddRewardMutation()

  const isMobile = useMobile()

  const isEditing = !!rewardId
  const isItem = currency === CustomRewardCurrency.ITEM
  const isDollar = currency === CustomRewardCurrency.DOLLAR
  const isMinute = currency === CustomRewardCurrency.MINUTE
  const plural = cost && cost === 1 ? "" : "s"
  const placeholderText = isDollar
    ? "Allowance"
    : isMinute
      ? "Tablet screen time for Roblox"
      : "Ice cream with mom!"
  const hasCurrencyRewards = rewardData?.some(
    (reward) =>
      reward.currency === CustomRewardCurrency.MINUTE ||
      reward.currency === CustomRewardCurrency.DOLLAR
  )

  const onClickAddReward = async () => {
    if (cost === null)
      return setErrorText(
        `Please enter ${isItem ? "a cost" : "an exchange rate"} for your reward`
      )
    if (selectedChildren.length === 0)
      return setErrorText("Please select at least one child")
    if (!emoji) return setErrorText("Please select a reward icon")
    if (!title) return setErrorText("Please enter a title")
    if (isEditing && !rewardId) throw new Error("Reward ID not found")
    setErrorText("")

    if (isEditing) {
      trackAnalyticEvent(ANALYTIC_EVENTS.EDIT_CUSTOM_REWARD, {
        currency: currency,
      })
      await editRewardMutation.mutateAsync({
        rewardId: rewardId as number,
        rewardInfo: {
          title: title,
          cost: cost,
          emoji: emoji,
          user_ids: selectedChildren,
          purchasable_from_joonity: true,
          currency: currency,
        },
      })
    } else {
      trackAnalyticEvent(ANALYTIC_EVENTS.CREATE_CUSTOM_REWARD, {
        currency: currency,
      })
      await addRewardMutation.mutateAsync({
        title: title,
        cost: cost,
        emoji: emoji,
        user_ids: selectedChildren,
        purchasable_from_joonity: true,
        currency: currency,
      })
      toast.success("Reward created successfully!")
      // always show Smart Redemption modal for minute rewards
      if (currency === CustomRewardCurrency.MINUTE) {
        setIsPostCreate(true)
        onOpenSmartRedemptionModal(SmartRedemptionModalStep.INTRO)
      }
    }
    onClose()
  }

  // if there are no currency rewards and not a template, set the currency to minute
  useEffect(() => {
    if (children?.[0]?.user?.id && selectedChildren.length === 0)
      setSelectedChildren([children?.[0]?.user?.id])
    if (isOpen && !hasCurrencyRewards && !templateId) {
      setCurrency(CustomRewardCurrency.MINUTE)
      setCost(4)
      setTitle("Screen Time")
      setEmoji("📱")
    }
  }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <RewardEmojiPicker setEmoji={setEmoji} />
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        displayCloseIcon
        animate
        style={{ background: JoonUIColor.background.lightAccent }}
      >
        <div className="quest-modal">
          <div
            className="quest-modal-content"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: SPACING.space4,
              padding: `${SPACING.space4} ${SPACING.space6}`,
            }}
          >
            <Typography variant="h3">
              {isEditing ? `Edit: ${emoji} ${title}` : "Create new reward"}
            </Typography>

            <FlexBox
              direction="column"
              gap={SPACING.space2}
              wrap={false}
              fullWidth
            >
              <Typography variant="bodyBold">Reward type</Typography>
              <FlexBox direction="row" gap={SPACING.space2} wrap={false}>
                <ImageCheckbox
                  name="Reward type"
                  key="Item or activity"
                  label={
                    <FlexBox direction="column" align="center">
                      <Typography
                        variant={isMobile ? "bodySmallBold" : "bodyBold"}
                      >
                        Item or activity
                      </Typography>
                      <Typography
                        variant={isMobile ? "bodyXSmall" : "bodySmall"}
                        color={JoonUIColor.text.secondary}
                        style={{ whiteSpace: "wrap" }}
                      >
                        For everything else you'd like to reward your child!
                      </Typography>
                    </FlexBox>
                  }
                  image={"/images/rewards_item_or_activity.png"}
                  onChange={() => setCurrency(CustomRewardCurrency.ITEM)}
                  selected={isItem}
                  inputType="radio"
                  style={{
                    width: "50%",
                    minHeight: isMobile ? "228px" : "220px",
                    maxHeight: isMobile ? "228px" : "220px",
                    paddingTop: SPACING.space3,
                    paddingBottom: SPACING.space3,
                    gap: SPACING.space1,
                  }}
                />
                <ImageCheckbox
                  name="Reward type"
                  key="Currency exchange"
                  label={
                    <FlexBox direction="column" align="center">
                      <Typography
                        variant={isMobile ? "bodySmallBold" : "bodyBold"}
                      >
                        Currency exchange
                      </Typography>
                      <Typography
                        variant={isMobile ? "bodyXSmall" : "bodySmall"}
                        color={JoonUIColor.text.secondary}
                        style={{ whiteSpace: "wrap" }}
                      >
                        Best for allowance or screen time
                      </Typography>
                    </FlexBox>
                  }
                  image={"/images/rewards_currency_exchange.png"}
                  onChange={() => setCurrency(CustomRewardCurrency.MINUTE)}
                  selected={!isItem}
                  inputType="radio"
                  style={{
                    width: "50%",
                    minHeight: isMobile ? "228px" : "220px",
                    maxHeight: isMobile ? "228px" : "220px",
                    paddingTop: SPACING.space3,
                    paddingBottom: SPACING.space3,
                    gap: SPACING.space1,
                  }}
                />
              </FlexBox>
            </FlexBox>
            {!isItem && (
              <FlexBox direction="column" gap={SPACING.space2} wrap={false}>
                <Typography variant="bodyBold">
                  What would you like to reward?
                </Typography>

                <ImageCheckboxRow
                  title="Screen time"
                  description="Exchange coins into screen time"
                  image="/images/icons/coin_to_screen_time.png"
                  onClick={() => setCurrency(CustomRewardCurrency.MINUTE)}
                  isChecked={isMinute}
                />
                <ImageCheckboxRow
                  title="Allowance"
                  description="Exchange coins into money"
                  image="/images/icons/coin_to_money.png"
                  onClick={() => setCurrency(CustomRewardCurrency.DOLLAR)}
                  isChecked={isDollar}
                />
              </FlexBox>
            )}

            <FlexBox direction="column" gap={SPACING.space2} wrap={false}>
              <Typography variant="bodyBold">
                {isItem
                  ? "Set your item or activity price"
                  : "Set your currency exchange rate"}
              </Typography>

              <FlexBox direction="column" gap={SPACING.space0} wrap={false}>
                {!isItem && (
                  <Typography
                    color={JoonUIColor.text.secondary}
                    variant="bodyXSmall"
                  >
                    This reward can be purchased and redeemed per{" "}
                    {isDollar ? "dollar" : "minute"}.
                  </Typography>
                )}
                <Typography
                  color={JoonUIColor.text.secondary}
                  variant="bodyXSmall"
                  style={{ marginBottom: SPACING.space1 }}
                >
                  As a reminder: an easy quest rewards 4 coins, medium 10 coins,
                  and hard 20 coins. In the last 7 days, on average your kids
                  earned {averageCoinsEarned} coins.
                </Typography>
                <FlexBox
                  direction="row"
                  justify="flex-start"
                  align="center"
                  gap={SPACING.space2}
                  wrap={false}
                  style={{ marginTop: SPACING.space2 }}
                >
                  <Typography variant="bodySmallBold">
                    {isItem ? "Cost:" : "Exchange:"}
                  </Typography>
                  <div style={{ maxWidth: "100px" }}>
                    <TextInput
                      name="Cost"
                      value={cost !== null ? `${cost}` : ""}
                      placeholder={isMinute ? "5" : "100"}
                      maxLength={6}
                      onChange={(e) => {
                        const value = e.target.value
                        if (value === "") return setCost(null)
                        if (isNaN(Number(value)) || Number(value) < 0) return
                        setCost(Number(value))
                      }}
                      style={{
                        marginBottom: SPACING.space1,
                        width: "100px",
                        height: "40px",
                        borderRadius: SPACING.space2,
                        border: `1px solid ${JoonUIColor.border.default}`,
                      }}
                    />
                  </div>
                  <Typography
                    variant="bodySmallBold"
                    style={{ whiteSpace: "nowrap", width: "fit-content" }}
                  >
                    coin{plural}
                    {!isItem && ` for each ${currency}`}
                  </Typography>
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <FlexBox direction="column" gap={SPACING.space2} wrap={false}>
              <Typography variant="bodyBold">Available for</Typography>
              <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
                {children?.map((child) => (
                  <Checkbox
                    name={child.user.name}
                    key={child.user.id}
                    label={child.user.name}
                    onChange={() => toggleSelectedChild(child.user.id)}
                    selected={selectedChildren.includes(child.user.id)}
                  />
                ))}
              </div>
            </FlexBox>

            <RewardEmojiPickerButton emoji={emoji} />
            <FlexBox direction="column" gap={SPACING.space2} wrap={false}>
              <Typography variant="bodyBold">Reward Name</Typography>
              {!isItem && (
                <Typography
                  variant="bodyXSmall"
                  color={JoonUIColor.text.secondary}
                >
                  {isMinute
                    ? "Make sure to add the name of apps you'd like to include as part of this reward!"
                    : "Avoid including amount or numbers in the title and use the exchange rate settings above instead. "}
                </Typography>
              )}
              <TextInput
                name="Reward Title"
                maxLength={140}
                value={title}
                placeholder={placeholderText}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
                style={{
                  height: "40px",
                  borderRadius: SPACING.space2,
                  border: `1px solid ${JoonUIColor.border.default}`,
                }}
              />
            </FlexBox>

            <Typography
              variant="bodySmallBold"
              color={JoonUIColor.semantic.alert}
              style={{
                width: "100%",
                textAlign: "center",
                margin: `${SPACING.space1} 0`,
              }}
            >
              {errorText}
            </Typography>

            <FlexBox
              direction="row"
              justify="space-between"
              align="center"
              fullWidth
            >
              {isEditing && (
                <TextButton
                  onClick={() => {
                    openDeleteRewardModal(rewardId as number)
                    onClose()
                  }}
                  style={{ width: "16%" }}
                >
                  <Typography
                    variant="bodySmallBold"
                    color={JoonUIColor.semantic.alert}
                  >
                    Delete
                  </Typography>
                </TextButton>
              )}
              <Button
                text={isEditing ? "Save changes" : "Add reward"}
                isLoading={
                  addRewardMutation.isLoading || editRewardMutation.isLoading
                }
                onClick={onClickAddReward}
                style={{ width: isEditing ? "80%" : "100%" }}
              />
            </FlexBox>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AddEditRewardModal
