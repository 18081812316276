import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  Typography,
  UserRole,
} from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import { useState } from "react"
import toast from "react-hot-toast"

import { useViewQuestModalStore } from "./useViewQuestModalStore"
import { QUERY_KEYS } from "../../../constants"
import { deleteQuest } from "../../../networking/quests"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { localStorageItems } from "../../../util/storage"
import {
  CoachUpsellModalType,
  useCoachUpsellModalStore,
} from "../../care/CoachUpsellModal"
import useCareTeam from "../../care/useCareTeam"
import { useAddEditQuestStore } from "../addEditQuestModal/useAddEditQuestStore"
import { useQuestGroupInstances } from "../useQuestGroups"

const EditQuestButton = () => {
  const { onOpen: openAddEditQuestModal } = useAddEditQuestStore()
  const { questInstance, onClose: closeViewQuestModal } =
    useViewQuestModalStore()

  const onClickEditQuest = () => {
    if (!questInstance) return
    openAddEditQuestModal(questInstance.series)
    closeViewQuestModal()
    trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_EDIT_QUEST)
  }

  return (
    <FlexBox
      direction="column"
      align="center"
      gap={SPACING.space2}
      style={{
        flex: 0.5,
        cursor: "pointer",
        padding: `${SPACING.space2} ${SPACING.space4}`,
      }}
      onClick={onClickEditQuest}
    >
      <FontAwesomeIcon icon={faPen} fontSize={24} color="#7B838E" />
      <Typography variant="bodyBold" color={JoonUIColor.icon.neutral}>
        Edit
      </Typography>
    </FlexBox>
  )
}

const DeleteQuestButton = () => {
  const { questInstance, onClose: closeViewQuestModal } =
    useViewQuestModalStore()
  const { onOpen: openCoachUpsellModal } = useCoachUpsellModalStore()
  const [isDeleting, setIsDeleting] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const { questGroupInstances } = useQuestGroupInstances(questInstance)
  const queryClient = useQueryClient()
  const { hasCoach } = useCareTeam()

  const isTherapistCreated =
    questInstance?.series?.assigner_profile?.role === UserRole.THERAPIST
  if (!questInstance || isTherapistCreated) return <></>

  const onClickDeleteQuest = async () => {
    try {
      setIsDeleting(true)
      if (!questGroupInstances.length) return
      const deleteQuestPromises = questGroupInstances.map((quest) =>
        deleteQuest(quest.series.id)
      )
      await Promise.all(deleteQuestPromises)
      trackAnalyticEvent(ANALYTIC_EVENTS.DELETE_QUEST)
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER])
      queryClient.invalidateQueries([QUERY_KEYS.PARENT_TASKS])
      toast.success(
        `Quest ${questGroupInstances.length > 1 ? "group " : ""}deleted`
      )
      setShowConfirmModal(false)
      closeViewQuestModal()
      if (hasCoach) return
      const lastSeenUpsellModalDate = localStorage.getItem(
        localStorageItems.deleteCoachUpsellModalSeenDate
      )
      const needsToSeeDeleteCoachUpsellModal =
        !dayjs().isSame(dayjs(lastSeenUpsellModalDate), "week") ||
        !lastSeenUpsellModalDate
      if (needsToSeeDeleteCoachUpsellModal)
        openCoachUpsellModal(CoachUpsellModalType.delete)
    } catch (error) {
      toast.error("Error deleting Quest")
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <>
      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space2}
        style={{
          padding: `${SPACING.space2} ${SPACING.space4}`,
          flex: 0.5,
          cursor: "pointer",
        }}
        onClick={() => setShowConfirmModal(true)}
      >
        <FontAwesomeIcon
          icon={faTrash}
          fontSize={24}
          color={JoonUIColor.semantic.destructive}
        />
        <Typography variant="bodyBold" color={JoonUIColor.semantic.destructive}>
          Delete
        </Typography>
      </FlexBox>
      <Modal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        animate
      >
        <ModalHeader
          title="Are you sure?"
          onClose={() => setShowConfirmModal(false)}
          style={{ borderBottom: `1px solid ${JoonUIColor.border.default}` }}
        />
        <FlexBox
          style={{
            padding: `${SPACING.space4} ${SPACING.space6} ${SPACING.space6}`,
            width: "min(500px, 100vw)",
          }}
          gap={SPACING.space4}
        >
          <Typography variant="body">
            This action will delete the Quest and all Quests in the series. You
            will not be able to undo this action.
          </Typography>

          <FlexBox
            align="center"
            justify="flex-end"
            wrap={false}
            gap={SPACING.space2}
          >
            <button onClick={() => setShowConfirmModal(false)}>
              <Typography
                variant="bodyBold"
                color={JoonUIColor.text.primaryAccent}
              >
                Cancel
              </Typography>
            </button>
            <Button
              buttonType="redPrimary"
              onClick={onClickDeleteQuest}
              isLoading={isDeleting}
              text="Delete"
            />
          </FlexBox>
        </FlexBox>
      </Modal>
    </>
  )
}

export { EditQuestButton, DeleteQuestButton }
