import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared/"

interface ChatBubbleProps {
  text: string
  width?: string
}

const ChatBubble = ({ text, width }: ChatBubbleProps) => {
  return (
    <FlexBox
      direction="column"
      align="center"
      wrap={false}
      style={{
        position: "absolute",
        width: width || "80%",
        padding: SPACING.space2,
        background: "#8677A7",
        borderRadius: SPACING.space2,
        bottom: "108px",
        left: "4px",
      }}
    >
      <Typography
        variant="bodySmall"
        color={JoonUIColor.text.inverted}
        textAlign="left"
      >
        {text}
      </Typography>
      <div style={{ position: "absolute", top: "-16px", left: "-4px" }}>
        <img src="/images/guidePhotos/bells.svg" alt="bells" />
      </div>
      <div style={{ position: "absolute", bottom: "-12px", left: "5%" }}>
        <img
          src="/images/guidePhotos/chat_bubble_tail.svg"
          alt="chat bubble tail"
        />
      </div>
    </FlexBox>
  )
}

export default ChatBubble
