import React, { useContext } from "react"

import { SortableItemContext } from "./SortableItem"

interface DragHandleProps {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

export function DragHandle({ children, className, style }: DragHandleProps) {
  const { attributes, listeners, ref } = useContext(SortableItemContext)

  return (
    <div
      className={className}
      style={{
        cursor: "grab",
        touchAction: "none",
        ...style,
      }}
      ref={ref}
      {...attributes}
      {...listeners}
    >
      {children}
    </div>
  )
}
