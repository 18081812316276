import { FlexBox, SPACING, Typography } from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import useOnboardContext from "../../hooks/useOnboardContext"
import { useQuestionnairesQuery } from "../../networking/queries"
import { getUserReasons } from "../../util/util"

const ParentSignupReasons = () => {
  const [userReasons, setUserReasons] = useState<any>(null)
  const { parentData } = useOnboardContext()
  const { data: questionsResponse, status } = useQuestionnairesQuery()

  useEffect(() => {
    if (!questionsResponse?.parentQuestions) return
    const reasons = getUserReasons(
      parentData,
      questionsResponse.parentQuestions
    )
    setUserReasons(reasons)
    // eslint-disable-next-line
  }, [questionsResponse])

  if (!userReasons || status === "loading") return <div></div>

  const hideSecondaryGoal =
    userReasons[0].text === userReasons[1].text ||
    userReasons[1].text === "Something else"

  return (
    <FlexBox direction="column" gap={SPACING.space1} wrap={false} fullWidth>
      <Typography variant="bodySmallBold">Primary Goal:</Typography>
      <div className="signup-reason">
        {<img src={userReasons[0].icon} alt="icon" />}
        {userReasons[0].text}
      </div>
      {!hideSecondaryGoal && (
        <>
          <Typography variant="bodySmallBold">Secondary Goal:</Typography>
          <div className="signup-reason">
            {<img src={userReasons[1].icon} alt="icon" />}
            {userReasons[1].text}
          </div>
        </>
      )}
    </FlexBox>
  )
}

export default ParentSignupReasons
