import {
  Button,
  JoonUIColor,
  QuotesIcon,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { FlexBox } from "@joonapp/web-shared"
import { useState } from "react"
import { useEffect } from "react"
import toast from "react-hot-toast"

import useSession from "./hooks/useSession"
import SessionDetailsHeader from "./SessionDetailsHeader"
import PageLoader from "../../../components/loading/PageLoader"
import PageContentWrapper from "../../../components/pageContentWrapper/PageContentWrapper"
import PageWrapper from "../../../components/pageWrapper/PageWrapper"
import CustomTextArea from "../../../components/textArea/CustomTextArea"
import { QUERY_KEYS } from "../../../constants"
import { updateSessionNotes } from "../../../networking/sessions/api"
import { useSessionBookingQuery } from "../../../networking/sessions/queries"
import { requireAuth } from "../../../util/auth"
import { queryClient } from "../../../util/queryClient"

const SessionDetailsPage = () => {
  const { data: sessionBooking, isLoading: isLoadingSessionBooking } =
    useSessionBookingQuery()

  const { refetch: refetchSessionBooking } = useSessionBookingQuery()
  const { sessionIsPast } = useSession(sessionBooking)

  const [discussionTopic, setDiscussionTopic] = useState("")
  const [isSaving, setIsSaving] = useState(false)

  // Set initial discussion topic value
  useEffect(() => {
    if (!sessionBooking) return
    setDiscussionTopic(sessionBooking.topic_to_discuss_notes?.note || "")
  }, [sessionBooking])

  // Refresh cache when the user comes back to the page
  useEffect(() => {
    refetchSessionBooking()
  }, [refetchSessionBooking])

  const hasMadeChanges =
    discussionTopic !== sessionBooking?.topic_to_discuss_notes?.note &&
    discussionTopic !== ""

  const handleSaveDiscussionTopic = async () => {
    if (!sessionBooking) return
    if (!sessionBooking.topic_to_discuss_notes?.id) return

    setIsSaving(true)
    await updateSessionNotes({
      noteId: sessionBooking.topic_to_discuss_notes?.id,
      note: discussionTopic,
      visibilityRole: null,
    })
    await queryClient.invalidateQueries([QUERY_KEYS.SESSION_BOOKING])
    setIsSaving(false)
    toast.success("Discussion topic saved")
  }

  return (
    <PageWrapper
      style={{
        paddingBottom: "0px",
        background: JoonUIColor.background.xlightGray,
      }}
    >
      <SessionDetailsHeader />
      {isLoadingSessionBooking ? (
        <PageLoader />
      ) : (
        <PageContentWrapper
          style={{
            padding: SPACING.space6,
            borderLeft: `1px solid ${JoonUIColor.border.default}`,
            borderRight: `1px solid ${JoonUIColor.border.default}`,
            background: JoonUIColor.background.primaryNeutral,
          }}
        >
          <FlexBox direction="column" gap={SPACING.space4}>
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="bodyBold" color={JoonUIColor.text.primary}>
                Discussion topic
              </Typography>
              <Typography variant="bodySmall" color={JoonUIColor.text.primary}>
                Share what would you like to focus on during this session with
                your coach.
              </Typography>
              <CustomTextArea
                value={discussionTopic}
                onChange={(e) => setDiscussionTopic(e.target.value)}
                placeholder="What went well this week? What would you like to work on?"
                disabled={sessionIsPast}
              />
              {hasMadeChanges && (
                <Button
                  onClick={handleSaveDiscussionTopic}
                  text="Save"
                  style={{ borderRadius: SPACING.space2 }}
                  buttonType="primary"
                  size="small"
                  isLoading={isSaving}
                />
              )}
            </FlexBox>
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="bodyBold" color={JoonUIColor.text.primary}>
                Session summary
              </Typography>
              <Typography variant="bodySmall" color={JoonUIColor.text.primary}>
                Your coach will share a session summary after your session ends.
              </Typography>
              {sessionBooking?.session_summary_notes?.note && (
                <div
                  style={{
                    background: JoonUIColor.background.xlightGray,
                    borderRadius: SPACING.space2,
                    padding: SPACING.space3,
                    width: "100%",
                    minHeight: "100px",
                    display: "flex",
                    gap: SPACING.space2,
                    alignItems: "flex-start",
                  }}
                >
                  <QuotesIcon />
                  <Typography
                    variant="bodySmall"
                    color={JoonUIColor.text.primary}
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {sessionBooking?.session_summary_notes?.note || ""}
                  </Typography>
                </div>
              )}
            </FlexBox>
          </FlexBox>
        </PageContentWrapper>
      )}
    </PageWrapper>
  )
}

const SessionDetailsPageWithAuth = requireAuth(SessionDetailsPage)
export default SessionDetailsPageWithAuth
