import { QuestInstance } from "@joonapp/web-shared"
import { create } from "zustand"

interface RejectQuestModalStore {
  isOpen: boolean
  onOpen: (quest?: QuestInstance) => void
  onClose: () => void

  quest: QuestInstance | null
}

export const useRejectQuestModalStore = create<RejectQuestModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (quest?: QuestInstance) => set({ quest, isOpen: true }),
    onClose: () => set({ isOpen: false }),

    quest: null,
  })
)
