import {
  Button,
  FlexBox,
  JoonUIColor,
  QuestStatus,
  SPACING,
  Typography,
  UserRole,
} from "@joonapp/web-shared"
import { useShallow } from "zustand/react/shallow"

import ParentTasksSectionSmall from "./parentTasks/ParentTasksSectionSmall"
import { useDisplayParentTaskSection } from "./parentTasks/useDisplayParentTasksSection"
import { useCatchUpCompleteStore } from "./useCatchUpCompleteStore"
import { useCatchUp, useCatchUpStore } from "./useCatchUpStore"
import { useQuestBoardQuery, useUserQuery } from "../../networking/queries"

const CatchUpCompleteEmptyState = () => {
  const onClose = useCatchUpStore((state) => state.onClose)
  const { selectedUserProfile } = useCatchUpCompleteStore(
    useShallow((state) => ({ selectedUserProfile: state.selectedUserProfile }))
  )
  const { displayParentTasksSection } = useDisplayParentTaskSection()
  const { data: quests } = useQuestBoardQuery()
  const { user } = useUserQuery()
  const { goToNextChildWithQuests } = useCatchUp()
  const questsToComplete = quests?.filter((questInstance) =>
    [QuestStatus.OPEN, QuestStatus.RETRY].includes(questInstance.status)
  )

  const isSelfSelected = selectedUserProfile?.user.id === user?.id
  const isCoparent =
    selectedUserProfile?.role === UserRole.PARENT && !isSelfSelected
  const hasRemainingQuests = questsToComplete && questsToComplete?.length > 0

  return (
    <FlexBox
      direction="column"
      justify="center"
      align="center"
      style={{
        height: "min(600px, calc(100vh - 80px))",
        padding: `${SPACING.space6}`,
      }}
    >
      <FlexBox justify="center" gap={SPACING.space1}>
        <Typography
          variant="h1"
          style={{ fontSize: "64px", marginBottom: SPACING.space10 }}
        >
          🎉
        </Typography>
        <Typography variant="bodyBold" textAlign="center">
          {isSelfSelected
            ? "You've reviewed all of your Quests!"
            : `You've reviewed all of ${selectedUserProfile?.user.name}'s Quests!`}
        </Typography>
        <FlexBox direction="column" gap={SPACING.space4} align="center">
          {!isSelfSelected && !isCoparent && (
            <>
              <Typography
                variant="bodySmall"
                textAlign="center"
                style={{ width: "245px" }}
                color={JoonUIColor.text.secondary}
              >
                A clean Quest Board makes it easier for your child to understand
                their daily expectation.
              </Typography>
              <Typography
                variant="bodySmall"
                color={JoonUIColor.text.secondary}
                style={{ width: "245px" }}
                textAlign="center"
              >
                Once no longer relevant, consider removing old Quests to keep{" "}
                {selectedUserProfile?.user.name}'s Quest Board clean!
              </Typography>
            </>
          )}
          {displayParentTasksSection ? (
            <div style={{ marginTop: SPACING.space6, width: "100%" }}>
              <ParentTasksSectionSmall onClose={onClose} />
            </div>
          ) : (
            <Button
              text={hasRemainingQuests ? "Next child" : "Return home"}
              name="Return home"
              onClick={hasRemainingQuests ? goToNextChildWithQuests : onClose}
              style={{ marginTop: SPACING.space4, width: "250px" }}
            />
          )}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export default CatchUpCompleteEmptyState
