import { faHashtag } from "@fortawesome/duotone-light-svg-icons"
import {
  AuthCodeType,
  Button,
  CalloutBox,
  Checkbox,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { create } from "zustand"

import therapistDisclaimer from "./therapistDisclaimer"
import {
  PhoneNumberModalType,
  useAddPhoneNumberModalStore,
} from "../../../components/modals/addPhoneNumberModal/useAddPhoneNumberModalStore"
import { Toasts } from "../../../components/toaster/Toaster"
import { QUERY_KEYS } from "../../../constants"
import { useFamilyQuery, useUserQuery } from "../../../networking/queries"
import { FamilyQuerySelect } from "../../../types"
import { createJoonAPIClient } from "../../../util/joon-api"
import { useRouter } from "../../../util/router"
import { localStorageItems } from "../../../util/storage"

interface EnterTherapistCodeModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void

  therapistCode: string
  setTherapistCode: (therapistCode: string) => void
}

export const useEnterTherapistCodeModal = create<EnterTherapistCodeModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),

    therapistCode: "",
    setTherapistCode: (therapistCode) => set({ therapistCode }),
  })
)

export const EnterTherapistCodeModal = () => {
  const { isOpen, onOpen, onClose, therapistCode, setTherapistCode } =
    useEnterTherapistCodeModal()
  const { onOpen: onOpenAddPhoneNumberModal } = useAddPhoneNumberModalStore()
  const [selectedChildren, setSelectedChildren] = useState<number[]>([])
  const [error, setError] = useState<string | null>(null)
  const [isLinking, setIsLinking] = useState(false)
  const [isValidating, setIsValidating] = useState(false)

  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const { user } = useUserQuery()
  const queryClient = useQueryClient()
  const router = useRouter()

  useEffect(() => {
    if (router.query.therapist_code) {
      localStorage.setItem(
        localStorageItems.therapistCode,
        router.query.therapist_code
      )
      router.replace(router.pathname)
    }
    if (!!user) {
      const code = localStorage.getItem(localStorageItems.therapistCode)
      // currently will always open until user confirms therapist code
      // maybe need to change to only clear code when not on paywall or therapists tab
      if (code) {
        onOpen()
        setTherapistCode(code)
      }
    }
  }, [router, user, onOpen, setTherapistCode])

  const selectChild = (id: number) => {
    if (selectedChildren.includes(id)) {
      setSelectedChildren(selectedChildren.filter((childId) => childId !== id))
    } else {
      setSelectedChildren([...selectedChildren, id])
    }
  }
  const validateCode = async () => {
    if (therapistCode.length !== 8)
      return setError("Code must be 8 characters long")

    try {
      const API = createJoonAPIClient()
      setIsValidating(true)
      const response = await API.get("api/auth-codes/validate/", {
        params: {
          code: therapistCode.toUpperCase(),
          type: AuthCodeType.ADMIT_PATIENT,
        },
      })
      if (!response.data.group.profiles) return setError("An error occurred")
      setError(null)
    } catch (error) {
      setError("Invalid code")
    }
    setIsValidating(false)
  }

  const linkChildren = async () => {
    const API = createJoonAPIClient()

    const linkChildrenPromises = selectedChildren.map((child) =>
      API.post("api/practices/admit-patient/", {
        code: therapistCode,
        user_id: child,
      })
    )
    try {
      setIsLinking(true)
      localStorage.removeItem(localStorageItems.therapistCode)
      const responses = await Promise.all(linkChildrenPromises)
      queryClient.invalidateQueries([QUERY_KEYS.THERAPISTS])
      queryClient.invalidateQueries([QUERY_KEYS.CARE_TEAM])
      queryClient.invalidateQueries([QUERY_KEYS.USER])
      setError(null)
      setTherapistCode("")
      localStorage.setItem(localStorageItems.needsToSeeTherapistTour, "true")
      if (router.pathname.includes("/paywall")) {
        localStorage.setItem(localStorageItems.needsToSeeWelcomeRewards, "true")
        localStorage.setItem(localStorageItems.needsToSeeWelcomeQuests, "true")
      }
      onClose()
      toast(Toasts.therapistSuccessfullyLinked)
      if (!user?.phone_number || user.phone_number.length < 12) {
        onOpenAddPhoneNumberModal(PhoneNumberModalType.THERAPIST)
      } else if (router.pathname.includes("/paywall")) router.push("/me")
      return responses
    } catch (error) {
      setError("An error occurred")
      throw new Error("Failed to link children")
    } finally {
      setIsLinking(false)
    }
  }

  useEffect(() => {
    if (therapistCode.length === 8) validateCode()
    else setError("")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [therapistCode])

  useEffect(() => {
    if (children && children.length > 0) selectChild(children[0]?.user.id)
  }, [children?.length]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal isOpen={isOpen} onClose={onClose} animate>
      <ModalHeader
        title="Link account with therapist"
        onClose={onClose}
        showBorderBottom
      />
      <FlexBox
        direction="column"
        style={{
          width: "min(400px, 95vw)",
          padding: SPACING.space6,
          paddingTop: SPACING.space4,
        }}
        gap={SPACING.space6}
      >
        <FlexBox direction="column" gap={SPACING.space4}>
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="body">Enter therapist code</Typography>
            <TextInput
              value={therapistCode}
              icon={faHashtag}
              name="therapist code"
              placeholder="ABCD1234"
              maxLength={8}
              fullWidth
              onChange={(e: any) => setTherapistCode(e.target.value)}
            />
            {error && (
              <Typography
                variant="bodySmallBold"
                color={JoonUIColor.semantic.alert}
                style={{ marginTop: SPACING.space1 }}
              >
                {error}
              </Typography>
            )}
          </FlexBox>
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="body">Whose therapist is this?</Typography>
            <FlexBox gap={SPACING.space2}>
              {children?.map((child, i) => (
                <Checkbox
                  name="test"
                  key={i}
                  label={child.user.name}
                  onChange={() => selectChild(child.user.id)}
                  selected={selectedChildren.includes(child.user.id)}
                />
              ))}
            </FlexBox>
          </FlexBox>
          <CalloutBox fullWidth>
            <Typography variant="bodySmall" style={{ fontWeight: 400 }}>
              {therapistDisclaimer}
            </Typography>
          </CalloutBox>
        </FlexBox>
        <Button
          text="Link with therapist"
          onClick={linkChildren}
          isLoading={isLinking}
          fullWidth
          disabled={
            therapistCode.length !== 8 ||
            !!error ||
            isValidating ||
            selectedChildren.length === 0
          }
        />
      </FlexBox>
    </Modal>
  )
}
