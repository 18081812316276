import { QuestInstance } from "@joonapp/web-shared"
import { create } from "zustand"

interface ReviewQuestModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void

  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void

  quest: QuestInstance | null
  reward: number | undefined
  notes: string | undefined
  difficulty: number | undefined
  setQuest: (quest: QuestInstance) => void
  setReward: (reward: number | undefined) => void
  setNotes: (notes: string | undefined) => void
  setDifficulty: (difficulty: number | undefined) => void

  error: string
  setError: (error: string) => void
}

export const useReviewQuestModalStore = create<ReviewQuestModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () =>
      set({ isOpen: false, quest: null, reward: undefined, notes: undefined }),

    isLoading: false,
    setIsLoading: (isLoading) => set({ isLoading }),

    quest: null,
    reward: undefined,
    notes: undefined,
    difficulty: undefined,
    setQuest: (quest) => set({ quest, reward: quest.series.redeemable_reward }),
    setReward: (reward) => set({ reward }),
    setNotes: (notes) => set({ notes }),
    setDifficulty: (difficulty) => set({ difficulty }),

    error: "",
    setError: (error) => set({ error }),
  })
)
