import {
  Checkbox,
  ChevronRightIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  Switch,
  Typography,
} from "@joonapp/web-shared"
import { create } from "zustand"

import { useRecurrance } from "./useRecurrance"
import { CloseButton } from "../../../../components/closeButton/CloseButton"
import SlideUpModal from "../../../../components/slideUpModal/SlideUpModal"
import {
  RECURRENCE_OPTIONS,
  RecurrenceOption,
  REPETITIONS,
} from "../../../../constants"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

interface RecurrenceModalStoreProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useRecurrenceModalStore = create<RecurrenceModalStoreProps>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  })
)

export const RecurrenceModal = () => {
  const { isOpen, onClose } = useRecurrenceModalStore()
  const {
    repetition,
    setRepetition,
    setRepeatsFrequency,
    setIsOngoing,
    selectedQuest,
  } = useAddEditQuestStore()
  const { selectedRecurrenceOption, setSelectedReptition, getLabel } =
    useRecurrance()

  const isEditing = !!selectedQuest
  const checked = !repetition.every((day) => !day)

  const onToggleRepeatQuest = () => {
    if (checked) {
      setRepetition(REPETITIONS.noDays)
      setRepeatsFrequency(null)
    } else {
      setRepetition(REPETITIONS.daily)
      setRepeatsFrequency(1)
      setIsOngoing(false)
    }
  }

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      zIndex={1000}
      backgroundType="overlay"
      size="fit-content"
    >
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{
          position: "relative",
          backgroundColor: JoonUIColor.background.xlightGray,
          borderRadius: SPACING.space2,
          padding: SPACING.space4,
        }}
      >
        <CloseButton onClick={onClose} />
        <Typography variant="bodyBold">Set recurrence</Typography>

        {!isEditing && (
          <Typography variant="bodyXSmall">
            Once saved, you can't change a non-recurring quest into a recurring
            task, and vice versa.
          </Typography>
        )}

        <FlexBox
          direction="column"
          gap={SPACING.space2}
          style={{
            padding: SPACING.space3,
            borderRadius: SPACING.space2,
            background: JoonUIColor.background.primaryNeutral,
            opacity: isEditing ? 0.5 : 1,
            pointerEvents: isEditing ? "none" : "auto",
            fontStyle: isEditing ? "italic" : "normal",
          }}
        >
          <FlexBox
            direction="row"
            gap={SPACING.space2}
            align="center"
            justify="space-between"
          >
            <Typography variant="bodySmallBold">Repeat this quest</Typography>
            <Switch
              checked={checked}
              onChange={onToggleRepeatQuest}
              name="repeat-quest"
            />
          </FlexBox>
          <Typography variant="bodyXSmall" color={JoonUIColor.text.secondary}>
            Repeated quests will show up every time they're assigned.
          </Typography>
        </FlexBox>

        {checked && (
          <FlexBox
            direction="column"
            style={{
              borderRadius: SPACING.space2,
              background: JoonUIColor.background.primaryNeutral,
              border: `1px solid ${JoonUIColor.border.default}`,
            }}
          >
            {RECURRENCE_OPTIONS.map((option, index) => (
              <button
                key={index}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: SPACING.space2,
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom:
                    option.value === RecurrenceOption.CUSTOM
                      ? "none"
                      : `1px solid ${JoonUIColor.border.default}`,
                  padding: SPACING.space3,
                }}
                onClick={() =>
                  setSelectedReptition(option.value as RecurrenceOption)
                }
              >
                <Typography variant="bodySmallBold" textAlign="left">
                  {getLabel(option)}
                </Typography>

                {index === RECURRENCE_OPTIONS.length - 1 &&
                getLabel(option) === "Custom..." ? (
                  <ChevronRightIcon
                    color={JoonUIColor.icon.neutral}
                    size={24}
                  />
                ) : (
                  <Checkbox
                    selected={option.value === selectedRecurrenceOption}
                    inputType="radio"
                    name={option.value?.toString() || "none"}
                    onChange={() => {}}
                    hideBorder
                  />
                )}
              </button>
            ))}
          </FlexBox>
        )}
      </FlexBox>
    </SlideUpModal>
  )
}
