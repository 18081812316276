import * as Sentry from "@sentry/react"
import { useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"

import useOnboardContext from "./useOnboardContext"
import { QUERY_KEYS } from "../constants"
import { createChildren } from "../networking/authentication"
import { createFamily } from "../networking/authentication"
import { createUser } from "../networking/authentication"
import { loadFamilies } from "../networking/families/api"
import { useQuestionnairesQuery } from "../networking/queries"
import { getSubscriberInfo } from "../networking/subscriptions"
import { joinFamily } from "../networking/user"
import { loadUser } from "../networking/users/api"
import useJooniperPrepaywallModal from "../pages/paywall/JooniperPrepaywallModal/useJooniperPrepaywallModal"
import { GAEvent, trackAnalyticEvent, trackGAEvent } from "../util/analytics"
import {
  cohorts,
  experimentNames,
  getExperimentCohorts,
} from "../util/experiments"
import { submitParentQuestionnaires } from "../util/questionnaires"
import { submitChildQuestionnaires } from "../util/questionnaires"
import { localStorageItems } from "../util/storage"
import { sessionManager } from "../util/storage"
import { separateFamily } from "../util/util"

export const useAccountCreation = () => {
  const {
    isSubmittingData,
    setIsSubmittingData,
    parentData,
    isCoparent,
    coparentCode,
    setIsCreatingUser,
  } = useOnboardContext()
  const queryClient = useQueryClient()
  const { initializeAndGenerate } = useJooniperPrepaywallModal()
  const { data: questionsResponse, status } = useQuestionnairesQuery()

  const uploadOnboardingSecondaryData = async () => {
    if (status === "loading" || status === "error") return {}
    localStorage.removeItem(localStorageItems.lastSeenPaywall)

    const { onboardingQuestionnaires } = questionsResponse
    if (isSubmittingData) return

    const hasChildQuestionnaires =
      parentData.childrenData.length > 0 &&
      !(
        Array.isArray(parentData.childrenData[0].questionAnswerMapping) &&
        parentData.childrenData[0].questionAnswerMapping.length === 0
      )

    setIsSubmittingData(true)
    try {
      // Create User, Family, and Children
      setIsCreatingUser(true)
      await createUser(
        parentData.name,
        parentData.avatar as string,
        parentData.lastName || undefined
      )
      setIsCreatingUser(false)
      trackAnalyticEvent("create-acc-user-created")

      if (isCoparent) {
        await joinFamily(coparentCode, parentData.nickname)
        trackAnalyticEvent("create-acc-family-joined")
      } else {
        const family = await createFamily(parentData.nickname)
        Sentry.addBreadcrumb({
          category: "account",
          message: `Family created: ${family?.data?.id}`,
          level: "info",
        })
        const children = await createChildren(
          family.data.id,
          parentData.childrenData
        )
        Sentry.addBreadcrumb({
          category: "account",
          message: `Children created: ${JSON.stringify(children)}`,
          level: "info",
        })
        trackAnalyticEvent("create-acc-family-created")

        // Set marker to show freemium popup when verifying first Quest.
        // We only need to set new user flags for those that are creating a family.
        sessionManager.setNewUserLocalStorageFlags()
      }
      Sentry.addBreadcrumb({
        category: "function",
        message: "Starting loadFamilies in uploadOnboardingSecondaryData",
        level: "info",
      })

      const familiesData = await loadFamilies()

      Sentry.addBreadcrumb({
        category: "function",
        message: "Finished loadFamilies in uploadOnboardingSecondaryData",
        level: "info",
      })

      // Set paywall flag if applicable
      const subscriberInfo = await getSubscriberInfo(familiesData)
      if (!subscriberInfo.plan_is_active) {
        sessionManager.setLocalStorageFlag(localStorageItems.needsToSeePaywall)
      }

      // Submit Questionnaires
      const userData = await loadUser()
      if (!familiesData || !userData)
        throw new Error(
          `Error loading data families:${familiesData} user:${userData}`
        )
      const { children } = separateFamily(userData, familiesData)
      const questionnairePromises: Promise<void>[] = []
      if (hasChildQuestionnaires) {
        questionnairePromises.push(
          submitChildQuestionnaires(
            onboardingQuestionnaires,
            parentData,
            children
          )
        )
      }
      if (Object.keys(parentData.parentQuestionnaireMapping).length > 0) {
        questionnairePromises.push(
          submitParentQuestionnaires(
            onboardingQuestionnaires,
            parentData,
            userData.id
          )
        )
      }
      await Promise.all(questionnairePromises)

      trackAnalyticEvent("create-acc-questionnaires-submitted")

      trackGAEvent(GAEvent.CREATE_ACCOUNT)

      const experimentCohorts = await getExperimentCohorts()
      const devtoolsOverride = localStorage.getItem(
        experimentNames.jooniperPrepaywall
      )
      const shouldShowJooniperPrepaywall =
        !isCoparent &&
        !localStorage.getItem(localStorageItems.therapistCode) &&
        (devtoolsOverride === cohorts.variantA ||
          experimentCohorts?.[experimentNames.jooniperPrepaywall] ===
            cohorts.variantA)

      if (shouldShowJooniperPrepaywall) initializeAndGenerate()
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setLevel("error")
        scope.setTag("function", "uploadOnboardingSecondaryData")
        scope.setExtra("status", status)
        scope.setExtra("isSubmittingData", isSubmittingData)
        scope.setExtra("parentData", JSON.stringify(parentData))
        scope.setExtra("isCoparent", isCoparent)
        scope.setExtra("coparentCode", coparentCode)

        if (error instanceof AxiosError) {
          scope.setExtra("axiosError", {
            message: error.message,
            code: error.code,
            response: error.response
              ? {
                  data: error.response.data,
                  status: error.response.status,
                  headers: error.response.headers,
                }
              : null,
            request: error.request
              ? {
                  method: error.request.method,
                  url: error.request.url,
                  headers: error.request.headers,
                }
              : null,
          })
        } else {
          scope.setExtra("error", {
            message: (error as Error)?.message,
            stack: (error as Error)?.stack,
          })
        }

        Sentry.captureException(error)
      })

      // Instead of throwing, return an error object
      throw error
    } finally {
      queryClient.invalidateQueries([QUERY_KEYS.FAMILY])
      setIsSubmittingData(false)
    }
  }
  return { uploadOnboardingSecondaryData }
}
