import { captureException } from "@sentry/react"
import { useCallback, useMemo } from "react"

import { useJooniperPrepaywallModalStore } from "./useJooniperPrepaywallModalStore"
import { QUERY_KEYS } from "../../../constants"
import {
  acceptSuggestions,
  createNewChatSession,
  generatePrepaywallChatResponse,
} from "../../../networking/chatSessions/api"
import { QuestAIChatSessionType } from "../../../types/quests"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { queryClient } from "../../../util/queryClient"

const useJooniperPrepaywallModal = () => {
  const {
    isLoading,
    setIsLoading,
    chatSessionId,
    setChatSessionId,
    response,
    setResponse,
    selectedQuestUUIDs,
    setSelectedQuestUUIDs,
    isAssigningQuests,
    setIsAssigningQuests,
    setError,
    setAssignedQuests,
  } = useJooniperPrepaywallModalStore()

  // Selected = either actual selected or ALL in response
  const selectedUUIDs = useMemo(() => {
    return selectedQuestUUIDs.length > 0
      ? selectedQuestUUIDs
      : response?.map((quest) => quest.pending_data_uuid) ?? []
  }, [selectedQuestUUIDs, response])

  const initializeAndGenerate = useCallback(async () => {
    if (isLoading || chatSessionId) return

    try {
      setIsLoading(true)

      // Create session
      const id = await createNewChatSession(
        QuestAIChatSessionType.ONBOARDING_ROUTINE
      )
      if (!id) throw new Error("Failed to create chat session")
      setChatSessionId(id)

      // Generate response
      const result = await generatePrepaywallChatResponse(id)
      setResponse(result)
      setSelectedQuestUUIDs(result.map((quest) => quest.pending_data_uuid))
      setAssignedQuests([])
      trackAnalyticEvent(ANALYTIC_EVENTS.PREPAYWALL_AI_GENERATE_RESPONSE)
    } catch (error) {
      captureError(error)
    } finally {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, chatSessionId])

  // Assign suggested quests
  const handleAssignQuests = useCallback(async () => {
    if (isLoading || isAssigningQuests || !chatSessionId || !response) return

    try {
      setIsAssigningQuests(true)
      const response = await acceptSuggestions(chatSessionId, selectedUUIDs)
      await Promise.all([
        queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD]),
        queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER]),
      ])
      trackAnalyticEvent(ANALYTIC_EVENTS.PREPAYWALL_AI_ACCEPT_SUGGESTIONS)
      setResponse(null)
      setAssignedQuests(response.quests)
      setSelectedQuestUUIDs([])
    } catch (error) {
      captureError(error)
    } finally {
      setIsAssigningQuests(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAssigningQuests, chatSessionId, selectedUUIDs])

  const captureError = useCallback(
    (error: any) => {
      console.error(error)
      captureException(error)
      setError(JSON.stringify(error, null, 2))
      trackAnalyticEvent(ANALYTIC_EVENTS.PREPAYWALL_AI_ERROR, {
        error: JSON.stringify(error, null, 2),
      })
    },
    [setError]
  )

  return {
    initializeAndGenerate,
    handleAssignQuests,
  }
}

export default useJooniperPrepaywallModal
