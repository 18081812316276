import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { useShallow } from "zustand/react/shallow"

import ParentTasksSectionSmall from "./parentTasks/ParentTasksSectionSmall"
import { useCatchUpStore } from "./useCatchUpStore"

const CatchUpCardEmptyState = () => {
  const { selectedChild, onClose } = useCatchUpStore(
    useShallow((state) => ({
      selectedChild: state.selectedChild,
      onClose: state.onClose,
    }))
  )
  return (
    <FlexBox
      direction="column"
      justify="space-between"
      align="center"
      style={{
        height: "min(600px, calc(100vh - 80px))",
        padding: `${SPACING.space14} ${SPACING.space6} ${SPACING.space6}`,
      }}
    >
      <FlexBox justify="center" gap={SPACING.space1}>
        <Typography
          variant="h1"
          style={{ fontSize: "64px", marginBottom: SPACING.space10 }}
        >
          🎉
        </Typography>
        <Typography variant="bodyBold" textAlign="center">
          You've approved all of {selectedChild?.user.name}'s Quests!
        </Typography>
        <Typography
          variant="bodySmall"
          textAlign="center"
          style={{ width: "245px" }}
        >
          The next time {selectedChild?.user.name} plays Joon, they can collect
          their reward from the rewards chest!
        </Typography>
      </FlexBox>
      <FlexBox justify="center">
        <Typography
          variant="bodySmallBold"
          color={JoonUIColor.text.secondary}
          textAlign="center"
        >
          How this helps {selectedChild?.user.name}
        </Typography>
        <Typography
          variant="bodySmall"
          textAlign="center"
          color={JoonUIColor.text.secondary}
          style={{
            width: "245px",
          }}
        >
          Consistent feedback loop helps your child build long term and
          sustainable habits! Great job - keep it up!
        </Typography>
      </FlexBox>
      <div style={{ marginTop: SPACING.space10 }}>
        <ParentTasksSectionSmall onClose={onClose} />
      </div>
    </FlexBox>
  )
}

export default CatchUpCardEmptyState
