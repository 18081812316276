import {
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  Typography,
  Button,
} from "@joonapp/web-shared"
import { create } from "zustand"

import doterGroup from "../../images/doter-group.svg"
import { useUserQuery } from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { localStorageItems } from "../../util/storage"

interface IOSModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  showiOSView: boolean
  setShowiOSView: (showiOSView: boolean) => void
}

export const useIOSModal = create<IOSModalStore>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false, showiOSView: false }),
  showiOSView: false,
  setShowiOSView: (showiOSView) => set({ showiOSView }),
}))

const IOSModal = () => {
  const { isOpen, onClose, showiOSView, setShowiOSView } = useIOSModal()
  const { user } = useUserQuery()

  const onClickNoIphone = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.IOS_DOWNLOAD_PROMPT, {
      action: "no_iphone",
      subscribed: false,
    })

    localStorage.removeItem(localStorageItems.showDownloadAppBanner)
    onClose()
  }

  const onClickDownloadIOS = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.IOS_DOWNLOAD_PROMPT, {
      action: "download",
      subscribed: false,
    })
    setShowiOSView(true)
  }

  const onClickNotNow = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.IOS_DOWNLOAD_PROMPT, {
      action: "not_now",
      subscribed: false,
    })
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} displayCloseIcon animate>
      <div className="subscription-modal">
        {showiOSView ? (
          <IOSDownloadSection />
        ) : (
          <>
            <img
              src={doterGroup}
              alt="doter-group"
              style={{ width: "160px", margin: "30px auto 0px auto" }}
            />
            <div className="subscription-modal__content">
              <FlexBox direction="column" gap={SPACING.space4} align="center">
                <Typography
                  variant="h3"
                  color={JoonUIColor.text.inverted}
                  textAlign="center"
                >
                  Welcome {user?.name ?? "to Joon!"}!
                </Typography>
                <Typography
                  variant="body"
                  color={JoonUIColor.text.inverted}
                  textAlign="center"
                >
                  Before you get started, we recommend you download our iOS app
                  if you have an iPhone since there are more features to explore
                  with better functionality!
                </Typography>
                <Button
                  text="Download iOS App"
                  style={{ width: "250px" }}
                  onClick={onClickDownloadIOS}
                />
                <Button
                  style={{ width: "250px" }}
                  onClick={onClickNoIphone}
                  buttonType="secondary"
                  text="I don't have an iPhone"
                />
                <button className="notnow-button" onClick={onClickNotNow}>
                  Not now
                </button>
              </FlexBox>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default IOSModal

export const IOSDownloadSection = () => {
  return (
    <>
      <img
        src={doterGroup}
        alt="doter-group"
        style={{ width: "160px", margin: "30px auto 0px auto" }}
      />
      <div className="subscription-modal__content">
        <div className="subscription-modal__content__title">
          Select an option to get started:
        </div>
        <div
          style={{
            fontSize: "18px",
            lineHeight: "30px",
            gap: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="ios-add-option">
            <div className="ios-add-option__id">a)</div>
            <div className="ios-add-option__content">
              Search{" "}
              <a
                onClick={() =>
                  trackAnalyticEvent(ANALYTIC_EVENTS.IOS_DOWNLOAD_LINK_CLICK)
                }
                style={{ color: JoonUIColor.semantic.info }}
                href="https://apps.apple.com/us/app/joon-behavior-improvement-app/id1482225056"
              >
                Joon: Behavior Improvement
              </a>{" "}
              in the Apple App Store (or click the link!)
            </div>
          </div>
          <div className="or-separator">
            <div style={{ background: "#2c2c2c" }}>or</div>
          </div>
          <div className="ios-add-option">
            <div className="ios-add-option__id">b)</div>
            <div className="ios-add-option__content">
              <div>Scan the QR code below:</div>
            </div>
          </div>
          <img
            src={"/images/iosQR.svg"}
            alt="iosQR"
            style={{ width: "138px" }}
          />
        </div>
      </div>
    </>
  )
}
