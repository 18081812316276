import { FlexBox, JoonUIColor, SPACING, Shadow } from "@joonapp/web-shared"

import NavLink from "./NavLink"
import CareIcon from "../icons/CareIcon"
import FamilyIcon from "../icons/FamilyIcon"
import MeIcon from "../icons/MeIcon"
import QuestsIcon from "../icons/QuestsIcon"
import RewardsIcon from "../icons/RewardsIcon"
import SettingsIcon from "../icons/SettingsIcon"

const Sidebar = () => {
  return (
    <div className={"nav-wrapper"}>
      <div
        style={{
          height: "100dvh",
          background: "white",
          position: "fixed",
          zIndex: "1000",
          boxShadow: Shadow.high,
          borderRight: `1px solid ${JoonUIColor.border.default}`,
          display: "flex",
          flexDirection: "column",
          borderRadius: "0 8px 8px 0",
          justifyContent: "space-between",
        }}
        className={"nav"}
      >
        <FlexBox direction="column" gap={SPACING.space4}>
          <div style={{ padding: SPACING.space2, position: "relative" }}>
            <img
              src="/images/logos/logo_white.svg"
              alt="Joon"
              style={{
                height: SPACING.space11,
                margin: "1.5rem 1rem 0rem 1rem",
              }}
            />
          </div>
          <FlexBox direction="column" gap={SPACING.space2}>
            <NavLink to="/me" name="Me" Icon={MeIcon} />
            <NavLink to="/care" name="Care" Icon={CareIcon} />
            <NavLink to="/quests" name="Quests" Icon={QuestsIcon} />
            <NavLink to="/rewards" name="Rewards" Icon={RewardsIcon} />
            <NavLink to="/family" name="Family" Icon={FamilyIcon} />
            <FlexBox
              align="center"
              style={{ padding: SPACING.space6, height: "14px" }}
            >
              <div
                style={{
                  width: "100%",
                  borderBottom: `1px solid ${JoonUIColor.border.default}`,
                }}
              />
            </FlexBox>

            <NavLink to="/settings" name="Settings" Icon={SettingsIcon} />
          </FlexBox>
        </FlexBox>
      </div>
    </div>
  )
}

export default Sidebar
