import { JoonUIColor } from "@joonapp/web-shared"
import "./counter.scss"

type Props = {
  value: number
  setValue: (e: any) => void
  maxValue?: number
}

const Counter = ({ value, setValue, maxValue = 100 }: Props) => {
  const increment = () =>
    value < maxValue
      ? value >= 20
        ? setValue(value + 5 - (value % 5))
        : setValue(value + 1)
      : null
  const decrement = () =>
    value > 0
      ? value > 25
        ? setValue(value - (value % 5 || 5))
        : setValue(value - 1)
      : null

  const onChangeReward = (value: number) => {
    if (isNaN(value) || value > maxValue) return
    if (value < 0) setValue(0)
    else setValue(value)
  }

  return (
    <div className="joon-counter">
      <button
        onClick={decrement}
        className={`crement ${value === 0 ? "disabled" : ""}`}
        style={{ backgroundColor: JoonUIColor.background.accent }}
      >
        <span>-</span>
      </button>
      <input
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
        className="counter-count"
        value={value}
        onChange={(e) => onChangeReward(Number(e.target.value))}
      />
      <button
        onClick={increment}
        className={`crement ${value === maxValue ? "disabled" : ""}`}
        style={{ backgroundColor: JoonUIColor.background.accent }}
      >
        <span>+</span>
      </button>
    </div>
  )
}

export default Counter
