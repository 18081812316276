import {
  FlexBox,
  JoonUIColor,
  Loader,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import { useReassignQuestModalStore } from "./ReassignQuest"
import UserAssignDifficultyCard from "./UserAssignDifficultyCard"
import { useFamilyQuery, useUserQuery } from "../../../../networking/queries"
import { getProfileDisplayName } from "../../../../util/util"
import { useQuestGroupSeries } from "../../useQuestGroups"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

const UserAssignDifficultySection = () => {
  const { user } = useUserQuery()
  const { data: family } = useFamilyQuery()
  const {
    isGrouped,
    setIsGrouped,
    selectedQuest,
    assignedUsers,
    toggleUser,
    setAssignedUsers,
  } = useAddEditQuestStore()
  const { onOpen: onOpenReassignModal } = useReassignQuestModalStore()
  const { questGroupSeries } = useQuestGroupSeries(selectedQuest)

  const isEditing = !!selectedQuest

  // Enable isGrouped if multiple users are assigned; only triggers once during edit
  // and set users to all users with quest group series
  useEffect(() => {
    if (isEditing) {
      if (!isGrouped && assignedUsers.length > 1) setIsGrouped(true)
      const userIds = questGroupSeries.map((series) => series.user_id)
      setAssignedUsers(Array.from(new Set(userIds)))
    } else {
      if (assignedUsers.length === 0) {
        const userId = family?.profiles?.[0]?.user.id
        if (userId) toggleUser(userId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questGroupSeries, isEditing])

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space2}
      style={{
        padding: SPACING.space4,
        borderBottom: `1px solid ${JoonUIColor.border.default}`,
      }}
      fullWidth
    >
      <FlexBox
        direction="row"
        align="center"
        justify="flex-start"
        fullWidth
        wrap={false}
      >
        <Typography variant="bodySmall" style={{ width: "32%" }}>
          {isEditing ? "Assigned to:" : "Assign to:"}
        </Typography>
        <FlexBox
          direction="row"
          align="center"
          justify="space-between"
          style={{ width: "68%" }}
          wrap={false}
        >
          <Typography variant="bodySmall" style={{ whiteSpace: "nowrap" }}>
            Difficulty level
          </Typography>
          <Typography variant="bodySmall" style={{ whiteSpace: "nowrap" }}>
            Reward
          </Typography>
        </FlexBox>
      </FlexBox>

      {isEditing &&
        assignedUsers.map((userId) => {
          const assignedProfile = family?.profiles?.find(
            (profile) => profile.user.id === userId
          )
          const userName = getProfileDisplayName(user, assignedProfile)
          return (
            <UserAssignDifficultyCard
              userId={userId}
              userName={userName}
              key={userId}
              onClick={() => onOpenReassignModal(userId)}
              isChecked={assignedUsers.includes(userId)}
            />
          )
        })}

      {!isEditing && family
        ? family.profiles.map((profile) => {
            const userName =
              (profile.user.name || "") +
              (profile.user.id === user?.id
                ? " (Me)"
                : profile.nickname
                  ? ` (${profile.nickname})`
                  : "")
            return (
              <UserAssignDifficultyCard
                userId={profile.user.id}
                userName={userName}
                key={profile.user.id}
                onClick={() => toggleUser(profile.user.id)}
                isChecked={assignedUsers.includes(profile.user.id)}
              />
            )
          })
        : !isEditing && (
            <FlexBox
              align="center"
              justify="center"
              style={{ width: "100%", height: "80px" }}
            >
              <Loader color={JoonUIColor.text.primaryAccent} />
            </FlexBox>
          )}
    </FlexBox>
  )
}

export default UserAssignDifficultySection
