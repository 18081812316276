import { JoonUIColor } from "@joonapp/web-shared"
import { useRef } from "react"

import useAIQuestStore from "./hooks/useAIQuestStore"
import QuestAIContent from "./QuestAIContent"
import useMediaQuery from "../../../hooks/useMediaQuery"
import useMobile from "../../../hooks/useMobile"

const QuestAISidePanel = () => {
  const { isOpen } = useAIQuestStore()
  const isMobile = useMobile()

  if (isMobile) return null

  return (
    <SidePanel isOpen={isOpen} from="right">
      <QuestAIContent />
    </SidePanel>
  )
}

export default QuestAISidePanel

interface Props {
  isOpen: boolean
  children: React.ReactNode
  from?: "left" | "right"
}

const SidePanel = ({ isOpen, children }: Props) => {
  const isMobile = useMobile()
  const isSmallScreen = useMediaQuery("(max-width: 1250px)")
  const sidebarRef = useRef<HTMLDivElement>(null)

  if (!isOpen) return null

  return (
    <div
      style={{
        display: "flex",
        position: isSmallScreen ? "absolute" : "relative",
        right: isSmallScreen ? "0" : "auto",
        zIndex: 100,
        flexDirection: "column",
        flexWrap: "nowrap",
        width: "100%",
        minWidth: isMobile ? "min(450px, 100vw)" : "375px",
        maxWidth: isMobile ? "min(450px, 100vw)" : "375px",
        height: "100dvh",
        background: JoonUIColor.background.primaryNeutral,
        borderLeft: `1px solid ${JoonUIColor.border.default}`,
        borderRight: `1px solid ${JoonUIColor.border.default}`,
        boxShadow: "-4px 6px 6px 0px rgba(0, 0, 0, 0.08)",
        overflow: "hidden",
        top: 0,
        transition: "0.2s cubic-bezier(0.16, 1, 0.3, 1)",
      }}
      ref={sidebarRef}
    >
      {children}
    </div>
  )
}
