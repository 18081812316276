import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { Button } from "@joonapp/web-shared"
import { useShallow } from "zustand/react/shallow"

import useAIQuestStore from "./questAI/hooks/useAIQuestStore"
import useQuestBoardMultiSelectStore from "./useQuestBoardMultiSelectStore"
import AnimatedStars from "../../components/animatedStars/AnimatedStars"
import useMobile from "../../hooks/useMobile"
import {
  cohorts,
  experimentNames,
  useGetExperimentCohort,
} from "../../util/experiments"

const AddAIQuestButton = () => {
  const {
    isOpen: isQuestAIOpen,
    onOpen: onOpenQuestAI,
    onClose: onCloseQuestAI,
  } = useAIQuestStore()

  const isMobile = useMobile()

  const { isMultiSelectMode } = useQuestBoardMultiSelectStore(
    useShallow((state) => ({ isMultiSelectMode: state.isMultiSelectMode }))
  )

  const cohort = useGetExperimentCohort(experimentNames.aiQuestBuilder)
  if (cohort === cohorts.control) return <></>

  if (isMultiSelectMode) return <></>

  const toggleQuestAI = () => {
    if (isQuestAIOpen) {
      onCloseQuestAI()
    } else {
      onOpenQuestAI()
    }
  }

  return (
    <Button
      onClick={toggleQuestAI}
      style={{
        position: "sticky",
        bottom: isMobile ? "142px" : "90px",
        alignSelf: "flex-end",
        marginRight: SPACING.space4,
        fontSize: "32px",
        borderRadius: SPACING.space2,
        padding: isMobile ? SPACING.space3 : SPACING.space4,
        border: "none",
        background: `linear-gradient(
          90deg,
          #f47474 10%,
          #6425ed 100%
        )`,
        height: isMobile ? "56px" : "44px",
        maxHeight: isMobile ? "56px" : "44px",
        minHeight: isMobile ? "56px" : "44px",
        width: isMobile ? "56px" : "unset",
        maxWidth: isMobile ? "56px" : "unset",
      }}
      text={
        <FlexBox
          direction="row"
          wrap={false}
          align="center"
          gap={SPACING.space2}
        >
          {isQuestAIOpen ? (
            <FontAwesomeIcon icon={faXmark} fontSize={isMobile ? 24 : 16} />
          ) : (
            <AnimatedStars animate={false} color={JoonUIColor.icon.inverted} />
          )}
          {!isMobile && (
            <Typography variant="bodyBold" color={JoonUIColor.icon.inverted}>
              {isQuestAIOpen ? "Close AI" : "Create with AI"}
            </Typography>
          )}
        </FlexBox>
      }
    />
  )
}

export default AddAIQuestButton
