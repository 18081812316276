import { Button, Modal, Typography } from "@joonapp/web-shared"
import { create } from "zustand"

import { useDoterAIInfoModalStore } from "../../pages/family/manageGameplay/doterChat/useDoterAIInfoModalStore"

interface WelcomeModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useWelcomeModalStore = create<WelcomeModalStore>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}))

const WelcomeModal = () => {
  const { isOpen, onClose } = useWelcomeModalStore()
  const { onOpen: onOpenDoterAIInfoModal } = useDoterAIInfoModalStore()

  const handleNext = () => {
    onOpenDoterAIInfoModal(true)
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}}
      mandatory
      style={{ width: "min(290px, 95vw)" }}
      animate
    >
      <div>
        <img src="/images/squelchee_welcome.png" alt="Welcome to Joonity" />
        <div className="flex flex-col items-center justify-center gap-4 p-6">
          <Typography variant="body" textAlign="center">
            To start enjoying the benefits of Joon, let’s get your kids set up!
          </Typography>
          <Button text="Next" onClick={handleNext} style={{ width: "80%" }} />
        </div>
      </div>
    </Modal>
  )
}

export default WelcomeModal
