import { CloseIcon, JoonUIColor, SPACING } from "@joonapp/web-shared"

export const CloseButton = ({
  onClick,
  style,
}: {
  onClick: () => void
  style?: React.CSSProperties
}) => {
  return (
    <button
      style={{
        position: "absolute",
        top: SPACING.space2,
        right: SPACING.space2,
        zIndex: 1000,
        ...style,
      }}
      onClick={onClick}
    >
      <CloseIcon size={24} color={JoonUIColor.icon.neutral} />
    </button>
  )
}
