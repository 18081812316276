import { useQuery } from "@tanstack/react-query"

import { getDoterChatLogs, getFlaggedChatSessions } from "./api"
import { QUERY_KEYS } from "../../constants"
import { useDoterChatModalStore } from "../../pages/family/manageGameplay/doterChat/useDoterChatModalStore"

export const useDoterChatLogsQuery = (date?: string) => {
  const { childUserId } = useDoterChatModalStore()

  return useQuery({
    queryKey: [QUERY_KEYS.DOTER_CHAT_LOGS, childUserId, date],
    queryFn: () => getDoterChatLogs({ childUserId: childUserId!, date }),
    enabled: !!childUserId,
  })
}
export const useFlaggedDoterChatLogsQuery = () => {
  const { childUserId } = useDoterChatModalStore()

  return useQuery({
    queryKey: [QUERY_KEYS.FLAGGED_DOTER_CHAT_LOGS, childUserId],
    queryFn: () => getFlaggedChatSessions(),
    select: (data) => data.filter((convo) => !!convo.message.prompt_flags),
  })
}
