import ForgotPassword from "../components/auth/ForgotPassword"
import SignIn from "../components/auth/SignIn"
import OnboardingPageWrapper from "../components/onboarding/onboardingPageWrapper/OnboardingPageWrapper"
import { BACKGROUND_COLORS } from "../constants"
import useDocumentTitle from "../hooks/useDocumentTitle"
import useMobile from "../hooks/useMobile"
import { useRouter } from "../util/router"

function AuthPage() {
  useDocumentTitle("Joon - Sign In")
  const router = useRouter()
  const isMobile = useMobile()

  const authType = router.query.type

  return (
    <OnboardingPageWrapper
      style={{
        background: isMobile
          ? "linear-gradient(to bottom, rgba(245, 250, 255, 1), rgba(210, 230, 250, 1))"
          : BACKGROUND_COLORS.xlightBlueBg,
      }}
    >
      {authType === "forgotpass" ? <ForgotPassword /> : <SignIn />}
    </OnboardingPageWrapper>
  )
}

export default AuthPage
