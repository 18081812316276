import dayjs from "dayjs"
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
} from "firebase/auth"

import { loadFamilies } from "./families/api"
import { loadUser } from "./users/api"
import { QUERY_KEYS } from "../constants"
import { ChildDataInterface } from "../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../util/analytics"
import { firebaseApp } from "../util/firebase"
import { createJoonAPIClient } from "../util/joon-api"
import { queryClient } from "../util/queryClient"
import { history } from "../util/router"
import { localStorageItems, sessionManager } from "../util/storage"

const handlePostSignin = async () => {
  // Check if they have a user account. Otherwise route to onboarding
  try {
    await loadUser()
  } catch (_) {
    history.push("/onboard")
    throw new Error("")
  }

  // If they are logging in, and have a user, but no family, create a family. Fail silently.
  try {
    const families = await loadFamilies()
    if (!families) await createFamily("Parent")
    queryClient.invalidateQueries([QUERY_KEYS.FAMILY])
  } catch (error) {
    console.error(error)
  }
}

export async function signin(email: string, password: string) {
  const joonApiClient = createJoonAPIClient()
  const response = await joonApiClient.post("api/auth/signin/", {
    email: email,
    password: password,
  })

  const refreshToken = response.data.refresh_token

  const idToken = response.data.id_token
  sessionManager.storeTokens(refreshToken, idToken)

  trackAnalyticEvent(ANALYTIC_EVENTS.PARENT_LOGIN_APPROVED)

  handlePostSignin()

  return response
}

export function signUpWithGoogle(): Promise<string> {
  const auth = getAuth(firebaseApp)
  const googleAuthProvider = new GoogleAuthProvider().setCustomParameters({
    prompt: "select_account",
  })
  return signInWithPopup(auth, googleAuthProvider).then((response) => {
    const refreshToken = response.user.refreshToken
    sessionManager.storeTokens(refreshToken, null)
    trackAnalyticEvent(ANALYTIC_EVENTS.CREATE_LOGIN, { type: "google" })
    return new Promise((resolve) => resolve(refreshToken))
  })
}

export function authentiateWithApple(
  type: "signin" | "signup"
): Promise<string> {
  const auth = getAuth(firebaseApp)
  const provider = new OAuthProvider("apple.com")

  return signInWithPopup(auth, provider).then((response) => {
    const refreshToken = response.user.refreshToken
    sessionManager.storeTokens(refreshToken, null)

    if (type === "signin") {
      handlePostSignin()
      trackAnalyticEvent(ANALYTIC_EVENTS.APPLE_LOGIN_APPROVED)
    } else {
      trackAnalyticEvent(ANALYTIC_EVENTS.CREATE_LOGIN, { type: "apple" })
    }

    return new Promise((resolve) => resolve(refreshToken))
  })
}

// With this function, I want to sign in, and then try to fetch the user.
// If there is no user, I want to show an error message.
// If there is a user, I want to store the tokens as usual
export function signInWithGoogle(): Promise<any> {
  const auth = getAuth(firebaseApp)
  const googleAuthProvider = new GoogleAuthProvider().setCustomParameters({
    prompt: "select_account",
  })
  return signInWithPopup(auth, googleAuthProvider).then(async (response) => {
    const refreshToken = response.user.refreshToken
    sessionManager.storeTokens(refreshToken, null)
    trackAnalyticEvent(ANALYTIC_EVENTS.GOOGLE_LOGIN_APPROVED)

    handlePostSignin()

    return new Promise((resolve) => resolve(refreshToken))
  })
}

export function signup(email: string, password: string) {
  const joonAPIClient = createJoonAPIClient()
  return joonAPIClient
    .post("api/auth/signup/", { email: email, password: password })
    .then((res) => {
      const refreshToken = res.data?.refresh_token
      const idToken = res.data?.id_token
      sessionManager.storeTokens(refreshToken, idToken)

      trackAnalyticEvent(ANALYTIC_EVENTS.CREATE_LOGIN, { type: "email", email })
      return res
    })
}

export function resetPassword(email: string) {
  const joonApiClient = createJoonAPIClient()
  return joonApiClient.post(`api/auth/reset-password/`, { email })
}

export function submitQuestionnaire(
  questionnaireId: number,
  userId: number,
  answers: AnswerSubmitFormat[]
) {
  const joonApiClient = createJoonAPIClient()
  return joonApiClient.post(`api/questionnaires/${questionnaireId}/response/`, {
    user_id: userId,
    answers: answers,
  })
}

interface AnswerSubmitFormat {
  question_id: number
  answer_ids?: number[]
  freeform_answer?: string
}

export const createUser = (name: string, avatar: string, lastName?: string) => {
  const joonApiClient = createJoonAPIClient()
  return joonApiClient.post("api/users/", {
    name,
    last_name: lastName,
    avatar,
    tune_id: localStorage.getItem(localStorageItems.tuneId) || undefined,
  })
}

export const createFamily = (nickname: string) => {
  const joonApiClient = createJoonAPIClient()
  return joonApiClient.post("api/families/", { nickname })
}

export const createChildren = (
  familyId: number,
  childrenData: ChildDataInterface[]
) => {
  const API = createJoonAPIClient()
  const childPromises = childrenData.map((child) => {
    const body = {
      name: child.first_name,
      birthdate: child.birthdate
        ? dayjs.unix(child.birthdate).format("YYYY-MM-DD")
        : undefined,
      gender: child.gender ?? undefined,
    }
    return API.post(`/api/families/${familyId}/create-child/`, body)
  })
  return Promise.all(childPromises)
}
