import { SizeProp } from "@fortawesome/fontawesome-svg-core"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { JoonUIColor, SPACING } from "@joonapp/web-shared"

interface ChevronBackButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  size?: SizeProp
}

const ChevronBackButton = ({
  size = "xl",
  onClick,
  style,
  ...props
}: ChevronBackButtonProps) => {
  return (
    <button
      onClick={onClick}
      style={{
        position: "absolute",
        top: SPACING.space4,
        left: SPACING.space6,
        padding: SPACING.space2,
        borderRadius: SPACING.space2,
        ...style,
      }}
      {...props}
    >
      <FontAwesomeIcon
        icon={faChevronLeft}
        color={JoonUIColor.text.secondary}
        size={size}
      />
    </button>
  )
}

export default ChevronBackButton
