import dayjs from "dayjs"
import { useCallback, useEffect } from "react"
import { create } from "zustand"

import { useAgreementLinksQuery } from "../../../networking/agreementLinks/queries"
import { useGetCoachSubscriptionQuery } from "../../../networking/coaching/queries"
import {
  useSessionBillingPeriodsQuery,
  useSessionBookingsQuery,
} from "../../../networking/sessions/queries"
import { CoachOverlayType, SubscriptionStatus } from "../../../types"
import { localStorageItems } from "../../../util/storage"

interface CoachOverlayStore {
  currentOverlay: CoachOverlayType | null
  setCurrentOverlay: (currentOverlay: CoachOverlayType | null) => void

  isOpenSessionBooking: boolean
  setIsOpenSessionBooking: (isOpenSessionBooking: boolean) => void
}

export const useCoachOverlayStore = create<CoachOverlayStore>((set) => ({
  currentOverlay: null,
  setCurrentOverlay: (currentOverlay) => set({ currentOverlay }),

  isOpenSessionBooking: true,
  setIsOpenSessionBooking: (isOpenSessionBooking) =>
    set({ isOpenSessionBooking }),
}))

const useCoachOverlay = () => {
  const { data: sessions } = useSessionBookingsQuery()
  const { data: billingPeriods } = useSessionBillingPeriodsQuery()

  const { isOpenSessionBooking, setCurrentOverlay } = useCoachOverlayStore()
  const { data: coachSubscriptionData, isLoading: isLoadingSubscription } =
    useGetCoachSubscriptionQuery()
  const { data: agreementLinks, isLoading: isLoadingAgreementLinks } =
    useAgreementLinksQuery()
  const hasExpiredSubscription =
    coachSubscriptionData?.status === SubscriptionStatus.EXPIRED &&
    !isLoadingSubscription
  const needsToSign =
    !isLoadingAgreementLinks && agreementLinks && agreementLinks.length > 0

  // Helper function to determine if session booking overlay should be shown
  const shouldShowSessionBookingOverlay = useCallback(() => {
    const lastSeenSessionBookingOverlay = localStorage.getItem(
      localStorageItems.lastSeenSessionBookingOverlay
    )
    const seenInPastDay = lastSeenSessionBookingOverlay
      ? dayjs(lastSeenSessionBookingOverlay).isAfter(dayjs().subtract(1, "day"))
      : false
    const nextSession = billingPeriods?.find((period) =>
      period.bookings.some(
        (booking) =>
          booking.end_datetime && new Date(booking.end_datetime) > new Date()
      )
    )
    return !nextSession && !seenInPastDay
  }, [billingPeriods])

  useEffect(() => {
    const showFailedPayment = hasExpiredSubscription
    const showFTUX =
      sessions &&
      sessions.length === 0 &&
      billingPeriods &&
      billingPeriods.length > 0
    const showTOS = needsToSign && !isLoadingSubscription
    const showSessionBooking =
      shouldShowSessionBookingOverlay() && isOpenSessionBooking

    if (showFailedPayment) {
      setCurrentOverlay(CoachOverlayType.EXPIRED_SUBSCRIPTION)
    } else if (showFTUX) {
      setCurrentOverlay(CoachOverlayType.FTUX)
    } else if (showTOS) {
      setCurrentOverlay(CoachOverlayType.TOS)
    } else if (showSessionBooking) {
      setCurrentOverlay(CoachOverlayType.SESSION_BOOKING)
    } else {
      setCurrentOverlay(null)
    }
  }, [
    sessions,
    billingPeriods,
    setCurrentOverlay,
    isOpenSessionBooking,
    isLoadingSubscription,
    hasExpiredSubscription,
    needsToSign,
    shouldShowSessionBookingOverlay,
  ])
}

export default useCoachOverlay
