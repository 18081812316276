import { QuestSeries } from "@joonapp/web-shared"

import { AIChatFlaggedPromptNotification, DoterChatLog } from "../../types"
import {
  QuestAIChatSessionType,
  QuestAICreateInfo,
  QuestAIResponse,
} from "../../types/quests"
import { createJoonAPIClient } from "../../util/joon-api"
import { localStorageItems } from "../../util/storage"

export const createNewChatSession = async (type: QuestAIChatSessionType) => {
  const isOnboarding = type === QuestAIChatSessionType.ONBOARDING_ROUTINE
  const chatSessionId = JSON.parse(
    localStorage.getItem(localStorageItems.chatSessionId) || "null"
  )
  if (isOnboarding && chatSessionId) return chatSessionId

  const API = createJoonAPIClient()
  return API.post<{ id: string }>("/api/chat-sessions/", {
    type,
  }).then((res) => {
    if (isOnboarding)
      localStorage.setItem(localStorageItems.chatSessionId, res.data.id)
    return res.data.id
  })
}

export const generateChatResponse = async (
  chatSessionId: string,
  prompt: string,
  uuids: string[]
) => {
  const API = createJoonAPIClient()
  return API.post<QuestAIResponse>(
    `/api/chat-sessions/${chatSessionId}/quest-builder/`,
    { prompt, uuids }
  ).then((res) => res.data)
}

export const acceptSuggestions = async (
  chatSessionId: string,
  uuids: string[]
) => {
  const API = createJoonAPIClient()
  return API.post<{ quests: QuestSeries[] }>(
    `/api/chat-sessions/${chatSessionId}/accept-suggestions/`,
    { uuids }
  ).then((res) => res.data)
}

export const getFlaggedChatSessions = async () => {
  const API = createJoonAPIClient()
  const response = await API.get<{
    results: AIChatFlaggedPromptNotification[]
  }>("/api/chat-session-flag-notifications/", {
    params: { unread: null, user_id: null },
  })
  return response.data.results
}

export const markFlaggedChatAsRead = async (id: number) => {
  const API = createJoonAPIClient()
  await API.post(`/api/chat-session-flag-notifications/${id}/read/`)
}

export const getDoterChatLogs = async ({
  childUserId,
  date,
}: {
  childUserId: number
  date?: string
}) => {
  const API = createJoonAPIClient()
  const response = await API.get<{ results: DoterChatLog[] }>(
    "/api/chat-sessions/doter-chat-logs/",
    { params: { user_id: childUserId, date } }
  )
  return response.data.results
}

export const generatePrepaywallChatResponse = async (chatSessionId: string) => {
  const prepaywallQuests = JSON.parse(
    localStorage.getItem(localStorageItems.prepaywallQuests) || "[]"
  ) as QuestAICreateInfo[]
  if (prepaywallQuests && prepaywallQuests.length > 0) return prepaywallQuests

  const API = createJoonAPIClient()
  return API.get<{ pending_create_quest_data: QuestAICreateInfo[] }>(
    `/api/chat-sessions/${chatSessionId}/onboarding-routine-builder/`
  ).then((res) => {
    localStorage.setItem(
      localStorageItems.prepaywallQuests,
      JSON.stringify(res.data.pending_create_quest_data)
    )
    return res.data.pending_create_quest_data
  })
}
