import {
  Button,
  FamilyChildProfile,
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { create } from "zustand"

import ChildAuthCode from "../../components/childAuthCode/ChildAuthCode"
import {
  ShareButton,
  ShareLink,
} from "../../components/shareButton/ShareButton"
import { QUERY_KEYS } from "../../constants"
import { useFamilyQuery } from "../../networking/queries"
import { FamilyQuerySelect } from "../../types"
import { queryClient } from "../../util/queryClient"

interface GetChildStartedModalStore {
  isOpen: boolean
  onOpen: (childId?: number) => void
  onClose: () => void
  childId?: number | null
  error: string | null
  setError: (error: string | null) => void
}

export const useGetChildStartedModal = create<GetChildStartedModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (childId?: number) => set({ isOpen: true, childId }),
    onClose: () => set({ isOpen: false, childId: null, error: null }),
    childId: null,
    error: null,
    setError: (error) => set({ error }),
  })
)

const GetChildStartedModal = () => {
  const { isOpen, onClose, childId, error, setError } =
    useGetChildStartedModal()
  const { refetch, isFetching } = useFamilyQuery(FamilyQuerySelect.CHILDREN)

  const checkIfChildHasChosenDoter = () => {
    refetch().then((query) => {
      const child = query?.data?.find(
        (child: FamilyChildProfile) => child.user.id === childId
      )
      if (!!child?.user.active_doter.type) {
        queryClient.invalidateQueries([
          QUERY_KEYS.QUEST_INSTANCE_STATS,
          child.user.id,
        ])
        onClose()
      } else setError(`Your child has not yet chosen a pet.`)
    })
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} displayCloseIcon animate>
      <FlexBox
        direction="column"
        align="center"
        style={{ width: "min(400px, 95vw)", padding: SPACING.space6 }}
        gap={SPACING.space6}
      >
        <Typography variant="h1" color={JoonUIColor.text.primaryAccent}>
          Getting the Kid App
        </Typography>
        <ShareButton />
        <FlexBox wrap={false} gap={SPACING.space2}>
          <StepNumber number={1} />
          <Typography variant="bodySmall">
            Search{" "}
            <Typography
              variant="bodySmallBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Joon Pet Game{" "}
            </Typography>
            in the Google Play Store, Apple App Store, Amazon App Store, or
            share the download link below to your child's device.
          </Typography>
        </FlexBox>
        <ShareLink />
        <FlexBox wrap={false} gap={SPACING.space2}>
          <StepNumber number={2} />
          <Typography variant="bodySmall">
            Use the code below to log them in once the app is installed.
          </Typography>
        </FlexBox>

        <div style={{ margin: `${SPACING.space3} auto` }}>
          <ChildAuthCode />
        </div>
        {!!childId && (
          <div style={{ width: "100%" }}>
            {error && (
              <Typography
                variant="bodySmallBold"
                color={JoonUIColor.semantic.alert}
                style={{ marginTop: "16px" }}
              >
                {error}
              </Typography>
            )}
            <Button
              onClick={checkIfChildHasChosenDoter}
              isLoading={isFetching}
              text="Done"
              fullWidth
              style={{ marginTop: "24px" }}
            />
          </div>
        )}
      </FlexBox>
    </Modal>
  )
}

const StepNumber = ({ number }: { number: number }) => {
  return (
    <Typography
      variant="bodySmall"
      style={{
        width: 28,
        height: 28,
        minWidth: 28,
        minHeight: 28,
        borderRadius: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        color: "white",
        backgroundColor: JoonUIColor.background.accent,
        fontSize: 15,
      }}
    >
      {number}
    </Typography>
  )
}

export default GetChildStartedModal
