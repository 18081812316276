import { faHashtag } from "@fortawesome/duotone-light-svg-icons"
import {
  ArrowRightIcon,
  Button,
  Checkbox,
  CloseIcon,
  DateInput,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextArea,
  TextButton,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import * as Sentry from "@sentry/react"
import dayjs from "dayjs"
import { useEffect, useMemo, useState } from "react"
import toast from "react-hot-toast"

import useIncidentReportStore, {
  IncidentReportStep,
} from "./useIncidentReportStore"
import Card from "../../../components/card/Card"
import SlideUpModal from "../../../components/slideUpModal/SlideUpModal"
import TimeInput from "../../../components/timeInput/TimeInput"
import TimerInput from "../../../components/timerInput/TimerInput"
import { createIncident } from "../../../networking/incidents"
import {
  useFamilyQuery,
  useIncidentTemplatesQuery,
} from "../../../networking/queries"
import { FamilyQuerySelect, IncidentLogDataType } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import CatchUpTabBar from "../CatchUpTabBar"

const IncidentReport = () => {
  const { isOpen, onClose, step } = useIncidentReportStore()

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      size="fit-content"
      backgroundType="overlay"
    >
      <CloseModalButton onClose={onClose} />
      {step === IncidentReportStep.SELECT_CHILD && <IncidentReportStep0 />}
      {step === IncidentReportStep.SELECT_BEHAVIOR && <IncidentReportStep1 />}
      {step === IncidentReportStep.SELECT_STRATEGY && <IncidentReportStep2 />}
      {step === IncidentReportStep.SUCCESS && <IncidentReportSuccess />}
    </SlideUpModal>
  )
}

export default IncidentReport

const IncidentReportStep0 = () => {
  const { setStep, selectedChildId, setSelectedChildId } =
    useIncidentReportStore()
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const { data: incidentTemplates, refetch } = useIncidentTemplatesQuery()

  const childIdsWithTemplates = useMemo(
    () =>
      incidentTemplates?.child_action_templates.map(
        (template) => template.user_id
      ) || [],
    [incidentTemplates]
  )

  useEffect(() => {
    if (childIdsWithTemplates.length === 0) {
      refetch()
    } else if (childIdsWithTemplates.length === 1) {
      setSelectedChildId(childIdsWithTemplates[0])
      setStep(1)
    } else {
      trackAnalyticEvent(ANALYTIC_EVENTS.ENTER_LOG_INCIDENT_CHILD_SELECTION)
    }
  }, [childIdsWithTemplates, setStep, setSelectedChildId, refetch])

  if (childIdsWithTemplates.length === 0) {
    return <></>
  }

  return (
    <>
      <FlexBox
        direction="column"
        wrap={false}
        style={{
          padding: SPACING.space4,
          // TODO: Replace with GRADIENT.gradient100 when MUI is merged in
          background: `linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.8) 2.83%,
            rgba(255, 255, 255, 0) 51.53%,
            rgba(255, 255, 255, 0.8) 99.81%
          ),
          #e9eafa`,
        }}
        gap={SPACING.space2}
      >
        <Typography variant="h3" style={{ marginBottom: SPACING.space2 }}>
          Which child is this in reference to?
        </Typography>
        {children?.map(
          (child) =>
            childIdsWithTemplates.includes(child.user.id) && (
              <Card
                key={child.user.id}
                title={child.user.name}
                onClick={() => setSelectedChildId(child.user.id)}
                buttonComponent={
                  <Checkbox
                    name={child.user.name}
                    selected={selectedChildId === child.user.id}
                    onChange={() => {}}
                    inputType="radio"
                    hideBorder
                  />
                }
              />
            )
        )}
      </FlexBox>
      <CatchUpTabBar>
        <FlexBox
          wrap={false}
          direction="row"
          gap={SPACING.space2}
          style={{ padding: SPACING.space2 }}
        >
          <Button
            text="Next"
            onClick={() => setStep(1)}
            fullWidth
            disabled={!selectedChildId}
          />
        </FlexBox>
      </CatchUpTabBar>
    </>
  )
}

const IncidentReportStep1 = () => {
  const {
    selectedChildId,
    selectedBehaviorId,
    setSelectedBehaviorId,
    behaviorDescription,
    setBehaviorDescription,
    incidentDate,
    setIncidentDate,
    incidentTime,
    setIncidentTime,
    incidentDuration,
    setIncidentDuration,
    setStep,
    setSelectedStrategyId,
    antecedent,
    setAntecedent,
    consequence,
    setConsequence,
    frequency,
    setFrequency,
  } = useIncidentReportStore()

  const { data: incidentTemplates, isLoading } = useIncidentTemplatesQuery()

  const templates = useMemo(
    () =>
      incidentTemplates?.child_action_templates.flatMap((template) =>
        template.user_id === selectedChildId ? template.target_behaviors : []
      ) || [],
    [incidentTemplates, selectedChildId]
  )

  const selectedBehavior = useMemo(
    () =>
      templates?.find((template) => template.id === selectedBehaviorId) || null,
    [templates, selectedBehaviorId]
  )
  const incidentLogDataType = selectedBehavior?.incident_log_data_type

  const isFreeFormBehavior = selectedBehaviorId === 0

  const hasNoBehaviorTarget = useMemo(
    () => templates.length === 0 && !isLoading,
    [templates, isLoading]
  )

  useEffect(() => {
    if (hasNoBehaviorTarget && selectedBehaviorId === null) {
      setSelectedBehaviorId(0)
      setSelectedStrategyId(0)
    }
  }, [
    hasNoBehaviorTarget,
    selectedBehaviorId,
    setSelectedBehaviorId,
    setSelectedStrategyId,
  ])

  const onClickNext = () => {
    const noBehaviorSelected = selectedBehaviorId === null
    const noDescription = selectedBehaviorId === 0 && !behaviorDescription

    if (noBehaviorSelected || noDescription)
      return toast.error("Please specify the behavior")

    if (incidentLogDataType === IncidentLogDataType.ANTECEDENT) {
      if (!antecedent || !consequence)
        return toast.error("Please specify the antecedent and consequence")
    } else if (incidentLogDataType === IncidentLogDataType.FREQUENCY) {
      if (!frequency) return toast.error("Please specify the frequency")
    } else if (incidentLogDataType === IncidentLogDataType.DURATION) {
      if (!incidentDuration) return toast.error("Please specify the duration")
    }

    setStep(2)
    trackAnalyticEvent(ANALYTIC_EVENTS.ENTER_LOG_INCIDENT_STRATEGY_DESCRIPTION)
  }

  useEffect(() => {
    trackAnalyticEvent(ANALYTIC_EVENTS.ENTER_LOG_INCIDENT_BEHAVIOR_DESCRIPTION)
  }, [])

  return (
    <>
      <FlexBox
        direction="column"
        wrap={false}
        style={{
          padding: SPACING.space4,
          // TODO: Replace with GRADIENT.gradient100 when MUI is merged in
          background: `linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.8) 2.83%,
            rgba(255, 255, 255, 0) 51.53%,
            rgba(255, 255, 255, 0.8) 99.81%
          ),
          #e9eafa`,
        }}
        gap={SPACING.space4}
      >
        <Typography variant="h3">Which behavior occured?</Typography>
        {templates?.length > 0 && (
          <FlexBox direction="column" gap={SPACING.space1}>
            {templates?.map((behavior) => (
              <Card
                key={behavior.id}
                title={behavior.title}
                wrapTitle
                onClick={() => {
                  setSelectedBehaviorId(behavior.id)
                  setBehaviorDescription("")
                }}
                buttonComponent={
                  <Checkbox
                    name={behavior.title}
                    selected={selectedBehaviorId === behavior.id}
                    onChange={() => {}}
                    inputType="radio"
                    hideBorder
                  />
                }
              />
            ))}

            <Card
              title="None of the above"
              onClick={() => {
                setSelectedBehaviorId(0)
                setSelectedStrategyId(0)
              }}
              buttonComponent={
                <Checkbox
                  name="None of the above"
                  selected={selectedBehaviorId === 0}
                  onChange={() => {}}
                  inputType="radio"
                  hideBorder
                />
              }
            />
          </FlexBox>
        )}
        {selectedBehaviorId === 0 && (
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="bodySmallBold">
              {hasNoBehaviorTarget
                ? "Specify the behavior that occurred"
                : "What happened?"}
            </Typography>
            <TextArea
              name="Behavior description"
              maxLength={1000}
              placeholder="We were watching TV but then it’s dinner time and he won’t want to go eat dinner."
              value={behaviorDescription}
              onChange={(e) => setBehaviorDescription(e.target.value)}
              showCounter
              fullWidth
            />
          </FlexBox>
        )}
        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="bodySmallBold">Incident date</Typography>
          <FlexBox
            direction="row"
            align="center"
            gap={SPACING.space1}
            wrap={false}
            justify="space-between"
          >
            <div style={{ width: "49%" }}>
              <DateInput
                modalTitle="Select date"
                name="Incident date"
                date={incidentDate}
                setDate={setIncidentDate}
                maxDate={new Date()}
                fullWidth
              />
            </div>
            <div style={{ width: "49%" }}>
              <TimeInput
                modalTitle="Select time"
                name="Incident time"
                time={incidentTime}
                setTime={setIncidentTime}
                fullWidth
                placeholder="Time"
              />
            </div>
          </FlexBox>
        </FlexBox>
        {(isFreeFormBehavior ||
          incidentLogDataType === IncidentLogDataType.DURATION) && (
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="bodySmallBold">Duration</Typography>
            <TimerInput
              modalTitle="Select duration"
              name="Incident duration"
              time={incidentDuration}
              setTime={setIncidentDuration}
              fullWidth
              placeholder="Select duration"
            />
          </FlexBox>
        )}
        {(isFreeFormBehavior ||
          incidentLogDataType === IncidentLogDataType.FREQUENCY) && (
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="bodySmallBold">Frequency</Typography>
            <TextInput
              name="Frequency"
              maxLength={3}
              icon={faHashtag}
              placeholder="How many times did this happen?"
              value={frequency || ""}
              onChange={(e) => setFrequency(e.target.value)}
              fullWidth
            />
          </FlexBox>
        )}
        {(isFreeFormBehavior ||
          incidentLogDataType === IncidentLogDataType.ANTECEDENT) && (
          <>
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="bodySmallBold">
                What happened before this incident?
              </Typography>
              <TextInput
                name="Antecedence"
                maxLength={255}
                placeholder="What is the Antecedent of this incident?"
                value={antecedent || ""}
                onChange={(e) => setAntecedent(e.target.value)}
                fullWidth
              />
            </FlexBox>
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="bodySmallBold">
                What happened after this incident?
              </Typography>
              <TextInput
                name="Consequence"
                maxLength={255}
                placeholder="What is the Consequence of this incident?"
                value={consequence || ""}
                onChange={(e) => setConsequence(e.target.value)}
                fullWidth
              />
            </FlexBox>
          </>
        )}
      </FlexBox>
      {/* TODO: need to refactor catchuptabbar because it has a store dependency */}
      <CatchUpTabBar>
        <FlexBox
          wrap={false}
          direction="row"
          gap={SPACING.space2}
          style={{ padding: SPACING.space2 }}
        >
          <Button text="Next" onClick={onClickNext} fullWidth />
        </FlexBox>
      </CatchUpTabBar>
    </>
  )
}

const IncidentReportStep2 = () => {
  const {
    setStep,
    selectedBehaviorId,
    selectedStrategyId,
    setSelectedStrategyId,
    strategyDescription,
    setStrategyDescription,

    selectedChildId,
    behaviorDescription,
    incidentDuration,
    incidentDate,
    incidentTime,

    antecedent,
    consequence,
    frequency,
  } = useIncidentReportStore()

  const { data: incidentTemplates } = useIncidentTemplatesQuery()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const strategies =
    incidentTemplates?.child_action_templates
      ?.flatMap((template) => template.target_behaviors)
      .filter((targetBehavior) => targetBehavior.id === selectedBehaviorId)
      .flatMap((targetBehavior) => targetBehavior.preventive_strategies) || []

  const templates = useMemo(
    () =>
      incidentTemplates?.child_action_templates?.flatMap((template) =>
        template.user_id === selectedChildId ? template.target_behaviors : []
      ) || [],
    [incidentTemplates, selectedChildId]
  )

  const selectedBehavior = useMemo(
    () =>
      templates?.find((template) => template.id === selectedBehaviorId) || null,
    [templates, selectedBehaviorId]
  )
  const incidentLogDataType = selectedBehavior?.incident_log_data_type

  const onSubmitIncidentReport = async () => {
    let datetime
    try {
      const noStrategySelected = selectedStrategyId === null
      const noDescription = selectedStrategyId === 0 && !strategyDescription

      if (noStrategySelected || noDescription) {
        return toast.error("Please specify your strategy")
      }

      if (incidentDate && incidentTime) {
        const parsedDate = dayjs(incidentDate).format("YYYY-MM-DD")
        const incidentTime24H = convertTo24Hour(incidentTime)
        const combinedDateTime = `${parsedDate}T${incidentTime24H}`
        datetime = dayjs(combinedDateTime).toISOString()
      } else if (incidentDate) {
        datetime = dayjs(incidentDate).toISOString()
      } else {
        return toast.error("Please select a date")
      }
      if (!selectedChildId) {
        return toast.error("No child selected")
      }

      if (incidentLogDataType === IncidentLogDataType.ANTECEDENT) {
        if (!antecedent || !consequence) {
          return toast.error("Please specify the antecedent and consequence")
        }
      } else if (incidentLogDataType === IncidentLogDataType.FREQUENCY) {
        if (!frequency) {
          return toast.error("Please specify the frequency")
        }
      } else if (incidentLogDataType === IncidentLogDataType.DURATION) {
        if (!incidentDuration) {
          return toast.error("Please specify the duration")
        }
      }

      setIsSubmitting(true)

      await createIncident({
        userId: selectedChildId,
        targetBehaviorId: selectedBehaviorId || undefined,
        freeformTargetBehavior:
          selectedBehaviorId === 0 ? behaviorDescription : undefined,
        strategyId: selectedStrategyId || undefined,
        freeformPreventiveStrategy: strategyDescription || undefined,
        incidentDateTime: datetime,
        duration: incidentDuration || undefined,
        antecedent: antecedent || undefined,
        consequence: consequence || undefined,
        frequency: frequency || undefined,
      })
      setIsSubmitting(false)
      setStep(3)
      trackAnalyticEvent(ANALYTIC_EVENTS.SUBMIT_INCIDENT_LOG)
    } catch (error) {
      Sentry.captureException(error)
      toast.error("Failed to submit incident report. Please try again.")
    }
  }

  return (
    <>
      <FlexBox
        direction="column"
        wrap={false}
        style={{
          padding: SPACING.space4,
          // TODO: Replace with GRADIENT.gradient100 when MUI is merged in
          background: `linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.8) 2.83%,
            rgba(255, 255, 255, 0) 51.53%,
            rgba(255, 255, 255, 0.8) 99.81%
          ),
          #e9eafa`,
        }}
        gap={SPACING.space4}
      >
        <FlexBox direction="row" align="center" gap={SPACING.space1}>
          <TextButton
            onClick={() => setStep(1)}
            style={{ transform: "rotate(180deg)", padding: SPACING.space1 }}
          >
            <ArrowRightIcon size={20} color={JoonUIColor.icon.neutral} />
          </TextButton>
          <Typography variant="h3"> What strategy did you try?</Typography>
        </FlexBox>
        {strategies?.length > 0 && (
          <FlexBox direction="column" gap={SPACING.space1}>
            {strategies.map((strategy) => (
              <Card
                key={strategy.id}
                title={strategy.title}
                wrapTitle
                onClick={() => {
                  setSelectedStrategyId(strategy.id)
                  setStrategyDescription("")
                }}
                buttonComponent={
                  <Checkbox
                    name={strategy.title}
                    selected={selectedStrategyId === strategy.id}
                    onChange={() => {}}
                    inputType="radio"
                    hideBorder
                  />
                }
              />
            ))}
            {selectedBehaviorId !== 0 && (
              <Card
                title="None of the above"
                onClick={() => setSelectedStrategyId(0)}
                buttonComponent={
                  <Checkbox
                    name="None of the above"
                    selected={selectedStrategyId === 0}
                    onChange={() => {}}
                    inputType="radio"
                    hideBorder
                  />
                }
              />
            )}
          </FlexBox>
        )}
        {(selectedStrategyId === 0 || selectedBehaviorId === 0) && (
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="bodySmallBold">
              {selectedBehaviorId === 0
                ? "Describe your actions to help address it"
                : "What did you try instead?"}
            </Typography>
            <TextArea
              name="Other strategy"
              maxLength={1000}
              showCounter={true}
              placeholder="eg. I tried the countdown strategy but it didn’t work..."
              value={strategyDescription}
              onChange={(e) => setStrategyDescription(e.target.value)}
              fullWidth
            />
          </FlexBox>
        )}
      </FlexBox>
      <CatchUpTabBar>
        <FlexBox
          wrap={false}
          direction="row"
          gap={SPACING.space2}
          style={{ padding: SPACING.space2 }}
        >
          <Button
            text="Submit"
            onClick={onSubmitIncidentReport}
            fullWidth
            isLoading={isSubmitting}
          />
        </FlexBox>
      </CatchUpTabBar>
    </>
  )
}

const IncidentReportSuccess = () => {
  const { onClose, resetIncidentReport } = useIncidentReportStore()

  return (
    <FlexBox
      direction="column"
      wrap={false}
      style={{
        padding: SPACING.space12,
        // TODO: Replace with GRADIENT.gradient100 when MUI is merged in
        background: `linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.8) 2.83%,
        rgba(255, 255, 255, 0) 51.53%,
        rgba(255, 255, 255, 0.8) 99.81%
      ),
      #e9eafa`,
      }}
      align="center"
      justify="center"
      gap={SPACING.space12}
    >
      <div style={{ fontSize: "64px" }}>🫶</div>
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        style={{ marginBottom: SPACING.space14 }}
      >
        <Typography variant="h3" textAlign="center">
          Thank you for recording this incident
        </Typography>
        <Typography
          variant="body"
          color={JoonUIColor.text.secondary}
          textAlign="center"
        >
          Keeping track of these incidents will help you and your clinician work
          together to see how the different strategies are working as well as
          highlighting all the wonderful improvements your child is showing.
        </Typography>
      </FlexBox>
      <FlexBox direction="column" gap={SPACING.space4} align="center">
        <Button
          text="Report another incident"
          fullWidth
          onClick={() => {
            trackAnalyticEvent(ANALYTIC_EVENTS.LOG_ANOTHER_INCIDENT)
            resetIncidentReport()
          }}
        />
        <TextButton onClick={onClose}>
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            Return home
          </Typography>
        </TextButton>
      </FlexBox>
    </FlexBox>
  )
}

const CloseModalButton = ({ onClose }: { onClose: () => void }) => {
  return (
    <TextButton
      onClick={onClose}
      style={{
        padding: SPACING.space1,
        position: "absolute",
        top: SPACING.space4,
        right: SPACING.space4,
        background: JoonUIColor.background.lightGray,
        borderRadius: "50%",
      }}
    >
      <CloseIcon size={16} color={JoonUIColor.text.secondary} />
    </TextButton>
  )
}
const convertTo24Hour = (time: string | null) => {
  if (!time) return time
  const timeParts = time?.match(/(\d+):(\d+)\s*(AM|PM)/i) || []
  let hours = parseInt(timeParts[1], 10)
  const minutes = parseInt(timeParts[2], 10)
  const period = timeParts[3].toUpperCase()

  if (period === "PM" && hours !== 12) {
    hours += 12
  } else if (period === "AM" && hours === 12) {
    hours = 0
  }

  const updatedTime = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`

  return updatedTime
}
