import {
  Button,
  CheckIcon,
  CloseIcon,
  DownloadIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  PDFIcon,
  QuestInstance,
  QuestStatus,
  QuestType,
  SPACING,
  Shadow,
  Slider,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import { useMemo, useState } from "react"
import toast from "react-hot-toast"

import useParentTasksModalStore from "./useParentTasksModalStore"
import useTaskReflectionModalStore from "./useTaskReflectionModalStore"
import SlideUpModal from "../../../components/slideUpModal/SlideUpModal"
import { QUERY_KEYS, completeQuestStatuses } from "../../../constants"
import {
  useParentTaskInstancesQuery,
  useParentTaskQuery,
} from "../../../networking/queries"
import { completeQuest } from "../../../networking/quests"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { getReadableFileSize } from "../../../util/util"
import CatchUpTabBar from "../CatchUpTabBar"
import TimeOfDayCard from "../TimeOfDayCard"

const TaskReflectionModal = () => {
  const { isOpen, onClose, showCard, view } = useTaskReflectionModalStore()
  const { data: parentTasks } = useParentTaskQuery({ enabled: isOpen })

  if (!parentTasks) return <></>

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      size="fit-content"
      backgroundType="overlay"
      showCard={showCard}
    >
      <button
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          padding: SPACING.space2,
        }}
        onClick={onClose}
      >
        <CloseIcon color={JoonUIColor.icon.neutral} />
      </button>
      {view === "details" ? <ParentTaskDetails /> : <ParentTaskReflection />}
    </SlideUpModal>
  )
}

const ParentTaskDetails = () => {
  const { task, setShowCard, setView } = useTaskReflectionModalStore()
  const { data: taskInstances } = useParentTaskInstancesQuery({
    maxDate: dayjs().format("YYYY-MM-DD"),
    minDate: dayjs().subtract(1, "week").format("YYYY-MM-DD"),
  })
  const completedSeriesInstances = taskInstances?.filter(
    (instance: QuestInstance) =>
      instance.series.id === task?.series.id &&
      completeQuestStatuses.includes(instance.status)
  )

  const completionText = useMemo(() => {
    const output = {} as { text: string; subtext: string }
    if (!task) return output
    if (task?.series.type === QuestType.REPEATING) {
      output.text = completedSeriesInstances
        ? `${completedSeriesInstances.length}x`
        : "--"
      output.subtext = "Last 7 days"
    } else if (completeQuestStatuses.includes(task?.status)) {
      if (!task.completion_date) return output
      output.text = dayjs.unix(+task.completion_date).format("MM/DD/YY")
      output.subtext = "Completion date"
    } else {
      output.text = dayjs.unix(task.series.date_created).format("MM/DD/YY")
      output.subtext = "Date assigned"
    }
    return output
  }, [task, completedSeriesInstances])

  if (!task) return <></>

  const isCompletedToday = completeQuestStatuses.includes(task?.status)

  const goToTaskReflection = () => {
    setShowCard(false)
    setTimeout(() => {
      setShowCard(true)
      setView("reflection")
    }, 300)
    trackAnalyticEvent(ANALYTIC_EVENTS.PARENT_TASKS_INITIATE_COMPLETE)
  }

  return (
    <>
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{ padding: SPACING.space6 }}
      >
        <TimeOfDayCard
          title={task?.series.title}
          routine={task?.series.routine}
          date={dayjs.unix(task?.series.date_created).format("YYYY-MM-DD")}
        />
        <Typography variant="bodySmall">{task?.series.description}</Typography>
        {task?.series?.resource && (
          <a
            style={{
              padding: SPACING.space3,
              borderRadius: SPACING.space2,
              border: `1px solid ${JoonUIColor.border.default}`,
              marginTop: 0,
              width: "100%",
              textDecoration: "none",
              display: "flex",
              justifyContent: "space-between",
              flex: 1,
              alignItems: "center",
            }}
            href={task?.series?.resource?.resource_url}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.stopPropagation()
              trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_PARENT_TASK_RESOURCE)
            }}
          >
            <FlexBox direction="row" align="center" gap={SPACING.space2}>
              <PDFIcon color={JoonUIColor.icon.neutral} size={24} />
              <div>
                <Typography
                  variant="bodySmallBold"
                  color={JoonUIColor.text.primary}
                >
                  {task?.series?.resource?.title}
                </Typography>
                <Typography
                  variant="bodyXSmall"
                  color={JoonUIColor.text.secondary}
                  textAlign="left"
                >
                  {getReadableFileSize(task?.series?.resource?.resource_size)}
                </Typography>
              </div>
            </FlexBox>
            <DownloadIcon />
          </a>
        )}
      </FlexBox>
      <CatchUpTabBar>
        <FlexBox
          direction="row"
          align="center"
          wrap={false}
          style={{ padding: SPACING.space2 }}
        >
          <FlexBox
            direction="column"
            align="flex-end"
            style={{ width: "40%", marginRight: SPACING.space6 }}
          >
            <Typography variant="bodyBold" textAlign="right">
              {completionText.text}
            </Typography>
            <Typography variant="bodyXSmall" textAlign="right">
              {completionText.subtext}
            </Typography>
          </FlexBox>
          <FlexBox
            direction="column"
            align="center"
            gap={SPACING.space2}
            style={{
              padding: `${SPACING.space2} ${SPACING.space4}`,
              borderRadius: SPACING.space2,
              background: isCompletedToday
                ? JoonUIColor.background.lightGray
                : JoonColorExpanded.viridian100,
              flex: 1,
              boxShadow: isCompletedToday ? "none" : Shadow.medium,
              cursor: isCompletedToday ? "default" : "pointer",
            }}
            onClick={isCompletedToday ? undefined : goToTaskReflection}
          >
            {isCompletedToday ? (
              <>
                <Typography variant="h3">🎉</Typography>
                <Typography variant="bodyBold" color={JoonUIColor.icon.neutral}>
                  Completed!
                </Typography>
              </>
            ) : (
              <>
                <CheckIcon color={JoonUIColor.semantic.success} size={24} />
                <Typography
                  variant="bodyBold"
                  color={JoonUIColor.semantic.success}
                >
                  Mark complete
                </Typography>
              </>
            )}
          </FlexBox>
        </FlexBox>
      </CatchUpTabBar>
    </>
  )
}

const ParentTaskReflection = () => {
  const { task, onClose } = useTaskReflectionModalStore()
  const { onOpen: openParentTasksModal } = useParentTasksModalStore()
  const [difficulty, setDifficulty] = useState<number | null>(null)
  const [notes, setNotes] = useState<string>("")
  const [isSaving, setIsSaving] = useState(false)

  const queryClient = useQueryClient()

  if (!task) return <></>

  const onClickSave = async () => {
    setIsSaving(true)
    try {
      // Mark the task as complete
      await completeQuest({
        instanceId: task.id,
        completionDifficulty: difficulty !== null ? difficulty : undefined,
        completionNotes: notes,
      })
      task.status = QuestStatus.COMPLETED
      queryClient.invalidateQueries([QUERY_KEYS.PARENT_TASKS])
      queryClient.invalidateQueries([QUERY_KEYS.TRAININGS])
      queryClient.invalidateQueries([QUERY_KEYS.PARENT_TASK_INSTANCES])
      trackAnalyticEvent(ANALYTIC_EVENTS.PARENT_TASKS_FINISH_COMPLETE)
      toast.success("Task complete!")
    } catch (error) {
      toast.error("Failed to save reflection.")
    } finally {
      setIsSaving(false)
      onClose()
      if (!task.series.assigner_profile?.user_id) return
      openParentTasksModal(task.series.assigner_profile?.user_id)
    }
  }

  return (
    <>
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{ padding: SPACING.space6 }}
      >
        <TimeOfDayCard
          title={task?.series.title}
          routine={task?.series.routine}
          date={dayjs.unix(task?.series.date_created).format("YYYY-MM-DD")}
        />

        <FlexBox direction="column" gap={SPACING.space1} wrap={false}>
          <Typography variant="bodySmallBold">How did it go?</Typography>
          <Slider
            options={[6, 5, 4, 3, 2, 1, 0]}
            value={difficulty}
            setValue={setDifficulty}
            minLabel="Terrible"
            maxLabel="Amazing"
          />
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space2} wrap={false}>
          <div>
            <Typography variant="bodySmallBold" textAlign="left">
              Any reflections you'd like to share?
            </Typography>
            <Typography
              variant="bodyXSmall"
              color={JoonUIColor.text.secondary}
              textAlign="left"
            >
              This will be securely shared only with your therapist.
            </Typography>
          </div>
          <TextInput
            name="Notes"
            fullWidth
            value={notes}
            placeholder="What went well? What could be better?"
            onChange={(e) => setNotes(e.target.value)}
          />
        </FlexBox>
      </FlexBox>
      <CatchUpTabBar>
        <FlexBox
          direction="row"
          gap={SPACING.space2}
          wrap={false}
          align="center"
          style={{ padding: SPACING.space2 }}
        >
          <button
            style={{
              padding: `${SPACING.space2} ${SPACING.space8}`,
              width: "fit-content",
            }}
            onClick={onClose}
          >
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </button>
          <Button
            text="Save"
            fullWidth
            onClick={onClickSave}
            isLoading={isSaving}
          />
        </FlexBox>
      </CatchUpTabBar>
    </>
  )
}

export default TaskReflectionModal
