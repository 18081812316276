import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { Button, FlexBox, SPACING, Typography } from "@joonapp/web-shared"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"

import { JooniperAssignBar } from "./JooniperAssignBar"
import { JooniperDraftRewardCard } from "./JooniperDraftRewardCard"
import { JooniperSuccessRewardCard } from "./JooniperSuccessRewardCard"
import { useJooniperPrepaywallModalStore } from "./useJooniperPrepaywallModalStore"
import { useAddRewardMutation } from "../../../networking/rewards/mutations"
import { useOnboardingRewardTemplatesQuery } from "../../../networking/rewards/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

const JooniperRewardSetup = () => {
  const { data: onboardingRewardTemplates, isLoading } =
    useOnboardingRewardTemplatesQuery()
  const [isSetUp, setIsSetUp] = useState(false)
  const {
    onClose,
    selectedRewardTemplateIds,
    setSelectedRewardTemplateIds,
    assignedRewards,
    addAssignedReward,
  } = useJooniperPrepaywallModalStore()
  const addRewardMutation = useAddRewardMutation()

  const onApproveSelected = async () => {
    try {
      const promises = selectedRewardTemplateIds.map(
        async (rewardTemplateId) => {
          const rewardTemplate = onboardingRewardTemplates?.find(
            (reward) => reward.id === rewardTemplateId
          )
          if (!rewardTemplate) return null
          trackAnalyticEvent(ANALYTIC_EVENTS.CREATE_CUSTOM_REWARD, {
            currency: rewardTemplate.currency,
          })
          addAssignedReward({
            id: rewardTemplate.id,
            user_id: rewardTemplate.user_ids[0],
            title: rewardTemplate.title,
            cost: rewardTemplate.cost,
            emoji: rewardTemplate.emoji,
            currency: rewardTemplate.currency,
            user_ids: rewardTemplate.user_ids,
          })
          return addRewardMutation.mutateAsync({
            title: rewardTemplate.title,
            cost: rewardTemplate.cost,
            emoji: rewardTemplate.emoji,
            purchasable_from_joonity: true,
            currency: rewardTemplate.currency,
            user_ids: rewardTemplate.user_ids,
          })
        }
      )

      await Promise.all(promises)
      trackAnalyticEvent(ANALYTIC_EVENTS.PREPAYWALL_REWARDS_ASSIGNED)

      setIsSetUp(true)
      toast.success(
        `Reward${selectedRewardTemplateIds.length > 1 ? "s" : ""} created successfully!`
      )
    } catch (error) {
      console.error("Error creating rewards:", error)
      toast.error("An error occurred while creating rewards.")
    }
  }

  useEffect(() => {
    if (isLoading) return
    trackAnalyticEvent(ANALYTIC_EVENTS.PREPAYWALL_REWARDS_SEEN)
    setSelectedRewardTemplateIds(
      onboardingRewardTemplates?.map((reward) => reward.id) ?? []
    )
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FlexBox
        direction="column"
        gap={SPACING.space6}
        justify="flex-start"
        align="flex-start"
        wrap={false}
        style={{
          padding: SPACING.space4,
          opacity: isLoading ? 0 : 1,
          transition: "opacity 0.2s ease-in-out",
          height: "100%",
        }}
      >
        <FlexBox
          direction="column"
          gap={SPACING.space2}
          justify="flex-start"
          align="flex-start"
          wrap={false}
        >
          <Typography variant="h3" textAlign="left">
            {isSetUp ? "Family Rewards Set Up!" : "Set up Family Rewards"}
          </Typography>
          <Typography variant="body" textAlign="left">
            {isSetUp
              ? "You're ready to explore the rest of Joon! Tap below to continue!"
              : "Based on what your family finds motivating, we recommend setting up the following Rewards! You can always modify this later!"}
          </Typography>
        </FlexBox>
        <FlexBox
          direction="column"
          gap={SPACING.space2}
          justify="flex-start"
          align="center"
          style={{ paddingBottom: "100px" }}
          wrap={false}
        >
          {isSetUp
            ? assignedRewards.map((reward) => (
                <JooniperSuccessRewardCard key={reward.title} reward={reward} />
              ))
            : onboardingRewardTemplates?.map((reward) => (
                <JooniperDraftRewardCard key={reward.id} reward={reward} />
              ))}
        </FlexBox>
      </FlexBox>
      {isSetUp ? (
        <FlexBox
          style={{
            zIndex: 1000,
            position: "fixed",
            bottom: 0,
            padding: SPACING.space4,
          }}
          fullWidth
        >
          <Button
            onClick={onClose}
            text="Take me to the app!"
            rightIcon={faChevronRight}
            fullWidth
          />
        </FlexBox>
      ) : (
        <JooniperAssignBar
          assignLabel="Set up selected"
          onAssignClick={onApproveSelected}
          assignDisabled={
            !onboardingRewardTemplates ||
            selectedRewardTemplateIds.length < 1 ||
            addRewardMutation.isLoading
          }
          dontAssignLabel="Don't set any up"
          onDontAssignClick={onClose}
          dontAssignDisabled={addRewardMutation.isLoading}
        />
      )}
    </>
  )
}

export default JooniperRewardSetup
