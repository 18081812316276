import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  Modal,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import { useParentQuestFTUXModalStore } from "./useParentQuestFTUXModalStore"
import { parentQuestItems } from "../../../constants"
import { useUserQuery } from "../../../networking/queries"
import { ANALYTIC_EVENTS } from "../../../util/analytics"
import { trackAnalyticEvent } from "../../../util/analytics"
import { useAddEditQuestStore } from "../addEditQuestModal/useAddEditQuestStore"

export const ParentQuestFTUXModal = () => {
  const { onOpen: openAddEditQuestModal, toggleUser } = useAddEditQuestStore()
  const { isOpen, onClose } = useParentQuestFTUXModalStore()
  const { user } = useUserQuery()

  const onCheckItOut = () => {
    if (user?.id) toggleUser(user.id)
    openAddEditQuestModal()
    trackAnalyticEvent(ANALYTIC_EVENTS.EMPTY_STATE_CREATE_CUSTOM, {
      type: "quest",
    })
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{
        width: "min(450px, 100%)",
        background: JoonUIColor.background.xlightGray,
        overflowY: "auto",
      }}
      animate
    >
      <FlexBox
        direction="column"
        style={{
          padding: `${SPACING.space6} ${SPACING.space6} ${SPACING.space4}`,
        }}
      >
        <Typography
          variant="bodyXSmall"
          style={{
            borderRadius: SPACING.space1,
            background: JoonColorExpanded.yellow300,
            padding: `${SPACING.space1} ${SPACING.space2}`,
            fontWeight: 600,
            marginBottom: SPACING.space2,
          }}
        >
          New from Joon!
        </Typography>

        <Typography variant="h3" textAlign="left">
          Join in on the fun - parents can now also participate in Quests!
        </Typography>
      </FlexBox>
      <img
        src={"/images/parent_quests_ftux.png"}
        alt="Parent Quests"
        style={{
          userSelect: "none",
          pointerEvents: "none",
        }}
      />
      <FlexBox
        direction="column"
        align="flex-start"
        gap={SPACING.space3}
        wrap={false}
        style={{
          padding: `${SPACING.space4} ${SPACING.space6} ${SPACING.space6}`,
        }}
      >
        {parentQuestItems.map((item, i) => (
          <FlexBox key={i} direction="row" gap={SPACING.space2} wrap={false}>
            <FontAwesomeIcon icon={item.icon} color={JoonUIColor.icon.accent} />
            <FlexBox direction="column" gap={SPACING.space1} wrap={false}>
              <Typography variant="bodySmallBold">{item.title}</Typography>
              <Typography variant="bodySmall">{item.description}</Typography>
            </FlexBox>
          </FlexBox>
        ))}
      </FlexBox>
      <FlexBox
        style={{
          padding: `${SPACING.space0} ${SPACING.space6} ${SPACING.space6}`,
        }}
      >
        <Button
          buttonType="primary"
          onClick={onCheckItOut}
          text="Check it out"
          fullWidth
        />
      </FlexBox>
    </Modal>
  )
}
