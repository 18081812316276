import {
  faArrowsRotate,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Shadow,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"

import { useCatchUp } from "./useCatchUpStore"
import { localStorageItems } from "../../util/storage"
import {
  CoachUpsellModalType,
  useCoachUpsellModalStore,
} from "../care/CoachUpsellModal"
import useCareTeam from "../care/useCareTeam"

const CatchUpTabBarReviewButtons = () => {
  const { onClickApproveQuest, onClickRejectQuest, onClickRetryQuest } =
    useCatchUp()
  const { hasCoach } = useCareTeam()
  const { onOpen: openCoachUpsellModal } = useCoachUpsellModalStore()

  const onRejectClick = () => {
    onClickRejectQuest()
    if (hasCoach) return
    const lastSeenUpsellModalDate = localStorage.getItem(
      localStorageItems.rejectCoachUpsellModalSeenDate
    )
    const needsToSeeRejectCoachUpsellModal =
      !dayjs().isSame(dayjs(lastSeenUpsellModalDate), "week") ||
      !lastSeenUpsellModalDate
    if (needsToSeeRejectCoachUpsellModal)
      openCoachUpsellModal(CoachUpsellModalType.reject)
  }

  return (
    <>
      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space2}
        style={{ flex: 0.5, cursor: "pointer" }}
        onClick={onRejectClick}
      >
        <FontAwesomeIcon
          icon={faThumbsDown}
          color={JoonUIColor.semantic.alert}
          fontSize="24px"
        />
        <Typography variant="bodyBold" color={JoonUIColor.icon.neutral}>
          Reject
        </Typography>
      </FlexBox>
      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space2}
        style={{ flex: 0.5, cursor: "pointer" }}
        onClick={onClickRetryQuest}
      >
        <FontAwesomeIcon
          icon={faArrowsRotate}
          color={JoonUIColor.icon.accent}
          fontSize="24px"
        />
        <Typography variant="bodyBold" color={JoonUIColor.icon.neutral}>
          Retry
        </Typography>
      </FlexBox>
      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space2}
        onClick={onClickApproveQuest}
        style={{
          padding: `${SPACING.space2} ${SPACING.space4}`,
          borderRadius: SPACING.space2,
          background: JoonColorExpanded.viridian100,
          boxShadow: Shadow.medium,
          cursor: "pointer",
          flex: 1,
        }}
      >
        <FontAwesomeIcon
          icon={faThumbsUp}
          color={JoonUIColor.semantic.success}
          fontSize="24px"
        />
        <Typography variant="bodyBold" color={JoonUIColor.semantic.success}>
          Approve
        </Typography>
      </FlexBox>
    </>
  )
}

export default CatchUpTabBarReviewButtons
