import { CoachProfile, FlexBox, SPACING } from "@joonapp/web-shared"
import { useEffect } from "react"

import CoachQuestionnaire from "./CoachQuestionnaire"
import CoachSummary from "./CoachSummary"
import Matching from "./Matching"
import PlanSelect from "./PlanSelect"
import PostAnswerScreen from "./PostAnswerScreen"
import PostSelectionQuestionnaire from "./PostSelectionQuestionnaire"
import RecommendedCoaches from "./RecommendedCoaches"
import BackButton from "../../../components/buttons/BackButton"
import JoinParentsTextBox from "../../../components/coaching/JoinParentsTextBox"
import TransitionWrapper from "../../../components/coaching/TransitionWrapper"
import { CoachPaywallChoice } from "../../../constants"
import useMobile from "../../../hooks/useMobile"
import {
  QuestionAnswerMappingInterface,
  QuestionnairesInterface,
} from "../../../types"
import { CoachingStep } from "../../../types/coachingGroups"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import {
  setNumPreferredSessions,
  submitCoachQuestionnaire,
} from "../../../util/questionnaires"
import { useRouter } from "../../../util/router"
import { localStorageItems } from "../../../util/storage"
import CoachLandingPage from "../coachingFlow/CoachLandingPage"
import ScheduledPage from "../ScheduledPage"
import { useCoachingStore } from "../useCoachingStore"
import useIsFromMOF from "../useIsFromMOF"

const CoachingFlow = () => {
  const {
    step,
    setStep,
    questionnaireStep,
    setQuestionnaireStep,
    setSelectedCoach,
    sourcePage,
  } = useCoachingStore()
  const router = useRouter()
  const isFromMOF = useIsFromMOF()
  const isMobile = useMobile()

  const showBackButton =
    step > CoachingStep.LANDING &&
    step < CoachingStep.PLAN_SELECT &&
    step !== CoachingStep.MATCHING &&
    step !== CoachingStep.COACH_SUMMARY &&
    step !== CoachingStep.RECOMMENDED_COACHES

  const onBackClick = () => {
    if (step === CoachingStep.POST_ANSWER_SCREEN) {
      setQuestionnaireStep(questionnaireStep - 1)
      setStep(CoachingStep.INTAKE_FORM)
    } else if (step === CoachingStep.INTAKE_FORM && questionnaireStep > 0) {
      setQuestionnaireStep(questionnaireStep - 1)
    } else {
      setStep(step - 1)
    }
  }

  const onConfirmCoachQuestionnaire = (
    parentId: number,
    coachQuestionnaire: QuestionnairesInterface,
    questionAnswerMapping: QuestionAnswerMappingInterface
  ) => {
    // set number of preferredsessions to default to on plan slider
    setNumPreferredSessions(questionAnswerMapping)

    submitCoachQuestionnaire(
      parentId,
      coachQuestionnaire,
      questionAnswerMapping
    )
  }

  const onConfirmPostSelectionQuestionnaire = (
    parentId: number,
    postSelectionQuestionnaire: QuestionnairesInterface,
    questionAnswerMapping: QuestionAnswerMappingInterface
  ) =>
    submitCoachQuestionnaire(
      parentId,
      postSelectionQuestionnaire,
      questionAnswerMapping
    )

  useEffect(() => {
    if (router.query.stripe_success === "false") {
      router.replace(
        `/coaching${router.query.from ? `?from=${router.query.from}` : ""}`
      )
      const coachChoiceBeforeStripe = JSON.parse(
        localStorage.getItem(localStorageItems.coachChoiceStripeCheckout) ||
          "null"
      ) as CoachProfile | null
      if (coachChoiceBeforeStripe) {
        setSelectedCoach(coachChoiceBeforeStripe)
        setStep(CoachingStep.PLAN_SELECT)
      } else setStep(CoachingStep.RECOMMENDED_COACHES) // should never happen
    } else if (router.query.stripe_success === "true") {
      router.replace(
        `/coaching${router.query.from ? `?from=${router.query.from}` : ""}`
      )
      setStep(CoachingStep.SCHEDULED)
    } else if (
      localStorage.getItem(localStorageItems.planChoiceFromPaywall) ===
      CoachPaywallChoice.WITH_COACH // with coach sends to matching, exit with coach sends to landing
    ) {
      setStep(CoachingStep.MATCHING)
    } else {
      trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_COACH_ONBOARDING_START, {
        is_tof: !isFromMOF,
        from_popup: router.query.popup === "true",
        bundle_joonity:
          localStorage.getItem(localStorageItems.planChoiceFromPaywall) ===
            CoachPaywallChoice.WITH_COACH ||
          localStorage.getItem(localStorageItems.planChoiceFromPaywall) ===
            CoachPaywallChoice.EXIT_WITH_COACH,
      })
      router.replace(
        `/coaching${router.query.from ? `?from=${router.query.from}` : ""}`
      )
      if (sourcePage === "coach-onboard") {
        setStep(CoachingStep.MATCHING)
      } else {
        setStep(CoachingStep.LANDING)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FlexBox
      direction="column"
      align="center"
      justify="space-between"
      gap={SPACING.space2}
      wrap={false}
      style={{
        position: "relative",
        width: "min(450px, 100%)",
        maxWidth: "100vw",

        ...(isMobile
          ? {
              height: "calc(100dvh - 20px)",
              margin: "20px 20px 0px",
              paddingTop: "20px",
              paddingBottom: "0px",
            }
          : {
              height: "100%",
              margin: "30px 0px 0px",
              paddingTop: "0px",
              paddingBottom: "60px",
            }),
      }}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            ...(step === CoachingStep.POST_ANSWER_SCREEN
              ? { opacity: 0.7, pointerEvents: "auto" }
              : showBackButton
                ? { opacity: 1, pointerEvents: "auto" }
                : { opacity: 0, pointerEvents: "none" }),
            transition: "opacity .2s ease",
            position: "absolute",
            top: "0px",
            left: "0px",
            zIndex: 1000,
          }}
        >
          <BackButton onBack={onBackClick} />
        </div>
        {(step === CoachingStep.POST_SELECTION ||
          step === CoachingStep.SCHEDULED) && (
          <img
            src={"/images/logos/joon-health-logo.svg"}
            alt=""
            style={{ height: "24px", width: "auto", margin: "0 auto" }}
          />
        )}
        <TransitionWrapper
          showChildren={step === CoachingStep.POST_ANSWER_SCREEN}
        >
          <PostAnswerScreen />
        </TransitionWrapper>
        <TransitionWrapper showChildren={step === CoachingStep.LANDING}>
          <FlexBox
            justify="flex-start"
            align="center"
            direction="column"
            fullWidth
            gap={SPACING.space4}
            style={{ minHeight: "70vh" }}
          >
            <CoachLandingPage />
          </FlexBox>
        </TransitionWrapper>
        <TransitionWrapper showChildren={step === CoachingStep.INTAKE_FORM}>
          <CoachQuestionnaire onConfirm={onConfirmCoachQuestionnaire} />
        </TransitionWrapper>
        <TransitionWrapper showChildren={step === CoachingStep.MATCHING}>
          <Matching />
        </TransitionWrapper>
        <TransitionWrapper showChildren={step === CoachingStep.COACH_SUMMARY}>
          <CoachSummary />
        </TransitionWrapper>
        <TransitionWrapper
          showChildren={step === CoachingStep.RECOMMENDED_COACHES}
        >
          <RecommendedCoaches />
        </TransitionWrapper>
        <TransitionWrapper showChildren={step === CoachingStep.POST_SELECTION}>
          <PostSelectionQuestionnaire
            onConfirm={onConfirmPostSelectionQuestionnaire}
          />
        </TransitionWrapper>
        <TransitionWrapper showChildren={step === CoachingStep.PLAN_SELECT}>
          <PlanSelect />
        </TransitionWrapper>
        <TransitionWrapper showChildren={step === CoachingStep.SCHEDULED}>
          <ScheduledPage />
        </TransitionWrapper>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          ...(step >= CoachingStep.LANDING && step < CoachingStep.INTAKE_FORM
            ? { opacity: 1, pointerEvents: "auto" }
            : { opacity: 0, pointerEvents: "none" }),
          transition: "opacity .2s ease",
        }}
      >
        <JoinParentsTextBox />
      </div>
    </FlexBox>
  )
}

export default CoachingFlow
