import { QuestDifficulty, QuestRoutine, SkillKey } from "@joonapp/web-shared"

interface SharedQuestTemplateFields {
  id: number
  title: string
  difficulty: QuestDifficulty
  routine: QuestRoutine
  requires_review: boolean
  skill: SkillKey | null

  type: "custom" | "suggested"
}

export interface SuggestedQuestTemplate extends SharedQuestTemplateFields {
  repeats_daily: boolean
  additional_text: string | null

  type: "suggested"
}

export interface CustomQuestTemplate extends SharedQuestTemplateFields {
  description: string | null
  redeemable_reward: number
  requires_completion_photo: boolean
  requires_completion_notes: boolean
  mandatory: boolean
  reminder_time: string | null
  timer_length: string | null
  recurrence: string
  occurrence_limit: number | null

  type: "custom"
}

export type QuestTemplate = SuggestedQuestTemplate | CustomQuestTemplate

/* eslint-disable no-unused-vars */
export enum QuestTemplateCategory {
  RECOMMENDED = "recommended",
  TOP = "top",
  HOUSEWORK = "housework",
  MENTAL_HEALTH = "mental_health",
  EDUCATION = "education",
  FITNESS = "fitness",
  FOCUS = "focus",
  RELATIONSHIPS = "relationships",
  CUSTOM = "custom",
}

export interface QuestInstanceStats {
  num_approved: number
  num_rejected: number
  num_to_collect: number
  num_to_complete: number
  num_to_review: number
  num_total_today: number
  num_user_created: number
}

export enum QuestSectionType {
  COMPLETED = "Completed",
  REVIEW = "In Review",
  SKIPPED = "Skipped",
  REJECTED = "Rejected",
  ROUTINE = "Routine",
  SELF_INITIATED = "Self Initiated",
}

export interface QuestAICreateInfo {
  pending_data_uuid: string
  quest_details: string
  pending_data: QuestAIToCreate
}
export interface QuestAIEditInfo {
  pending_data_uuid: string
  quest_details: string
  pending_data: QuestAIToEdit
}

export interface QuestAIToCreate {
  user_ids: number[]
  title: string
  description: string | null
  redeemable_reward: number
  skill: string | null
  routine: string
  recurrence_byday: string[]
  mandatory: boolean
  review_type:
    | "no_review"
    | "requires_basic_review"
    | "requires_completion_notes_no_photo"
    | "requires_completion_photo_no_notes"
    | "requires_completion_notes_and_photo"
  reminder_time: string | null
  timer_length: string | null
  order: number
}

export interface QuestAIToEdit {
  quest_id: number
  user_id: number
  title: string
  description: string | null
  redeemable_reward: number
  skill: string | null
  routine: string
  recurrence_byday: string[]
  mandatory: boolean
  review_type:
    | "no_review"
    | "requires_basic_review"
    | "requires_completion_notes_no_photo"
    | "requires_completion_photo_no_notes"
    | "requires_completion_notes_and_photo"
  reminder_time: string | null
  timer_length: string | null
  order: number
}

export interface QuestAIResponse {
  response: string
  pending_create_quest_data: QuestAICreateInfo[] | []
  pending_edit_existing_quest_data: QuestAIEditInfo[] | []
  selected_existing_quests_no_edits: QuestAIEditInfo[] | []
}

export enum QuestAIChatSessionType {
  QUEST_EDITOR = "quest-editor",
  ONBOARDING_ROUTINE = "onboarding-routine",
}

export enum QuestBoardSelectOption {
  ALL_USERS,
  ALL_KIDS,
  USER,
}
