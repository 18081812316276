import React from "react"

interface TransitionWrapperProps {
  showChildren: boolean
  children: React.ReactNode
  style?: React.CSSProperties
}

const TransitionWrapper = ({
  showChildren,
  children,
  style,
}: TransitionWrapperProps) => {
  return (
    <div
      style={{
        transition: "opacity .2s ease",
        opacity: showChildren ? 1 : 0,
        position: showChildren ? "static" : "absolute",
        ...style,
      }}
    >
      {showChildren && children}
    </div>
  )
}

export default TransitionWrapper
