import { create } from "zustand"

interface CoachSummarizerModalStoreProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useCoachSummarizerModalStore =
  create<CoachSummarizerModalStoreProps>((set) => ({
    isOpen: false,
    onClose: () => set({ isOpen: false }),
    onOpen: () => set({ isOpen: true }),
  }))
