import {
  SPACING,
  FlexBox,
  JoonColorExpanded,
  Typography,
  TextArea,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import CoachQuestionnaireAnswerButton from "./CoachQuestionnaireAnswerButton"
import TextArrowButton from "../../../components/coaching/TextArrowButton"
import LinearProgress from "../../../components/linearProgress/LinearProgress"
import PageLoader from "../../../components/loading/PageLoader"
import {
  useCoachIntakeFormQuestionnaireQuery,
  useUserQuery,
} from "../../../networking/queries"
import {
  AnswerInterface,
  QuestionAnswerMappingInterface,
  QuestionInterface,
  QuestionnairesInterface,
  QuestionType,
} from "../../../types"
import { CoachingStep } from "../../../types/coachingGroups"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { localStorageItems } from "../../../util/storage"
import { useCoachingStore } from "../useCoachingStore"
import useIsFromMOF from "../useIsFromMOF"

interface CoachQuestionnaireProps {
  onConfirm: (
    parentId: number,
    coachQuestionnaire: QuestionnairesInterface,
    questionAnswerMapping: QuestionAnswerMappingInterface
  ) => void
}

function CoachQuestionnaire({ onConfirm }: CoachQuestionnaireProps) {
  const { user } = useUserQuery()
  const { data: questionnaire, isLoading } =
    useCoachIntakeFormQuestionnaireQuery()
  const questions: QuestionInterface[] | [] = questionnaire?.questions || []
  const {
    questionnaireStep,
    setQuestionnaireStep,
    questionAnswerMapping,
    setQuestionAnswerMapping,
    setStep,
    setPostAnswerScreenInfo,
  } = useCoachingStore()
  const isFromMOF = useIsFromMOF()

  const nextQuestion = (
    // checking for post answer screen
    answer?: AnswerInterface,
    // this is so that we have the most updated mapping for single choice questions
    updatedAnswers?: QuestionAnswerMappingInterface
  ) => {
    if (answer && answer.post_answer_screen) {
      setPostAnswerScreenInfo({
        background_color_hex: answer.post_answer_screen.background_color_hex,
        background_image_url:
          answer.post_answer_screen.background_image_url || "",
        button_title: answer.post_answer_screen.button_title,
      })
      setStep(CoachingStep.POST_ANSWER_SCREEN)
    }
    const currentQuestion = questions[questionnaireStep]
    trackAnalyticEvent(
      ANALYTIC_EVENTS.MARKETING_INTAKE_FORM(Number(currentQuestion.id)),
      currentQuestion.type === QuestionType.FREEFORM
        ? {
            freeform_answer: updatedAnswers?.[currentQuestion.id],
            is_tof: !isFromMOF,
          }
        : {
            answers: updatedAnswers?.[currentQuestion.id],
            is_tof: !isFromMOF,
          }
    )
    setQuestionnaireStep(questionnaireStep + 1)
  }

  const didSelectAnswer = (answer: AnswerInterface) => {
    // this is only called for single choice questions
    const currentQuestion = questions[questionnaireStep]
    const newMapping = {
      ...questionAnswerMapping,
      [currentQuestion.id]: [answer.id],
    }
    setQuestionAnswerMapping(newMapping)
    nextQuestion(answer, newMapping)
  }

  const toggleMultiSelectAnswer = (answer: AnswerInterface) => {
    const currentQuestion = questions[questionnaireStep]
    const currentAnswers = questionAnswerMapping[currentQuestion.id] || []
    if (typeof currentAnswers === "string") return
    const newAnswers = currentAnswers.includes(answer.id)
      ? currentAnswers.filter((id) => id !== answer.id)
      : [...currentAnswers, answer.id]
    const newMapping = {
      ...questionAnswerMapping,
      [currentQuestion.id]: newAnswers,
    }
    setQuestionAnswerMapping(newMapping)
  }

  const onSubmitMultiSelect = () => {
    // just chooses one answer to check for post answer screen
    const answer = currentQuestion.answers.find(
      (answer) => answer.id === questionAnswerMapping[currentQuestion.id]?.[0]
    )
    nextQuestion(answer, questionAnswerMapping)
  }

  const onSubmitFreeform = () => {
    // we do not have post answer screen for freeform questions
    nextQuestion(undefined, questionAnswerMapping)
  }

  useEffect(() => {
    if (questions.length > 0 && questionnaireStep >= questions.length) {
      if (user && questionnaire) {
        onConfirm(user.id, questionnaire, questionAnswerMapping)
        localStorage.setItem(
          localStorageItems.coachQuestionnaireAnswers,
          JSON.stringify(questionAnswerMapping)
        )
      }
      trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_INTAKE_FORM_SUBMIT, {
        is_tof: !isFromMOF,
      })
      setStep(CoachingStep.MATCHING) // overrides post answer screen if last question
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireStep, questionAnswerMapping])

  if (isLoading) return <PageLoader />

  const currentQuestion = questions[questionnaireStep]

  if (currentQuestion === undefined) return null

  return (
    <FlexBox
      direction="column"
      align="flex-start"
      justify="center"
      style={{
        width: "min(450px, 100%)",
        margin: "0 auto",
        height: "100%",
        paddingBottom: "10dvh",
      }}
    >
      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space6}
        fullWidth
        style={{
          minHeight: "65dvh",
          paddingTop: "5dvh",
        }}
      >
        <Typography variant="h3">{currentQuestion.question}</Typography>
        {currentQuestion.type === QuestionType.SINGLE_CHOICE ? (
          <FlexBox
            direction="column"
            align="flex-start"
            gap={SPACING.space3}
            fullWidth
          >
            {currentQuestion.answers.map((answer) => (
              <CoachQuestionnaireAnswerButton
                key={answer.id}
                answer={answer}
                didSelectAnswer={didSelectAnswer}
                isMultiSelected={false}
                numberOfSelectedAnswers={
                  questionAnswerMapping[currentQuestion.id]?.length || 0
                }
                delay={true}
              />
            ))}
          </FlexBox>
        ) : currentQuestion.type === QuestionType.MULTI_CHOICE ? (
          <FlexBox direction="column" gap={SPACING.space4}>
            <FlexBox
              direction="column"
              align="flex-start"
              gap={SPACING.space3}
              fullWidth
            >
              <Typography variant="bodySmall" textAlign="center">
                Select up to 3
              </Typography>
              {currentQuestion.answers.map((answer) => (
                <CoachQuestionnaireAnswerButton
                  key={answer.id}
                  answer={answer}
                  didSelectAnswer={toggleMultiSelectAnswer}
                  isMultiSelected={(
                    questionAnswerMapping[currentQuestion.id] as number[]
                  )?.includes(answer.id)}
                  numberOfSelectedAnswers={
                    (questionAnswerMapping[currentQuestion.id] as number[])
                      ?.length || 0
                  }
                  delay={false}
                />
              ))}
            </FlexBox>
            <TextArrowButton
              text="Next"
              onClick={onSubmitMultiSelect}
              disabled={
                !questionAnswerMapping[currentQuestion.id] ||
                questionAnswerMapping[currentQuestion.id].length === 0
              }
              style={{
                width: "min(450px, calc(98% - 40px))",
                position: "fixed",
                left: "50%",
                transform: "translateX(-50%)",
                bottom: "9dvh",
              }}
            />
          </FlexBox>
        ) : (
          <>
            <FlexBox
              direction="column"
              align="flex-start"
              gap={SPACING.space3}
              fullWidth
            >
              <TextArea
                placeholder="Type your answer..."
                value={
                  questionAnswerMapping[currentQuestion.id]?.toString() || ""
                }
                onChange={(e) =>
                  setQuestionAnswerMapping({
                    ...questionAnswerMapping,
                    [currentQuestion.id]: e.target.value,
                  })
                }
                name="freeformAnswer"
                maxLength={255}
                showCounter={true}
                fullWidth
              />
            </FlexBox>
            <TextArrowButton
              text="Next"
              onClick={onSubmitFreeform}
              disabled={
                !questionAnswerMapping[currentQuestion.id] ||
                questionAnswerMapping[currentQuestion.id]?.toString().length ===
                  0
              }
              style={{
                width: "min(450px, calc(98% - 40px))",
                position: "fixed",
                left: "50%",
                transform: "translateX(-50%)",
                bottom: "9dvh",
              }}
            />
          </>
        )}
      </FlexBox>
      <p />
      <div
        style={{
          width: "450px",
          maxWidth: "calc(100% - 40px)",
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: "3vh",
        }}
      >
        <LinearProgress
          value={((questionnaireStep + 1) / questions.length) * 100}
          colorPrimary={JoonColorExpanded.viridian300}
          style={{
            width: "99%",
          }}
        />
      </div>
    </FlexBox>
  )
}

export default CoachQuestionnaire
