import {
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SessionBookingLinkType,
  SPACING,
} from "@joonapp/web-shared"

import { useSessionBookingModalStore } from "./hooks/useSessionBookingModalStore"
import { QUERY_KEYS } from "../../../constants"
import useMobile from "../../../hooks/useMobile"
import { queryClient } from "../../../util/queryClient"
import { useRouter } from "../../../util/router"
import { useCoachOverlayStore } from "../overlays/useCoachOverlay"
const SessionBookingModal = () => {
  const { isOpen, onClose, link, linkType } = useSessionBookingModalStore()
  const { setIsOpenSessionBooking } = useCoachOverlayStore()
  const isMobile = useMobile()
  const router = useRouter()
  const careTeamUserId = router.query.careTeamUserId as string | undefined

  const title = (() => {
    switch (linkType) {
      case SessionBookingLinkType.SCHEDULE:
        return "Schedule Session"
      case SessionBookingLinkType.RESCHEDULE:
        return "Reschedule Session"
      case SessionBookingLinkType.CANCEL:
        return "Cancel Session"
      default:
        return ""
    }
  })()

  const handleClose = () => {
    if (linkType === SessionBookingLinkType.CANCEL) {
      router.push(`/coach/${careTeamUserId}/sessions`)
    }
    onClose()
    queryClient.invalidateQueries([QUERY_KEYS.SESSION_BOOKINGS])
    queryClient.invalidateQueries([QUERY_KEYS.SESSION_BILLING_PERIODS])
    setIsOpenSessionBooking(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} animate mobileFull>
      <ModalHeader
        title={title}
        onClose={handleClose}
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          background: JoonUIColor.background.xlightGray,
          width: "100%",
        }}
      />
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        style={{ background: "#F3F4F6" }}
      >
        <iframe
          src={link}
          frameBorder="0"
          title="Session Booking"
          style={{
            marginTop: isMobile ? 0 : SPACING.space1,
            height: isMobile ? "100vh" : "min(700px, 100vh)",
            width: isMobile ? "100vw" : "min(100vw, 1000px)",
          }}
        ></iframe>
      </FlexBox>
    </Modal>
  )
}

export default SessionBookingModal
