import { faEnvelope, faLock } from "@fortawesome/pro-light-svg-icons"
import { CalloutBox, FlexBox, SPACING, Typography } from "@joonapp/web-shared"
import { useState } from "react"

import OnboardingPageContentWrapper from "./onboardingPageContentWrapper/OnboardingPageContentWrapper"
import { useAccountCreation } from "../../hooks/useAccountCreation"
import useOnboardContext from "../../hooks/useOnboardContext"
import { signup } from "../../networking/authentication"
import { AlertInterface } from "../../types"
import { localStorageItems } from "../../util/storage"
import {
  createErrorFromSignupResponse,
  isUsingInAppBrowser,
} from "../../util/util"
import GoogleAuth from "../auth/GoogleAuth"
import ChevronBackButton from "../buttons/ChevronBackButton"
import Primary3DButton from "../buttons/Primary3DButton"
import TextInputRect from "../textInputRect/TextInputRect"

function SignupSection() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [pending, setPending] = useState(false)
  const [formAlert, setFormAlert] = useState<AlertInterface | null>(null)
  const { previousStep } = useOnboardContext()

  const { uploadOnboardingSecondaryData } = useAccountCreation()

  const redirectLink = localStorage.getItem(localStorageItems.redirectLink)
  const isAllspireCampaign = redirectLink?.includes("Allspire")

  const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setPending(true)
    try {
      await signup(email, password)
      uploadOnboardingSecondaryData()
    } catch (err: any) {
      const errorMessage = createErrorFromSignupResponse(err)
      if (errorMessage) setFormAlert({ type: "error", message: errorMessage })
    } finally {
      setPending(false)
    }
  }

  return (
    <OnboardingPageContentWrapper
      backgroundOnDesktop="linear-gradient(to bottom, rgba(245, 250, 255, 1), rgba(210, 230, 250, 1))"
      paddingTop="24px"
      style={{
        position: "relative",
        height: "100",
        justifyContent: "center",
        alignItems: "center",
        gap: SPACING.space6,
      }}
    >
      <ChevronBackButton onClick={previousStep} />
      <FlexBox
        direction="column"
        align="center"
        justify="flex-end"
        gap={SPACING.space8}
        wrap={false}
        style={{
          flex: 1,
          paddingTop: SPACING.space7,
          maxHeight: "100%",
        }}
      >
        <FlexBox
          direction="column"
          align="center"
          gap={SPACING.space2}
          wrap={false}
          style={{ textAlign: "center" }}
        >
          <img
            src="/images/logos/logo_white.svg"
            alt="Joon"
            style={{
              width: "100px",
              userSelect: "none",
              pointerEvents: "none",
            }}
          />

          <Typography variant="h2Serif">
            {localStorage.getItem(localStorageItems.therapistCode)
              ? "Create an account to link to your therapist!"
              : "Save your result"}
          </Typography>
          {!localStorage.getItem(localStorageItems.therapistCode) && (
            <Typography variant="body">
              Create an account to view your personalized plan!
            </Typography>
          )}
        </FlexBox>

        <form
          onSubmit={handleSignUp}
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            gap: SPACING.space8,
            width: "100%",
          }}
        >
          <FlexBox
            direction="column"
            wrap={false}
            gap={SPACING.space6}
            style={{
              padding: SPACING.space6,
              background: "rgba(255, 255, 255, 0.6)",
              borderRadius: SPACING.space2,
            }}
          >
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="body">Email</Typography>
              <TextInputRect
                type="email"
                icon={faEnvelope}
                name="email"
                placeholder="Email"
                fullWidth={true}
                onChange={(e: any) => setEmail(e.target.value)}
                value={email}
              />
            </FlexBox>
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="body">Password</Typography>
              <TextInputRect
                icon={faLock}
                type="password"
                placeholder="Password"
                name="password"
                fullWidth={true}
                onChange={(e: any) => setPassword(e.target.value)}
                value={password}
              />
            </FlexBox>
            {formAlert && (
              <FlexBox style={{ margin: `${SPACING.space2} 0` }}>
                <CalloutBox type="error" fullWidth>
                  <Typography variant="bodySmall">
                    {formAlert.message}
                  </Typography>
                </CalloutBox>
              </FlexBox>
            )}
          </FlexBox>
          <Primary3DButton
            isLoading={pending}
            disabled={pending}
            type="submit"
            text="Sign up"
            fullWidth
          />
        </form>
      </FlexBox>

      <FlexBox direction="column" gap={SPACING.space4}>
        {!isUsingInAppBrowser && <GoogleAuth type="signup" />}

        <Typography
          variant="bodyXSmall"
          textAlign="center"
          style={{ marginTop: SPACING.space2 }}
        >
          By signing up, you agree to our{" "}
          <a
            href="https://www.joonapp.io/terms-of-service"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            Terms of Service
          </a>{" "}
          {isAllspireCampaign ? ", " : "and "}
          <a
            href="https://www.joonapp.io/privacy-policy"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            Privacy Policy
          </a>{" "}
          {isAllspireCampaign && (
            <>
              and{" "}
              <a
                href="https://www.joonapp.io/gpo-acknowledgement"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                GPO agreement
              </a>
            </>
          )}
        </Typography>
      </FlexBox>
    </OnboardingPageContentWrapper>
  )
}

export default SignupSection
