const Overlay = ({
  color,
  children,
}: {
  color: string
  children: React.ReactNode | React.ReactNode[]
}) => {
  return (
    <div className="flex items-center justify-center bg-black/50 absolute top-0 left-0 right-0 bottom-0 z-300 backdrop-blur-sm">
      <div
        className={`flex px-6 py-4 rounded-lg w-full max-w-[375px] z-2 relative`}
        style={{ backgroundColor: color }}
      >
        {children}
      </div>
    </div>
  )
}

export default Overlay
