import {
  Button,
  FlexBox,
  Modal,
  ModalHeader,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import { useState } from "react"
import toast from "react-hot-toast"

import { useRejectQuestModalStore } from "./useRejectQuestModalStore"
import { useReviewQuestModalStore } from "./useReviewQuestModalStore"
import { QUERY_KEYS } from "../../constants"
import { rejectQuest, retryQuest } from "../../networking/quests"
import { localStorageItems } from "../../util/storage"
import {
  CoachUpsellModalType,
  useCoachUpsellModalStore,
} from "../care/CoachUpsellModal"
import useCareTeam from "../care/useCareTeam"

const RejectQuestModal = () => {
  const [isLoadingReject, setIsLoadingReject] = useState(false)
  const [isLoadingRetry, setIsLoadingRetry] = useState(false)
  const {
    isOpen,
    onClose: closeRejectModal,
    quest,
  } = useRejectQuestModalStore()
  const {
    onClose: closeReviewModal,
    notes,
    difficulty,
  } = useReviewQuestModalStore()
  const { onOpen: openCoachUpsellModal } = useCoachUpsellModalStore()
  const queryClient = useQueryClient()
  const { hasCoach } = useCareTeam()

  const clickRejectButton = async (type: "reject" | "retry") => {
    if (!quest) return
    const body = {
      verification_notes: notes,
      completion_difficulty: difficulty,
    }
    if (type === "reject") {
      try {
        setIsLoadingReject(true)
        await rejectQuest({ instanceId: quest.id, data: body })
        queryClient.invalidateQueries([QUERY_KEYS.QUEST_REVIEW])
        queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
        queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER])
        toast.success("Quest rejected!")
      } catch (error) {
        toast.error("Failed to reject quest")
      } finally {
        setIsLoadingReject(false)
        if (hasCoach) return
        const lastSeenUpsellModalDate = localStorage.getItem(
          localStorageItems.rejectCoachUpsellModalSeenDate
        )
        const needsToSeeRejectCoachUpsellModal =
          !dayjs().isSame(dayjs(lastSeenUpsellModalDate), "week") ||
          !lastSeenUpsellModalDate
        if (needsToSeeRejectCoachUpsellModal)
          openCoachUpsellModal(CoachUpsellModalType.reject)
      }
    } else if (type === "retry") {
      try {
        setIsLoadingRetry(true)
        await retryQuest({ instanceId: quest.id, data: body })
        queryClient.invalidateQueries([QUERY_KEYS.QUEST_REVIEW])
        queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
        queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER])
        toast.success("Quest marked for retry!")
      } catch (error) {
        toast.error("Failed to retry quest")
      } finally {
        setIsLoadingRetry(false)
      }
    }
    closeReviewModal()
    closeRejectModal()
  }

  if (!quest) return <></>

  return (
    <Modal isOpen={isOpen} onClose={closeRejectModal} animate>
      <ModalHeader title="Are you sure?" onClose={closeRejectModal} />
      <FlexBox
        direction="column"
        gap="24px"
        style={{
          padding: SPACING.space6,
          paddingTop: SPACING.space2,
          width: "min(400px, 95vw)",
        }}
      >
        <Typography variant="body">
          You can also have them retry the Quest if you think your child can do
          better!
        </Typography>
        <FlexBox
          direction="row"
          justify="space-between"
          gap={SPACING.space6}
          wrap={false}
        >
          <Button
            text="Retry"
            isLoading={isLoadingRetry}
            disabled={isLoadingReject || isLoadingRetry}
            onClick={() => clickRejectButton("retry")}
          />
          <Button
            text="Reject"
            isLoading={isLoadingReject}
            disabled={isLoadingReject || isLoadingRetry}
            buttonType="redPrimary"
            onClick={() => clickRejectButton("reject")}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default RejectQuestModal
