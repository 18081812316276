import { ParentAvatar } from "@joonapp/web-shared"
import * as Sentry from "@sentry/react"
import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"

import useCoachOnboardingStore from "./useCoachOnboardingStore"
import { CoachPaywallChoice, QUERY_KEYS } from "../../constants"
import {
  createChildren,
  createFamily,
  createUser,
} from "../../networking/authentication"
import { loadFamilies } from "../../networking/families/api"
import {
  useCoachOnboardIntakeFormQuestionnaireQuery,
  useQuestionnairesQuery,
} from "../../networking/queries"
import { loadUser } from "../../networking/users/api"
import { GAEvent, trackGAEvent } from "../../util/analytics"
import {
  submitChildQuestionnaires,
  submitCoachQuestionnaire,
} from "../../util/questionnaires"
import { localStorageItems } from "../../util/storage"
import { separateFamily } from "../../util/util"

export const useCoachAccountCreation = () => {
  const {
    isSubmittingData,
    setIsSubmittingData,
    parentData,
    questionAnswerMapping,
  } = useCoachOnboardingStore()
  const { data: questionnaire } = useCoachOnboardIntakeFormQuestionnaireQuery()
  const queryClient = useQueryClient()

  const { data: questionsResponse, status } = useQuestionnairesQuery()

  const uploadOnboardingSecondaryData = async () => {
    if (status === "loading" || status === "error") return {}
    const { onboardingQuestionnaires } = questionsResponse
    if (isSubmittingData) return

    // Ensure we have at least one child with a default name
    let updatedParentData = { ...parentData }
    if (updatedParentData.childrenData.length === 0) {
      updatedParentData.childrenData = [
        {
          first_name: "Child",
          birthdate: null,
          gender: null,
          questionAnswerMapping: {},
        },
      ]
    }

    const hasChildQuestionnaires =
      updatedParentData.childrenData.length > 0 &&
      !(
        Array.isArray(
          updatedParentData.childrenData[0].questionAnswerMapping
        ) &&
        updatedParentData.childrenData[0].questionAnswerMapping.length === 0
      )

    setIsSubmittingData(true)
    try {
      // Create User, Family, and Children
      await createUser(
        updatedParentData.name,
        ParentAvatar.FRUMPULOUS,
        updatedParentData.lastName || undefined
      )

      localStorage.setItem(
        localStorageItems.lastSeenPaywall,
        dayjs().format("YYYY-MM-DD")
      )
      localStorage.setItem(
        localStorageItems.planChoiceFromPaywall,
        CoachPaywallChoice.WITH_COACH
      )

      const family = await createFamily("Parent")
      await createChildren(family.data.id, updatedParentData.childrenData)

      const familiesData = await loadFamilies()

      // Submit Questionnaires
      const userData = await loadUser()
      if (!familiesData || !userData)
        throw new Error(
          `Error loading data families:${familiesData} user:${userData}`
        )

      if (questionnaire) {
        submitCoachQuestionnaire(
          userData.id,
          questionnaire,
          questionAnswerMapping
        )
      }

      const { children } = separateFamily(userData, familiesData)
      if (hasChildQuestionnaires) {
        submitChildQuestionnaires(
          onboardingQuestionnaires,
          updatedParentData,
          children
        )
      }

      trackGAEvent(GAEvent.CREATE_ACCOUNT)
    } catch (error) {
      Sentry.captureException(error)

      // Instead of throwing, return an error object
      throw error
    } finally {
      queryClient.invalidateQueries([QUERY_KEYS.FAMILY])
      setIsSubmittingData(false)
    }
  }
  return { uploadOnboardingSecondaryData }
}
