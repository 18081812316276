import { FlexBox, SPACING, Typography } from "@joonapp/web-shared"
import { useState, useEffect } from "react"

import "./styles.scss"
import PromptTextArea from "./PromptTextArea"
import AnimatedStars from "../../../components/animatedStars/AnimatedStars"
import TransitionWrapper from "../../../components/coaching/TransitionWrapper"

const QuestAIWelcome = () => {
  const [visibleSections, setVisibleSections] = useState<number[]>([])
  const [isStarAnimating, setIsStarAnimating] = useState(true)

  useEffect(() => {
    const sectionDelays = [0, 2000, 3500, 5000, 6500, 8000] // Individual delays for each section

    sectionDelays.forEach((delay, index) => {
      setTimeout(() => {
        setVisibleSections((prev) => [...prev, index])
      }, delay)
    })
  }, [])

  useEffect(() => {
    setIsStarAnimating(true)
    const timeout = setTimeout(() => {
      setIsStarAnimating(false)
    }, 2000)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space6}
      align="center"
      style={{
        padding: `${SPACING.space6}`,
        paddingTop: `${SPACING.space12}`,
        position: "relative",
        flex: 1,
        transform: isStarAnimating ? "translateY(100px)" : "translateY(0px)",
        transition: "transform 0.5s ease-in-out",
      }}
    >
      <TransitionWrapper showChildren={visibleSections.includes(0)}>
        <AnimatedStars animate={isStarAnimating} scale={3} />
      </TransitionWrapper>
      <TransitionWrapper showChildren={visibleSections.includes(1)}>
        <div className="gradient-text">
          <Typography variant="aiExpressive" style={{ fontSize: "36px" }}>
            Hello, I'm Jooniper
          </Typography>
        </div>
      </TransitionWrapper>
      <TransitionWrapper showChildren={visibleSections.includes(2)}>
        <Typography
          variant="body"
          textAlign="center"
          style={{
            fontFamily: "Lora",
            fontStyle: "italic",
            padding: `0 ${SPACING.space2}`,
            fontWeight: 500,
          }}
        >
          I'm here to help you design the best routine for your family!
        </Typography>
      </TransitionWrapper>
      <TransitionWrapper showChildren={visibleSections.includes(3)}>
        <Typography
          variant="aiBody"
          textAlign="center"
          style={{ padding: `0 ${SPACING.space2}` }}
        >
          We'll use what we know about your family to generate Quest
          recommendations!
        </Typography>
      </TransitionWrapper>
      <TransitionWrapper showChildren={visibleSections.includes(4)}>
        <Typography
          variant="aiBody"
          textAlign="center"
          style={{ padding: `0 ${SPACING.space10}` }}
        >
          To start, tell me what you hope to accomplish with Joon.
        </Typography>
      </TransitionWrapper>
      <TransitionWrapper
        showChildren={visibleSections.includes(5)}
        style={{ width: "100%", marginTop: "auto" }}
      >
        <PromptTextArea />
      </TransitionWrapper>
    </FlexBox>
  )
}

export default QuestAIWelcome
