import { Gender, User, UserRole } from "@joonapp/web-shared"

export * from "./user"

export interface ParentDataInterface {
  name: string
  lastName?: string
  nickname: string
  avatar: string | null
  parentQuestionnaireMapping: QuestionAnswerMappingInterface
  childrenData: ChildDataInterface[]
}

export interface QuestionAnswerMappingInterface {
  [key: number]: number[] | string
}

export interface ChildDataInterface {
  birthdate: number | null
  first_name: string
  gender: Gender | null
  questionAnswerMapping: QuestionAnswerMappingInterface
}

export interface TempChildDataInterface
  extends Omit<ChildDataInterface, "gender"> {
  gender: Gender | null | undefined
}

export interface AddChildRequest {
  name: string
  gender: Gender | null
  birthdate?: string
}

export interface EditChildRequest extends AddChildRequest {
  id: number
}

export interface PostAnswerScreenInterface {
  title?: string
  description?: string
  image_url?: string
  button_title: string
  background_image_url?: string
  background_color_hex: string
  gif_key?:
    | "onboarding-clipboard-gif"
    | "onboarding-grow-plant-gif"
    | "onboarding-routine-cycle-gif"
    | "onboarding-tree-clouds-gif"
    | "onboarding-zoom-search-gif"
}

export interface AnswerInterface {
  id: number
  answer: string
  post_answer_screen: PostAnswerScreenInterface
  value: number
}

export interface QuestionInterface {
  id: number
  question: string
  answers: AnswerInterface[]
  type: QuestionType
  question_subtext?: string | null
}

export enum QuestionType {
  SINGLE_CHOICE = "single_choice",
  MULTI_CHOICE = "multi_choice",
  FREEFORM = "freeform",
}

export interface QuestionnairesInterface {
  day_of_week: string | null
  id: number
  onboarding: boolean
  questions: QuestionInterface[]
  title:
    | "child_onboarding_basic"
    | "child_repeating_behavioral"
    | "parent_onboarding"
    | "coach_intake_form"
    | "coach_post_selection"
  type: UserRole.CHILD | UserRole.PARENT
}

export interface AlertInterface {
  message: string
  type: "success" | "error"
}

export interface SubscriberAPIResponse {
  active_plan: {
    plan_type: string
    is_active: boolean
  }
  plan_is_active: boolean
  request_date: string
  request_date_ms: number
  subscriber: Subscriber
}

export interface Subscriber {
  entitlements: {
    premium: {
      expires_date: string
      grace_period_expires_date?: string
      product_identifier: string
      purchase_date: string
    }
  }
  first_seen: string
  last_seen: string
  management_url?: string | null
  non_subscriptions?: any
  original_app_user_id: string
  original_application_version: string
  original_purchase_date: string
  other_purchases: any
  subscriptions: {
    [key: string]: any
  }
}

// TODO: Move this to web-shared
export interface SubscriptionInfo {
  active_period_end_date: string
  num_sessions: number
  price_per_seat: number | null
  set_to_cancel: boolean
  status: SubscriptionStatus
}

// TODO: Move this to web-shared
export enum SubscriptionStatus {
  FREE = "free",
  EXPIRED = "expired",
  PAYING = "paying",
  TRIAL = "trial",
}

export interface CustomErrorInterface extends Error {
  code?: string
}

export interface MessageInterface {
  name: string
  email: string
  message: string
}

declare global {
  interface Window {
    dataLayer: any[]
  }
}

export interface Invitation {
  id: number
  invitee_email: string
  inviter: {
    id: number
    name: string
    email: string
    last_name: string | null
  }
  subject_user: {
    id: number
    name: string
    last_name: string | null
  }
  can_send_reminder: boolean
  type: string
}

export type InvitationGroup = Invitation[]

export enum QuestBoardTab {
  ASSIGNED,
  REVIEW,
}

export enum IncidentLogDataType {
  DURATION = "duration",
  ACCURACY = "accuracy",
  FREQUENCY = "frequency",
  ANTECEDENT = "antecedent",
  RATING = "rating",
}

export type IncidentTemplate = {
  target_behaviors: {
    id: number
    incident_log_data_type: IncidentLogDataType
    preventive_strategies: {
      id: number
      title: string
    }
    title: string
  }
  user_id: number
}

export type DataTaskIncidentTemplate = {
  id: number
  title: string
  description: string
  data_type: IncidentLogDataType
  training_id: number
  training: {
    id: number
    title: string
  }
  child: User
  assigner: User
  subject: User
  date_assigned: string
  date_read: string | null
  date_archived: string | null
}

export enum FamilyQuerySelect {
  CHILDREN,
  PARENT,
  COPARENTS,
}

export enum ShopItemType {
  ACCESSORY = "accessory",
  DECORATION = "decoration",
  FOOD = "food",
  CONSUMABLE = "consumable",
  NON_CONSUMABLE = "non-consumable",
}

export interface ShopItem {
  id: number
  name: string
  description: string
  type: ShopItemType
  image_url: string
  price: number
}

export interface InventoryItem {
  id: number
  user_id: number
  shop_item: ShopItem
}

export interface FamilyMail {
  id: number
  body: string
  sender: User
  receiver: User
  sent_datetime: string
  read_datetime: string | null
  shop_item_gift: ShopItem | null
}

export enum FamilyMailboxTab {
  RECEIVED = "Received",
  SENT = "Sent",
}

export interface NotificationPreferences {
  quest_reminder: boolean
  receive_sms: boolean
  verify_quest: boolean
  coach_checkin_time: string // format is "20:30:00" to represent 8:30pm (default).
}

export enum SetPhoneNumberSource {
  POST_THERAPIST_LINK = "post_therapist_link",
  SETTINGS = "settings",
}

export enum CancellationSurveyType {
  COACH = "coach",
  FAMILY = "family",
}

export enum CancellationSurveyStep {
  CUSTOM_REWARDS,
  SURVEY,
}

export enum CancellationSurveyQuestion {
  WHY_LEAVING,
  ALTERNATIVE_SOLUTIONS,
}

export enum JooniperPrepaywallStep {
  WELCOME,
  QUEST_RECOMMENDATION,
  REWARD_SETUP,
}

export interface DoterChatLog {
  id: number
  prompt: string | null //  null means no prompt
  prompt_flags: AIPromptFlag[] | null //  null/[] means no flags
  responses: string[]
  date_created: string // iso format
}

export enum AIPromptFlag {
  BULLYING = "bullying",
  ANXIETY = "clinical-anxiety",
  DEPRESSION = "depression",
  ABUSE = "abuse",
  VIOLENCE = "violence",
  SELF_HARM = "self-harm",
  SEXUAL_CONTENT = "sexual-content",
  SUBSTANCE = "substance",
  PROFANITY = "profanity",
}

export type AIChatFlaggedPromptNotification = {
  id: number
  user_id: number
  message: DoterChatLog
  date_created: string // iso format
  date_read: string | null // iso format
}

export enum CoachOverlayType {
  FTUX = "FTUX",
  EXPIRED_SUBSCRIPTION = "EXPIRED_SUBSCRIPTION",
  TOS = "TOS",
  SESSION_BOOKING = "SESSION_BOOKING",
}

export enum SubscriptionCampaign {
  DISCOUNT_ANNUAL = "DiscountAnnual",
  SPECIAL_DISCOUNT_ANNUAL = "SpecialDiscountAnnual",
}
