import { useCallback, useState } from "react"

import { usePaywallReferralModalStore } from "./PaywallReferralModal"
import { useFamilyQuery } from "../../networking/queries"
import { SubscriptionCampaign } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"
import {
  getFamilyBillingLink,
  getFamilyStripeCheckoutLink,
} from "../../util/stripe"

export const useCheckoutRedirect = () => {
  const [isLoadingCheckoutFn, setIsLoadingCheckoutFn] = useState(false)
  const router = useRouter()
  const { hasValidCode } = usePaywallReferralModalStore()

  const { data: family } = useFamilyQuery()

  const redirectToFamilyCheckout = useCallback(async () => {
    if (!family) return
    if (isLoadingCheckoutFn) return

    setIsLoadingCheckoutFn(true)

    const { checkout_url } = await getFamilyStripeCheckoutLink({
      familyId: family.id,
      campaign: hasValidCode
        ? SubscriptionCampaign.DISCOUNT_ANNUAL
        : router.query.campaign,
    })

    if (checkout_url === null) {
      redirectToFamilyBilling()
    } else {
      window.open(checkout_url, "_self")
      trackAnalyticEvent(ANALYTIC_EVENTS.SHOW_CHECKOUT)
    }
    setIsLoadingCheckoutFn(false)
  }, [router, family]) // eslint-disable-line react-hooks/exhaustive-deps

  const redirectToFamilyBilling = useCallback(async () => {
    if (!family) return

    setIsLoadingCheckoutFn(true)
    const billingLink = await getFamilyBillingLink({ familyId: family.id })

    window.open(billingLink, "_self")
    trackAnalyticEvent(ANALYTIC_EVENTS.SHOW_BILLING)
    setIsLoadingCheckoutFn(false)
  }, [family])

  return {
    isLoadingCheckoutFn,
    redirectToFamilyCheckout,
    redirectToFamilyBilling,
  }
}
