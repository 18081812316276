import { faCalendarStar } from "@fortawesome/duotone-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { JoonUIColor, IconProps } from "@joonapp/web-shared"

const QuestsIcon = ({
  color = JoonUIColor.icon.accent,
  size = 30,
}: IconProps) => {
  return <FontAwesomeIcon icon={faCalendarStar} color={color} fontSize={size} />
}

export default QuestsIcon
