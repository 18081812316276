import { captureException } from "@sentry/react"
import { useCallback, useMemo } from "react"

import useAIQuestStore from "./useAIQuestStore"
import { QUERY_KEYS } from "../../../../constants"
import {
  acceptSuggestions,
  createNewChatSession,
  generateChatResponse,
} from "../../../../networking/chatSessions/api"
import { QuestAIChatSessionType } from "../../../../types/quests"
import { trackAnalyticEvent } from "../../../../util/analytics"
import { ANALYTIC_EVENTS } from "../../../../util/analytics"
import { queryClient } from "../../../../util/queryClient"
import { localStorageItems } from "../../../../util/storage"

const useAIQuest = () => {
  const {
    prompt,
    setPrompt,
    isLoading,
    setIsLoading,
    chatSessionId,
    setChatSessionId,
    response,
    setResponse,
    selectedQuestUUIDs,
    setSelectedQuestUUIDs,
    isAssigning,
    setIsAssigning,
    setError,
    setAssignedQuests,
    setDidStartOver,
    setIsRemoving,
  } = useAIQuestStore()

  // Selected = either actual selected or ALL in response
  const selectedUUIDs = useMemo(() => {
    const toCreateUUIDs =
      response?.pending_create_quest_data?.map(
        (quest) => quest.pending_data_uuid
      ) ?? []
    const toEditUUIDs =
      response?.pending_edit_existing_quest_data?.map(
        (quest) => quest.pending_data_uuid
      ) ?? []
    const toModifyUUIDs =
      response?.selected_existing_quests_no_edits?.map(
        (quest) => quest.pending_data_uuid
      ) ?? []

    return selectedQuestUUIDs.length > 0
      ? selectedQuestUUIDs
      : [...toCreateUUIDs, ...toEditUUIDs, ...toModifyUUIDs]
  }, [selectedQuestUUIDs, response])

  const initializeChatSession = useCallback(async () => {
    try {
      const id = await createNewChatSession(QuestAIChatSessionType.QUEST_EDITOR)
      if (!id) throw new Error("Failed to create chat session")
      setChatSessionId(id)
    } catch (error) {
      captureError(error)
    } finally {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setChatSessionId, setPrompt])

  const generateResponse = useCallback(
    async (event) => {
      event.preventDefault()
      if (isLoading || !chatSessionId) return
      setIsLoading(true)
      setAssignedQuests([])
      setDidStartOver(false)

      try {
        const result = await generateChatResponse(
          chatSessionId,
          prompt,
          selectedUUIDs
        )
        trackAnalyticEvent(ANALYTIC_EVENTS.AI_SEND_QUEST_BUILDER_PROMPT)
        setPrompt("")
        setResponse(result)
      } catch (error) {
        captureError(error)
      } finally {
        setIsLoading(false)

        const welcomeFlag = localStorageItems.hasSeenAIQuestBuilderWelcome
        const hasSeenAIQuestBuilderWelcome = localStorage.getItem(welcomeFlag)
        if (!hasSeenAIQuestBuilderWelcome) {
          localStorage.setItem(welcomeFlag, "true")
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, chatSessionId, prompt, selectedUUIDs]
  )

  const handleStartOver = useCallback(() => {
    initializeChatSession()
    setDidStartOver(true)
    setPrompt("")
    setSelectedQuestUUIDs([])
    setAssignedQuests([])
    setError(null)
    trackAnalyticEvent(ANALYTIC_EVENTS.AI_QUEST_BUILDER_RESTART_SESSION)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializeChatSession])

  // When selecting from list, and want to remove, send manual prompt
  const handleRemoveQuests = useCallback(async () => {
    if (isLoading || !chatSessionId) return
    const removePrompt = `Remove the following quests from the list: ${selectedQuestUUIDs.join(", ")}`
    setIsRemoving(true)
    try {
      const result = await generateChatResponse(
        chatSessionId,
        removePrompt,
        selectedUUIDs
      )
      trackAnalyticEvent(ANALYTIC_EVENTS.AI_QUEST_BUILDER_REMOVE_CHANGES)
      setResponse(result)
      setSelectedQuestUUIDs([])
    } catch (error) {
      captureError(error)
    } finally {
      setIsRemoving(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, chatSessionId, selectedUUIDs])

  // Assign suggested quests
  const handleAssignQuests = useCallback(async () => {
    if (isLoading || isAssigning || !chatSessionId || !response) return

    try {
      setIsAssigning(true)
      const response = await acceptSuggestions(chatSessionId, selectedUUIDs)
      await queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
      await queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER])
      if (selectedUUIDs.length > 0) {
        trackAnalyticEvent(ANALYTIC_EVENTS.AI_QUEST_BUILDER_ACCEPT_SELECTED)
      } else {
        trackAnalyticEvent(ANALYTIC_EVENTS.AI_QUEST_BUILDER_ACCEPT_ALL_CHANGES)
      }
      setResponse(null)
      setAssignedQuests(response.quests)
      setSelectedQuestUUIDs([])
    } catch (error) {
      captureError(error)
    } finally {
      setIsAssigning(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, chatSessionId, selectedUUIDs])

  const captureError = useCallback(
    (error: any) => {
      console.error(error)
      captureException(error)
      setError(JSON.stringify(error, null, 2))
      trackAnalyticEvent(ANALYTIC_EVENTS.AI_QUEST_BUILDER_ERROR, {
        error: JSON.stringify(error, null, 2),
      })
    },
    [setError]
  )

  return {
    initializeChatSession,
    generateResponse,
    handleStartOver,
    handleRemoveQuests,
    handleAssignQuests,
  }
}

export default useAIQuest
