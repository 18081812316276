import { useQuery } from "@tanstack/react-query"

import { getSBCredentials, loadCareTeam } from "./api"
import { QUERY_KEYS } from "../../constants"
import { useUserQuery } from "../queries"

export const useSBCredentialsQuery = (enabled: boolean = false) => {
  const { user } = useUserQuery()
  return useQuery({
    queryKey: [QUERY_KEYS.SB_CREDENTIALS],
    queryFn: () => getSBCredentials(user?.id!),
    enabled: !!user?.id && enabled,
  })
}

export const useCareTeamQuery = () => {
  const { user } = useUserQuery()
  return useQuery({
    queryKey: [QUERY_KEYS.CARE_TEAM],
    queryFn: () => loadCareTeam(user?.id!),
    enabled: !!user,
  })
}
