import { FlexBox, SPACING, Typography } from "@joonapp/web-shared"
import { useState } from "react"

import useCoachOnboardingStore, {
  CoachOnboardingStep,
} from "./useCoachOnboardingStore"
import Primary3DButton from "../../components/buttons/Primary3DButton"
import OnboardingPageContentWrapper from "../../components/onboarding/onboardingPageContentWrapper/OnboardingPageContentWrapper"
import OnboardingPageWrapper from "../../components/onboarding/onboardingPageWrapper/OnboardingPageWrapper"
import useMobile from "../../hooks/useMobile"
import { ANALYTIC_EVENTS } from "../../util/analytics"
import { trackAnalyticEvent } from "../../util/analytics"

const CoachQuestionnaireDisclaimer = () => {
  const isMobile = useMobile()
  const { setStep } = useCoachOnboardingStore()
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  return (
    <OnboardingPageWrapper
      style={
        isMobile
          ? {
              backgroundImage: "url(/images/background/cloud-bg.png)",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }
          : { background: "transparent" }
      }
    >
      <OnboardingPageContentWrapper
        backgroundImageOnDesktop="url(/images/background/cloud-bg.png)"
        paddingTop="20dvh"
      >
        <FlexBox direction="column" align="center" gap={SPACING.space4}>
          <img
            src={"/images/tree.png"}
            alt="tree"
            style={{
              height: "130px",
              width: "130px",
              opacity: isImageLoaded ? 1 : 0,
            }}
            onLoad={() => setIsImageLoaded(true)}
          />
          <Typography variant="h2Serif">About your coaching needs </Typography>
          <Typography variant="body" textAlign="center">
            These next few questions will help us learn more about your goals
            and coach preferences so that we can best support you!
          </Typography>
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space3}>
          <Primary3DButton
            text="Continue"
            fullWidth
            onClick={() => {
              trackAnalyticEvent(
                ANALYTIC_EVENTS.COACH_ONBOARDING_QUESTIONNAIRE_START
              )
              setStep(CoachOnboardingStep.COACH_QUESTIONNAIRE)
            }}
          />
        </FlexBox>
      </OnboardingPageContentWrapper>
    </OnboardingPageWrapper>
  )
}

export default CoachQuestionnaireDisclaimer
