import Snowfall from "react-snowfall"

import leaf1 from "/images/leaves/leaf1.svg"
import leaf2 from "/images/leaves/leaf2.svg"
import leaf3 from "/images/leaves/leaf3.svg"
import leaf4 from "/images/leaves/leaf4.svg"
import leaf5 from "/images/leaves/leaf5.svg"
import leaf6 from "/images/leaves/leaf6.svg"
import leaf7 from "/images/leaves/leaf7.svg"
import leaf8 from "/images/leaves/leaf8.svg"
import leaf9 from "/images/leaves/leaf9.svg"

const FallingLeaves = ({ snowflakeCount }: { snowflakeCount?: number }) => {
  const images = [
    Object.assign(new Image(), { src: leaf1 }),
    Object.assign(new Image(), { src: leaf2 }),
    Object.assign(new Image(), { src: leaf3 }),
    Object.assign(new Image(), { src: leaf4 }),
    Object.assign(new Image(), { src: leaf5 }),
    Object.assign(new Image(), { src: leaf6 }),
    Object.assign(new Image(), { src: leaf7 }),
    Object.assign(new Image(), { src: leaf8 }),
    Object.assign(new Image(), { src: leaf9 }),
  ]

  return (
    <Snowfall
      snowflakeCount={snowflakeCount || 50}
      images={images}
      wind={[0, 0.5]}
      radius={[8, 15]}
      speed={[0.3, 1]}
      opacity={[0.3, 1]}
    />
  )
}

export default FallingLeaves
