import { faEnvelope } from "@fortawesome/pro-light-svg-icons"
import {
  CalloutBox,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import React, { useState } from "react"

import { resetPassword } from "../../networking/authentication"
import { AlertInterface } from "../../types"
import { useRouter } from "../../util/router"
import { localStorageItems } from "../../util/storage"
import { createErrorFromResponse } from "../../util/util"
import ChevronBackButton from "../buttons/ChevronBackButton"
import Primary3DButton from "../buttons/Primary3DButton"
import OnboardingPageContentWrapper from "../onboarding/onboardingPageContentWrapper/OnboardingPageContentWrapper"
import TextInputRect from "../textInputRect/TextInputRect"

const ForgotPassword = () => {
  const [email, setEmail] = useState("")
  const [formAlert, setFormAlert] = useState<AlertInterface | null>(null)
  const [pending, setPending] = useState(false)
  const router = useRouter()

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      setPending(true)
      await resetPassword(email)
      setFormAlert({ type: "success", message: "Password reset email sent" })
    } catch (err) {
      const errorMessage = createErrorFromResponse(err)
      if (errorMessage) setFormAlert({ type: "error", message: errorMessage })
    } finally {
      setPending(false)
    }
  }

  return (
    <OnboardingPageContentWrapper
      backgroundOnDesktop="linear-gradient(to bottom, rgba(245, 250, 255, 1), rgba(210, 230, 250, 1))"
      paddingTop={SPACING.space6}
      style={{ position: "relative" }}
    >
      <ChevronBackButton onClick={() => router.push("/auth/signin")} />
      <form
        onSubmit={onSubmit}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop: SPACING.space7,
        }}
      >
        <FlexBox direction="column" align="center" gap={SPACING.space9}>
          <FlexBox direction="column" align="center" gap={SPACING.space4}>
            <img
              src="/images/logos/logo_white.svg"
              alt="Joon"
              style={{
                width: "100px",
                userSelect: "none",
                pointerEvents: "none",
              }}
            />
            <Typography variant="h2Serif">Reset password</Typography>
            <Typography
              variant="body"
              textAlign="center"
              style={{ width: "80%" }}
            >
              We will send you a reset password link to access your Joon account
            </Typography>
            {formAlert && (
              <div style={{ margin: `${SPACING.space2} 0` }}>
                <CalloutBox type={formAlert.type} fullWidth>
                  {formAlert.message}
                </CalloutBox>
              </div>
            )}
          </FlexBox>

          <FlexBox
            direction="column"
            wrap={false}
            gap={SPACING.space1}
            style={{
              padding: SPACING.space6,
              background: "rgba(255, 255, 255, 0.6)",
              borderRadius: SPACING.space2,
            }}
          >
            <Typography variant="body">Email</Typography>
            <TextInputRect
              type="email"
              icon={faEnvelope}
              name="email"
              placeholder="user@example.com"
              fullWidth={true}
              onChange={(e: any) => setEmail(e.target.value)}
              value={email}
            />
          </FlexBox>
        </FlexBox>

        <FlexBox direction="column" align="center" gap={SPACING.space4}>
          <Primary3DButton
            isLoading={pending}
            type="submit"
            disabled={pending}
            text="Reset password"
            fullWidth
            parentStyle={{ marginBottom: SPACING.space12 }}
          />
          <Typography variant="body">
            Remember it after all?{" "}
            <button onClick={() => router.push("/auth/signin")}>
              <Typography
                variant="bodyBold"
                color={JoonUIColor.text.primaryAccent}
              >
                Sign In
              </Typography>
            </button>
          </Typography>
          <Typography variant="body" textAlign="center">
            Don't have an account?{" "}
            <button onClick={() => router.push("/welcome")}>
              <Typography
                variant="bodyBold"
                color={JoonUIColor.text.primaryAccent}
              >
                Create one here
              </Typography>
            </button>
          </Typography>
        </FlexBox>
      </form>
    </OnboardingPageContentWrapper>
  )
}

export default ForgotPassword

export const getAfterAuthPath = (router: any) => {
  const redirectLink = localStorage.getItem(localStorageItems.redirectLink)
  if (router.query.next) return router.query.next
  else if (redirectLink) {
    localStorage.removeItem(localStorageItems.redirectLink)
    return redirectLink
  } else return "/"
}
