import { faEnvelope } from "@fortawesome/pro-light-svg-icons"
import {
  Button,
  CalloutBox,
  Checkbox,
  FlexBox,
  Modal,
  ModalHeader,
  SPACING,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import toast from "react-hot-toast"
import { create } from "zustand"

import therapistDisclaimer from "./therapistDisclaimer"
import { QUERY_KEYS } from "../../../constants"
import { useFamilyQuery } from "../../../networking/queries"
import { sendTherapistInvitation } from "../../../networking/user"
import { FamilyQuerySelect } from "../../../types"
import { localStorageItems } from "../../../util/storage"
import { createErrorFromResponse } from "../../../util/util"

interface InviteTherapistViaEmailModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useInviteTherapistViaEmailModal =
  create<InviteTherapistViaEmailModalStore>((set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  }))

export const InviteTherapistViaEmailModal = () => {
  const { isOpen, onClose } = useInviteTherapistViaEmailModal()
  const [email, setEmail] = useState("")
  const [error, setError] = useState<string | null>(null)
  const [selectedChildren, setSelectedChildren] = useState<number[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const queryClient = useQueryClient()

  const selectChild = (id: number) => {
    if (selectedChildren.includes(id)) {
      setSelectedChildren(selectedChildren.filter((childId) => childId !== id))
    } else {
      setSelectedChildren([...selectedChildren, id])
    }
  }

  const sendInvite = async () => {
    setIsLoading(true)
    try {
      await sendTherapistInvitation(email, selectedChildren)
      onClose()
      toast.success("Invite sent successfully!")
      localStorage.removeItem(localStorageItems.showABAInviteSection)
      queryClient.invalidateQueries([QUERY_KEYS.THERAPIST_INVITES])
      setEmail("")
      setError(null)
    } catch (error) {
      const errorMessage = createErrorFromResponse(error)
      setError(errorMessage)
    }
    setIsLoading(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      animate
      style={{ width: "min(450px, 95vw)" }}
    >
      <ModalHeader
        title="Invite Therapist"
        onClose={onClose}
        showBorderBottom
      />
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{ padding: SPACING.space6, paddingTop: SPACING.space4 }}
      >
        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="body">Therapist email address</Typography>
          <TextInput
            value={email}
            icon={faEnvelope}
            type="email"
            name="therapist code"
            placeholder="Enter email"
            onChange={(e: any) => setEmail(e.target.value)}
            fullWidth
          />
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="body">Whose therapist is this?</Typography>
          <FlexBox gap={SPACING.space1}>
            {children?.map((child, i) => (
              <Checkbox
                name="test"
                key={i}
                label={child.user.name}
                onChange={() => selectChild(child.user.id)}
                selected={selectedChildren.includes(child.user.id)}
              />
            ))}
          </FlexBox>
        </FlexBox>
        <CalloutBox fullWidth>{therapistDisclaimer}</CalloutBox>
        {error && (
          <CalloutBox fullWidth type="error">
            <Typography variant="bodySmall">{error}</Typography>
          </CalloutBox>
        )}
        <Button
          text="Invite therapist"
          size="large"
          fullWidth
          isLoading={isLoading}
          onClick={sendInvite}
          disabled={email === "" || selectedChildren.length === 0}
        />
      </FlexBox>
    </Modal>
  )
}
