import { faEnvelope, faLock, faUser } from "@fortawesome/pro-light-svg-icons"
import {
  CalloutBox,
  createErrorFromSignupResponse,
  FlexBox,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import { useCoachAccountCreation } from "./useCoachAccountCreation"
import useCoachOnboardingStore from "./useCoachOnboardingStore"
import Primary3DButton from "../../components/buttons/Primary3DButton"
import OnboardingPageContentWrapper from "../../components/onboarding/onboardingPageContentWrapper/OnboardingPageContentWrapper"
import OnboardingPageWrapper from "../../components/onboarding/onboardingPageWrapper/OnboardingPageWrapper"
import TextInputRect from "../../components/textInputRect/TextInputRect"
import { CoachPaywallChoice } from "../../constants"
import useMobile from "../../hooks/useMobile"
import { signup } from "../../networking/authentication"
import { AlertInterface } from "../../types"
import { ANALYTIC_EVENTS } from "../../util/analytics"
import { trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"
import { localStorageItems } from "../../util/storage"
import { useCoachingStore } from "../coaching/useCoachingStore"

const CoachSignUp = () => {
  const isMobile = useMobile()
  const [isLoading, setIsLoading] = useState(true)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [formAlert, setFormAlert] = useState<AlertInterface | null>(null)
  const [firstName, setFirstName] = useState("")
  const { setSourcePage } = useCoachingStore()
  const { isSubmittingData, setParentData, parentData } =
    useCoachOnboardingStore()

  const router = useRouter()

  const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      if (!firstName || !email || !password) {
        setFormAlert({ type: "error", message: "Please fill out all fields" })
        return
      }
      setParentData({ ...parentData, name: firstName })

      await new Promise((resolve) => setTimeout(resolve, 0))

      await signup(email, password)
      await uploadOnboardingSecondaryData()
      localStorage.setItem(localStorageItems.isFromCoachOnboarding, "true")
      localStorage.setItem(
        localStorageItems.planChoiceFromPaywall,
        CoachPaywallChoice.WITH_COACH
      )
      router.push("/coaching")
      setSourcePage("coach-onboard")
      trackAnalyticEvent(ANALYTIC_EVENTS.COACH_ONBOARDING_SIGNUP)
    } catch (error) {
      const errorMessage = createErrorFromSignupResponse(error)
      if (errorMessage) setFormAlert({ type: "error", message: errorMessage })
    }
  }

  const { uploadOnboardingSecondaryData } = useCoachAccountCreation()

  return (
    <OnboardingPageWrapper
      style={
        isMobile
          ? {
              backgroundImage: "url(/images/background/cloud-bg.png)",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }
          : { background: "transparent" }
      }
    >
      <OnboardingPageContentWrapper
        backgroundImageOnDesktop="url(/images/background/cloud-bg.png)"
        paddingTop="5dvh"
      >
        <img
          src="/images/logos/logo_white.svg"
          alt="Joon"
          style={{
            width: "110px",
            zIndex: 1,
            marginBottom: SPACING.space3,
            userSelect: "none",
            pointerEvents: "none",
            opacity: isLoading ? 0 : 1,
            transition: "opacity 0.1s ease-in-out",
          }}
          onLoad={() => setIsLoading(false)}
        />
        <Typography
          variant="h2Serif"
          textAlign="center"
          style={{ maxWidth: "300px" }}
        >
          Save your result to see your matched coaches
        </Typography>

        <form
          onSubmit={handleSignUp}
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            gap: SPACING.space8,
            width: "100%",
          }}
        >
          <FlexBox
            direction="column"
            wrap={false}
            gap={SPACING.space6}
            style={{
              padding: SPACING.space6,
              background: "rgba(255, 255, 255, 0.6)",
              borderRadius: SPACING.space2,
            }}
          >
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="body">First name</Typography>
              <TextInputRect
                type="text"
                icon={faUser}
                name="first_name"
                placeholder="First name"
                fullWidth={true}
                onChange={(e: any) => {
                  setFirstName(e.target.value)
                  setParentData({
                    ...parentData,
                    name: e.target.value,
                  })
                }}
                value={firstName}
              />
            </FlexBox>
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="body">Email</Typography>
              <TextInputRect
                icon={faEnvelope}
                type="email"
                placeholder="Email"
                name="email"
                fullWidth={true}
                onChange={(e: any) => setEmail(e.target.value)}
                value={email}
              />
            </FlexBox>
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="body">Password</Typography>
              <TextInputRect
                icon={faLock}
                type="password"
                placeholder="Password"
                name="password"
                fullWidth={true}
                onChange={(e: any) => setPassword(e.target.value)}
                value={password}
              />
            </FlexBox>
            {formAlert && (
              <FlexBox style={{ margin: `${SPACING.space2} 0` }}>
                <CalloutBox type="error" fullWidth>
                  <Typography variant="bodySmall">
                    {formAlert.message}
                  </Typography>
                </CalloutBox>
              </FlexBox>
            )}
          </FlexBox>
          <Primary3DButton
            isLoading={isSubmittingData}
            disabled={isSubmittingData}
            type="submit"
            text="Sign up"
            fullWidth
          />
        </form>
      </OnboardingPageContentWrapper>
    </OnboardingPageWrapper>
  )
}

export default CoachSignUp
