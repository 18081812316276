import { create } from "zustand"

interface RewardPageStore {
  showWelcome: boolean
  setShowWelcome: (showWelcome: boolean) => void
}

export const useRewardPageStore = create<RewardPageStore>((set) => ({
  showWelcome: false,
  setShowWelcome: (showWelcome) => set({ showWelcome }),
}))
