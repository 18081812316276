import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

import {
  ArrowRightIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import ChatBubble from "./planComponents/ChatBubble"
import PlanInfoCard from "./planComponents/PlanInfoCard"
import PlanSecondaryOfferingModal, {
  usePlanSecondaryOfferingModalStore,
} from "./planComponents/PlanSecondaryOfferingModal"
import TimerBanner from "./planComponents/TimerBanner"
import { useRecommendedCoachVideoModalStore } from "./recommendedCoaches/useRecommendedCoachVideoModalStore"
import PageLoader from "../../../components/loading/PageLoader"
import useMobile from "../../../hooks/useMobile"
import { useCoachSubscriptionPackagesQuery } from "../../../networking/coaching/queries"
import { CoachingStep } from "../../../types/coachingGroups"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { localStorageItems } from "../../../util/storage"
import SmallCoachCard from "../SmallCoachCard"
import { useCoachingStore } from "../useCoachingStore"
import useIsFromMOF from "../useIsFromMOF"

const PlanSelect = () => {
  const { selectedCoach, setTimeLeft, setStep } = useCoachingStore()
  const { isLoading } = useCoachSubscriptionPackagesQuery()
  const { onClose } = useRecommendedCoachVideoModalStore()
  const { onOpen: onOpenSecondaryOfferingModal } =
    usePlanSecondaryOfferingModalStore()

  const isMobileMediaQuery = useMobile()
  const isFromMOF = useIsFromMOF()
  const coachName = selectedCoach?.user.name

  const onBackClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_CHOOSE_ANOTHER_COACH, {
      is_tof: !isFromMOF,
    })
    onClose()
    setStep(CoachingStep.RECOMMENDED_COACHES)
  }

  const onNoThanksClick = () => {
    trackAnalyticEvent(
      ANALYTIC_EVENTS.MARKETING_SECONDARY_OFFERING_MODAL_OPEN,
      { is_tof: !isFromMOF }
    )
    onOpenSecondaryOfferingModal()
  }

  useEffect(() => {
    setTimeLeft(10 * 60)
  }, [setTimeLeft])

  if (!selectedCoach || !coachName || isLoading) {
    return <PageLoader />
  }

  const isFromCoachOnboarding = localStorage.getItem(
    localStorageItems.isFromCoachOnboarding
  )

  return (
    <FlexBox
      direction="column"
      align="center"
      gap={SPACING.space4}
      wrap={false}
    >
      <TimerBanner coachName={coachName} />

      <button
        onClick={onBackClick}
        style={{
          width: "100%",
          padding: "0",
          marginRight: "auto",
          marginTop: isMobileMediaQuery ? SPACING.space3 : SPACING.space6,
        }}
      >
        <FlexBox
          direction="row"
          justify="flex-start"
          align="center"
          wrap={false}
        >
          <FlexBox
            direction="row"
            justify="center"
            align="center"
            wrap={false}
            style={{
              marginRight: SPACING.space2,
              transform: "rotate(180deg)",
              width: "fit-content",
            }}
          >
            <ArrowRightIcon size={20} color={JoonUIColor.text.secondary} />
          </FlexBox>
          <Typography
            variant="bodySmallBold"
            color={JoonUIColor.text.secondary}
            style={{ whiteSpace: "nowrap" }}
          >
            Return to all coaches
          </Typography>
        </FlexBox>
      </button>

      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space4}
        style={{
          position: "relative",
          marginTop: "52px",
          marginBottom: SPACING.space2,
        }}
      >
        <ChatBubble
          text={`Hi! I'm ${coachName} and I can't wait to get started working with you!`}
          width="280px"
        />
        <SmallCoachCard
          coachCardContent={selectedCoach}
          backgroundColor={JoonUIColor.background.primaryNeutral}
        />
      </FlexBox>

      <PlanInfoCard />

      {isFromCoachOnboarding !== "true" && (
        <TextButton onClick={onNoThanksClick}>
          <Typography
            variant="bodySmallBold"
            color={JoonUIColor.text.primaryAccent}
          >
            No thanks, I don't want a coach after all
          </Typography>
        </TextButton>
      )}
      <PlanSecondaryOfferingModal />
    </FlexBox>
  )
}

export default PlanSelect
