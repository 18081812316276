import {
  ChildUser,
  FlexBox,
  JoonUIColor,
  SPACING,
  UserRole,
} from "@joonapp/web-shared"
import React, { useMemo } from "react"

import { QuestBoardUserSelectButton } from "./QuestBoardUserSelectButton"
import useQuestBoardStore from "./useQuestBoardStore"
import useMobile from "../../hooks/useMobile"
import { useFamilyQuery, useUserQuery } from "../../networking/queries"
import { QuestBoardSelectOption } from "../../types/quests"
import { getChildImage, getParentAvatarImage } from "../../util/util"

const QuestBoardUserSelectHeader = ({
  style,
}: {
  style?: React.CSSProperties
}) => {
  const {
    selectedUserId,
    setSelectedUserId,
    selectOption: userSelectOption,
    setSelectOption,
  } = useQuestBoardStore()
  const { user } = useUserQuery()
  const { data: family } = useFamilyQuery()
  const isMobile = useMobile()

  const questBoardUserSelectOptions = useMemo(
    () => [
      {
        label: "All",
        selectOption: QuestBoardSelectOption.ALL_USERS,
        imageUrl: null,
        userId: null,
      },
      ...(family?.profiles && family?.profiles?.length > 2
        ? [
            {
              label: "All kids",
              selectOption: QuestBoardSelectOption.ALL_KIDS,
              imageUrl: null,
              userId: null,
            },
          ]
        : []),
      {
        label: "Me",
        selectOption: QuestBoardSelectOption.USER,
        imageUrl: user?.avatar ? getParentAvatarImage(user.avatar) : null,
        userId: user?.id ?? null,
      },
      ...(family?.profiles
        ?.filter((profile) => profile.user.id !== user?.id)
        .map((profile) => ({
          label: profile.user.name,
          selectOption: QuestBoardSelectOption.USER,
          imageUrl:
            profile.role === UserRole.PARENT
              ? getParentAvatarImage(profile.user.avatar)
              : getChildImage(profile.user as ChildUser),
          userId: profile.user.id,
        })) || []),
    ],
    [family, user]
  )

  const onClick = (
    selectOption: QuestBoardSelectOption,
    userId: number | null
  ) => {
    setSelectOption(selectOption)
    setSelectedUserId(userId)
  }

  const isSelected = (
    userId: number | null,
    selectOption: QuestBoardSelectOption
  ) => {
    switch (selectOption) {
      case QuestBoardSelectOption.ALL_USERS:
      case QuestBoardSelectOption.ALL_KIDS:
        return selectOption === userSelectOption
      case QuestBoardSelectOption.USER:
        return userId === selectedUserId
    }
  }

  return (
    <FlexBox
      direction="row"
      align="center"
      justify="flex-start"
      wrap={false}
      gap={SPACING.space1}
      fullWidth
      style={{
        maxWidth: "600px",
        width: "100%",
        background: JoonUIColor.background.primaryNeutral,
        padding: `${SPACING.space5} ${SPACING.space2} ${SPACING.space2}`,
        overflowX: "auto",
        scrollbarWidth: "none",
        borderBottomLeftRadius: isMobile ? 0 : SPACING.space2,
        borderBottomRightRadius: isMobile ? 0 : SPACING.space2,
        boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.25)",
        zIndex: 100,
        margin: "0 auto",
        ...style,
      }}
    >
      {questBoardUserSelectOptions.map(
        ({ label, selectOption, imageUrl, userId }) => (
          <QuestBoardUserSelectButton
            key={label}
            label={label}
            imageUrl={imageUrl}
            selectOption={selectOption}
            onClick={() => onClick(selectOption, userId)}
            isSelected={isSelected(userId, selectOption)}
          />
        )
      )}
    </FlexBox>
  )
}

export default QuestBoardUserSelectHeader
