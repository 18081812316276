import { Family } from "@joonapp/web-shared"

import { AddChildRequest } from "../../types"
import { createJoonAPIClient } from "../../util/joon-api"

export async function addChild({
  childInfo,
  familyId,
}: {
  childInfo: AddChildRequest
  familyId: number
}) {
  const API = createJoonAPIClient()
  return API.post(`api/families/${familyId}/create-child/`, childInfo).then(
    (res) => res.data
  )
}

export async function removeChild({
  childId,
  familyId,
}: {
  childId: number
  familyId: number
}) {
  const API = createJoonAPIClient()
  return API.post(`api/families/${familyId}/remove-member/`, {
    user_id: childId,
  }).then((res) => res.data)
}

export async function loadFamilies() {
  const joonApiClient = createJoonAPIClient()
  try {
    const response = await joonApiClient.get<{ results: Family[] }>(
      "api/families/"
    )
    return response.data.results[0]
  } catch (error) {
    throw error
  }
}

export async function getJoonityChurnkeyCancelFlow(familyId: number) {
  const API = createJoonAPIClient()
  return API.get<{
    subscription_id: string | null
    customer_id: string | null
    churnkey_auth_hash: string | null
  }>(`api/families/${familyId}/cancel-flow/`).then((res) => res.data)
}
