import {
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import React from "react"

type Props = {
  value: number
  style?: React.CSSProperties
  colorPrimary?: JoonColorExpanded
  colorSecondary?: string
  displayValue?: boolean
  withIcon?: boolean
}

const LinearProgress = ({
  value,
  style,
  colorPrimary = JoonUIColor.background.accent,
  colorSecondary = "#D1DCEC",
  displayValue = false,
  withIcon = false,
}: Props) => {
  return (
    <div
      style={{
        height: withIcon ? "8px" : SPACING.space4,
        background: colorSecondary,
        width: "100%",
        borderRadius: SPACING.space6,
        overflow: withIcon ? "" : "hidden",
        position: "relative",
        ...style,
      }}
    >
      <div
        style={{
          position: "relative",
          height: "100%",
          width: `${value}%`,
          background: colorPrimary,
          transition: "width 0.3s",
          borderRadius: SPACING.space6,
        }}
      >
        {withIcon && (
          <img
            src="/images/icons/progress-leaf.png"
            alt="progress-leaf"
            style={{
              userSelect: "none",
              pointerEvents: "none",
              minWidth: "21px",
              width: "21px",
              height: "24px",
              position: "absolute",
              right: "-13px",
              top: "-10px",
            }}
          />
        )}
      </div>
      {displayValue && (
        <Typography
          variant="bodyXSmallBold"
          style={{
            position: "absolute",
            left: SPACING.space2,
            top: 0,
          }}
        >
          {value.toFixed()}%
        </Typography>
      )}
    </div>
  )
}

export default LinearProgress
