import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  SPACING,
  Button,
  FlexBox,
  Typography,
  JoonUIColor,
} from "@joonapp/web-shared"
import { useShallow } from "zustand/react/shallow"

import { useAddQuestTemplatesModalStore } from "./addQuestTemplates/useAddQuestTemplatesModalStore"
import useQuestBoardMultiSelectStore from "./useQuestBoardMultiSelectStore"
import useMobile from "../../hooks/useMobile"

export const AddQuestButton = () => {
  const { onOpen: addQuest } = useAddQuestTemplatesModalStore()
  const isMobile = useMobile()

  const { isMultiSelectMode } = useQuestBoardMultiSelectStore(
    useShallow((state) => ({ isMultiSelectMode: state.isMultiSelectMode }))
  )

  if (isMultiSelectMode) return <></>

  return (
    <Button
      onClick={addQuest}
      style={{
        position: "sticky",
        marginRight: SPACING.space4,
        alignSelf: "flex-end",
        padding: isMobile ? SPACING.space3 : SPACING.space4,
        bottom: isMobile ? "82px" : "40px",
        borderRadius: SPACING.space2,
        height: isMobile ? "56px" : "44px",
        maxHeight: isMobile ? "56px" : "44px",
        minHeight: isMobile ? "56px" : "44px",
        width: isMobile ? "56px" : "unset",
        maxWidth: isMobile ? "56px" : "unset",
        border: "none",
      }}
      text={
        <FlexBox
          direction="row"
          wrap={false}
          align="center"
          justify="center"
          gap={SPACING.space2}
        >
          <FontAwesomeIcon icon={faPlus} fontSize={isMobile ? 24 : 16} />
          {!isMobile && (
            <Typography variant="bodyBold" color={JoonUIColor.icon.inverted}>
              Add Quest
            </Typography>
          )}
        </FlexBox>
      }
    />
  )
}

export default AddQuestButton
