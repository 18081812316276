import { SPACING, Typography } from "@joonapp/web-shared"
import { FlexBox } from "@joonapp/web-shared"

import PromptTextArea from "./PromptTextArea"
import AnimatedStars from "../../../components/animatedStars/AnimatedStars"

import "./styles.scss"

const QuestAILanding = () => {
  return (
    <FlexBox
      direction="column"
      gap={SPACING.space6}
      align="center"
      style={{
        padding: `${SPACING.space4}`,
        position: "relative",
        flex: 1,
      }}
    >
      <div style={{ height: "10vh" }} />
      <AnimatedStars animate={false} scale={3} />
      <div className="gradient-text">
        <Typography variant="aiExpressive">Welcome back!</Typography>
      </div>
      <Typography variant="aiBody">What can I help you with today?</Typography>
      <div style={{ width: "100%", marginTop: "auto" }}>
        <PromptTextArea />
      </div>
    </FlexBox>
  )
}

export default QuestAILanding
