import {
  Checkbox,
  ChevronRightIcon,
  DropdownOption,
  FlexBox,
  JoonUIColor,
  QuestRoutine,
  SegmentedControl2,
  SPACING,
  Switch,
  Typography,
} from "@joonapp/web-shared"
import { create } from "zustand"

import { RoutineSelectButton } from "./RoutineSelectButton"
import { useDateTime } from "./useDateTime"
import { CloseButton } from "../../../../components/closeButton/CloseButton"
import SlideUpModal from "../../../../components/slideUpModal/SlideUpModal"
import { anytimeToggleOptions, StartDateOption } from "../../../../constants"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

interface DateTimeModalStoreProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useDateTimeModalStore = create<DateTimeModalStoreProps>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}))

export const DateTimeModal = () => {
  const {
    routine,
    repetition,
    isOngoing,
    setRoutine,
    setIsOngoing,
    setReminderTimeMap,
  } = useAddEditQuestStore()
  const { isOpen, onClose } = useDateTimeModalStore()
  const {
    selectedStartDate,
    startDateOptions,
    getRoutineString,
    setSelectedStartDate,
  } = useDateTime()

  const hasRepetition = repetition.some((day) => day)

  const anytimeToggleValue =
    routine === QuestRoutine.ANYTIME
      ? anytimeToggleOptions.find((option) => option.value === "anytime") ||
        anytimeToggleOptions[0]
      : anytimeToggleOptions.find((option) => option.value === "time-of-day") ||
        anytimeToggleOptions[1]

  const onToggleAnytime = (value: DropdownOption) => {
    if (
      (value.value === QuestRoutine.ANYTIME &&
        routine === QuestRoutine.ANYTIME) ||
      (value.value === "time-of-day" && routine !== QuestRoutine.ANYTIME)
    )
      return
    if (routine === QuestRoutine.ANYTIME) setRoutine(QuestRoutine.MORNING)
    else setRoutine(QuestRoutine.ANYTIME)
    setReminderTimeMap({})
  }

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      zIndex={1000}
      backgroundType="overlay"
      size="fit-content"
    >
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{
          position: "relative",
          backgroundColor: JoonUIColor.background.xlightGray,
          borderRadius: SPACING.space2,
          padding: SPACING.space4,
        }}
      >
        <CloseButton onClick={onClose} />
        <Typography variant="bodyBold">Date & Time</Typography>

        <FlexBox direction="column" gap={SPACING.space2}>
          {!hasRepetition && (
            <button
              onClick={(e) => e.stopPropagation()}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: SPACING.space2,
                padding: SPACING.space3,
                borderRadius: SPACING.space2,
                background: JoonUIColor.background.primaryNeutral,
                cursor: "default",
              }}
            >
              <FlexBox
                direction="row"
                gap={SPACING.space2}
                align="center"
                justify="space-between"
              >
                <Typography variant="bodySmallBold">
                  Keep this quest until complete
                </Typography>
                <Switch
                  checked={isOngoing}
                  onChange={() => setIsOngoing(!isOngoing)}
                  name="ongoing-quest"
                />
              </FlexBox>
              <Typography
                variant="bodyXSmall"
                color={JoonUIColor.text.secondary}
                textAlign="left"
              >
                This quest will continue to show up until it's done and will be
                scheduled as part of an Anytime quest. Best for one of those
                once in a while bonus tasks!
              </Typography>
            </button>
          )}

          {!isOngoing && (
            <>
              <FlexBox
                direction="column"
                wrap={false}
                fullWidth
                style={{
                  background: JoonUIColor.background.primaryNeutral,
                  borderRadius: SPACING.space2,
                  border: `1px solid ${JoonUIColor.border.default}`,
                }}
              >
                <FlexBox
                  direction="row"
                  align="center"
                  justify="space-between"
                  gap={SPACING.space2}
                  wrap={false}
                  fullWidth
                  style={{
                    borderBottom: `1px solid ${JoonUIColor.border.default}`,
                    padding: SPACING.space3,
                  }}
                >
                  <Typography
                    variant="bodySmallBold"
                    color={JoonUIColor.text.secondary}
                  >
                    Time
                  </Typography>
                  <Typography
                    variant="bodySmallBold"
                    color={JoonUIColor.text.primary}
                  >
                    {getRoutineString()}
                  </Typography>
                </FlexBox>
                <FlexBox
                  direction="column"
                  align="flex-start"
                  gap={SPACING.space2}
                  wrap={false}
                  fullWidth
                  style={{
                    borderBottom:
                      routine === QuestRoutine.ANYTIME
                        ? "none"
                        : `1px solid ${JoonUIColor.border.default}`,
                    padding: SPACING.space3,
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <SegmentedControl2
                    options={anytimeToggleOptions}
                    value={anytimeToggleValue}
                    setValue={(value) => onToggleAnytime(value)}
                    fullWidth
                    style={{
                      border: `1px solid ${JoonUIColor.border.default}`,
                      borderRadius: SPACING.space2,
                    }}
                  />
                  {routine === QuestRoutine.ANYTIME && (
                    <Typography
                      variant="bodyXSmall"
                      color={JoonUIColor.text.secondary}
                    >
                      Your child can do this at their leisure. We show Anytime
                      quests underneath Night quests.
                    </Typography>
                  )}
                </FlexBox>
                {routine !== QuestRoutine.ANYTIME &&
                  Object.values(QuestRoutine)
                    .slice(0, 3)
                    .map((questRoutine, index) => (
                      <RoutineSelectButton
                        key={index}
                        questRoutine={questRoutine}
                        index={index}
                      />
                    ))}
              </FlexBox>

              <FlexBox
                direction="column"
                wrap={false}
                fullWidth
                style={{
                  background: JoonUIColor.background.primaryNeutral,
                  borderRadius: SPACING.space2,
                  border: `1px solid ${JoonUIColor.border.default}`,
                }}
              >
                <FlexBox
                  direction="row"
                  align="center"
                  justify="space-between"
                  gap={SPACING.space2}
                  wrap={false}
                  fullWidth
                  style={{
                    borderBottom: `1px solid ${JoonUIColor.border.default}`,
                    padding: SPACING.space3,
                  }}
                >
                  <Typography
                    variant="bodySmallBold"
                    color={JoonUIColor.text.secondary}
                    textAlign="left"
                  >
                    Date
                  </Typography>

                  <Typography
                    variant="bodySmallBold"
                    color={JoonUIColor.text.primary}
                    textAlign="left"
                    style={{ width: "fit-content", whiteSpace: "nowrap" }}
                  >
                    {
                      startDateOptions.find(
                        (option) => option.value === selectedStartDate
                      )?.label
                    }
                  </Typography>
                </FlexBox>
                {startDateOptions.map((option, index) => (
                  <button
                    key={index}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      gap: SPACING.space2,
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: `1px solid ${JoonUIColor.border.default}`,
                      ...(index === startDateOptions.length - 1 && {
                        borderBottom: "none",
                        borderBottomLeftRadius: SPACING.space2,
                        borderBottomRightRadius: SPACING.space2,
                      }),
                      padding: SPACING.space3,
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      setSelectedStartDate(option.value as number)
                    }}
                  >
                    <Typography variant="bodySmallBold" textAlign="left">
                      {option.label}
                    </Typography>

                    {index === startDateOptions.length - 1 &&
                    selectedStartDate !== StartDateOption.CUSTOM ? (
                      <ChevronRightIcon
                        color={JoonUIColor.icon.neutral}
                        size={24}
                      />
                    ) : (
                      <Checkbox
                        selected={option.value === selectedStartDate}
                        inputType="radio"
                        name={option.value?.toString() || "none"}
                        onChange={() => {}}
                        hideBorder
                      />
                    )}
                  </button>
                ))}
              </FlexBox>
            </>
          )}
        </FlexBox>
      </FlexBox>
    </SlideUpModal>
  )
}
