import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"

import { PhoneNumberReminder } from "./PhoneNumberReminder"
import {
  PhoneNumberModalType,
  useAddPhoneNumberModalStore,
} from "./useAddPhoneNumberModalStore"
import { useNotificationPreferencesMutation } from "./useNotificationPreferencesMutation"
import { usePhoneNumberMutation } from "./usePhoneNumberMutation"
import { useGetCoachSubscriptionQuery } from "../../../networking/coaching/queries"
import { useTherapistsQuery, useUserQuery } from "../../../networking/queries"
import useCareTeam from "../../../pages/care/useCareTeam"
import { useCoachOverlayStore } from "../../../pages/coach/overlays/useCoachOverlay"
import useCoachSMSConfigModalStore from "../../../pages/coach/useCoachSMSConfigModalStore"
import { CoachOverlayType } from "../../../types"
import { SetPhoneNumberSource } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { useRouter } from "../../../util/router"
import { isValidPhoneNumber } from "../../../util/util"
import { PhoneNumberInput } from "../../phoneNumberInput/PhoneNumberInput"

const AddPhoneNumberModal = () => {
  const router = useRouter()
  const { user } = useUserQuery()
  const { firstCoach } = useCareTeam()
  const { data: therapist } = useTherapistsQuery()
  const { isOpen, onClose, phoneNumber, setPhoneNumber, type } =
    useAddPhoneNumberModalStore()
  const { onOpen: openCoachSMSConfigModal } = useCoachSMSConfigModalStore()
  const notificationPreferencesMutation = useNotificationPreferencesMutation()
  const phoneNumberMutation = usePhoneNumberMutation()
  const { data: coachSubscriptionData, isLoading: isLoadingCoachSubscription } =
    useGetCoachSubscriptionQuery()
  const { setCurrentOverlay } = useCoachOverlayStore()

  const initialPhoneNumber = user?.phone_number || ""
  const therapistName = therapist?.[0]?.nickname ?? "your therapist"
  const coachName =
    firstCoach?.nickname ?? firstCoach?.user.name ?? "your coach"

  const isMessageOnly =
    !isLoadingCoachSubscription && coachSubscriptionData?.num_sessions === 0

  const subtitleText = () => {
    switch (type) {
      case PhoneNumberModalType.COACH:
        return `This will enable SMS notifications when ${coachName} messages you.`
      case PhoneNumberModalType.THERAPIST:
        return `This will allow ${therapistName} to send you notifications, reminders, or helpful tips on how to best help your child at home.`
      default:
        return "Phone number"
    }
  }

  const onAddNumber = async () => {
    Promise.all([
      phoneNumberMutation.mutateAsync(phoneNumber),
      notificationPreferencesMutation.mutateAsync({ receiveSms: true }),
    ]).then(() => {
      trackAnalyticEvent(ANALYTIC_EVENTS.SET_PHONE_NUMBER, {
        source: SetPhoneNumberSource.POST_THERAPIST_LINK,
      })
      if (router.pathname.includes("/paywall")) router.push("/me")
      if (type === PhoneNumberModalType.COACH) {
        setTimeout(() => openCoachSMSConfigModal(), 300)
      }
      onClose()
    })
  }

  const onSkip = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.SKIP_SET_PHONE_NUMBER)
    if (router.pathname.includes("/paywall")) router.push("/me")
    onClose()
    if (type === PhoneNumberModalType.COACH && !isMessageOnly) {
      setCurrentOverlay(CoachOverlayType.SESSION_BOOKING)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onSkip}
      displayCloseIcon={false}
      mandatory={type === PhoneNumberModalType.COACH}
      animate
      style={{ width: "min(500px, 95vw)" }}
    >
      <div className="flex flex-row justify-between items-center px-6 py-4 border-b border-borderPrimary">
        <Typography variant="h4">Add phone number</Typography>
        {type !== PhoneNumberModalType.COACH && (
          <TextButton onClick={onSkip}>
            <FontAwesomeIcon icon={faXmark} />
          </TextButton>
        )}
      </div>
      <FlexBox
        direction="column"
        gap={SPACING.space6}
        style={{
          width: "min(500px, 95vw)",
          padding: SPACING.space6,
          paddingTop: SPACING.space4,
        }}
      >
        <FlexBox direction="column" gap={SPACING.space2}>
          <Typography
            variant="bodySmall"
            style={{ textWrap: "wrap", lineHeight: 1.25 }}
          >
            {subtitleText()}
          </Typography>
          <PhoneNumberInput
            initialPhoneNumber={initialPhoneNumber}
            setPhoneNumber={setPhoneNumber}
          />
        </FlexBox>

        <PhoneNumberReminder />

        <FlexBox justify="flex-end" gap={SPACING.space4} align="center">
          {type !== PhoneNumberModalType.COACH && (
            <TextButton onClick={onSkip}>
              <Typography
                variant="bodySmallBold"
                color={JoonUIColor.text.primaryAccent}
              >
                Skip for now
              </Typography>
            </TextButton>
          )}
          <Button
            text="Add number"
            buttonType="primary"
            fullWidth={type === PhoneNumberModalType.COACH}
            onClick={onAddNumber}
            disabled={!isValidPhoneNumber(phoneNumber)}
            isLoading={
              notificationPreferencesMutation.isLoading ||
              phoneNumberMutation.isLoading
            }
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default AddPhoneNumberModal
