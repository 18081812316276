import {
  Typography,
  FlexBox,
  SPACING,
  Switch,
  JoonUIColor,
  Button,
  Modal,
  ModalContent,
  JoonColorExpanded,
  TextButton,
  InfoSolidIcon,
  UserRole,
} from "@joonapp/web-shared"
import { useEffect, useMemo } from "react"
import { useShallow } from "zustand/react/shallow"

import { CompletionRequirementModal } from "./completionRequirement/CompletionRequirementModal"
import { CompletionRequirementSection } from "./completionRequirement/CompletionRequirementSection"
import {
  ConfirmUngroupModal,
  useConfirmUngroupModalStore,
} from "./ConfirmUngroupModal"
import { DateTimeModal } from "./dateTime/DateTimeModal"
import { DateTimeSection } from "./dateTime/DateTimeSection"
import SetTimeModal from "./dateTime/SetTimeModal"
import SpecifyDateModal from "./dateTime/SpecifyDateModal"
import { AddEditQuestModalHeader } from "./header/AddEditQuestModalHeader"
import { AddSkillModal } from "./header/AddSkillModal"
import { MandatoryQuestInfoModal } from "./header/MandatoryQuestInfoModal"
import { QuestPhotoUploadModal } from "./header/QuestPhotoUploadModal"
import {
  QuestGroupInfoModal,
  useQuestGroupInfoModalStore,
} from "./QuestGroupInfoModal"
import { RecurrenceModal } from "./recurrence/RecurrenceModal"
import { RecurrenceSection } from "./recurrence/RecurrenceSection"
import { RepeatWeekModal } from "./recurrence/RepeatWeekModal"
import { TimerModal } from "./timer/TimerModal"
import { TimerSection } from "./timer/TimerSection"
import { useAddEditQuestModal } from "./useAddEditQuest"
import { useAddEditQuestStore } from "./useAddEditQuestStore"
import ReassignQuest from "./userAssignDifficulty/ReassignQuest"
import UserAssignDifficultySection from "./userAssignDifficulty/UserAssignDifficultySection"
import { useFamilyQuery } from "../../../networking/queries"
import {
  useAddQuestMutation,
  useEditQuestMutation,
} from "../../../networking/quests/mutations"
import { useQuestGroupSeries } from "../useQuestGroups"

const AddEditQuestModal = () => {
  const {
    assignedUsers,
    saveTemplate,
    setSaveTemplate,
    isOpen,
    onClose,
    errors,
    selectedQuest,
    isGrouped,
    setIsGrouped,
  } = useAddEditQuestStore(
    useShallow((state) => ({
      assignedUsers: state.assignedUsers,
      saveTemplate: state.saveTemplate,
      setSaveTemplate: state.setSaveTemplate,
      isOpen: state.isOpen,
      onClose: state.onClose,
      errors: state.errors,
      selectedQuest: state.selectedQuest,
      isGrouped: state.isGrouped,
      setIsGrouped: state.setIsGrouped,
    }))
  )
  const addQuestMutation = useAddQuestMutation()
  const editQuestMutation = useEditQuestMutation()
  useAddEditQuestModal()

  const { onOpen: onOpenQuestGroupInfoModal } = useQuestGroupInfoModalStore()
  const { onOpen: onOpenConfirmUngroupModal } = useConfirmUngroupModalStore()
  const { questGroupSeries } = useQuestGroupSeries(selectedQuest)

  const { data: family } = useFamilyQuery()

  const hasParentSelected = family?.profiles.some(
    (profile) =>
      assignedUsers.includes(profile.user.id) &&
      profile.role === UserRole.PARENT
  )
  const isEditing = !!selectedQuest
  const isEditingAndHasMultipleInstances =
    isEditing && questGroupSeries.length > 1
  const isCreatingAndHasMultipleChildren =
    !isEditing && assignedUsers.length > 1

  const createQuestText = useMemo(() => {
    if (isEditing)
      return isGrouped || questGroupSeries.length === 1
        ? "Save"
        : `Save & ungroup quests`
    if (assignedUsers.length > 1) {
      return isGrouped
        ? "Create a grouped quest"
        : `Create ${assignedUsers.length} quests`
    }
    return "Create Quest"
  }, [isEditing, assignedUsers, questGroupSeries, isGrouped])

  useEffect(() => {
    if (
      isOpen &&
      (isEditingAndHasMultipleInstances || isCreatingAndHasMultipleChildren)
    ) {
      setIsGrouped(true)
    }
  }, [
    isEditingAndHasMultipleInstances,
    isCreatingAndHasMultipleChildren,
    isOpen,
    setIsGrouped,
  ])

  const onCreateQuestClick = async () => {
    if (!isEditing) await addQuestMutation.mutateAsync(hasParentSelected)
    else {
      if (isGrouped || questGroupSeries.length === 1)
        await editQuestMutation.mutateAsync()
      else onOpenConfirmUngroupModal()
    }
  }

  const isLoading = addQuestMutation.isLoading || editQuestMutation.isLoading

  return (
    <Modal isOpen={isOpen} onClose={onClose} animate displayCloseIcon>
      <>
        <ModalContent style={{ position: "relative", overflow: "hidden" }}>
          {isLoading && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                zIndex: 10000,
              }}
            />
          )}
          <FlexBox
            direction="column"
            style={{
              padding: 0,
              margin: "0 auto",
              width: "min(500px, 100vw)",
              overflowX: "hidden",
            }}
            wrap={false}
            fullWidth
          >
            <Typography
              variant="h3"
              textAlign="center"
              style={{ paddingTop: SPACING.space4 }}
              color={JoonColorExpanded.indigo700}
            >
              {isEditing ? "Edit" : "Create"}
            </Typography>

            <AddEditQuestModalHeader />

            <UserAssignDifficultySection />

            <RecurrenceSection />

            <DateTimeSection />

            <TimerSection />

            <CompletionRequirementSection />

            {!isEditing && (
              <FlexBox
                direction="column"
                gap={SPACING.space2}
                style={{ padding: SPACING.space4 }}
              >
                <FlexBox
                  direction="row"
                  align="center"
                  justify="space-between"
                  fullWidth
                >
                  <Typography variant="bodySmallBold">
                    Save as a template
                  </Typography>
                  <Switch
                    checked={saveTemplate}
                    name="saveTemplate"
                    onChange={() => setSaveTemplate(!saveTemplate)}
                  />
                </FlexBox>
                {saveTemplate && (
                  <Typography
                    variant="bodyXSmall"
                    color={JoonUIColor.text.secondary}
                  >
                    You can find quest templates filed under the “My Templates”
                    category when you create a new quest.
                  </Typography>
                )}
              </FlexBox>
            )}
          </FlexBox>

          <FlexBox
            direction="column"
            align="flex-end"
            justify="center"
            style={{
              position: "sticky",
              bottom: 0,
              backgroundColor: JoonUIColor.background.primaryNeutral,
              boxShadow: "0px -2px 10px 0px rgba(0, 0, 0, 0.12)",
            }}
          >
            <FlexBox direction="column" align="center">
              {Object.values(errors).map((error) => (
                <Typography
                  variant="bodySmallBold"
                  color={JoonUIColor.semantic.alert}
                >
                  {error}
                </Typography>
              ))}
            </FlexBox>

            <FlexBox
              direction="column"
              gap={SPACING.space4}
              wrap={false}
              style={{
                padding: `${SPACING.space3} ${SPACING.space4} ${SPACING.space3} ${SPACING.space4}`,
                borderTop: `1px solid ${JoonUIColor.border.default}`,
                height: assignedUsers.length > 1 ? "116px" : "69px",
                transition: "height 0.2s ease-in-out",
              }}
            >
              {(!isEditing || selectedQuest.quest_group_id) &&
                assignedUsers.length > 1 && (
                  <FlexBox
                    direction="row"
                    align="center"
                    justify="space-between"
                    fullWidth
                    wrap={false}
                  >
                    <FlexBox
                      direction="row"
                      align="center"
                      justify="flex-start"
                      gap={SPACING.space1}
                      wrap={false}
                      style={{ width: "fit-content" }}
                    >
                      <Typography
                        variant="bodySmallBold"
                        color={JoonUIColor.text.primary}
                      >
                        {isEditing ? "Save" : "Create"} as a Quest Group
                      </Typography>
                      <TextButton
                        onClick={onOpenQuestGroupInfoModal}
                        style={{
                          padding: SPACING.space05,
                          borderRadius: "50%",
                        }}
                      >
                        <InfoSolidIcon
                          size={12}
                          color={JoonUIColor.icon.neutral}
                        />
                      </TextButton>
                    </FlexBox>
                    <Switch
                      checked={isGrouped}
                      name="is-grouped-quest"
                      onChange={() => setIsGrouped(!isGrouped)}
                      disabled={isEditing ? false : assignedUsers.length < 2}
                    />
                  </FlexBox>
                )}
              <Button
                onClick={onCreateQuestClick}
                isLoading={
                  !isEditing
                    ? addQuestMutation.isLoading
                    : editQuestMutation.isLoading
                }
                text={createQuestText}
                style={{
                  minHeight: "44px",
                  maxHeight: "44px",
                  borderRadius: SPACING.space2,
                  position: "sticky",
                  marginTop: "auto",
                  bottom: SPACING.space3,
                }}
                fullWidth
              />
            </FlexBox>
          </FlexBox>
        </ModalContent>
        <QuestPhotoUploadModal />
        <AddSkillModal />
        <MandatoryQuestInfoModal />
        <ReassignQuest />
        <RecurrenceModal />
        <RepeatWeekModal />
        <SpecifyDateModal />
        <DateTimeModal />
        <SetTimeModal />
        <TimerModal />
        <CompletionRequirementModal />
        <QuestGroupInfoModal />
        <ConfirmUngroupModal />
      </>
    </Modal>
  )
}

export default AddEditQuestModal
