import {
  ChevronRightIcon,
  CustomRewardBalance,
  CustomRewardCurrency,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import RewardRedeemButton from "./RewardRedeemButton"
import { useCustomRewardBalancesQuery } from "../../../networking/rewards/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { useRouter } from "../../../util/router"
import { getScreenTimeConfigurations } from "../../../util/util"

type Props = {
  rewardBalance: CustomRewardBalance
}

const RewardRedeemCard = ({ rewardBalance }: Props) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isPressed, setIsPressed] = useState(false)
  const router = useRouter()
  const { data: rewardsBalances } = useCustomRewardBalancesQuery({
    rewardId: rewardBalance.reward.id,
  })
  const { numLinkedDevices } = getScreenTimeConfigurations(
    rewardBalance.reward,
    rewardBalance.user_id
  )

  const rewardCurrency =
    rewardsBalances?.length && rewardsBalances.length > 0
      ? rewardsBalances[0].reward.currency
      : undefined
  const balance = rewardBalance?.balance || 0

  const balanceText =
    rewardCurrency === CustomRewardCurrency.DOLLAR
      ? `$${balance}`
      : rewardCurrency === CustomRewardCurrency.MINUTE
        ? `${balance} min${balance === 1 ? "" : "s"}`
        : `${balance}`

  return (
    <FlexBox
      justify="space-between"
      direction="row"
      align="center"
      wrap={false}
      gap={SPACING.space2}
      onClick={() => {
        trackAnalyticEvent(ANALYTIC_EVENTS.VIEW_CUSTOM_REWARD_DETAILS)
        router.push(`/rewards/details/${rewardBalance.reward.id}`)
      }}
      style={{
        width: "100%",
        padding: `${SPACING.space3} ${SPACING.space4}`,
        border: "1px solid JoonUIColor.border.default",
        borderRadius: SPACING.space2,
        background: isHovered
          ? JoonUIColor.background.xlightGray
          : JoonUIColor.background.primaryNeutral,
        cursor: isHovered ? "pointer" : "default",
        transition: "background 0.1s ease-in-out",
        transform: isPressed ? "translateY(1px)" : "translateY(0px)",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false)
        setIsPressed(false)
      }}
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => setIsPressed(false)}
    >
      <FlexBox
        direction="column"
        align="flex-start"
        justify="center"
        wrap={false}
        gap={SPACING.space1}
      >
        <Typography variant="bodySmallBold" textAlign="left">
          {`${rewardBalance.reward.emoji} ${rewardBalance.reward.title}`}
        </Typography>
        {numLinkedDevices > 0 && (
          <Typography variant="bodySmall" color={JoonUIColor.text.secondary}>
            Smart redemption linked on {numLinkedDevices} device
            {numLinkedDevices === 1 ? "" : "s"}
          </Typography>
        )}
        <Typography
          variant="bodySmallBold"
          style={{
            backgroundColor: rewardBalance?.balance
              ? JoonColorExpanded.green200
              : JoonUIColor.icon.light,
            padding: `${SPACING.space05} ${SPACING.space1}`,
            borderRadius: SPACING.space1,
          }}
        >
          {balanceText}
        </Typography>
      </FlexBox>
      <FlexBox
        direction="row"
        align="center"
        justify="flex-end"
        gap={SPACING.space2}
        wrap={false}
        style={{ width: "fit-content" }}
      >
        <RewardRedeemButton
          rewardBalance={rewardBalance}
          childId={rewardBalance.user_id}
        />
        <ChevronRightIcon size={24} color={JoonUIColor.icon.neutral} />
      </FlexBox>
    </FlexBox>
  )
}

export default RewardRedeemCard
