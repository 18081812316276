import { useEffect } from "react"
import { useShallow } from "zustand/react/shallow"

import { useAddEditQuestStore } from "./useAddEditQuestStore"
import {
  getRepetitionArrayFromRrule,
  getRepetitionIntervalFromRrule,
} from "../../../util/util"

export const useAddEditQuestModal = () => {
  const {
    reqReview,
    reqNotes,
    reqPhoto,

    setQuestName,
    setDescription,
    setPhotoUrl,
    setQuestDifficulty,
    setCoinRewardsMap,
    setCategory,
    setRoutine,
    setRepetition,
    setRepeatsFrequency,
    setIsOngoing,
    setStartDate,
    setReminderTimeMap,
    setTimerLength,
    setMandatory,
    setReqReview,
    setSaveTemplate,
    setReqNotes,
    setReqPhoto,

    selectedQuest,
  } = useAddEditQuestStore(
    useShallow((state) => ({
      reqReview: state.reqReview,
      reqNotes: state.reqNotes,
      reqPhoto: state.reqPhoto,
      setQuestName: state.setQuestName,
      setDescription: state.setDescription,
      setPhotoUrl: state.setPhotoUrl,
      setQuestDifficulty: state.setQuestDifficulty,
      setCoinRewardsMap: state.setCoinRewardsMap,
      setCategory: state.setCategory,
      setRoutine: state.setRoutine,
      setRepetition: state.setRepetition,
      setRepeatsFrequency: state.setRepeatsFrequency,
      setStartDate: state.setStartDate,
      setIsOngoing: state.setIsOngoing,
      setReminderTimeMap: state.setReminderTimeMap,
      setTimerLength: state.setTimerLength,
      setMandatory: state.setMandatory,
      setReqReview: state.setReqReview,
      setSaveTemplate: state.setSaveTemplate,
      setReqNotes: state.setReqNotes,
      setReqPhoto: state.setReqPhoto,
      selectedQuest: state.selectedQuest,
    }))
  )

  // On initial load, make sure the quest details are set
  // Maybe a better way to do this?
  useEffect(() => {
    if (!selectedQuest) return
    const questData = selectedQuest
    const repeatFreq = getRepetitionIntervalFromRrule(questData.recurrence)
    const repetitionArray = getRepetitionArrayFromRrule(questData.recurrence)

    setQuestName(questData.title)
    questData.description && setDescription(questData.description)
    questData.photo_url && setPhotoUrl(questData.photo_url)
    setQuestDifficulty(questData.difficulty)
    setCoinRewardsMap(questData.user_id, questData.redeemable_reward)
    questData.skill && setCategory(questData.skill)
    setRoutine(questData.routine)
    setRepetition(repetitionArray)
    setRepeatsFrequency(repeatFreq)
    if (!repetitionArray.some((day) => day) && questData.end_date === null)
      setIsOngoing(true)
    else setIsOngoing(false)
    questData.start_date && setStartDate(questData.start_date)
    questData.reminder_time &&
      setReminderTimeMap({ [questData.routine]: questData.reminder_time })
    questData.timer_length && setTimerLength(questData.timer_length)
    setMandatory(!!questData.mandatory)
    setReqReview(!!questData.requires_review)
    setReqNotes(!!questData.requires_completion_notes)
    setReqPhoto(!!questData.requires_completion_photo)
    setSaveTemplate(!!questData.template_id)
    //eslint-disable-next-line
  }, [selectedQuest])

  // Ensure that when the quest repeat selection is non repeating,
  // the repetition and repeats frequency are set to null

  useEffect(() => {
    if (!reqReview) {
      reqNotes && setReqNotes(false)
      reqPhoto && setReqPhoto(false)
    }
    // eslint-disable-next-line
  }, [reqReview])
}
