import { JoonUIColor, SPACING } from "@joonapp/web-shared"

import MobileNavLink from "./MobileNavLink"
import useCoachStore from "../../pages/coach/useCoachStore"
import CareIcon from "../icons/CareIcon"
import FamilyIcon from "../icons/FamilyIcon"
import MeIcon from "../icons/MeIcon"
import QuestsIcon from "../icons/QuestsIcon"
import RewardsIcon from "../icons/RewardsIcon"

const MobileNav = () => {
  const { isFocused: userIsTypingMessageToCoach } = useCoachStore()

  if (userIsTypingMessageToCoach) return <></>

  return (
    <div
      style={{
        background: JoonUIColor.background.primaryNeutral,
        position: "fixed",
        bottom: 0,
        width: "100vw",
        padding: `0 ${SPACING.space4}`,
        height: "70px",
        borderTop: `1px solid ${JoonUIColor.border.default}`,
        zIndex: "500",
        display: "flex",
        justifyContent: "space-around",
        boxShadow: "2px 0px 30px 0px rgba(36, 23, 185, 0.25)",
      }}
    >
      <MobileNavLink to="/me" name="Me" Icon={MeIcon} />
      <MobileNavLink to="/care" name="Care" Icon={CareIcon} />
      <MobileNavLink to="/quests" name="Quests" Icon={QuestsIcon} />
      <MobileNavLink to="/rewards" name="Rewards" Icon={RewardsIcon} />
      <MobileNavLink to="/family" name="Family" Icon={FamilyIcon} />
    </div>
  )
}

export default MobileNav
