import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import {
  Button,
  JoonColorExpanded,
  SessionBookingLinkType,
  Typography,
} from "@joonapp/web-shared"

import { getFirstAvailableSession } from "./FTUXOverlay"
import Overlay from "./Overlay"
import { useCoachOverlayStore } from "./useCoachOverlay"
import { CloseButton } from "../../../components/closeButton/CloseButton"
import {
  PhoneNumberModalType,
  useAddPhoneNumberModalStore,
} from "../../../components/modals/addPhoneNumberModal/useAddPhoneNumberModalStore"
import { useUserQuery } from "../../../networking/queries"
import { useSessionBillingPeriodsQuery } from "../../../networking/sessions/queries"
import { CoachOverlayType } from "../../../types"
import { localStorageItems } from "../../../util/storage"
import useCareTeam from "../../care/useCareTeam"
import { useSessionBookingModalStore } from "../sessions/hooks/useSessionBookingModalStore"

const SessionBookingOverlay = () => {
  const { selectedCoachProfile } = useCareTeam()
  const { data: billingPeriods } = useSessionBillingPeriodsQuery()
  const { onOpen: openSessionBookingModal } = useSessionBookingModalStore()
  const { onOpen: openAddPhoneNumberModal } = useAddPhoneNumberModalStore()
  const { user } = useUserQuery()
  const { currentOverlay, setCurrentOverlay, setIsOpenSessionBooking } =
    useCoachOverlayStore()

  if (currentOverlay !== CoachOverlayType.SESSION_BOOKING) return <></>

  const handleClose = () => {
    localStorage.setItem(
      localStorageItems.lastSeenSessionBookingOverlay,
      new Date().toISOString()
    )
    setCurrentOverlay(null)
    setIsOpenSessionBooking(false)
  }

  const handleBookSession = () => {
    const userPhoneNumber = user?.phone_number
    if (!userPhoneNumber) {
      openAddPhoneNumberModal(PhoneNumberModalType.COACH)
      return
    }

    if (!billingPeriods) return
    const firstSession = getFirstAvailableSession(billingPeriods)
    if (!firstSession) return
    openSessionBookingModal(firstSession, SessionBookingLinkType.SCHEDULE)

    handleClose()
  }

  return (
    <Overlay color={JoonColorExpanded.blue100}>
      <div className="flex flex-col gap-4">
        <CloseButton onClick={handleClose} />
        <div className="flex flex-col gap-2">
          <Typography variant="bodyBold">
            🚀 Keep making progress on your parenting journey!
          </Typography>
          <Typography variant="body">
            Book your session to get expert support and parenting guidance from{" "}
            {selectedCoachProfile?.user.name}.
          </Typography>
        </div>

        <Button
          text="Book next session"
          buttonType="primary"
          onClick={handleBookSession}
          fullWidth
          rightIcon={faChevronRight}
        />
      </div>
    </Overlay>
  )
}

export default SessionBookingOverlay
