import {
  Button,
  FlexBox,
  Modal,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { create } from "zustand"

import doterGroup from "../../images/doter-group.svg"
import { sessionManager } from "../../util/storage"

interface FreeTrialFirstVerifyModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useFreeTrialFirstVerifyModalStore =
  create<FreeTrialFirstVerifyModalStore>((set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => {
      sessionManager.clearNeedsToSeeVerifyFreemiumPopup()
      set({ isOpen: false })
    },
  }))

const FreeTrialFirstVerifyModal = () => {
  const { isOpen, onClose } = useFreeTrialFirstVerifyModalStore()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      displayCloseIcon
      animate
      style={{ width: "min(400px, 95vw)" }}
    >
      <FlexBox
        direction="column"
        style={{ padding: SPACING.space6, gap: SPACING.space4 }}
      >
        <img
          src={doterGroup}
          alt="doter group"
          style={{ width: "100%", height: "120px" }}
        />
        <Typography variant="h2" textAlign="center">
          Try Joon!
        </Typography>
        <Typography
          variant="body"
          style={{ margin: "0 auto", width: "250px" }}
          textAlign="center"
        >
          You can verify up to 7 Quests a day without a subscription!
        </Typography>
        <Button text="Got it!" onClick={onClose} fullWidth />
      </FlexBox>
    </Modal>
  )
}

export default FreeTrialFirstVerifyModal
