import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"

import { useDisplayParentTaskSection } from "./useDisplayParentTasksSection"
import AcademyActions from "../../academy/AcademyActions"

const ParentTasksSection = () => {
  const { displayParentTasksSection } = useDisplayParentTaskSection()

  if (!displayParentTasksSection) return <></>

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space4}
      align="center"
      style={{
        border: `1px solid ${JoonUIColor.border.default}`,
        background: JoonUIColor.background.primaryNeutral,
        borderRadius: SPACING.space4,
        padding: SPACING.space4,
      }}
    >
      <FlexBox direction="row" align="center" gap={SPACING.space2} wrap={false}>
        <Typography variant="bodyBold">Your tasks</Typography>
      </FlexBox>
      <AcademyActions />
    </FlexBox>
  )
}

export default ParentTasksSection
