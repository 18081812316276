import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { Button, JoonColorExpanded, Typography } from "@joonapp/web-shared"

import Overlay from "./Overlay"
import { useCoachOverlayStore } from "./useCoachOverlay"
import { useGetCoachSubscriptionQuery } from "../../../networking/coaching/queries"
import { CoachOverlayType } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { localStorageItems } from "../../../util/storage"
import useCareTeam from "../../care/useCareTeam"
import { TOSSigningModal } from "../TOSSigningModal"
import { useTOSSigningModalStore } from "../useTOSSigningModalStore"

const TOSOverlay = () => {
  const { onOpen: openTOSModal } = useTOSSigningModalStore()
  const { selectedCoachProfile } = useCareTeam()
  const { data: coachSubscriptionData, isLoading: isLoadingCoachSubscription } =
    useGetCoachSubscriptionQuery()
  const isMessageOnly =
    !isLoadingCoachSubscription && coachSubscriptionData?.num_sessions === 0

  const { currentOverlay } = useCoachOverlayStore()

  if (currentOverlay !== CoachOverlayType.TOS) return <></>

  // Set the last seen TOS overlay date to today so it doesn't pop up
  localStorage.setItem(
    localStorageItems.lastSeenSessionBookingOverlay,
    new Date().toISOString()
  )

  const onViewAgreement = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.SHOW_COACHING_TOS)
    openTOSModal()
  }

  return (
    <>
      <Overlay color={JoonColorExpanded.yellow200}>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <Typography variant="h4">
              🚀
              {isMessageOnly
                ? " Let's get you ready for your coach!"
                : " Let's get you ready for your first session!"}
            </Typography>
            <Typography variant="body">
              Please review our Coaching Agreement. This outlines what parent
              coaching is, how it works at Joon, and how you and{" "}
              {selectedCoachProfile?.user.name || "your coach"} will work
              together to help you reach your goals.
            </Typography>
          </div>

          <Button
            text="View coaching agreement"
            buttonType="primary"
            onClick={onViewAgreement}
            fullWidth
            rightIcon={faChevronRight}
          />
        </div>
      </Overlay>
      <TOSSigningModal />
    </>
  )
}

export default TOSOverlay
