import { FlexBox, Typography, SPACING } from "@joonapp/web-shared"

import { useCoachSummarizerModalStore } from "./useCoachSummarizerModalStore"
import { CloseButton } from "../../../../components/closeButton/CloseButton"
import SlideUpModal from "../../../../components/slideUpModal/SlideUpModal"
import { useCoachingStore } from "../../useCoachingStore"

export const CoachSummarizerModal = () => {
  const { isOpen, onClose } = useCoachSummarizerModalStore()
  const { selectedCoach } = useCoachingStore()

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      size="fit-content"
      backgroundType="overlay"
      zIndex={1000}
    >
      <CloseButton onClick={onClose} />
      <FlexBox
        direction="column"
        fullWidth
        gap={SPACING.space4}
        style={{ padding: SPACING.space3 }}
      >
        <Typography variant="bodyBold">
          More about Coach {selectedCoach?.user.name}
        </Typography>
        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="bodySmallBold">
            Why Coach {selectedCoach?.user.name} might be a good fit for you:
          </Typography>

          <Typography variant="bodySmall">
            {selectedCoach?.match_reason_long}
          </Typography>
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="bodySmallBold">
            About Coach {selectedCoach?.user.name}
          </Typography>
          <Typography variant="bodySmall">{selectedCoach?.bio}</Typography>
        </FlexBox>
      </FlexBox>
    </SlideUpModal>
  )
}
