import { create } from "zustand"

import {
  ParentDataInterface,
  QuestionAnswerMappingInterface,
} from "../../types"

export enum CoachOnboardingStep {
  COACH_QUESTIONNAIRE_DISCLAIMER,
  POST_ANSWER_SCREEN,
  COACH_QUESTIONNAIRE,
  ADD_CHILDREN_START,
  ADD_CHILDREN,
  COACH_SIGN_UP,
}

export enum ChildStep {
  CHILD_INFO,
  QUESTIONNAIRE_DISCLAIMER,
  QUESTIONNAIRE,
}

interface CoachOnboardingStore {
  step: CoachOnboardingStep
  setStep: (step: CoachOnboardingStep) => void
  coachQuestionnaireStep: number
  setCoachQuestionnaireStep: (step: number) => void
  questionAnswerMapping: QuestionAnswerMappingInterface
  setQuestionAnswerMapping: (mapping: QuestionAnswerMappingInterface) => void
  postAnswerScreenInfo: {
    background_color_hex: string
    background_image_url: string
    button_title: string
  }
  setPostAnswerScreenInfo: (info: {
    background_color_hex: string
    background_image_url: string
    button_title: string
  }) => void

  parentData: ParentDataInterface
  setParentData: (data: ParentDataInterface) => void

  childStep: ChildStep
  setChildStep: (step: ChildStep) => void

  isSubmittingData: boolean
  setIsSubmittingData: (isSubmitting: boolean) => void
}

const useCoachOnboardingStore = create<CoachOnboardingStore>((set) => ({
  step: CoachOnboardingStep.COACH_QUESTIONNAIRE,
  setStep: (step) => set({ step }),

  coachQuestionnaireStep: 0,
  setCoachQuestionnaireStep: (step) => set({ coachQuestionnaireStep: step }),

  questionAnswerMapping: {},
  setQuestionAnswerMapping: (mapping) =>
    set({ questionAnswerMapping: mapping }),
  postAnswerScreenInfo: {
    background_color_hex: "",
    background_image_url: "",
    button_title: "",
  },
  setPostAnswerScreenInfo: (info) => set({ postAnswerScreenInfo: info }),

  parentData: {
    name: "",
    lastName: "",
    nickname: "",
    avatar: "",
    childrenData: [],
    parentQuestionnaireMapping: {},
  },
  setParentData: (data) => set({ parentData: data }),

  childStep: ChildStep.CHILD_INFO,
  setChildStep: (step) => set({ childStep: step }),

  isSubmittingData: false,
  setIsSubmittingData: (isSubmitting) =>
    set({ isSubmittingData: isSubmitting }),
}))

export default useCoachOnboardingStore
