import { faHashtag } from "@fortawesome/duotone-light-svg-icons"
import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import OnboardingPageContentWrapper from "./onboardingPageContentWrapper/OnboardingPageContentWrapper"
import useMediaQuery from "../../hooks/useMediaQuery"
import useOnboardContext from "../../hooks/useOnboardContext"
import { validateAuthCode } from "../../networking/user"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { localStorageItems } from "../../util/storage"
import ChevronBackButton from "../buttons/ChevronBackButton"
import Primary3DButton from "../buttons/Primary3DButton"
import SecondaryTransparentButton from "../buttons/SecondaryTransparentButton"
import TextInputRect from "../textInputRect/TextInputRect"

function OnboardCoparentCodeSection() {
  const { coparentCode, setCoparentCode, nextStep, previousStep } =
    useOnboardContext()
  const codeLength = 8
  const [isLoading, setIsLoading] = useState(false)
  const [codeIsValid, setCodeIsValid] = useState(false)
  const [errorText, setErrorText] = useState("")
  const isSmallMobile = useMediaQuery("(max-width: 500px)")

  useEffect(() => {
    if (coparentCode.length === codeLength) {
      setIsLoading(true)
      validateAuthCode(coparentCode.toUpperCase())
        .then(() => {
          setCodeIsValid(true)
          setErrorText("")
        })
        .catch(() => {
          setErrorText("Invalid code")
        })
        .finally(() => setIsLoading(false))
    } else {
      setCodeIsValid(false)
      setErrorText("")
    }
  }, [coparentCode])

  const onJoinFamily = () => {
    if (coparentCode.length === codeLength) {
      nextStep()
      trackAnalyticEvent(ANALYTIC_EVENTS.ENTER_COPARENT_CODE)
      localStorage.setItem(localStorageItems.isCoparent, "true")
    } else {
      trackAnalyticEvent(ANALYTIC_EVENTS.INVALID_COPARENT_CODE)
      setErrorText("Invalid code")
    }
  }

  return (
    <OnboardingPageContentWrapper
      paddingTop={SPACING.space14}
      backgroundOnDesktop="#f7fafd"
      style={{
        position: "relative",
        minWidth: "min(500px, 100%)",
        maxWidth: "500px",
      }}
    >
      <ChevronBackButton onClick={previousStep} />
      <FlexBox direction="column" align="center" gap={SPACING.space6}>
        <img
          src="/images/gifs/onboarding-tree-clouds-gif.gif"
          alt="existing family"
          style={{ width: isSmallMobile ? "160px" : "200px" }}
        />
        <Typography variant="h2Serif">Join an existing family</Typography>
        <Typography variant="body" textAlign="center" style={{ width: "80%" }}>
          Enter the code that your co-parent has shared with you. You can find
          this code in Family tab → Edit → Add parent.
        </Typography>
        <FlexBox direction="column" align="flex-start" gap={SPACING.space2}>
          <Typography variant="body">8 character parent invite code</Typography>
          <TextInputRect
            name="Coparent Code"
            value={coparentCode}
            onChange={(e) => setCoparentCode(e.target.value)}
            maxLength={8}
            placeholder="ABCD1234"
            icon={faHashtag}
            fullWidth
          />
          {errorText && (
            <Typography
              variant="bodySmallBold"
              color={JoonUIColor.semantic.alert}
              style={{ marginTop: SPACING.space1 }}
            >
              {errorText}
            </Typography>
          )}
        </FlexBox>
      </FlexBox>

      <FlexBox direction="column" align="center" gap={SPACING.space3}>
        <Primary3DButton
          onClick={onJoinFamily}
          isLoading={isLoading}
          disabled={!codeIsValid}
          fullWidth
          text="Join family"
        />
        <SecondaryTransparentButton
          fullWidth
          onClick={previousStep}
          text="Sign up manually instead"
        />
        <Typography variant="bodyXSmall" textAlign="center">
          Your answers are never shared with anyone without your consent.
        </Typography>
      </FlexBox>
    </OnboardingPageContentWrapper>
  )
}

export default OnboardCoparentCodeSection
