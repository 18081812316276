import {
  faArrowsRotate,
  faThumbsDown,
  faThumbsUp,
  faCheck,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  CloseIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Shadow,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"

import useQuestReview from "./useQuestReview"
import useQuestReviewStore from "./useQuestReviewStore"
import Card from "../../../components/card/Card"
import useMobile from "../../../hooks/useMobile"
import { useSubscription } from "../../../hooks/useSubscription"
import { useQuestReviewQuery } from "../../../networking/queries"
import { groupQuestsByDate } from "../../../util/util"
import { QuestCardStatusOverlay } from "../../me/CatchUpCompleteContent"
import CatchUpTabBar from "../../me/CatchUpTabBar"
import { QuestCardTitle } from "../QuestCard"

const QuestReviewModalContent = () => {
  const { selectedQuestIds, setSelectedQuestIds, onClose } =
    useQuestReviewStore()

  const { data: questsToReview } = useQuestReviewQuery()
  const isMobile = useMobile()
  const questsGroupedByDate = groupQuestsByDate(questsToReview ?? [])

  return (
    <>
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        wrap={false}
        style={{
          padding: SPACING.space4,
          overflowY: isMobile ? "unset" : "auto",
        }}
      >
        <FlexBox
          direction="row"
          wrap={false}
          justify="space-between"
          align="center"
        >
          <Typography variant="h3">
            {questsToReview?.length} Quest
            {questsToReview?.length === 1 ? " " : "s "}
            to review
          </Typography>
          <button onClick={onClose} style={{ padding: 0 }}>
            <CloseIcon color={JoonUIColor.icon.neutral} size={24} />
          </button>
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space4} wrap={false}>
          {questsGroupedByDate.map(([date, quests], i) => (
            <FlexBox
              direction="column"
              gap={SPACING.space1}
              wrap={false}
              key={i}
            >
              <Typography
                variant="bodySmall"
                color={JoonUIColor.text.secondary}
              >
                Assigned: {dayjs(date).format("MMMM D, YYYY")}
              </Typography>
              {quests.map((quest, i) => (
                <div style={{ position: "relative", width: "100%" }} key={i}>
                  {/* @ts-ignore */}
                  {quest.temp_status && (
                    // @ts-ignore
                    <QuestCardStatusOverlay questStatus={quest.temp_status} />
                  )}
                  <Card
                    title={<QuestCardTitle questInstance={quest} />}
                    subtitle={
                      quest.series.assigner_profile === null
                        ? "Self initiated"
                        : `Completed ${dayjs.unix(Number(quest.completion_date)).format("MMM DD")} at ${dayjs.unix(Number(quest.completion_date)).format("h:mm A")}`
                    }
                    userIds={[quest.user_id]}
                    isChecked={selectedQuestIds.includes(quest.id)}
                    onClick={() =>
                      setSelectedQuestIds(
                        selectedQuestIds.includes(quest.id)
                          ? selectedQuestIds.filter((id) => id !== quest.id)
                          : [...selectedQuestIds, quest.id]
                      )
                    }
                    coinAmount={quest.series?.redeemable_reward}
                  />
                </div>
              ))}
            </FlexBox>
          ))}
        </FlexBox>
      </FlexBox>
      <CatchUpTabBar>
        <FlexBox direction="column">
          <FlexBox
            direction="column"
            align="center"
            wrap={false}
            style={{ padding: SPACING.space2, paddingTop: 0 }}
          >
            <Typography variant="bodySmallBold">
              {selectedQuestIds.length > 0
                ? `${selectedQuestIds.length} Quest${
                    selectedQuestIds.length === 1 ? "" : "s"
                  } selected`
                : "Tap a Quest to verify individually"}
            </Typography>
            <FlexBox
              direction="row"
              wrap={false}
              gap={SPACING.space2}
              justify="space-around"
            >
              <RejectQuestsButton />
              <RetryQuestsButton />
              <ApproveQuestsButton />
            </FlexBox>
          </FlexBox>
          <FlexBox
            direction="row"
            wrap={false}
            align="center"
            style={{
              padding: SPACING.space2,
              borderTop: `1px solid ${JoonUIColor.border.default}`,
            }}
          >
            <Typography
              variant="bodySmallBold"
              color={JoonUIColor.text.secondary}
              style={{ padding: SPACING.space2 }}
            >
              or
            </Typography>
            <ApproveAllButton />
          </FlexBox>
        </FlexBox>
      </CatchUpTabBar>
    </>
  )
}

export default QuestReviewModalContent

const RejectQuestsButton = () => {
  const { selectedQuestIds } = useQuestReviewStore()
  const { rejectQuestsMutation } = useQuestReview()

  const buttonsAreEnabled = selectedQuestIds.length > 0

  const onClickReject = async () => {
    if (!buttonsAreEnabled) return
    rejectQuestsMutation.mutateAsync({ questInstanceIds: selectedQuestIds })
  }

  return (
    <button
      onClick={onClickReject}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: SPACING.space2,
        padding: `${SPACING.space2} ${SPACING.space4}`,
        borderRadius: SPACING.space2,
        cursor: buttonsAreEnabled ? "pointer" : "default",
        opacity: buttonsAreEnabled ? 1 : 0.6,
        flex: 1,
      }}
    >
      <FontAwesomeIcon
        icon={faThumbsDown}
        color={
          buttonsAreEnabled
            ? JoonUIColor.semantic.alert
            : JoonUIColor.icon.neutral
        }
        fontSize="24px"
      />
      <Typography variant="bodySmallBold" color={JoonUIColor.text.disabled}>
        Reject
      </Typography>
    </button>
  )
}

const RetryQuestsButton = () => {
  const { selectedQuestIds } = useQuestReviewStore()
  const { retryQuestsMutation } = useQuestReview()

  const buttonsAreEnabled = selectedQuestIds.length > 0

  const onClickRetry = async () => {
    if (!buttonsAreEnabled) return
    retryQuestsMutation.mutateAsync({ questInstanceIds: selectedQuestIds })
  }

  return (
    <button
      onClick={buttonsAreEnabled ? onClickRetry : undefined}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: SPACING.space2,
        padding: `${SPACING.space2} ${SPACING.space4}`,
        borderRadius: SPACING.space2,
        cursor: buttonsAreEnabled ? "pointer" : "default",
        opacity: buttonsAreEnabled ? 1 : 0.6,
        flex: 1,
      }}
    >
      <FontAwesomeIcon
        icon={faArrowsRotate}
        color={
          buttonsAreEnabled ? JoonUIColor.icon.accent : JoonUIColor.icon.neutral
        }
        fontSize="24px"
      />
      <Typography variant="bodySmallBold" color={JoonUIColor.text.disabled}>
        Retry
      </Typography>
    </button>
  )
}

const ApproveQuestsButton = () => {
  const { selectedQuestIds } = useQuestReviewStore()
  const { verifyAllMutation } = useQuestReview()
  const { checkRemainingFreeQuests } = useSubscription()

  const buttonsAreEnabled = selectedQuestIds.length > 0

  const onClickApprove = () => {
    if (!buttonsAreEnabled) return
    checkRemainingFreeQuests(selectedQuestIds.length)
    verifyAllMutation.mutate({ questInstanceIds: selectedQuestIds })
  }

  return (
    <button
      onClick={onClickApprove}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: SPACING.space2,
        padding: `${SPACING.space2} ${SPACING.space4}`,
        borderRadius: SPACING.space2,
        cursor: buttonsAreEnabled ? "pointer" : "default",
        background: buttonsAreEnabled ? JoonColorExpanded.viridian100 : "unset",
        boxShadow: buttonsAreEnabled ? Shadow.medium : "unset",
        opacity: buttonsAreEnabled ? 1 : 0.6,
        flex: 1,
      }}
    >
      <FontAwesomeIcon
        icon={faCheck}
        color={
          buttonsAreEnabled
            ? JoonUIColor.semantic.success
            : JoonUIColor.icon.neutral
        }
        fontSize="24px"
      />
      <Typography
        variant="bodySmallBold"
        color={
          buttonsAreEnabled
            ? JoonUIColor.semantic.success
            : JoonUIColor.icon.neutral
        }
      >
        Approve
      </Typography>
    </button>
  )
}

const ApproveAllButton = () => {
  const { data: questsToReview } = useQuestReviewQuery()
  const { selectedQuestIds } = useQuestReviewStore()
  const { checkRemainingFreeQuests } = useSubscription()
  const { verifyAllMutation } = useQuestReview()

  const buttonsAreEnabled = selectedQuestIds.length === 0

  const onClickApproveAll = () => {
    if (!buttonsAreEnabled) return
    checkRemainingFreeQuests(questsToReview?.length)
    const questIdsToVerify = questsToReview?.map((quest) => quest.id) ?? []
    verifyAllMutation.mutate({ questInstanceIds: questIdsToVerify })
  }

  return (
    <button
      onClick={onClickApproveAll}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: SPACING.space2,
        padding: SPACING.space4,
        borderRadius: SPACING.space2,
        cursor: buttonsAreEnabled ? "pointer" : "default",
        background: buttonsAreEnabled
          ? JoonColorExpanded.viridian100
          : JoonUIColor.background.lightGray,
        boxShadow: buttonsAreEnabled ? Shadow.medium : "unset",
        flex: 1,
      }}
    >
      <FontAwesomeIcon
        icon={faThumbsUp}
        color={
          buttonsAreEnabled
            ? JoonUIColor.semantic.success
            : JoonUIColor.icon.neutral
        }
        fontSize="24px"
      />
      <Typography
        variant="bodySmallBold"
        color={
          buttonsAreEnabled
            ? JoonUIColor.semantic.success
            : JoonUIColor.icon.neutral
        }
      >
        Approve all
      </Typography>
    </button>
  )
}
