import {
  faFaceSmileHorns,
  faFaceAnxiousSweat,
  faFaceDotted,
  faPersonHarassing,
  faScalpelLineDashed,
  faVenusMars,
  faJoint,
  faHandFist,
  faFaceSwear,
  faFlag,
} from "@fortawesome/duotone-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import { AIPromptFlag } from "../../../../types"

const DoterChatFlag = ({ type }: { type: AIPromptFlag }) => {
  const { icon, color, text } = flagContent[type] || {
    icon: faFlag,
    color: JoonColorExpanded.neutral400,
    text: "Other",
  }

  return (
    <FlexBox
      style={{
        borderRadius: SPACING.space2,
        backgroundColor: color,
        padding: "4px 6px",
        width: "fit-content",
        gap: SPACING.space1,
      }}
    >
      <FontAwesomeIcon
        icon={icon}
        fontSize={15}
        color={JoonUIColor.text.inverted}
      />
      <Typography
        variant="bodyXSmall"
        style={{ fontWeight: 500 }}
        color={JoonUIColor.text.inverted}
      >
        {text}
      </Typography>
    </FlexBox>
  )
}

export default DoterChatFlag

export const flagContent = {
  [AIPromptFlag.BULLYING]: {
    icon: faFaceSmileHorns,
    color: JoonColorExpanded.orange400,
    text: "Bullying",
  },
  [AIPromptFlag.ANXIETY]: {
    icon: faFaceAnxiousSweat,
    color: JoonColorExpanded.blue500,
    text: "Anxiety",
  },
  [AIPromptFlag.DEPRESSION]: {
    icon: faFaceDotted,
    color: JoonColorExpanded.indigo600,
    text: "Depression",
  },
  [AIPromptFlag.ABUSE]: {
    icon: faPersonHarassing,
    color: JoonColorExpanded.pink600,
    text: "Abuse",
  },
  [AIPromptFlag.SELF_HARM]: {
    icon: faScalpelLineDashed,
    color: JoonColorExpanded.red500,
    text: "Self harm",
  },
  [AIPromptFlag.SEXUAL_CONTENT]: {
    icon: faVenusMars,
    color: JoonColorExpanded.pink700,
    text: "Sexual content",
  },
  [AIPromptFlag.SUBSTANCE]: {
    icon: faJoint,
    color: JoonColorExpanded.orange600,
    text: "Substance",
  },
  [AIPromptFlag.VIOLENCE]: {
    icon: faHandFist,
    color: JoonColorExpanded.neutral600,
    text: "Violence",
  },
  [AIPromptFlag.PROFANITY]: {
    icon: faFaceSwear,
    color: JoonColorExpanded.blue700,
    text: "Profanity",
  },
}
