import {
  Button,
  SPACING,
  FlexBox,
  JoonUIColor,
  Typography,
  capitalizeFirstLetter,
} from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import { BACKGROUND_COLORS } from "../../../constants"
import useMediaQuery from "../../../hooks/useMediaQuery"
import useMobile from "../../../hooks/useMobile"
import useOnboardContext from "../../../hooks/useOnboardContext"
import {
  AnswerInterface,
  QuestionAnswerMappingInterface,
  QuestionInterface,
  QuestionType,
} from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import ChevronBackButton from "../../buttons/ChevronBackButton"
import Primary3DButton from "../../buttons/Primary3DButton"
import LinearProgress from "../../linearProgress/LinearProgress"
import PageLoader from "../../loading/PageLoader"
import QuestionnaireCheckbox from "../../questionnaireCheckbox/questionnaireCheckbox"
import OnboardingPageContentWrapper from "../onboardingPageContentWrapper/OnboardingPageContentWrapper"

interface AnswerButtonProps {
  didSelectAnswer: (answer: AnswerInterface) => void
  answer: AnswerInterface
}

function AnswerButton({ didSelectAnswer, answer }: AnswerButtonProps) {
  const [isHovered, setIsHovered] = useState(false)
  const [isSelected, setIsSelected] = useState(false)

  const selectAnswer = () => {
    if (isSelected) return

    setIsSelected(true)
    setTimeout(function () {
      setIsSelected(false)
      didSelectAnswer(answer)
    }, 250)
  }

  const buttonStyles = isSelected
    ? {
        border: `1px solid ${JoonUIColor.border.accent}`,
        color: JoonUIColor.text.primaryAccent,
        boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.1)",
      }
    : {
        border: `1px solid ${JoonUIColor.border.default}`,
        color: JoonUIColor.text.primary,
        boxShadow: "none",
      }

  return (
    <button
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        background:
          !isHovered || isSelected
            ? JoonUIColor.background.primaryNeutral
            : JoonUIColor.background.xlightGray,
        borderRadius: SPACING.space2,
        padding: SPACING.space4,
        width: "100%",
        minHeight: "56px",
        fontSize: "16px",
        textAlign: "left",
        transition:
          "border, color, box-shadow 0.15s ease-in-out, background 0.3s ease-in-out",
        userSelect: "none",
        pointerEvents: isSelected ? "none" : "auto",
        cursor: isSelected ? "not-allowed" : "pointer",
        ...buttonStyles,
      }}
      onClick={selectAnswer}
    >
      {answer.answer}
    </button>
  )
}

interface QuestionnaireSectionProps {
  questions: QuestionInterface[] | []
  onConfirm: ({ questionAnswerMapping }: any) => void
  previousStep: () => void
  lastSeenStep?: number
}

function QuestionnaireSection({
  questions,
  onConfirm,
  previousStep,
  lastSeenStep,
}: QuestionnaireSectionProps) {
  const [questionAnswerMapping, setQuestionAnswerMapping] =
    useState<QuestionAnswerMappingInterface>({})
  const { postAnswerScreen, setPostAnswerScreen } = useOnboardContext()
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(
    lastSeenStep || 0
  )
  const isMobile = useMobile()
  const isSmallMobile = useMediaQuery("(max-width: 500px)")

  const goToPreviousStep = () => {
    if (postAnswerScreen) {
      setPostAnswerScreen(null)
    } else if (currentQuestionIndex === 0) previousStep()
    else setCurrentQuestionIndex(currentQuestionIndex - 1)
  }

  const didSelectAnswer = (answer: AnswerInterface) => {
    const newMapping = {
      ...questionAnswerMapping,
      [currentQuestion.id]: [answer.id],
    }
    trackAnalyticEvent(
      ANALYTIC_EVENTS.ONBOARDING_QUESTION(currentQuestion.id),
      { answers: [answer.id] }
    )
    setQuestionAnswerMapping(newMapping)

    if (answer.post_answer_screen) {
      setPostAnswerScreen(answer)
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    }
  }

  const toggleMultiSelectAnswer = (answer: AnswerInterface) => {
    if (typeof currentAnswers === "string") return
    const newAnswers = currentAnswers.includes(answer.id)
      ? currentAnswers.filter((id) => id !== answer.id)
      : [...currentAnswers, answer.id]
    const newMapping = {
      ...questionAnswerMapping,
      [currentQuestion.id]: newAnswers,
    }
    trackAnalyticEvent(
      ANALYTIC_EVENTS.ONBOARDING_QUESTION(currentQuestion.id),
      { answers: newAnswers }
    )
    setQuestionAnswerMapping(newMapping)
  }

  const onSubmitMultiSelect = () => {
    // just chooses one answer to check for post answer screen
    const answer = currentQuestion.answers.find(
      (answer) => answer.id === questionAnswerMapping[currentQuestion.id]?.[0]
    )
    if (answer && answer.post_answer_screen) {
      setPostAnswerScreen(answer)
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    }
  }

  const handlePostAnswerContinue = () => {
    setPostAnswerScreen(null)
    setCurrentQuestionIndex(currentQuestionIndex + 1)
  }

  useEffect(() => {
    if (questions.length > 0 && currentQuestionIndex >= questions.length) {
      Object.entries(questionAnswerMapping).forEach(([questionId, answerIds]) =>
        trackAnalyticEvent(
          ANALYTIC_EVENTS.ONBOARDING_QUESTION(Number(questionId)),
          { answers: answerIds }
        )
      )

      onConfirm({ questionAnswerMapping })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionIndex])

  if (currentQuestionIndex >= questions.length) return <PageLoader />

  const currentQuestion = questions[currentQuestionIndex]
  const currentAnswers = questionAnswerMapping[currentQuestion.id] || []

  const { question_subtext, question, type } = currentQuestion

  return (
    <OnboardingPageContentWrapper
      backgroundImageOnDesktop={
        postAnswerScreen ? "url(/images/background/cloud-bg.png)" : undefined
      }
      backgroundOnDesktop={
        postAnswerScreen ? undefined : BACKGROUND_COLORS.lightBlueBg
      }
      paddingTop="10dvh"
      style={{
        position: "relative",
        justifyContent: "flex-start",
        ...(type === QuestionType.SINGLE_CHOICE
          ? {
              paddingLeft: SPACING.space6,
              paddingRight: SPACING.space6,
              paddingBottom: SPACING.space6,
            }
          : { paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }),
      }}
    >
      <FlexBox
        direction="row"
        align="center"
        gap={SPACING.space2}
        fullWidth
        wrap={false}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          padding: `${SPACING.space4} ${SPACING.space6}`,
        }}
      >
        <ChevronBackButton
          onClick={goToPreviousStep}
          style={{ position: "relative", top: "", left: "" }}
        />
        <div style={{ width: "100%" }}>
          <LinearProgress
            value={(currentQuestionIndex / (questions.length - 1)) * 100}
            withIcon
          />
        </div>
      </FlexBox>
      {postAnswerScreen ? (
        <QuestionnairePostAnswerScreen onContinue={handlePostAnswerContinue} />
      ) : (
        <FlexBox
          direction="column"
          align="center"
          justify="flex-start"
          gap={SPACING.space6}
          wrap={false}
          fullWidth
          style={{ height: "100%" }}
        >
          <FlexBox direction="column" gap={SPACING.space05} fullWidth>
            {question_subtext && (
              <Typography
                variant="bodySmall"
                textAlign="center"
                color={JoonUIColor.text.secondary}
              >
                {question_subtext}...
              </Typography>
            )}
            <Typography
              variant="h3"
              textAlign="center"
              style={{
                fontFamily: "Lora",
                paddingLeft:
                  type === QuestionType.MULTI_CHOICE ? SPACING.space6 : 0,
                paddingRight:
                  type === QuestionType.MULTI_CHOICE ? SPACING.space6 : 0,
              }}
            >
              {question_subtext
                ? capitalizeFirstLetter(
                    question.replace(question_subtext, "").trim()
                  )
                : question}
            </Typography>
          </FlexBox>
          {type === QuestionType.SINGLE_CHOICE ? (
            <FlexBox direction="column" gap={SPACING.space2} fullWidth>
              {currentQuestion.answers.map((answer) => (
                <AnswerButton
                  key={answer.id}
                  answer={answer}
                  didSelectAnswer={didSelectAnswer}
                />
              ))}
            </FlexBox>
          ) : type === QuestionType.MULTI_CHOICE ? (
            <FlexBox
              direction="column"
              justify="space-between"
              gap={SPACING.space4}
              fullWidth
              style={{
                position: "relative",
                height: "100%",
              }}
              wrap={false}
            >
              <FlexBox
                direction="column"
                gap={SPACING.space2}
                fullWidth
                wrap={false}
                style={{
                  paddingLeft: SPACING.space6,
                  paddingRight: SPACING.space6,
                  paddingBottom: SPACING.space6,
                }}
              >
                {currentQuestion.answers.map((answer, index) => (
                  <QuestionnaireCheckbox
                    key={index}
                    selected={
                      Array.isArray(currentAnswers) &&
                      currentAnswers.includes(answer.id)
                    }
                    onChange={() => toggleMultiSelectAnswer(answer)}
                    label={answer.answer}
                  />
                ))}
              </FlexBox>

              <div
                style={{
                  opacity:
                    !currentAnswers || currentAnswers.length === 0 ? 0 : 1,
                  width: "100%",
                  position: isMobile ? "sticky" : "relative",
                  bottom: 0,
                  padding: SPACING.space6,
                  backgroundColor: isSmallMobile
                    ? BACKGROUND_COLORS.xlightBlueBg
                    : BACKGROUND_COLORS.lightBlueBg,
                  boxShadow: isSmallMobile
                    ? `0px -8px 12px 0px ${JoonUIColor.background.lightAccent}`
                    : "none",
                  pointerEvents:
                    !currentAnswers || currentAnswers.length === 0
                      ? "none"
                      : "auto",
                }}
              >
                <Button
                  text="Next"
                  fullWidth
                  onClick={onSubmitMultiSelect}
                  style={{ borderRadius: SPACING.space2 }}
                />
              </div>
            </FlexBox>
          ) : (
            <></>
          )}
        </FlexBox>
      )}
    </OnboardingPageContentWrapper>
  )
}

interface QuestionnairePostAnswerScreenProps {
  onContinue: () => void
}

const QuestionnairePostAnswerScreen = ({
  onContinue,
}: QuestionnairePostAnswerScreenProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const { postAnswerScreen } = useOnboardContext()

  useEffect(() => {
    if (!postAnswerScreen || !postAnswerScreen.post_answer_screen) {
      onContinue()
    }
  }, [postAnswerScreen, onContinue])

  if (!postAnswerScreen || !postAnswerScreen.post_answer_screen) {
    return null
  }

  return (
    <FlexBox
      direction="column"
      align="center"
      justify="space-between"
      gap={SPACING.space4}
      style={{
        width: "100%",
        height: "100%",
        textAlign: "center",
      }}
    >
      <FlexBox direction="column" align="center" gap={SPACING.space4}>
        <img
          src={
            postAnswerScreen.post_answer_screen.gif_key
              ? `/images/gifs/${postAnswerScreen.post_answer_screen.gif_key}.gif`
              : postAnswerScreen.post_answer_screen.image_url ??
                "/images/tree.png"
          }
          alt=""
          style={{
            opacity: isLoading ? 0 : 1,
            width: "min(200px, 50%)",
            height: "auto",
            userSelect: "none",
            pointerEvents: "none",
            transition: "opacity 0.1s ease-in-out",
          }}
          onLoad={() => setIsLoading(false)}
        />
        <Typography variant="h2Serif" style={{ width: "80%" }}>
          {postAnswerScreen.post_answer_screen.title}
        </Typography>
        <Typography variant="body" style={{ width: "80%" }}>
          {postAnswerScreen.post_answer_screen.description}
        </Typography>
      </FlexBox>
      <Primary3DButton
        text={postAnswerScreen.post_answer_screen.button_title}
        fullWidth
        onClick={onContinue}
      />
    </FlexBox>
  )
}

export default QuestionnaireSection
