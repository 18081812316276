import { useQueryClient } from "@tanstack/react-query"
import React, { useEffect } from "react"

import {
  ANALYTIC_EVENTS,
  analyticsUserDidLogout,
  trackAnalyticEvent,
} from "./analytics"
import { useExperimentsQuery } from "./experiments"
import { history, useRouter } from "./router"
import { localStorageItems, sessionManager } from "./storage"
import PageLoader from "../components/loading/PageLoader"
import { useUserQuery } from "../networking/queries"

export const useAuth = () => {
  const queryClient = useQueryClient()

  function signout() {
    analyticsUserDidLogout()
    sessionManager.clearAuthTokens()
    window.location.href = "/welcome"
    localStorage.removeItem(localStorageItems.lastSeenPaywall)
    queryClient.removeQueries()
  }

  return {
    signout,
  }
}

export const requireAuth = (Component: React.FC) => (props: any) => {
  // Get authenticated user
  const auth = useAuth()
  const { user } = useUserQuery()
  useExperimentsQuery()
  const router = useRouter()

  const hasRefreshToken = sessionManager.getRefreshToken()

  const { priceId, campaign, existing_user } = router.query

  useEffect(() => {
    if (hasRefreshToken) return

    const isEmailDiscountLink = !!priceId || !!existing_user
    const currentPath = router.location.pathname + router.location.search
    const shouldSaveRedirect = !user && (campaign || isEmailDiscountLink)

    if (shouldSaveRedirect) {
      trackAnalyticEvent(ANALYTIC_EVENTS.CAMPAIGN_REDIRECT, {
        campaign,
      })
      localStorage.setItem(localStorageItems.redirectLink, currentPath)
    }

    // Send user to signin for pricing/TPK flows, otherwise to welcome page
    const redirectTo = isEmailDiscountLink ? "/auth/signin" : "/welcome"
    history.replace(redirectTo)

    // eslint-disable-next-line
  }, [auth, router])

  if (!user) return <PageLoader />

  // Render component now that we have user
  return <Component {...props} />
}
