import { CustomRewardCurrency } from "@joonapp/web-shared"
import { useEffect } from "react"

import { SmartRedemptionModalStep } from "../../../constants"
import { useCustomRewardsQuery } from "../../../networking/rewards/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { useRouter } from "../../../util/router"
import { localStorageItems } from "../../../util/storage"
import { useSmartRedemptionModalStore } from "../redeem/SmartRedemptionModal"

export const useShowInitialSmartRedemptionModal = () => {
  const {
    isOpen: isOpenSmartRedemptionModal,
    onOpen: onOpenSmartRedemptionModal,
    setIsPostCreate,
  } = useSmartRedemptionModalStore()
  const { data: rewards } = useCustomRewardsQuery()
  const router = useRouter()

  const minuteReward = rewards?.find(
    (reward) =>
      reward.assignments.length > 0 &&
      reward.assignments[0].screen_time_configurations.length === 0 &&
      reward.currency === CustomRewardCurrency.MINUTE
  )

  return useEffect(() => {
    const hasSeenSmartRedemptionModal = localStorage.getItem(
      localStorageItems.hasSeenSmartRedemptionModal
    )
    if (!hasSeenSmartRedemptionModal && !isOpenSmartRedemptionModal) {
      localStorage.setItem(
        localStorageItems.hasSeenSmartRedemptionModal,
        "true"
      )
      trackAnalyticEvent(ANALYTIC_EVENTS.VIEW_SCREENTIME_FTUX)
      setIsPostCreate(false)
      if (minuteReward) {
        router.push(`/rewards/details/${minuteReward.id}`)
        onOpenSmartRedemptionModal(SmartRedemptionModalStep.INTRO)
      } else {
        onOpenSmartRedemptionModal(SmartRedemptionModalStep.ANNOUNCEMENT)
      }
    }
  }, []) // eslint-disable-line
}
