import { IconProps, JoonUIColor } from "@joonapp/web-shared"

const CareIcon = ({
  color = JoonUIColor.icon.accent,
  size = 30,
}: IconProps) => {
  const alpha = Math.round(0.4 * 255)
    .toString(16)
    .padStart(2, "0")
  const secondaryColor = `${color}${alpha}`

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2241_2415)">
        <path
          d="M10.5 12C8.9087 12 7.38258 11.3679 6.25736 10.2426C5.13214 9.11742 4.5 7.5913 4.5 6C4.5 4.4087 5.13214 2.88258 6.25736 1.75736C7.38258 0.632141 8.9087 0 10.5 0C12.0913 0 13.6174 0.632141 14.7426 1.75736C15.8679 2.88258 16.5 4.4087 16.5 6C16.5 7.5913 15.8679 9.11742 14.7426 10.2426C13.6174 11.3679 12.0913 12 10.5 12Z"
          fill={secondaryColor}
        />
        <path
          d="M6 6C6 6.59095 6.1164 7.17611 6.34254 7.72208C6.56869 8.26804 6.90016 8.76412 7.31802 9.18198C7.73588 9.59984 8.23196 9.93131 8.77792 10.1575C9.32389 10.3836 9.90905 10.5 10.5 10.5C11.0909 10.5 11.6761 10.3836 12.2221 10.1575C12.768 9.93131 13.2641 9.59984 13.682 9.18198C14.0998 8.76412 14.4313 8.26804 14.6575 7.72208C14.8836 7.17611 15 6.59095 15 6C15 5.40905 14.8836 4.82389 14.6575 4.27792C14.4313 3.73196 14.0998 3.23588 13.682 2.81802C13.2641 2.40016 12.768 2.06869 12.2221 1.84254C11.6761 1.6164 11.0909 1.5 10.5 1.5C9.90905 1.5 9.32389 1.6164 8.77792 1.84254C8.23196 2.06869 7.73588 2.40016 7.31802 2.81802C6.90016 3.23588 6.56869 3.73196 6.34254 4.27792C6.1164 4.82389 6 5.40905 6 6ZM10.5 12C8.9087 12 7.38258 11.3679 6.25736 10.2426C5.13214 9.11742 4.5 7.5913 4.5 6C4.5 4.4087 5.13214 2.88258 6.25736 1.75736C7.38258 0.632141 8.9087 0 10.5 0C12.0913 0 13.6174 0.632141 14.7426 1.75736C15.8679 2.88258 16.5 4.4087 16.5 6C16.5 7.5913 15.8679 9.11742 14.7426 10.2426C13.6174 11.3679 12.0913 12 10.5 12Z"
          fill={color}
        />
        <path
          d="M0.925 22.5721C0.925 18.1931 4.37942 14.675 8.60781 14.675H12.8922C17.1206 14.675 20.575 18.1931 20.575 22.5721C20.575 23.004 20.2378 23.325 19.8578 23.325H1.64219C1.26224 23.325 0.925 23.004 0.925 22.5721Z"
          fill={secondaryColor}
          stroke={color}
          strokeWidth="1.35"
        />
        <path
          d="M17.4484 12.7875L16.9984 13.125L16.6 13.425C16.4875 13.5094 16.3328 13.5235 16.2062 13.4625C16.0797 13.4016 16 13.2704 16 13.125V12.6235V12.061V11.9954V10.4954H15.25C14.0078 10.4954 13 9.48755 13 8.24536V2.24536C13 1.00317 14.0078 -0.00463867 15.25 -0.00463867L22.75 4.88283e-05C23.9922 4.88283e-05 25 1.00786 25 2.25005V8.25005C25 9.49224 23.9922 10.5 22.75 10.5H20.5L17.5 12.75L17.4484 12.7875Z"
          fill={"white"}
        />
        <path
          d="M17.4484 12.7875L16.9984 13.125L16.6 13.425C16.4875 13.5094 16.3328 13.5235 16.2062 13.4625C16.0797 13.4016 16 13.2704 16 13.125V12.6235V12.061V11.9954V10.4954H15.25C14.0078 10.4954 13 9.48755 13 8.24536V2.24536C13 1.00317 14.0078 -0.00463867 15.25 -0.00463867L22.75 4.88283e-05C23.9922 4.88283e-05 25 1.00786 25 2.25005V8.25005C25 9.49224 23.9922 10.5 22.75 10.5H20.5L17.5 12.75L17.4484 12.7875Z"
          fill={secondaryColor}
        />
        <path
          d="M20.5 9.00005H22.75C23.1625 9.00005 23.5 8.66255 23.5 8.25005V2.25005C23.5 1.83755 23.1625 1.50005 22.75 1.50005H15.25C14.8375 1.50005 14.5 1.83755 14.5 2.25005V8.25005C14.5 8.66255 14.8375 9.00005 15.25 9.00005H16C16.8297 9.00005 17.5 9.67036 17.5 10.5V10.875L19.6 9.30005C19.8578 9.10317 20.1766 9.00005 20.5 9.00005ZM17.4484 12.7875L16.9984 13.125L16.6 13.425C16.4875 13.5094 16.3328 13.5235 16.2062 13.4625C16.0797 13.4016 16 13.2704 16 13.125V12.6235V12.061V11.9954V10.4954H15.25C14.0078 10.4954 13 9.48755 13 8.24536V2.24536C13 1.00317 14.0078 -0.00463867 15.25 -0.00463867L22.75 4.88283e-05C23.9922 4.88283e-05 25 1.00786 25 2.25005V8.25005C25 9.49224 23.9922 10.5 22.75 10.5H20.5L17.5 12.75L17.4484 12.7875Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default CareIcon
