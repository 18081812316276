import {
  Checkbox,
  CustomRewardCurrency,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import { useJooniperPrepaywallModalStore } from "./useJooniperPrepaywallModalStore"
import UserBadge from "../../../components/badges/userBadge/UserBadge"
import { useFamilyQuery } from "../../../networking/queries"
import { FamilyQuerySelect } from "../../../types"
import { OnboardingCustomRewardTemplateWithUserIds } from "../../../types/rewards"

export const JooniperDraftRewardCard = ({
  reward,
}: {
  reward: OnboardingCustomRewardTemplateWithUserIds
}) => {
  const { selectedRewardTemplateIds, toggleRewardTemplateId } =
    useJooniperPrepaywallModalStore()
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const assignedChildIds = children?.filter((child) =>
    reward.user_ids.includes(child.user.id)
  )

  const isSelected = selectedRewardTemplateIds.includes(reward.id)

  if (!assignedChildIds?.length) return null

  return (
    <button
      style={{ width: "100%" }}
      onClick={() => toggleRewardTemplateId(reward.id)}
    >
      <FlexBox direction="row" align="center" gap={SPACING.space2} wrap={false}>
        <FlexBox
          direction="column"
          gap={SPACING.space2}
          wrap={false}
          style={{
            padding: SPACING.space2,
            border: `1px dashed ${isSelected ? JoonUIColor.border.accent : JoonUIColor.border.default}`,
            borderRadius: SPACING.space2,
            background: "rgba(249, 250, 252, 0.40)",
          }}
        >
          <FlexBox
            direction="row"
            justify="space-between"
            align="center"
            wrap={false}
          >
            <Typography variant="bodyBold" textAlign="left">
              {reward.emoji} {reward.title}
            </Typography>
            <FlexBox
              direction="row"
              align="center"
              justify="flex-end"
              gap={SPACING.space1}
              wrap={false}
              style={{ width: "fit-content" }}
            >
              <FlexBox
                direction="row"
                align="center"
                justify="flex-end"
                gap={SPACING.space05}
                wrap={false}
              >
                <Typography variant="bodySmall">{reward.cost}</Typography>
                <img
                  style={{ height: SPACING.space3 }}
                  src="/images/icons/coin-icon.png"
                  alt="coin"
                />
              </FlexBox>
              {reward.currency !== CustomRewardCurrency.ITEM && (
                <>
                  <Typography
                    variant="bodySmall"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    →
                  </Typography>
                  <Typography
                    variant="bodySmall"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    1 {reward.currency}
                  </Typography>
                </>
              )}
            </FlexBox>
          </FlexBox>
          <FlexBox direction="row" gap={SPACING.space1}>
            {assignedChildIds.map((child) => (
              <UserBadge key={child.user.id} userId={child.user.id} />
            ))}
          </FlexBox>
        </FlexBox>
        <div style={{ height: "100%", padding: SPACING.space2 }}>
          <Checkbox
            name={reward.id.toString()}
            hideBorder
            selected={isSelected}
            onChange={() => {}}
          />
        </div>
      </FlexBox>
    </button>
  )
}
