import { useEffect } from "react"

// This is a hook that injects CSS for Churnkey modal
// It ensures that the Churnkey modal is above everything else
// This is necessary because Churnkey was showing up under the mobile nav

const useChurnkeyStyles = () => {
  // Add useEffect to inject CSS for Churnkey modal
  useEffect(() => {
    // Create a style element with more specific selectors and higher z-index
    const styleEl = document.createElement("style")
    styleEl.innerHTML = `
        /* Target all Churnkey related elements */
        [class*="ck-"], [id*="ck-"], 
        .ck-style, .ck-modal-container, #ck-cf-modal,
        div[role="dialog"], div[aria-modal="true"],
        .ck-theme-pro-section, .ck-step-header, .ck-step-body,
        .ck-step-footer, .ck-wrapper {
          z-index: 99999 !important;
        }
        #ck-theme-pre-section, 
        div[id="ck-theme-pre-section"],
        .z-50.inline-block.bg-white.align-bottom.rounded-t-lg.text-left.overflow-hidden.shadow-xl {
          z-index: 99999 !important;
        }
        
        /* Ensure the modal overlay is above everything else */
        .fixed.z-40, .fixed.inset-0, [aria-hidden="true"].fixed {
          z-index: 99998 !important;
        }
      `
    document.head.appendChild(styleEl)

    // Clean up on unmount
    return () => {
      document.head.removeChild(styleEl)
    }
  }, [])
}

export default useChurnkeyStyles
