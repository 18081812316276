import { CoachProfile, UserRole } from "@joonapp/web-shared"

import { SubscriptionPackagesResponse } from "../../types"
import { CoachingGroup } from "../../types/coachingGroups"
import { createJoonAPIClient } from "../../util/joon-api"

export const getCoachingGroups = async () => {
  const API = createJoonAPIClient()
  return API.get<{ results: CoachingGroup[] }>("/api/coaching-groups/", {
    params: { role: UserRole.COACH },
  }).then((response) => response.data.results)
}

export async function loadRecommendedCoaches() {
  const joonApiClient = createJoonAPIClient()
  try {
    const response = await joonApiClient.get<{ profiles: CoachProfile[] }>(
      "api/coaching-groups/recommended-profiles/"
    )
    return response.data.profiles
  } catch (error) {
    throw error
  }
}

export async function loadRecommendedCoachesSummary() {
  const joonApiClient = createJoonAPIClient()
  try {
    const response = await joonApiClient.get<{
      response_summary: string | null
    }>("api/coaching-groups/recommended-profiles/")
    return response.data.response_summary
  } catch (error) {
    throw error
  }
}

export const loadCoachSubscriptionPackages = async () => {
  const joonApiClient = createJoonAPIClient()
  try {
    const response = await joonApiClient.get<SubscriptionPackagesResponse>(
      "api/coaching-groups/subscription-packages/"
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export async function getCoachingChurnkeyCancelFlow() {
  const API = createJoonAPIClient()
  return API.get<{
    subscription_id: string | null
    customer_id: string | null
    churnkey_auth_hash: string | null
  }>("api/coaching-groups/cancel-flow/").then((res) => res.data)
}
