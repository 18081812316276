import React, { forwardRef } from "react"

import "./onboardingPageWrapper.scss"
import { BACKGROUND_COLORS } from "../../../constants"
import useMobile from "../../../hooks/useMobile"
import { useRouter } from "../../../util/router"

interface OnboardingPageWrapperProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode
  style?: React.CSSProperties
}

const OnboardingPageWrapper = forwardRef<
  HTMLDivElement,
  OnboardingPageWrapperProps
>(({ children, style }, ref) => {
  const router = useRouter()
  const isMobile = useMobile()
  const isOnWelcome = router.pathname === "/welcome"

  return (
    <div
      className="jooniverse__onboarding-page-wrapper"
      style={{
        position: "relative",
        background: BACKGROUND_COLORS.xlightBlueBg,
        ...style,
      }}
      ref={ref}
    >
      {children}
      {!isMobile && !isOnWelcome && (
        <img
          src="/images/tree-on-hill.png"
          alt="Joon"
          style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            width: "400px",
            zIndex: 0,
            userSelect: "none",
            pointerEvents: "none",
          }}
        />
      )}
    </div>
  )
})

OnboardingPageWrapper.displayName = "OnboardingPageWrapper"

export default OnboardingPageWrapper
