import {
  capitalizeFirstLetter,
  CoachProfile,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
  UserRole,
} from "@joonapp/web-shared"

import CareTeamAvatar from "./CareTeamAvatar"
import Card from "../../components/card/Card"
import ArrowCircleRightIcon from "../../components/icons/ArrowCircleRightIcon"
import { TherapistProfile } from "../../types"
import { useRouter } from "../../util/router"

const CareTeamCard = ({
  profile,
}: {
  profile: CoachProfile | TherapistProfile
}) => {
  const router = useRouter()

  const handleClick = () => {
    if (profile.role === UserRole.COACH) {
      router.push(`/coach/${profile.user.id}`)
    } else {
      router.push(`/care/${profile.user.id}/academy`)
    }
  }

  const displayName = profile.nickname
    ? profile.nickname
    : `${profile.user.name} ${profile.user.last_name || ""}`

  return (
    <Card
      onClick={handleClick}
      title={
        <FlexBox
          direction="row"
          align="center"
          justify="flex-start"
          gap={SPACING.space2}
          wrap={false}
        >
          <CareTeamAvatar profile={profile} />
          <FlexBox direction="column">
            <Typography variant="bodyBold" color={JoonUIColor.text.primary}>
              {displayName}
            </Typography>
            <Typography
              variant="bodyXSmall"
              color={JoonColorExpanded.purple600}
            >
              {profile?.role === UserRole.THERAPIST ? "" : "Joon "}
              {capitalizeFirstLetter(profile?.role)}
            </Typography>
          </FlexBox>
        </FlexBox>
      }
      style={{
        padding: SPACING.space3,
        width: "99%",
        position: "relative",
        border: "none",
      }}
      buttonComponent={<ArrowCircleRightIcon />}
    />
  )
}

export default CareTeamCard
