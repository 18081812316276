import {
  CalendarSolidIcon,
  CoinsIcon,
  FlexBox,
  GiftIcon,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"
import { useShallow } from "zustand/react/shallow"

import useMobile from "../../hooks/useMobile"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useAddEditQuestStore } from "../questBoard/addEditQuestModal/useAddEditQuestStore"
import { useBonusRewardModalStore } from "../rewards/BonusRewardModal"
import useAddEditRewardModalStore from "../rewards/hooks/useAddEditRewardModalStore"

const QuickActionsSection = () => {
  const onOpenAddEditQuestModal = useAddEditQuestStore(
    useShallow((state) => state.onOpen)
  )
  const openAddRewardModal = useAddEditRewardModalStore(
    useShallow((state) => state.onOpen)
  )
  const openBonusRewardModal = useBonusRewardModalStore(
    useShallow((state) => state.onOpen)
  )

  const isMobile = useMobile()

  if (isMobile)
    return (
      <FlexBox
        direction="row"
        wrap={false}
        gap={SPACING.space2}
        style={{
          position: "fixed",
          zIndex: 500,
          width: "100%",
          left: 0,
          bottom: "70px",
          background: "rgba(13, 5, 105, 0.12)",
          padding: SPACING.space2,
        }}
      >
        <QuickActionMobileButton
          text="Create Quest"
          onClick={() => {
            trackAnalyticEvent(ANALYTIC_EVENTS.CREATE_CUSTOM_QUEST, {
              source: "me_tab_shortcuts",
            })
            onOpenAddEditQuestModal()
          }}
        />
        <QuickActionMobileButton
          text="Create Reward"
          onClick={() => {
            trackAnalyticEvent(ANALYTIC_EVENTS.CUSTOM_REWARD_CREATE_CUSTOM, {
              source: "me_tab_shortcuts",
            })
            openAddRewardModal()
          }}
        />
        <QuickActionMobileButton
          text="Send Bonus"
          onClick={() => {
            trackAnalyticEvent(ANALYTIC_EVENTS.SEND_BONUS_REWARD, {
              source: "me_tab_shortcuts",
            })
            openBonusRewardModal()
          }}
        />
      </FlexBox>
    )
  else
    return (
      <FlexBox direction="column" gap={SPACING.space2}>
        <Typography variant="bodyBold">Quick actions</Typography>
        <FlexBox
          direction="row"
          gap={SPACING.space1}
          wrap={false}
          style={{
            width: "100%",
            overflowX: "auto",
            paddingBottom: SPACING.space2,
            marginBottom: `-${SPACING.space2}`,
          }}
        >
          <QuickActionButton
            icon={
              <CalendarSolidIcon color={JoonUIColor.icon.accent} size={18} />
            }
            text="New Quest"
            onClick={() => {
              trackAnalyticEvent(ANALYTIC_EVENTS.CREATE_CUSTOM_QUEST, {
                source: "me_tab_shortcuts",
              })
              onOpenAddEditQuestModal()
            }}
          />
          <QuickActionButton
            icon={<GiftIcon color={JoonUIColor.icon.accent} size={18} />}
            text="Custom reward"
            onClick={() => {
              trackAnalyticEvent(ANALYTIC_EVENTS.CUSTOM_REWARD_CREATE_CUSTOM, {
                source: "me_tab_shortcuts",
              })
              openAddRewardModal()
            }}
          />
          <QuickActionButton
            icon={<CoinsIcon color={JoonUIColor.icon.accent} size={18} />}
            text="Bonus coins"
            onClick={() => {
              trackAnalyticEvent(ANALYTIC_EVENTS.SEND_BONUS_REWARD, {
                source: "me_tab_shortcuts",
              })
              openBonusRewardModal()
            }}
          />
        </FlexBox>
      </FlexBox>
    )
}

const QuickActionButton = ({
  icon,
  text,
  onClick,
}: {
  icon: React.ReactNode
  text: string
  onClick: () => void
}) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <FlexBox
      justify="space-between"
      direction="row"
      align="center"
      wrap={false}
      onClick={onClick}
      gap={SPACING.space2}
      style={{
        width: "fit-content",
        padding: `${SPACING.space3} ${SPACING.space4}`,
        border: `1px solid ${JoonUIColor.border.default}`,
        borderRadius: SPACING.space2,
        cursor: "pointer",
        boxShadow: "4px 6px 6px 0px rgba(0, 0, 0, 0.08)",
        background: isHovered
          ? JoonUIColor.background.xlightGray
          : JoonUIColor.background.primaryNeutral,
        height: "50px",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {icon}
      <Typography
        variant="body"
        color={JoonUIColor.text.primary}
        style={{ whiteSpace: "nowrap" }}
      >
        {text}
      </Typography>
    </FlexBox>
  )
}

export const QuickActionMobileButton = ({
  text,
  onClick,
}: {
  text: string
  onClick: () => void
}) => {
  return (
    <FlexBox
      direction="column"
      align="center"
      wrap={false}
      onClick={onClick}
      gap={SPACING.space2}
      style={{
        flex: 1,
        padding: `${SPACING.space1} ${SPACING.space2}`,
        border: `1px solid ${JoonUIColor.border.default}`,
        borderRadius: SPACING.space2,
        cursor: "pointer",
        boxShadow: "4px 6px 6px 0px rgba(0, 0, 0, 0.08)",
        background: JoonUIColor.background.primaryNeutral,
      }}
    >
      <Typography variant="bodySmallBold" color={JoonColorExpanded.indigo700}>
        {text}
      </Typography>
    </FlexBox>
  )
}

export default QuickActionsSection
