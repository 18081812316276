import { Button, CloseIcon, JoonUIColor, Typography } from "@joonapp/web-shared"

import ShopItem from "./ShopItem"
import { useGiftShopModalStore } from "./useGiftShopModalStore"
import { useSelectGiftModalStore } from "./useSelectGiftModalStore"
import { useSendMessageModalStore } from "./useSendMessageModalStore"
import SlideUpModal from "../../../components/slideUpModal/SlideUpModal"
import { useUserQuery } from "../../../networking/queries"
import { usePurchaseShopItemMutation } from "../../../networking/rewards/mutations"
import { useShopItemsQuery } from "../../../networking/rewards/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

const GiftShopModal = () => {
  const { user } = useUserQuery()
  const { isOpen, onClose, selectedShopItem } = useGiftShopModalStore()
  const { setSelectedGift } = useSendMessageModalStore()
  const { onOpen: onOpenSelectGiftModal } = useSelectGiftModalStore()
  const { onOpen: onOpenSendMessageModal } = useSendMessageModalStore()
  const { data: shopItems } = useShopItemsQuery()
  const purchaseShopItemMutation = usePurchaseShopItemMutation()

  const userCoins = user?.coins ?? 0
  const firstShopItemPrice = shopItems?.[0]?.price ?? Infinity
  const doesNotHaveEnoughCoins = userCoins < firstShopItemPrice

  const onCloseModal = () => {
    setSelectedGift(null)
    onClose()
    onOpenSelectGiftModal()
  }

  const onPurchaseAndAddToMail = async () => {
    if (!selectedShopItem || !user?.id) return
    trackAnalyticEvent(ANALYTIC_EVENTS.PURCHASE_AND_ADD_TO_MAIL)
    const inventoryItem = await purchaseShopItemMutation.mutateAsync({
      shopItem: selectedShopItem,
      userId: user.id,
    })
    setSelectedGift(inventoryItem)
    onClose()
    onOpenSendMessageModal()
  }

  const onPurchaseAndGoToInventory = async () => {
    if (!selectedShopItem || !user?.id) return
    trackAnalyticEvent(ANALYTIC_EVENTS.PURCHASE_AND_GO_TO_INVENTORY)
    await purchaseShopItemMutation.mutateAsync({
      shopItem: selectedShopItem,
      userId: user.id,
    })
    onClose()
    onOpenSelectGiftModal()
  }

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onCloseModal}
      showCard={true}
      size="fit-content"
      backgroundType="overlay"
    >
      <div className="flex flex-col max-h-dvh">
        <button className="absolute right-0 top-0 m-4" onClick={onCloseModal}>
          <CloseIcon color={JoonUIColor.icon.neutral} />
        </button>
        <div className="flex flex-col gap-4 p-6 overflow-y-auto">
          <Typography variant="h3">Gift shop</Typography>

          <Typography variant="bodySmall">
            {doesNotHaveEnoughCoins &&
              "You do not have enough balance to purchase anything at this moment. "}
            Assign quests to yourself and complete them to earn coins you can
            use to purchase gifts for your child!
          </Typography>
          <div
            className={`flex flex-row justify-start items-center gap-2 w-fit  rounded-md p-2 sticky top-2  ${
              doesNotHaveEnoughCoins ? "bg-red-100" : "bg-indigo-100"
            }`}
          >
            <Typography variant="bodySmall" style={{ whiteSpace: "nowrap" }}>
              My balance:
            </Typography>

            <div className="flex flex-row justify-start items-center gap-1">
              <img
                src="/images/icons/coin-icon.png"
                alt="coin"
                style={{ width: "16px" }}
              />
              <Typography variant="bodySmall">{user?.coins}</Typography>
            </div>
          </div>

          <div className="flex flex-1 flex-col gap-2 pb-4">
            {!shopItems || shopItems.length === 0 ? (
              <div className="flex flex-row items-center justify-center w-full p-2">
                <Typography
                  variant="bodySmall"
                  color={JoonUIColor.text.secondary}
                >
                  No items available for purchase.
                </Typography>
              </div>
            ) : (
              shopItems.map((shopItem) => (
                <ShopItem key={shopItem.id} shopItem={shopItem} />
              ))
            )}
          </div>
        </div>
        {selectedShopItem && (
          <div className="flex flex-col gap-2 w-full h-fit p-6 border-t border-borderPrimary bg-neutral-0 ">
            <Button
              text="Purchase and add to mail"
              onClick={onPurchaseAndAddToMail}
              fullWidth
              disabled={!selectedShopItem}
              isLoading={purchaseShopItemMutation.isLoading}
            />
            <Button
              text="Purchase and go to inventory"
              onClick={onPurchaseAndGoToInventory}
              fullWidth
              disabled={!selectedShopItem}
              isLoading={purchaseShopItemMutation.isLoading}
              buttonType="secondary"
            />
          </div>
        )}
      </div>
    </SlideUpModal>
  )
}

export default GiftShopModal
