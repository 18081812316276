import { faHashtag } from "@fortawesome/duotone-light-svg-icons"
import {
  AuthCodeType,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import { useInviteCodeStore } from "./useInviteCodeStore"
import ChevronBackButton from "../../components/buttons/ChevronBackButton"
import Primary3DButton from "../../components/buttons/Primary3DButton"
import SecondaryTransparentButton from "../../components/buttons/SecondaryTransparentButton"
import OnboardingPageContentWrapper from "../../components/onboarding/onboardingPageContentWrapper/OnboardingPageContentWrapper"
import OnboardingPageWrapper from "../../components/onboarding/onboardingPageWrapper/OnboardingPageWrapper"
import TextInputRect from "../../components/textInputRect/TextInputRect"
import { BACKGROUND_COLORS } from "../../constants"
import useMediaQuery from "../../hooks/useMediaQuery"
import useMobile from "../../hooks/useMobile"
import useOnboardContext, {
  OnboardingStep,
} from "../../hooks/useOnboardContext"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { createJoonAPIClient } from "../../util/joon-api"
import { useRouter } from "../../util/router"
import { localStorageItems } from "../../util/storage"

function InviteCode() {
  const router = useRouter()
  const { setIsInvited, therapistCode, setTherapistCode } = useInviteCodeStore()
  const { setStep } = useOnboardContext()
  const [error, setError] = useState<string | null>(null)
  const [isValidating, setIsValidating] = useState(false)
  const isMobile = useMobile()
  const isSmallMobile = useMediaQuery("(max-width: 500px)")

  const onSubmitTherapistCode = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.ONBOARDING_USING_THERAPIST_CODE)
    localStorage.setItem(localStorageItems.therapistCode, therapistCode)
    setStep(OnboardingStep.AddChildOrJoinFamily)
    router.push("/onboard")
  }

  const onClickSignUpManually = () => {
    localStorage.removeItem(localStorageItems.therapistCode)
    setTherapistCode("")
    setStep(OnboardingStep.ParentingQuestionnaire)
    trackAnalyticEvent(ANALYTIC_EVENTS.NOT_INVITED_BY_THERAPIST)
    setIsInvited(false)
    router.push("/onboard")
  }

  const validateCode = async () => {
    if (therapistCode.length !== 8) return

    try {
      const API = createJoonAPIClient()
      setIsValidating(true)
      const response = await API.get("api/auth-codes/validate/", {
        params: {
          code: therapistCode.toUpperCase(),
          type: AuthCodeType.ADMIT_PATIENT,
        },
      })
      if (!response.data.group.profiles) return setError("An error occurred")
      setError("")
    } catch (error) {
      setError("Invalid code.")
    }
    setIsValidating(false)
  }

  useEffect(() => {
    if (therapistCode.length === 8) validateCode()
    else setError("")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [therapistCode])

  useEffect(() => {
    const code = localStorage.getItem(localStorageItems.therapistCode)
    if (code) {
      setTherapistCode(code)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <OnboardingPageWrapper
      style={{
        backgroundColor: isMobile ? "#f7fafd" : BACKGROUND_COLORS.xlightBlueBg,
      }}
    >
      <OnboardingPageContentWrapper
        paddingTop={SPACING.space14}
        backgroundOnDesktop="#f7fafd"
        style={{ position: "relative" }}
      >
        <ChevronBackButton onClick={() => setIsInvited(false)} />
        <FlexBox direction="column" align="center" gap={SPACING.space6}>
          <div
            style={{
              height: isSmallMobile ? "160px" : "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/images/gifs/onboarding-grow-plant-gif.gif"
              alt="therapist"
              style={{ width: isSmallMobile ? "160px" : "200px" }}
            />
          </div>
          <Typography variant="h2Serif">Enter therapist code</Typography>
          <Typography
            variant="body"
            textAlign="center"
            style={{ width: "80%" }}
          >
            If you've been invited by a therapist, you should have a code to
            input below.
          </Typography>
          <FlexBox direction="column" align="flex-start" gap={SPACING.space2}>
            <Typography variant="body">
              8 character therapist invite code
            </Typography>
            <TextInputRect
              name="therapist code"
              value={therapistCode}
              onChange={(e: any) => setTherapistCode(e.target.value)}
              maxLength={8}
              fullWidth
              icon={faHashtag}
              placeholder="ABCD12345"
            />
            {error && (
              <Typography
                variant="bodySmallBold"
                color={JoonUIColor.semantic.alert}
                style={{ marginTop: SPACING.space1 }}
              >
                {error}
              </Typography>
            )}
          </FlexBox>
        </FlexBox>
        <FlexBox direction="column" align="center" gap={SPACING.space3}>
          <Primary3DButton
            fullWidth
            onClick={onSubmitTherapistCode}
            text="Submit"
            disabled={therapistCode.length !== 8 || !!error || isValidating}
            style={{ marginTop: SPACING.space1 }}
          />
          <SecondaryTransparentButton
            fullWidth
            onClick={onClickSignUpManually}
            text="Sign up manually instead"
          />
          <Typography variant="bodyXSmall" textAlign="center">
            Your answers are never shared with anyone without your consent. If
            you prefer not to answer a question, you can always select “Prefer
            not to answer.”
          </Typography>
        </FlexBox>
      </OnboardingPageContentWrapper>
    </OnboardingPageWrapper>
  )
}

export default InviteCode
