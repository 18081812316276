import {
  faArrowsRotateReverse,
  faThumbsUp,
  faTrashXmark,
} from "@fortawesome/duotone-light-svg-icons"
import { JoonColorExpanded, SPACING } from "@joonapp/web-shared"
import { JoonUIColor, Typography } from "@joonapp/web-shared"
import { FlexBox } from "@joonapp/web-shared"

import useAIQuest from "./hooks/useAIQuest"
import useAIQuestStore from "./hooks/useAIQuestStore"
import PromptTextArea from "./PromptTextArea"
import { BaseActionButton } from "../../../components/buttons/BaseActionButton"

const ModificationBar = () => {
  const {
    selectedQuestUUIDs,
    setSelectedQuestUUIDs,
    isAssigning,
    isLoading,
    isRemoving,
  } = useAIQuestStore()
  const hasSelectedQuests = selectedQuestUUIDs.length > 0

  if (isAssigning || isLoading || isRemoving) return null

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: JoonUIColor.background.primaryNeutral,
        borderTop: `1px solid ${JoonUIColor.border.default}`,
        borderTopRightRadius: SPACING.space4,
        borderTopLeftRadius: SPACING.space4,
        boxShadow: "0px -4px 20px 0px rgba(212, 201, 255, 0.75)",
      }}
    >
      {hasSelectedQuests && (
        <FlexBox
          direction="row"
          justify="space-between"
          wrap={false}
          style={{
            padding: `${SPACING.space2} ${SPACING.space4}`,
            borderBottom: `1px solid ${JoonUIColor.border.default}`,
          }}
        >
          <Typography variant="bodySmall">
            For the {selectedQuestUUIDs.length} quests selected...
          </Typography>
          <button onClick={() => setSelectedQuestUUIDs([])}>
            <Typography variant="bodyXSmall" color={JoonUIColor.text.secondary}>
              Unselect all
            </Typography>
          </button>
        </FlexBox>
      )}
      <FlexBox
        direction="row"
        wrap={false}
        style={{
          padding: `${SPACING.space1} ${SPACING.space4}`,
          borderBottom: `1px solid ${JoonUIColor.border.default}`,
        }}
      >
        <RemoveButton />
        <StartOverButton />
        <AssignButton />
      </FlexBox>
      <FlexBox
        direction="column"
        gap={SPACING.space1}
        style={{
          padding: `${SPACING.space2} ${SPACING.space4} ${SPACING.space4} ${SPACING.space4}`,
        }}
      >
        <FlexBox direction="column">
          <Typography variant="bodySmallBold">
            What changes would you like to see?
          </Typography>
        </FlexBox>
        <PromptTextArea
          placeholder="Ask Jooniper to change routine time, assignment, levels of difficulty, or recurrence"
          showSuggestions={false}
        />
      </FlexBox>
    </div>
  )
}

const RemoveButton = () => {
  const { selectedQuestUUIDs } = useAIQuestStore()
  const { handleRemoveQuests } = useAIQuest()

  if (selectedQuestUUIDs.length === 0) return null

  return (
    <BaseActionButton
      icon={faTrashXmark}
      primaryColor={JoonUIColor.semantic.alert}
      secondaryColor={JoonColorExpanded.red100}
      label="Remove"
      onClick={handleRemoveQuests}
    />
  )
}

const AssignButton = () => {
  const { handleAssignQuests } = useAIQuest()
  const { selectedQuestUUIDs, response } = useAIQuestStore()

  if (
    response?.pending_create_quest_data.length === 0 &&
    response?.pending_edit_existing_quest_data.length === 0
  )
    return null

  return (
    <BaseActionButton
      icon={faThumbsUp}
      primaryColor={JoonUIColor.semantic.success}
      secondaryColor={JoonColorExpanded.viridian100}
      label={selectedQuestUUIDs.length > 0 ? "Assign selected" : "Assign all"}
      onClick={handleAssignQuests}
    />
  )
}

const StartOverButton = () => {
  const { handleStartOver } = useAIQuest()
  const { selectedQuestUUIDs } = useAIQuestStore()

  if (selectedQuestUUIDs.length > 0) return null

  return (
    <BaseActionButton
      icon={faArrowsRotateReverse}
      primaryColor={JoonUIColor.semantic.primary}
      secondaryColor={JoonColorExpanded.indigo100}
      label="Start over"
      onClick={handleStartOver}
    />
  )
}

export default ModificationBar
