import { QuestSeries, Typography } from "@joonapp/web-shared"

import Card from "../../../components/card/Card"

export const JooniperAssignedQuestCard = ({
  questSeries,
  childIds,
}: {
  questSeries: QuestSeries
  childIds: number[]
}) => {
  return (
    <Card
      type="secondary"
      title={
        <Typography
          variant="bodySmallBold"
          style={{
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {questSeries?.title}
        </Typography>
      }
      subtitle={questSeries?.reminder_time || ""}
      userIds={childIds}
      coinAmount={questSeries?.redeemable_reward}
      style={{ pointerEvents: "none" }}
    />
  )
}
