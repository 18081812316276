import { useEffect, useRef } from "react"

import { AddRewardButton } from "./AddRewardButton"
import { useRewardPageStore } from "./hooks/useRewardPageStore"
import { useShowInitialSmartRedemptionModal } from "./hooks/useShowInitialSmartRedemptionModal"
import RewardsChildToggleHeader from "./redeem/RewardsChildToggleHeader"
import RewardsRedeem from "./RewardsRedeem"
import WelcomeCustomRewards from "./WelcomeCustomRewards"
import ChildAuthCode from "../../components/childAuthCode/ChildAuthCode"
import PageContentWrapper from "../../components/pageContentWrapper/PageContentWrapper"
import PageHeader from "../../components/pageHeader/PageHeader"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { GRADIENTS } from "../../constants"
import useDocumentTitle from "../../hooks/useDocumentTitle"
import { requireAuth } from "../../util/auth"
import { localStorageItems } from "../../util/storage"

const CustomRewards = () => {
  const pageWrapperRef = useRef<HTMLDivElement>(null)
  useDocumentTitle("Joon - Rewards")
  const { showWelcome, setShowWelcome } = useRewardPageStore()

  // Show the initial smart redemption modal if the user has not seen it yet
  useShowInitialSmartRedemptionModal()

  useEffect(() => {
    setShowWelcome(
      !!localStorage.getItem(localStorageItems.needsToSeeWelcomeRewards)
    )
  }, [setShowWelcome])

  useEffect(() => {
    if (pageWrapperRef.current) {
      pageWrapperRef.current.scrollTo({ top: 0, behavior: "instant" })
    }
  }, []) // eslint-disable-line

  if (showWelcome) return <WelcomeCustomRewards />

  return (
    <PageWrapper
      style={{ background: GRADIENTS.gradient100 }}
      ref={pageWrapperRef}
    >
      <PageHeader title="Rewards" />
      <ChildAuthCode />
      <RewardsChildToggleHeader />
      <PageContentWrapper mobileGutter>
        <RewardsRedeem />
      </PageContentWrapper>
      <AddRewardButton />
    </PageWrapper>
  )
}

const CustomRewardsWithAuth = requireAuth(CustomRewards)
export default CustomRewardsWithAuth
