import {
  Checkbox,
  JoonUIColor,
  Shadow,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

export interface ImageCheckboxProps {
  id?: string
  name: string
  selected: boolean
  onChange: (event: any) => void
  image: string
  imageAlt?: string
  label?: string | React.ReactNode
  disabled?: boolean
  fullWidth?: boolean
  inputType?: "checkbox" | "radio"
  hideBorder?: boolean
  style?: React.CSSProperties
}

const ImageCheckbox: React.FC<ImageCheckboxProps> = ({
  name,
  selected,
  onChange,
  image,
  imageAlt,
  label,
  disabled = false,
  fullWidth,
  inputType = "checkbox",
  hideBorder = false,
  style = {},
}) => (
  <button
    style={{
      display: "flex",
      flexDirection: "column",
      padding: hideBorder ? "0" : `${SPACING.space1} ${SPACING.space2}`,
      borderRadius: SPACING.space2,
      alignItems: "center",
      userSelect: "none",
      width: fullWidth ? "100%" : "fit-content",
      boxShadow: selected || hideBorder ? Shadow.high : "none",
      border: hideBorder ? "none" : `1px solid ${JoonUIColor.border.default}`,
      backgroundColor: disabled
        ? JoonUIColor.background.disabled
        : JoonUIColor.background.primaryNeutral,
      cursor: disabled ? "not-allowed" : "pointer",
      opacity: selected ? 1 : 0.7,
      ...style,
    }}
    onClick={onChange}
    aria-checked={selected}
    role="checkbox"
    name={name}
  >
    <Checkbox
      selected={selected}
      inputType={inputType}
      name={name}
      onChange={onChange}
      hideBorder
    />

    <img
      src={image}
      alt={imageAlt || name}
      style={{ minHeight: "100px", maxHeight: "100px" }}
    />
    <label htmlFor={name}>
      <Typography
        variant="bodyBold"
        style={{
          whiteSpace: "nowrap",
          cursor: disabled ? "not-allowed" : "pointer",
        }}
        color={disabled ? JoonUIColor.text.disabled : JoonUIColor.text.primary}
      >
        {label}
      </Typography>
    </label>
  </button>
)

export default ImageCheckbox
