import { useMemo } from "react"

import useQuestBoardStore from "./useQuestBoardStore"
import { useFamilyQuery, useQuestBoardQuery } from "../../networking/queries"
import { FamilyQuerySelect } from "../../types"
import { QuestBoardSelectOption } from "../../types/quests"

const useQuestBoardFilters = () => {
  const { data } = useQuestBoardQuery()
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const { selectOption, selectedUserId, selectedRoutine } = useQuestBoardStore()

  const filteredQuests = useMemo(() => {
    return (
      data?.filter((quest) => {
        // Filter quests by select option
        if (selectOption === QuestBoardSelectOption.ALL_KIDS) {
          return children?.some((child) => child.user.id === quest.user_id)
        }

        // Filter quests by child
        if (selectedUserId && quest.user_id !== selectedUserId) {
          return false
        }
        // Filter quests by routine
        if (selectedRoutine && quest.series.routine !== selectedRoutine) {
          return false
        }
        return true
      }) || []
    )
  }, [data, children, selectOption, selectedUserId, selectedRoutine])

  return { filteredQuests }
}

export default useQuestBoardFilters
