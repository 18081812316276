import { FlexBox, SPACING, Typography } from "@joonapp/web-shared"

import useAIQuestStore from "./hooks/useAIQuestStore"

const QuestAIError = () => {
  const { error } = useAIQuestStore()
  if (!error) return null

  return (
    <FlexBox direction="column" gap={SPACING.space4}>
      <Typography variant="aiBody" textAlign="left">
        I’m sorry, something went wrong.
      </Typography>
      <Typography variant="aiBody">Why don’t we start over below?</Typography>
    </FlexBox>
  )
}

export default QuestAIError
