import { create } from "zustand"

interface CoachSMSConfigModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  coachCheckinTime: string
  setCoachCheckinTime: (time: string) => void
}

const useCoachSMSConfigModalStore = create<CoachSMSConfigModalStore>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
  coachCheckinTime: "20:30:00",
  setCoachCheckinTime: (time: string) => set({ coachCheckinTime: time }),
}))

export default useCoachSMSConfigModalStore
