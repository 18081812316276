import {
  FlexBox,
  JoonUIColor,
  Loader,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

const CreatingAccount = () => {
  return (
    <FlexBox
      direction="column"
      wrap={false}
      justify="center"
      align="center"
      style={{ height: "80dvh" }}
      gap={SPACING.space2}
    >
      <img
        style={{ height: "150px" }}
        src={"/images/chibi_polair.svg"}
        alt="polair"
      />
      <Typography
        variant="h1"
        textAlign="center"
        style={{ marginBottom: SPACING.space4 }}
      >
        Creating your Account!
      </Typography>
      <Loader color={JoonUIColor.background.accent} size={32} thickness={4} />
    </FlexBox>
  )
}

export default CreatingAccount
