import {
  ChildUser,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import usePurchaseRewardModalStore from "./hooks/usePurchaseRewardModalStore"

const PurchaseRewardChildSelectCard = ({ child }: { child: ChildUser }) => {
  const { selectedChildId, setSelectedChildId } = usePurchaseRewardModalStore()

  return (
    <button
      onClick={() => setSelectedChildId(child.id)}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: SPACING.space3,
        borderRadius: SPACING.space2,
        userSelect: "none",
        width: "100%",
        border: `1px solid ${JoonUIColor.border.default}`,
        backgroundColor: JoonUIColor.background.primaryNeutral,
      }}
    >
      <FlexBox direction="row" align="center" gap={SPACING.space1} wrap={false}>
        <input
          type="radio"
          name={child.name}
          checked={selectedChildId === child.id}
          onChange={() => {}}
          style={{ display: "none" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "18px",
            height: "18px",
            position: "relative",
            borderRadius: SPACING.space14,
            border:
              selectedChildId === child.id
                ? `2px solid ${JoonUIColor.border.accent}`
                : `2px solid ${JoonUIColor.background.disabled}`,
            backgroundColor:
              selectedChildId === child.id
                ? JoonUIColor.background.accent
                : JoonUIColor.background.primaryNeutral,
          }}
        >
          {selectedChildId === child.id && (
            <div
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                background: "white",
              }}
            />
          )}
        </div>
        <Typography variant="bodySmall">{child.name}</Typography>
      </FlexBox>
      <FlexBox
        direction="row"
        align="center"
        gap={SPACING.space1}
        wrap={false}
        style={{ width: "fit-content" }}
      >
        <img
          style={{ height: SPACING.space3 }}
          src="/images/icons/coin-icon.png"
          alt="coin"
        />
        <Typography variant="bodySmall">{child.coins}</Typography>
      </FlexBox>
    </button>
  )
}

export default PurchaseRewardChildSelectCard
