import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  SessionBookingLinkType,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import { useSessionBookingModalStore } from "./sessions/hooks/useSessionBookingModalStore"
import useCoachSMSConfigModalStore from "./useCoachSMSConfigModalStore"
import { useNotificationPreferencesMutation } from "../../components/modals/addPhoneNumberModal/useNotificationPreferencesMutation"
import TimeInputNew from "../../components/timeInput/TimeInputNew"
import { useGetCoachSubscriptionQuery } from "../../networking/coaching/queries"
import { useNotificationPreferencesQuery } from "../../networking/queries"
import { useSessionBillingPeriodsQuery } from "../../networking/sessions/queries"
import { localStorageItems } from "../../util/storage"

const CoachSMSConfigModal = () => {
  const { isOpen, onClose, coachCheckinTime, setCoachCheckinTime } =
    useCoachSMSConfigModalStore()
  const { onOpen: openSessionBookingModal } = useSessionBookingModalStore()
  const { data: billingPeriods, refetch: refetchBillingPeriods } =
    useSessionBillingPeriodsQuery()
  const { data: coachSubscriptionData, isLoading: isLoadingCoachSubscription } =
    useGetCoachSubscriptionQuery()

  const { data: notificationPreferences } = useNotificationPreferencesQuery()
  const notificationPreferencesMutation = useNotificationPreferencesMutation()

  const isMessageOnly =
    !isLoadingCoachSubscription && coachSubscriptionData?.num_sessions === 0

  const handleNext = () => {
    // Format time to ensure it has seconds when submitting
    const timeWithSeconds =
      coachCheckinTime.split(":").length === 2
        ? `${coachCheckinTime}:00`
        : coachCheckinTime

    notificationPreferencesMutation.mutate({
      coachCheckinTime: timeWithSeconds,
    })

    goToNextStep()
  }

  useEffect(() => {
    setCoachCheckinTime(
      notificationPreferences?.coach_checkin_time || "20:30:00"
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationPreferences])

  const goToNextStep = async () => {
    refetchBillingPeriods()
    if (!isMessageOnly) {
      setTimeout(() => {
        const firstSession = billingPeriods?.[0]?.booking_link
        if (!firstSession) return
        openSessionBookingModal(firstSession, SessionBookingLinkType.SCHEDULE)
      }, 300)
      // Set flag that user has seen messaging to avoid showing overlay after this
      localStorage.setItem(
        localStorageItems.lastSeenSessionBookingOverlay,
        new Date().toISOString()
      )
    }
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}}
      animate
      mandatory={true}
      style={{ width: "min(420px, 95vw)" }}
    >
      <FlexBox
        style={{
          padding: `${SPACING.space4} ${SPACING.space6}`,
          borderBottom: `1px solid ${JoonUIColor.border.default}`,
        }}
      >
        <Typography variant="bodyBold">Notification preferences</Typography>
      </FlexBox>
      <FlexBox
        direction="column"
        style={{ padding: SPACING.space6, paddingTop: SPACING.space4 }}
        gap={SPACING.space2}
      >
        <Typography variant="body" style={{ fontWeight: 500 }}>
          When is usually a good time to remind you to check-in with your coach?
        </Typography>
        <Typography variant="bodySmall">
          We'll use this information to ensure that you're getting notifications
          during your most available time.
        </Typography>
        <FlexBox
          align="center"
          justify="center"
          style={{ margin: `${SPACING.space4} 0` }}
        >
          <TimeInputNew
            value={coachCheckinTime}
            setValue={setCoachCheckinTime}
          />
        </FlexBox>
      </FlexBox>
      <FlexBox
        style={{ padding: SPACING.space4 }}
        gap={SPACING.space2}
        align="center"
        direction="row"
        wrap={false}
      >
        <TextButton style={{ width: "100%" }} onClick={goToNextStep}>
          <Typography
            variant="bodySmallBold"
            color={JoonUIColor.text.primaryAccent}
          >
            Skip for now
          </Typography>
        </TextButton>
        <Button
          text={!isMessageOnly ? "Next: Book first session" : "Save"}
          buttonType="primary"
          style={{ width: "100%", borderRadius: SPACING.space2 }}
          onClick={handleNext}
          isLoading={notificationPreferencesMutation.isLoading}
        />
      </FlexBox>
    </Modal>
  )
}
export default CoachSMSConfigModal
