import {
  SessionBillingPeriod,
  SPACING,
  FlexBox,
  Typography,
  JoonUIColor,
} from "@joonapp/web-shared"
import dayjs from "dayjs"

import SessionSlot from "./SessionSlot"

type Props = {
  period: SessionBillingPeriod
  isCurrentCycle: boolean
}

const SessionBillingPeriodGroup = ({ period, isCurrentCycle }: Props) => {
  const bookingsMinusMakeups = period.bookings?.filter(
    (booking) => !booking.missed_session_id
  )
  const numSlots = Math.max(period.max_bookings, bookingsMinusMakeups?.length)
  return (
    <FlexBox direction="column" gap={SPACING.space2}>
      <Typography variant="bodySmallBold" color={JoonUIColor.text.secondary}>
        {isCurrentCycle ? "CURRENT CYCLE" : "UPCOMING CYCLE"} (
        {dayjs(period.start_datetime).format("MMM D")}
        {" - "}
        {dayjs(period.end_datetime).format("MMM D")})
      </Typography>
      <FlexBox direction="row" gap={SPACING.space2}>
        {new Array(numSlots).fill(0).map((_, i) => (
          <SessionSlot
            session={bookingsMinusMakeups?.[i]}
            key={i}
            billingPeriod={period}
          />
        ))}
      </FlexBox>
    </FlexBox>
  )
}

export default SessionBillingPeriodGroup
