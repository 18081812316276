import { create } from "zustand"

export enum DoterChatTab {
  CHAT = "chat",
  FLAGGED = "flagged",
}

interface DoterChatModalStore {
  isOpen: boolean
  onClose: () => void
  onOpen: (childUserId: number) => void

  tab: DoterChatTab
  setTab: (tab: DoterChatTab) => void

  childUserId: number | null
  setChildUserId: (childUserId: number) => void
}

export const useDoterChatModalStore = create<DoterChatModalStore>((set) => ({
  isOpen: false,
  onOpen: (childUserId: number) => set({ isOpen: true, childUserId }),
  onClose: () => set({ isOpen: false, childUserId: null }),

  tab: DoterChatTab.CHAT,
  setTab: (tab) => set({ tab }),

  childUserId: null,
  setChildUserId: (childUserId) => set({ childUserId }),
}))
