import * as Sentry from "@sentry/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import toast from "react-hot-toast"
import { RRule } from "rrule"
import { useShallow } from "zustand/react/shallow"

import { QUERY_KEYS } from "../../constants"
import { useAddEditQuestStore } from "../../pages/questBoard/addEditQuestModal/useAddEditQuestStore"
import { useQuestGroupSeries } from "../../pages/questBoard/useQuestGroups"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { queryClient } from "../../util/queryClient"
import { convertToByWeekday } from "../../util/util"
import { addQuest, deleteQuestTemplate, editQuest } from "../quests"

export const useDeleteQuestTemplateMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteQuestTemplate,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.QUEST_TEMPLATES],
      })
    },
  })
}

export const useEditQuestMutation = () => {
  const {
    questName,
    description,
    photoFile,
    coinRewardsMap,
    category,
    routine,
    repetition,
    repeatsFrequency,
    isOngoing,
    startDate,
    reminderTimeMap,
    timerLength,
    assignedUsers,
    mandatory,
    reqReview,
    reqNotes,
    reqPhoto,
    saveTemplate,
    isGrouped,

    resetQuestDetails,
    onClose,
    setErrors,
    selectedQuest,
  } = useAddEditQuestStore(
    useShallow((state) => ({
      questName: state.questName,
      description: state.description,
      photoFile: state.photoFile,
      coinRewardsMap: state.coinRewardsMap,
      category: state.category,
      routine: state.routine,
      repetition: state.repetition,
      repeatsFrequency: state.repeatsFrequency,
      isOngoing: state.isOngoing,
      startDate: state.startDate,
      reminderTimeMap: state.reminderTimeMap,
      timerLength: state.timerLength,
      assignedUsers: state.assignedUsers,
      mandatory: state.mandatory,
      reqReview: state.reqReview,
      reqNotes: state.reqNotes,
      reqPhoto: state.reqPhoto,
      saveTemplate: state.saveTemplate,
      templateId: state.templateId,
      customTemplateId: state.customTemplateId,
      isGrouped: state.isGrouped,

      resetQuestDetails: state.resetQuestDetails,
      setErrors: state.setErrors,
      onClose: state.onClose,
      selectedQuest: state.selectedQuest,
    }))
  )

  const { questGroupSeries } = useQuestGroupSeries(selectedQuest)

  const checkForErrors = () => {
    const tempErrors = {} as any
    if (questName.length === 0) {
      tempErrors.questName = "Please enter a Quest Title"
    }
    if (assignedUsers.length === 0) {
      tempErrors.assignedUsers = "Please assign to at least one person"
    }
    setErrors(tempErrors)
    if (Object.keys(tempErrors)?.length > 0)
      throw new Error("Invalid quest details")
  }

  const editQuestAction = () => {
    if (!selectedQuest || questGroupSeries.length < 1)
      throw new Error("No quest selected")
    checkForErrors()

    const isRepeatingQuest = !!repetition?.some((day) => day)

    const recurrence = new RRule({
      freq: isRepeatingQuest ? RRule.WEEKLY : RRule.DAILY,
      byweekday: isRepeatingQuest ? convertToByWeekday(repetition) : undefined,
      interval: isRepeatingQuest ? repeatsFrequency ?? 1 : undefined,
    })

    const didNotUploadPhoto = photoFile === null

    const editQuestPromises = [] as Promise<any>[]

    // always iterate through all children
    // NOTE: Can we just map through the actual children IDs?
    questGroupSeries.forEach((questSeries) => {
      const questInfo = {
        title: questName,
        ...(didNotUploadPhoto ? { photo_url: null } : {}),
        requires_review: reqReview,
        requires_completion_notes: reqNotes,
        requires_completion_photo: reqPhoto,
        routine: routine,
        save_as_template: saveTemplate,
        mandatory: mandatory,
        redeemable_reward: coinRewardsMap[questSeries.user_id],
        description,
        occurrence_limit: isRepeatingQuest ? null : 1,
        skill: category,
        timer_length: timerLength,
        reminder_time: reminderTimeMap[routine] || null,
        recurrence: recurrence.toString(),
        start_date: isOngoing
          ? dayjs().format("YYYY-MM-DD")
          : dayjs(startDate || new Date()).format("YYYY-MM-DD"),
        end_date:
          isOngoing || isRepeatingQuest
            ? null
            : dayjs(startDate || new Date()).format("YYYY-MM-DD"),
        quest_group_id:
          isGrouped && assignedUsers.length > 1
            ? questSeries.quest_group_id
            : null,
      }

      editQuestPromises.push(
        editQuest(
          questSeries.id,
          questSeries.user_id,
          questInfo,
          photoFile ?? undefined
        )
      )
    })

    return Promise.all(editQuestPromises)
  }

  const editQuestMutation = useMutation({
    mutationFn: editQuestAction,
    onError: (err: any) => {
      console.log(err)
      Sentry.captureException(err)
      toast.error("Error editing quest. Please refresh the page and try again.")
    },
    onSuccess: async () => {
      trackAnalyticEvent(ANALYTIC_EVENTS.EDIT_QUEST)
      await queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER])
      onClose()
      resetQuestDetails()
    },
  })

  return editQuestMutation
}

export const useAddQuestMutation = () => {
  const {
    questName,
    description,
    photoFile,
    coinRewardsMap,
    category,
    routine,
    repetition,
    repeatsFrequency,
    isOngoing,
    startDate,
    reminderTimeMap,
    timerLength,
    assignedUsers,
    mandatory,
    reqReview,
    reqNotes,
    reqPhoto,
    saveTemplate,
    isGrouped,
    templateId,
    customTemplateId,

    resetQuestDetails,
    onClose,
    setErrors,
  } = useAddEditQuestStore(
    useShallow((state) => ({
      questName: state.questName,
      description: state.description,
      photoFile: state.photoFile,
      coinRewardsMap: state.coinRewardsMap,
      category: state.category,
      routine: state.routine,
      repetition: state.repetition,
      repeatsFrequency: state.repeatsFrequency,
      isOngoing: state.isOngoing,
      startDate: state.startDate,
      reminderTimeMap: state.reminderTimeMap,
      timerLength: state.timerLength,
      assignedUsers: state.assignedUsers,
      mandatory: state.mandatory,
      reqReview: state.reqReview,
      reqNotes: state.reqNotes,
      reqPhoto: state.reqPhoto,
      saveTemplate: state.saveTemplate,
      templateId: state.templateId,
      customTemplateId: state.customTemplateId,
      isGrouped: state.isGrouped,

      resetQuestDetails: state.resetQuestDetails,
      setErrors: state.setErrors,
      onClose: state.onClose,
      selectedQuest: state.selectedQuest,
    }))
  )

  const checkForErrors = () => {
    const tempErrors = {} as any
    if (questName.length === 0) {
      tempErrors.questName = "Please enter a Quest Title"
    }
    if (assignedUsers.length === 0) {
      tempErrors.assignedUsers = "Please assign to at least one person"
    }
    setErrors(tempErrors)
    if (Object.keys(tempErrors)?.length > 0)
      throw new Error("Invalid quest details")
  }

  const addQuestAction = (hasParentSelected?: boolean) => {
    checkForErrors()

    const isRepeatingQuest = !!repetition.some((day) => day)

    const recurrence = new RRule({
      freq: isRepeatingQuest ? RRule.WEEKLY : RRule.DAILY,
      byweekday: isRepeatingQuest ? convertToByWeekday(repetition) : undefined,
      interval: isRepeatingQuest ? repeatsFrequency ?? 1 : undefined,
    })

    const addQuestPromises = [] as Promise<any>[]

    const sharedData = {
      title: questName,
      requires_review: reqReview,
      requires_completion_notes: reqNotes,
      requires_completion_photo: reqPhoto,
      routine: routine,
      mandatory: mandatory,
      description,
      skill: category,
      timer_length: timerLength,
      reminder_time: reminderTimeMap[routine] || null,
      occurrence_limit: isRepeatingQuest ? null : 1,
      template_id: templateId,
      custom_template_id: customTemplateId,
      recurrence: recurrence.toString(),
      start_date: isOngoing
        ? dayjs().format("YYYY-MM-DD")
        : dayjs(startDate || new Date()).format("YYYY-MM-DD"),
      end_date:
        isOngoing || isRepeatingQuest
          ? null
          : dayjs(startDate || new Date()).format("YYYY-MM-DD"),
    }

    // if grouped quest, only hit endpoint once. Do not send user_id or redeemable_reward
    if (isGrouped && assignedUsers.length > 1) {
      addQuestPromises.push(
        addQuest(
          undefined,
          {
            ...sharedData,
            quest_group_user_rewards: assignedUsers.map((userId) => ({
              user_id: userId,
              redeemable_reward: coinRewardsMap[userId],
            })),
            save_as_template: saveTemplate,
          },
          photoFile ?? undefined
        )
      )
    } else {
      // For each user, create a quest promise
      assignedUsers.forEach((userId, index) => {
        addQuestPromises.push(
          addQuest(
            userId,
            {
              ...sharedData,
              user_id: userId,
              redeemable_reward: coinRewardsMap[userId],
              ...(index === 0 ? { save_as_template: saveTemplate } : {}),
            },
            photoFile ?? undefined
          )
        )
      })
    }

    trackAnalyticEvent(ANALYTIC_EVENTS.ADD_QUEST, {
      is_parent_quest: !!hasParentSelected,
    })

    // Wait for all the API calls to complete before continuing
    return Promise.all(addQuestPromises)
  }

  const addQuestMutation = useMutation({
    mutationFn: addQuestAction,
    onError: (err: any) => {
      console.log(err)
      Sentry.captureException(err)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
      queryClient.invalidateQueries([QUERY_KEYS.PARENT_TASKS])
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER])
      onClose()
      resetQuestDetails()
    },
  })

  return addQuestMutation
}
