import { create } from "zustand"

interface FlaggedConvoModalStore {
  isOpen: boolean
  onClose: () => void
  onOpen: (date: string, logIdToScrollTo: number) => void
  date: string | null
  setDate: (date: string) => void
  logIdToScrollTo: number | null
  setLogIdToScrollTo: (logId: number | null) => void
}

const useFlaggedConvoModalStore = create<FlaggedConvoModalStore>((set) => ({
  isOpen: false,
  onOpen: (date, logIdToScrollTo) =>
    set({ isOpen: true, date, logIdToScrollTo }),
  onClose: () => set({ isOpen: false, date: null, logIdToScrollTo: null }),
  date: null,
  setDate: (date) => set({ date }),
  logIdToScrollTo: null,
  setLogIdToScrollTo: (logId) => set({ logIdToScrollTo: logId }),
}))

export default useFlaggedConvoModalStore
