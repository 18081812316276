import {
  Button,
  CustomRewardCurrency,
  CustomRewardLog,
  CustomRewardLogType,
  JoonUIColor,
  SPACING,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useState } from "react"
import toast from "react-hot-toast"

import Card from "../../components/card/Card"
import { useUndoCustomRewardLogMutation } from "../../networking/rewards/mutations"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"

const RewardTransactionCard = ({
  currentRewardLog,
  allRewardLogs,
}: {
  currentRewardLog: CustomRewardLog
  allRewardLogs: CustomRewardLog[]
}) => {
  const [clickedLogId, setClickedLogId] = useState<number | null>(null)
  const undoCustomRewardLogMutation = useUndoCustomRewardLogMutation()
  const router = useRouter()

  const onUndoClick = async (logId: number) => {
    await undoCustomRewardLogMutation.mutateAsync(logId)
    const clickedRewardLog = allRewardLogs?.find((log) => log.id === logId)
    toast.success(
      `Reward ${clickedRewardLog?.type === CustomRewardLogType.REDEEM ? "redemption" : "purchase"} successfully undone!`
    )
  }

  const amountChange = Math.abs(currentRewardLog.amount_change)
  const rewardLogTitle = `${currentRewardLog.reward.emoji} ${currentRewardLog.reward.title} (${currentRewardLog.reward.currency === CustomRewardCurrency.DOLLAR ? "$" : ""}${amountChange}${currentRewardLog.reward.currency === CustomRewardCurrency.MINUTE ? (amountChange === 1 ? " minute" : " minutes") : ""})`
  return (
    <Card
      title={rewardLogTitle}
      subtitle={`${currentRewardLog.type === CustomRewardLogType.REDEEM ? "Redeemed" : "Purchased"} on ${dayjs(currentRewardLog.date_created).format("MMM D, YYYY")}`}
      coinAmount={
        currentRewardLog.type === CustomRewardLogType.PURCHASE
          ? Math.abs(
              currentRewardLog.reward.cost * currentRewardLog.amount_change
            )
          : undefined
      }
      key={currentRewardLog.id}
      userIds={[currentRewardLog.user.id]}
      onClick={(e) => {
        e.stopPropagation()
        trackAnalyticEvent(ANALYTIC_EVENTS.VIEW_CUSTOM_REWARD_DETAILS)
        router.push(`/rewards/details/${currentRewardLog.reward.id}`)
      }}
      buttonComponent={
        currentRewardLog.can_undo && (
          <Button
            buttonType="secondary"
            size="small"
            onClick={(e) => {
              setClickedLogId(currentRewardLog.id)
              e.stopPropagation()
              onUndoClick(currentRewardLog.id)
            }}
            text="Undo"
            isLoading={
              undoCustomRewardLogMutation.isLoading &&
              clickedLogId === currentRewardLog.id
            }
            style={{
              color: JoonUIColor.text.primary,
              borderRadius: SPACING.space2,
              border: `1px solid ${JoonUIColor.border.default}`,
              padding: `${SPACING.space1} ${SPACING.space2}`,
              overflow: "visible",
              height: "auto",
            }}
          />
        )
      }
    />
  )
}

export default RewardTransactionCard
