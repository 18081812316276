import {
  ArrowRightIcon,
  CustomReward,
  CustomRewardCurrency,
  EditIcon,
  FilterIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Switch,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"

import FilterItem from "./FilterItem"
import RewardChildRedeemCard from "./RewardChildRedeemCard"
import { RewardLogRow, RewardLogTableLabel } from "./RewardLogRow"
import { useSmartRedemptionModalStore } from "./SmartRedemptionModal"
import UserBadge from "../../../components/badges/userBadge/UserBadge"
import PageContentWrapper from "../../../components/pageContentWrapper/PageContentWrapper"
import PageWrapper from "../../../components/pageWrapper/PageWrapper"
import {
  GRADIENTS,
  SmartRedemptionAnalyticsStep,
  SmartRedemptionModalStep,
} from "../../../constants"
import useMobile from "../../../hooks/useMobile"
import { useEditRewardMutation } from "../../../networking/rewards/mutations"
import {
  useCustomRewardLogsQuery,
  useCustomRewardLogsStatsQuery,
  useCustomRewardsQuery,
} from "../../../networking/rewards/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { requireAuth } from "../../../util/auth"
import { useRouter } from "../../../util/router"
import { getScreenTimeConfigurations } from "../../../util/util"
import { QuickActionMobileButton } from "../../me/QuickActionsSection"
import useAddEditRewardModalStore from "../hooks/useAddEditRewardModalStore"
import { useFilterRewardLogsModalStore } from "../hooks/useFilterRewardLogsModalStore"
import usePurchaseRewardModalStore from "../hooks/usePurchaseRewardModalStore"

const RewardLogDetailsPage = () => {
  const { onOpen: openAddEditRewardModal } = useAddEditRewardModalStore()
  const {
    onOpen: openFilterRewardLogsModal,
    selectedUser,
    setSelectedUser,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = useFilterRewardLogsModalStore()
  const { onOpen: onOpenPurchaseRewardModal } = usePurchaseRewardModalStore()
  const { onOpen: onOpenSmartRedemptionModal, setIsPostCreate } =
    useSmartRedemptionModalStore()
  const router = useRouter()
  const { rewardId } = router.params
  const { data: customRewards } = useCustomRewardsQuery()
  const { data: customRewardLogs, isLoading: isCustomRewardLogsLoading } =
    useCustomRewardLogsQuery({ rewardId: Number(rewardId) })
  const { data: customRewardLogsStats } = useCustomRewardLogsStatsQuery({
    rewardId: Number(rewardId),
  })
  const rewardDetails: CustomReward | null =
    customRewards?.find((reward) => reward.id === Number(rewardId)) ||
    customRewardLogs?.find(
      (rewardLog) => rewardLog.reward_id === Number(rewardId)
    )?.reward ||
    null
  const { numLinkedDevices } = getScreenTimeConfigurations(rewardDetails)
  const editRewardMutation = useEditRewardMutation()
  const isMobile = useMobile()

  const hasFilters = selectedUser || startDate || endDate

  const onFilterClick = () => {
    if (!customRewardLogs?.length) return
    openFilterRewardLogsModal(Number(rewardId))
  }

  const onClearClick = () => {
    setSelectedUser(null)
    setStartDate(null)
    setEndDate(null)
  }

  const onSmartRedemptionClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.REWARD_DETAILS_SETUP_SCREENTIME)
    setIsPostCreate(false)
    onOpenSmartRedemptionModal(
      numLinkedDevices > 0
        ? SmartRedemptionModalStep.DEVICE_SELECTION
        : SmartRedemptionModalStep.INTRO
    )
    if (numLinkedDevices > 0)
      trackAnalyticEvent(ANALYTIC_EVENTS.SCREENTIME_INSTRUCTIONS, {
        step: SmartRedemptionAnalyticsStep.DEVICE_SELECTION,
      })
  }

  return (
    <PageWrapper style={{ background: GRADIENTS.gradient100 }}>
      <PageContentWrapper mobileGutter>
        <FlexBox
          direction="row"
          justify="space-between"
          align="center"
          wrap={false}
          style={{ paddingTop: SPACING.space4, paddingBottom: SPACING.space4 }}
        >
          <TextButton
            onClick={() => {
              router.push("/rewards")
              onClearClick()
            }}
            style={{ transform: "rotate(180deg)", padding: SPACING.space1 }}
          >
            <ArrowRightIcon size={20} color={JoonUIColor.icon.neutral} />
          </TextButton>
          <Typography
            variant="h3"
            textAlign="center"
            color={JoonColorExpanded.indigo700}
          >
            {!rewardDetails && isCustomRewardLogsLoading ? (
              <Typography variant="bodyXSmall">Loading...</Typography>
            ) : rewardDetails ? (
              `${rewardDetails?.emoji} ${rewardDetails?.title}`
            ) : (
              <Typography variant="bodyXSmall">No reward found</Typography>
            )}
          </Typography>
          {rewardDetails && !rewardDetails?.date_deleted ? (
            <TextButton onClick={() => openAddEditRewardModal(rewardDetails)}>
              <EditIcon size={16} color={JoonUIColor.icon.neutral} />
            </TextButton>
          ) : (
            <TextButton
              onClick={() => {}}
              style={{ pointerEvents: "none", opacity: 0 }}
            >
              <div style={{ width: 16, height: 16 }}></div>
            </TextButton>
          )}
        </FlexBox>

        <FlexBox direction="column" gap={SPACING.space6} wrap={false}>
          <FlexBox
            direction="column"
            justify="flex-start"
            align="flex-start"
            gap={SPACING.space1}
            wrap={false}
            style={{
              border: `1px solid ${JoonUIColor.border.default}`,
              borderRadius: SPACING.space2,
              padding: SPACING.space3,
              background: JoonUIColor.background.primaryNeutral,
            }}
          >
            {rewardDetails?.date_deleted ? (
              <FlexBox direction="column" gap={SPACING.space2}>
                <Typography
                  variant="bodyXSmallBold"
                  color={JoonUIColor.text.secondary}
                  style={{
                    background: JoonColorExpanded.neutral200,
                    padding: `${SPACING.space0} ${SPACING.space2}`,
                    borderRadius: SPACING.space2,
                  }}
                >
                  {`This reward was deleted on ${dayjs(
                    rewardDetails?.date_deleted
                  ).format("MMM D")}.`}
                </Typography>
                <Typography variant="bodyXSmall">
                  Reward no longer available for purchase, but you can still
                  redeem previously purchased items.
                </Typography>
              </FlexBox>
            ) : (
              rewardDetails && (
                <FlexBox
                  direction="row"
                  justify="space-between"
                  align="center"
                  wrap={false}
                >
                  <Typography variant="bodySmallBold">
                    Allow kids to purchase
                  </Typography>
                  <Switch
                    checked={rewardDetails.purchasable_from_joonity}
                    name="availableForPurchase"
                    onChange={() =>
                      editRewardMutation.mutate({
                        rewardId: rewardDetails.id,
                        rewardInfo: {
                          title: rewardDetails.title,
                          cost: rewardDetails.cost,
                          emoji: rewardDetails.emoji,
                          user_ids: rewardDetails.assignments.map(
                            (assignment) => assignment.user_id
                          ),
                          purchasable_from_joonity:
                            !rewardDetails.purchasable_from_joonity,
                        },
                      })
                    }
                  />
                </FlexBox>
              )
            )}

            <FlexBox
              direction="column"
              gap={SPACING.space1}
              style={{ marginBottom: SPACING.space1 }}
            >
              <Typography
                variant="bodyXSmallBold"
                color={JoonUIColor.text.secondary}
              >
                {rewardDetails?.currency === CustomRewardCurrency.ITEM
                  ? "Cost"
                  : "Exchange rate"}
              </Typography>
              <FlexBox
                gap={SPACING.space1}
                direction="row"
                wrap={false}
                align="center"
              >
                <img
                  style={{ height: SPACING.space3 }}
                  src="/images/icons/coin-icon.png"
                  alt="coin"
                />
                <Typography variant="bodySmall">
                  {rewardDetails?.cost}
                  {rewardDetails?.currency === CustomRewardCurrency.MINUTE
                    ? "/minute"
                    : rewardDetails?.currency === CustomRewardCurrency.DOLLAR
                      ? "/dollar"
                      : ""}
                </Typography>
              </FlexBox>
            </FlexBox>

            {!rewardDetails?.date_deleted && rewardDetails?.assignments && (
              <FlexBox direction="column" gap={SPACING.space1}>
                <Typography
                  variant="bodyXSmallBold"
                  color={JoonUIColor.text.secondary}
                >
                  Available for
                </Typography>
                <FlexBox direction="row" gap={SPACING.space1}>
                  {rewardDetails.assignments.length === 0 ? (
                    <Typography variant="bodyXSmall">-</Typography>
                  ) : (
                    rewardDetails.assignments.map((assignment, i) => (
                      <UserBadge userId={assignment.user_id} key={i} />
                    ))
                  )}
                </FlexBox>
              </FlexBox>
            )}
          </FlexBox>

          <FlexBox direction="column" gap={SPACING.space2}>
            <Typography variant="bodyBold">Current balance</Typography>
            {rewardDetails?.assignments.map((assignment) => (
              <RewardChildRedeemCard
                childId={assignment.user_id}
                key={assignment.user_id}
                reward={rewardDetails}
              />
            ))}
          </FlexBox>

          <FlexBox direction="column" gap={SPACING.space2}>
            <Typography variant="bodyBold">Analytics</Typography>

            <FlexBox direction="row" justify="space-between" wrap={false}>
              <FlexBox direction="column" gap={SPACING.space1}>
                <Typography
                  variant="bodyXSmallBold"
                  color={JoonUIColor.text.secondary}
                >
                  Total purchased (all time)
                </Typography>
                <Typography
                  variant="bodySmallBold"
                  color={JoonUIColor.text.primary}
                >
                  {customRewardLogsStats?.purchased_all_time}
                </Typography>
              </FlexBox>
              <FlexBox direction="column" gap={SPACING.space1}>
                <Typography
                  variant="bodyXSmallBold"
                  color={JoonUIColor.text.secondary}
                >
                  Total purchased (this week)
                </Typography>
                <Typography
                  variant="bodySmallBold"
                  color={JoonUIColor.text.primary}
                >
                  {customRewardLogsStats?.purchased_this_week}
                </Typography>
              </FlexBox>
            </FlexBox>

            <FlexBox direction="row" justify="space-between" wrap={false}>
              <FlexBox direction="column" gap={SPACING.space1}>
                <Typography
                  variant="bodyXSmallBold"
                  color={JoonUIColor.text.secondary}
                >
                  Total redeemed (all time)
                </Typography>
                <Typography
                  variant="bodySmallBold"
                  color={JoonUIColor.text.primary}
                >
                  {customRewardLogsStats?.redeemed_all_time}
                </Typography>
              </FlexBox>
              <FlexBox direction="column" gap={SPACING.space1}>
                <Typography
                  variant="bodyXSmallBold"
                  color={JoonUIColor.text.secondary}
                >
                  Total redeemed (this week)
                </Typography>
                <Typography
                  variant="bodySmallBold"
                  color={JoonUIColor.text.primary}
                >
                  {customRewardLogsStats?.redeemed_this_week}
                </Typography>
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <FlexBox
            direction="column"
            gap={SPACING.space2}
            style={{ marginBottom: SPACING.space14 }}
          >
            <FlexBox direction="column" gap={SPACING.space1}>
              <FlexBox
                direction="row"
                justify="space-between"
                align="center"
                wrap={false}
              >
                <TextButton
                  onClick={() => router.push("/rewards/transactions")}
                  style={{
                    padding: 0,
                  }}
                >
                  <Typography variant="bodyBold">
                    Transaction history
                  </Typography>
                </TextButton>
                <TextButton onClick={onFilterClick}>
                  <FilterIcon
                    size={16}
                    color={
                      hasFilters
                        ? JoonUIColor.icon.accent
                        : JoonUIColor.icon.neutral
                    }
                  />
                </TextButton>
              </FlexBox>
              {hasFilters && (
                <FlexBox
                  direction="row"
                  justify="space-between"
                  align="flex-end"
                  wrap={false}
                >
                  <FlexBox direction="row" gap={SPACING.space1}>
                    {selectedUser && <FilterItem user={selectedUser} />}
                    {startDate && endDate ? (
                      <FilterItem startDate={startDate} endDate={endDate} />
                    ) : startDate ? (
                      <FilterItem startDate={startDate} />
                    ) : endDate ? (
                      <FilterItem endDate={endDate} />
                    ) : null}
                  </FlexBox>
                  <TextButton onClick={onClearClick} style={{ padding: 0 }}>
                    <Typography
                      variant="bodySmall"
                      color={JoonUIColor.text.primaryAccent}
                    >
                      clear
                    </Typography>
                  </TextButton>
                </FlexBox>
              )}
            </FlexBox>

            <RewardLogTableLabel />
            <hr />
            {isCustomRewardLogsLoading ? (
              <Typography variant="bodyXSmall">Loading...</Typography>
            ) : customRewardLogs?.length ? (
              customRewardLogs?.map((log) => (
                <RewardLogRow log={log} key={log.id} />
              ))
            ) : (
              <Typography variant="bodyXSmall">No logs found.</Typography>
            )}
          </FlexBox>
        </FlexBox>
      </PageContentWrapper>
      {rewardDetails && !rewardDetails.date_deleted && (
        <FlexBox
          direction="row"
          justify="center"
          align="center"
          gap={SPACING.space2}
          wrap={false}
          style={{
            position: "fixed",
            zIndex: 500,
            width: "min(600px, 100%)",
            bottom: isMobile ? "78px" : "20px",
            borderRadius: SPACING.space2,
            background: "rgba(13, 5, 105, 0.12)",
            padding: SPACING.space2,
          }}
          fullWidth
        >
          <QuickActionMobileButton
            text="Purchase"
            onClick={() =>
              rewardDetails && onOpenPurchaseRewardModal(rewardDetails)
            }
          />
          {rewardDetails?.currency === CustomRewardCurrency.MINUTE && (
            <div style={{ width: "68%" }}>
              <QuickActionMobileButton
                text={
                  numLinkedDevices > 0
                    ? "Smart Redemption Help"
                    : "Set up Smart Redemption"
                }
                onClick={onSmartRedemptionClick}
              />
            </div>
          )}
        </FlexBox>
      )}
    </PageWrapper>
  )
}

const RewardLogDetailsPageWithAuth = requireAuth(RewardLogDetailsPage)
export default RewardLogDetailsPageWithAuth
