import { JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { Toaster } from "react-hot-toast"

export const CustomToaster = () => {
  return (
    <Toaster
      position="top-center"
      gutter={4}
      containerStyle={{ zIndex: 1500 }}
      toastOptions={{
        style: {
          background: JoonUIColor.background.inverted,
          color: JoonUIColor.text.inverted,
          padding: SPACING.space1,
          borderRadius: SPACING.space2,
        },
      }}
    />
  )
}

export const Toasts = {
  therapistSuccessfullyLinked: (
    <div>
      <Typography
        variant="bodySmallBold"
        color={JoonUIColor.text.inverted}
        textAlign="center"
      >
        Accounts successfully linked.
      </Typography>
      <Typography
        variant="bodyXSmall"
        color={JoonUIColor.text.inverted}
        textAlign="center"
      >
        You have free access to Joon for the next year!
      </Typography>
    </div>
  ),
  parentPaywallDiscount: (
    <div>
      <Typography
        variant="bodySmallBold"
        color={JoonUIColor.text.inverted}
        textAlign="center"
      >
        Referral code applied.
      </Typography>
      <Typography
        variant="bodyXSmall"
        color={JoonUIColor.text.inverted}
        textAlign="center"
      >
        You've unlocked the discounted price!
      </Typography>
    </div>
  ),
  noItemsToGift: (
    <div>
      <Typography
        variant="bodySmallBold"
        color={JoonUIColor.text.inverted}
        textAlign="center"
      >
        You have no items to gift.
      </Typography>
      <Typography
        variant="bodyXSmall"
        color={JoonUIColor.text.inverted}
        textAlign="center"
      >
        Earn items you can gift your kids by doing tasks on this app!
      </Typography>
    </div>
  ),
  noOnboardedChildren: (
    <div>
      <Typography
        variant="bodySmallBold"
        color={JoonUIColor.text.inverted}
        textAlign="center"
      >
        You need to onboard your children first!
      </Typography>
      <Typography
        variant="bodyXSmall"
        color={JoonUIColor.text.inverted}
        textAlign="center"
      >
        Set them up on the Joon Pet Game first before you can send them mail.
      </Typography>
    </div>
  ),
  successReorderingQuests: (
    <div>
      <Typography
        variant="bodySmallBold"
        color={JoonUIColor.text.inverted}
        textAlign="center"
      >
        Quests reordered successfully.
      </Typography>
    </div>
  ),
  errorReorderingQuests: (
    <div>
      <Typography
        variant="bodySmallBold"
        color={JoonUIColor.text.inverted}
        textAlign="center"
      >
        Error reordering quests.
      </Typography>
    </div>
  ),
  planUpgradeRequestSent: (
    <div>
      <Typography
        variant="bodySmallBold"
        color={JoonUIColor.text.inverted}
        textAlign="center"
      >
        Request sent!
      </Typography>
    </div>
  ),
}
