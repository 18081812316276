import { CalendarSolidIcon, InfoSolidIcon } from "@joonapp/web-shared"
import {
  ArrowRightIcon,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { JoonColorExpanded } from "@joonapp/web-shared"
import { FlexBox } from "@joonapp/web-shared"
import { useState } from "react"

import useSession from "./hooks/useSession"
import SessionActionButton from "./SessionActionButton"
import SessionBookingModal from "./SessionBookingModal"
import { useSessionBookingQuery } from "../../../networking/sessions/queries"
import { useRouter } from "../../../util/router"
import { formatDate } from "../../../util/util"
import CareTeamAvatar from "../../care/CareTeamAvatar"
import useCareTeam from "../../care/useCareTeam"
import CoachInfoModal from "../CoachInfoModal"
import useCoachStore from "../useCoachStore"

const SessionDetailsHeader = () => {
  const [isHoveredInfoIcon, setIsHoveredInfoIcon] = useState(false)
  const [isHoveredArrowIcon, setIsHoveredArrowIcon] = useState(false)
  const { onOpenInfoModal } = useCoachStore()
  const { selectedCoachProfile } = useCareTeam()

  const router = useRouter()
  const careTeamUserId = router.query.careTeamUserId as string | undefined
  const { data: sessionBooking, isLoading: isLoadingSessionBooking } =
    useSessionBookingQuery()
  const {
    sessionIsWithin24Hours,
    handleJoinSession,
    handleCancelSession,
    handleReschedule,
    sessionIsPast,
  } = useSession(sessionBooking)

  const { nickname } = selectedCoachProfile || {}
  const { name, last_name } = selectedCoachProfile?.user || {}

  const displayName = nickname ?? `${name} ${last_name || ""}`

  if (!selectedCoachProfile) return <></>

  return (
    <FlexBox
      direction="column"
      justify="space-between"
      wrap={false}
      gap={SPACING.space2}
      style={{
        background: JoonColorExpanded.yellow200,
        width: "100%",
        padding: SPACING.space4,
        borderRadius: `0 0 ${SPACING.space4} ${SPACING.space4}`,
      }}
    >
      <FlexBox
        direction="row"
        gap={SPACING.space2}
        wrap={false}
        align="center"
        style={{
          width: `min(100%, calc(600px - ${SPACING.space8}))`,
          margin: "0 auto",
        }}
      >
        <TextButton
          onClick={() => router.push(`/coach/${careTeamUserId}/sessions`)}
          style={{
            borderRadius: "50%",
            padding: SPACING.space1,
            transform: "rotate(180deg)",
            marginRight: SPACING.space2,
          }}
          onMouseEnter={() => setIsHoveredArrowIcon(true)}
          onMouseLeave={() => setIsHoveredArrowIcon(false)}
        >
          <ArrowRightIcon
            size={24}
            color={
              isHoveredArrowIcon
                ? JoonColorExpanded.neutral300
                : JoonUIColor.icon.neutral
            }
          />
        </TextButton>
        <CareTeamAvatar profile={selectedCoachProfile} />
        <FlexBox
          direction="row"
          justify="space-between"
          align="center"
          wrap={false}
        >
          <FlexBox direction="column" align="flex-start" wrap={false}>
            <Typography variant="h3">{displayName}</Typography>
            {!isLoadingSessionBooking && (
              <Typography
                variant="bodySmallBold"
                color={JoonColorExpanded.purple600}
              >
                {formatDate(
                  new Date(sessionBooking?.start_datetime || ""),
                  "MMM D"
                )}
                {", "}
                {formatDate(
                  new Date(sessionBooking?.start_datetime || ""),
                  "h:mm A",
                  false
                )}
              </Typography>
            )}
          </FlexBox>

          <TextButton
            onClick={onOpenInfoModal}
            style={{
              borderRadius: "50%",
              padding: SPACING.space1,
            }}
            onMouseEnter={() => setIsHoveredInfoIcon(true)}
            onMouseLeave={() => setIsHoveredInfoIcon(false)}
          >
            <InfoSolidIcon
              size={20}
              color={
                isHoveredInfoIcon
                  ? JoonColorExpanded.purple500
                  : JoonColorExpanded.purple600
              }
            />
          </TextButton>
        </FlexBox>
      </FlexBox>
      <FlexBox
        direction="row"
        gap={SPACING.space1}
        wrap={false}
        justify="center"
        style={{
          width: `min(100%, calc(600px - ${SPACING.space8}))`,
          margin: "0 auto",
        }}
      >
        {sessionIsWithin24Hours ? (
          <SessionActionButton
            text="Join Session"
            Icon={CalendarSolidIcon}
            onClick={handleJoinSession}
            fullWidth
          />
        ) : (
          sessionBooking &&
          !sessionIsPast && (
            <>
              <SessionActionButton
                text="Cancel"
                Icon={CalendarSolidIcon}
                onClick={handleCancelSession}
                fullWidth
              />
              <SessionActionButton
                text="Reschedule"
                Icon={CalendarSolidIcon}
                onClick={handleReschedule}
                fullWidth
              />
            </>
          )
        )}
      </FlexBox>
      <CoachInfoModal />
      <SessionBookingModal />
    </FlexBox>
  )
}

export default SessionDetailsHeader
