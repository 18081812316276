import { FlexBox, SPACING } from "@joonapp/web-shared"
import { useEffect } from "react"

import AddChildren from "./AddChildren"
import AddChildrenStart from "./AddChildrenStart"
import CoachQuestionnaireDisclaimer from "./CoachQuestionnaireDisclaimer"
import CoachQuestionnaireSection from "./CoachQuestionnaireSection"
import CoachSignUp from "./CoachSignUp"
import PostAnswerScreen from "./PostAnswerScreen"
import useCoachOnboardingStore, {
  CoachOnboardingStep,
} from "./useCoachOnboardingStore"
import OnboardingPageWrapper from "../../components/onboarding/onboardingPageWrapper/OnboardingPageWrapper"
import { BACKGROUND_COLORS } from "../../constants"
import useMobile from "../../hooks/useMobile"
import { useCoachOnboardIntakeFormQuestionnaireQuery } from "../../networking/queries"
import { useRouter } from "../../util/router"
import { sessionManager } from "../../util/storage"

const CoachOnboarding = () => {
  const isMobile = useMobile()
  const router = useRouter()
  useCoachOnboardIntakeFormQuestionnaireQuery()
  const { step } = useCoachOnboardingStore()

  // Redirect logged-in users to coaching page
  useEffect(() => {
    if (sessionManager.getRefreshToken()) {
      router.push("/coaching")
    }
  }, [router])

  const currentStep = () => {
    switch (step) {
      case CoachOnboardingStep.COACH_QUESTIONNAIRE_DISCLAIMER:
        return <CoachQuestionnaireDisclaimer />
      case CoachOnboardingStep.COACH_QUESTIONNAIRE:
        return (
          <FlexBox
            direction="column"
            style={{ padding: `0 ${SPACING.space4}`, paddingBottom: "10dvh" }}
            gap={SPACING.space4}
          >
            <CoachQuestionnaireSection />
          </FlexBox>
        )
      case CoachOnboardingStep.POST_ANSWER_SCREEN:
        return <PostAnswerScreen />
      case CoachOnboardingStep.ADD_CHILDREN_START:
        return <AddChildrenStart />
      case CoachOnboardingStep.ADD_CHILDREN:
        return <AddChildren />
      case CoachOnboardingStep.COACH_SIGN_UP:
        return <CoachSignUp />
    }
  }

  return (
    <OnboardingPageWrapper
      style={{
        background: isMobile
          ? "linear-gradient(to bottom, rgba(245, 250, 255, 1), rgba(210, 230, 250, 1))"
          : BACKGROUND_COLORS.xlightBlueBg,
      }}
    >
      {currentStep()}
    </OnboardingPageWrapper>
  )
}

export default CoachOnboarding
