import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { useState } from "react"

import usePlanUpdateModalStore from "./planUpdate/usePlanUpdateModalStore"

const NoSessionsInPlanScreen = () => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const { onOpen } = usePlanUpdateModalStore()

  return (
    <FlexBox
      direction="column"
      align="center"
      gap={SPACING.space4}
      style={{
        maxWidth: "500px",
        margin: "0 auto",
        textAlign: "center",
        padding: SPACING.space6,
      }}
    >
      <Typography
        variant="bodyBold"
        style={{ maxWidth: "300px", textAlign: "center" }}
      >
        Get more out of your coach with 1:1 personalized live coaching sessions.
      </Typography>
      <Typography variant="bodySmall" style={{ maxWidth: "200px" }}>
        Upgrade today to virtually meet your coach, anytime, anywhere
      </Typography>
      <div style={{ minHeight: "170px" }}>
        <img
          src={"/images/sessions_empty.svg"}
          alt="sessions"
          loading="lazy"
          style={{
            opacity: imageLoaded ? 1 : 0,
            transition: "opacity 0.1s ease-in-out",
          }}
          onLoad={() => setImageLoaded(true)}
        />
      </div>
      <Typography variant="bodySmall" style={{ maxWidth: "200px" }}>
        All you need is a little space and an open mind to get started
      </Typography>

      <FlexBox
        direction="column"
        gap={SPACING.space2}
        style={{
          background: JoonUIColor.background.lightGray,
          borderRadius: SPACING.space2,
          padding: SPACING.space4,
          width: "fit-content",
        }}
      >
        <Typography variant="bodySmallBold">
          Your current coaching plan does not include live coaching sessions.
        </Typography>
        <button
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: JoonUIColor.background.accent,
            borderRadius: SPACING.space2,
            padding: `${SPACING.space2} ${SPACING.space4}`,
            boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.1)",
            width: "100%",
          }}
          onClick={onOpen}
        >
          <Typography variant="bodyBold" color={JoonUIColor.text.inverted}>
            Upgrade coaching plan
          </Typography>

          <FontAwesomeIcon
            icon={faChevronRight}
            fontSize={14}
            color={JoonUIColor.icon.inverted}
          />
        </button>
      </FlexBox>
    </FlexBox>
  )
}

export default NoSessionsInPlanScreen
