import { create } from "zustand"

interface TOSSigningModalStore {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void

  isAgreementAccepted: boolean

  firstName: string
  lastName: string
  month: string
  day: string
  year: string
  streetAddress: string
  addressLine2: string
  city: string
  stateProvinceRegion: string
  zipPostalCode: string
  country: string
  emergencyContactFirstName: string
  emergencyContactLastName: string
  emergencyContactPhoneNumber: string
  showFormContent: boolean

  setIsAgreementAccepted: (value: boolean) => void
  setFirstName: (value: string) => void
  setLastName: (value: string) => void
  setMonth: (value: string) => void
  setDay: (value: string) => void
  setYear: (value: string) => void
  setStreetAddress: (value: string) => void
  setAddressLine2: (value: string) => void
  setCity: (value: string) => void
  setStateProvinceRegion: (value: string) => void
  setZipPostalCode: (value: string) => void
  setCountry: (value: string) => void
  setEmergencyContactFirstName: (value: string) => void
  setEmergencyContactLastName: (value: string) => void
  setEmergencyContactPhoneNumber: (value: string) => void
  setShowFormContent: (value: boolean) => void
}

export const useTOSSigningModalStore = create<TOSSigningModalStore>()(
  (set) => ({
    isOpen: false,
    onClose: () => set({ isOpen: false }),
    onOpen: () => set({ isOpen: true }),

    isAgreementAccepted: false,

    firstName: "",
    lastName: "",
    month: "",
    day: "",
    year: "",
    streetAddress: "",
    addressLine2: "",
    city: "",
    stateProvinceRegion: "",
    zipPostalCode: "",
    country: "",
    emergencyContactFirstName: "",
    emergencyContactLastName: "",
    emergencyContactPhoneNumber: "",
    showFormContent: false,

    setIsAgreementAccepted: (value: boolean) =>
      set({ isAgreementAccepted: value }),
    setFirstName: (value: string) => set({ firstName: value }),
    setLastName: (value: string) => set({ lastName: value }),
    setMonth: (value: string) => set({ month: value }),
    setDay: (value: string) => set({ day: value }),
    setYear: (value: string) => set({ year: value }),
    setStreetAddress: (value: string) => set({ streetAddress: value }),
    setAddressLine2: (value: string) => set({ addressLine2: value }),
    setCity: (value: string) => set({ city: value }),
    setStateProvinceRegion: (value: string) =>
      set({ stateProvinceRegion: value }),
    setZipPostalCode: (value: string) => set({ zipPostalCode: value }),
    setCountry: (value: string) => set({ country: value }),
    setEmergencyContactFirstName: (value: string) =>
      set({ emergencyContactFirstName: value }),
    setEmergencyContactLastName: (value: string) =>
      set({ emergencyContactLastName: value }),
    setEmergencyContactPhoneNumber: (value: string) =>
      set({ emergencyContactPhoneNumber: value }),
    setShowFormContent: (value: boolean) => set({ showFormContent: value }),
  })
)
