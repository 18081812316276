import { Checkbox, JoonUIColor, SPACING } from "@joonapp/web-shared"
import React, { useState } from "react"

export interface CheckboxProps {
  selected: boolean
  onChange: () => void
  label?: string
}

const QuestionnaireCheckbox: React.FC<CheckboxProps> = ({
  selected,
  onChange,
  label,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <button
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: SPACING.space2,
        background:
          !isHovered || selected
            ? JoonUIColor.background.primaryNeutral
            : JoonUIColor.background.xlightGray,
        border: selected
          ? `1px solid ${JoonUIColor.border.accent}`
          : `1px solid ${JoonUIColor.border.default}`,
        color: selected
          ? JoonUIColor.text.primaryAccent
          : JoonUIColor.text.primary,
        borderRadius: SPACING.space2,
        padding: SPACING.space4,
        width: "100%",
        minHeight: "56px",
        fontSize: "16px",
        textAlign: "left",
        transition:
          "border, color, box-shadow 0.15s ease-in-out, background 0.3s ease-in-out",
        userSelect: "none",
        boxShadow: selected ? "1px 1px 1px 1px rgba(0, 0, 0, 0.1)" : "none",
      }}
      onClick={onChange}
    >
      {label}
      <Checkbox
        name={label ?? ""}
        selected={selected}
        onChange={() => {}}
        hideBorder
      />
    </button>
  )
}
export default QuestionnaireCheckbox
