import { create } from "zustand"

import { localStorageItems } from "../../../util/storage"

interface ParentQuestFTUXModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useParentQuestFTUXModalStore = create<ParentQuestFTUXModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: () => {
      const hasSeenParentQuestFTUX = localStorage.getItem(
        localStorageItems.hasSeenParentQuestFTUX
      )
      if (!hasSeenParentQuestFTUX) set({ isOpen: true })
    },
    onClose: () => {
      set({ isOpen: false })
      localStorage.setItem(localStorageItems.hasSeenParentQuestFTUX, "true")
    },
  })
)
