import {
  faThumbsUp,
  faTrashCanXmark,
} from "@fortawesome/duotone-light-svg-icons"
import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
} from "@joonapp/web-shared"

import { BaseActionButton } from "../../../components/buttons/BaseActionButton"

interface JooniperAssignBarProps {
  assignLabel: string
  onAssignClick: () => void
  assignDisabled?: boolean
  dontAssignLabel: string
  onDontAssignClick: () => void
  dontAssignDisabled?: boolean
}

export const JooniperAssignBar = ({
  assignLabel,
  onAssignClick,
  assignDisabled = false,
  dontAssignLabel,
  onDontAssignClick,
  dontAssignDisabled = false,
}: JooniperAssignBarProps) => {
  return (
    <FlexBox
      direction="row"
      align="center"
      gap={SPACING.space2}
      wrap={false}
      fullWidth
      style={{
        padding: `${SPACING.space2} ${SPACING.space4}`,
        position: "fixed",
        bottom: 0,
        zIndex: 1000,
        backgroundColor: JoonUIColor.background.primaryNeutral,
        borderTop: `1px solid ${JoonUIColor.border.default}`,
        borderTopLeftRadius: SPACING.space4,
        borderTopRightRadius: SPACING.space4,
        boxShadow: `0px -2px 12px 0px ${JoonUIColor.background.lightAccent}`,
      }}
    >
      <BaseActionButton
        icon={faTrashCanXmark}
        primaryColor={JoonUIColor.semantic.destructive}
        secondaryColor={JoonColorExpanded.red100}
        label={dontAssignLabel}
        onClick={onDontAssignClick}
        disabled={dontAssignDisabled}
      />
      <BaseActionButton
        icon={faThumbsUp}
        primaryColor={JoonUIColor.background.success}
        secondaryColor={JoonColorExpanded.viridian100}
        label={assignLabel}
        onClick={onAssignClick}
        disabled={assignDisabled}
      />
    </FlexBox>
  )
}
