import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { create } from "zustand"

import doterGroup from "../../images/doter-group.svg"
import { useRouter } from "../../util/router"

interface FreeTrialLimitModalStore {
  isOpen: boolean
  onOpen: (remainingQuests?: number) => void
  onClose: () => void
  remainingQuests: number
}

export const useFreeTrialLimitModal = create<FreeTrialLimitModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (remainingQuests = 0) => set({ isOpen: true, remainingQuests }),
    onClose: () => set({ isOpen: false }),
    remainingQuests: 0,
  })
)

const FreeTrialLimitModal = () => {
  const { isOpen, onClose, remainingQuests } = useFreeTrialLimitModal()
  const router = useRouter()

  return (
    <Modal isOpen={isOpen} onClose={onClose} displayCloseIcon animate>
      <FlexBox
        direction="column"
        style={{
          width: "min(450px, 95vw)",
          padding: SPACING.space6,
          textAlign: "center",
        }}
        gap={SPACING.space4}
      >
        <img
          src={doterGroup}
          alt=""
          style={{ width: "100%", height: "120px" }}
        />
        <Typography
          variant="h2"
          color={JoonUIColor.text.primaryAccent}
          textAlign="center"
        >
          Unlock Unlimited Access
        </Typography>
        {remainingQuests > 0 ? (
          <Typography variant="body">
            You don't have enough verifies left for today. Come back tomorrow or
            start a free trial to verify more Quests.
          </Typography>
        ) : (
          <Typography variant="body">
            You've reached the free limit today. To verify more Quests, come
            back tomorrow or
            <Typography variant="bodyBold"> start a free trial.</Typography>
          </Typography>
        )}
        <Button
          text={
            <Typography variant="h3" color={JoonUIColor.text.inverted}>
              Start Free Trial
            </Typography>
          }
          onClick={() => {
            onClose()
            router.push("/checkout?tpk=seven")
          }}
          fullWidth
        />
      </FlexBox>
    </Modal>
  )
}

export default FreeTrialLimitModal
