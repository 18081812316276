import {
  Checkbox,
  FlexBox,
  Gender,
  InputSectionBox,
  JoonUIColor,
  SPACING,
  Typography,
  UserRole,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useEffect, useState } from "react"

import useCoachOnboardingStore, {
  ChildStep,
  CoachOnboardingStep,
} from "./useCoachOnboardingStore"
import ChevronBackButton from "../../components/buttons/ChevronBackButton"
import Primary3DButton from "../../components/buttons/Primary3DButton"
import SecondaryTransparentButton from "../../components/buttons/SecondaryTransparentButton"
import OnboardingPageContentWrapper from "../../components/onboarding/onboardingPageContentWrapper/OnboardingPageContentWrapper"
import { mergeQuestionnaireAnswers } from "../../components/onboarding/questionnaires/mergeQuestionnaireAnswers"
import QuestionnaireDisclaimer from "../../components/onboarding/questionnaires/QuestionnaireDisclaimer"
import QuestionnaireSection from "../../components/onboarding/questionnaires/QuestionnaireSection"
import TextInputRect from "../../components/textInputRect/TextInputRect"
import UserComponent from "../../components/UserComponent"
import { BACKGROUND_COLORS, GENDER_OPTIONS } from "../../constants"
import useMediaQuery from "../../hooks/useMediaQuery"
import useMobile from "../../hooks/useMobile"
import { useQuestionnairesQuery } from "../../networking/queries"
import { ChildDataInterface, TempChildDataInterface } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { localStorageItems } from "../../util/storage"

interface AddChildInformationProps {
  name?: string
  birthday?: dayjs.Dayjs | null
  gender?: Gender | null | undefined
  onRemoveRow: () => void
  onDoneAdding: (
    name: string,
    birthday: dayjs.Dayjs | null,
    gender: Gender | null | undefined
  ) => void
  editingChildIndex?: number | null
  setEditingChildIndex: (index: number | null) => void
  setTempChildInfo: (childInfo: TempChildDataInterface) => void
  childrenData?: ChildDataInterface[]
}

function AddChildInformation({
  name: initialName,
  birthday: initialBirthday,
  gender: initialGender,
  onRemoveRow,
  onDoneAdding,
  editingChildIndex,
  setEditingChildIndex,
  setTempChildInfo,
  childrenData,
}: AddChildInformationProps) {
  const [name, setName] = useState(initialName ?? "")
  const [month, setMonth] = useState(
    initialBirthday ? initialBirthday.format("MM") : ""
  )
  const [day, setDay] = useState(
    initialBirthday ? initialBirthday.format("DD") : ""
  )
  const [year, setYear] = useState(
    initialBirthday ? initialBirthday.format("YYYY") : ""
  )
  const [gender, setGender] = useState<Gender | null | undefined>(
    initialGender === null ? null : initialGender ?? undefined
  )
  const [warningText, setWarningText] = useState("")

  const onClickDoneAdding = () => {
    const trimmedName = name.trim()
    if (trimmedName.length === 0) {
      setWarningText("Please enter a name")
      return
    }
    const hasNoDate = month === "" && day === "" && year === ""
    const hasValidDate =
      hasNoDate ||
      (month.length === 2 &&
        day.length === 2 &&
        year.length === 4 &&
        dayjs(`${year}-${month}-${day}`).isValid() &&
        dayjs(`${year}-${month}-${day}`).isBefore(dayjs()))

    if (!hasValidDate) {
      setWarningText("Please enter a valid birthdate (MM/DD/YYYY).")
      return
    }

    const birthday = hasNoDate ? null : dayjs(`${year}-${month}-${day}`)

    trackAnalyticEvent(ANALYTIC_EVENTS.INPUT_CHILD_INFORMATION, {
      gender: gender,
      birthdate: birthday?.format("MM/DD/YYYY") ?? null,
    })

    onDoneAdding(trimmedName, birthday, gender)
  }

  const onChangeDateOfBirth = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/[^0-9]/g, "")
    if (value.length > 2) {
      value = value.slice(0, 2) + "/" + value.slice(2)
    }
    if (value.length > 5) {
      value = value.slice(0, 5) + "/" + value.slice(5)
    }
    const [newMonth, newDay, newYear] = value.split("/")
    setMonth(newMonth || "")
    setDay(newDay || "")
    setYear(newYear || "")
  }

  return (
    <InputSectionBox
      style={{
        padding: 0,
        border: "none",
        background: "transparent",
        boxShadow: "none",
        overflow: "visible",
      }}
    >
      <FlexBox direction="column" gap={SPACING.space6}>
        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="body">Child's first name</Typography>
          <TextInputRect
            placeholder="First name"
            onChange={(event: any) => setName(event.target.value)}
            value={name}
            name="firstName"
            fullWidth
          />
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="body">
            Your child is a...{" "}
            <Typography variant="bodyXSmall" color={JoonUIColor.text.secondary}>
              (optional)
            </Typography>
          </Typography>
          <FlexBox
            direction="column"
            wrap={false}
            fullWidth
            style={{
              background: JoonUIColor.background.primaryNeutral,
              borderRadius: SPACING.space2,
              border: `1px solid ${JoonUIColor.border.default}`,
            }}
          >
            {GENDER_OPTIONS.map((option, index) => (
              <button
                key={index}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: SPACING.space2,
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: `1px solid ${JoonUIColor.border.default}`,
                  ...(index === 0 && {
                    borderTop: "none",
                    borderTopLeftRadius: SPACING.space2,
                    borderTopRightRadius: SPACING.space2,
                  }),
                  ...(index === GENDER_OPTIONS.length - 1 && {
                    borderBottom: "none",
                    borderBottomLeftRadius: SPACING.space2,
                    borderBottomRightRadius: SPACING.space2,
                  }),
                  padding: SPACING.space3,
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  setGender(option.value as Gender | null)
                }}
              >
                <Typography variant="bodySmall" textAlign="left">
                  {option.label}
                </Typography>
                <Checkbox
                  selected={option.value === gender}
                  inputType="radio"
                  name={option.label}
                  onChange={() => {}}
                  hideBorder
                />
              </button>
            ))}
          </FlexBox>
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="body">
            Birthday{" "}
            <Typography variant="bodyXSmall" color={JoonUIColor.text.secondary}>
              (optional for task suggestions)
            </Typography>
          </Typography>
          <TextInputRect
            name="date-of-birth"
            placeholder="MM/DD/YYYY"
            value={`${month}${day ? "/" + day : ""}${year ? "/" + year : ""}`}
            onChange={onChangeDateOfBirth}
            maxLength={10}
            fullWidth
          />
        </FlexBox>
        <FlexBox
          justify="center"
          align="center"
          direction="column"
          gap={SPACING.space2}
        >
          {(warningText.length > 0 ||
            (childrenData && childrenData.length === 0)) && (
            <Typography
              variant="bodySmall"
              textAlign="center"
              color={
                warningText.length > 0
                  ? JoonUIColor.semantic.alert
                  : JoonUIColor.text.primary
              }
              style={{ marginTop: SPACING.space2 }}
            >
              {warningText.length > 0
                ? warningText
                : "You can always add more children later!"}
            </Typography>
          )}

          <FlexBox
            direction="column"
            gap={SPACING.space3}
            wrap={false}
            align="center"
            fullWidth
          >
            <Primary3DButton
              text="Save child info"
              onClick={onClickDoneAdding}
            />
            <FlexBox
              direction="row"
              align="center"
              wrap={false}
              gap={SPACING.space2}
              fullWidth
            >
              {editingChildIndex !== -1 && (
                <SecondaryTransparentButton
                  text={
                    <Typography
                      variant="body"
                      color={JoonUIColor.semantic.alert}
                    >
                      Remove child
                    </Typography>
                  }
                  onClick={onRemoveRow}
                  style={{
                    width: "50%",
                    height: "50px",
                    padding: SPACING.space3,
                    borderRadius: SPACING.space2,
                    border: `1px solid ${JoonUIColor.semantic.alert}`,
                  }}
                />
              )}
              {editingChildIndex !== null &&
                editingChildIndex !== undefined &&
                childrenData &&
                childrenData.length !== 0 && (
                  <SecondaryTransparentButton
                    onClick={() => {
                      if (editingChildIndex >= 0)
                        setTempChildInfo({} as TempChildDataInterface)
                      setEditingChildIndex(null)
                    }}
                    text="Cancel"
                    style={{ width: editingChildIndex !== -1 ? "50%" : "100%" }}
                  />
                )}
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </InputSectionBox>
  )
}

function OnboardAddChildrenSection() {
  const { setParentData, parentData, childStep, setChildStep, setStep } =
    useCoachOnboardingStore()
  const [childrenData, setChildrenData] = useState(parentData.childrenData)
  const [editingChildIndex, setEditingChildIndex] = useState<number | null>(
    null
  )
  const [tempChildInfo, setTempChildInfo] = useState<TempChildDataInterface>(
    {} as TempChildDataInterface
  )
  const { data: questionsResponse } = useQuestionnairesQuery()
  const isMobile = useMobile()
  const isSmallMobile = useMediaQuery("(max-width: 500px)")
  useEffect(() => {
    if (childrenData.length === 0 && editingChildIndex === null)
      setEditingChildIndex(-1)
  }, [childrenData, editingChildIndex])

  if (!questionsResponse) return null
  const { childQuestions: questions } = questionsResponse

  const onDoneAdding = (
    name: string,
    birthday: dayjs.Dayjs | null,
    gender: Gender | null | undefined
  ) => {
    const updatedTempChildInfo = {
      first_name: name,
      birthdate: birthday && birthday.unix(),
      gender,
      questionAnswerMapping: [],
    }
    setTempChildInfo(updatedTempChildInfo)
    if (!localStorage.getItem(localStorageItems.therapistCode))
      setChildStep(ChildStep.QUESTIONNAIRE_DISCLAIMER)
    else {
      finishQuestionnaire(updatedTempChildInfo)
    }
  }

  const onRemoveRow = () => {
    if (editingChildIndex == null) return
    if (editingChildIndex >= 0) {
      const newChildrenData = [...childrenData]
      newChildrenData.splice(editingChildIndex, 1)
      setChildrenData(newChildrenData)
    }
    setEditingChildIndex(null)
    setTempChildInfo({} as TempChildDataInterface)
  }

  const finishQuestionnaire = (data: TempChildDataInterface) => {
    if (editingChildIndex == null) return

    // If user said their child has ABA therapist, we want to show the ABA invite section
    const childHasABAAnswer = 632
    if (
      Object.values(data.questionAnswerMapping).some((answerIds) =>
        answerIds.includes(childHasABAAnswer)
      )
    ) {
      localStorage.setItem(localStorageItems.showABAInviteSection, "true")
      trackAnalyticEvent(ANALYTIC_EVENTS.ABA_QUESTION_ANSWERED)
    }

    const gender =
      data.gender ??
      (tempChildInfo.gender === null || tempChildInfo.gender === undefined)
        ? null
        : tempChildInfo.gender

    const newChild = {
      first_name: data.first_name ? data.first_name : tempChildInfo.first_name,
      birthdate: data.birthdate ? data.birthdate : tempChildInfo.birthdate,
      gender: gender,
      questionAnswerMapping: data.questionAnswerMapping,
    }
    if (editingChildIndex < 0) {
      // Add new child
      const newChildrenData = [...childrenData, newChild]
      setChildrenData(newChildrenData)
      setTempChildInfo({} as TempChildDataInterface)
    } else {
      // Update existing child
      const newChildrenData = [...childrenData]
      newChildrenData[editingChildIndex] = newChild
      setChildrenData(newChildrenData)
    }
    const mergedAnswers = mergeQuestionnaireAnswers(data.questionAnswerMapping)

    localStorage.setItem(
      localStorageItems.parentQuestionnaireAnswers,
      JSON.stringify(mergedAnswers)
    )
    requestAnimationFrame(() => {
      setEditingChildIndex(null)
      setChildStep(ChildStep.CHILD_INFO)
    })
  }

  if (childStep === ChildStep.CHILD_INFO) {
    return (
      <OnboardingPageContentWrapper
        backgroundOnDesktop={BACKGROUND_COLORS.lightBlueBg}
        paddingTop={
          (editingChildIndex === null || editingChildIndex === -1) &&
          childrenData.length === 0
            ? SPACING.space4
            : SPACING.space12
        }
        style={{
          position: "relative",
          height: isMobile ? "100dvh" : "80dvh",
          minHeight: isMobile ? "100dvh" : "780px",
        }}
      >
        <ChevronBackButton
          onClick={() => {
            if (editingChildIndex === null || childrenData.length === 0)
              setStep(CoachOnboardingStep.COACH_QUESTIONNAIRE)
            else {
              if (editingChildIndex >= 0)
                setTempChildInfo({} as TempChildDataInterface)
              setEditingChildIndex(null)
            }
          }}
        />
        <FlexBox direction="column" align="center" gap={SPACING.space4}>
          {(editingChildIndex === null || childrenData.length === 0) && (
            <div
              style={{
                height: childrenData.length === 0 ? "110px" : "200px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={
                  childrenData.length === 0
                    ? "images/ahoot_go_pie.png"
                    : "images/gifs/onboarding-tree-clouds-gif.gif"
                }
                alt={childrenData.length === 0 ? "Ahoot Go Pie" : "treehouse"}
                style={{
                  width:
                    childrenData.length === 0
                      ? "min(40%, 100px)"
                      : isSmallMobile
                        ? "160px"
                        : "200px",
                }}
              />
            </div>
          )}
          <Typography variant="h2Serif" textAlign="center">
            {editingChildIndex !== null && editingChildIndex !== -1
              ? "Update child information"
              : childrenData.length === 0
                ? "Let's add your first child"
                : editingChildIndex === null
                  ? "Your children"
                  : "Add a child"}
          </Typography>
          <FlexBox gap={SPACING.space2} wrap={false} direction="column">
            {childrenData.map((childData, index) => {
              const isEditingChild = index === editingChildIndex
              return (
                <FlexBox key={index}>
                  {isEditingChild ? (
                    <AddChildInformation
                      name={childData.first_name}
                      birthday={
                        childData.birthdate
                          ? dayjs.unix(childData.birthdate)
                          : null
                      }
                      gender={childData.gender}
                      onDoneAdding={onDoneAdding}
                      onRemoveRow={onRemoveRow}
                      setEditingChildIndex={setEditingChildIndex}
                      setTempChildInfo={setTempChildInfo}
                      childrenData={childrenData}
                      editingChildIndex={editingChildIndex}
                    />
                  ) : editingChildIndex === null ? (
                    <UserComponent
                      name={childData.first_name}
                      index={index}
                      type={UserRole.CHILD}
                      editChild={() => setEditingChildIndex(index)}
                    />
                  ) : (
                    <></>
                  )}
                </FlexBox>
              )
            })}
            {editingChildIndex === -1 && (
              <AddChildInformation
                name={tempChildInfo.first_name}
                birthday={
                  tempChildInfo.birthdate
                    ? dayjs.unix(tempChildInfo.birthdate)
                    : null
                }
                gender={tempChildInfo.gender}
                onDoneAdding={onDoneAdding}
                onRemoveRow={onRemoveRow}
                setEditingChildIndex={setEditingChildIndex}
                setTempChildInfo={setTempChildInfo}
                childrenData={childrenData}
                editingChildIndex={editingChildIndex}
              />
            )}
          </FlexBox>
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space3}>
          {editingChildIndex == null && (
            <>
              <Typography variant="bodySmall" textAlign="center">
                You can always add more children later!
              </Typography>
              {childrenData.length > 0 && (
                <Primary3DButton
                  fullWidth
                  onClick={() => {
                    setStep(CoachOnboardingStep.COACH_SIGN_UP)
                    trackAnalyticEvent(ANALYTIC_EVENTS.ADD_CHILDREN)
                    setParentData({
                      ...parentData,
                      childrenData: childrenData,
                    })
                  }}
                  text="Continue"
                />
              )}
              <SecondaryTransparentButton
                text="Add another child"
                onClick={() => {
                  setTimeout(
                    () => window.scrollTo(0, document.body.scrollHeight),
                    100
                  )
                  trackAnalyticEvent(ANALYTIC_EVENTS.INPUT_CHILD_INFO_INTENT)
                  setEditingChildIndex(-1)
                }}
                opaque
              />
            </>
          )}
          <Typography variant="bodyXSmall" textAlign="center">
            Your answers are never shared with anyone without your consent.
          </Typography>
        </FlexBox>
      </OnboardingPageContentWrapper>
    )
  } else if (childStep === ChildStep.QUESTIONNAIRE_DISCLAIMER) {
    return (
      <QuestionnaireDisclaimer
        childName={tempChildInfo?.first_name}
        onConfirm={() => setChildStep(ChildStep.QUESTIONNAIRE)}
      />
    )
  } else if (childStep === ChildStep.QUESTIONNAIRE) {
    return (
      <QuestionnaireSection
        questions={questions.slice(0, 4)}
        onConfirm={finishQuestionnaire}
        previousStep={() => setChildStep(ChildStep.CHILD_INFO)}
      />
    )
  } else return <div></div>
}

export default OnboardAddChildrenSection
