import {
  FamilyParentProfile,
  FlexBox,
  Modal,
  SPACING,
  Typography,
  ParentAvatar,
  Button,
  TextButton,
  JoonUIColor,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import ParentAvatarButton from "./ParentAvatarButton"
import { useSelectAvatarModalStore } from "./useSelectAvatarModalStore"
import useEditParentMutation from "../../settings/useEditParentMutation"

type Props = {
  user: FamilyParentProfile
}

const SelectAvatarModal = ({ user }: Props) => {
  const { isOpen, onClose, selectedAvatar, setSelectedAvatar } =
    useSelectAvatarModalStore()
  const editParentMutation = useEditParentMutation()

  const onSave = () => {
    if (selectedAvatar !== user?.user.avatar) {
      editParentMutation.mutate({
        parentInfo: { avatar: selectedAvatar },
        userId: user?.user.id,
      })
    }
    onClose()
  }

  useEffect(() => {
    isOpen && setSelectedAvatar(user?.user.avatar)
  }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!user) return <></>

  return (
    <Modal isOpen={isOpen} onClose={onClose} displayCloseIcon animate>
      <div className="quest-modal">
        <div
          className="quest-modal-content"
          style={{ width: "min(400px, 95vw)" }}
        >
          <FlexBox wrap={false} gap={SPACING.space4} direction="column">
            <FlexBox justify="center">
              <Typography variant="h2">Select Your Avatar</Typography>
            </FlexBox>
            <FlexBox direction="column">
              <Typography variant="body">
                This is what your children will see you as in Joon.
              </Typography>
            </FlexBox>
            <FlexBox
              justify="space-between"
              align="center"
              wrap={false}
              gap={SPACING.space6}
            >
              <FlexBox
                direction="row"
                gap={SPACING.space0}
                wrap={false}
                justify="space-between"
              >
                <ParentAvatarButton
                  avatar={ParentAvatar.FRUMPULOUS}
                  isSelected={selectedAvatar === ParentAvatar.FRUMPULOUS}
                  setSelectedParentAvatar={() => {
                    setSelectedAvatar(ParentAvatar.FRUMPULOUS)
                  }}
                />
                <ParentAvatarButton
                  avatar={ParentAvatar.JUNK_MUTT}
                  isSelected={selectedAvatar === ParentAvatar.JUNK_MUTT}
                  setSelectedParentAvatar={() => {
                    setSelectedAvatar(ParentAvatar.JUNK_MUTT)
                  }}
                />
                <ParentAvatarButton
                  avatar={ParentAvatar.SCRUFFIT}
                  isSelected={selectedAvatar === ParentAvatar.SCRUFFIT}
                  setSelectedParentAvatar={() => {
                    setSelectedAvatar(ParentAvatar.SCRUFFIT)
                  }}
                />
              </FlexBox>
            </FlexBox>

            {user?.user.avatar !== selectedAvatar && (
              <FlexBox
                wrap={false}
                gap={SPACING.space1}
                direction="column"
                align="right"
              >
                <Button onClick={onSave} text="Save" fullWidth />
                <TextButton onClick={onClose}>
                  <Typography
                    variant="bodyBold"
                    color={JoonUIColor.text.primaryAccent}
                  >
                    Cancel
                  </Typography>
                </TextButton>
              </FlexBox>
            )}
          </FlexBox>
        </div>
      </div>
    </Modal>
  )
}

export default SelectAvatarModal
