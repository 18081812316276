import { CloseCircleIcon } from "@joonapp/web-shared"
import { TextButton } from "@joonapp/web-shared"
import { JoonUIColor, Typography } from "@joonapp/web-shared"
import { DropdownOption, FlexBox, SPACING } from "@joonapp/web-shared"
import dayjs from "dayjs"

import { useFilterRewardLogsModalStore } from "../hooks/useFilterRewardLogsModalStore"

const FilterItem = ({
  startDate,
  endDate,
  user,
}: {
  startDate?: Date
  endDate?: Date
  user?: DropdownOption
}) => {
  const { setSelectedUser, setStartDate, setEndDate } =
    useFilterRewardLogsModalStore()

  const formatDate = (date: Date) => dayjs(date).format("MMM D")

  const onRemoveFilterClick = async () => {
    if (user?.value) setSelectedUser(null)
    if (startDate) setStartDate(null)
    if (endDate) setEndDate(null)
  }

  return (
    <FlexBox
      direction="row"
      align="center"
      gap={SPACING.space1}
      wrap={false}
      style={{
        background: JoonUIColor.background.lightAccent,
        width: "fit-content",
        padding: `${SPACING.space05} ${SPACING.space2}`,
        borderRadius: SPACING.space4,
      }}
    >
      {startDate && endDate ? (
        <Typography variant="bodySmall">
          {formatDate(startDate)} - {formatDate(endDate)}
        </Typography>
      ) : startDate ? (
        <Typography variant="bodySmall">{`${formatDate(startDate)} -`}</Typography>
      ) : endDate ? (
        <Typography variant="bodySmall">{`- ${formatDate(endDate)}`}</Typography>
      ) : user ? (
        <Typography variant="bodySmall">{user.label}</Typography>
      ) : null}
      <TextButton onClick={onRemoveFilterClick} style={{ padding: 0 }}>
        <CloseCircleIcon size={12} color={JoonUIColor.icon.neutral} />
      </TextButton>
    </FlexBox>
  )
}

export default FilterItem
