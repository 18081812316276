import { faLock, faEnvelope } from "@fortawesome/pro-light-svg-icons"
import {
  CalloutBox,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import AppleAuth from "./AppleAuth"
import { getAfterAuthPath } from "./ForgotPassword"
import GoogleAuth2 from "./GoogleAuth"
import { signin } from "../../networking/authentication"
import { AlertInterface } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"
import { createErrorFromSignupResponse } from "../../util/util"
import Primary3DButton from "../buttons/Primary3DButton"
import OnboardingPageContentWrapper from "../onboarding/onboardingPageContentWrapper/OnboardingPageContentWrapper"
import TextInputRect from "../textInputRect/TextInputRect"

const SignIn = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [formAlert, setFormAlert] = useState<AlertInterface | null>(null)
  const router = useRouter()
  const [pending, setPending] = useState(false)

  const handleSignIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      setPending(true)
      await signin(email, password)
      router.push(getAfterAuthPath(router))
    } catch (err) {
      const errorMessage = createErrorFromSignupResponse(err)
      if (errorMessage) setFormAlert({ type: "error", message: errorMessage })
      trackAnalyticEvent(ANALYTIC_EVENTS.SIGNUP_FAILED, { type: "email" })
    } finally {
      setPending(false)
    }
  }

  return (
    <OnboardingPageContentWrapper
      backgroundOnDesktop="linear-gradient(to bottom, rgba(245, 250, 255, 1), rgba(210, 230, 250, 1))"
      paddingTop="24px"
      style={{
        height: "100",
        justifyContent: "center",
        alignItems: "center",
        gap: SPACING.space6,
      }}
    >
      <FlexBox
        direction="column"
        align="center"
        justify="flex-end"
        gap={SPACING.space8}
        wrap={false}
        style={{
          flex: 1,
          paddingTop: SPACING.space7,
          maxHeight: "100%",
        }}
      >
        <FlexBox direction="column" align="center" gap={SPACING.space4}>
          <img
            src="/images/logos/logo_white.svg"
            alt="Joon"
            style={{
              width: "100px",
              userSelect: "none",
              pointerEvents: "none",
            }}
          />
          <FlexBox
            direction="column"
            align="center"
            justify="center"
            gap={SPACING.space2}
          >
            <Typography variant="h2Serif">Welcome back!</Typography>
          </FlexBox>
        </FlexBox>

        <form
          onSubmit={handleSignIn}
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            gap: SPACING.space8,
            width: "100%",
          }}
        >
          <FlexBox
            direction="column"
            wrap={false}
            gap={SPACING.space6}
            style={{
              padding: SPACING.space6,
              background: "rgba(255, 255, 255, 0.6)",
              borderRadius: SPACING.space2,
            }}
          >
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="body">Email</Typography>
              <TextInputRect
                type="email"
                icon={faEnvelope}
                name="email"
                placeholder="user@example.com"
                fullWidth={true}
                onChange={(e: any) => {
                  if (formAlert) setFormAlert(null)
                  setEmail(e.target.value)
                }}
                value={email}
              />
            </FlexBox>
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="body">Password</Typography>
              <TextInputRect
                icon={faLock}
                type="password"
                placeholder="Password"
                name="pass"
                fullWidth={true}
                onChange={(e: any) => {
                  if (formAlert) setFormAlert(null)
                  setPassword(e.target.value)
                }}
                value={password}
              />
              <button
                onClick={() => router.push("/auth/forgotpass")}
                style={{ marginTop: SPACING.space1 }}
                type="button"
              >
                <Typography
                  variant="bodyBold"
                  color={JoonUIColor.semantic.primary}
                >
                  Forgot Password?
                </Typography>
              </button>
            </FlexBox>
            {formAlert && (
              <CalloutBox type="error" fullWidth>
                <Typography variant="bodySmall">{formAlert.message}</Typography>
              </CalloutBox>
            )}
          </FlexBox>

          <Primary3DButton isLoading={pending} text="Sign in" type="submit" />
        </form>
      </FlexBox>

      <FlexBox direction="column" gap={SPACING.space4}>
        <FlexBox gap={SPACING.space2} direction="column">
          <GoogleAuth2 type="signin" setFormAlert={setFormAlert} />
          <AppleAuth type="signin" setFormAlert={setFormAlert} />
        </FlexBox>
        <Typography
          variant="body"
          color={JoonUIColor.text.secondary}
          textAlign="center"
        >
          Don't have an account?{" "}
          <button onClick={() => router.push("/welcome")}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Create one here
            </Typography>
          </button>
        </Typography>
      </FlexBox>
    </OnboardingPageContentWrapper>
  )
}

export default SignIn
