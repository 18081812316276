import {
  Checkbox,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import { useDraftQuestDetailsModalStore } from "./DraftQuestDetailsModal"
import useAIQuestStore from "./hooks/useAIQuestStore"
import { useFamilyQuery } from "../../../networking/queries"
import { QuestAICreateInfo, QuestAIEditInfo } from "../../../types/quests"
import { getDayRepetitionString } from "../../../util/util"

const DraftQuestCard = ({
  quest,
}: {
  quest: QuestAICreateInfo | QuestAIEditInfo
}) => {
  const { selectedQuestUUIDs, toggleQuestUUID } = useAIQuestStore()
  const { onOpen } = useDraftQuestDetailsModalStore()

  const isCreatingQuest = (
    quest: QuestAICreateInfo | QuestAIEditInfo
  ): quest is QuestAICreateInfo => {
    return "user_ids" in quest.pending_data
  }

  const assignedUserIds = isCreatingQuest(quest)
    ? quest.pending_data.user_ids
    : [quest.pending_data.user_id]

  const { data: family } = useFamilyQuery()
  const assignedUsers = family?.profiles
    ?.filter((profile) => assignedUserIds.includes(profile.user.id))
    .map((profile) => profile.user.name)

  const isSelected = selectedQuestUUIDs.includes(quest.pending_data_uuid)

  return (
    <FlexBox direction="row" wrap={false}>
      <button style={{ width: "100%" }} onClick={() => onOpen(quest)}>
        <FlexBox
          direction="column"
          style={{
            padding: SPACING.space2,
            border: `1px dashed ${isSelected ? JoonUIColor.border.accent : JoonUIColor.border.default}`,
            borderRadius: SPACING.space2,
            background: "rgba(249, 250, 252, 0.40)",
          }}
        >
          <Typography variant="bodyBold" textAlign="left">
            {quest.pending_data.title}
          </Typography>
          <Typography variant="bodySmall" color={JoonUIColor.text.secondary}>
            {assignedUsers && assignedUsers.length === 2
              ? assignedUsers.join(" and ")
              : assignedUsers?.join(", ")}
          </Typography>
          <Typography variant="bodySmall" color={JoonUIColor.text.secondary}>
            {getDayRepetitionString(
              quest.pending_data.recurrence_byday,
              quest.pending_data.reminder_time
            )}
          </Typography>
        </FlexBox>
      </button>
      <button
        style={{ height: "100%", padding: SPACING.space2 }}
        onClick={() => toggleQuestUUID(quest.pending_data_uuid)}
      >
        <Checkbox
          name={quest.pending_data.title}
          hideBorder
          selected={isSelected}
          onChange={() => {}}
        />
      </button>
    </FlexBox>
  )
}

export default DraftQuestCard
