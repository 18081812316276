import { FlexBox } from "@joonapp/web-shared"
import { useEffect, useRef } from "react"
import { useShallow } from "zustand/react/shallow"

import AddAIQuestButton from "./AddAIQuestButton"
import AddQuestButton from "./AddQuestButton"
import AssignedQuests from "./AssignedQuests"
import { ParentQuestFTUXModal } from "./parentQuestFTUXModal/ParentQuestFTUXModal"
import { useParentQuestFTUXModalStore } from "./parentQuestFTUXModal/useParentQuestFTUXModalStore"
import QuestAISidePanel from "./questAI/QuestAISidePanel"
import QuestBoardMultiSelect from "./QuestBoardMultiSelect"
import QuestBoardUserSelectHeader from "./QuestBoardUserSelectHeader"
import useQuestBoardMultiSelectStore from "./useQuestBoardMultiSelectStore"
import useQuestBoardStore from "./useQuestBoardStore"
import WelcomeQuests from "./WelcomeQuests"
import PageContentWrapper from "../../components/pageContentWrapper/PageContentWrapper"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { GRADIENTS } from "../../constants"
import useDocumentTitle from "../../hooks/useDocumentTitle"
import useMobile from "../../hooks/useMobile"
import { useFamilyQuery } from "../../networking/queries"
import { FamilyQuerySelect } from "../../types"
import { requireAuth } from "../../util/auth"
import { localStorageItems } from "../../util/storage"
const QuestBoard = () => {
  useDocumentTitle("Joon - Quests")
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const { showWelcome, setShowWelcome, setQuestBoardRef } = useQuestBoardStore()
  const { onOpen: onOpenParentQuestFTUXModal } = useParentQuestFTUXModalStore()
  const { isMultiSelectMode } = useQuestBoardMultiSelectStore(
    useShallow((state) => ({
      isMultiSelectMode: state.isMultiSelectMode,
    }))
  )
  const isMobile = useMobile()
  // Setting a reference for the top of the quest board
  // so I can scroll to it after quests added
  const qbRef = useRef<HTMLDivElement>(null)
  useEffect(() => setQuestBoardRef(qbRef), [setQuestBoardRef])

  useEffect(() => {
    setShowWelcome(
      !!localStorage.getItem(localStorageItems.needsToSeeWelcomeQuests)
    )
  }, [setShowWelcome])

  useEffect(() => {
    // have at least one onboarded child
    if (
      children &&
      children.some((child) => child?.user.active_doter.date_hatched !== null)
    )
      onOpenParentQuestFTUXModal()
  }, [onOpenParentQuestFTUXModal, children])

  if (showWelcome) return <WelcomeQuests />

  return (
    <FlexBox wrap={false}>
      <PageWrapper ref={qbRef} style={{ background: GRADIENTS.gradient100 }}>
        <PageContentWrapper mobileGutter>
          {!isMultiSelectMode && (
            <QuestBoardUserSelectHeader
              style={{
                width: isMobile ? "100vw" : "100%",
                transform: isMobile ? "translateX(-12px)" : "none",
              }}
            />
          )}
          <AssignedQuests />
          <QuestBoardMultiSelect />
        </PageContentWrapper>
        <AddAIQuestButton />
        <AddQuestButton />
      </PageWrapper>
      <QuestAISidePanel />
      <ParentQuestFTUXModal />
    </FlexBox>
  )
}

const QuestBoardWithAuth = requireAuth(QuestBoard)
export default QuestBoardWithAuth
