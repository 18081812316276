import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import TagManager from "react-gtm-module"

import Devtools from "./components/devtools/Devtools"
import SBProvider from "./components/sendbird/SendbirdProvider"
import Routing from "./routing"
import { queryClient } from "./util/queryClient"
import { Router } from "./util/router"

import "@joonapp/web-shared/dist/style.css"
import "./styles/tailwind.css"

TagManager.initialize({ gtmId: "GTM-W29NM4S" })

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <SBProvider>
          <Routing />
          <Devtools />
        </SBProvider>
      </Router>
      {import.meta.env.MODE === "development" && <ReactQueryDevtools />}
    </QueryClientProvider>
  )
}

export default App
