import {
  faCheck,
  faFastForward,
  faThumbsDown,
  faTrashCanXmark,
} from "@fortawesome/duotone-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  FlexBox,
  InfoSolidIcon,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  TextButton,
  Typography,
  UserRole,
} from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import { useState } from "react"
import toast from "react-hot-toast"
import { create } from "zustand"

import { useQBDatePickerStore } from "./QuestBoardDatePicker"
import useQuestBoardMultiSelect from "./useQuestBoardMultiSelect"
import useQuestBoardMultiSelectStore from "./useQuestBoardMultiSelectStore"
import { QUERY_KEYS } from "../../constants"
import useMobile from "../../hooks/useMobile"
import { useQuestBoardQuery } from "../../networking/queries"
import { deleteQuest } from "../../networking/quests"
import { localStorageItems } from "../../util/storage"
import {
  CoachUpsellModalType,
  useCoachUpsellModalStore,
} from "../care/CoachUpsellModal"
import useCareTeam from "../care/useCareTeam"

const QuestBoardMultiSelect = () => {
  const {
    selectedQuestIds,
    isMultiSelectMode,
    setMultiSelectMode,
    clearSelectedQuestIds,
  } = useQuestBoardMultiSelectStore()
  const { data: quests } = useQuestBoardQuery()
  const { onClickCompleteQuests, onClickRejectQuests, onClickSkipQuests } =
    useQuestBoardMultiSelect()
  const { onOpen: openDeleteQuestModal } = useDeleteQuestModalStore()
  const { questBoardDate } = useQBDatePickerStore()

  const isMobile = useMobile()
  const isInFuture = dayjs().isBefore(questBoardDate, "day")

  const hasTherapistModifiedQuest = selectedQuestIds.some((questId) => {
    const quest = quests?.find((quest) => quest.id === questId)
    const isTherapistCreated =
      quest?.series.assigner_profile?.role === UserRole.THERAPIST
    const isTherapistModified = !!quest?.series.current_frequency_target
    return isTherapistCreated || isTherapistModified
  })

  const isDeleteDisabled = hasTherapistModifiedQuest
  const isCompleteDisabled = isInFuture
  const isRejectDisabled = isInFuture

  if (!isMultiSelectMode) return <></>

  return (
    <>
      <FlexBox
        direction="column"
        align="center"
        justify="center"
        gap={SPACING.space2}
        style={{
          position: "fixed",
          background: JoonUIColor.background.primaryNeutral,
          boxShadow: `0px 0px 10px 0px rgba(0, 0, 0, 0.1)`,
          borderTopLeftRadius: SPACING.space4,
          borderTopRightRadius: SPACING.space4,
          bottom: 0,
          left: isMobile ? 0 : "unset",
          padding: `${isDeleteDisabled ? SPACING.space2 : SPACING.space1} ${SPACING.space3}`,
          width: isMobile ? "100vw" : "600px",
          zIndex: 900,
        }}
      >
        {isDeleteDisabled && (
          <FlexBox
            direction="row"
            align="center"
            wrap={false}
            gap={SPACING.space1}
            style={{
              padding: SPACING.space2,
              background: JoonUIColor.background.lightBlue,
              borderRadius: SPACING.space2,
            }}
          >
            <InfoSolidIcon size={18} color={JoonUIColor.semantic.info} />
            <Typography
              variant="bodySmallBold"
              color={JoonUIColor.semantic.info}
            >
              Therapist added or modified Quests can only be deleted by them.
            </Typography>
          </FlexBox>
        )}

        <FlexBox
          direction="row"
          align="center"
          justify="space-between"
          gap={SPACING.space2}
          wrap={false}
          style={{
            borderBottom: `1px solid ${JoonUIColor.border.default}`,
            padding: SPACING.space05,
          }}
        >
          <Typography variant="bodySmall">
            For the {selectedQuestIds.length} quest
            {selectedQuestIds.length > 1 ? "s" : ""} selected...
          </Typography>
          <TextButton
            onClick={() => {
              setMultiSelectMode(false)
              clearSelectedQuestIds()
            }}
          >
            <Typography
              variant="bodyXSmall"
              color={JoonUIColor.text.primaryAccent}
            >
              Unselect all
            </Typography>
          </TextButton>
        </FlexBox>
        <FlexBox
          direction="row"
          align="center"
          justify="space-around"
          gap={SPACING.space2}
          wrap={false}
          style={{ padding: `${SPACING.space2} 0` }}
        >
          <button
            style={{
              padding: "0",
              cursor: isDeleteDisabled ? "not-allowed" : "pointer",
            }}
            onClick={() => {
              !isDeleteDisabled && openDeleteQuestModal()
            }}
          >
            <FlexBox
              align="center"
              justify="center"
              direction="column"
              gap={SPACING.space1}
              wrap={false}
            >
              <FontAwesomeIcon
                icon={faTrashCanXmark}
                size="xl"
                color={
                  isDeleteDisabled
                    ? JoonUIColor.icon.light
                    : JoonUIColor.icon.neutral
                }
              />
              <Typography
                variant="bodySmallBold"
                color={
                  isDeleteDisabled
                    ? JoonUIColor.icon.light
                    : JoonUIColor.icon.neutral
                }
              >
                Delete
              </Typography>
            </FlexBox>
          </button>
          <button
            style={{
              padding: "0",
              cursor: isRejectDisabled ? "not-allowed" : "pointer",
            }}
            onClick={() => !isRejectDisabled && onClickRejectQuests()}
          >
            <FlexBox
              align="center"
              justify="center"
              direction="column"
              gap={SPACING.space1}
              wrap={false}
            >
              <FontAwesomeIcon
                icon={faThumbsDown}
                size="xl"
                color={
                  isRejectDisabled
                    ? JoonUIColor.icon.light
                    : JoonUIColor.icon.neutral
                }
              />
              <Typography
                variant="bodySmallBold"
                color={
                  isRejectDisabled
                    ? JoonUIColor.icon.light
                    : JoonUIColor.icon.neutral
                }
              >
                Reject
              </Typography>
            </FlexBox>
          </button>
          <button style={{ padding: "0" }} onClick={onClickSkipQuests}>
            <FlexBox
              align="center"
              justify="center"
              direction="column"
              gap={SPACING.space1}
              wrap={false}
            >
              <FontAwesomeIcon
                icon={faFastForward}
                size="xl"
                color={JoonUIColor.icon.neutral}
              />
              <Typography
                variant="bodySmallBold"
                color={JoonUIColor.icon.neutral}
              >
                Skip
              </Typography>
            </FlexBox>
          </button>
          <button
            style={{ padding: "0" }}
            onClick={() => !isCompleteDisabled && onClickCompleteQuests()}
          >
            <FlexBox
              align="center"
              justify="center"
              direction="column"
              gap={SPACING.space1}
              wrap={false}
            >
              <FontAwesomeIcon
                icon={faCheck}
                size="xl"
                color={
                  isCompleteDisabled
                    ? JoonUIColor.icon.light
                    : JoonUIColor.semantic.success
                }
              />
              <Typography
                variant="bodySmallBold"
                color={
                  isCompleteDisabled
                    ? JoonUIColor.icon.light
                    : JoonUIColor.icon.neutral
                }
              >
                Complete
              </Typography>
            </FlexBox>
          </button>
        </FlexBox>
      </FlexBox>
      <DeleteQuestModal />
    </>
  )
}

interface DeleteQuestModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

const useDeleteQuestModalStore = create<DeleteQuestModalStore>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}))

const DeleteQuestModal = () => {
  const { onOpen: openCoachUpsellModal } = useCoachUpsellModalStore()
  const { isOpen, onClose } = useDeleteQuestModalStore()
  const { data } = useQuestBoardQuery()
  const { selectedQuestIds, setMultiSelectMode } =
    useQuestBoardMultiSelectStore()

  const { hasCoach } = useCareTeam()

  const [isDeleting, setIsDeleting] = useState(false)

  const queryClient = useQueryClient()

  // Writing out a function instead of a mutation
  // Do we want some sort of optimistic update / UI change for this too?
  const onClickDeleteQuests = async () => {
    try {
      if (!data) throw new Error("No data")

      // get list of quest series ids from the selected (instance) ids
      const questSeriesIds = selectedQuestIds.map(
        (questId) => data.find((quest) => quest.id === questId)?.series.id
      )

      const deleteQuestPromises = questSeriesIds.map(
        (questId) => questId && deleteQuest(questId)
      )
      setIsDeleting(true)
      await Promise.all(deleteQuestPromises)
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER])
      setMultiSelectMode(false)
      onClose()
      toast.success(
        `${selectedQuestIds.length} Quest${
          selectedQuestIds.length > 1 ? "s" : ""
        } deleted`
      )
      if (hasCoach) return
      const lastSeenUpsellModalDate = localStorage.getItem(
        localStorageItems.deleteCoachUpsellModalSeenDate
      )
      const needsToSeeDeleteCoachUpsellModal =
        !dayjs().isSame(dayjs(lastSeenUpsellModalDate), "week") ||
        !lastSeenUpsellModalDate
      if (needsToSeeDeleteCoachUpsellModal)
        openCoachUpsellModal(CoachUpsellModalType.delete)
    } catch (error) {
      toast.error(
        `Failed to delete quest${selectedQuestIds.length > 1 ? "s" : ""}`
      )
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} animate>
      <ModalHeader title="Delete Quest" onClose={onClose} showBorderBottom />
      <FlexBox
        direction="column"
        align="center"
        justify="center"
        gap={SPACING.space2}
        style={{
          padding: SPACING.space6,
          paddingTop: SPACING.space4,
        }}
      >
        <Typography
          variant="body"
          color={JoonUIColor.text.primary}
          textAlign="left"
        >
          Are you sure you want to delete the selected Quest
          {selectedQuestIds.length > 1 ? "s" : ""}?
        </Typography>
        <Typography variant="body" textAlign="left">
          This action cannot be undone.
        </Typography>
        <FlexBox
          direction="row"
          align="center"
          justify="flex-end"
          gap={SPACING.space4}
          wrap={false}
          style={{ padding: `${SPACING.space2} 0`, marginTop: SPACING.space2 }}
        >
          <TextButton onClick={onClose}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </TextButton>
          <Button
            text="Delete Quests"
            onClick={onClickDeleteQuests}
            buttonType="redPrimary"
            isLoading={isDeleting}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default QuestBoardMultiSelect
