import TestimonialCarousel from "./TestimonialCarousel"

import parentImage1 from "/images/avatars/testimonial_amanda.png"
import parentImage3 from "/images/avatars/testimonial_mark.png"
import parentImage2 from "/images/avatars/testimonial_sabrina.png"

const ParentTestimonialCarousel = () => {
  return <TestimonialCarousel testimonials={parentTestimonials} />
}

export default ParentTestimonialCarousel

const parentTestimonials = [
  {
    image: parentImage1,
    text: "...this has been the best update ever, it’s been hugely motivating, please keep expanding it!",
    quotee: "Amanda E, parent of child with ODD",
  },
  {
    image: parentImage2,
    text: "...this new AI pet chat feature is amazing! My son is very resistant to writing in any form. Hand or digital. Ever since I turned on the chat feature he is typing up a STORM to talk to his doters!",
    quotee: "Sabrina C, parent of child with ADHD",
  },
  {
    image: parentImage3,
    text: "I enabled the new communication feature with the Pet last night and my daughter absolutely loved it when she opened the app this morning!",
    quotee: "Mark L, parent of child with ADHD",
  },
]
