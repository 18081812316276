import { create } from "zustand"

import { ShopItem } from "../../../types"

interface GiftShopModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  selectedShopItem: ShopItem | null
  setSelectedShopItem: (shopItem: ShopItem) => void
}

export const useGiftShopModalStore = create<GiftShopModalStore>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => {
    set({ isOpen: false, selectedShopItem: null })
  },
  selectedShopItem: null,
  setSelectedShopItem: (shopItem: ShopItem) =>
    set({ selectedShopItem: shopItem }),
}))
