import {
  Button,
  FlexBox,
  Modal,
  ModalHeader,
  ParentAvatar,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import { isVercelDeployment } from "../../constants"
import useOnboardContext, {
  OnboardingStep,
} from "../../hooks/useOnboardContext"
import { useFamilyQuery, useUserQuery } from "../../networking/queries"
import {
  useExperimentsQuery,
  cohorts,
  onboardExperiments,
} from "../../util/experiments"
import { useRouter } from "../../util/router"

const Devtools = () => {
  const { data: family } = useFamilyQuery()
  const { user } = useUserQuery()
  const [showModal, setShowModal] = useState(false)
  const { data: dbExperiments } = useExperimentsQuery()
  const router = useRouter()
  const allExperiments = [
    ...onboardExperiments.map((exp) => exp.name),
    ...(dbExperiments ? Object.keys(dbExperiments) : []),
  ]

  const handleCohortChange = (experiment: string, cohort: string | null) => {
    if (cohort === null) {
      localStorage.removeItem(experiment)
    } else {
      localStorage.setItem(experiment, cohort)
    }
    // Force a page reload to ensure all experiment-dependent code updates
    router.push(router.location.pathname + router.location.search, {
      replace: true,
    })
  }

  const handleSkipOnboarding = () => {
    router.push("/onboard")
    useOnboardContext.setState({
      ...useOnboardContext.getState(),
      ...dummyOnboardingData,
    } as any)
    setShowModal(false)
  }

  if (
    !user?.enable_developer_mode &&
    import.meta.env.PROD &&
    !isVercelDeployment
  )
    return null

  return (
    <div
      style={{
        position: "absolute",
        left: "10px",
        bottom: "10px",
        zIndex: 999,
      }}
    >
      <button
        onClick={() => setShowModal(true)}
        style={{
          border: "1px solid black",
          padding: `${SPACING.space1} ${SPACING.space2}`,
          borderRadius: "10px",
          background: "white",
        }}
      >
        <Typography variant="bodySmallBold">Devtools</Typography>
      </button>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        animate
        style={{ maxWidth: "700px" }}
      >
        <ModalHeader
          title="Devtools"
          onClose={() => setShowModal(false)}
          showBorderBottom
        />
        <FlexBox
          direction="column"
          style={{ padding: SPACING.space6, paddingTop: SPACING.space4 }}
          gap={SPACING.space4}
        >
          <div>
            <Typography variant="bodyBold">Family ID: </Typography>
            <Typography variant="body">{family?.id}</Typography>
          </div>
          <div>
            <Typography variant="bodyBold">User ID: </Typography>
            <Typography variant="body">{user?.id}</Typography>
          </div>
          <Typography variant="bodyBold">Experiment Overrides</Typography>
          {allExperiments.map((experiment) => (
            <FlexBox key={experiment} gap={SPACING.space4} align="center">
              <Typography variant="body">{experiment}</Typography>
              <select
                value={localStorage.getItem(experiment) || ""}
                onChange={(e) =>
                  handleCohortChange(experiment, e.target.value || null)
                }
                style={{ minWidth: "150px" }}
              >
                <option value="">Default</option>
                {Object.values(cohorts).map((cohort) => (
                  <option key={cohort} value={cohort}>
                    {cohort}
                  </option>
                ))}
              </select>
            </FlexBox>
          ))}
          <Button
            size="small"
            text="Skip onboarding"
            onClick={handleSkipOnboarding}
          />
        </FlexBox>
      </Modal>
    </div>
  )
}

export default Devtools

export const dummyOnboardingData = {
  isCoparent: false,
  coparentCode: "",
  parentData: {
    name: "Test",
    lastName: "User",
    nickname: "MAMADADA",
    avatar: ParentAvatar.FRUMPULOUS,
    parentQuestionnaireMapping: {
      "1": [4],
      "2": [10],
      "68": [138],
      "431": [600],
      "432": [605],
      "433": [608],
      "436": [621],
    },
    childrenData: [
      {
        first_name: "sdfasdf",
        birthdate: null,
        gender: "male",
        questionAnswerMapping: {
          "4": [20],
          "5": [23],
          "6": [29],
          "167": [633],
          "398": [534],
          "399": [537],
          "403": [554],
          "408": [575],
          "410": [582],
          "411": [587],
          "629": [964],
          "630": [970, 969],
          "631": [977, 979, 976],
        },
      },
    ],
  },
  creatingPlan: false,
  isCreatingUser: false,
  currentStep: OnboardingStep.EmailPassword,
  childStep: 0,
}
