import {
  ChevronRightIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"
import { isAndroid, isChrome, isMobile } from "react-device-detect"

import { CoachSummarizerModal } from "./recommendedCoaches/CoachSummarizerModal"
import { RecommendedCoachList } from "./recommendedCoaches/RecommendedCoachList"
import RecommendedCoachVideoModal from "./recommendedCoaches/RecommendedCoachVideoModal"
import PageLoader from "../../../components/loading/PageLoader"
import { useAddToHomescreenModal } from "../../../components/modals/AddToHomescreenModal"
import { useIOSModal } from "../../../components/modals/IOSModal"
import { useWelcomeModalStore } from "../../../components/modals/WelcomeModal"
import { CoachPaywallChoice } from "../../../constants"
import { useRecommendedCoachesQuery } from "../../../networking/coaching/queries"
import { useCoachPostSelectionQuestionnaireQuery } from "../../../networking/queries"
import { CoachingStep } from "../../../types/coachingGroups"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { cohorts, experimentNames } from "../../../util/experiments"
import { useGetExperimentCohort } from "../../../util/experiments"
import { useRouter } from "../../../util/router"
import { localStorageItems } from "../../../util/storage"
import { useCoachingStore } from "../useCoachingStore"
import useIsFromMOF from "../useIsFromMOF"

function RecommendedCoaches() {
  const router = useRouter()
  const { selectedCoach, setSelectedCoach, setStep } = useCoachingStore()
  const isOnMobileChrome = isChrome && isMobile && isAndroid
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()
  const { onOpen: openIOSModal } = useIOSModal()
  const { isLoading: isLoadingQuestionnaire } =
    useCoachPostSelectionQuestionnaireQuery()
  const { data: recommendedCoaches, isLoading: isLoadingCoaches } =
    useRecommendedCoachesQuery()
  const isFromMOF = useIsFromMOF()
  const doterAICohort = useGetExperimentCohort(experimentNames.doterChatFTUX)
  const { onOpen: openWelcomeModal } = useWelcomeModalStore()

  const onSkipClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_SKIP_CLICK, {
      step: CoachingStep.RECOMMENDED_COACHES,
      is_tof: !isFromMOF,
    })
    if (
      localStorage.getItem(localStorageItems.planChoiceFromPaywall) ===
        CoachPaywallChoice.WITH_COACH ||
      localStorage.getItem(localStorageItems.planChoiceFromPaywall) ===
        CoachPaywallChoice.EXIT_WITH_COACH
    ) {
      router.push("/paywall/trial")
    } else {
      localStorage.removeItem(localStorageItems.planChoiceFromPaywall)
      router.push("/me")
      if (isFromMOF) return
      if (doterAICohort === cohorts.variantA) {
        openWelcomeModal()
      } else {
        if (isOnMobileChrome) openAddToHomescreenModal()
        else openIOSModal()
      }
    }
  }

  const onSelectCoachClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_COACH_SELECT, {
      coach_id: selectedCoach?.user.id,
      is_tof: !isFromMOF,
    })
    setStep(CoachingStep.PLAN_SELECT)
  }

  useEffect(() => {
    if (recommendedCoaches?.[0] && !selectedCoach) {
      setSelectedCoach(recommendedCoaches[0])
    }
  }, [recommendedCoaches, setSelectedCoach, selectedCoach])

  if (isLoadingQuestionnaire || isLoadingCoaches) return <PageLoader />

  if (recommendedCoaches) {
    trackAnalyticEvent(ANALYTIC_EVENTS.SHOW_RECOMMENDED_COACHES, {
      coach_ids: recommendedCoaches.map((coach) => coach.id),
    })
  }

  const isFromCoachOnboarding = localStorage.getItem(
    localStorageItems.isFromCoachOnboarding
  )

  return (
    <FlexBox
      direction="column"
      align="center"
      justify="center"
      fullWidth
      gap={SPACING.space6}
      wrap={false}
      style={{ marginBottom: 0 }}
    >
      <Typography variant="h2" color={JoonUIColor.text.primary}>
        Your recommended coaches
      </Typography>
      <Typography variant="bodySmall" color={JoonUIColor.text.primary}>
        Select a coach to get started, and you can change it at anytime.
      </Typography>

      {recommendedCoaches && (
        <FlexBox
          direction="column"
          align="center"
          justify="center"
          fullWidth
          wrap={false}
          gap={SPACING.space2}
          style={{ position: "relative" }}
        >
          <RecommendedCoachList recommendedCoaches={recommendedCoaches} />

          <Typography
            variant="bodySmall"
            color={JoonUIColor.text.primary}
            style={{
              marginTop: SPACING.space8,
              marginBottom: SPACING.space2,
            }}
          >
            You can change your coach at any time.
          </Typography>

          <button
            onClick={onSelectCoachClick}
            style={{
              width: "98%",
              position: "sticky",
              bottom: SPACING.space4,
              boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
              background: JoonUIColor.background.accent,
              borderRadius: SPACING.space2,
              overflow: "hidden",
              zIndex: 100,
            }}
          >
            <FlexBox
              fullWidth
              justify="space-between"
              style={{ padding: SPACING.space4 }}
            >
              <Typography variant="bodyBold" color={JoonUIColor.text.inverted}>
                Continue with {selectedCoach?.user.name || "this coach"}
              </Typography>
              <ChevronRightIcon size={24} color={JoonUIColor.text.inverted} />
            </FlexBox>
          </button>
          {isFromCoachOnboarding !== "true" && (
            <TextButton onClick={onSkipClick}>
              <Typography
                variant="bodySmallBold"
                color={JoonUIColor.text.primaryAccent}
              >
                No thanks, I don't want a coach
              </Typography>
            </TextButton>
          )}

          <RecommendedCoachVideoModal />
          <CoachSummarizerModal />
        </FlexBox>
      )}
    </FlexBox>
  )
}

export default RecommendedCoaches
