import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { useState } from "react"

import Primary3DButton from "../../components/buttons/Primary3DButton"
import SecondaryTransparentButton from "../../components/buttons/SecondaryTransparentButton"
import OnboardingPageContentWrapper from "../../components/onboarding/onboardingPageContentWrapper/OnboardingPageContentWrapper"
import OnboardingPageWrapper from "../../components/onboarding/onboardingPageWrapper/OnboardingPageWrapper"
import useMediaQuery from "../../hooks/useMediaQuery"
import useMobile from "../../hooks/useMobile"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"
import { ImagePreloader } from "../onboard"
import FallingLeaves from "../welcome/FallingLeaves"

const WelcomeContent = () => {
  const [isLoading, setIsLoading] = useState(false)
  const router = useRouter()

  const isMobile = useMobile()
  const isSmallMobile = useMediaQuery("(max-width: 500px)")

  return (
    <OnboardingPageWrapper
      style={{
        backgroundImage: isSmallMobile
          ? `url("/images/background/coach-welcome-bg-mobile.svg")`
          : isMobile
            ? `url("/images/background/coach-welcome-bg.svg")`
            : "none",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {!isMobile && <FallingLeaves />}
      <OnboardingPageContentWrapper
        backgroundImageOnDesktop={
          isSmallMobile
            ? "none"
            : `url("/images/background/coach-welcome-bg.svg")`
        }
      >
        {isMobile && <FallingLeaves snowflakeCount={20} />}
        <FlexBox direction="column" align="center" gap={SPACING.space1}>
          <img
            src="/images/logos/logo_white.svg"
            alt="Joon"
            style={{
              width: "130px",
              zIndex: 1,
              marginBottom: SPACING.space3,
              userSelect: "none",
              pointerEvents: "none",
              opacity: isLoading ? 0 : 1,
              transition: "opacity 0.1s ease-in-out",
            }}
            onLoad={() => setIsLoading(false)}
          />
          <Typography
            variant="h2Serif"
            color={JoonUIColor.text.primary}
            textAlign="center"
          >
            Personalized Parent Coaching
          </Typography>
          <Typography
            variant="body"
            color={JoonUIColor.text.primary}
            textAlign="center"
          >
            The intersection of calmer days and a happier home.
          </Typography>
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space3} style={{ zIndex: 1 }}>
          <Primary3DButton
            text="Get started"
            onClick={() => {
              trackAnalyticEvent(ANALYTIC_EVENTS.AUTH_NO_ACCOUNT)
              router.push("/coach-onboarding")
            }}
          />
          <SecondaryTransparentButton
            text="Sign in to an existing account"
            onClick={() => {
              trackAnalyticEvent(ANALYTIC_EVENTS.AUTH_HAS_ACCOUNT)
              router.push("/auth/signin")
            }}
          />
          <Typography
            variant="bodyXSmallBold"
            textAlign="center"
            style={{ marginTop: SPACING.space2 }}
          >
            By signing up, you agree to our{" "}
            <a
              href="https://www.joonapp.io/terms-of-service"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              href="https://www.joonapp.io/privacy-policy"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              Privacy Policy
            </a>
          </Typography>
        </FlexBox>

        <ImagePreloader />
      </OnboardingPageContentWrapper>
    </OnboardingPageWrapper>
  )
}

export default WelcomeContent
