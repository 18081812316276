import { useEffect } from "react"

import { useSubscriberInfo } from "../networking/queries"
import { useFamilyQuery } from "../networking/queries"
import { useUserQuery } from "../networking/queries"
import { history } from "../util/router"
import { localStorageItems } from "../util/storage"

export const usePaywall = () => {
  const { user } = useUserQuery()
  const { data: family } = useFamilyQuery()
  const { data: subscriber } = useSubscriberInfo()
  const needsToSeePaywall = localStorage.getItem(
    localStorageItems.needsToSeePaywall
  )
  const redirectLink = localStorage.getItem(localStorageItems.redirectLink)
  const isFromCoachOnboarding = localStorage.getItem(
    localStorageItems.isFromCoachOnboarding
  )

  useEffect(() => {
    if (!user || !family) return

    // Skip paywall for coaching-only flow - explicit check for "true"
    if (isFromCoachOnboarding === "true") {
      localStorage.removeItem(localStorageItems.needsToSeePaywall)
      return
    }

    // eventually we want to instead have everyone sent to the paywall
    // and the paywall is dynamic with the redirect link params
    // For now, if they have redirect, they bypass paywall
    if (!!redirectLink) {
      history.push(redirectLink)
      localStorage.removeItem(localStorageItems.redirectLink)
      localStorage.removeItem(localStorageItems.needsToSeePaywall)
    } else if (!!needsToSeePaywall) {
      // If a user is not a coparent, or if they are a coparent and not subscribed, show them paywall
      const userIsCoparent = localStorage.getItem(localStorageItems.isCoparent)
      const familyIsSubscribed = subscriber?.active_plan?.is_active

      if (!userIsCoparent || !familyIsSubscribed) {
        history.push("/paywall/trial")
        localStorage.removeItem(localStorageItems.needsToSeePaywall)
        localStorage.removeItem(localStorageItems.isCoparent)
      }
    }
  }, [
    user,
    family,
    needsToSeePaywall,
    redirectLink,
    subscriber,
    isFromCoachOnboarding,
  ])
}
