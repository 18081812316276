import TimePicker from "react-time-picker"
import "react-time-picker/dist/TimePicker.css"
import "react-clock/dist/Clock.css"
import "./timeInput.scss"

const TimeInputNew = ({
  value,
  setValue,
}: {
  value: string
  setValue: (value: string) => void
}) => {
  const handleChange = (newValue: any) => {
    setValue(newValue)
  }

  return (
    <TimePicker
      onChange={handleChange}
      value={value}
      format="h:mm a"
      amPmAriaLabel="Select AM/PM"
      clearIcon={null}
      disableClock={true}
      minutePlaceholder="MM"
      hourPlaceholder="HH"
      className="jooniverse__time-input"
    />
  )
}

export default TimeInputNew
