import { JoonUIColor } from "@joonapp/web-shared"
import { SPACING } from "@joonapp/web-shared"
import { useState } from "react"

export const SmartRedemptionButton = ({
  children,
  onClick,
  style,
}: {
  children: React.ReactNode
  onClick: () => void
  style?: React.CSSProperties
}) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <button
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: SPACING.space2,
        padding: SPACING.space3,
        background: isHovered
          ? JoonUIColor.background.xlightGray
          : JoonUIColor.background.primaryNeutral,
        borderRadius: SPACING.space2,
        overflow: "hidden",
        border: `1px solid ${JoonUIColor.border.default}`,
        transition: "background 0.2s ease-in-out",
        ...style,
      }}
    >
      {children}
    </button>
  )
}
