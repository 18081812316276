import { CustomReward } from "@joonapp/web-shared"
import { create } from "zustand"

interface PurchaseRewardModalStore {
  isOpen: boolean
  onOpen: (reward: CustomReward) => void
  onClose: () => void

  reward: CustomReward | null
  selectedChildId: number | null
  setSelectedChildId: (selectedChildId: number | null) => void
  quantity: number
  setQuantity: (quantity: number) => void
}

const usePurchaseRewardModalStore = create<PurchaseRewardModalStore>((set) => ({
  isOpen: false,
  onOpen: (reward) => set({ isOpen: true, reward }),
  onClose: () =>
    set({
      isOpen: false,
      reward: null,
      selectedChildId: null,
      quantity: 1,
    }),

  reward: null,
  selectedChildId: null,
  setSelectedChildId: (selectedChildId) => set({ selectedChildId }),
  quantity: 1,
  setQuantity: (quantity) => set({ quantity }),
}))

export default usePurchaseRewardModalStore
