import { FlexBox, SPACING, Typography } from "@joonapp/web-shared"
import { useEffect } from "react"

import JooniperQuestRecommendation from "./JooniperQuestRecommendation"
import JooniperRewardSetup from "./JooniperRewardSetup"
import JooniperWelcome from "./JooniperWelcome"
import { useJooniperPrepaywallModalStore } from "./useJooniperPrepaywallModalStore"
import { BackgroundRings } from "../../../components/backgroundRings/BackgroundRings"
import TransitionWrapper from "../../../components/coaching/TransitionWrapper"
import SlideUpModal from "../../../components/slideUpModal/SlideUpModal"
import useMobile from "../../../hooks/useMobile"
import { JooniperPrepaywallStep } from "../../../types"
import {
  cohorts,
  experimentNames,
  useGetExperimentCohort,
} from "../../../util/experiments"

export const JooniperPrepaywallModal = () => {
  const { isOpen, onClose, step, setStep } = useJooniperPrepaywallModalStore()
  const jooniperPrepaywallVariant = useGetExperimentCohort(
    experimentNames.jooniperPrepaywall
  )
  const customRewardsPrepaywallVariant = useGetExperimentCohort(
    experimentNames.customRewardsPrepaywall
  )

  const isMobile = useMobile()

  const containerStyle = {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: SPACING.space4,
    width: "100%",
    padding: SPACING.space4,
  } as const

  useEffect(() => {
    if (jooniperPrepaywallVariant === cohorts.variantA)
      setStep(JooniperPrepaywallStep.WELCOME)
    else setStep(JooniperPrepaywallStep.REWARD_SETUP)
  }, [jooniperPrepaywallVariant, setStep])

  if (
    jooniperPrepaywallVariant === null ||
    customRewardsPrepaywallVariant === null
  )
    return null

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      size={isMobile ? "full-height" : "fit-content"}
      backgroundType="overlay"
    >
      <FlexBox
        direction="column"
        wrap={false}
        style={{
          flex: 1,
          minHeight: "70vh",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <Typography
          variant="body"
          textAlign="left"
          style={{ padding: SPACING.space4 }}
        >
          {step === JooniperPrepaywallStep.REWARD_SETUP
            ? "Custom Rewards Set Up"
            : "Jooniper: AI Quest & Routine Builder"}
        </Typography>

        <TransitionWrapper
          showChildren={step === JooniperPrepaywallStep.WELCOME}
          style={{
            ...containerStyle,
            height: step === JooniperPrepaywallStep.WELCOME ? "100%" : "0",
          }}
        >
          <JooniperWelcome />
        </TransitionWrapper>
        <TransitionWrapper
          showChildren={step === JooniperPrepaywallStep.QUEST_RECOMMENDATION}
          style={{
            ...containerStyle,
            padding: SPACING.space0,
            height:
              step === JooniperPrepaywallStep.QUEST_RECOMMENDATION
                ? "100%"
                : "0",
          }}
        >
          <JooniperQuestRecommendation />
        </TransitionWrapper>
        <TransitionWrapper
          showChildren={step === JooniperPrepaywallStep.REWARD_SETUP}
          style={{
            ...containerStyle,
            padding: SPACING.space0,
            height: step === JooniperPrepaywallStep.REWARD_SETUP ? "100%" : "0",
          }}
        >
          <JooniperRewardSetup />
        </TransitionWrapper>
        <div style={{ zIndex: -1 }}>
          <BackgroundRings />
        </div>
      </FlexBox>
    </SlideUpModal>
  )
}
