import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  Loader,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

interface Primary3DButtonProps extends React.HTMLProps<HTMLButtonElement> {
  text: string | React.ReactNode
  isLoading?: boolean
  fullWidth?: boolean
  parentStyle?: React.CSSProperties
}

const Primary3DButton = ({
  text,
  onClick,
  fullWidth = true,
  disabled,
  isLoading,
  style,
  parentStyle,
}: Primary3DButtonProps) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isPressed, setIsPressed] = useState(false)
  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        width: fullWidth ? "100%" : "fit-content",
        opacity: disabled || isLoading ? 0.2 : 1,
        pointerEvents: disabled || isLoading ? "none" : "auto",
        cursor: disabled || isLoading ? "not-allowed" : "pointer",
        transition: "opacity 0.2s ease-in-out",
        ...parentStyle,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: SPACING.space1,
          left: SPACING.space1,
          width: "100%",
          height: "100%",
          background: JoonColorExpanded.indigo700,
          borderRadius: 8,
        }}
      />
      <button
        onMouseDown={() => setIsPressed(true)}
        onMouseUp={() => setIsPressed(false)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => {
          setIsHovered(false)
          setIsPressed(false)
        }}
        onTouchStart={() => setIsPressed(true)}
        onTouchEnd={() => setIsPressed(false)}
        onTouchCancel={() => setIsPressed(false)}
        onFocus={() => setIsHovered(true)}
        onBlur={() => {
          setIsHovered(false)
          setIsPressed(false)
        }}
        onClick={onClick}
        style={{
          width: fullWidth ? "100%" : "fit-content",
          background: isPressed
            ? JoonColorExpanded.indigo700
            : isHovered
              ? JoonColorExpanded.indigo400
              : JoonColorExpanded.indigo500,
          borderRadius: SPACING.space2,
          border: `1px solid ${JoonColorExpanded.indigo500}`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: SPACING.space3,
          transition: "background 0.2s ease-in-out, transform 0.1s ease-in-out",
          transform: isPressed ? "translate(4px, 4px)" : "translate(0, 0)",
          ...style,
        }}
      >
        <FlexBox justify="center" align="center" fullWidth>
          {isLoading ? (
            <Loader size={24} thickness={4} />
          ) : (
            <>
              {typeof text === "string" ? (
                <Typography
                  variant="bodyBold"
                  color={JoonUIColor.text.inverted}
                >
                  {text}
                </Typography>
              ) : (
                text
              )}
            </>
          )}
        </FlexBox>
      </button>
    </div>
  )
}

export default Primary3DButton
