import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { FlexBox } from "@joonapp/web-shared"
import { useEffect } from "react"

import useAIQuest from "./hooks/useAIQuest"
import useAIQuestStore from "./hooks/useAIQuestStore"
import QuestAILanding from "./QuestAILanding"
import QuestAIResponse from "./QuestAIResponse"
import QuestAIWelcome from "./QuestAIWelcome"
import { BackgroundRings } from "../../../components/backgroundRings/BackgroundRings"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { localStorageItems } from "../../../util/storage"

const QuestAIContent = () => {
  const { initializeChatSession } = useAIQuest()
  const { chatSessionId, response, isLoading, assignedQuests, isAssigning } =
    useAIQuestStore()

  const needsToSeeWelcome = !localStorage.getItem(
    localStorageItems.hasSeenAIQuestBuilderWelcome
  )

  useEffect(() => {
    if (chatSessionId) return
    trackAnalyticEvent(ANALYTIC_EVENTS.AI_QUEST_BUILDER_NEW_SESSION)
    initializeChatSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderContent = () => {
    if (isLoading || response || assignedQuests.length > 0 || isAssigning)
      return <QuestAIResponse />
    if (needsToSeeWelcome) return <QuestAIWelcome />
    return <QuestAILanding />
  }

  return (
    <FlexBox
      direction="column"
      wrap={false}
      style={{ position: "relative", flex: 1, height: "100%" }}
    >
      <AIContentHeader />
      <FlexBox direction="column" wrap={false} style={{ zIndex: 1, flex: 1 }}>
        {renderContent()}
      </FlexBox>
      <BackgroundRings />
    </FlexBox>
  )
}

export default QuestAIContent

const AIContentHeader = () => {
  const { onClose } = useAIQuestStore()
  return (
    <FlexBox
      direction="row"
      align="center"
      justify="space-between"
      wrap={false}
      style={{ padding: SPACING.space4 }}
    >
      <Typography variant="bodySmall">
        Jooniper: AI Quest & Routine Builder
      </Typography>
      <button onClick={onClose} style={{ padding: 0, margin: 0 }}>
        <FontAwesomeIcon
          icon={faXmark}
          fontSize={16}
          color={JoonUIColor.icon.neutral}
        />
      </button>
    </FlexBox>
  )
}
