import { faChildren, faHomeHeart } from "@fortawesome/duotone-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"

import { QuestBoardSelectOption } from "../../types/quests"

export const QuestBoardUserSelectButton = ({
  label,
  imageUrl,
  selectOption,
  onClick,
  isSelected,
}: {
  label: string
  imageUrl: string | null
  selectOption: QuestBoardSelectOption
  onClick: () => void
  isSelected: boolean
}) => {
  return (
    <button
      onClick={onClick}
      style={{
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: SPACING.space1,
        minWidth: "44px",
        maxWidth: "44px",
        userSelect: "none",
      }}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="avatar"
          draggable="false"
          style={{
            border: `3px solid ${
              isSelected ? JoonUIColor.border.accent : "transparent"
            }`,
            borderRadius: "50%",
            minWidth: "40px",
            minHeight: "40px",
            maxWidth: "40px",
            maxHeight: "40px",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      ) : (
        <div
          style={{
            border: `3px solid ${
              isSelected ? JoonUIColor.border.accent : "transparent"
            }`,
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: isSelected
              ? JoonUIColor.background.lightAccent
              : JoonUIColor.background.xlightGray,
            borderRadius: "50%",
          }}
        >
          <FontAwesomeIcon
            icon={
              selectOption === QuestBoardSelectOption.ALL_KIDS
                ? faChildren
                : faHomeHeart
            }
            color={
              isSelected ? JoonUIColor.icon.accent : JoonUIColor.icon.neutral
            }
          />
        </div>
      )}
      <Typography
        variant={isSelected ? "bodyXSmallBold" : "bodyXSmall"}
        color={
          isSelected ? JoonUIColor.text.primaryAccent : JoonUIColor.text.primary
        }
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          maxWidth: "100%",
        }}
      >
        {label}
      </Typography>
    </button>
  )
}
