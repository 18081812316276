import { faChevronRight } from "@fortawesome/pro-light-svg-icons"
import {
  Button,
  ChatIcon,
  FlexBox,
  HandHoldingHeartIcon,
  InfoIcon,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
  VideoIcon,
} from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import { PlanTitle } from "./coachingFlow/planComponents/PlanTitle"
import { useCoachingStore } from "./useCoachingStore"
import useIsFromMOF from "./useIsFromMOF"
import ListCard from "../../components/coaching/ListCard"
import PageLoader from "../../components/loading/PageLoader"
import {
  PhoneNumberModalType,
  useAddPhoneNumberModalStore,
} from "../../components/modals/addPhoneNumberModal/useAddPhoneNumberModalStore"
import { COACH_PLANS, CoachPaywallChoice, QUERY_KEYS } from "../../constants"
import {
  useCoachSubscriptionPackagesQuery,
  useGetCoachSubscriptionQuery,
} from "../../networking/coaching/queries"
import { useUserQuery } from "../../networking/queries"
import { SubscriptionPackage } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { queryClient } from "../../util/queryClient"
import { useRouter } from "../../util/router"
import { localStorageItems } from "../../util/storage"
import useCareTeam from "../care/useCareTeam"

const ScheduledPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { coachingPlan, setCoachingPlan } = useCoachingStore()
  const { firstCoach } = useCareTeam()
  const router = useRouter()
  const isFromMOF = useIsFromMOF()
  const { onOpen: openAddPhoneNumberModal } = useAddPhoneNumberModalStore()
  const { data: coachSubscription } = useGetCoachSubscriptionQuery()
  const { data: subscriptionPackages } = useCoachSubscriptionPackagesQuery()
  const packages: SubscriptionPackage[] = Object.values(
    subscriptionPackages || {}
  )
  const { user } = useUserQuery()
  const selectedPackage = packages.find(
    (plan) => plan.num_sessions === coachSubscription?.num_sessions
  )
  const { plan, frequency } = COACH_PLANS[coachingPlan]

  const onFinishClick = () => {
    router.push("/coach")
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_COACH_ONBOARDING_COMPLETE)
    if (!user?.phone_number) {
      openAddPhoneNumberModal(PhoneNumberModalType.COACH)
    }
  }

  useEffect(() => {
    const timer = setTimeout(async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.CARE_TEAM])
      setIsLoading(false)
    }, 3000)

    const bundleJoonity =
      localStorage.getItem(localStorageItems.planChoiceFromPaywall) ===
        CoachPaywallChoice.WITH_COACH ||
      localStorage.getItem(localStorageItems.planChoiceFromPaywall) ===
        CoachPaywallChoice.EXIT_WITH_COACH

    if (selectedPackage?.lookup_key && firstCoach) {
      setCoachingPlan(selectedPackage.lookup_key)
      trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_COACH_PURCHASE_COMPLETE, {
        is_tof: !isFromMOF,
        lookup_key: selectedPackage?.lookup_key,
        bundle_joonity: bundleJoonity,
        coach_id: firstCoach?.user.id,
      })
    }
    return () => clearTimeout(timer)
  }, [setCoachingPlan, selectedPackage, isFromMOF, firstCoach])

  return (
    <>
      {isLoading && <PageLoader />}

      <FlexBox
        justify="flex-start"
        align="center"
        direction="column"
        fullWidth
        gap={SPACING.space4}
        style={{
          minHeight: "70vh",
          paddingTop: "20px",
          opacity: isLoading ? 0 : 1,
          pointerEvents: isLoading ? "none" : "auto",
          transition: "opacity 0.3s ease-in-out",
        }}
      >
        <FlexBox
          direction="column"
          align="center"
          justify="center"
          gap={SPACING.space4}
          style={{ marginTop: SPACING.space6 }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: SPACING.space4,
              backgroundColor: JoonColorExpanded.viridian100,
              width: "62px",
              height: "62px",
              transform: "rotate(15deg)",
              paddingBottom: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                transform: "rotate(-15deg)",
              }}
            >
              <HandHoldingHeartIcon
                size={48}
                color={JoonUIColor.semantic.success}
              />
            </div>
          </div>
          <Typography variant="h2" color={JoonUIColor.text.primary}>
            Coaching membership started!
          </Typography>
        </FlexBox>

        <Typography variant="body" color={JoonUIColor.text.primary}>
          Membership summary:
        </Typography>
        <FlexBox
          direction="column"
          justify="center"
          align="center"
          style={{
            position: "relative",
            width: "min(450px, 100%)",
            background: "#F9FAFC",
            border: `2px solid ${JoonUIColor.text.primaryAccent}`,
            borderRadius: SPACING.space2,
            marginBottom: SPACING.space2,
            boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
            textAlign: "left",
            padding: SPACING.space2,
          }}
        >
          <FlexBox
            justify="left"
            align="center"
            gap={SPACING.space1}
            style={{
              marginTop: SPACING.space2,
            }}
          >
            <PlanTitle plan={plan} coachingPlan={coachingPlan} />
          </FlexBox>

          <FlexBox justify="center" align="center" gap={SPACING.space6}>
            <FlexBox direction="column" align="flex-start" gap={SPACING.space0}>
              <FlexBox
                direction="row"
                justify="flex-start"
                align="center"
                gap={SPACING.space2}
                wrap={false}
                style={{ padding: SPACING.space2 }}
              >
                <div
                  style={{
                    minWidth: "20px",
                    maxWidth: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <HandHoldingHeartIcon
                    size={20}
                    color={JoonUIColor.icon.accent}
                  />
                </div>
                <Typography
                  variant="bodyXSmallBold"
                  color={JoonUIColor.text.primary}
                >
                  A personally matched Coach to help you set up your Joon app
                  properly for your family
                </Typography>
              </FlexBox>

              <FlexBox
                direction="row"
                justify="flex-start"
                align="center"
                gap={SPACING.space2}
                wrap={false}
                style={{ padding: SPACING.space2 }}
              >
                <div
                  style={{
                    minWidth: "20px",
                    maxWidth: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ChatIcon size={20} color={JoonUIColor.icon.accent} />
                </div>
                <Typography
                  variant="bodyXSmallBold"
                  color={JoonUIColor.text.primary}
                >
                  Anytime direct messaging with your coach
                </Typography>
              </FlexBox>

              {frequency && (
                <FlexBox
                  direction="row"
                  justify="flex-start"
                  align="center"
                  gap={SPACING.space2}
                  wrap={false}
                  style={{
                    padding: SPACING.space2,
                    paddingRight: SPACING.space0,
                    borderRadius: SPACING.space2,
                  }}
                >
                  <div
                    style={{
                      minWidth: "20px",
                      maxWidth: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <VideoIcon size={20} color={JoonUIColor.icon.accent} />
                  </div>
                  <Typography
                    variant="bodyXSmallBold"
                    color={JoonUIColor.text.primary}
                  >
                    {frequency}
                  </Typography>
                </FlexBox>
              )}
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <ListCard
          icon={InfoIcon}
          text={`You're all set - ${firstCoach?.user.name || "your coach"} is excited to meet you, start a chat below!`}
          backgroundColor={JoonUIColor.background.lightAccent}
          gap={SPACING.space2}
          iconColor={JoonUIColor.border.accent}
        />

        <Button
          fullWidth
          onClick={onFinishClick}
          text="Continue"
          rightIcon={faChevronRight}
        />
      </FlexBox>
    </>
  )
}

export default ScheduledPage
