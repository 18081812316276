export const BackgroundRings = () => (
  <div
    style={{
      position: "absolute",
      bottom: 0,
      userSelect: "none",
    }}
  >
    <svg
      width="375"
      height="437"
      viewBox="0 0 375 437"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2" filter="url(#filter0_f_30_2685)">
        <circle
          cx="271.693"
          cy="495.19"
          r="189.841"
          transform="rotate(133.661 271.693 495.19)"
          stroke="#A7BFFA"
        />
        <circle
          cx="207.906"
          cy="489.609"
          r="189.841"
          transform="rotate(133.661 207.906 489.609)"
          stroke="#A7BFFA"
        />
        <circle
          cx="136.884"
          cy="477.123"
          r="189.841"
          transform="rotate(133.661 136.884 477.123)"
          stroke="#A7BFFA"
        />
        <circle
          cx="65.8617"
          cy="464.638"
          r="189.841"
          transform="rotate(133.661 65.8617 464.638)"
          stroke="#A7BFFA"
        />
        <circle
          cx="-5.16026"
          cy="452.152"
          r="189.841"
          transform="rotate(133.661 -5.16026 452.152)"
          stroke="#A7BFFA"
        />
        <circle
          cx="-76.1821"
          cy="439.666"
          r="189.841"
          transform="rotate(133.661 -76.1821 439.666)"
          stroke="#A7BFFA"
        />
        <circle
          cx="236.182"
          cy="488.947"
          r="189.841"
          transform="rotate(133.661 236.182 488.947)"
          stroke="#F1B9E2"
        />
        <circle
          cx="172.395"
          cy="483.366"
          r="189.841"
          transform="rotate(133.661 172.395 483.366)"
          stroke="#F1B9E2"
        />
        <circle
          cx="101.373"
          cy="470.88"
          r="189.841"
          transform="rotate(133.661 101.373 470.88)"
          stroke="#F1B9E2"
        />
        <circle
          cx="30.3508"
          cy="458.395"
          r="189.841"
          transform="rotate(133.661 30.3508 458.395)"
          stroke="#F1B9E2"
        />
        <circle
          cx="-40.6711"
          cy="445.909"
          r="189.841"
          transform="rotate(133.661 -40.6711 445.909)"
          stroke="#F1B9E2"
        />
        <circle
          cx="-111.693"
          cy="433.424"
          r="189.841"
          transform="rotate(133.661 -111.693 433.424)"
          stroke="#F1B9E2"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_30_2685"
          x="-303.035"
          y="242.082"
          width="766.069"
          height="444.45"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.5"
            result="effect1_foregroundBlur_30_2685"
          />
        </filter>
      </defs>
    </svg>
  </div>
)
