import { useEffect } from "react"

import { useRouter } from "../util/router"
import { localStorageItems, sessionManager } from "../util/storage"

const useCoachingBundleVariantFlag = () => {
  const router = useRouter()

  useEffect(() => {
    const hasCoachingBundle = !!router.query.coachingPlan
    if (!hasCoachingBundle) return
    router.replace(router.pathname)
    sessionManager.setLocalStorageFlag(
      localStorageItems.wasRoutedFromExternalCoachMarketing
    )
  }, [router])
}

export default useCoachingBundleVariantFlag
