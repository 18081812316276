import { faCheck, faChevronRight } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  Loader,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useState } from "react"

import DoterChatFlag, { flagContent } from "./DoterChatFlag"
import FlaggedConvoModal from "./FlaggedConvoModal"
import { useDoterChatModalStore } from "./useDoterChatModalStore"
import useFlaggedConvoModalStore from "./useFlaggedConvoModalStore"
import NotificationDot2 from "../../../../components/notificationDot/NotificationDot2"
import { QUERY_KEYS } from "../../../../constants"
import { markFlaggedChatAsRead } from "../../../../networking/chatSessions/api"
import { useFlaggedDoterChatLogsQuery } from "../../../../networking/chatSessions/queries"
import {
  AIChatFlaggedPromptNotification,
  AIPromptFlag,
} from "../../../../types"
import { trackAnalyticEvent } from "../../../../util/analytics"
import { ANALYTIC_EVENTS } from "../../../../util/analytics"
import { queryClient } from "../../../../util/queryClient"

const FlaggedConvos = () => {
  const flags = Object.values(AIPromptFlag)
  return (
    <FlexBox
      direction="column"
      wrap={false}
      gap={SPACING.space4}
      style={{ padding: SPACING.space4, overflowY: "auto" }}
    >
      <Typography variant="bodySmall">
        Conversations are automatically flagged when potential references to{" "}
        {flags.map((flag, index) => (
          <span
            key={flag}
            style={{ color: flagContent[flag].color, fontWeight: 500 }}
          >
            {flagContent[flag].text}
            {index < flags.length - 2 ? ", " : ""}
            {index === flags.length - 2 ? ", and " : ""}
          </span>
        ))}{" "}
        are detected.
      </Typography>
      <FlaggedConvosList />
      <FlaggedConvoModal />
    </FlexBox>
  )
}

export default FlaggedConvos

const FlaggedConvosList = () => {
  const { data: convos, isLoading } = useFlaggedDoterChatLogsQuery()
  const { childUserId } = useDoterChatModalStore()

  const filteredConvos =
    convos?.filter((convo) => convo.user_id === childUserId) || []
  const unreadConvos = filteredConvos?.filter((convo) => !convo.date_read)

  if (isLoading)
    return (
      <FlexBox
        justify="center"
        align="center"
        style={{ padding: SPACING.space6 }}
      >
        <Loader color={JoonUIColor.icon.neutral} thickness={2} />
      </FlexBox>
    )

  if (!filteredConvos) return null

  return (
    <FlexBox direction="column" gap={SPACING.space2}>
      {unreadConvos?.length > 0 && (
        <FlexBox
          direction="row"
          gap={SPACING.space2}
          justify="space-between"
          align="center"
        >
          <Typography variant="bodySmall">
            {unreadConvos?.length} flagged conversations
          </Typography>
          <MarkAllAsReadButton />
        </FlexBox>
      )}
      {filteredConvos.map((log) => (
        <FlaggedConvosListItem key={log.id} log={log} />
      ))}
    </FlexBox>
  )
}

const MarkAllAsReadButton = () => {
  const [isHovered, setIsHovered] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { refetch } = useFlaggedDoterChatLogsQuery()
  const { data: convos } = useFlaggedDoterChatLogsQuery()
  const { childUserId } = useDoterChatModalStore()
  const filteredConvos = convos?.filter(
    (convo) => convo.user_id === childUserId
  )

  const handleMarkAllAsRead = async () => {
    setIsLoading(true)
    const ids = filteredConvos?.map((convo) => convo.id)
    const promises: Promise<any>[] = []
    ids?.forEach((id) => promises.push(markFlaggedChatAsRead(id)))
    await Promise.all(promises)
    queryClient.invalidateQueries([QUERY_KEYS.FLAGGED_DOTER_CHAT_LOGS])
    refetch()
    setIsLoading(false)
    trackAnalyticEvent(ANALYTIC_EVENTS.MARK_ALL_FLAGGED_MESSAGES_READ)
  }

  return (
    // TODO: update this to small subtle button once variant is built
    <button
      style={{
        padding: "4px 6px",
        border: `1px solid ${JoonUIColor.border.default}`,
        borderRadius: "6px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: isLoading ? "not-allowed" : "pointer",
        gap: SPACING.space1,
        background:
          isHovered || isLoading
            ? JoonUIColor.background.xlightGray
            : JoonUIColor.background.primaryNeutral,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      disabled={isLoading}
      onClick={handleMarkAllAsRead}
    >
      <div style={{ position: "absolute", opacity: isLoading ? 1 : 0 }}>
        <Loader thickness={2} size={16} color={JoonUIColor.icon.neutral} />
      </div>
      <FlexBox
        direction="row"
        align="center"
        gap={SPACING.space1}
        style={{ opacity: isLoading ? 0 : 1 }}
      >
        <FontAwesomeIcon icon={faCheck} />
        <Typography variant="bodySmall">Mark all as read</Typography>
      </FlexBox>
    </button>
  )
}

const FlaggedConvosListItem = ({
  log,
}: {
  log: AIChatFlaggedPromptNotification
}) => {
  const { refetch } = useFlaggedDoterChatLogsQuery()
  const { onOpen: openFlaggedConvoModal } = useFlaggedConvoModalStore()

  const handleReadConvo = async () => {
    try {
      const formattedDate = dayjs(log.date_created).format("YYYY-MM-D")
      openFlaggedConvoModal(formattedDate, log.message.id)
      await markFlaggedChatAsRead(log.id)
      queryClient.invalidateQueries([QUERY_KEYS.FLAGGED_DOTER_CHAT_LOGS])
      refetch()
      trackAnalyticEvent(ANALYTIC_EVENTS.VIEW_FLAGGED_CONVERSATION)
    } catch (error) {
      console.error("error marking convo as read", error)
    }
  }

  return (
    <FlexBox
      id={log.id.toString()}
      direction="row"
      style={{
        border: `1px solid ${JoonUIColor.border.default}`,
        borderRadius: SPACING.space2,
      }}
    >
      <FlexBox
        direction="row"
        align="center"
        wrap={false}
        gap={SPACING.space2}
        justify="space-between"
        style={{
          background: JoonUIColor.background.lightGray,
          padding: SPACING.space2,
        }}
      >
        <FlexBox direction="row" align="center" gap={SPACING.space1}>
          {!log.date_read && <NotificationDot2 />}
          <Typography variant="bodySmall" style={{ fontWeight: 500 }}>
            {dayjs(log.date_created).format("MMM D")}
          </Typography>
        </FlexBox>
        <button
          style={{
            display: "flex",
            alignItems: "center",
            gap: SPACING.space1,
          }}
          onClick={handleReadConvo}
        >
          <Typography variant="bodyXSmall" style={{ whiteSpace: "nowrap" }}>
            read conversation
          </Typography>
          <FontAwesomeIcon
            icon={faChevronRight}
            color={JoonUIColor.icon.neutral}
          />
        </button>
      </FlexBox>
      <FlexBox
        direction="column"
        gap={SPACING.space1}
        style={{ padding: SPACING.space2 }}
      >
        <FlexBox
          direction="column"
          gap={SPACING.space1}
          style={{
            background: JoonColorExpanded.purple600,
            padding: "6px 12px",
            borderRadius: SPACING.space2,
          }}
        >
          <Typography variant="bodySmall" color={JoonUIColor.text.inverted}>
            {log.message.prompt}
          </Typography>
          <FlexBox direction="row" gap={SPACING.space1}>
            {log.message.prompt_flags &&
              log.message.prompt_flags.map((flag) => (
                <DoterChatFlag key={flag} type={flag} />
              ))}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}
