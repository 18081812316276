import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import {
  useCoinCulumativeStatsThisWeekQuery,
  useFamilyQuery,
} from "../../../networking/queries"
import { FamilyQuerySelect } from "../../../types"
import { useCoinTransactionsModalStore } from "../../family/coinTransactions/useTransactionsModalStore"

const TotalCoinsEarnedCard = ({ childId }: { childId: number }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isPressed, setIsPressed] = useState(false)
  const { onOpen: openCoinTransactionModal } = useCoinTransactionsModalStore()
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const child = children?.find((child) => child.user.id === childId)
  const { data: coinStats, isLoading: isLoadingCoinStats } =
    useCoinCulumativeStatsThisWeekQuery(child)

  return (
    <FlexBox
      justify="space-between"
      direction="row"
      align="center"
      wrap={false}
      gap={SPACING.space2}
      onClick={() => openCoinTransactionModal(child?.user)}
      style={{
        width: "100%",
        padding: `${SPACING.space3} ${SPACING.space4}`,
        border: `1px solid ${JoonUIColor.border.default}`,
        borderRadius: SPACING.space2,
        background: isHovered
          ? JoonUIColor.background.xlightGray
          : JoonUIColor.background.primaryNeutral,
        cursor: isHovered ? "pointer" : "default",
        transition: "background 0.1s ease-in-out",
        transform: isPressed ? "translateY(1px)" : "translateY(0px)",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false)
        setIsPressed(false)
      }}
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => setIsPressed(false)}
    >
      <Typography
        variant="bodySmallBold"
        textAlign="left"
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        Total coins earned last 7 days
      </Typography>

      <FlexBox
        direction="row"
        align="center"
        gap={SPACING.space1}
        wrap={false}
        style={{
          width: "fit-content",
          backgroundColor: JoonColorExpanded.green200,
          padding: `${SPACING.space0} ${SPACING.space1}`,
          borderRadius: SPACING.space1,
        }}
      >
        <Typography variant="bodySmallBold">
          {isLoadingCoinStats ? "-" : coinStats?.coins_earned}
        </Typography>
        <img
          style={{ height: SPACING.space3 }}
          src="/images/icons/coin-icon.png"
          alt="coin"
        />
      </FlexBox>
    </FlexBox>
  )
}

export default TotalCoinsEarnedCard
