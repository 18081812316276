import { faChevronRight, faLock } from "@fortawesome/free-solid-svg-icons"
import { faEdit } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  FamilyParentProfile,
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import ParentAvatarButton from "./ParentAvatarButton"
import { useParentPasscodeModalStore } from "./ParentPasscodeModal"
import SelectAvatarModal from "./SelectAvatarModal"
import { useManageProfileModalStore } from "./useManageProfileModalStore"
import { useSelectAvatarModalStore } from "./useSelectAvatarModalStore"
import { useUserQuery } from "../../../networking/queries"
import useEditParentMutation from "../../settings/useEditParentMutation"

type Props = {
  user: FamilyParentProfile
}

const ManageProfileModal = ({ user }: Props) => {
  const [isHoveredManagePasscode, setIsHoveredManagePasscode] = useState(false)
  const { isOpen, onClose, newNickname, setNewNickname } =
    useManageProfileModalStore()
  const { onOpen: onOpenSelectAvatarModal } = useSelectAvatarModalStore()
  const editParentMutation = useEditParentMutation()
  const { onOpen: onOpenParentPasscodeModal } = useParentPasscodeModalStore()
  // this is always the same user as the user.user passed in the props
  const { user: currentUser } = useUserQuery()

  const onSubmit = () => {
    if (newNickname !== user?.nickname) {
      editParentMutation.mutate({
        parentInfo: {
          name: user?.user.name,
          nickname: newNickname,
          avatar: user?.user.avatar,
        },
        userId: user?.user.id,
      })
    }
    onClose()
  }

  useEffect(() => {
    isOpen && setNewNickname(user?.nickname || "")
  }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!user) return <></>

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} displayCloseIcon animate>
        <div className="quest-modal">
          <div className="quest-modal-content">
            <FlexBox wrap={false} gap={SPACING.space4} direction="column">
              <FlexBox justify="center">
                <Typography variant="h2">Profile</Typography>
              </FlexBox>
              <FlexBox
                onClick={onOpenSelectAvatarModal}
                className="quest-modal-content"
                style={{ width: "min(200px, 25vw)", borderRadius: "50%" }}
              >
                <div style={{ position: "relative" }}>
                  <ParentAvatarButton
                    avatar={user?.user.avatar || null}
                    isSelected={false}
                    setSelectedParentAvatar={() => {}}
                  />
                  <div
                    style={{
                      position: "absolute",
                      bottom: "0",
                      right: "0",
                      cursor: "pointer",
                      background: JoonUIColor.background.primaryNeutral,
                      borderRadius: SPACING.space2,
                      padding: SPACING.space1,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faEdit}
                      color={JoonUIColor.icon.accent}
                      size="lg"
                    />
                  </div>
                </div>
              </FlexBox>
              <FlexBox justify="space-between" align="center" wrap={false}>
                <FlexBox direction="column" gap={SPACING.space2}>
                  <FlexBox gap={SPACING.space0}>
                    <Typography variant="bodyBold">Your Nickname:</Typography>
                    <Typography variant="body">
                      This is your avatar's name that your kids will see you as
                      in the game.
                    </Typography>
                  </FlexBox>
                  <FlexBox gap={SPACING.space4}>
                    <TextInput
                      name="nickname"
                      value={newNickname}
                      onChange={(e: any) => setNewNickname(e.target.value)}
                      placeholder="Eg. Mom"
                      maxLength={140}
                      fullWidth
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>

              <button
                onMouseEnter={() => setIsHoveredManagePasscode(true)}
                onMouseLeave={() => setIsHoveredManagePasscode(false)}
                onClick={onOpenParentPasscodeModal}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: SPACING.space2,
                  padding: `${SPACING.space2} ${SPACING.space4}`,
                  border: `1px solid ${JoonUIColor.border.default}`,
                  borderRadius: SPACING.space2,
                  background: isHoveredManagePasscode
                    ? JoonUIColor.background.xlightGray
                    : JoonUIColor.background.primaryNeutral,
                  transition: "background 0.2s ease-in-out",
                }}
              >
                <FlexBox
                  direction="row"
                  align="center"
                  gap={SPACING.space4}
                  wrap={false}
                >
                  <FontAwesomeIcon
                    icon={faLock}
                    color={JoonUIColor.icon.accent}
                  />
                  <FlexBox direction="column" wrap={false}>
                    <Typography variant="bodyBold">
                      {currentUser?.passcode === null ? "Create" : "Manage"}{" "}
                      Passcode
                    </Typography>
                    <Typography variant="bodySmall">
                      {currentUser?.passcode === null
                        ? "Create"
                        : "Edit or delete"}{" "}
                      your account passcode
                    </Typography>
                  </FlexBox>
                </FlexBox>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  color={JoonUIColor.icon.accent}
                />
              </button>

              <Button
                onClick={onSubmit}
                fullWidth
                text="Save"
                disabled={newNickname?.length === 0}
              />
            </FlexBox>
          </div>
        </div>
      </Modal>
      <SelectAvatarModal user={user} />
    </>
  )
}

export default ManageProfileModal
