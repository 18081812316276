import { faGear } from "@fortawesome/duotone-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { JoonUIColor } from "@joonapp/web-shared"
import { IconProps } from "@joonapp/web-shared"

const SettingsIcon = ({
  color = JoonUIColor.icon.accent,
  size = 24,
}: IconProps) => {
  return <FontAwesomeIcon icon={faGear} color={color} fontSize={size} />
}

export default SettingsIcon
