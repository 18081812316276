// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/autoplay"
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { JoonUIColor, Typography } from "@joonapp/web-shared"
import { useRef, useState } from "react"
import {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

const PrevButton = ({ swiperRef }: any) => {
  return (
    <button onClick={() => swiperRef.current.slidePrev()}>
      <FontAwesomeIcon
        fontSize={20}
        color={JoonUIColor.icon.neutral}
        icon={faChevronLeft}
      />
    </button>
  )
}

const NextButton = ({ swiperRef }: any) => {
  return (
    <button onClick={() => swiperRef.current.slideNext()}>
      <FontAwesomeIcon
        fontSize={20}
        color={JoonUIColor.icon.neutral}
        icon={faChevronRight}
      />
    </button>
  )
}

function TestimonialCarousel({
  testimonials,
}: {
  testimonials: {
    image: string
    text: string
    quotee: string
  }[]
}) {
  const swiperRef = useRef<any>(null)
  const [currentSlide, setCurrentSlide] = useState(0)

  const handleSlideChange = (index: number) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(index)
      setCurrentSlide(index)
    }
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center gap-2">
        <PrevButton swiperRef={swiperRef} />
        <div className="relative flex flex-col items-center w-full max-w-[534px] rounded-2xl overflow-hidden shadow-md bg-white border-2 border-primary p-4">
          <Swiper
            loop
            className="w-full h-full flex-1"
            modules={[Pagination, Navigation, Scrollbar, A11y, Autoplay]}
            pagination={false}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            spaceBetween={30}
            onInit={(swiper: any) => (swiperRef.current = swiper)}
            onSlideChange={(swiper) => setCurrentSlide(swiper.realIndex)}
          >
            {testimonials.map((testimonial: any, index: number) => (
              <SwiperSlide key={index}>
                <Testimonial testimonial={testimonial} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <NextButton swiperRef={swiperRef} />
      </div>
      <div className="flex flex-row items-center justify-center mt-4 gap-2">
        {testimonials.map((_, index) => (
          <button key={index} onClick={() => handleSlideChange(index)}>
            <div
              className={`w-2 h-2 rounded-full transition-colors ${index === currentSlide ? "bg-primary" : "bg-white"}`}
            />
          </button>
        ))}
      </div>
    </div>
  )
}

export default TestimonialCarousel

function Testimonial({
  testimonial,
}: {
  testimonial: {
    image: string
    text: string
    quotee: string
  }
}) {
  const [isTextExpanded, setIsTextExpanded] = useState(false)
  const { image, text, quotee } = testimonial

  return (
    <button
      onClick={() => setIsTextExpanded(!isTextExpanded)}
      className="w-full"
    >
      <div className="flex flex-col items-center justify-between gap-2 min-h-[150px]">
        <img
          src={image}
          style={{ width: "38px", borderRadius: "38px" }}
          alt="placeholder"
        />
        <Typography variant="body">
          <span
            style={
              isTextExpanded
                ? {}
                : {
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }
            }
          >
            {text}
          </span>
        </Typography>
        <Typography variant="bodySmallBold">{quotee}</Typography>
      </div>
    </button>
  )
}
