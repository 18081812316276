import {
  faFaceSmileHearts,
  faLock,
  faMessageSmile,
  faRectangleHistory,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  ChildUser,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"
import { useState } from "react"
import { isAndroid, isChrome, isMobile } from "react-device-detect"

import squelcheeOn from "/images/avatars/squelchee_on.png"
import squelcheeOff from "/images/avatars/squelchee_off.png"

import {
  DoterAIInfoStep,
  useDoterAIInfoModalStore,
} from "./useDoterAIInfoModalStore"
import ParentTestimonialCarousel from "../../../../components/carousel/ParentTestimonialCarousel"
import { useAddToHomescreenModal } from "../../../../components/modals/AddToHomescreenModal"
import { useIOSModal } from "../../../../components/modals/IOSModal"
import SlideUpModal from "../../../../components/slideUpModal/SlideUpModal"
import { PREFERENCE_KEY } from "../../../../constants"
import { useFamilyQuery } from "../../../../networking/queries"
import { FamilyQuerySelect } from "../../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import { localStorageItems } from "../../../../util/storage"
import { useManageGameplay } from "../useManageGameplay"

const DoterAIInfoModal = () => {
  const { isOpen, onClose, currentStep } = useDoterAIInfoModalStore()

  const renderStep = () => {
    switch (currentStep) {
      case DoterAIInfoStep.STEP_1:
        return <DoterAIInfoStep1 />
      case DoterAIInfoStep.STEP_2:
        return <DoterAIInfoStep2 />
      case DoterAIInfoStep.STEP_3:
        return <DoterAIInfoStep3 />
      default:
        return null
    }
  }

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      size="fit-content"
      backgroundType="overlay"
      desktopWidth="400px"
    >
      {renderStep()}
    </SlideUpModal>
  )
}

const DoterAIInfoStep1 = () => {
  const { setCurrentStep, isOnboarding } = useDoterAIInfoModalStore()

  const isFTUX = !localStorage.getItem(localStorageItems.hasSeenDoterAIFTUX)

  const handleNext = () => {
    setCurrentStep(DoterAIInfoStep.STEP_2)
  }

  return (
    <>
      <div className="flex flex-col h-full overflow-auto">
        <div className="flex flex-col p-6">
          {isFTUX && !isOnboarding && (
            <Typography
              variant="bodyXSmall"
              style={{
                borderRadius: SPACING.space1,
                background: JoonColorExpanded.yellow300,
                padding: `${SPACING.space1} ${SPACING.space2}`,
                fontWeight: 600,
                marginBottom: SPACING.space2,
                width: "fit-content",
              }}
            >
              New from Joon!
            </Typography>
          )}
          <Typography variant="h3" textAlign="left">
            Kids complete 33% more tasks with an AI pet
          </Typography>
        </div>
        <FlexBox
          direction="column"
          style={{
            padding: SPACING.space4,
            background:
              "linear-gradient(291deg, rgba(152, 242, 219, 0.40) 10.3%, rgba(36, 155, 238, 0.40) 91.68%), #FFF",
          }}
          gap={SPACING.space2}
        >
          <FlexBox
            direction="row"
            wrap={false}
            align="flex-end"
            gap={SPACING.space4}
          >
            <img
              src={"/images/avatars/aiDoter.svg"}
              alt="doter"
              style={{ height: "40px" }}
            />
            <FlexBox direction="column">
              <Typography variant="bodySmall" style={{ fontWeight: 600 }}>
                Doter: Sharky
              </Typography>
              <Typography
                variant="bodySmall"
                style={{
                  background: "rgba(249, 250, 252, 0.60)",
                  padding: SPACING.space2,
                  borderRadius: SPACING.space2,
                  width: "80%",
                }}
              >
                Good morning, Kevin! Ready to tackle a new day? What's on your
                to-do list?
              </Typography>
            </FlexBox>
          </FlexBox>
          <FlexBox
            direction="row"
            wrap={false}
            align="flex-end"
            gap={SPACING.space4}
          >
            <FlexBox direction="column" align="flex-end">
              <Typography variant="bodySmall">Kevin</Typography>
              <Typography
                variant="bodySmall"
                style={{
                  background: JoonColorExpanded.purple600,
                  padding: SPACING.space2,
                  width: "80%",
                  borderRadius: SPACING.space2,
                }}
              >
                <Typography
                  variant="bodySmall"
                  color={JoonUIColor.text.inverted}
                >
                  I have to clean my room and feed the dog before school!
                </Typography>
              </Typography>
            </FlexBox>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                width: SPACING.space8,
                minWidth: SPACING.space8,
                height: SPACING.space8,
                minHeight: SPACING.space8,
                background: JoonColorExpanded.purple600,
              }}
            >
              <Typography
                variant="body"
                color={JoonUIColor.background.primaryNeutral}
              >
                K
              </Typography>
            </div>
          </FlexBox>
          <FlexBox
            direction="row"
            wrap={false}
            align="flex-end"
            gap={SPACING.space4}
          >
            <img
              src={"/images/avatars/aiDoter.svg"}
              style={{ height: "40px" }}
              alt="doterai"
            />
            <FlexBox direction="column">
              <Typography variant="bodySmall" style={{ fontWeight: 600 }}>
                Doter: Sharky
              </Typography>
              <Typography
                variant="bodySmall"
                style={{
                  background: "rgba(249, 250, 252, 0.60)",
                  padding: SPACING.space2,
                  width: "80%",
                  borderRadius: SPACING.space2,
                }}
              >
                That's awesome! Think you can finish both in 15 minutes? Let's
                earn those coins!
              </Typography>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <FlexBox
          direction="column"
          gap={SPACING.space4}
          style={{
            padding: SPACING.space6,
            paddingTop: SPACING.space4,
          }}
        >
          <Typography variant="h3">
            Tap into the digital motivator that works
          </Typography>
          <FlexBox direction="row" wrap={false} gap={SPACING.space2}>
            <FontAwesomeIcon
              icon={faFaceSmileHearts}
              fontSize={18}
              style={{ marginTop: SPACING.space1 }}
              color={JoonUIColor.icon.accent}
            />
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="bodySmallBold">
                Less nagging, more doing!
              </Typography>
              <Typography variant="bodySmall">
                Kids with AI Pet complete
                <Typography variant="bodySmallBold">
                  {" "}
                  33% more tasks{" "}
                </Typography>
                and stay
                <Typography variant="bodySmallBold">
                  {" "}
                  2x more consistent{" "}
                </Typography>
                with routines.
              </Typography>
            </FlexBox>
          </FlexBox>
          <FlexBox direction="row" wrap={false} gap={SPACING.space2}>
            <FontAwesomeIcon
              icon={faMessageSmile}
              fontSize={18}
              style={{ marginTop: SPACING.space1 }}
              color={JoonUIColor.icon.accent}
            />
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="bodySmallBold">Safety first</Typography>
              <Typography variant="bodySmall">
                Age-appropriate interactions only, with full monitoring and
                control options to you available anytime.
              </Typography>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </div>
      <div className="px-6 pb-4 pt-2 bg-white w-full border-t border-borderPrimary">
        <Button
          text="Next"
          fullWidth
          onClick={handleNext}
          style={{ borderRadius: SPACING.space2 }}
        />
      </div>
    </>
  )
}

const DoterAIInfoStep2 = () => {
  const { setCurrentStep, onClose, isOnboarding } = useDoterAIInfoModalStore()
  const isFTUX = !localStorage.getItem(localStorageItems.hasSeenDoterAIFTUX)

  const handleNext = () => {
    if (isFTUX || isOnboarding) {
      setCurrentStep(DoterAIInfoStep.STEP_3)
    } else {
      onClose()
    }
  }

  return (
    <>
      <div className="flex flex-col h-full overflow-auto">
        <FlexBox
          direction="column"
          style={{
            padding: SPACING.space6,
            paddingBottom: SPACING.space4,
          }}
        >
          <Typography variant="h3" textAlign="left">
            Trusted and beloved by parents
          </Typography>
        </FlexBox>
        <div
          className="w-full"
          style={{
            padding: SPACING.space4,
            background:
              "linear-gradient(291deg, rgba(152, 242, 219, 0.40) 10.3%, rgba(36, 155, 238, 0.40) 91.68%), #FFF",
          }}
        >
          <ParentTestimonialCarousel />
        </div>
        <FlexBox
          direction="column"
          gap={SPACING.space4}
          style={{
            padding: SPACING.space6,
            paddingTop: SPACING.space4,
          }}
        >
          <Typography variant="h3">
            Get insights into your child’s day like never before!
          </Typography>
          <FlexBox direction="row" wrap={false} gap={SPACING.space2}>
            <FontAwesomeIcon
              icon={faRectangleHistory}
              fontSize={16}
              style={{ marginTop: SPACING.space05 }}
              color={JoonUIColor.icon.accent}
            />
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="bodySmallBold">
                View Conversation History
              </Typography>
              <Typography variant="bodySmall">
                Stay informed about your child’s conversations and discover
                meaningful topics you can pick up during dinner time!
              </Typography>
            </FlexBox>
          </FlexBox>
          <FlexBox direction="row" wrap={false} gap={SPACING.space2}>
            <FontAwesomeIcon
              icon={faLock}
              fontSize={18}
              style={{ marginTop: SPACING.space05 }}
              color={JoonUIColor.icon.accent}
            />
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="bodySmallBold">
                Full Parental Control
              </Typography>
              <Typography variant="bodySmall">
                Receive alerts when sensitive topics arise and easily enable or
                disable this for each child anytime.
              </Typography>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </div>
      <div className="px-6 pb-4 pt-2 bg-white w-full border-t border-borderPrimary">
        <Button
          text={isFTUX || isOnboarding ? "Set up doter conversations" : "Done"}
          fullWidth
          style={{ borderRadius: SPACING.space2 }}
          onClick={handleNext}
        />
      </div>
    </>
  )
}

const DoterAIInfoStep3 = () => {
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const { onClose, isOnboarding } = useDoterAIInfoModalStore()
  const [pendingToggleStates, setPendingToggleStates] = useState<{
    [key: number]: boolean
  }>({})
  const { updatePreferenceMutation } = useManageGameplay()

  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()
  const { onOpen: openIOSModal } = useIOSModal()

  const isOnMobileChrome = isChrome && isMobile && isAndroid

  const handleNext = async () => {
    onClose()
    const promises: Promise<any>[] = []
    Object.keys(pendingToggleStates).forEach((childId) => {
      promises.push(
        updatePreferenceMutation.mutateAsync({
          childId: parseInt(childId),
          key: PREFERENCE_KEY.enableDoterChat,
          value: pendingToggleStates[parseInt(childId)],
        })
      )
    })
    await Promise.all(promises)
    if (isOnboarding) {
      if (isOnMobileChrome) openAddToHomescreenModal()
      else openIOSModal()
    }
  }

  const handleToggle = (child: ChildUser, isSelected: boolean) => {
    setPendingToggleStates({
      ...pendingToggleStates,
      [child.id]: isSelected,
    })
    trackAnalyticEvent(ANALYTIC_EVENTS.TOGGLE_DOTER_CHAT, {
      source: "ftux",
      enabled: isSelected,
    })
  }

  // Set default toggle states
  useEffect(() => {
    if (!children) return
    const newToggleStates = { ...pendingToggleStates }
    children.forEach((child) => {
      newToggleStates[child.user.id] = true
    })
    setPendingToggleStates(newToggleStates)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children])

  return (
    <>
      <div className="flex flex-col h-full overflow-auto">
        <div className="flex flex-col p-6 pb-0">
          <Typography variant="h3" textAlign="left">
            Which child would you like to turn on AI Pet for?
          </Typography>
        </div>
        <div className="p-6 pb-4 pt-4 w-full">
          {children?.map((child) => (
            <div className="flex flex-col gap-2 p2 rounded-md border border-borderPrimary w-full p-3">
              <Typography variant="bodyBold">{child.user.name}</Typography>
              <div className="flex flex-row gap-2">
                <SquelcheeOffButton
                  onClick={() => handleToggle(child.user, false)}
                  isSelected={!pendingToggleStates[child.user.id]}
                />
                <SquelcheeOnButton
                  onClick={() => handleToggle(child.user, true)}
                  isSelected={pendingToggleStates[child.user.id]}
                />
              </div>
            </div>
          ))}
        </div>
        <Typography
          variant="bodyXSmall"
          textAlign="center"
          className="mx-auto py-2 max-w-[300px]"
        >
          You can always change this setting inside of each child’s Manage Game
          Limits settings page.
        </Typography>
      </div>
      <div className="px-6 pb-4 pt-2 bg-white w-full border-t border-borderPrimary">
        <Button
          text="Save settings"
          fullWidth
          style={{ borderRadius: SPACING.space2 }}
          onClick={handleNext}
        />
      </div>
    </>
  )
}

export default DoterAIInfoModal

const SquelcheeOffButton = ({
  onClick,
  isSelected,
}: {
  onClick: () => void
  isSelected: boolean
}) => {
  return (
    <button onClick={onClick} className="flex-1">
      <div
        className={`flex flex-row gap-2 items-center border rounded-md justify-center ${
          isSelected ? "border-primary" : "border-transparent bg-bgLightGray"
        }`}
      >
        <Typography
          variant="bodyBold"
          color={
            isSelected
              ? JoonUIColor.text.primaryAccent
              : JoonUIColor.text.primary
          }
        >
          OFF
        </Typography>
        <img
          src={squelcheeOff}
          alt="doter AI off"
          style={isSelected ? {} : { filter: "grayscale(100%)" }}
          className="h-[54px] -translate-y-[-1px]"
        />
      </div>
    </button>
  )
}

const SquelcheeOnButton = ({
  onClick,
  isSelected,
}: {
  onClick: () => void
  isSelected: boolean
}) => {
  return (
    <button onClick={onClick} className="flex-1">
      <div
        className={`flex flex-row gap-2 items-center border rounded-md justify-center ${
          isSelected ? "border-primary" : "border-transparent bg-bgLightGray"
        }`}
      >
        <img
          src={squelcheeOn}
          alt="doter AI on"
          style={isSelected ? {} : { filter: "grayscale(100%)" }}
          className="h-[54px] -translate-y-[-1px]"
        />
        <Typography
          variant="bodyBold"
          color={
            isSelected
              ? JoonUIColor.text.primaryAccent
              : JoonUIColor.text.primary
          }
        >
          ON
        </Typography>
      </div>
    </button>
  )
}
