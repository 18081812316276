import {
  Button,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { Placement } from "react-joyride"

import NavigationDots from "./NavigationDots"
import useTourStore from "./useTourStore"
import useMediaQuery from "../../../hooks/useMediaQuery"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { useRouter } from "../../../util/router"
import useIncidentReportStore from "../../me/incidents/useIncidentReportStore"

const StepOne = () => {
  const { setRun, setStepIndex } = useTourStore()
  const isSmallScreen = useMediaQuery("(max-height:750px)")
  return (
    <FlexBox
      direction="column"
      align="center"
      justify="space-between"
      gap={SPACING.space4}
      wrap={false}
    >
      <Typography variant="h3" textAlign="left">
        👋 Welcome to Joon!
      </Typography>
      <Typography
        variant={isSmallScreen ? "bodySmall" : "body"}
        textAlign="left"
      >
        We're excited to have your family here. The{" "}
        <Typography variant={isSmallScreen ? "bodySmall" : "bodyBold"}>
          Care tab
        </Typography>{" "}
        is where you'll be working closely with your therapist.
      </Typography>
      <Typography
        variant={isSmallScreen ? "bodySmall" : "body"}
        textAlign="left"
      >
        Let me show you around!
      </Typography>
      <Button
        buttonType="primary"
        text="Let's go!"
        onClick={() => setStepIndex(1)}
        style={{ width: "90%" }}
      />
      <button
        onClick={() => {
          setStepIndex(0)
          setRun(false)
        }}
        style={{
          position: "absolute",
          top: SPACING.space3,
          right: SPACING.space4,
        }}
      >
        <Typography variant="bodySmallBold" color={JoonUIColor.border.accent}>
          Skip
        </Typography>
      </button>
    </FlexBox>
  )
}

const StepTwo = () => {
  const { trainingId, setStepIndex } = useTourStore()
  const isSmallScreen = useMediaQuery("(max-height:750px)")

  const router = useRouter()
  const careTeamUserId = router.query.careTeamUserId
  return (
    <FlexBox
      direction="column"
      align="center"
      justify="space-between"
      gap={SPACING.space4}
      wrap={false}
    >
      <Typography
        variant={isSmallScreen ? "bodySmall" : "body"}
        textAlign="left"
      >
        <Typography variant={isSmallScreen ? "bodySmallBold" : "bodyBold"}>
          Lessons are how your therapist will assign bite-sized learning modules
          curated for your family.{" "}
        </Typography>
        These modules are short and are made to only take a few minutes to
        complete.
      </Typography>
      <Button
        buttonType="primary"
        text="Try this lesson (2 min)"
        onClick={() => {
          router.push(`/care/${careTeamUserId}/academy/${trainingId}`)
          trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_PARENT_TRAINING_DETAILS)
          setStepIndex(2)
        }}
        style={{ width: "90%" }}
      />
      <NavigationDots activeIndex={1} />
    </FlexBox>
  )
}

const StepThree = () => {
  const { setShowTour } = useTourStore()
  const { onOpen } = useIncidentReportStore()
  const isSmallScreen = useMediaQuery("(max-height:750px)")

  return (
    <FlexBox
      direction="column"
      align="center"
      justify="space-between"
      gap={SPACING.space4}
      wrap={false}
    >
      <Typography
        variant={isSmallScreen ? "bodySmall" : "body"}
        textAlign="left"
      >
        <Typography variant={isSmallScreen ? "bodySmallBold" : "bodyBold"}>
          Use Report Child Behavior Incident to keep track of all behaviors
          you'd like your therapist to be aware of.{" "}
        </Typography>
        Keeping a consistent log will help your therapist strategize next steps
        with you.
      </Typography>
      <Typography
        variant={isSmallScreen ? "bodySmall" : "body"}
        textAlign="left"
      >
        Additionally, daily to-dos list all of the practice tasks your
        therapists would want you to do in between sessions.
      </Typography>
      <Button
        buttonType="primary"
        text="Try reporting an incident"
        onClick={() => {
          onOpen()
          trackAnalyticEvent(ANALYTIC_EVENTS.INITIATE_LOG_INCIDENT)
          setShowTour(false)
        }}
        style={{ width: "90%" }}
      />
      <NavigationDots activeIndex={2} />
    </FlexBox>
  )
}

const steps = [
  {
    content: <StepOne />,
    disableBeacon: true,
    hideCloseButton: true,
    hideFooter: true,
    target: ".tour-step-1",
  },
  {
    content: <StepTwo />,
    disableBeacon: true,
    hideCloseButton: true,
    placement: "bottom" as Placement,
    target: ".tour-step-2",
  },
  {
    content: <StepThree />,
    disableBeacon: true,
    hideCloseButton: true,
    target: ".tour-step-3",
  },
]

export { steps }
