import { FamilyChildProfile, FamilyParentProfile } from "@joonapp/web-shared"
import { create } from "zustand"

interface CatchUpCompleteStore {
  selectedUserProfile: FamilyChildProfile | FamilyParentProfile | null
  setSelectedUserProfile: (
    userProfile: FamilyChildProfile | FamilyParentProfile
  ) => void
  // Quests for completion
  selectedQuestIdsForCompletion: number[]
  clearQuestIdsForCompletion: () => void
  toggleQuestIdForCompletion: (questId: number) => void
}

export const useCatchUpCompleteStore = create<CatchUpCompleteStore>((set) => ({
  selectedUserProfile: null,
  setSelectedUserProfile: (userProfile) =>
    set({ selectedUserProfile: userProfile }),
  // Quests for completion
  selectedQuestIdsForCompletion: [],
  clearQuestIdsForCompletion: () => set({ selectedQuestIdsForCompletion: [] }),
  toggleQuestIdForCompletion: (questId: number) => {
    set((state) => {
      const newSelectedQuestIds = state.selectedQuestIdsForCompletion.includes(
        questId
      )
        ? state.selectedQuestIdsForCompletion.filter((id) => id !== questId)
        : [...state.selectedQuestIdsForCompletion, questId]
      return { selectedQuestIdsForCompletion: newSelectedQuestIds }
    })
  },
}))
