import { faGear } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Badge,
  Family,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import { useManageTherapistModal } from "./ManageTherapistModal"
import { useFamilyQuery } from "../../../networking/queries"
import { TherapistProfileWithPatients } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { getUserFromFamily } from "../../../util/util"

const TherapistItem = ({
  therapist,
}: {
  therapist: TherapistProfileWithPatients
}) => {
  const { onOpen: openManageTherapistModal } = useManageTherapistModal()
  const { data: family } = useFamilyQuery()
  const children = therapist.patients.map((patient) =>
    getUserFromFamily(family as Family, Number(patient))
  )

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space1}
      style={{
        borderRadius: "4px",
        padding: "8px 12px 12px 12px",
        background: JoonUIColor.background.primaryNeutral,
        border: `1px solid ${JoonUIColor.border.accent}`,
      }}
    >
      <FlexBox justify="space-between" align="center" wrap={false}>
        <Typography variant="bodyBold">{therapist.user.name}</Typography>
        <FlexBox
          gap={SPACING.space1}
          wrap={false}
          align="center"
          style={{ width: "fit-content" }}
        >
          <Typography
            variant="bodySmallBold"
            style={{
              background: JoonColorExpanded.viridian100,
              padding: SPACING.space1,
              borderRadius: SPACING.space1,
            }}
          >
            Connected!
          </Typography>
          <button
            onClick={() => {
              trackAnalyticEvent(ANALYTIC_EVENTS.MANAGE_THERAPISTS_BUTTON_CLICK)
              openManageTherapistModal(therapist)
            }}
          >
            <FontAwesomeIcon
              icon={faGear}
              size="lg"
              color={JoonUIColor.icon.neutral}
            />
          </button>
        </FlexBox>
      </FlexBox>
      <FlexBox gap={SPACING.space1}>
        {children.map((patient) => (
          <Badge key={patient?.id}>
            <Typography variant="bodySmallBold">{patient?.name}</Typography>
          </Badge>
        ))}
      </FlexBox>
    </FlexBox>
  )
}

export default TherapistItem
