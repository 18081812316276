import { CustomRewardLogType } from "@joonapp/web-shared"
import { useQuery } from "@tanstack/react-query"
import dayjs from "dayjs"

import {
  getCustomRewardBalances,
  getCustomRewardLogsStats,
  getCustomRewards,
  getOnboardingRewardTemplates,
  getRewardTemplates,
  getScreenTimeBalances,
  getShopItems,
} from "./api"
import { getCustomRewardLogs } from "./api"
import { QUERY_KEYS } from "../../constants"
import { useFilterRewardLogsModalStore } from "../../pages/rewards/hooks/useFilterRewardLogsModalStore"
import { useUserQuery } from "../queries"

export const useCustomRewardLogsQuery = (params?: {
  rewardId?: number
  childId?: number
  type?: CustomRewardLogType
  enabled?: boolean
}) => {
  const { user } = useUserQuery()
  const { rewardId, childId, type, enabled = true } = params || {}
  const { selectedUser, startDate, endDate } = useFilterRewardLogsModalStore()

  const userId = childId || Number(selectedUser?.value) || undefined
  const minDate = startDate ? dayjs(startDate).format("YYYY-MM-DD") : undefined
  const maxDate = endDate ? dayjs(endDate).format("YYYY-MM-DD") : undefined

  return useQuery({
    queryKey: [
      QUERY_KEYS.CUSTOM_REWARD_LOGS,
      rewardId,
      userId,
      minDate,
      maxDate,
      type,
    ],
    queryFn: () =>
      getCustomRewardLogs({ rewardId, userId, minDate, maxDate, type }),
    enabled: !!user && enabled,
  })
}

export const useCustomRewardLogsStatsQuery = (params?: {
  rewardId?: number
}) => {
  const { user } = useUserQuery()
  const { rewardId } = params || {}
  return useQuery({
    queryKey: [QUERY_KEYS.CUSTOM_REWARD_LOGS_STATS, String(rewardId)],
    queryFn: () => getCustomRewardLogsStats({ rewardId: rewardId }),
    enabled: !!user,
  })
}

export const useCustomRewardBalancesQuery = (params?: {
  rewardId?: number
}) => {
  const { user } = useUserQuery()
  const { rewardId } = params || {}
  return useQuery({
    queryKey: [QUERY_KEYS.CUSTOM_REWARD_BALANCES, rewardId],
    queryFn: () => getCustomRewardBalances({ rewardId }),
    enabled: !!user,
  })
}

export const useScreenTimeBalancesQuery = (params?: { rewardId?: number }) => {
  const { user } = useUserQuery()
  const { rewardId } = params || {}
  return useQuery({
    queryKey: [QUERY_KEYS.SCREEN_TIME_BALANCES, rewardId],
    queryFn: () => getScreenTimeBalances({ rewardId }),
    enabled: !!user,
  })
}

export const useCustomRewardsQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.CUSTOM_REWARDS],
    queryFn: getCustomRewards,
    select: (response) => response.data.results,
  })
}

export const useRewardTemplatesQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.REWARD_TEMPLATES],
    queryFn: getRewardTemplates,
    select: (response) => response.data.results,
  })
}

export const useOnboardingRewardTemplatesQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.ONBOARDING_REWARD_TEMPLATES],
    queryFn: getOnboardingRewardTemplates,
  })
}

export const useShopItemsQuery = () => {
  const { user } = useUserQuery()
  return useQuery({
    queryKey: [QUERY_KEYS.SHOP_ITEMS],
    queryFn: () =>
      getShopItems({ userId: user?.id as number, shopType: "parent_shop" }),
    enabled: !!user,
  })
}
