import { create } from "zustand"

import { AnswerInterface, ParentDataInterface } from "../types"
import { history } from "../util/router"
import { sessionManager } from "../util/storage"

export enum OnboardingStep {
  ParentingQuestionnaire,
  AddChildOrJoinFamily,
  CoparentCode,
  AddChildren,
  ParentName,
  ParentNicknameAvatar,
  EmailPassword,
  CreateAccountWithCredentials,
}

export const ChildStep = Object.freeze({
  ChildInfo: 0,
  QuestionnaireDisclaimer: 1,
  Questionnaire: 2,
})

interface OnboardContextStore {
  isCoparent: boolean
  setIsCoparent: (value: boolean) => void
  coparentCode: string
  setCoparentCode: (value: string) => void

  parentData: ParentDataInterface
  creatingPlan: boolean
  setCreatingPlan: (value: boolean) => void
  isCreatingUser: boolean
  setIsCreatingUser: (value: boolean) => void
  currentStep: OnboardingStep
  childStep: number
  parentQuestionnaireStep: number
  setParentQuestionnaireStep: (value: number) => void
  setChildStep: (value: number) => void
  setParentData: (
    fieldName:
      | "name"
      | "nickname"
      | "lastName"
      | "avatar"
      | "parentQuestionnaireMapping"
      | "childrenData",
    value: any
  ) => void
  setStep: (value: number) => void
  nextStep: () => void
  previousStep: () => void
  isSubmittingData: boolean
  setIsSubmittingData: (value: boolean) => void
  postAnswerScreen: AnswerInterface | null
  setPostAnswerScreen: (value: AnswerInterface | null) => void
}

const useOnboardContext = create<OnboardContextStore>((set) => ({
  isCoparent: false,
  setIsCoparent: (value: boolean) => set({ isCoparent: value }),
  coparentCode: "",
  setCoparentCode: (value: string) =>
    set({ coparentCode: value.toUpperCase() }),

  parentData: {
    name: "",
    lastName: "",
    nickname: "",
    avatar: null,
    parentQuestionnaireMapping: {},
    childrenData: [],
  },
  setParentData: (fieldName, value) =>
    set((state) => ({
      parentData: { ...state.parentData, [fieldName]: value },
    })),

  creatingPlan: false,
  setCreatingPlan: (value: boolean) => set({ creatingPlan: value }),
  isCreatingUser: false,
  setIsCreatingUser: (value: boolean) => set({ isCreatingUser: value }),
  // STEPS
  currentStep: OnboardingStep.ParentingQuestionnaire,
  childStep: 0,
  parentQuestionnaireStep: 0,
  setParentQuestionnaireStep: (value: number) =>
    set({ parentQuestionnaireStep: value }),

  setChildStep: (value: number) => set({ childStep: value }),
  setStep: (value: number) => set({ currentStep: value }),
  nextStep: () => {
    set((state: OnboardContextStore) => {
      if (state.currentStep === OnboardingStep.CoparentCode) {
        return { currentStep: OnboardingStep.ParentName }
      } else if (
        state.currentStep === OnboardingStep.ParentNicknameAvatar &&
        sessionManager.hasRefreshToken()
      ) {
        return { currentStep: OnboardingStep.CreateAccountWithCredentials }
      } else return { currentStep: state.currentStep + 1 }
    })
  },
  previousStep: () => {
    set((state: OnboardContextStore) => {
      if (state.currentStep === 0) {
        sessionManager.clearAuthTokens()
        history.goBack()
        return {}
      } else if (state.currentStep === OnboardingStep.ParentName) {
        return state.isCoparent
          ? { currentStep: OnboardingStep.CoparentCode }
          : { currentStep: OnboardingStep.AddChildren }
      } else if (state.currentStep === OnboardingStep.AddChildren) {
        return { currentStep: OnboardingStep.AddChildOrJoinFamily }
      } else return { currentStep: state.currentStep - 1 }
    })
  },

  // Data submit
  isSubmittingData: false,
  setIsSubmittingData: (value: boolean) => set({ isSubmittingData: value }),
  postAnswerScreen: null,
  setPostAnswerScreen: (value: AnswerInterface | null) =>
    set({ postAnswerScreen: value }),
}))

export default useOnboardContext
