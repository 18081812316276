import {
  SPACING,
  JoonUIColor,
  FlexBox,
  ChevronRightIcon,
} from "@joonapp/web-shared"

const ArrowCircleRightIcon = () => {
  return (
    <FlexBox
      direction="row"
      align="center"
      justify="center"
      gap={SPACING.space2}
      style={{
        background: JoonUIColor.background.lightGray,
        borderRadius: "50%",
        width: SPACING.space8,
        height: SPACING.space8,
        minWidth: SPACING.space8,
        minHeight: SPACING.space8,
        paddingLeft: SPACING.space05,
      }}
    >
      <ChevronRightIcon size={20} color={JoonUIColor.icon.neutral} />
    </FlexBox>
  )
}

export default ArrowCircleRightIcon
