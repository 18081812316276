import { create } from "zustand"

interface TextMessageSettingsStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  phoneNumber: string
  setPhoneNumber: (phoneNumber: string) => void
  isTextMessageEnabled: boolean
  setIsTextMessageEnabled: (isTextMessageEnabled: boolean) => void
  notificationTime: string
  setNotificationTime: (notificationTime: string) => void
  isSaving: boolean
  setIsSaving: (isSaving: boolean) => void
}

const useTextMessageSettingsStore = create<TextMessageSettingsStore>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
  phoneNumber: "",
  setPhoneNumber: (phoneNumber) => set({ phoneNumber }),
  isTextMessageEnabled: false,
  setIsTextMessageEnabled: (isTextMessageEnabled) =>
    set({ isTextMessageEnabled }),
  notificationTime: "",
  setNotificationTime: (notificationTime) => set({ notificationTime }),
  isSaving: false,
  setIsSaving: (isSaving) => set({ isSaving }),
}))

export default useTextMessageSettingsStore
