import { CoachProfile, FlexBox, SPACING } from "@joonapp/web-shared"

import RecommendedCoachCard from "./RecommendedCoachCard"
import { useCoachingStore } from "../../useCoachingStore"

export function RecommendedCoachList({
  recommendedCoaches,
}: {
  recommendedCoaches: CoachProfile[]
}) {
  const { selectedCoach } = useCoachingStore()

  return (
    <FlexBox
      direction="column"
      align="center"
      justify="center"
      wrap={false}
      fullWidth
      gap={SPACING.space6}
    >
      {recommendedCoaches.map((coach, index) => (
        <RecommendedCoachCard
          key={index}
          coachCardContent={coach}
          isSelected={selectedCoach?.user.id === coach.user.id}
          index={index}
        />
      ))}
    </FlexBox>
  )
}
