import { JoonUIColor } from "@joonapp/web-shared"
import { IconProps } from "@joonapp/web-shared"

export const SplotchSolidExclamationIcon = ({
  color = JoonUIColor.icon.accent,
  size = 138,
}: IconProps) => {
  return (
    <svg
      width={size + 1}
      height={size}
      viewBox="0 0 139 138"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_17335_20281)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M64.4045 6.83379L56.8443 16.7616C50.9253 24.5371 41.1589 28.3844 31.5001 26.7432L17.698 24.4025C8.84635 22.8959 0.748047 29.7297 0.748047 38.7158C0.748047 41.5408 1.58209 44.312 3.11566 46.6796L13.4201 62.365C16.1913 66.616 17.6711 71.5664 17.6711 76.6245C17.6711 82.7319 15.5187 88.6509 11.5906 93.3323L8.57731 96.9376C6.31732 99.6818 5.0528 103.126 5.0528 106.677C5.0528 116.121 13.6085 123.277 22.9175 121.582L45.1946 117.52C49.8491 116.686 54.665 117.358 58.916 119.484C60.315 120.156 61.6064 120.99 62.8171 121.959L77.7223 133.878C80.8164 136.353 84.7175 137.725 88.6994 137.725C99.1923 137.725 107.344 128.604 106.187 118.192L105.515 112.085C104.6 103.96 108.071 95.969 114.609 91.0723L132.715 77.4855C136.375 74.7681 138.5 70.4903 138.5 65.9434C138.5 59.9436 134.76 54.5627 129.137 52.4372L118.429 48.4284C109.362 45.0384 102.421 37.6127 99.6496 28.3575L94.8606 12.3762C92.6544 5.03118 85.9014 0 78.2335 0C72.7988 0 67.6869 2.52904 64.4045 6.83379ZM76.461 30.9085C79.2003 31.4487 80.977 34.0983 80.4368 36.8376L72.6235 76.4572C72.0833 79.1965 69.4337 80.9732 66.6944 80.433C63.9551 79.8927 62.1784 77.2432 62.7186 74.5039L70.5319 34.8843C71.0721 32.145 73.7217 30.3683 76.461 30.9085ZM67.5227 99.2928C66.1328 100.225 64.4296 100.567 62.7878 100.243C61.1459 99.919 59.7 98.9562 58.768 97.5663C57.8359 96.1764 57.4942 94.4732 57.818 92.8314C58.1418 91.1895 59.1046 89.7436 60.4945 88.8116C61.8844 87.8795 63.5876 87.5378 65.2294 87.8616C66.8713 88.1854 68.3172 89.1482 69.2492 90.5381C70.1812 91.928 70.5229 93.6312 70.1992 95.273C69.8754 96.9148 68.9126 98.3608 67.5227 99.2928Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_17335_20281">
          <rect
            width="137.752"
            height="137.752"
            fill="white"
            transform="translate(0.748047)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
